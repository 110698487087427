import React, { useState } from "react";
import { SidebarData } from "../SideNav/SidebarData";
import "./UpperNavigation.css";
import "./RedesignUpperNavigation.css";
import { NavLink } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaPhoneAlt,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

import { Link } from "react-router-dom";

const RedesignUpperNavigation = () => {
  const [isUpperNavOpen, setIsUpperNavOpen] = useState(false);

  const toggleNav = () => {
    setIsUpperNavOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div className="redesign-upper-navigation-quick-link-container">
        <div className="redesign-upper-nav-visiable-container-with-button">
          <div className="redesign-harber-menu-packet-container">
            <button
              className="redesign-button-of-menu-in-upper-navigation"
              onClick={toggleNav}
            >
              <FaBars className="redesign-button-in-upper-nav-icon-box" />{" "}
              <p className="redesign-button-in-upper-nav-text-box">Menu</p>
            </button>
          </div>
          <div className="redesign-other-details-container-in-uppermenu">
            <ul className="redesign-other-details-in-upper-menu-ul">
              <li className="redesign-other-details-in-upper-menu-li">
                <NavLink
                  to="/"
                  className="redesign-other-details-in-upper-menu-Navlink"
                >
                  <FaHome />
                </NavLink>
              </li>
              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                |
              </li>
              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <FaPhoneAlt className="phone-redesign-phone-icon" /> Contact
                -29552039, 29552040
              </li>
              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                |
              </li>
              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <a href="https://www.facebook.com/profile.php?id=61553962983251">
                  <FaFacebookF />
                </a>
              </li>

              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <a href="https://www.instagram.com/dpsruofficial/">
                  <FaInstagram />
                </a>
              </li>

              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <a href="https://twitter.com/dpsru_official">
                  {" "}
                  <FaTwitter />{" "}
                </a>
              </li>

              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <a href="https://www.linkedin.com/in/dpsru-university/">
                  {" "}
                  <FaLinkedinIn />{" "}
                </a>
              </li>

              <li className="redesign-other-details-in-upper-menu-li Redesign-list-seprator-pipe-list redesign-responsive-li-which-should-be-hidden-in-mobile-view">
                <a href="https://www.youtube.com/channel/UC1HJbrrrelw3nZy0ZHO0mgw">
                  <FaYoutube />{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={`links-container-for-redesign-uppernavigation-dpsru ${
            isUpperNavOpen ? "open" : ""
          }`}
        >
          <div className="link-container-and-addition-information-for-university-re-design-upper-nav-container01">
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className="upper-navigation-quick-link-li">
                  <a href={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </a>
                </li>
              );
            })}
          </div>

          <div className="link-container-and-addition-information-for-university-re-design-upper-nav-container02"> <div className="redesign-ul-second-container-for-addintional-information">
          <li>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeE2QdMBuHEdxr36hge4YCaNYZ7fTe7Dy85T4mEsiqdpyaUVg/viewform'>Internet Users Account Creation Form - Google Forms</a> <br /> (For use by university faculty, staff, and students only)
            </li>
        </div></div>
        </div>

       
      </div>
    </div>
  );
};

export default RedesignUpperNavigation;
