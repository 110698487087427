import React from 'react'

const NextGenerationSequencinganditsApplications = () => {
  return (
    <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page"> Next Generation Sequencing and its Applications</h2>
<div className="inner-container">


            <h2 className="dpsru-secondary-heading-for-every-page">Introduction</h2>
            <p>
            Next Generation Sequencing and Its Applications The proposed course on Next Generation Sequencing will provide students with a basic understanding of this technology and its varied applications. Students will be introduced to the development of this novel technique as well as learn about the details of a typical workflow, the different methods and applications of NGS with their associated advantages. In addition, students will explore the evolution and advancements in the tools of this technology in order to get a grasp of the changing landscape of NGS and what that means for the future of genomics.
            </p>

<ul>
    <li>
        <b> Duration :</b>3 months (only on weekends): 20-24 days (4 hrs / day)
    </li>
    <li>
        <b> Intake:</b> 25 for 1st batch
    </li>
    <li>
        <b>Proposed course fee:</b> Rs. 15000/- to Rs. 20000/-
    </li>
    <li>
        <b>Who can be interested for this course</b>This course is aimed at students’/ research scholars/ industry people interested in learning about the NGS technology and its applications.
    </li>
    <li>
        <b>Eligibility:  </b>BMLT / B. Pharm. / B. Sc. (Life Sciences)/ B. Tech. (Biotech)
    </li>
    </ul>
<br /><br />
    <p><b> <h2>Learning Outcome:</h2></b></p>

   <ul><li>Review of the genetic concepts required to understand NGS and explore the development of the first generations of DNA sequencing methods</li><li>Explore and understand the motivation and fundamental basis of NGS, the current sequencing technologies alongside their advantages</li><li>Understand the typical workflow of NGS from sample preparation, to making high-quality libraries, to post-sequencing quality control and data management</li><li>Gain familiarity with the major application of NGS, which include, among others, transcriptomic analysis by RNA-Seq, genomic variation discovery by Whole Genome Sequencing, and epigenomics analysis</li><li>Insight into the future of NGS with changing landscape of NGS and the rapid evolution of advanced tools.</li></ul>					<ul><li>Review of the genetic concepts required to understand NGS and explore the development of the first generations of DNA sequencing methods</li><li>Explore and understand the motivation and fundamental basis of NGS, the current sequencing technologies alongside their advantages</li><li>Understand the typical workflow of NGS from sample preparation, to making high-quality libraries, to post-sequencing quality control and data management</li><li>Gain familiarity with the major application of NGS, which include, among others, transcriptomic analysis by RNA-Seq, genomic variation discovery by Whole Genome Sequencing, and epigenomics analysis</li><li>Insight into the future of NGS with changing landscape of NGS and the rapid evolution of advanced tools.</li></ul>
   <br /><br />
    <p><b> <h2>Employability Outcome:</h2></b></p>

    <ul><li>The students with this certification along with degree will be best fit for diagnostic, biotechnology and pharmaceutical industry. In addition to it, this skill will be helpful to get highly paid jobs in diagnostic labs.</li><li>The usage of NGS technology is rising after Covid pandemic, but skilled people are not available in market. So, more supply of skilled persons is required to fulfil the demand.</li></ul>

            </div>
</div>
  )
}

export default NextGenerationSequencinganditsApplications