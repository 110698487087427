import React from 'react'
import './UCPs.css'
import ucgif from './ucgif.gif'

function UCPs() {
    return (
        <div className='ucps-component'>
            <h3 className='ucps-heading'>This Page is Under Construction... </h3>
            
            <img src={ucgif} width={600} height={830.638}  />

        </div>
    )
}

export default UCPs