import React, { useState } from "react";
import "./Naac.css";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

import { Link } from "react-router-dom";

function Naac() {
  const [status1a, setStatus1a] = useState(false);
  const [status1b, setStatus1b] = useState(false);
  const [status1c, setStatus1c] = useState(false);
  const [status1d, setStatus1d] = useState(false);

  // second
  const [status2a, setStatus2a] = useState(false);
  const [status2b, setStatus2b] = useState(false);
  const [status2c, setStatus2c] = useState(false);
  const [status2d, setStatus2d] = useState(false);
  const [status2e, setStatus2e] = useState(false);
  const [status2f, setStatus2f] = useState(false);
  const [status2g, setStatus2g] = useState(false);

  //Third
  const [status3a, setStatus3a] = useState(false);
  const [status3b, setStatus3b] = useState(false);
  const [status3c, setStatus3c] = useState(false);
  const [status3d, setStatus3d] = useState(false);
  const [status3e, setStatus3e] = useState(false);
  const [status3f, setStatus3f] = useState(false);
  const [status3g, setStatus3g] = useState(false);

  // Fourth
  const [status4a, setStatus4a] = useState(false);
  const [status4b, setStatus4b] = useState(false);
  const [status4c, setStatus4c] = useState(false);
  const [status4d, setStatus4d] = useState(false);

  // Fifth
  const [status5a, setStatus5a] = useState(false);
  const [status5b, setStatus5b] = useState(false);
  const [status5c, setStatus5c] = useState(false);
  const [status5d, setStatus5d] = useState(false);

  // Sixth
  const [status6a, setStatus6a] = useState(false);
  const [status6b, setStatus6b] = useState(false);
  const [status6c, setStatus6c] = useState(false);
  const [status6d, setStatus6d] = useState(false);
  const [status6e, setStatus6e] = useState(false);

  //Seventh
  const [status7a, setStatus7a] = useState(false);
  const [status7b, setStatus7b] = useState(false);
  const [status7c, setStatus7c] = useState(false);
  const [status7d, setStatus7d] = useState(false);
  const [status7e, setStatus7e] = useState(false);
  const [status7f, setStatus7f] = useState(false);
  const [status7g, setStatus7g] = useState(false);
  const [status7h, setStatus7h] = useState(false);
  const [status7i, setStatus7i] = useState(false);
  const [status7j, setStatus7j] = useState(false);
  const [status7k, setStatus7k] = useState(false);
  const [status7l, setStatus7l] = useState(false);
  const [status7m, setStatus7m] = useState(false);

  // second section
  // second section
  // Data Validation and Verification (DVV)

  const [secStatus1a, setSecStatus1a] = useState(false);
  const [secStatus1b, setSecStatus1b] = useState(false);
  const [secStatus1c, setSecStatus1c] = useState(false);
  const [secStatus1d, setSecStatus1d] = useState(false);
  const [secStatus1e, setSecStatus1e] = useState(false);
  const [secStatus1f, setSecStatus1f] = useState(false);
  const [secStatus1g, setSecStatus1g] = useState(false);
  // second section

  //   function for open/close all tabs in one click
  // cr01
  const [isCr1Open, setIsCr1Open] = useState(false);
  const openAllTabsCr1 = () => {
    setStatus1a(true);
    setStatus1b(true);
    setStatus1c(true);
    setStatus1d(true);
    setIsCr1Open(true);
  };
  const closeAllTabsCr1 = () => {
    setStatus1a(false);
    setStatus1b(false);
    setStatus1c(false);
    setStatus1d(false);
    setIsCr1Open(false);
  };

  // cr02
  const [isCr2open, setIsCr2open] = useState(false);
  const openAllTabsCr2 = () => {
    setStatus2a(true);
    setStatus2b(true);
    setStatus2c(true);
    setStatus2d(true);
    setStatus2e(true);
    setStatus2f(true);
    setStatus2g(true);
    setIsCr2open(true);
  };
  const closeAllTabsCr2 = () => {
    setStatus2a(false);
    setStatus2b(false);
    setStatus2c(false);
    setStatus2d(false);
    setStatus2e(false);
    setStatus2f(false);
    setStatus2g(false);
    setIsCr2open(false);
  };

  // cr 03
  const [isCr3open, setIsCr3open] = useState(false);
  const openAllTabsCr3 = () => {
    setStatus3a(true);
    setStatus3b(true);
    setStatus3c(true);
    setStatus3d(true);
    setStatus3e(true);
    setStatus3f(true);
    setStatus3g(true);
    setIsCr3open(true);
  };
  const closeAllTabsCr3 = () => {
    setStatus3a(false);
    setStatus3b(false);
    setStatus3c(false);
    setStatus3d(false);
    setStatus3e(false);
    setStatus3f(false);
    setStatus3g(false);
    setIsCr3open(false);
  };

  // cr 04
  const [isCr4open, setIsCr4open] = useState(false);
  const openAllTabsCr4 = () => {
    setStatus4a(true);
    setStatus4b(true);
    setStatus4c(true);
    setStatus4d(true);
    setIsCr4open(true);
  };
  const closeAllTabsCr4 = () => {
    setStatus4a(false);
    setStatus4b(false);
    setStatus4c(false);
    setStatus4d(false);
    setIsCr4open(false);
  };

  //cr 05
  const [isCr5open, setIsCr5open] = useState(false);
  const openAllTabsCr5 = () => {
    setStatus5a(true);
    setStatus5b(true);
    setStatus5c(true);
    setStatus5d(true);
    setIsCr5open(true);
  };
  const closeAllTabsCr5 = () => {
    setStatus5a(false);
    setStatus5b(false);
    setStatus5c(false);
    setStatus5d(false);
    setIsCr5open(false);
  };

  // cr 06
  const [isCr6open, setIsCr6open] = useState(false);
  const openAllTabsCr6 = () => {
    setStatus6a(true);
    setStatus6b(true);
    setStatus6c(true);
    setStatus6d(true);
    setStatus6e(true);
    setIsCr6open(true);
  };
  const closeAllTabsCr6 = () => {
    setStatus6a(false);
    setStatus6b(false);
    setStatus6c(false);
    setStatus6d(false);
    setStatus6e(false);
    setIsCr6open(false);
  };

  // cr 07
  const [isCr7open, setIsCr7open] = useState(false);
  const openAllTabsCr7 = () => {
    setStatus7a(true);
    setStatus7b(true);
    setStatus7c(true);
    setStatus7d(true);
    setStatus7e(true);
    setStatus7f(true);
    setStatus7g(true);
    setStatus7h(true);
    setStatus7i(true);
    setStatus7j(true);
    setStatus7k(true);
    setStatus7l(true);
    setStatus7m(true);
    setIsCr7open(true);
  };
  const closeAllTabsCr7 = () => {
    setStatus7a(false);
    setStatus7b(false);
    setStatus7c(false);
    setStatus7d(false);
    setStatus7e(false);
    setStatus7f(false);
    setStatus7g(false);
    setStatus7h(false);
    setStatus7i(false);
    setStatus7j(false);
    setStatus7k(false);
    setStatus7l(false);
    setStatus7m(false);
    setIsCr7open(false);
  };

  return (
    <div className="naac-container">
      <h2 className="dpsru-primary-heading-for-every-page">NAAC</h2>
      <h4 className="dpsru-naac-grade-declearation">DPSRU has been Accredited  by NAAC with a CGPA of 3.02 on a seven point scale at A Grade valid for a period of 5 years from 02 - 08 - 2023</h4>
  
   

        {/* button */}
        <div className="container-wrapper-button-of-naac-peer-team">   
        <div className="wrapper-of-peer-team-visit-btn">
          <Link
            to="/peer-team-visit/"
            className=" peer-team-visit-btn"
          >
            Peer Team Visit
          </Link>
        </div>
        <div className="wrapper-of-peer-team-visit-btn">
          <a
           href="/wp-content/uploads/2023/07/SSR-after-DVV.pdf"
            className=" peer-team-visit-btn"
          >
           Pre-qualified SSR (NAAC Accreditation Cycle -1)
          </a>
        </div>
        </div>

        {/* button */}
    
      <div className="naac-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">
          Self Study Report (SSR)
        </h2>

        <h3 className="naac-sub-heading">Criterion1-CurricularAspects</h3>

        {isCr1Open ? (
          <button
            onClick={() => closeAllTabsCr1()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr1()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}
        {/* for first data 1.1 */}
        <button
          className={
            status1a
              ? "naac-button-true naac-button"
              : "naac-button-false naac-button"
          }
          onClick={() => setStatus1a(!status1a)}
        >
          <span> 1.1 Curriculum Design and Development </span>
        </button>

        {status1a ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              1.1.1 Curricula developed and implemented have relevance to the
              local, national, regional and global developmental needs, which is
              reflected in the Programme outcomes (POs), Programme Specific
              Outcomes(PSOs) and Course Outcomes(COs) of the Programmes offered
              by the University
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/1.1.1.pdf">
                  Supporting Document of 1.1.1
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              1.1.2 Percentage of Programmes where syllabus revision was carried
              out during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/1.1.2_1.pdf">
                  1.1.2(1) Details of Program revised syllabus with Minutes of
                  BoS for the A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.1.2_2.pdf">
                  1.1.2(2) Details of Program revised syllabus with Minutes of
                  BoS for the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.1.2_3.pdf">
                  1.1.2(3) Details of Program revised syllabus with Minutes of
                  BoS for the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.1.2_4.pdf">
                  1.1.2(4) Details of Program revised syllabus with Minutes of
                  BoS for the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.1.2_5.pdf">
                  1.1.2(5) Details of Program revised syllabus with Minutes of
                  BoS for the A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              1.1.3 Average percentage of courses having focus on employability/
              entrepreneurship/ skill development offered by the University
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/1.1.3.pdf">
                  Supporting Document of 1.1.3
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for second data 1.2*/}
        <button className="naac-button" onClick={() => setStatus1b(!status1b)}>
          1.2 Academic Flexibility
        </button>

        {status1b ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              1.2.1 Percentage of new courses introduced out of the total number
              of courses across all programmes offered during the last five
              years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.1_1.pdf">
                  1.2.1(1) List of new courses introduced in the A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.1_2.pdf">
                  1.2.1(2) List of new courses introduced in the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.1_3.pdf">
                  1.2.1(3) List of new courses introduced in the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.1_4.pdf">
                  1.2.1(4) List of new courses introduced in the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.1_5.pdf">
                  1.2.1(5) List of new courses introduced in the A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              1.2.2 Percentage of Programmes in which Choice Based Credit System
              (CBCS)/elective course system has been implemented (Data to be
              given for the latest completed academic year)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.2-ASSRM.pdf">
                  Academy of Sports Sciences Research and Management(ASSRM)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.2.2-SAHSM.pdf">
                  School of Allied Health Sciences and Management (SAHSM)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.2.-SOP.pdf">
                  School of Physiotherapy (SOP){" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.2.2_Pharmacy.pdf">
                  Pharmaceutical Sciences{" "}
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for third data 1.3*/}
        <button className="naac-button" onClick={() => setStatus1c(!status1c)}>
          1.3 Curriculum Enrichment
        </button>
        {status1c ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              1.3.1 Institution integrates cross-cutting issues relevant to
              Professional Ethics ,Gender, Human Values, Environment and
              Sustainability into the Curriculum
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.1.pdf">
                  Supporting Document of 1.3.1
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              1.3.2 Number of value-added courses for imparting transferable and
              life skills offered during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.2_1.pdf">
                  1.3.2(1) Documents for the value-added programs organised
                  during the A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.2_2.pdf">
                  1.3.2(2) Documents for the value-added programs organised
                  during the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.2_3.pdf">
                  1.3.2(3) Documents for the value-added programs organised
                  during the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.2_4.pdf">
                  1.3.2(4) Documents for the value-added programs organised
                  during the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.2_5.pdf">
                  1.3.2(5) Documents for the value-added programs organised
                  during the A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              1.3.3 Average Percentage of students enrolled in the courses under
              1.3.2 as above
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.3_1.pdf">
                  1.3.3(1) Average Percentage of students enrolled in the
                  courses during the A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.3_2.pdf">
                  1.3.3(2) Average Percentage of students enrolled in the
                  courses during the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.3_3.pdf">
                  1.3.3(3) Average Percentage of students enrolled in the
                  courses during the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.3_4.pdf">
                  1.3.3(4) Average Percentage of students enrolled in the
                  courses during the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.3.3_5.pdf">
                  1.3.3(5) Average Percentage of students enrolled in the
                  courses during the A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              1.3.4 Percentage of students undertaking field projects / research
              projects / internships (Data to be given for the latest completed
              academic year)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.4_1.pdf">
                  1.3.4(1) Documents for the Project work
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.4_2.pdf">
                  1.3.4(2) Documents for the Industrial Visits
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/1.3.4_3.pdf">
                  1.3.4(3) Documents for the Internships
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for fourth data 1.4*/}
        <button className="naac-button" onClick={() => setStatus1d(!status1d)}>
          1.4 Feedback System
        </button>

        {status1d ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/1.4.1.pdf">
                  1.4.1 Structured feedback for design and review of syllabus
                  semester wise / year-wise is received from 1 Students, 2
                  Teachers, 3 Employers and 4 Alumni
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/1.4.2.pdf">
                  1.4.2 Feedback processes of the institution
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* second 2 */}
        <h3 className="naac-sub-heading">
          Criterion -2 Teaching-Learning and Evaluation
        </h3>

        {isCr2open ? (
          <button
            onClick={() => closeAllTabsCr2()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr2()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* first of second sec 2.1 */}
        <button className="naac-button" onClick={() => setStatus2a(!status2a)}>
          2.1 Student Enrolment and Profile
        </button>
        {status2a ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              2.1.1 Demand Ratio (Average of last five years){" "}
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/2.1.1_1.pdf">
                  2.1.1(1) List of enrolled students in theA.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/2.1.1_2.pdf">
                  2.1.1(2) List of enrolled students in the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/2.1.1_3.pdf">
                  2.1.1(3) List of enrolled students in the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/2.1.1_4.pdf">
                  2.1.1(4) List of enrolled students in the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/2.1.1_5.pdf">
                  2.1.1(5) List of enrolled students in the A.Y.2017-18
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.1.1_6.pdf">
                  2.1.1(6) Approvals for the last five years
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.1.2 Average percentage of seats filled against reserved
              categories (SC, ST, OBC, Divyangjan, etc.) as per applicable
              reservation policy during the last five years (Excluding
              Supernumerary Seats)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.1.2_1.pdf">
                  2.1.2(1) List of students enrolled against reserved category
                  for the last five years Government Order
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.1.2_2.pdf">
                  2.1.2(2) GO about Reservation Policy in admissions
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* second of second section 2.2 */}
        <button className="naac-button" onClick={() => setStatus2b(!status2b)}>
          2.2 Catering to Student Diversity
        </button>
        {status2b ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              2.2.1 The institution assesses the learning levels of the students
              and organizes special Programmes for advanced learners and slow
              learners.{" "}
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.2.1.pdf">
                  2.2.1 List of students enrolled against reserved category for
                  the last five years
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.2.2 Student – Full time teacher ratio (Data for the latest
              completed academic year)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.2.2_1.pdf">
                  2.2.2(1) List of students in the A.Y.2021-22List of Full-time
                  Teachers
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.2.2_2.pdf">
                  2.2.2(2) List of full-time teachers in the A.Y.2021-22
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* third of second sec 2.3 */}
        <button className="naac-button" onClick={() => setStatus2c(!status2c)}>
          2.3 Teaching-Learning Process
        </button>

        {status2c ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.1-SD.pdf">
                  2.3.1 Student centric methods, such as experiential learning,
                  participative learning and problem solving methodologies are
                  used for enhancing their learning experiences
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.2.pdf">
                  2.3.2 Teachers use ICT- enabled tools including online
                  resources for effective teaching and learning processes
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              2.3.3 Ratio of students mentored for academic and other related
              issues (Data to be provided only for the latest completed academic
              year)
            </h3>

            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.3_1.pdf">
                  2.3.3(1) Circulars of the appointment of Mentors
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.3_2.pdf">
                  2.3.3(2) List of Mentors
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.3_3.pdf">
                  2.3.3(3) List of Students
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.3.3_4.pdf">
                  2.3.3(4) Mentor-wise Issues raised and resolve{" "}
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* Fourth of second sec 2.4 */}
        <button className="naac-button" onClick={() => setStatus2d(!status2d)}>
          2.4 Teacher Profile and Quality
        </button>
        {status2d ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              2.4.1 Average percentage of full time teachers appointed against
              the number of sanctioned posts during the last five years{" "}
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.1_1.pdf">
                  2.4.1(1) List of Full-time teachers for the A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.1_2.pdf">
                  2.4.1(2) List of Full-time teachers for the A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.1_3.pdf">
                  2.4.1(3) List of Full-time teachers for the A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.1_4.pdf">
                  2.4.1(4) List of full-time teachers for the A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.1_5.pdf">
                  2.4.1(5) List of Full-time teachers for the A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.4.2Average percentage of full time teachers with
              Ph.D./D.M/M.Ch./D.N.B Superspeciality/D.Sc./D’Lit. during the last
              five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.2_1.pdf">
                  2.4.2(1) List of full-time teachers with Ph.D. for the
                  A.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.2_2.pdf">
                  2.4.2(2) List of full-time teachers with Ph.D. for the
                  A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.2_3.pdf">
                  2.4.2(3) List of full-time teachers with Ph.D. for the
                  A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.2_4.pdf">
                  2.4.2(4) List of full-time teachers with Ph.D. for the
                  A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.2_5.pdf">
                  2.4.2(5) List of full-time teachers with Ph.D. for the
                  A.Y.2017-18
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.4.3 Average teaching experience of full time teachers in the
              same institution (Data to be provided only for the latest
              completed academic year, in number of years)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.3.pdf">
                  2.4.3 List of full-time teachers in the A.Y.2021-22
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.4.4 Average percentage of full time teachers who received
              awards, recognition, fellowships at the State, National,
              International level from Government/Govt. recognized bodies during
              the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.4_1.pdf">
                  2.4.4(1) e-copies of the award letters received in
                  theA.Y.2021-22
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.4_2.pdf">
                  2.4.4(2) e-copies of the award letters received in the
                  A.Y.2020-21
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.4_3.pdf">
                  2.4.4(3) e-copies of the award letters received in the
                  A.Y.2019-20
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.4_4.pdf">
                  2.4.4(4) e-copies of the award letters received in the
                  A.Y.2018-19
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.4.4_5.pdf">
                  2.4.4(5) e-copies of the award letters received in the
                  A.Y.2017-18
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* fifth of second section 2.5 */}
        <button className="naac-button" onClick={() => setStatus2e(!status2e)}>
          2.5 Evaluation Process and Reforms
        </button>
        {status2e ? (
          <div className="inner-container">
            <ul>
              <li style={{ fontSize: "1.3rem" }}>
                2.5.1 Average number of days from the date of last semester-end/
                year- end examination till the declaration of results during the
                last five years
                <ol>
                  <li>
                    <a
                      href="/wp-content/uploads/2022/12/2.5.1-ad.pdf"
                      style={{ textDecoration: "underline" }}
                    >
                      Additional Document of 2.5.1
                    </a>
                  </li>
                  <li>
                    <a
                      href="/wp-content/uploads/2022/12/2.5.1-SD1.pdf"
                      style={{ textDecoration: "underline" }}
                    >
                      Supporting Document of 2.5.1
                    </a>
                  </li>
                </ol>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.5.2..pdf">
                  2.5.2 Average percentage of student complaints/grievances
                  about evaluation against total number appeared in the
                  examinations during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.5.3.pdf">
                  2.5.3 IT integration and reforms in the examination procedures
                  and processes (continuous internal assessment and end-semester
                  assessment) have brought in considerable improvement in
                  examination management system of the institution
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2.5.4.pdf">
                  2.5.4 Status of automation of Examination division along with
                  approved Examination Manual
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* sixth of second section 2.6 */}
        <button className="naac-button" onClick={() => setStatus2f(!status2f)}>
          2.6 Student Performance and Learning Outcomes
        </button>

        {status2f ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              2.6.1 The institution has stated learning outcomes (generic and
              programme specific)/graduate attributes which are integrated into
              the assessment process and widely publicized through the website
              and other documents
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.6.1.pdf">
                  2.6.1 Pos, PSO and COs
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.6.2 Attainment of Programme Outcomes, Programme Specific
              Outcomes and Course Outcomes are evaluated by the institution{" "}
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.6.2.pdf">
                  2.6.2 Attainment Process
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              2.6.3 Pass percentage of students (Data to be provided only for
              the latest completed academic year)
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.6.3.pdf">
                  2.6.3 Result Analysis and Result sheet published by the
                  University
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* seventh of second section 2.7 */}
        <button className="naac-button" onClick={() => setStatus2g(!status2g)}>
          2.7 Student Satisfaction Survey
        </button>
        {status2g ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2.7.pdf">
                  2.7.1 Online Student Satisfaction Survey regarding the
                  teaching-learning process
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* Third */}

        <h3 className="naac-sub-heading">
          Criterion 3 – Research, Innovations and Extension
        </h3>

        {isCr3open ? (
          <button
            onClick={() => closeAllTabsCr3()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr3()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* for first data of third 3.1 */}
        <button className="naac-button" onClick={() => setStatus3a(!status3a)}>
          3.1 Promotion of Research and Facilities
        </button>

        {status3a ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.1.pdf">
                  3.1.1 The institution’s Research facilities are frequently
                  updated and there is a well defined policy for promotion of
                  research which is uploaded on the institutional website and
                  implemented
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.2.pdf">
                  3.1.2 The institution provides seed money to its teachers for
                  research (average per year; INR in Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.3.pdf">
                  3.1.3 Percentage of teachers receiving national/ international
                  fellowship/financial support by various agencies for advanced
                  studies/ research during the last five years{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.4.pdf">
                  3.1.4 Number of JRFs, SRFs, Post Doctoral Fellows, Research
                  Associates and other research fellows enrolled in the
                  institution during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.5.pdf">
                  3.1.5 Institution has the following facilities to support
                  research
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.1.6.pdf">
                  3.1.6 Percentage of departments with UGC-SAP, CAS, DST-FIST,
                  DBT, ICSSR and other recognitions by national and
                  international agencies (Data for the latest completed academic
                  year)
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for second data of third section 3.2 */}
        <button className="naac-button" onClick={() => setStatus3b(!status3b)}>
          3.2 Resource Mobilization for Research
        </button>
        {status3b ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.2.1.pdf">
                  3.2.1 Extramural funding for Research (Grants sponsored by
                  nongovernment sources such as industry, corporate houses,
                  international bodies for research projects), endowments,
                  Chairs in the University during the last five years (INR in
                  Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.2.2.pdf">
                  3.2.2 Grants for research projects sponsored by government
                  agencies during the last five years (INR in Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.2.3.pdf">
                  3.2.3 Number of research projects per teacher funded by
                  government and non-government agencies during the last five
                  years
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for first data 3.3 */}
        <button className="naac-button" onClick={() => setStatus3c(!status3c)}>
          3.3 Innovation Ecosystem
        </button>

        {status3c ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.3.1.pdf">
                  3.3.1 Institution has created an ecosystem for innovations,
                  including Incubation centre and other initiatives for the
                  creation and transfer of knowledge
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.3.2.pdf">
                  3.3.2 Number of workshops/seminars conducted on Research
                  methodology, Intellectual Property Rights (IPR),
                  Entrepreneurship, Skill development during the last five years{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.3.3.pdf">
                  3.3.3 Number of awards / recognitions received for
                  research/innovations by the institution/teachers/research
                  scholars/students during the last five years{" "}
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for first data 3.4 */}
        <button className="naac-button" onClick={() => setStatus3d(!status3d)}>
          3.4 Research Publications and Awards
        </button>

        {status3d ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.1.pdf">
                  3.4.1 The institution ensures implementation of its stated
                  Code of Ethics for research
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.2.pdf">
                  3.4.2 The institution provides incentives to teachers who
                  receive state, national and international recognitions/awards
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.3-1.pdf">
                  3.4.3 Number of Patents published/awarded during the last five
                  years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.4.pdf">
                  3.4.4. Number of Ph.Ds awarded per teacher during the last
                  five years
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              3.4.5 Number of research papers published per teacher in the
              Journals as notified on UGC website during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2017-2018.pdf">
                  [2017-2018]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2018-2019-2.pdf">
                  [2018-2019]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2019-2020-2.pdf">
                  [2019-2020]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2020-2021-2.pdf">
                  [2020-2021]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2021-2022-1.pdf">
                  [2021-2022]
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.6.pdf">
                  3.4.6 Number of books and chapters in edited volumes published
                  per teacher during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.7.pdf">
                  3.4.7 E-content is developed by teachers
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.8.pdf">
                  3.4.8 Bibliometrics of the publications during the last five
                  years based on average Citation Index in Scopus/ Web of
                  Science/PubMed
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.4.9.pdf">
                  3.4.9 Bibliometrics of the publications during the last five
                  years based on Scopus/ Web of Science – h-Index of the
                  University
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* for first data 3.5 */}
        <button className="naac-button" onClick={() => setStatus3e(!status3e)}>
          3.5 Consultancy
        </button>
        {status3e ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.5.1.pdf">
                  3.5.1 Institution has a policy for consultancy including
                  revenue sharing between the institution and the individual and
                  encourages its faculty to undertake consultancy
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.5.2.pdf">
                  3.5.2 Revenue generated from consultancy and corporate
                  training during the last five years (INR in Lakhs)
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* for first data 3.6 */}
        <button className="naac-button" onClick={() => setStatus3f(!status3f)}>
          3.6 Extension Activities
        </button>

        {status3f ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              3.6.1 Extension activities in the neighborhood community in terms
              of impact and sensitizing the students to social issues and
              holistic development during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.6.1.pdf">
                  3.6.1 Supporting Document
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              3.6.2 Number of awards received by the Institution, its teachers
              and students from Government /Government-recognized bodies in
              recognition of the extension activities carried out during the
              last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/2018-2019.pdf">
                  [2018-2019]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2019-2020.pdf">
                  [2019-2020]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2020-2021.pdf">
                  [2020-2021]
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/2021-2022.pdf">
                  [2021-2022]
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              3.6.3 Number of extension and outreach programs conducted by the
              institution including those through NSS/NCC, Government and
              Government recognized bodies during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.6.3-1.pdf">
                  3.6.3 Extension and Outreach programs conducted during the
                  assessment period
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              3.6.4 Average percentage of students participating in extension
              activities listed at 3.6.3 above, during the last five years
            </h3>
          </div>
        ) : null}

        {/* for first data 3.7 */}
        <button className="naac-button" onClick={() => setStatus3g(!status3g)}>
          3.7 Collaboration
        </button>
        {status3g ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/3.7.1.pdf">
                  3.7.1 Number of collaborative activities with other
                  institutions/ research establishment/industry for research and
                  academic development of faculty and students per year{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/3.7.2.pdf">
                  3.7.2 Number of functional MoUs with institutions/ industries
                  in India and abroad for internship, on-the-job training,
                  project work, student / faculty exchange and collaborative
                  research during the last five years
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* Fourth */}
        <h3 className="naac-sub-heading">
          Criterion 4 – Infrastructure and Learning Resources
        </h3>
        {isCr4open ? (
          <button
            onClick={() => closeAllTabsCr4()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr4()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* 4.1 */}
        <button className="naac-button" onClick={() => setStatus4a(!status4a)}>
          4.1 Physical Facilities
        </button>

        {status4a ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/4.1.1.pdf">
                  4.1.1. The institution has adequate facilities for teaching –
                  learning. viz., classrooms, laboratories, computing equipment,
                  etc.
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.1.2.pdf">
                  4.1.2 The institution has adequate facilities for cultural
                  activities, yoga, games and sports (indoor & outdoor);
                  (gymnasium, yoga centre, auditorium, etc.,)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.1.3-file.pdf">
                  4.1.3 Availability of general campus facilities and overall
                  ambience{" "}
                </a>
              </li>
              <h3 className="inner-container-heading">
                4.1.4 Average percentage of expenditure excluding salary, for
                infrastructure augmentation during the last five years (INR in
                Lakhs)
              </h3>
              <ul>
                <li>
                  <a href="/wp-content/uploads/2022/12/4.1.4.pdf">
                    Supporting Document of 4.1.4
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2022/12/4.1.41.pdf">
                    Additional Document of 4.1.4
                  </a>
                </li>
              </ul>
            </ul>
          </div>
        ) : null}

        {/* 4.2 */}
        <button className="naac-button" onClick={() => setStatus4b(!status4b)}>
          4.2 Library as a Learning Resource
        </button>

        {status4b ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/4.2.1.pdf">
                  4.2.1 Library is automated using Integrated Library Management
                  System (ILMS) and has digitization facility
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.2.2.pdf">
                  4.2.2 Institution has subscription for e-Library resources
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.2.3.pdf">
                  4.2.3 Average annual expenditure for purchase of books/
                  e-books and subscription to journals/e-journals during the
                  last five years (INR in Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.2.4-1.pdf">
                  4.2.4 Percentage per day usage of library by teachers and
                  students ( foot falls and login data for online access) (Data
                  to be provided only for the latest completed academic year)
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 4.3 */}
        <button className="naac-button" onClick={() => setStatus4c(!status4c)}>
          4.3 IT Infrastructure
        </button>
        {status4c ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/4.3.1.pdf">
                  4.3.1 Percentage of classrooms and seminar halls with ICT –
                  enabled facilities such as LCD, smart board, Wi-Fi/LAN, audio
                  video recording facilities.(Data to be provided only for the
                  latest completed academic year)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.3.2.pdf">
                  4.3.2 Institution has an IT policy, makes appropriate
                  budgetary provisions and updates its IT facilities including
                  the Wi-Fi facility
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.3.3.pdf">
                  4.3.3 Student – Computer ratio (Data to be provided only for
                  the latest completed academic year)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.3.4.pdf">
                  4.3.4 Available bandwidth of internet connection in the
                  Institution
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.3.5.pdf">
                  4.3.5 Institution has the following Facilities for e-content
                  development
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 4.4 */}
        <button className="naac-button" onClick={() => setStatus4d(!status4d)}>
          4.4 Maintenance of Campus Infrastructure
        </button>

        {status4d ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              4.4.1 Average percentage expenditure incurred on maintenance of
              physical facilities and academic support facilities excluding
              salary component during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/4.4.1.pdf">
                  4.4.1 Supporting Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/4.4.11.pdf">
                  4.4.1 Additional Document
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              4.4.2 There are established systems and procedures for maintaining
              and utilizing physical, academic and support facilities –
              laboratory, library, sports complex, computers, classrooms etc.
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/IT-4.4.2.pdf">
                  4.4.2 Supporting Document
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* Fifth */}
        <h3 className="naac-sub-heading">
          Criterion 5 – Student Support and Progression
        </h3>
        {isCr5open ? (
          <button
            onClick={() => closeAllTabsCr5()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr5()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* 5.1 */}
        <button className="naac-button" onClick={() => setStatus5a(!status5a)}>
          5.1 Student Support
        </button>
        {status5a ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/5.1.1_1.pdf">
                  5.1.1 Average percentage of students benefited by scholarships
                  and freeships provided by the institution, Government and
                  nongovernment bodies, industries, individuals, philanthropists
                  during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.1.2.pdf">
                  5.1.2 Average percentage of students benefited by career
                  counseling and guidance for competitive examinations as
                  offered by the Institution, during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.1.3.pdf">
                  5.1.3 Following Capacity development and skills enhancement
                  initiatives are undertaken by the institution
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.1.4-1.pdf">
                  5.1.4 The Institution adopts the following for redressal of
                  student grievances including sexual harassment and ragging
                  cases
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 5.2 */}
        <button className="naac-button" onClick={() => setStatus5b(!status5b)}>
          5.2 Student Progression
        </button>
        {status5b ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/5.2.1.pdf">
                  5.2.1 Average percentage of students qualifying in state/
                  national/ international level examinations during the last
                  five years (eg: NET/SLET/GATE/GMAT/CAT/GRE/JAM/IELTS/TOEFL/
                  CLAT/ Civil Services/State government examinations)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.2.2.pdf">
                  5.2.2 Average percentage of placement of outgoing students
                  during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.2.3.pdf">
                  5.2.3 Percentage of recently-graduated students who have
                  progressed to higher education (previous graduating batch)
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 5.3 */}
        <button className="naac-button" onClick={() => setStatus5c(!status5c)}>
          5.3 Student Participation and Activities
        </button>
        {status5c ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/5.3.1.pdf">
                  5.3.1 Number of awards/medals won by students for outstanding
                  performance in sports/cultural activities at
                  inter-university/state/national/international events (award
                  for a team event should be counted as one) during the last
                  five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.3.2.pdf">
                  5.3.2 Presence of Student Council and its activities for
                  institutional development and student welfare.
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.3.3.pdf">
                  5.3.3 Average number of sports and cultural events /
                  competitions organized by the institution per year
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 5.4 */}
        <button className="naac-button" onClick={() => setStatus5d(!status5d)}>
          5.4 Alumni Engagement
        </button>
        {status5d ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/5.4.1.pdf">
                  5.4.1 The Alumni Association/Chapters (registered and
                  functional) contributes significantly to the development of
                  the institution through financial and other support services
                  during the last five years
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/5.4.2-1.pdf">
                  5.4.2 Alumni contribution during the last five years (INR in
                  lakhs)
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* Sixth */}
        <h3 className="naac-sub-heading">
          Criterion 6 – Governance, Leadership and Management
        </h3>
        {isCr6open ? (
          <button
            onClick={() => closeAllTabsCr6()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr6()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* 6.1 */}
        <button className="naac-button" onClick={() => setStatus6a(!status6a)}>
          6.1 Institutional Vision and Leadership
        </button>
        {status6a ? (
          <div className="inner-container">
            <ul>
              <li>
                {/* <Link to="/ucps"> */}
                  6.1.1 The institution has a clearly stated vision and mission
                  which are reflected in its academic and administrative
                  governance{" "}
                {/* </Link> */}
              </li>
              <li>
                <Link to="/genralCounsil">
                  6.1.2 Effective leadership is reflected in various
                  institutional practices such as decentralization and
                  participative management.
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 6.2 */}
        <button className="naac-button" onClick={() => setStatus6b(!status6b)}>
          6.2 Strategy Development and Deployment
        </button>
        {status6b ? (
          <div className="inner-container">
            <ul>
              <li>
                <Link to="/university/strategic-plan-of-dpsru/">
                  6.2.1 The institutional Strategic plan is effectively deployed{" "}
                </Link>
              </li>
              <li>
                <Link to="/administration">
                  6.2.2 Functioning of the institutional bodies is effective and
                  efficient as visible from policies, administrative setup,
                  appointment, service rules, and procedures, etc,.{" "}
                </Link>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              6.2.3 Institution Implements e-governance in its areas of
              operations
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.2.3_1.pdf">
                  6.2.3(1) Policy Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.2.3_2.pdf">
                  6.2.3(2) ERP Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.2.3_3.pdf">
                  6.2.3(3) Screenshots
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.2.3_4.pdf">
                  6.2.3(4) Invoices
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.2.3_5.pdf">
                  6.2.3(5) Annual E-Governance Report
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 6.3 */}
        <button className="naac-button" onClick={() => setStatus6c(!status6c)}>
          6.3 Faculty Empowerment Strategies
        </button>
        {status6c ? (
          <div className="inner-container">
            <h3 className="inner-container-heading">
              6.3.1 The institution has a performance appraisal system,
              promotional avenues and effective welfare measures for teaching
              and non-teaching staff
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.1.pdf">
                  6.3.1 Performance appraisal system, promotional avenues and
                  effective welfare measures
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              6.3.2 Average percentage of teachers provided with financial
              support to attend conferences/workshops and towards membership fee
              of professional bodies during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.2_1.pdf">
                  6.3.2(1) Policy Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.2_2.pdf">
                  6.3.2(2) e-copies of the Sanction letters
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.2_3.pdf">
                  6.3.2(3) Audited Statements
                </a>
              </li>
            </ul>

            <h3 className="inner-container-heading">
              6.3.3 Average number of professional development / administrative
              training Programmes organized by the institution for teaching and
              non teaching staff during the last five years
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.3_1.pdf">
                  6.3.3(1) Documents for the organized professional development
                  programs and administrative training programs during the A. Y
                  .2021-22{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.3_2.pdf">
                  6.3.3(2) Documents for the organized professional development
                  programs and administrative training programs during the A. Y
                  .2020-21{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.3_3.pdf">
                  6.3.3(3) Documents for the organized professional development
                  programs and administrative training programs during the A. Y
                  .2019-20{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.3_4.pdf">
                  6.3.3(4) Documents for the organized professional development
                  programs and administrative training programs during the A. Y
                  .2018-19{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.3_5.pdf">
                  6.3.3(5) Documents for the organized professional development
                  programs and administrative training programs during the A. Y
                  .2017-18{" "}
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              6.3.4 Average percentage of teachers undergoing online/
              face-to-face Faculty Development Programmes (FDP)during the last
              five years (Professional Development Programmes, Orientation /
              Induction Programmes, Refresher Course, Short Term Course )
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.3.4_1.pdf">
                  6.3.4 e-copies of the participation certificates during the
                  assessment period
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 6.4 */}
        <button className="naac-button" onClick={() => setStatus6d(!status6d)}>
          6.4 Financial Management and Resource Mobilization
        </button>
        {status6d ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/6.4.1.pdf">
                  6.4.1 Institutional strategies for mobilization of funds and
                  the optimal utilization of resources
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.4.2_1.pdf">
                  6.4.2 Funds / Grants received from government bodies during
                  the last five years for development and maintenance of
                  infrastructure (not covered under Criteria III and V ) (INR in
                  Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.4.3_1.pdf">
                  6.4.3 Funds / Grants received from non-government bodies,
                  individuals, philanthropists during the last five years for
                  development and maintenance of infrastructure (not covered
                  under Criteria III and V ) (INR in Lakhs)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.4.3_1.pdf">
                  6.4.4 Institution regularly conducts internal and external
                  financial audits
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 6.5 */}
        <button className="naac-button" onClick={() => setStatus6e(!status6e)}>
          6.5 Internal Quality Assurance System (IQAS)
        </button>
        {status6e ? (
          <div className="inner-container">
            <ul>
              <li>
                <Link to="/iaqcIntroduction">
                  6.5.1 Internal Quality Assurance Cell (IQAC) has contributed
                  significantly for institutionalizing the quality assurance
                  strategies and processes, by constantly reviewing the
                  teaching-learning process, structures & methodologies of
                  operations and learning outcomes, at periodic intervals{" "}
                </Link>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              6.5.2 Institution has adopted the following for Quality assurance:
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_1.pdf">
                  6.5.2(1) Academic and Administrative Audit
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_2.pdf">
                  6.5.2(2) Documents for Quality Intiative activities
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_3.pdf">
                  6.5.2(3) Collaborations with other Institutions
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_4.pdf">
                  6.5.2(4) Orientation on quality issues
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_5.pdf">
                  6.5.2(5) Documents for the participation in NIRF
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/6.5.2_6.pdf">
                  6.5.2(6) Quality Audit Recognition Certificates{" "}
                </a>
              </li>
            </ul>
            <h3 className="inner-container-heading">
              6.5.3 Incremental improvements made for the preceding five years
              with regard to quality (in case of first cycle NAAC A/A) Post
              accreditation quality initiatives (second and subsequent cycles of
              NAAC A/A){" "}
            </h3>
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/6.5.3-SD1.pdf">
                  6.5.3 (1) Teachers Attending Professional Development Program
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2023/01/6.5.3_2.pdf">
                  6.5.3(2) Research Publications
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* seven */}
        <h3 className="naac-sub-heading">
          Criterion -7 Institutional Values and Best Practices
        </h3>
        {isCr7open ? (
          <button
            onClick={() => closeAllTabsCr7()}
            className="all-tabs-open-naac-files"
          >
            <IoIosRemove />
          </button>
        ) : (
          <button
            onClick={() => openAllTabsCr7()}
            className="all-tabs-open-naac-files"
          >
            <IoIosAdd />
          </button>
        )}

        {/* 7.1 */}
        <button className="naac-button" onClick={() => setStatus7a(!status7a)}>
          7.1.1 Measures initiated by the Institution for the promotion of
          gender equity during the last five years
        </button>
        {status7a ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_1.pdf">
                  7.1.1(1) Gender Equity Measures{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_2.pdf">
                  7.1.1(2) Gender Sensitization Plan
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_3.pdf">
                  7.1.1(3) Geotagged Photos{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_4.pdf">
                  7.1.1(4) Male to Female Ratio
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_5.pdf">
                  7.1.1(5) Office Orders
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.1_6.pdf">
                  7.1.1(6) Reports{" "}
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 7.2 */}
        <button className="naac-button" onClick={() => setStatus7b(!status7b)}>
          7.1.2 The Institution has facilities for alternate sources of energy
          and energy conservation measures
        </button>
        {status7b ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.2-1.pdf">
                  7.1.2(1) Geo-tagged photographs
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.2_2.pdf">
                  7.1.2(2) Invoices
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 7.3 */}
        <button className="naac-button" onClick={() => setStatus7c(!status7c)}>
          7.1.3 Describe the facilities in the Institution for the management of
          the following types of degradable and non-degradable waste (within 500
          words)
        </button>
        {status7c ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-WASTE-MANAGEMENT.pdf">
                  Waste Management{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-SOP.pdf">SOPs</a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-DPSRU-INSTITUTIONAL-ETHICS-COMMITTEE.pdf">
                  DPSRU Institutional Ethics Committee
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-FLOOR-PLAN.pdf">
                  Floor Plan
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-MOU.pdf">MOU</a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-INVOICES.pdf">
                  Invoices
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-WEEDING-OUTLOOK-POLICY.pdf">
                  Weeding Outlook Policy
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.3-GEOTAGGED-PHOTOS.pdf">
                  Geotagged Photos
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.4*/}
        <button className="naac-button" onClick={() => setStatus7d(!status7d)}>
          7.1.4 Water conservation facilities available in the Institution:
        </button>
        {status7d ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.4-1-Geotagged-Photos-1.pdf">
                  Rainwater Harvesting ,Water bodies ,Water Tank, Borewell, STP
                  Plant
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.4-2-DPSRU-Plan-Layout.pdf">
                  Layout Plan
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.4-3-Water-Body-Maintance.pdf">
                  Maintenance of Water Bodies
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 7.5*/}
        <button className="naac-button" onClick={() => setStatus7e(!status7e)}>
          7.1.5 Green campus initiatives
        </button>
        {status7e ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-4-Policy-Document-on-Green-Campus-Intiative.pdf">
                  Policy Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-1-Circular-Restricted-entry-of-automobiles.pdf">
                  Circular for Restricted Entry of Vehicles
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-2-Use-of-Bicycles.pdf">
                  Use of Bicycles
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-3-Geotagged-Photos.pdf">
                  Landscaping / Pedestrian Friendly Pathway
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-6-Report.pdf">
                  Report of Green Campus Activities{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.5-5-Any-other-relevant-documents.pdf">
                  Any other Relevant Documents
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.6*/}
        <button className="naac-button" onClick={() => setStatus7f(!status7f)}>
          7.1.6 Quality audits on environment and energy are regularly
          undertaken by the institution
        </button>
        {status7f ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-1-Envionment-and-Energy-Uttilzation-Policy-1.pdf">
                  Environment and Energy Utilization Policy
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-2-Carbon-Footprint-and-Energy-Audit-1.pdf">
                  Carbon Footprint and Energy Audit
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-3-Enviromental-Audit-Water-and-Wate-Management-1.pdf">
                  Environmental Audit (Water and Water Management)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-4-Green-Landscape-audit-1.pdf">
                  Green Landscape Audit
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-5-Certificates-On-Quality-audits-on-environment-and-energy-1.pdf">
                  Certificates on (Quality audits on environment and energy){" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-6-Green-Audit-Commmitee-of-DPSRU-Any-other-relevant-information-1.pdf">
                  Green Audit Committee of DPSRU (Any other relevant
                  information)
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-List-Of-Vendors.pdf">
                  List of Vendors{" "}
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.6-Report.pdf">
                  Report
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.7*/}
        <button className="naac-button" onClick={() => setStatus7g(!status7g)}>
          7.1.7 The Institution has friendly, barrier free environment
        </button>
        {status7g ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.7-1-Policy-Document-Disabled-Friently.pdf">
                  Policy Document
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.7-Invoice-of-Maintanacce.pdf">
                  Invoices
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.7-2-Geotagged-Photos.pdf">
                  Documents on Ramps and Lift/ Disabled Friendly
                  Washrooms/Direction Boards/Scribe and Exam Policy/Willingness
                  Letters for Scribe
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.8*/}
        <button className="naac-button" onClick={() => setStatus7h(!status7h)}>
          7.1.8 Describe the Institutional efforts/initiatives in providing an
          inclusive environment i.e., tolerance and harmony towards cultural,
          regional, linguistic, communal, socio-economic and such other
          diversities (within 500 words).
        </button>

        {status7h ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.8-2-Supporting-documents.pdf">
                  Report
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.9*/}
        <button className="naac-button" onClick={() => setStatus7i(!status7i)}>
          7.1.9 Sensitization of students and employees of the Institution to
          the constitutional obligations: values, rights, duties and
          responsibilities of citizens
        </button>
        {status7i ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.9-2-Activites-of-Institution-to-the-constitutional-obligations-1.pdf">
                  Activities of Institution to the constitutional obligations
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.10*/}
        <button className="naac-button" onClick={() => setStatus7j(!status7j)}>
          7.1.10 The Institution has a prescribed code of conduct for students,
          teachers, administrators and other staff and conducts periodic
          programmes in this regard
        </button>
        {status7j ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-1-Policy-Document-on-Code-of-Conduct.pdf">
                  Policy Document on Code of Conduct
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-2-Annual-awareness-programmes-on-Code-of-Conduct.pdf">
                  Annual Awareness Programmes on Code of Conduct
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-3-Committee-on-Code-of-Coduct.pdf">
                  Committee on Code of Conduct
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-4-Code-of-Conduct-Suppoting-Documents.pdf">
                  Code of Conduct
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-5-Policy-Document-on-Code-of-Ethics.pdf">
                  Policy Document on Code of Ethics
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-6-Delhi-Gazzate.pdf">
                  Delhi Gazette
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.10-7-Any-Other-Relevent-Information.pdf">
                  Hostel Rules, DTTE Notification, Quarter Allotment Rules
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        {/* 7.11*/}
        <button className="naac-button" onClick={() => setStatus7k(!status7k)}>
          7.1.11 Institution celebrates / organizes national and international
          commemorative days, events and festivals
        </button>
        {status7k ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.1.11-2-Report.pdf">
                  Report
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.2.1*/}
        <button className="naac-button" onClick={() => setStatus7l(!status7l)}>
          7.2.1 Best Practices
        </button>
        {status7l ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2022/12/7.2.1-BEST-PRACTICE-1-1.pdf">
                  {" "}
                  Best Practice 1
                </a>
              </li>
              <li>
                <a href="/wp-content/uploads/2022/12/BEST-PRACTICE-2-1.pdf">
                  {" "}
                  Best Practice 2
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* 7.3.1*/}
        <button className="naac-button" onClick={() => setStatus7m(!status7m)}>
          7.3.1 Institutional Distinctiveness
        </button>
        {status7m ? (
          <div className="inner-container">
            <ul>
              <li>
                <a href="/wp-content/uploads/2023/01/7.3.1.pdf">
                  Institutional Distinctiveness
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        {/* section 2 Data Validation and Verification (DVV) */}
        {/* section 2 Data Validation and Verification (DVV) */}

        {/* A) Extended Profile Deviations */}
        <h2 className="dpsru-secondary-heading-for-every-page">
          Data Validation and Verification (DVV)
        </h2>

        <h2 className="data-validation-and-varification-heading-inside-naac">
          (A) Extended Profile Deviations
        </h2>
        <div className="extended-profile-deviations-section-of-naac">
          <ul>
            <li>
              <a href="/wp-content/uploads/2023/01/1.1.pdf">
                1.1 Registrar certified list of programmes offered during the
                last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/1.2.pdf">
                1.2 Registrar certified list of departments along with the
                offered programmes
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/2.1.pdf">
                2.1 Certified list of students for the last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/2.2.pdf">
                2.2 Certified list of final year students for the last years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/2.3.pdf">
                2.3 Certified letter indicating the number of students appeared
                for the University examinations of each year
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/2.4.pdf">
                2.4 Certification letter showing the number of applications
                received for revaluation during the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/3.1.pdf">
                3.1 Certified list of programmes along with the number of
                courses for the last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/3.2-1.pdf">
                3.2 (1) List of full-time teachers for the last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/3.2-2.pdf">
                3.2 (2) Appointment orders of full-time teachers for the
                A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/3.3.pdf">
                3.3 Documents for Sanctioned posts
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.1.pdf">
                4.1 Registrar certified letter showing the number of
                program-wise applications received for admissions during the
                assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.2-1.pdf">
                4.2 (1) GO about reservation policy
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.2-2.pdf">
                4.2 (2) Registrar certified number of seats reserved for each
                programme for the last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.3.pdf">
                4.3 Geo-tagged photos of classrooms and seminar halls
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.4-1.pdf">
                4.4 (1) Stock registers by highlighting the entries of computers
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.4-2.pdf">
                4.4 (2) Invoices
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2023/01/4.5.pdf">
                4.5 Audited Statement
              </a>
            </li>
          </ul>
        </div>

        <h2 className="data-validation-and-varification-heading-inside-naac">
          (B) Matrix Deviations
        </h2>
        <div className="extended-profile-deviations-section-of-naac">
          {/* criteria 1 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1a(!secStatus1a)}
          >
            Criterion 1 – CurricularAspects
          </button>

          {secStatus1a ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.1.21.pdf">
                    1.1.2(1) Registrar certified list of Programmes where
                    syllabus was revised during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.1.22.pdf">
                    1.1.2(2) Minutes of BoS
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.1.31.pdf">
                    1.1.3(1) Syllabus copies of courses highlighting the focus
                    on employability/ entrepreneurship/ skill development along
                    with their course outcomes
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.1.32.pdf">
                    1.1.3(2) Mapping of the courses to employability /
                    entrepreneurship / skill development
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.2.11.pdf">
                    1.2.1(1) Registrar certified list of new courses introduced
                    during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.2.12.pdf">
                    1.2.1(2) Minutes of BoS
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.2.21.pdf">
                    1.2.2(1) Registrar certified list of programmes offered
                    CBCS/ECS
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.2.22.pdf">
                    1.2.2(2) University Letter
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.2.23.pdf">
                    1.2.2(3) Course structure of the Programmes highlighting
                    elective courses
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.21.pdf">
                    1.3.2(1) Brochures, Programme report and syllabus of
                    value-added courses offered during the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.31.pdf">
                    1.3.3(1) List of enrolled students of all value-added
                    courses
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.32.pdf">
                    1.3.3(2) Attendance sheets of all value-added courses
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.33.pdf">
                    1.3.3(3) Paarticipation certifications of 10% students in
                    all value-added courses offered during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.41.pdf">
                    1.3.4(1) Internship/ Research Project certificates of M.
                    Pharm (Pharmaceutics)
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.42.pdf">
                    1.3.4(2) Internship/ Research Project certificates of M.
                    Pharm (Pharmacology)
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.3.43.pdf">
                    1.3.4(3) Internship/ Research Project certificates of B.Sc
                    (H) Sports Science
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.4.11.pdf">
                    1.4.1(1) Five sample filled feedback forms from all the
                    stakeholders
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.4.12.pdf">
                    1.4.1(2) Direct feedback link to the HEI website
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.4.21.pdf">
                    1.4.2(1) Feedback analysis report
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/1.4.22.pdf">
                    1.4.2(2) Link to the Institutional website
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          {/* criteria 1 */}

          {/* Criteria 2 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1b(!secStatus1b)}
          >
            Criterion 2 – Teaching-Learning and Evaluation
          </button>

          {secStatus1b ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.1.1.pdf">
                    2.1.1 Sanction letters for the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.1.2.pdf">
                    2.1.2 Admission extract during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.2.2.pdf">
                    2.2.2 List of full-time teachers
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.3.3.pdf">
                    2.3.3 List of Mentors
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.4.11.pdf">
                    2.4.1(1) Sanction letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.4.12.pdf">
                    2.4.1(2) Appointment letters of all full-time teachers in
                    the A.Y.2021-22
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.21.pdf">
                    2.4.2(1) Doctoral degree certificates of fulltime teachers
                    for the A.Y.2021-22
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.22.pdf">
                    2.4.2(2)Doctoral degree certificates of fulltime teachers
                    for the A.Y.2020-21
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.23.pdf">
                    2.4.2(3) Doctoral degree certificates of fulltime teachers
                    for the A.Y.2019-20
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.24.pdf">
                    2.4.2(4) Doctoral degree certificates of fulltime teachers
                    for the A.Y.2018-19
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.25.pdf">
                    2.4.2(5) Doctoral degree certificates of fulltime teachers
                    for the A.Y.2017-18
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/02/2.4.2.pdf">
                    2.4.2 Doctoral degree certificates of the specified
                    full-time teachers
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.4.3.pdf">
                    2.4.3 Experience certificates of the specified full-time
                    teachers till 31-08-2022
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.4.4.pdf">
                    2.4.4 e-copies of awards of full-time teachers
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.5.11.pdf">
                    2.5.1(1) Reports from the CoE by highlighting the name of
                    the program, end date of the examination and date of
                    announcement of the results along with the number of days
                    for the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.5.12.pdf">
                    2.5.1(2) Ceertified report from CoE
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.5.2.pdf">
                    2.5.2 COE certified list of students applied for
                    revaluation/recounting during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.5.4.pdf">
                    2.5.4 Screenshots for automation of examination
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/2.6.3.pdf">
                    2.6.3 CoE certified results report
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          {/* Criteria 3 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1c(!secStatus1c)}
          >
            Criterion 3 – Research, Innovations and Extension
          </button>

          {secStatus1c ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.21.pdf">
                    3.1.2(1) Sanction letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.22.pdf">
                    3.1.2(2) Audited Statements
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.3.pdf">
                    3.1.3 e-copies of award letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.41.xlsx">
                    3.1.4(1) Updated Data template
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.42.pdf">
                    3.1.4(2) Supporting document
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.51.pdf">
                    3.1.5(1) Videos and Geo-tagged photographs
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.52.pdf">
                    3.1.5(2) Report on Research facilities
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.1.6.pdf">
                    3.1.6 Supporting document
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.2.1.pdf">
                    3.2.1 e-copies of sanction letters of the specified research
                    grants
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.2.2.pdf">
                    3.2.2 e-copies of the sanction letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.2.3.pdf">
                    3.2.3 e-copies of the sanction letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.3.2.pdf">
                    3.3.2 Programme reports of the specified workshops/seminars
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.3.3.pdf">
                    3.3.3 e-copies of awards/recognitions
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.11.pdf">
                    3.4.1(1) Syllabus copy of Research Methodology course
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.12.pdf">
                    3.4.1(2) Proceedings for constitution of the ethics
                    committee
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.13.pdf">
                    3.4.1(3) Weblink on the institutional website for Code of
                    Ethics
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.14.pdf">
                    3.4.1(4) Report of research content checked through licensed
                    plagiarism check software
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.21.pdf">
                    3.4.2(1) Policy Document
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.22.pdf">
                    3.4.2(2) e-copies of the letters of incentives granted by
                    the University of the specified activities
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.3.pdf">
                    3.4.3 e-copies of the patents
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.4.pdf">
                    3.4.4 Documents for Ph.Ds awarded per teacher for the last
                    five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.5.xlsx">
                    3.4.5 Modified data template
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.6.pdf">
                    3.4.6 e-copy of the Cover page, content page and first page
                    of the publication indicating ISBN number and year of
                    publication for books/chapters and conference proceedings
                    for year 2018-19
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.4.7.pdf">
                    3.4.7 Supporting document
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.5.21.pdf">
                    3.5.2(1) Audited statements
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.5.22.pdf">
                    3.5.2(2) Sanction letter from the consultancy by
                    highlighting amounts
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.6.2.pdf">
                    3.6.2 e-copies of awards and recognitions
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.6.31.pdf">
                    3.6.3(1) Programme reports of all extension andd outreach
                    programmes conducted during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.6.32.pdf">
                    3.6.3(2) Documents of the specified programmes
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.6.4.pdf">
                    3.6.4 Attendance sheets of all extension activities during
                    the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.7.1.pdf">
                    3.7.1 e-copies of MoUs highlighting area of collaborations
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/3.7.2.pdf">
                    3.7.2 e-copies of MoUs established during the last five
                    years
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          {/* Criteria 3 end here */}

          {/* criteria 4 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1d(!secStatus1d)}
          >
            Criterion 4 – Infrastructure and Learning Resources
          </button>

          {secStatus1d ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.1.4.pdf">
                    4.1.4 Audited statement highlighting expenditure for
                    infrastructure augmentation
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.2.2.pdf">
                    4.2.2 e-copies of subscription letters
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.2.3.pdf">
                    4.2.3 Audited statement highlighting expenditure for
                    purchase of books and journal library resources
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.2.4.pdf">
                    4.2.4 Log book entries for five working days of February
                    2022
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.1.pdf">
                    4.3.1 Geo-tagged photographs of all classrooms and seminar
                    halls with ICT-enabled facilities
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.3.pdf">
                    4.3.3 Stock registers by highlighting the purchase of
                    computers
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.4.pdf">
                    4.3.4 e-copy of agreement with service provider
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.51.pdf">
                    4.3.5(1) Geo-tagged photographs
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.52.pdf">
                    4.3.5(2) Stock register entry by highlighting facilities for
                    e-content development
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.3.53.pdf">
                    4.3.5(3) Audited statement highlighting the relevant
                    expenditure
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/4.4.1.pdf">
                    4.4.1 Audited statement highlighting the academic and
                    physical facilities
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          {/* criteria 4 end here */}

          {/* criteria 5 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1e(!secStatus1e)}
          >
            Criterion 5 – Student Support and Progression
          </button>

          {secStatus1e ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.11.pdf">
                    5.1.1(1) Policy document
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.12.pdf">
                    5.1.1(2) Sanction letters of scholarships
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.13.pdf">
                    5.1.1(3) Audited statement
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.21.pdf">
                    5.1.2(1) Reports and photographs of all programmes offered
                    during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.22.pdf">
                    5.1.2(2) Attendance sheets and participation certificates of
                    the specified programmes
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.3.pdf">
                    5.1.3 Attendance sheets and participation certificates of
                    the specified programmes
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.41.pdf">
                    5.1.4(1) Constitution circulars of all committees
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.42.pdf">
                    5.1.4(2) Reports of the grievance
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.1.43.pdf">
                    5.1.4(3) URL of online grievance mechanism in the
                    Institutional website
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.2.1.pdf">
                    5.2.1 Qualifying certificates of all students during the
                    assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.2.21.pdf">
                    5.2.2(1) Offer letters of students during the A.Y.2021-22
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.2.22.pdf">
                    5.2.2(2) Offer letters of students during the A.Y.2017-18
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.2.3.pdf">
                    5.2.3 Admission letters/Identity cards of the specified
                    students
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.3.1.pdf">
                    5.3.1 e-copies of awards/medals for outstanding performance
                    during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.3.3.pdf">
                    5.3.3 Reports of all sports/cultural events organised during
                    the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.4.21.pdf">
                    5.4.2(1) List of alumni with their contributions
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/5.4.22.pdf">
                    5.4.2(2) Annual Audited Statements
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          {/* criteria 5 end here*/}

          {/* criteria 6 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1f(!secStatus1f)}
          >
            Criterion 6 – Governance, Leadership and Management
          </button>

          {secStatus1f ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.2.31.pdf">
                    6.2.3(1) Highlighted expenditure for e-governance in audited
                    statement
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.2.32.pdf">
                    6.2.3(2) Invoices
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.21.pdf">
                    6.3.2(1) e-copy of the financial sanction letters for five
                    years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.22.pdf">
                    6.3.2(2) Audited statement
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.31.pdf">
                    6.3.3(1) Photographs of all professional
                    development/administrative training programmes offered by
                    the Institution during the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.32.pdf">
                    6.3.3(2) Annual reports highlighting the above programmes
                    for the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.41.pdf">
                    6.3.4(1) Participation certificates of all teachers during
                    the assessment period
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.3.42.pdf">
                    6.3.4(2) Annual reports
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.4.21.pdf">
                    6.4.2(1) Audited statements by highlighting the grants
                    received from the government
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.4.22.pdf">
                    6.4.2(2) e-copies of sanction letters of all grants received
                    from government for the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.4.31.pdf">
                    6.4.3(1) Audited statements by highlighting the grants
                    received from the non-government bodies
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.4.32.pdf">
                    6.4.3(2) e-copies of sanction letters of all grants received
                    from non-government bodies for the last five years
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.5.21.pdf">
                    6.5.2(1) Documents of collaborative quality initiatives with
                    other institutions
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/6.5.22.pdf">
                    6.5.2(2) Documents related to NIRF participation
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          {/* criteria 7 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1g(!secStatus1g)}
          >
            Criterion -7 Institutional Values and Best Practices
          </button>

          {secStatus1g ? (
            <div className="inner-container">
              <ul>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.2.pdf">
                    7.1.2 Documents related to communication to the grid from
                    electricity authority
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.4-1.pdf">
                    7.1.4(1) Invoices
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.4-2.pdf">
                    7.1.4(2) Green audit reports
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.5-1.pdf">
                    7.1.5(1) Geo-tagged photographs of all facilities
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.5-2.pdf">
                    7.1.5(2) Circulars for the implementation of the initiatives
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.6-1.pdf">
                    7.1.6(1) Policy document on environment and energy usage
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.6-2.pdf">
                    7.1.6(2) Certificate from the auditing agency
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.7.pdf">
                    7.1.7 Geo-tagged photographs of all facilities
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.101.pdf">
                    7.1.10(1) Constitution and proceedings of the Code of
                    Conduct Committee
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.10-2.pdf">
                    7.1.10(2) Report on the student attributes
                  </a>
                </li>
                <li>
                  <a href="/wp-content/uploads/2023/01/7.1.10-3.pdf">
                    7.1.10(3) Web-Link to the CoC Handbook in the Institutional
                    website
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          {/* section 2 Data Validation and Verification (DVV) end*/}
        </div>



{/* Re Data validation and Verification */}



        {/* A) Extended Profile Deviations */}
        <h2 className="dpsru-secondary-heading-for-every-page" style={{color:'skyblue'}}>
         Re-Data Validation and Verification (RE-DVV)
        </h2>

        <h2 className="data-validation-and-varification-heading-inside-naac">
          (A) Extended Profile Deviations
        </h2>
        <div className="extended-profile-deviations-section-of-naac">
          <ul>
            <li>
              <a href="/wp-content/uploads/2024/02/3.2.pdf">
                3.2 {" "} Certified list of final year students by excluding Diploma/Certificate/Ph.D students for the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/3.3.pdf">
                3.3 {" "}  Certified list of students appeared in the University examinations by excluding Diploma/Certificate/Ph.D students for the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/3.4(1).pdf">
                3.4(1) {" "}   Certified list of students applied fo revaluation along with their revaluation applications by excluding  Diploma/Certificate/Ph.D students during last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/3.4(2).pdf">
                3.4(2) {" "} Certified list of students during the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/4.1.pdf">
                4.1 {" "} Certified list of courses offered by excluding Diploma/Certificate/Ph.D courses for the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.1(1).pdf">
                5.1(1) {" "} Certified letter indicating the number of eligible applications received by excluding Diploma/Certificate/Ph.D students for the last five years
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.1(2).pdf">
                5.1(2) {" "} Screenshots of admissions in ERP software
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/5.2(1).pdf">
                5.2(1) {" "} GO indicating reservation policy
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.2(2).pdf">
                5.2(2) {" "} Certified letter indicating the program-wise sanctioned seats earmarked for reserved category by excluding Diploma/Certificate/Ph.D students for the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.2(3).pdf">
                5.2(3) {" "} Certified letter indicating the program-wise admitted students against reserved category by excluding Diploma/Certificate/Ph.D students for the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.2(4).pdf">
                5.2(4) {" "} Number of actual students admitted from the reserved category during the assessment period
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.4(1).pdf">
                5.4(1) {" "} List of students for the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.4(2).pdf">
                5.4(2) {" "} Certified letter indicating location-wise computers in the University
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.4(3).pdf">
                5.4(3) {" "} Attested stock register entry by highlighting the quantity of the purchased computers
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/5.4(4).pdf">
                5.4(4) {" "} Invoices
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/5.4(5).pdf">
                5.4(5) {" "} Geo-tagged photographs of the computer labs
              </a>
            </li>
            
            {/* Extended file end here and criteria starts */}
            
           
            
          </ul>
        </div>

        <h2 className="data-validation-and-varification-heading-inside-naac">
          (B) Matrix Deviations
        </h2>
        <div className="extended-profile-deviations-section-of-naac">
          {/* criteria 1 RE-DVV*/}
          <button
            className="naac-button"
            onClick={() => setSecStatus1a(!secStatus1a)}
          >
            Criterion 1 – CurricularAspects
          </button>

          {secStatus1a ? (
            <div className="inner-container">
              <ul>
              <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(1).pdf">
              1.1.2(1) {" "} Certified list of new courses introduced along with coursse structure by highlighting the new courses during the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(2).pdf">
              1.1.2(2) {" "}Certified list of new courses introduced along with coursse structure by highlighting the new courses during the A.Y.2020-21
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(3).pdf">
              1.1.2(3) {" "}Certified list of new courses introduced along with coursse structure by highlighting the new courses during the A.Y.2019-20
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(4).pdf">
              1.1.2(4) {" "}Certified list of new courses introduced along with coursse structure by highlighting the new courses during the A.Y.2018-19
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(5).pdf">
              1.1.2(5) {" "}Certified list of new courses introduced along with coursse structure by highlighting the new courses during the A.Y.2017-18
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.2(6).pdf">
              1.1.2(6) {" "}Minutes of BoS
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.3(1).pdf">
              1.1.3(1) {" "}Syllabus copies of courses highlighting the focus on employability/ entrepreneurship/ skill development along with their course outcomes 
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.1.3(2).pdf">
              1.1.3(2) {" "}Mapping of the courses to employability / entrepreneurship / skill development
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.2(1).pdf">
              1.3.2(1) {" "}Documents for the offered value-added courses during the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.2(2).pdf">
              1.3.2(2) {" "}Documents for the offered value-added courses during the A.Y.2020-21
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.2(3).pdf">
              1.3.2(3) {" "}Documents for the offered value-added courses during the A.Y.2019-20
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.2(4).pdf">
              1.3.2(4) {" "}Documents for the offered value-added courses during the A.Y.2018-19
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.2(5).pdf">
              1.3.2(5) {" "}Documents for the offered value-added courses during the A.Y.2017-18
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.3(1).pdf">
              1.3.3(1) {" "}List of enrolled students in the offered value-added courses during the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.3(2).pdf">
              1.3.3(2) {" "}List of enrolled students in the offered value-added courses during the A.Y.2020-21
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.3(3).pdf">
              1.3.3(3) {" "}List of enrolled students in the offered value-added courses during the A.Y.2019-20
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.3(4).pdf">
              1.3.3(4) {" "}List of enrolled students in the offered value-added courses during the A.Y.2018-19
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.3(5).pdf">
              1.3.3(5) {" "}List of enrolled students in the offered value-added courses during the A.Y.2017-18
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.4(1).pdf">
              1.3.4(1) {" "}List of students participated in experiential learning in the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.4(2).pdf">
              1.3.4(2) {" "}Certificates of Project work 
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.4(3).pdf">
              1.3.4(3) {" "}Internship certificates
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.4(4).pdf">
              1.3.4(4) {" "}Documents related to filedvisits
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.3.4(5).pdf">
              1.3.4(5) {" "}Course structure by highlighting Projectwork/Fieldwork/ Internship for the A.Y.2021-22
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(1).pdf">
              1.4.1(1) {" "}Sample filled student feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(2).pdf">
              1.4.1(2) {" "}Sample filled teacher feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(3).pdf">
              1.4.1(3) {" "}Sample filled employer feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(4).pdf">
              1.4.1(4) {" "}Sample filled alumni feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(5).pdf">
              1.4.1(5) {" "}List of stakeholders
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(6).pdf">
              1.4.1(6) {" "}Direct feedback link to the HEI website
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(1).pdf">
              1.4.2(1) {" "}Sample filled student feedback forms
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(2).pdf">
              1.4.2(2) {" "}Sample filled teacher feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(3).pdf">
              1.4.2(3) {" "}Sample filled employer feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(4).pdf">
              1.4.2(4) {" "}Sample filled alumni feedback forms
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(5).pdf">
              1.4.2(5) {" "}List of stakeholders
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr01/1.4.1(6).pdf">
              1.4.2(6) {" "}Direct feedback link to the HEI website
              </a>
            </li>
           
              </ul>
            </div>
          ) : null}

          {/* criteria 1 re dvv end here */}

          {/* Criteria 2 re dvv starts here*/}
          <button
            className="naac-button"
            onClick={() => setSecStatus1b(!secStatus1b)}
          >
            Criterion 2 – Teaching-Learning and Evaluation
          </button>

          {secStatus1b ? (
            <div className="inner-container">
              <ul>
              <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(1).pdf">
              2.1.1(1) {" "}Approval letters for the sanctioned intake
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(2).pdf">
              2.1.1(2) {" "}List of Enrolled Students in A.Y 2021-22
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(3).pdf">
              2.1.1(3) {" "}List of Enrolled Students in A.Y 2020-21
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(4).pdf">
              2.1.1(4) {" "}List of Enrolled Students in A.Y 2019-20
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(5).pdf">
              2.1.1(5) {" "}List of Enrolled Students in A.Y 2018-19
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.1(6).pdf">
              2.1.1(6) {" "}List of Enrolled Students in A.Y 2017-18
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(1).pdf">
              2.1.2(1) {" "}G.O. related to reservation policy
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(2).pdf">
              2.1.2(2) {" "}List of Enrolled Students against reserved category during A.Y 2021-22
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(3).pdf">
              2.1.2(3) {" "}List of Enrolled Students against reserved category during A.Y 2020-21
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(4).pdf">
              2.1.2(4) {" "}List of Enrolled Students against reserved category during  A.Y 2019-20
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(5).pdf">
              2.1.2(5) {" "}List of Enrolled Students against reserved category during  A.Y 2018-19
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.1.2(6).pdf">
              2.1.2(6) {" "}List of Enrolled Students against reserved category during  A.Y 2017-18
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(1).pdf">
              2.4.4(1) {" "}Certified list of full-time teachers received awards along with their e-copies during the assessment period
              </a>
            </li>

            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(2).pdf">
              2.4.4(2) {" "}Teachers received awards along with their e-copies during the year 2021-22
              </a>
            </li>

            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(3).pdf">
              2.4.4(3) {" "}Teachers received awards along with their e-copies during the year 2020-21
              </a>
            </li>
            

            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(4).pdf">
              2.4.4(4) {" "}Teachers received awards along with their e-copies during the year 2019-20
              </a>
            </li>
            

            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(5).pdf">
              2.4.4(5) {" "}Teachers received awards along with their e-copies during the year 2018-19
              </a>
            </li>

            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.4.4(6).pdf">
              2.4.4(6) {" "}Teachers received awards along with their e-copies during the year 2017-18
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr02/2.6.3.pdf">
              2.6.3 {" "} Certified final-semester result analysis for the A.Y.2021-22
              </a>
            </li>
            
              </ul>
            </div>
          ) : null}

          {/* Criteria 3 re dvv starts here */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1c(!secStatus1c)}
          >
            Criterion 3 – Research, Innovations and Extension
          </button>

          {secStatus1c ? (
            <div className="inner-container">
              <ul>
              <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.2.1.pdf">
              3.2.1 {" "} CA certified audited statement by highlighting the research grants
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.4.5.xlsx">
              3.4.5 {" "} Data template
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.4.6(1).pdf">
              3.4.6(1) {" "} Certified list of books/book chapters published during the assessment period
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.4.6(2).pdf">
              3.4.6(2) {" "} e-copies of the Cover page, Content page & First page of the books/chapters and conference proceedings for the last five years
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.4.7.pdf">
              3.4.7 {" "}Geo-tagged photographs and video clips of e-content infrastructure
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.2(1).pdf">
              3.6.2(1) {" "}Documents for extension/outreach programs organised during the A.Y.2021-22
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.2(2).pdf">
              3.6.2(2) {" "}Documents for extension/outreach programs organised during the A.Y.2020-21
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.2(3).pdf">
              3.6.2(3) {" "}Documents for extension/outreach programs organised during the A.Y.2019-20
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.2(4).pdf">
              3.6.2(4) {" "}Documents for extension/outreach programs organised during the A.Y.2018-19
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.2(5).pdf">
              3.6.2(5) {" "}Documents for extension/outreach programs organised during the A.Y.2017-18
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.4(1).pdf">
              3.6.4(1) {" "}List of students participated in extension/outreach programs during the A.Y.2021-22
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.4(2).pdf">
              3.6.4(2) {" "}List of students participated in extension/outreach programs during the A.Y.2020-21
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.4(3).pdf">
              3.6.4(3) {" "}List of students participated in extension/outreach programs during the A.Y.2019-20
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.4(4).pdf">
              3.6.4(4) {" "}List of students participated in extension/outreach programs during the A.Y.2018-19
              </a>
            </li>
            <li>
              <a href="/wp-content/uploads/2024/02/cr03/3.6.4(5).pdf">
              3.6.4(5) {" "}List of students participated in extension/outreach programs during the A.Y.2017-18
              </a>
            </li>
            
              </ul>
            </div>
          ) : null}
          {/* Criteria 3 re dvv end here */}

          {/* criteria 4 re dvv starts here */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1d(!secStatus1d)}
          >
            Criterion 4 – Infrastructure and Learning Resources
          </button>

          {secStatus1d ? (
            <div className="inner-container">
              <ul>
              <li>
              <a href="/wp-content/uploads/2024/02/cr04/4.3.1(1).pdf">
              4.3.1(1) {" "}List of classrooms and Seminar Halls with ICT-enabled facilities
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr04/4.3.1(2).pdf">
              4.3.1(2) {" "}Geo-Tagged Photographs and class timetables
              </a>
            </li>
            
              </ul>
            </div>
          ) : null}
          {/* criteria 4 re dvv end here */}

          {/* criteria 5 re dvv starts here */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1e(!secStatus1e)}
          >
            Criterion 5 – Student Support and Progression
          </button>

          {secStatus1e ? (
            <div className="inner-container">
              <ul>
               
            <li>
              <a href="/wp-content/uploads/2024/02/cr05/5.3.1(1).pdf">
              5.3.1(1) {" "} e-copies of award letters/certificates in sports/cultural activities for the A.Y 2021-22
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr05/5.3.1(2).pdf">
              5.3.1(2) {" "}  e-copies of award letters/certificates in sports/cultural activities for the A.Y 2020-21
              </a>
            </li>
            
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr05/5.3.1(3).pdf">
              5.3.1(3) {" "}  e-copies of award letters/certificates in sports/cultural activities for the A.Y 2019-20
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr05/5.3.1(4).pdf">
              5.3.1(4) {" "}  e-copies of award letters/certificates in sports/cultural activities for the A.Y 2018-19
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr05/5.3.1(5).pdf">
              5.3.1(5) {" "} e-copies of award letters/certificates in sports/cultural activities for the A.Y 2017-18
              </a>
            </li>
            
              </ul>
            </div>
          ) : null}

          {/* criteria 5 end here*/}

          {/* criteria 6 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1f(!secStatus1f)}
          >
            Criterion 6 – Governance, Leadership and Management
          </button>

          {secStatus1f ? (
            <div className="inner-container">
              <ul>
                
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(1).pdf">
              6.3.4(1) {" "}List of participants along with their participation certificates during A.Y 2021-22
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(2).pdf">
              6.3.4(2) {" "}List of participants along with their participation certificates during A.Y 2020-21
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(3).pdf">
              6.3.4(3) {" "}List of participants along with their participation certificates during A.Y 2019-20
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(4).pdf">
              6.3.4(4) {" "}List of participants along with their participation certificates during A.Y 2018-19
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(5).pdf">
              6.3.4(5) {" "}List of participants along with their participation certificates during A.Y 2017-18
              </a>
            </li>
            
            <li>
              <a href="/wp-content/uploads/2024/02/cr06/6.3.4(6).pdf">
              6.3.4(6) {" "}Annual reports for the last five years
              </a>
            </li>
            
              </ul>
            </div>
          ) : null}

          {/* criteria 7 */}
          <button
            className="naac-button"
            onClick={() => setSecStatus1g(!secStatus1g)}
          >
            Criterion -7 Institutional Values and Best Practices
          </button>

          {secStatus1g ? (
            <div className="inner-container">
              <ul>
              <li>
              <a href="/wp-content/uploads/2024/02/7.1.2.mp4">
              7.1.2 {" "}Video clips for the claimed facilities
              </a>
            </li>
              </ul>
            </div>
          ) : null}

          {/* section 2 Data Validation and Verification (DVV) end*/}
        </div>
      </div>
    </div>
  );
}

export default Naac;
