import React from "react";
import '../VisionforViksitBharat2047.css'
import newblink from '../../../NEW/new.gif'
import {
  FaFacebook,
  FaTwitterSquare,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";
const VoiceOfYouthViksitBharat2047 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        {" "}
        Vision for Viksit Bharat @2047
      </h2>
      <h3 className="dpsru-secondary-heading-for-every-page">
        DELHI PHARMACEUTIAL SCIENCES AND RESEARCH UNIVERSITY
      </h3>


<div className="pmIndiaWebcastLink-viksit-bharat-link-container"> Click here {'>'} {" "}
  <a href="https://pmindiawebcast.nic.in/"  target="_blank" rel="noreferrer">https://pmindiawebcast.nic.in/</a> <img src={newblink} alt="new"/>
</div>

      <div className="social-media-container-of-viksit-bharat">
        <a
          href="https://twitter.com/dpsru_official"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitterSquare />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61553962983251"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.linkedin.com/in/dpsru-university/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.instagram.com/dpsruofficial/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagramSquare />
        </a>
      </div>

      <div className="visionforviksitBharat-container">
        <ul>
          <li>
            <b>The Indian Pharmaceuticals industry</b> has emerged globally as
            one of the fastest growing industries. India is ranked 3rd worldwide
            in the production and 13th in terms of value with over 60,000
            generic drugs in different 60 therapeutic categories and about 60%
            of the global demand for vaccines The pharma export was $25.3
            billion in the 2022-2023 financial year. It is valued to be
            approximately as US$45 billion in 2023 and is estimated to reach
            $130 billion by 2030. India It is also emerging as the world’s
            iconic centre for Traditional Medicine and even medical devices
          </li>

          <li>
            Education plays an important role as mentioned by Hon.’ble Prime
            Minister Shri Narendra Modi about starting of L. M. College of
            Pharmacy in 1947 in Ahmedabad. In Delhi we have Delhi Pharmaceutical
            Sciences & Research University as the first and only Pharmacy
            University of India and third in the world’s dedicated to
            pharmaceutical and allied health sciences. DPSRU is thriving to
            create the state-of-the-art infrastructure research facilities to
            see that it contributes to the Vision of Viksit Bharat 2047.
          </li>

          <li>
            {" "}
            In Aug 2022, DPSRU witnessed the inauguration of a fully- integrated
            State-of-the-art DPSRU-Centre for Precision Medicine and Pharmacy
            (DCPMP) with the state of the art technology in genetics, genomics,
            laboratory automation and bioinformatics funded by the Delhi
            knowledge Development Foundation (DKDF), DTTE, Govt. of NCT of
            Delhi. It has started to undertake projects in genomics. DPSRU has
            also started M.Sc. in artificial medicine (AI) and precision
            medicine. DPSRU can contribute to make India self sufficient in
            biological products.{" "}
          </li>
          <li>
            {" "}
            The University has also developed the Plant Tissue Culture
            laboratory in the department of pharmacognosy and phytochemistry. It
            is an alternative method of getting crude drugs especially when
            various plant drug species are under threat of getting extinct. The
            University has started B. Pharm. (Ayurveda) and has collaborations
            for research projects with All India Institute of Ayurveda,
            Ayurvedic & Unani Tibbia College & Hospital and Chaudhry Braham
            Prakash College of Ayurveda. Many of the faculties are in AYUSH
            committees of it divisions like CCRAS, CCRAH, and CCRUM. With the
            recognition of Bharat in traditional medicine by the WHO, DPSRU can
            significantly add value in this field.
          </li>
          <li>
            {" "}
            The University organized IASTAM (Indian Association for the System
            of Traditional Asian Medicine) Oration and award function &
            International Conclave on January 21-22, 2023, wherein Acharya
            Balkrishna, VC, Patanjali University, Haridwar was conferred Pandit
            Shiv Sharma Oration. The two-day deliberations came out with
            recommendations submitted to the Government with the respect to
            regulatory issues and solutions to promote AYUSH Industry. DPSRU can
            be a think tank for the promotion of AYUSH products.
          </li>
          <li>
            {" "}
            Regulations play an important Role in Pharma Sector, DPSRU has
            started PG course in Drug Regulatory Affairs wherein officials of
            CDSCO, New Delhi, are deputed by the Drugs Controller General of
            India. Faculty and high officials of the University are there in
            NIB, IPC and BIS also. DPSRU can provide a glittering image of
            Bharat for the harmonized regulatory ecosystem in the world.
          </li>
          <li>
            {" "}
            DPSRU Innovation and Incubation Foundation (DIIF) has 9 successful
            roll outs of start-ups that created approximately 155 Cr. equity and
            about 10 Cr external funding raised in a span of few years. DPSRU
            may become a hub for innovation in pharma industry.
          </li>
          <li>
            {" "}
            DPSRU took a lead in organizing workshop for the implementation of
            NEP-2020 in professional course with Presidents or their
            representative present. DPSRU is trying to take lead in health care
            policies implementation.
          </li>
          <li>
            {" "}
            DPSRU is the first DSIR sponsored CRTDH program in the pharmacy
            institutions after the IITs given the same. It is coming out with
            GMP certified small scale manufacturing facility and GLP certified
            analytical facilities for MSME of pharma sector.
          </li>
          <li>
            {" "}
            DPSRU is having strong in-house facilities for new Drug Development
            facilities with molecular modelling and have given a repurposed
            medicine during COVID 19. Further it is enhancing facilities to
            contribute to the API development also.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VoiceOfYouthViksitBharat2047;
