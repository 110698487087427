import React, { useState } from "react";

import profimg from "./IMG_6144-scaled-e1684304814941.jpg";

const DrSakshiBajaj = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Sakshi Bajaj</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmacognosy
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              sakshibajaj [at] dpsru [dot] edu [dot] in
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.com/citations?user=0rr8MWgAAAAJ&amp;hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.scopus.com/authid/detail.uri?authorId=57191285091">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-9742-2765">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        D.Pharm, B.Pharm, M.Pharm & Ph.D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Isolation, identification, characterization of compounds,
        Standardization, quality control and evaluation of biological activities
        of herbal drugs
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <b>Published Paper</b>
          </p>
          <p>
            <b>Publications Details of Dr. Sakshi </b>
            <b>Bajaj</b>
          </p>
          <p>
            1.Chon 
            <b>Chee </b>
            Siang, 
            <b>nagaraja </b>
            Suryadevara
            , 
            <b>Chandrasekaran Selvaraj</b>
            <b>, </b>
            V.V.S.S Appalaraju, 
            <b>Usha</b>
          </p>
          <p>
            Kumari 
            <b>Sakshi </b>
            <b>Bajaj. </b>
            Antioxidant and antibacterial response 
            <b>of </b>
            hydroalcoholic extracts 
            of
          </p>
          <p>
            <b>Plumeria </b>
            <b>alba </b>
            <b>leaves in International </b>
            Journal 
            <b>of Health Sciences</b>
            , 2022, 
            <b>6</b>
            (S1), 
            <b>13858-76</b>
            . 
            <b>IF-</b>
          </p>
          <p>
            <b>0.23</b>
          </p>
          <ol start="2">
            <li>
              <b>Bernice Yii </b>
              <b>Shu </b>
              Ting
              <b>, Neeraj </b>
              Kumar 
              Fuloria, 
              <b>Vetriselvan Subramanian</b>
              , 
              Sakshi 
              <b>Bajaj, </b>
              Suresh
            </li>
            <li>
               Chini Lebaka Veeranjaneva 
              <b>Reddy</b>
              , 
              Kathiresan 
              <b>Sathasivam</b>
              , 
              <b>Sundram </b>
              <b>Karupiah</b>
              <b>, </b>
              <b>Rishabha</b>
            </li>
          </ol>
          <p>
            Malviya
            , 
            Dhanalekshmi Unnikrishnan Meenakshi, Neeraj Paliwal
            <b>, </b>
            Krishna Priya
            <b>, </b>
            Shivkanya
          </p>
          <p>
            Fuloria
            . 
            <b>Biosynthesis </b>
            and response 
            of zinc 
            <b>oxide </b>
            nanoparticles 
            <b>against </b>
            peri-implantitis 
            <b>triggering</b>
          </p>
          <p>
            pathogens 
            <b>in Materials</b>
            , 2022
            , 15
            <b>, 3170,1-13</b>
            . 
            IF-3.748
          </p>
          <ol start="3">
            <li>
               Poorti Sharma
              <b>, </b>
              <b>Sakshi </b>
              <b>Bajaj</b>
              , 
              <b>Shivkanya Fuloria, Omji Porwal</b>
              , Vetriselvan 
              Subramaniyan,
            </li>
          </ol>
          <p>
            Mehmet ozdemir, Unnikrishnan Meenakshi D, Narra 
            <b>Kishore</b>
            , 
            <b>Neeraj </b>
            Kumar 
            <b>Fuloria</b>
            .
          </p>
          <p>
            <b>Ethnomedicinal </b>
            and Pharmacological 
            <b>Uses </b>
            of 
            <b>
              <i>Curcuma </i>
            </b>
            <b>
              <i>Caesia </i>
            </b>
            in 
            <b>Natural </b>
            <b>Volatiles </b>
            <b>&amp;</b>
          </p>
          <p>
            Essential 
            Oils
            <b>, </b>
            2021; 
            <b>8</b>
            (4): 14902-14910.IF
            <b>–</b>
            0.74
          </p>
          <ol start="4">
            <li>
              <b> </b>
              <b>Sakshi </b>
              Bajaj, 
              Shivkanya Fuloria, Vetriselvan 
              <b>subramaniyan</b>
              , 
              Dhanalekshmi 
              Unnikrishnan
            </li>
          </ol>
          <p>
            Meenakshi
            , Sharad 
            <b>Wakode, </b>
            <b>Avneet Kaur, </b>
            himangini 
            <b>bansal</b>
            <b>, </b>
            <b>Satish Manchanda, </b>
            Sachin 
            kumar
            <b>,</b>
          </p>
          <p>
            and Neeraj kumar Fuloria
            . 
            Chemical Characterization and 
            anti
            <b>–</b>
            <b>inflammatory </b>
            activity of
          </p>
          <p>
            phytoconstituents from 
            <b>
              <i>Swertia </i>
            </b>
            <b>
              <i>alata </i>
            </b>
            <b>in </b>
            Plants 
            2021,10,1109
            <b>. </b>
            <b>(</b>
            <b>IF</b>
            -4.658
            <b>)</b>
            .
          </p>
          <ol start="5">
            <li>
              <b> Sakshi </b>
              <b>Bajaj, </b>
              Poorti 
              <b>Sharma</b>
              <b>, </b>
              <b>Nishant Bharti</b>
              , 
              <b>Homeopathic </b>
              treatment of 
              <b>Leishmaniasis: A</b>
            </li>
          </ol>
          <p>
            Review
            , 
            <b>International Journal of </b>
            <b>Pharmaceutical </b>
            <b>Progress </b>
            and 
            <b>health Sciences </b>
            <b>2021, Vol 1</b>
          </p>
          <p>
            <b>(1), 23-27</b>
            .
          </p>
          <ol start="6">
            <li>
              <b> </b>
              Shivkanya 
              Fuloria, Vetriselvan 
              Subramaniyan
              , Sundram 
              Karupiah, 
              <b>Usha </b>
              Kumari, 
              <b>Kathiresan</b>
            </li>
          </ol>
          <p>
            Sathasivam, 
            Dhanalekshmi 
            Unnikrishnan 
            <b>Meenakshi</b>
            , Yuan Seng 
            <b>Wu</b>
            , 
            <b>Mahendran </b>
            Sekar, 
            <b>Nitin</b>
          </p>
          <p>
            Chitranshi
            , 
            <b>Rishabha </b>
            Malviya
            <b>, Kalvatala </b>
            Sudhakar
            , 
            <b>Sakshi Bajaj </b>
            and 
            <b>Neeraj </b>
            Kumar 
            Fuloria
            .
          </p>
          <p>
            Comprehensive 
            <b>Review </b>
            on Methodology to Detect Reactive Oxygen Species (
            <b>ROS</b>
            ) in
          </p>
          <p>
            <b>Mammalian </b>
            Species and 
            <b>Establish </b>
            its relationship with Antioxidants 
            <b>and </b>
            Cancer in
          </p>
          <p>
            <b>Antioxidants</b>
            , 2021, 10(1), 
            <b>128. IF-</b>
            7.675
            .
          </p>
          <ol start="7">
            <li>
              <b> Sakshi Bajaj</b>
              , Sharad Wakode, Avneet Kaur
              , Shivkanya 
              <b>Fuloria, </b>
              Neeraj Fuloria. Anti-
            </li>
          </ol>
          <p>
            <b>inflammatory and </b>
            ulcerogenic activity of newer 
            <b>phytoisolates </b>
            of 
            <i>
              Swertia 
            </i>
            <b>
              <i>alata </i>
            </b>
            <b>C.B. </b>
            <b>Clarke </b>
            in
          </p>
          <p>
            <b>Natural product </b>
            research
            , 2020
            <b>; </b>
            1-11. IF
            <b>-2.488</b>
            .
          </p>
          <ol start="8">
            <li>
              <b>Sachin </b>
              kumar, 
              <b>Faizana </b>
              Fayaz, Faheem potto, 
              <b>Sakshi Bajaj</b>
              , 
              Satish 
              <b>Manchanda</b>
              <b>, </b>
              Himangini
            </li>
          </ol>
          <p>
            
              bansal. Nanophytomedicine Based Novel Therapeutic Strategies{" "}
            
            <b>in </b>
            Liver Cancer. 
            <b>Current</b>
          </p>
          <p>
            <b>Topics in Medicinal Chemistry, 2020; 20</b>
            (22)
            :
            1999-2024. IF-3.57
            .
          </p>
          <ol start="9">
            <li>
              <b>Deepak Tayal, </b>
              <b>Sakshi Bajaj</b>
              . 
              <b>Diabetes mellitus </b>
              and 
              <b>treatment </b>
              of 
              <b>T2DM with </b>
              SGIT
              <b>-2</b>
            </li>
          </ol>
          <p>
            Inhibitors and 
            <b>DPP</b>
            <b>–</b>
            4 Inhibitors in combined 
            <b>tablet </b>
            dosage forms
            <b>. International </b>
            Journal of
          </p>
          <p>
            <b>Innovative </b>
            <b>Science, </b>
            <b>Engineering </b>
            <b>&amp; </b>
            Technology
            . 
            Vol.7 
            <b>Issue </b>
            7, 2020:177-197.IF
            <b>–</b>
            0.611
            .
          </p>
          <ol start="10">
            <li>
               Rajput 
              A 
              Gupta A, Vashist N, 
              <b>Bajaj S, </b>
              Gahlot V, 
              <b>Yadav </b>
              S, 
              <b>Maratha </b>
              S. Can Food
            </li>
          </ol>
          <p>
            Supplements 
            <b>Combat Cancer</b>
            : An 
            <b>Insight</b>
            . 
            Indo Global Journal of Pharmaceutical 
            <b>Sciences</b>
          </p>
          <p>
            (
            <b>IGJPS), </b>
            2019: 9(1)
            <b>; </b>
            32-41
            . 
            <b>IF</b>
            –
            <b>0.675</b>
          </p>
          <ol start="11">
            <li>
              Poorva 
              <b>Satija, Pankaj </b>
              Chakravorty, 
              <b>Sakshi </b>
              Bajaj
              <b>, Faraat </b>
              Ali. Arthritis: 
              A 
              General Perception
            </li>
          </ol>
          <p>
            <b>on </b>
            the 
            <b>Prevalence </b>
            of the 
            <b>Disease </b>
            in 
            Indian 
            <b>Population</b>
            . 
            Scholars Academic 
            Journal 
            of 
            <b>Pharmacy</b>
          </p>
          <p>
            <b>(SAJP)</b>
            , 
            2019
            <b>; 8(7): 345-349</b>
            <b>. </b>
            <b>2347-9531</b>
            ,
          </p>
          <ol start="12">
            <li>
              <b>Sakshi Bajaj</b>
              , 
              Sharad Wakode, Washim Khan, Sachin Kumar, 
              <b>Satish </b>
              Manchanda,
            </li>
          </ol>
          <p>
            Simultaneous 
            <b>HPTLC </b>
            analysis and 
            <i>
              In
            </i>
            <b>
              <i>–</i>
            </b>
            <i>
              vitro 
            </i>
            <b>antileishmanial </b>
            activity of various 
            <b>secondary</b>
          </p>
          <p>
            metabolites 
            present 
            in 
            the 
            extract of the traditional 
            medicinal 
            herb 
            <b>
              <i>Artabortrys </i>
            </b>
            <b>
              <i>hexapetalus</i>
            </b>
            <b>. </b>
            Ayu,
          </p>
          <p>
            <b>2018; 39: </b>
            92-100
            .
          </p>
          <ol start="13">
            <li>
              <b> </b>
              Lata Mangesh 
              <b>Bhati</b>
              <b>, Sakshi </b>
              <b>Bajaj, </b>
              The 
              Pharmacognostic, Standardization
              , 
              Phytochemistry
            </li>
          </ol>
          <p>
            and Phytopharmacological Potential of 
            <i>
              Toona ciliata 
            </i>
            <b>M- </b>
            An Overview
            <b>, </b>
            Scholars 
            <b>Academic</b>
          </p>
          <p>
            <b>Journal of </b>
            <b>Pharmacy </b>
            (
            <b>SAJP)</b>
            , Jun 
            2018; 7(
            <b>6)</b>
            : 
            <b>284-290</b>
            . 
            <b>2347-9531</b>
          </p>
          <ol start="14">
            <li>
              <b>Bajaj </b>
              <b>Sakshi, </b>
              Wakode Sharad, 
              <b>Comparative </b>
              <b>
                <i>in-vitro </i>
              </b>
              antioxidant
              , 
              <b>anti-</b>
              <b>inflammatory and</b>
            </li>
          </ol>
          <p>
            antidiabetic 
            activity 
            <b>of </b>
            standardized polar 
            extracts 
            <b>of </b>
            <b>
              <i>S </i>
            </b>
            <b>
              <i>alata</i>
            </b>
            <b>
              <i>. </i>
            </b>
            MOJ 
            <b>drug </b>
            design 
            <b>development and</b>
          </p>
          <p>
            <b>therapy</b>
            , 
            <b>2018; </b>
            2(3): 
            151-155.
          </p>
          <ol start="15">
            <li>
              <b> Sakshi </b>
              <b>Bajaj</b>
              , 
              Vijender 
              <b>Singh</b>
              , Mohammed Ali
              , 
              Triterpenic glycosides 
              from 
              <i>
                Swertia 
              </i>
              <b>
                <i>chirata</i>
              </b>
              .
            </li>
          </ol>
          <p>
            Ancient Science of 
            <b>Life (</b>
            <b>ASL</b>
            <b>), </b>
            2017
            <b>, 37 </b>
            (1)
            <b>, </b>
            37-44
            .
          </p>
          <ol start="16">
            <li>
              <b> </b>
              Sakshi 
              <b>Bajaj and </b>
              Sharad Wakode, 
              Pharmacognostical 
              <b>evaluation </b>
              and 
              anthelmintic 
              activity
            </li>
          </ol>
          <p>
            <b>of </b>
            <i>
              Swertia 
            </i>
            <b>
              <i>alata </i>
            </b>
            <i>
              Royle
            </i>
            <b>
              <i>, </i>
            </b>
            <b>International </b>
            Journal of 
            Pharmaceutical 
            Sciences 
            and Research 
            <b>(</b>
            <b>IJPSR</b>
            <b>)</b>
          </p>
          <p>
            2017; 
            <b>8(8): 3315-3324</b>
            .
          </p>
          <ol start="17">
            <li>
              <b> </b>
              <b>Sakshi Bajaj</b>
              , 
              Sharad 
              <b>Wakode </b>
              and 
              <b>Washim </b>
              <b>Khan</b>
              , HPTLC 
              <b>analysis </b>
              <b>and </b>
              antiproliferative
            </li>
          </ol>
          <p>
            effect 
            <b>of </b>
            various 
            <b>extracts </b>
            of 
            <i>
              Swertia 
            </i>
            <b>
              <i>alata </i>
            </b>
            on the 
            <b>growth </b>
            of 
            <b>
              <i>Leishmania </i>
            </b>
            <b>
              <i>donovani </i>
            </b>
            <b>Promastigotes</b>
          </p>
          <p>
            <b>
              <i>In-vitro. </i>
            </b>
            <b>Pharmacognosy </b>
            <b>Journal </b>
            <b>(</b>
            <b>PJ), </b>
            2017; 9
            <b>(6) </b>
            suppl
            : 
            <b>s107-</b>
            s116.IF-1.074
            .
          </p>
          <ol start="18">
            <li>
              <b> </b>
              <b>Bajaj </b>
              Sakshi 
              and 
              Wakode 
              <b>S, </b>
              Effect of 
              extraction 
              solvent on 
              <b>total </b>
              phenol content, 
              <b>total</b>
            </li>
          </ol>
          <p>
            <b>flavonoid </b>
            content, 
            the 
            <b>antioxidant </b>
            <b>and anti</b>
            <b>–</b>
            inflammatory activity of 
            <i>
              Artabortrys 
            </i>
            <b>
              <i>hexapetalus</i>
            </b>
            <b>
              <i>,</i>
            </b>
          </p>
          <p>
            International journal of biology, 
            <b>pharmacy </b>
            and 
            <b>allied </b>
            Sciences (IJBPAS), 2017, 6
            <b>(8)</b>
            :1562-
          </p>
          <p>
            1577.IF
            <b>–</b>
            0.66
            .
          </p>
          <ol start="19">
            <li>
              <b> Sakshi </b>
              <b>Bajaj, </b>
              Sharad 
              <b>Wakode, </b>
              <b>Evaluation </b>
              of 
              <b>total </b>
              <b>phenolic, </b>
              flavonoid
              , 
              <b>
                <i>Invitro </i>
              </b>
              <b>antioxidant</b>
            </li>
          </ol>
          <p>
            potential and anti
            –
            <b>inflammatory activity </b>
            of successive extracts of 
            <b>
              <i>Swertia alata</i>
            </b>
            . 
            <b>Bulletin </b>
            of
          </p>
          <p>
            environment 
            pharmacology 
            <b>(BEPLS</b>
            <b>), </b>
            2017, 6(9
            <b>), </b>
            73-81
            .
          </p>
          <ol start="20">
            <li>
               S Kumar
              <b>, S </b>
              <b>Bajaj</b>
              , RB Bodla, Practical screening methods in 
              <b>cancer</b>
              , Indian Journal of
            </li>
          </ol>
          <p>
            Pharmacology 
            (
            <b>IJP)</b>
            , 
            2016
            , 
            48
            <b>(5)</b>
            , 
            <b>481-6.IF-2.833</b>
          </p>
          <ol start="21">
            <li>
              <b>Bajaj </b>
              <b>Sakshi</b>
              , 
              <b>Wakode </b>
              Sharad, Standarization of 
              <b>
                <i>Artabortry </i>
              </b>
              <b>
                <i>shexapetalus, </i>
              </b>
              International
            </li>
          </ol>
          <p>
            Journal 
            of 
            <b>Pharmacognosy and </b>
            Phytochemical Research 
            <b>(</b>
            <b>IJPPR) </b>
            2016; 8(3)
            : 
            <b>398-406.IF</b>
            -0.129
            ,
          </p>
          <p>
            <b>0975 4873</b>
          </p>
          <p>
            <b>Book Chapters</b>
          </p>
          <ol>
            <li>
              <b> Sakshi </b>
              <b>Bajaj</b>
              <b>, Satish </b>
              <b>Manchanda </b>
              <b>‘Nutraceutical </b>
              and 
              <b>functional </b>
              foods 
              <b>in </b>
              <b>cancer management</b>
            </li>
          </ol>
          <p>
            and 
            <b>therapy</b>
            ‘ In 
            <b>Nutraceuticals in disease prevention</b>
            . Edited by 
            <b>Raj </b>
            K. 
            <b>Keservani</b>
            , Anil K.
          </p>
          <p>
            Sharma
            , 
            Rajesh K. Kesharwani
            , 
            IGI Global International Publisher, Pennsylvania, 
            <b>USA</b>
            ,
          </p>
          <ol start="9781522532675">
            <li>
              <b>9781522532675</b>
              <b>.</b>
            </li>
            <li>
              <b> </b>
              Sakshi 
              <b>Bajaj, </b>
              Himangini 
              <b>Bansal </b>
              ‘Aromatic medicine
              <b>‘ In Herbal </b>
              Product 
              Development
              <b>. </b>
              Edited
            </li>
          </ol>
          <p>
            <b>by Raj </b>
            <b>K. Keservani</b>
            <b>, Anil </b>
            <b>K. Sharma</b>
            , 
            <b>Rajesh </b>
            <b>K. Kesharwani</b>
            <b>, </b>
            Apple 
            Academic 
            <b>Press</b>
            , Exclusive
          </p>
          <p>
            <b>worldwide </b>
            distribution by 
            <b>CRC </b>
            Press
            , 
            a Taylor 
            <b>&amp; </b>
            Francis Group, Chapter 
            <b>Chapter </b>
            10
            |
            <b>35 </b>
            <b>pages</b>
            ,
          </p>
          <p>
            <b>1st </b>
            Edition
            <b>, </b>
            Pub. 
            <b>Location New York</b>
            .
          </p>
          <ol start="3">
            <li>
               Himangini 
              Bansal
              , 
              <b>Sakshi Bajaj </b>
              ‘
              Herbal supplements and Health’ In 
              <b>Herbal Product</b>
            </li>
          </ol>
          <p>
            <b>Development</b>
            . 
            Edited by 
            <b>Raj </b>
            K. 
            <b>Keservani</b>
            , Anil 
            <b>K. </b>
            Sharma, 
            <b>Rajesh </b>
            K. 
            <b>Kesharwani</b>
            , 
            <b>Apple</b>
          </p>
          <p>
            <b>Academic </b>
            Press, Exclusive 
            <b>worldwide </b>
            <b>distribution </b>
            by 
            <b>CRC </b>
            Press
            <b>, </b>
            a Taylor &amp; 
            <b>Francis </b>
            Group
            ,
          </p>
          <p>
            Chapter 
            319 pages, 
            1st 
            Edition
            <b>, Pub</b>
            . 
            <b>Location New York</b>
            .
          </p>
          <ol start="4">
            <li>
              <b> Sakshi </b>
              <b>Bajaj, </b>
              <b>Faizana </b>
              Fayaz, Bhumika 
              <b>Kumar</b>
              <b>, </b>
              Shivkanya 
              <b>Fuloria, </b>
              <b>Neeraj </b>
              Kumar 
              <b>Fuloria</b>
              ,
            </li>
          </ol>
          <p>
            Faheem 
            Hyder Pottoo 
            book 
            <b>chapter </b>
            entitled 
            ‘
            Bioactive 
            <b>Nutraceuticals </b>
            for Amyotrophic Lateral
          </p>
          <p>
            <b>Sclerosis</b>
            ” to 
            <b>be </b>
            published in 
            <b>the </b>
            book “Exploring 
            <b>the </b>
            Efficacy and Beneficence of
          </p>
          <p>
            Complementary 
            <b>and </b>
            <b>Alternative Medicinal </b>
            Products 
            <b>in </b>
            Disease 
            <b>Therapy</b>
            ” by IGI Global. This
          </p>
          <p>
            <b>book </b>
            chapter 
            <b>is </b>
            accepted
            .
          </p>
          <ol start="5">
            <li>
              <b> </b>
              Sakshi 
              <b>Bajaj, </b>
              Supriya 
              <b>Singh</b>
              <b>, Prateek </b>
              <b>sharma </b>
              book 
              <b>chapter </b>
              <b>entitled </b>
              ‘
              <b>Role of antioxidants in</b>
            </li>
          </ol>
          <p>
            <b>neutralizing oxidative </b>
            stress’ 
            to be 
            <b>published </b>
            <b>in </b>
            the book 
            <b>
              <i>‘</i>
            </b>
            <b>
              <i>Nutraceutical </i>
            </b>
            <b>
              <i>Fruits </i>
            </b>
            <b>
              <i>and Foods </i>
            </b>
            <b>
              <i>for</i>
            </b>
          </p>
          <p>
            <b>
              <i>Neurodegenerative Disorders</i>
            </b>
            ‘ 
            <b>by </b>
            <b>Elsevier</b>
            . 
            <b>This </b>
            book chapter is accepted
            .
          </p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>Young Researcher Award 2019</li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrSakshiBajaj;
