import React from 'react'

import bPharm from './Placement Record 2021- 2022 Files/BPHARM-21-22.pdf'
import mba from './Placement Record 2021- 2022 Files/MBA-21-22.pdf'
import mph from './Placement Record 2021- 2022 Files/MPH-21-22.pdf'
import mPharm from './Placement Record 2021- 2022 Files/MPHARM-2021-22.pdf'

const PlacementRecord20212022 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Placement Record 2021- 2022</h2>

    <div className="results-data-link-container">
      <ul>
        <li>
          <a href={bPharm}>
          B. Pharm 
          </a>
        </li>
        <li>
          <a href={mba}>
          MBA
          </a>
        </li>
        <li>
          <a href={mph}>
          MPH
          </a>
        </li>
        <li>
          <a href={mPharm}>
          M.PHARM 
          </a>
        </li>
      
     
      </ul>
    </div></div>
  )
}

export default PlacementRecord20212022