import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye, FaUserSecret, FaLock } from "react-icons/fa";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = sessionStorage.getItem("admin");
    if (auth) {
      navigate("/admin/gravience-form-data");
    }
  }, []);

  const handleLogin = async () => {
    console.warn(`email ${email} \n Password ${password}`);

    let result = await fetch("http://localhost:8080/dpsru-login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();

    console.warn(result);
    if (result.name) {
      sessionStorage.setItem("admin", JSON.stringify(result));
      navigate("/admin/gravience-form-data");
    } else {
      alert("Enter Correct Details");
    }
  };

  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-secondary-heading-for-every-page login-heading">
        Log In
      </h2>

      <div className="dpsru-login-container">
        <div className="input-box-each-container-login">
          <FaUserSecret className="icon-for-login-user-password" />
          <input
            type="text"
            placeholder="Enter User Id"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ textTransform: "lowercase" }}
          />
        </div>

        <div className="input-box-each-container-login input-box-container-for-password-admin">
          <FaLock className="icon-for-login-user-password icon-for-login-user" />
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-admin-dpsru-password-inpbox"
            required
          />

          {/* show password button */}
          {showPassword ? (
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="show-hide-button"
            >
              <FaEyeSlash />
            </button>
          ) : (
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="show-hide-button"
            >
              <FaEye />
            </button>
          )}
        </div>

        <button className=" btn-login-dpsru" onClick={handleLogin}>
          Login
        </button>

        {/* <div className="sign-up-container-in-login-page"> <span className="dont-have-account-text">Don't Have Account? {" "}</span>
        <NavLink
          to="/admin/admin-signup"
          className="signup-button-in-login-page"
        >
          Signup
        </NavLink>
      </div> */}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Login;
