import React, { useState } from "react";

import profimg from "./IMG_0054-scaled-e1686561325717-150x150.jpg";

const MsSakshiSudha = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Ms. Sakshi Sudha
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Sports Science 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a href="mailto:">
              {" "}
            
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Perusing Ph.D. from Punjab University, Chandigarh</li><li>UGC Net Qualified (November’ 2017 and July’ 2018)</li><li>Masters of Physical Education (M.P.Ed) from Panjab University, Chandigarh 2017(Ist Div.).</li><li>Bachelors of Physical Education (B.P.Ed. 4year Integrated Course) from Panjab University, Chandigarh 2015 (Gold Medalist).</li></ul>
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Academic Area:</b> Sports and Event Management, Sports and Exercise Nutrition, Research and Statistics in sports, Sports Sociology, Yoga Education
      </p>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Sports Interest:</b> Badminton, Yoga, Taekwondo, Gymnastics, Track and Field
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">
      <ul><li>Presented a research paper on “Impact of General Well-being of Chandigarh teachers on their Job Satisfaction”2023</li><li>Physical Exercise and yoga: as an alternative approach towards COVID-19</li><li>Study of performance of Indian Male Wrestling Players in Wrestling</li><li>Study of Anthropometric Characteristic and Body Composition of Indian Wrestlers.</li></ul>
      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Conferences
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
       <ul><li>Attended an International Conference Physical Education &amp; Exercise Sciences dated 22 nd  to 24 th  March, 2023 held at Punjabi University, Patiala </li><li>Sports India Conference 2022: “India- the Emerging sporting Superpower”.</li><li>Participated in Seminar on “Awareness of Flag Code of India”.</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <ul><li>Worked as Self Defence Trainer for Girls under Sarva sikhsha Abhiyan at senior secondary school Sector 18 Chandigarh.</li><li>Worked as Self Defence Trainer for Girls under Sarva sikhsha Abhiyan and Rashtriya Madhyamik Shiksha Abhiyan at Govt. senior secondary school Sector 37 Chandigarh.</li></ul>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
       <ul><li>Represented as Badminton coach for All India Inter University in The Maharaja Bhupinder Singh Punjab sports university, Patiala.</li><li>Represent as a technical official in all India aquatic inter-university championships held at Panjab university Chandigarh.</li><li>Represent as technical official in all India IISER inter-university championship held at IISER Mohali.</li><li>Represent as technical official in taekwondo championship.</li></ul><ul><li>Panjab University Gold Medallist in B.P.Ed.</li><li>Received role of honor in academics in B.P.Ed.</li></ul><ul><li>Participated in AIU Artistic Gymnastics Championship.</li><li>Guest lecture on yoga at Amity University, Noida.</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default MsSakshiSudha;
