import React, { useState } from "react";

import profimg from "./gagandeep-mam.jpg";

const DrGagandeepKaur = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Gagandeep Kaur
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor & Nodal Officer| Department of Sports Science 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              kaurbakshi92 [at] gmail [dot] com
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>PhD Doctorate Degree on entitled “Recovery Modalities and Lactic acid response of Hockey Enrolled in 2020 players to Endurance Training : A Diagnostic Study” from Sri Guru Granth Sahib World University, Fatehgarh Sahib, Punjab</li><li>Masters of Physical Education Punjabi University Studied Physical Education  Sports. Passed 2014 – 2016 (4 Semester) Patiala with 85%</li><li>Bachelor of Physical Education Punjabi University Scored 75% and studied Sports  Physical 2010 – 2014 (4 Year)Patiala, Education.</li></ul>
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Academic Area:</b> Research and Statistics, Sports and Exercise Psychology, Health Education, Sports Medicine, Fitness, Wellness and Aerobics, Performance Analysis and Sports Technology
      </p>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Sports Interest:</b> Athletics, Soft Ball, Yoga, Gymnastics, Gym Management
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">
      <ul><li>Changes in the blood lactate responses after the massage and stretching , 2019</li><li>Advance pedagogical approaches encompasses teacher thinking doing an impetrative appraisal, 2016</li><li>A Comparative study of self-esteem and Body Imaging among Physically Active and Non Active Women, 2015</li><li>Menstrual Cycle and the sports performance of female athletes  Long Jumpers, 2014</li><li>A Comparative Study of Difference in Explosive Strength in Athletes of 100 Meter, 400 Meter, 2013</li><li>Presented a research paper on “Kinematic Variables and Its Relationship with Performance of Hang Style In Long Jump”2023</li></ul>
      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Conferences
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
         <ul><li>Attended a International Conference Physical Education  Exercise Sciences dated 22nd to 24th&nbsp; March, 2023 held at Punjabi University, Patiala.</li><li>International Conference on psychology in contemporary sports, health and fitness perspective: 2019</li><li>GLOCOSPES 2016: Global Conference on Scientific Culture in Physical Education and Sports</li><li>ICSHS 2015: International Conference on Sports, Health and Sports Economic</li><li>ICFTPE 2013: International Conference on Futuristic Trends in Physical Education National Conference on Wellness through Physical Activity 2014: Future Perspective GLOPEHSS2013: Global Scientific Conference on Physical Education, Health  Sports Sciences</li><li>Excellent knowledge of computer  MS-Office (obtained Diploma in Computer Applications Prog.)</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <ul><li>Worked as a gym trainer for 45 days.</li><li>Six-week internship training in senior secondary (Delhi)</li><li>Two year teaching experience as an Assistant Professor in Department of Physical Education and Sports Technology at World University Fathegarh Sahib, Punjab.</li><li>One year experience in school teaching CBSE Board Class12th</li></ul>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul><li>Invited as key speaker on “ Coping Tips from Stress management” in Short Course on Stress Management Sponsored by HRDC at Guru Nanak Dev University, Amritsar on 15th Jan, 2023, in New Delhi</li><li>Deputy Editor of “International Journal of Health Exercise and Sports Science” Peer reviewed Journal</li><li>Inter Zone Gold Medallist in Kho-Kho</li><li>Inter College Silver Medallist in Baseball and Softball</li><li>Inter College and inter college Bronze Medallist in Gymnastics</li><li>Gold Medal in Amity Spardha in Volleyball in</li><li>29th Punjab State Sub-Junior Gymnastics Championship from 5th 6th November,</li><li>51st Punjab State Junior Gymnastics Championship from 15th 16th November,</li><li>52nd Punjab State Junior Gymnastics Championship from 28th to 29th November, 2014.</li><li>Gymnastics Federation of India appointed technical official in Rhythmic and Artistic Gymnastic in 2017-18</li><li>Gymnastics Federation of India appointed technical official in Rhythmic and Artistic Gymnastic in 2018-19</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrGagandeepKaur;
