import React, { useState } from "react";

import profimg from "./IMG_6141-scaled-e1684304999121.jpg";

const DrManjuVyasSingh = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Manju Vyas Singh</h2>
      <h3 className="facult-designation-for-each-container">
        Associate Professor | Department of Pharmacognosy
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              manjuvyassingh05 [at] gmail [dot] com
         
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.co.in/citations?view_op=list_works&hl=en&hl=en&user=JvdIWkoAAAAJ">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              {" "}
              <a>Scopus</a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0003-1664-8680">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M.Pharm (Pharmacognosy & Phytochemistry)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Standardization and Evaluation of Biological Activities
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <b>Publications</b>
          </p>
          <ol>
            <li>
              <span>
                Singh S, Vyas M. Comparative in-vitro biological study of aerial
                parts of plant Hamelia patens. International Journal of
                Pharmaceutical Sciences and Research. 2016 Apr 1;7(4):1793.
              </span>
            </li>
            <li>
              <span>
                Chorsiya A, Singh MV, Khasimbi S. Fernandoa Adenophylla: A
                review of its Phytochemistry, Traditional and Pharmacology use
                and Future Aspects. Current Traditional Medicine. 2021 Jun
                1;7(3):348-54.
              </span>
            </li>
            <li>
              <span>
                Bhati P, Jain J, Singh L, Vyas M, Niyaz A, Kaushik R.
                Ameliorative effect of sodium valproate in combination with
                juglans regia fruit extract against pentylenetetrazole induced
                convulsions in mice. Journal of Applied Pharmaceutical Sciences
                and Research. 2018 Jan 1:23-8.
              </span>
            </li>
            <li>
              <span>
                Savita N, Singh MV. A comprehensive review on aromatherapy.
              </span>{" "}
              <span>
                World Journal of Pharmaceutical Research. 2021; 10(12):771-777.
              </span>
            </li>
            <li>
              <span>
                Singh MV Savita N, Kumar B, Aggarwal D. A Comprehensive Review
                on Annona Squamosa: Nature’s Gift International Journal of
                Pharmaceutical Progress Health Sciences.2021 1.
              </span>
            </li>
            <li>
              <span>
                Kajal Jain, Manju Vyas Singh, Uma Agarwal. Phytochemistry and
                Pharmacological Activities of Bauhinia variegata Var. Candida.
              </span>{" "}
              <span>Sch Acad J Pharm, April, 2019; 8 (4): 105–114.</span>
            </li>
            <li>
              <span>
                Parveen S, Singh MV. Pharmacognostic and preliminary
                phytochemical study of alstonia scholaris fruits (l) r. br. fam:
                apocynaceae.Pharmatutor. October 2012.
              </span>
            </li>
            <li>
              <span>
                Kaushik C, Singh MV. An updated phytopharmacological review on
                hamelia patens jacq.
              </span>{" "}
              <span>IJP. 2020; 7(3).</span>
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
        
        </div>
      ) : null}
    </div>
  );
};

export default DrManjuVyasSingh;
