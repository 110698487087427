import React, { useState } from "react";

import profimg from "./IMG_6485-scaled-e1685347032993.jpg";

const DrSheetalKalra = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] = useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Sheetal Kalra</h2>
      <h3 className="facult-designation-for-each-container">
      Associate Professor & Head (PG), school of physiotherapy
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
              sheetalkalra [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=SGOZYYgAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a>Scopus</a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0003-3317-4624">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        BPT,MPT,PhD
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        17 Years 9 Months
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Clinical Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        6 Months
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research Area
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <p>
            Health and Fitness, Non Communicable diseases, Sports Biomechanics,
            Sports Injury Prevention, Women Health, Ergonomics
          </p>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <strong>PAPER PUBLICATIONS</strong>
          </p>
          <ol>
            <li>
              Swain L, Kalra S*, Rai R, Raghav D. Effect of scapular
              strengthening exercises on rounded shoulder and neck disability
              among college students of Delhi NCR. Comparative Exercise
              Physiology. 2023:1-8.
            </li>
            <li>
              Ajmera P, Miraj M, Kalra S*, Goyal RK, Chorsiya V, Shaik RA,
              Alzhrani M, Alanazi A, Alqahtani M, Miraj SA, Pawaria S. Impact of
              telehealth interventions on physiological and psychological
              outcomes in breast cancer survivors: A meta-analysis of randomised
              controlled trials. Frontiers in oncology. 2023 Jan 5;12:1017343.
            </li>
            <li>
              Alam S, Kalra S*, Rai R, Raghav D. Frequency and Associated
              Factors of Physical Activity and Sleep with Depression in College
              Going Students. Journal of the Dow University of Health Sciences
              (JDUHS). 2023 Feb 20;17(1):17-24.
            </li>
            <li>
              Kalra S, Aggarwal G, Pawaria S, Yadav S, Ajmera P. Psychological
              health of postmenopausal women: a bibliometric analysis in the
              recent decade. Climacteric. 2023 Apr 27:1-9.
            </li>
            <li>
              Sharma F, Kalra S*, Rai R, Chorsiya V, Dular S. Work-related
              Musculoskeletal Disorders, Workability and its Predictors among
              Nurses Working in Delhi Hospitals: A Multicentric Survey. Journal
              of Clinical &amp; Diagnostic Research. 2022 Oct 1;16(10).
            </li>
            <li>
              Saran M, Pawaria S, Kalra S. Kinesio taping with ballistic six
              plyometric training on speed, accuracy, target and joint
              proprioception in fast bowlers with glenohumeral instability.
              Comparative Exercise Physiology. 2022 Sep 2;18(4):357-63.
            </li>
            <li>
              Kalra S, Miraj M, Ajmera P, Shaik RA, Seyam MK, Shawky GM, Alasiry
              SM, Mohamed EH, Alasiri HM, Alzhrani M, Alanazi A. Effects of
              Yogic Interventions on Patients Diagnosed With Cardiac Diseases. A
              Systematic Review and Meta-Analysis. Frontiers in Cardiovascular
              Medicine. 2022;9.
            </li>
            <li>
              Pal S, Yadav J, Kalra S. Influence of stress and anxiety in
              sports. JCDR. 2021.YE01-YE05
            </li>
            <li>
              Kalra S*, Yadav J, Ajmera P, Sindhu B, Pal S. Impact of Physical
              activity on physical and mental health of post-menopausal women: a
              systematic review. JCDR,2022;16(2): ye01-ye08
            </li>
            <li>
              Jain R, Kalra S*, Pawaria S. A cross sectional survey on physical
              fitness, mental health and associated factors in mothers of
              children with special needs. Comparative Exercise physiology; CEP;
              0(1);1-8
            </li>
            <li>
              Kalra S*, Chorsiya V, Ajmera P, Pawaria S, Pal S. High prevalence
              of depression among mothers tending children with special needs in
              Gurugram India. Asian Journal of Psychiatry. 2021:64:102790
            </li>
            <li>
              Roshni, Kalra S*, Yadav J.A comparative study of wobble board and
              Bosu ball along with strength training on lower limb strength
              ,dynamic balance, agility and functional performance in runners
              with lateral ankle instability.JCDR.2021;15(5):YC01-YC06.
            </li>
            <li>
              Prajapat J, Kalra S*, Yadav J, Pal S, Pawaria S. Comparative Study
              Of Foam Rolling And Vibration Therapy On Blood Creatinine Level,
              Pain Sprint Speed, Lower Limb Power And Hip Range Of Motion In
              Delayed Onset Muscle Soreness.Int J Res Pharm Sci.2021;12(2):1-11
            </li>
            <li>
              Pal S, Yadav J, Kalra S, Sindhu B. Different Training Approaches
              in Karate-A Review. Lond. J. Res. Hum. Soc. Sci. 2020;20:33-44.
            </li>
            <li>
              Pal S, Kalra S, Jain S. Covid-19 Pandemic: Mental Health and
              coping strategies.JCDR.2020:14(12):YE01-YE04
            </li>
            <li>
              Pal S, Kalra S, Kumar S.A literature review on common injuries and
              their prevention in Kabaddi. .European Journal of Sports &amp;
              Exercise Science, 2020;9 (1): 01-09
            </li>
            <li>
              Sheetal Kalra*, Puneeta Ajmera, Joginder Yadav, Bijender Sindhu,
              Sonia Pawaria. Development of a valid and reliable questionnaire
              to identify factors affecting health of post menopausal women in
              selected areas of Gurugram India accepted for publication in
              Journal of Critical reviews.JCR.2020;7(9):1150-1155
            </li>
            <li>
              Sajjan Pal, Joginder Yadav, Sheetal Kalra. Injury profile in
              Karate athletes-A literature review. Journal of Critical Reviews.
              2020; 7(9):110-1159
            </li>
            <li>
              Sonia Pawaria, Sheetal Kalra. Effect of Inspiratory muscle
              training and aerobic training on pulmonary function, exercise
              capacity and cardiorespiratory fitness in females with
              hypothyroidism accepted for publication in International journal
              of Research in Pharmaceutical Sciences.2020:11(3)
            </li>
            <li>
              Kumar S, Panwar P, Garg S, Kalra S, Yadav J. Translation and
              cross-cultural adaptation of spinal cord independence measure
              version III in hindi language. Annals of Indian Academy of
              Neurology. 2020 Jan;23(1):98.
            </li>
            <li>
              Sonia Pawaria, Sheetal Kalra. Effect of Deep Cervical Flexor
              training on Respiratory functions in chronic neck pain patients
              with forward head posture. International journal of Research in
              Pharmaceutical Sciences.2020; 11(3)
            </li>
            <li>
              Mohanta N, Kalra S*, Pawaria S. A Comparative Study of Circuit
              Training and Plyometric Training on Strength, Speed and Agility in
              State Level Lawn Tennis Players. JCDR.2019;13(2):YC05-YC10.
            </li>
            <li>
              Amola M,Pawaria S, Kalra S*. Effect of Inspiratory Muscle Training
              and Diaphragmatic Breathing Exercises on Dyspnea, Pulmonary
              Functions, Fatigue and Functional Capacity in Pregnancy during
              Third Trimester.Journal of clinical and diagnostic
              research.2019;13(8);YC01-YC04.
            </li>
            <li>
              Yadav P, Kalra S, Pawaria S*. Effect of pilates on agility and
              coordination skills in aspiring state level badminton players: a
              pilot study. Inter. Journal of Medical Science and Diagnosis
              Research. 2019.90-95.
            </li>
            <li>
              Preeti, Kalra S*, Pawaria S, Yadav J. Effect of Pilates on Lower
              Limb Strength, Dynamic Balance, Agility and Coordination Skills in
              Aspiring State Level Badminton Players. Journal of clinical and
              diagnostic research.2019; 13(7):YC01-YC06.
            </li>
            <li>
              Pawaria S, Sudan D, Kalra S, Yadav J. Effectiveness of Cervical
              Stabilisation Exercises on Respiratory Strength in Chronic Neck
              Pain Patients with Forward Head Posture- A Pilot Study. Journal of
              clinical and diagnostic research.2019;13(4):YC06-YC09.
            </li>
            <li>
              Pawaria S, Sudan D, Kalra S, Yadav J. Effectiveness of cervical
              stabilization exercises with feedback on respiratory status in
              chronic neck pain patients with forward head posture.
              International journal of Physiotherapy.2019;6(3):70-74
            </li>
            <li>
              <strong>
                Sachdeva S, Pawaria S, Kalra S. Effectiveness of pursed lip
                breathing versus mouth mask on dyspnea and functional capacity
                in acute exacerbation of chronic obstructive pulmonary disease.
                International journal of health sciences and Research.2018;
                8(9):94-101.
              </strong>
            </li>
            <li>
              <strong>
                Sachdeva S, Pawaria S, Kalra S* Effects of muscle energy
                technique versus mobilization on pain and disability in
                post-partum females with sacroiliac joint dysfunction. Indian
                Journal of Health Sciences and care.2018; 5(1):11-17
              </strong>
            </li>
            <li>
              <strong>
                Sachdeva S, Pawaria S, Kalra S. Effect of Pursed lip breathing
                and mouth mask on dyspnea on healthy individual-A Pilot study.
                International journal of health sciences and Research.2018:8(7):
                135-140.
              </strong>
            </li>
            <li>
              Pancholi P, Yadav J, Kalra S*. Effect of resistance band exercises
              on neck pain, disability and forward head posture in dentists with
              chronic neck pain. J Physiother Rehabil. 2018;2(1).
            </li>
            <li>
              Raghav D, Yadav J, Kalra S. Effect of Thoraco-Lumbar Fascia
              Kinesio taping as a Therapeutic Intervention for Pain, Functional
              Disability in Non Specific Low Back Pain-A Pilot Study. Indian
              Journal of Health Sciences&amp; Care. 2017; 4(1):9-13
            </li>
            <li>
              Pawaria S, Kalra S, Pal S. A study on cardiorespiratory fitness of
              Physiotherapy Students. Int J of Yoga, Physiotherapy and Physical
              education.2017; 2(4):22-24.
            </li>
            <li>
              Kalra S*, Pal S, Pawaria S. Correlational study of chronic neck
              pain and hand grip strength in Physiotherapy Practioners. Int Jof
              Yoga, Physiotherapy and Physical education.2017; 2(4):30-32.
            </li>
            <li>
              Sharma V, Kalra S*. Pawaria S. Manual Physical Therapy in Patients
              with Myofascial Pain in Upper Trapezius: A Case Series. Indian J
              Physiotherapy &amp;Occupational Therapy. 2016; 10 (4):66-70.
            </li>
            <li>
              Kalra S*, Arora B, Pawaria S. Cross sectional survey of work
              related musculoskeletal disorders and associated risk factors
              among female farmers in rural areas of Gurgaon. Indian J
              Physiotherapy &amp;Occupational therapy. 2016; 10(2);149-154
            </li>
            <li>
              <em>Sharma V, Yadav J, Ka</em>lra S. Effect of square stepping
              exercises versus strength and balance training on balance and risk
              of fall in elderly population. Journal for Physiotherapy.2016;
              104:140-47.
            </li>
            <li>
              Singhal S, Pawaria S, Kalra S.{" "}
              <em>
                Effects of Aerobic versus resistance training on blood pressure
                in hypertensive patients. Journal of anesthesia and critical
                care.2015;3(3):1-6.
              </em>
            </li>
            <li>
              Pawaria S, Kalra S. A comparative study of myofascial release and
              stretching on pain, disability and range of motion in trapezius
              trigger points. Indian Journal of Health Sciences and Yadav J,
              Yadav J, Kalra S. Effect of Pelvic Floor Muscle Exercises in
              comparison with Core Stability Exercises on Pain, Functional
              status and Abdominal muscle endurance in Post-Partum females with
              Chronic Low back pain. Indian Journal of Health Sciences and Care.
              2015; 2(2):121-125.
            </li>
            <li>
              Khatwani P, Yadav J, Kalra S. The Effect of Cervical Lateral Glide
              and Manual Cervical Traction Combined with Neural Mobilization on
              Patients with Cervical Radiculopathy. Website: www. ijpot. com.
              2015 Oct;9(4):152.
            </li>
            <li>
              Vohra R, Kalra S*, Yadav J. A comparative study of static
              stretching and PNF stretching on pectoral muscle flexibility. Ind.
              J of Physio &amp; Occup therapy.2014; 8(3).
            </li>
            <li>
              Yadav A, Yadav J, Kalra S* .A comparative study of effectiveness
              between Myofascial release and Pressure release on pain and ankle
              range of motion in adults with soleus myofascial trigger points.
              Intl. J. of Orthopedics and Rehabilitation.2014;1:61-66
            </li>
            <li>
              Popli S, Yadav J, Kalra S*. Effect of Static stretching versus
              retro walking on hamstring flexibility. Ind. J of Physio &amp;
              Occup Therapy.2014; (8):3.
            </li>
            <li>
              Sethi C, Kalra S*, Yadav J. A correlational study of BMI and
              fitness level among children in India in the age group of 13-16”.J
              Sports and Physical Education, Photon.2014; 3:169-174.
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Invited Lecture
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>TOPIC</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>PLACE</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>YEAR</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <i>Organized 1</i>
                    <i>st</i>
                    <i> Physiotherapy conference” </i>
                    <b>
                      <i>Diagnostika Rendezvous”</i>
                    </b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      <i>FIT, Manav Rachna</i>
                    </b>{" "}
                    <b>
                      <i>International University , Faridabad</i>
                    </b>
                  </p>
                </td>
                <td>
                  <p>2006</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Paper presentation Respiratory response to BiPAP aided
                    exercise in COPD patients
                  </p>
                </td>
                <td>
                  <p>PHYSIO HARCON</p>
                </td>
                <td>
                  <p>2016</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Talk delivered on Physiotherapy yesterday, today and
                    Tomorrow
                  </p>
                </td>
                <td>
                  <p>Physiotherapy Convention by Physio help</p>
                </td>
                <td>
                  <p>2016</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Women Health and Physiotherapy</p>
                </td>
                <td>
                  <p>INCPT, AIIMS</p>
                </td>
                <td>
                  <p>2016</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Paper presentation on Effect of Integerated Pelvic floor
                    muscle exercises on Pain and disability in post partum low
                    back pain
                  </p>
                </td>
                <td>
                  <p>SHARDA PHYSIO CON</p>
                  <p>SHARDA UNIVERSITY,Noida</p>
                </td>
                <td>
                  <p>2017</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on “Female Athletic Triad”</p>
                </td>
                <td>
                  <p>GCON-World Laproscopic Hospital</p>
                </td>
                <td>
                  <p>2017</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on “Ergonomics for desk job workers”</p>
                  <p>
                    In a CME organized by Indian Association of Physiotherapy
                  </p>
                </td>
                <td>
                  <p>Sarvodaya Hospital Fardiabad</p>
                </td>
                <td>
                  <p>2017</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Talk on “Integrated Role of Yoga and Physiotherapy in Sports
                    Injury prevention”
                  </p>
                </td>
                <td>
                  <p>
                    Intergerated CAM Conference on Sports Medicine held in
                    Jakarta Indonesia
                  </p>
                </td>
                <td>
                  <p>2018</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on “Women Health”</p>
                </td>
                <td>
                  <p>International Conference of Physical Therapy,AIIMS</p>
                </td>
                <td>
                  <p>2018</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Paper presentation on physical fitness and mental health of
                    post menopusal women in Gurugram
                  </p>
                </td>
                <td>
                  <p>International Orthopedic Conference Singapore</p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on “Role of yoga in Sports”</p>
                </td>
                <td>
                  <p>International Orthopedic Conference Singapore</p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on “Physiotherapy in lifestyle disorders”</p>
                </td>
                <td>
                  <p>
                    PHYSIO NEXT GEN CON by Indian Association of
                    Physiotherapy(IAP)
                  </p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Talk on “Pre participation Sports Evaluation-Perspectives of
                    Sports Physio”
                  </p>
                </td>
                <td>
                  <p>International Conference of Physical Therapy,AIIMS</p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Talk on Women Health</p>
                </td>
                <td>
                  <p>FORTIS Gurugram</p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Panelist in “PAN INDIA ONLINE TALENT SEARCH”</p>
                </td>
                <td>
                  <p>
                    Organized by Indian Association of Physiotherapy (IAP) in
                    May 2020.
                  </p>
                </td>
                <td>
                  <p>May 2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Panelist on topic “Current trends in physiotherapy education
                    and clinical practices”
                  </p>
                </td>
                <td>
                  <p>
                    Organized by international sports and orthopedics manual
                    therapy (isomt).
                  </p>
                </td>
                <td>
                  <p>june 14 2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Guest speaker webinar on “FITNESS TESTING IN SPORTS”</p>
                </td>
                <td>
                  <p>20Organized by Rehabilitation Sciences Group</p>
                </td>
                <td>
                  <p>on June 4, 2020.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Guest speaker -Online webinar on topic “Skill Fitness
                    Testing in Sports”
                  </p>
                </td>
                <td>
                  <p>Organized by Indian Association of Physiotherapy</p>
                </td>
                <td>
                  <p>25th July 2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Guest speaker -Online webinar on Topic “Physical Fitness
                    during Covid-19” on the occasion of World Physiotherapy Day
                    Celebration
                  </p>
                </td>
                <td>
                  <p>Physiotherapy Department AIIMS, New Delhi</p>
                </td>
                <td>
                  <p>8th September 2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Guest speaker -online webinar on the topic “Physical
                    activity for Physical and Mental Health” during Covid-19
                  </p>
                </td>
                <td>
                  <p>
                    Haryana Chartered Association of Physiotherapy during
                    National Haryana Physiotherapy Conference
                  </p>
                </td>
                <td>
                  <p>25th September 2020.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {" "}
                    Panelist in Sports Conclave on the Topic “Sports
                    Physiotherapists in India. Are we in Right direction” 2
                  </p>
                </td>
                <td>
                  <p>Organized during online conference-SPORTS Connect-</p>
                </td>
                <td>
                  <p>14th Feb 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {" "}
                    Panelist in Panel discussion on “Telerehabilitation: Does it
                    address the gap in sports injuries?”
                  </p>
                </td>
                <td>
                  <p>Indian Association of Physiotherapists</p>
                </td>
                <td>
                  <p>8th Aug 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Scientific Committee coordinator</p>
                  <br />
                  <br />
                </td>
                <td>
                  <p>Mathura Physiotherapy Conference (Physio NEXGEN</p>
                </td>
                <td>
                  <p>11th July 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Chaired session in National Web conference on delivery of
                    Tele-therapy services
                  </p>
                </td>
                <td>
                  <p>Rehabilitation council of India(RCI)</p>
                </td>
                <td>
                  <p>8th and 9th September 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Moderator in webinar on “Kinesiotaping in treatment and
                    prevention of Sports Injuries”
                  </p>
                </td>
                <td>
                  <p>Indian Association of Physiotherapists</p>
                </td>
                <td>
                  <p>18th September 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Organized workshop on Therabands in Physiotherapy</p>
                </td>
                <td>
                  <p>
                    Indian Association of Physiotherapy Women Cell as Joint
                    Coordinator Haryana team
                  </p>
                </td>
                <td>
                  <p>30th April 2022</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Program Coordinator Certificate Course on Personality
                    Development
                  </p>
                </td>
                <td>
                  <p>SOP,DPSRU</p>
                </td>
                <td>
                  <p>23/05/2022-27/05/2022</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Chaired session on Role of Gait and foot pressure analysis
                    in Diabetic foot ulcers
                  </p>
                </td>
                <td>
                  <p>Indian Podiatry Association</p>
                </td>
                <td>
                  <p>28th May 2022-29th May 2022</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Organizing secretary and Scientific committee chairperson
                  </p>
                  <p>Event: Physiotherapy Conference</p>
                </td>
                <td>
                  <p>Indian Association of Physiotherapists Haryana branch</p>
                </td>
                <td>
                  <p>21st August 2022</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Gave lecture on Ethics in Physiotherapy Research; and
                    chaired
                  </p>
                  <p>scientific session in the conference</p>
                  <br />
                  <p>.</p>
                </td>
                <td>
                  <p>Santosh Physiotherapy College and</p>
                  <p>Medical University</p>
                </td>
                <td>
                  <p>4th September 2022</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <strong>Paper presentation</strong>
          </p>
          <ol>
            <li>
              Correlation of BMI and physical fitness in school children
              presented during INCPT AIIMS, ND.
            </li>
            <li>
              Paper presentation Respiratory response to BiPAP aided exercise in
              patients, with respiratory diseases during PHYSIOHARCON in Rohtak
              in the year 2016
            </li>
            <li>
              Paper presentation on Effect of Integrated Pelvic floor muscle
              exercises on Pain and disability in low back pain during post
              partum phase in 2017
            </li>
            <li>
              Paper presentation on physical fitness and mental health of post
              menopausal women in Gurugram in International Orthopedic
              Conference in Singapore 2018
            </li>
          </ol>
          <p>
            <strong>BOOK DETAILS:</strong>
          </p>
          <ol>
            <li>
              Myofascial Release and Muscle Stretching in Trigger Points ISBN-
              978-3330003491.
            </li>
            <li>
              Health Psychology 1<sup>ST</sup> Ed May 2021, Vikas Publishing Pvt
              Ltd New Delhi Copyright Algappa University, Tamil Nadu, State Govt
            </li>
            <li>Healthcare Management, (ISBN: 9789356741973), December 2022</li>
            <li>Health and Positive Psychology (ISBN:9789354533365), 2023</li>
            <li>
              Additive manufacturing in healthcare, Book chapter published in
              book Advances in Additive Manufacturing by Elsevier December 2022.
              ISBN no978-0-323-91834-3
            </li>
            <li>
              Chief Editor e book :Recent Paradigms in Physiotherapy management
              by The Indian Association of Physiotherapists. ISBN no
              978-93-92791
            </li>
          </ol>
          <p>
            <strong>PROJECTS GRANTED</strong>
          </p>
          <ul>
            <li>
              <strong>
                Developing a mindful perinatal yoga and meditation program and
                determining its effectiveness on perinatal depression. Funded by
                ICSSR in April 2023. Amount sanctioned 9 Lakh rupees
              </strong>
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsAwardsandRecOpen(!isAwardsandRecOpen)}
      >
        Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
          
          <ul >
            
           <li>
              1. Award of Excellent contribution in Field of Physiotherapy
              PHYSIO-HARCON, Rohtak 2016
            </li>
           <li>
              2. Award of Young Achiever’s INCPT-6 AIIMS, New Delhi 2016
              </li>
           <li>
              3. Significant Contribution Award Conference by Indian Association
              of Physiotherapy 2016
              </li>
           <li>
              4. Award of Academic Excellence Physio Conference at World
              Laproscopic Hospital, Gurgaon 2017
              </li>
           <li>
              5. Academic Excellence award MEDANTA Hospital 2018
              </li>
           <li>
              6. Certificate of Appreciation for significant Contribution in
              Physiotherapy IAPWC at National Sports Club Delhi 2022 7
              Custodians of Humanity Award IGHM 2022
              </li>
            </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrSheetalKalra;
