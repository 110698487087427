import React from 'react'
import './DisciplineRagging.css'

function DisciplineRagging() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">Discipline & Ragging</h2>

            <div className="discipline-section">
                <h2 className="dpsru-secondary-heading-for-every-page">Discipline</h2>
                <p>All the students admitted to the University will be under the complete diciplinary control of the Vice Chancellor of the University or any other officer(s) appointed by him for the purpose and must conduct themselves in accordance with the disciplinary rules of the University as framed and notified by the Vice Chancellor.</p>
            </div>

            <div className="ragging-section discipline-section">
                <h2 className="dpsru-secondary-heading-for-every-page">Ragging</h2>
                <ul><li>In accordence with the order of the Hon’ble Supreme court dated 10.12.2007, in the Special Leave to Appeal (Civil) NOS 24295 and 24296-99 of 2004. “if any incident of ragging comes to the notice of the authority, the concerned student shall be given liberty to explain and if his explanation is not found satisfactory, the authority would expel him from the institution.</li><li>The candidates seeking admission have to give an undertaking that they will abide by the rules and regulations prescribed and abide by an action taken in this regard against students by the Vice-Chancellor.</li><li>Ragging in any form is strictly prohibited within the premises of University as well as on public transport.</li><li>Ragging, for the purpose of the Ordinance, means any act, conduct or practice by which dominant power or status of senior students, isbrought to bear on students freshly enrolled or students who are in any way conidered junior or inferior by other students and includes individual or collective acts or practices which:<ul><li>involve physical assault or treat or use of physical force;</li><li>violate the status, dignity and honor of women students;</li><li>violate the status, dignity and honor of students belonging to the scheduled castes and tribes;</li><li>expose students to ridicules and contempt and affect their self esteem;</li><li>entrain verbal and aggression, indecent gestures and obscene behavior.</li></ul></li></ul>
            </div>


        </div>
    )
}

export default DisciplineRagging