import React from 'react'

const ResearchSupport = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">
    Research Support
    </h2>

    <h2 className="dpsru-secondary-heading-for-every-page">
    Plagiarism Detection Tools
    </h2>

    <ul className="subscribed-resources-ul"><li><a href="https://secure.urkund.com/account/auth/login">Ouriginal&nbsp;</a></li><li><a href="https://dpsru.turnitin.com/home/sign-in">Turnitin&nbsp;</a></li></ul>

    </div>
  )
}

export default ResearchSupport
