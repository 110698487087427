import React from "react";
import "../TLP Report/TLPReports.css";
import hivAids1 from './HIV_and_AIDS_Policy_for_Establishments_2022_0.pdf'

const HIVAIDSPreventionsControl = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        HIV AIDS Preventions & Control
      </h2>

      <section className="hiv-aids-control-section">
        <ul>
          <li>
            <a
              href={hivAids1}
              title="कृपया विस्तृत अधिसूचना के लिए इस एचआईवी और एड्स नीति स्थापना 2022 देखें"
            >
              Please refer to this HIV and AIDS policy for Establishment 2022
              for the detailed notification <br/>कृपया विस्तृत अधिसूचना के लिए इस एचआईवी और एड्स नीति स्थापना 2022 देखें
            </a>
          </li>
<br/>
          <li>
            {" "}
            <a href={hivAids1}>HIV AIDS (Preventions & Control) Act</a>
          </li>
        </ul>

        <h2 className="dpsru-secondary-heading-for-every-page">
          Details Of DPSRU Complaints Officer:
        </h2>
        <div>
          <div>
            <span className="key-of-hiv-aids-details-off">Name :</span> <span className="value-of-hiv-aids-details-off">Prof. Richa H. Rai</span>
          </div>
          <div>
            <span className="key-of-hiv-aids-details-off "  >Email :</span> <span className="value-of-hiv-aids-details-off value-of-hiv-aids-details-off-email">aidshivco [at] dpsru [dot] edu [dot] in</span>
          </div>
        </div>
        <div className="link-for-online-complain-form-hiv-aids">Link for Online Complaint Form: <a href="https://forms.gle/oKerEYuyesA1w7R49"> Click Here..</a> </div>
      </section>
    </div>
  );
};

export default HIVAIDSPreventionsControl;
