import React from 'react'
import { Link } from 'react-router-dom'

function CertificateCourses() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">CERTIFICATE COURSES</h2>

            <div className="ce-container-box">
            <ul><li>Medicine Management</li><li className='alternative-li-in-cc'>Sports Assessment and Yoga Application</li><li>Beauty Wellness Consultant</li><li className='alternative-li-in-cc'> Next Generation Sequencing and its Applications <Link to='/Next-Generation-Sequencing-and-its-Applications' style={{color:'blue'}}>Click Here</Link></li><li>Quality Assurance of Bio-Pharmaceuticals</li><li className='alternative-li-in-cc'>Certificate Course in Yoga</li></ul>
            </div>
        </div>
    )
}

export default CertificateCourses