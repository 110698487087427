import React from "react";
import "./PpiRti.css";

function PpiRti() {
  return (
    <div className="dpsru-every-component-css" style={{ marginBottom: "2%" }}>
      <h2 className="dpsru-primary-heading-for-every-page">
        PUBLICITY AND PUBLIC INTERFACE
      </h2>

      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          border: "1px solid black",
        }} className="ppirti-table-container-in-rti"
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>S.No.</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Item</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Details of Disclosure
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Remarks/Reference Points
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid black", padding: "8px" }}>3.1</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Particulars for any arrangement for consultation with or
              representation by the members of the public in relation to the
              formulation of policy or implementation thereof
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
                Arrangement for consultations with or representation by the
                members of the public
              </p>
              <p>
                (i) Relevant Acts, Rules, Forms and other documents which are
                normally accessed by citizens
              </p>
              <p>
                (ii) Arrangements for consultation with or representation by:
              </p>
              <p>
                a. Members of the public in policy formulation/policy
                implementation
              </p>
              <p>b. Day & time allotted for visitors</p>
              <p>
                c. Contact details of Information & Facilitation Counter (IFC)
                to provide publications frequently sought by RTI applicants
              </p>
              <p>Public-private partnerships (PPP)</p>
              <p>(i) Details of Special Purpose Vehicle (SPV), if any</p>
              <p>(ii) Detailed project reports (DPRs)</p>
              <p>(iii) Concession agreements</p>
              <p>(iv) Operation and maintenance manuals</p>
              <p>
                (v) Other documents generated as part of the implementation of
                the PPP
              </p>
              <p>
                (vi) Information relating to fees, tolls, or other kinds of
                revenues that may be collected under authorization from the
                government
              </p>
              <p>(vii) Information relating to outputs and outcomes</p>
              <p>
                (viii) The process of the selection of the private sector party
                (concessionaire etc.)
              </p>
              <p>(ix) All payment made under the PPP project</p>
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
                All relevant Acts, Rules, Regulations, Forms are made available
                on the website, which can be accessed by public at any time.
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/governingActs">
                  https://dpsru.edu.in/governingActs
                </a>
              </p>
              <p>
                There is no direct provision for consultation with or
                representation by the members of the public in relation to the
                formulation of its policy or implementation.
              </p>
              <p>
                Board of Governors, General Council, Academic Council, Finance
                Committee, as nominated members to the authority/authorities of
                the University.
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                  https://dpsru.edu.in/AuthoritiesoftheUniversity
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/iqacPolicy">
                  https://dpsru.edu.in/iqacPolicy
                </a>
              </p>
              <p>Case to Case Basis</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/contactUs">
                  https://dpsru.edu.in/contactUs
                </a>
              </p>
              <p>
                RTI queries are answered through online RTI portal and
                enclosures are sent through speed/ordinary post.
              </p>
              <p>
                Click Here{" "}
                <a href="https://rtionline.delhi.gov.in/RTIMIS/login/index.php">
                  https://rtionline.delhi.gov.in/RTIMIS/login/index.php
                </a>
              </p>
              <p>Kindly refer in MoU section</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/annualReport">
                  https://dpsru.edu.in/annualReport
                </a>
              </p>
              <p>Not Applicable</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf">
                  SOP Academic DPSRU
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/static/media/Standard%20Operating%20Procedure%20of%20DPSRU%20(2).da634e58c324a160ef23.pdf">
                  Standard Operating Procedure of DPSRU
                </a>
              </p>
              <p>Case to case basis</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/">https://dpsru.edu.in/</a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/newsletters">
                  https://dpsru.edu.in/newsletters
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/annualReport">
                  https://dpsru.edu.in/annualReport
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/tenders">
                  https://dpsru.edu.in/tenders
                </a>
              </p>
              <p>Case to Case Basis</p>
            </td>
          </tr>

          <tr>
            <td style={{ border: "1px solid black", padding: "8px" }}>3.2</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Are the details of policies/decisions, which affect public,
              informed to them
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
                Publish all relevant facts while formulating important policies
                or announcing decisions which affect public to make the process
                more interactive;
              </p>
              <p>
                (i) Policy decisions/legislations taken in the previous one year
              </p>
              <p>(ii) Outline the Public consultation process</p>
              <p>
                (iii) Outline the arrangement for consultation before
                formulation of policy
              </p>
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
                All the important policies framed by the DPSRU/DIPSAR are placed
                on the website of DPSRU.
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/governingActs">
                  https://dpsru.edu.in/governingActs
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/iqacPolicy">
                  https://dpsru.edu.in/iqacPolicy
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/nirf/">
                  https://dpsru.edu.in/nirf/
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/admissions/">
                  https://dpsru.edu.in/admissions/
                </a>
              </p>
              <p>It may depend on case to case basis</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/contactUs">
                  https://dpsru.edu.in/contactUs
                </a>
              </p>
              <p>
                It may depend on case to case basis and directions by the
                competent authority
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/contactUs">
                  https://dpsru.edu.in/contactUs
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "8px" }}>3.3</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Dissemination of information widely and in such form and manner
              which is easily accessible to the public
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>Use of the most effective means of communication:</p>
              <p>(i) Print Versions of published documents</p>
              <p>(ii) Internet (website)</p>
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>Information available on internet via DPSRU</p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/">https://dpsru.edu.in/</a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/contactUs">
                  https://dpsru.edu.in/contactUs
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid black", padding: "8px" }}>3.4</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              Form of accessibility of information manual/handbook
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>Information manual/handbook available in:</p>
              <p>(i) Electronic format</p>
              <p>(ii) Printed format</p>
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
                For admission queries Click Here{" "}
                <a href="https://dpsru.edu.in/annualReport">
                  https://dpsru.edu.in/annualReport
                </a>
              </p>
              <p>
                Click Here{" "}
                <a
                  href="https://dpsru.edu.in/static/me
dia/rti1.f355da6ed16e9fa349b
7.pdf"
                >
                  https://dpsru.edu.in/static/me dia/rti1.f355da6ed16e9fa349b
                  7.pdf
                </a>
              </p>
              <p>
                Click Here{" "}
                <a href="https://dpsru.edu.in/governingActs">
                  https://dpsru.edu.in/governin gActs
                </a>
              </p>
            </td>
          </tr>

          <tr>
            <td style={{ border: "1px solid black", padding: "8px" }}>3.5</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
            Whether
information
manual/
handbook
available free of
cost or not
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>List of materials
              available:</p>
              <p>(i) Free of cost</p>
              <p>(ii) At a reasonable
              cost of the medium</p>
            </td>
            <td style={{ border: "1px solid black", padding: "8px" }}>
              <p>
              The documents are available
              online, it is free of cost.
                <a href="https://dpsru.edu.in">
                  https://dpsru.edu.in
                </a>
              </p>
              
           
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PpiRti;
