import React, { useState } from "react";

import profimg from "./IMG_0051-scaled-e1686561162316.jpg";

const MsSonia = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Ms. Sonia
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Sports Science 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a href="mailto:">
              {" "}
            
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Pursuing Doctor Of Philosophy in Physical Education from Punjabi University Patiala</li><li>Master of Philosophy in Physical Education from Punjabi University Patiala-2019</li><li>Qualified UGC NET, December-2018</li><li>Master of Physical Education (M.P.Ed), from Punjabi University Patiala in 2016 (Gold Medallist)</li><li>Bachelor of Physical Education (B.P.Ed), from Guru Nanak Dev University, Amritsar in 2014</li><li>Bachelor of Arts from Government Degree College Dharamshala, Shimla Univrsity-2013</li></ul>
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Academic Area:</b> Anatomy and Physiology in sports, Sports and Exercise Physiology, Sports and Exercise Psychology, Sports Anthropometry, Sports Nutrition, Yoga Education.
      </p>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Sports Interest:</b> Field Hockey, Track and Field Events, Yoga Practices, Cycling
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">
      <ul><li>Presented a research paper on Relationship Of State Anxiety With The Premenstrual Syndrome (PMS) of Female Athletes in Individual Combat Game ” 2023</li><li>Published a paper on “Psycho-Analytical Comparison Between Male And Female Spikers” in an “International Journal of Health, Exercise and Sports Sciences, 2021, , ISSN: 2583-2328.</li><li>“Sports management education” in an “International Journal of Physiology, Nutrition and Physical Education 2019; 4(1): 1596-1598</li><li>Published a paper on “ Comparative study of PMS between a sedentary lifestyle and physically active females” in an “International Journal of Yogic, Human Movement and Sports Sciences” 2019; 4(1): 1050-1052</li><li>Published a paper on “Comparative study of will to win among all India Inter- University level, senior-level and State level Boxer” in UGC approved Journal NO. 48514, ISSN: 2249-894x, 2019</li><li>Published a paper on “Comparative study of the male and female athlete on Pre- competition” in UGC approved Journal NO. 48514, ISSN: 2249-894x, 2019</li><li>Published a paper on “Comparative study on Emotional Intelligence between the male Athlete and Non-Athlete” in UGC approved Journal 48514, ISSN: 2249- 894x, 2018</li></ul>
      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Conferences
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        <ul><li>Attended a International Conference Physical Education &amp; Exercise Sciences dated 22nd to 24th March, 2023 held at Punjabi University, Patiala.</li><li>Attended 2 days’ workshop on SPSS conducted by Academy of Sports Sciences Research and Management, on 29th -30th September 2021</li><li>Participation in 7-day National workshop on research methodology &amp; SPSS, organized by department of physical education Punjabi university Patiala, 2018</li><li>Presented a poster in “Global Conference on Scientific Culture in Physical Education and Sports (2016)” at Punjabi University, Patiala.</li><li>Presented Paper in the International Conference on “Sports, Health and Sports Economics (2015)” at Sri Guru Granth Sahib World University, Fatehgarh Sahib</li><li>Presented a paper at the International conference on “Global excellence in fitness and Sports Science (2015).” by deptt. Of phy.edu, JECRC University.</li><li>Presented a paper in International conference on “Global Scientific Conference on Physical Education, Health &amp; Sports Sciences (2014)” Guru Nanak Dev University, Amritsar</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
   <p>Facility Volunteer in the COVID awareness program (social service program ), 2021.</p>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         <ul><li>Patent on “Probiotic Sports Nutrition Composition for Athletes and Method for preparation thereof”</li><li>Executive Editor of “International Journal of Health Exercise and Sports Science” Peer reviewed Journal</li><li>Awarded Gold medal in Master of Physical Education</li><li>Student Dance Participation in “Indian Science Congress Association &amp; University of Jammu<br/>(2014)” at University of Jammu<br/> Conducted Inter hostel competitions at Chandigarh University<br/> Conducted All Indian Inter-University of American Football at Chandigarh University, 2019<br/> Conducted Para National Game of Boccia at Chandigarh University, 2019<br/> Conducted Faculty Athletic Meet at Chandigarh University, 2019<br/> Created Curriculum scheme and Syllabus on UIMS (LSM), Chandigarh University<br/> Taking Online classes of B.P.Ed on Blackboard Application<br/> Served as an Examination deputy superintendent in University’s final exams,2019<br/> Served as an Examination Superintendent in University’s summer term exams,2019<br/> Visited as an external examiner at BHAAGO MAJRA physical college in-game lesson from the<br/>Punjabi University Patiala<br/> First Aid proper Knowledge<br/> Complete Knowledge of Computer Application</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default MsSonia;
