import React from "react";

import dse01 from './Dipsar Sessional Examination files/sessional-exam-notice-6799-6804.pdf'
import dse02 from './Dipsar Sessional Examination files/B.-Pharma-6th-semester-1st-sessional-datesheet.pdf'
import dse03 from './Dipsar Sessional Examination files/B.pharma-8th-semester-2nd-sessional.pdf'
import dse04 from './Dipsar Sessional Examination files/D.-pharma-1-year-2nd-sessional-datesheet.pdf'
import dse05 from './Dipsar Sessional Examination files/Date-sheet-for-D.-Pharma-II-sessional-2022-23.pdf'
import dse06 from './Dipsar Sessional Examination files/1-st-sessional-exam-of-B-pharma-8th-semester.pdf'
import dse07 from './Dipsar Sessional Examination files/sessional-exam-notice-of-b-pharm-8-semester-.pdf'
import dse08 from './Dipsar Sessional Examination files/examination-notice-m.phrama.pdf'
import dse09 from './Dipsar Sessional Examination files/Date-sheet-For-D-pharma-I-sessional-exam-22-23.pdf'
import dse10 from './Dipsar Sessional Examination files/1st-sessional-Bpharm-3rd-sem-and-2nd-sessional-of-Bpharma-7thsem.pdf'
import dse11 from './Dipsar Sessional Examination files/date-sheet-D-pharma-2nd-sessional-1.pdf'

const DipsarSessionalExamination = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
      Sessional Examination
      </h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={dse01}>
              B. Pharm Sessional Exam 21 July 2023
            </a>
          </li>
          <li>
            <a href={dse02}>
              B. Pharm 6th Semester 1st Sessional Exam 26 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse03}>
              B. Pharm 8th Semester 2nd Sessional Exam 25 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse04}>
              D. pharma 1 year 2nd sessional datasheet 21 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse05}>
              Date sheet for D. Pharma 2nd Year II Sessional Exam 12 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse06}>
              B. Pharm 8th Semester 1st Sessional Exam 10 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse07}>
              Sessional Exam Notice of B. Pharm 8th Semester 10 Apr 2023
            </a>
          </li>
          <li>
            <a href={dse08}>
              M. Pharm sessional 01 Feb 2023
            </a>
          </li>
          <li>
            <a href={dse09}>
              Date sheet For D. Pharma 1st Year I Sessional Exam 23 jan 2023
            </a>
          </li>
          <li>
            <a href={dse10}>
              B. Pharm 2,3,&amp;5 Semester 1st Sessional Exam 15 Dec 2022
            </a>
          </li>
          <li>
            <a href={dse11}>
              D. Pharm 2nd Year 1st Sessional Exam 15 Dec 2022
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DipsarSessionalExamination;
