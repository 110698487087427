import React from 'react'

import bPharm from './Placement Record 2020-2021 Files/BPHARM-20-21.pdf'
import mba from './Placement Record 2020-2021 Files/MBA-20-21.pdf'
import mhm from './Placement Record 2020-2021 Files/MHM-20-21.pdf'
import mPharm from './Placement Record 2020-2021 Files/MPHARM-20-21.pdf'
import mph from './Placement Record 2020-2021 Files/MPH-20-21.pdf'

const PlacementRecord20202021 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Placement Record 2021- 2022</h2>

    <div className="results-data-link-container">
      <ul>
        <li>
          <a href={bPharm}>
          B. Pharm 
          </a>
        </li>
        <li>
          <a href={mba}>
          MBA
          </a>
        </li>
        <li>
          <a href={mhm}>
          MHM
          </a>
        </li>
        <li>
          <a href={mPharm}>
          M.PHARM 
          </a>
        </li>
        <li>
          <a href={mph}>
          MPH
          </a>
        </li>
     
      </ul>
    </div></div>
  )
}

export default PlacementRecord20202021