import React from "react";
import "./NewsLetters.css";

import newsletters01 from "./NewsLetters files/Newsletter-20th-feb.pdf";
import newsletters02 from "./NewsLetters files/newsletter-2.pdf";
import newsletters03 from "./NewsLetters files/newsletter-1.pdf";
import newsletters04 from "./NewsLetters files/NEWSLETTER-IV-1.pdf";
import newsletters05 from "./NewsLetters files/Newsletter-Vol-II-Issue-I.pdf";
import newsletters06 from "./NewsLetters files/Newsletter-VIth.pdf";
import newsletters07 from "./NewsLetters files/Newsletter-VIIth-1.pdf";
import newsletters08 from "./NewsLetters files/Newsletter-VIIIth.pdf";
import newsletters09 from "./NewsLetters files/Newsletter-IXth.pdf";
import newsletters10 from "./NewsLetters files/Newsletter-Xth.pdf";
import newsletters11 from "./NewsLetters files/Newsletter-XIth_.pdf";
import newsletters12 from './NewsLetters files/Newsletter XII.pdf'

function NewsLetters() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        QUARTERLY NEWSLETTER OF DELHI PHARMACEUTICAL SCIENCES RESEARCH
        UNIVERSITY
      </h2>{" "}
      <hr />
      <div className="inner-container">
        <ol>
         
          <li>
            <a href={newsletters01}>
              Volume No. 1: Issue 1: January – February 2020
            </a>
          </li>
          <li>
            <a href={newsletters02}>
              Volume No. 1: Issue 2: March – April 2020
            </a>
          </li>
          <li>
            <a href={newsletters03}>
              Volume No. 1: Issue 3: May -August 2020{" "}
            </a>
          </li>
          <li>
            <a href={newsletters04}>
              Volume No. 1: Issue 4: Sept – Dec 2020
            </a>
          </li>
          <li>
            <a href={newsletters05}>
              Volume No. 2: Issue 1: January – April 2021
            </a>
          </li>
          <li>
            <a href={newsletters06}>
              Volume No. 2: Issue 2: May – August 2021
            </a>
          </li>
          <li>
            <a href={newsletters07}>
              Volume No. 2: Issue 3: September – December 2021
            </a>
          </li>
          <li>
            <a href={newsletters08}>
              Volume 3, Issue 1: Jan – April’ 2022
            </a>
          </li>
          <li>
            <a href={newsletters09}>
              Volume 3, Issue 2: May – August 2022
            </a>
          </li>
          <li>
            <a href={newsletters10}>
              Volume 3, Issue 3&nbsp; September – December 2022
            </a>
          </li>
          <li>
            <a href={newsletters11}>
              Volume 4, Issue 1 January – April 2023
            </a>
          </li>
          <li>
            <a href={newsletters12}>
            Volume 4, Issue 2 May - August 2023
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default NewsLetters;
