import React, { useState } from "react";

import profimg from "./WhatsApp-Image-2023-07-19-at-10.19.30-AM-e1689743369529.jpeg";

const DrDalapathiGugulothu = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
        Dr. Dalapathi Gugulothu
      </h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
              dalapathig [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=mSWy-XEAAAAJ&hl=en">
                {" "}
                Google Scholar{" "}
              </a>
            </li>
            <li>Scopus</li>
            <li>
              <a href="https://orcid.org/0000-0001-5615-201X">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M.Pharm , Ph.D(Tech.)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Si RNA Delivery, Targeted drug delivery systems, functional Graphen
        oxide Nanoparticles Nanofibers, Exosomes, Nanotoxicology, Intracellular
        Trafficking of nanoparticles, Microneedles, Development of Lyophilzation
        Cycle, Gastroretentive drug delivery systems, Orodispersible drug
        delivery systems. Furthermore, working on development of analytical
        methods for APIs.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <strong>SNO</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Research Project</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Grant</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Collaboration</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Status </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>1</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Exploring Natural Exosome Nanovesicles with Rotigotine as a
                    Targeted Therapy for Parkinson Disease
                  </p>
                </td>
                <td>
                  <p>DST- SERB</p>
                </td>
                <td>
                  <p>NA</p>
                </td>
                <td>
                  <p>Ongoing</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>
                    Development of Gastrorententive Drug Delivery System using
                    Natural Polymers for Improved Bioavailability
                  </p>
                </td>
                <td>
                  <p>DS -SERB</p>
                </td>
                <td>
                  <p>NA</p>
                </td>
                <td>
                  <p>Completed</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Poojitha Pinjala , Kamatham Pushpa Tryphena , Renuka Prasad G ,
              Woong Sun , Shashi, Bala Singh ,{" "}
              <strong>Dalapathi Gugulothu</strong> , Lalitkumar Vora , Saurabh
              Srivastava , DharmendraKumar Khatri 1CRISPR/Cas9: A Potential
              Genome Editing Tool for Parkinson’s Disease using stemcells
              Biomaterials Research (2023) 27:46.
            </li>
            <li>
              Dharmendra Kumar Khatri, Kumari Preeti, Shivraj Tonape, Sheoshree
              Bhattacharjee, Monica Patel, Saurabh Shah, Pankaj Kumar Singh,
              Saurabh Srivastav, <strong>Dalapathi Gugulothu,</strong> Lalit
              Vora and Shashi Bala Sing Nanotechnological Advances for Nose to
              Brain Delivery of Therapeutics to Improve the Parkinson Therapy,
              Current Neuropharmacology, 493 – 516, 21(3),
            </li>
            <li>
              Dalapathi Gugulothu, Suraj Kumar Choudhary, Dharmendra Kumar
              Khatri, Extraction and investigation of polysaccharide mucilages
              as additives in drug delivery systems and application in
              development of floating tablets of silymarin, Journal of
              Excipients and Food Chemicals, 12(4), 2021, 70-86.
            </li>
            <li>
              <strong>Dalapathi Gugulothu,</strong> Choudhary Suraj Kumar,
              Design and <em>In-Vitro </em>Evaluation of Floating Drug Delivery
              System of Glipizide Using Combination of Natural Mucilages and
              Synthetic Polymers, International Journal of Pharmacy and
              Pharmaceutical Science,13(7), 2021, 40-48.
            </li>
            <li>
              Design and Evaluation of Glipizide Loaded In-situ Gel Formulation
              Using Novel Natural Mucilages for Improved Bioavailability,
              International Journal of Pharma Sciences and Research,12,41-49,
              2021.
            </li>
            <li>
              <strong>
                <u>Gugulothu Dalapathi</u>
              </strong>
              , Choudhary Suraj Kumar, <em>Ex</em>ploring{" "}
              <em>Ziziphus Sps fruit </em>mucilages as a pharmaceutical
              excipient in Novel Drug Delivery Systems, Journal of Innovation in
              Pharmaceutical Sciences (2018);2(2): 17-21.
            </li>
            <li>
              <strong>
                <u>Dalapathi B. Gugulothu</u>
              </strong>
              , Kulkarni Aditya, Vandana B. Patravale, Prajakta Dandekar,
              Curcumin-Celecoxib loaded pH-sensitive nanoparticles as
              combination therapy for ulcerative colitis”: Journal of
              Pharmaceutical Sciences<em>, </em>103(2), 687-696, 2014.
            </li>
            <li>
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>
              , Preshita Desai, Pranav Pandharipande and Vandana Patravale
              “Freeze drying: exploring potential in development of
              orodispersible tablets of sumatriptan succinate. Drug Development
              and Industrial Pharmacy, 41(3), 398-405, 2015.
            </li>
            <li>
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>
              , Preshita Desai and Vandana Patravale, A Versatile Liquid
              Chromatographic Technique for Pharmacokinetic Estimation of
              Curcumin in Human Plasma. Journal of Chromatographic Science,
              52(8) 872-879, 2014
            </li>
            <li>
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>{" "}
              and Vandana Patravale, Stability-Indicating HPLC Method for
              arteether and Application to Nanoparticles of Arteether; Journal
              of Chromatographic Science; 52(8), 831-6,
            </li>
            <li>
              Suresh Subramanian, Usha Pandey,{" "}
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>
              , Vandana Patravale, Grace Samuel, Modification of PLGA
              nanoparticles for improved properties as a <sup>99m</sup>
              Tc-labeled agent in sentinel lymph node detection, Cancer
              Biotherapy and Radiopharmaceuticals, 28(8):598-606 (2013)
            </li>
            <li>
              <strong>
                <u>Dalapathi B Gugulothu</u>
              </strong>
              <strong>, Clara B Fernandes and Vandana B Patravale, </strong>
              <a href="http://www.omicsonline.org/2153-2435/pdfdownload.php?download=2153-2435-3-156.pdf?%20aid=6922">
                <strong>
                  A Versatile High Performance Liquid Chromatography Method for
                  Simultaneous Determination of three Curcuminoids in
                  Pharmaceutical Dosage forms
                </strong>
              </a>
              <strong>, Pharm Anal Acta, 3,156(2012)</strong>
            </li>
            <li>
              <strong>
                <u>Dalapathi B Gugulothu</u>
              </strong>
              <strong> and Vandana B Patravale,</strong>
              <a href="http://www.omicsonline.org/2153-2435/pdfdownload.php?download=2153-2435-3-157.pdf?%20aid=7013">
                <strong>
                  A New Stability-Indicating HPLC Method for Simultaneous
                  Determination of Curcumin and Celecoxib at Single Wavelength:
                  an Application to Nanoparticulate Formulation
                </strong>
              </a>
              <strong>, Pharm Anal Acta, 3,157(2012).</strong>
            </li>
            <li>
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>
              , Sulabha Pathak, Shital Suryavanshi, Shobhona Sharma and Vandana
              Patravale, Self-Microemulsifying Suppository Formulation of
              β-Artemether, AAPS PharmSciTech,11(3)1179-1184 (2010).
            </li>
          </ul>
          <p>
            <strong>Book Chapters </strong>
          </p>
          <ul>
            <li>
              Meenakshi K. Chauhan, Alisha Sachdeva, Lubna Ansari,{" "}
              <strong>Dalapathi Gugulothu</strong>, Manufacturing Process of
              Nanoparticles Pharmaceutical Process Engineering and Scale-up
              Principles, Springer, Anil Jindal (Editor){" "}
              <strong>eBook ISBN</strong>978-3-031-31380-6, 2023
            </li>
            <li>
              Dalapathi Gugulothu, Dimple Dhawan, Alisha Sachdeva, Deepali, and
              Meenakshi Kanwar Chauhan
              <strong>
                , Biomaterial-Based Delivery Systems for Chemotherapeutics in
                Book Targeted
              </strong>{" "}
              Cancer Therapy in Biomedical Engineering, Publisher: Springer,
              pages 105-178Rishabha Malviya · Sonali Sundram (Editors)
            </li>
            <li>
              <strong>Dalapathi Gugulothu</strong>and Dharmendra Kumar Khatri
              <em>, </em>Emerging applications of bionanomaterials in medicine
              and drug delivery, accepted in book Bionanotechnology: Emerging
              Applications of Bio nanomaterials : 2022 chapter 3; page
              No:129-186. Elsevier publishers,Editors:Ahmed Barhoum,Jaison
              Jeevanandam and Michael K. Danquah
            </li>
            <li>
              Dalapathi Gugulothu, Ahmed Barhou<sup>m</sup>
              <sub>,</sub>Syed Muzammil Afzal, Banoth Venkateshwarlu, Hassan
              Uludag, Structural Multifunctional Nanofibers and Their Emerging
              Applications in Hand Book of Nanofibers , Springer Nature
              Publishers Edotor: Ahmed Barhoum, 2018.
            </li>
            <li>
              <strong>Dalapathi Gugulothu</strong>, Ahmed Barhoum,Raghunandan
              Nerella,Ramkishan Ajmer, Mikhael Bechlany, Nanofiber Fabrication
              Techniques:Electrospinning and Non-Electrospinning techniques in
              Hand Book of Nanofibers , Springer Nature Publishers Edotor: Ahmed
              Barhoum, 2018.
            </li>
            <li>
              F, Priyanka. P,{" "}
              <strong>
                Dalapathi. G<u>,</u>
              </strong>{" "}
              Vandana. P, Lipid Nanocarriers for Topical Anticancer Therapy: An
              Update (Ed. Eliana Souto) In Lipid Nanocarriers in Cancer
              Diagnosis and Therapy, Chapter 15
              <em>, iSmithers Rapra Publishing</em>, 2011.
            </li>
          </ul>
          <p>
            <strong>Patents (Granted) </strong>
          </p>
          <ul>
            <li>
              <ul>
                <li>
                  Highly porous Tablet: Patent No; 341894; Application No.{" "}
                  <strong>887/MUM/2014 </strong>
                </li>
                <li>
                  Controlled release pharmaceutical solid matrix composition and
                  method of preparation thereof, Patent No: 366222(Application
                  No. and Date: <strong>201641039156/ 17/11/2016</strong>)
                </li>
                <li>
                  “A Composition of Gastroretentive Mucoadhesive Microsphere and
                  Process for Preparation Thereof, Patent Number:
                  383799;Application No.{" "}
                  <strong>202021052264 and date 01/12/2020. </strong>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Dalapathi Gugulothu, A Composition of Gastroretentive Mucoadhesive
              Microsphere of Glipizide for Improving Gastric Retention,
              IISF-2020, YSC, New Delhi (Virtual) December 22nd -25th, 2020.
            </li>
            <li>
              Dalapathi Gugulothu, Design And Evaluation Of Glipizide Loaded
              Bioadhesive And Buoyant Microspheres Using Natural Polysaccharides
              For Improved Anti Diabetic Efficacy, AOCE-SICEM 2020, South Korea,
              Octber28-31, 2020
            </li>
            <li>
              Dalapathi Gugulothu, Radhika Kandula and Suraj Kumar Choudhary,
              Novel bioadhesive microspheres of Silymarin:Formulation design and
              Anti-Parkinson efficacy evaluation , IISF2019, YSC, Kolkata, India
              on November 5th -8th, 2019.
            </li>
            <li>
              Honey Jasmine Jangam, SoumyaPittala, Suraj Kumar Choudhary and
              Dalapathi Gugulothu, Design and Evaluation of Glipizide Loaded
              Bioadhesiveand Buoyant Microspheres Using Natural Polysaccharides
              at 2nd Pharmaceutical Sciences Congress 2019, at St.Peter’s
              Institute of Pharmaceutical Sciences, Hanamkonda, Telangana, India
              on 01 &amp; 02 Nov 2019.
            </li>
            <li>
              Suraj Kumar Choudhary, SoumyaPittala and Dalapathi Gugulothu,
              poster entiled Novel Bioadhesive and Buoyant Microspheres of
              Glipizide: Formulation Design and In- Vitro Evaluation at Two days
              National conference on Current scenaraion in Pharmaceutical
              Sciences , Chaithanya College of Pharamcy Education and Research
              in association with Telangana Acadeny of Sciences ,Hanamkonda,
              Warangal, TS, India, on 24th -25th October, 2019.
            </li>
            <li>
              Gugulothu Dalapathi , Ramya Nagapuri and Dharmendra Kumar Khatri
              poster entitled “Development of Mucoadhesive Sustained Release
              Floating Tablets of Silymarin using Natural Polymer for Improved
              Anti-Parkinson Efficacy” at 2019 International Congress of
              Parkinson’s disease and Movement Disorders, Nice, France.
              September 22-26, 2019.
            </li>
            <li>
              Snehitha Muttineni, Dalapathi Gugulothu, Suraj Kumar Choudhary and
              Ramya Nagapuri presented a poster entitled “Novel expandable
              gastroretentive system of Silymarin: Formulation design and In
              -Vitro evaluation”, at DST-SERB sponsored Two day National level
              seminar on NMR Techniques and its Applications in Pharmaceutical
              Sciences organized by Jayamukhi College of Pharmacy, Narsampet,
              Warangal, Telangana, India, held on 20st-21st September, 2019.
            </li>
            <li>
              Prashanthi Vallakatla, Dalapathi Gugulothu, Suraj Kumar Choudhary,
              and Ramya Nagapuri, presented a poster entitled “Development of
              Mucoadhesive Sustained Release Floating Tablets of Silymarin using
              Cocciniagrandis and Rumex vasicarious Natural Polymers”, at
              DST-SERB sponsored Two day National level seminar on NMR
              Techniques and its Applications in Pharmaceutical Sciences
              organized by Jayamukhi College of Pharmacy, Narsampet, Warangal,
              Telangana, India, held on 20st -21st September, 2019.
            </li>
            <li>
              Saigirishma Pounugati, Dalapathi Gugulothu, Suraj Kumar Choudhary
              and Ramya Nagapuri, presented a poster entitled “Design and
              In-Vitro evaluation of buoyant drug delivery systems of Using
              natural macromolecules and synthetic polymers”, at DST-SERB
              sponsored Two day National level seminar on NMR Techniques and its
              Applications in Pharmaceutical Sciences organized by Jayamukhi
              College of Pharmacy, Narsampet, Warangal, Telangana, India, held
              on 20st -21st September, 2019.
            </li>
            <li>
              Honey Jasmine, Dalapathi Gugulothu, Suraj Kumar Choudhary, and
              Ramya Nagapuri, presented a poster entitled “Effect of Natural
              Biopolymers and Synthetic Polymers on Floating tablets of
              Silymarin”, at DST-SERB sponsored Two day National level seminar
              on NMR Techniques and its Applications in Pharmaceutical Sciences
              organized by Jayamukhi College of Pharmacy, Narsampet, Warangal,
              Telangana, India, held on 20st -21st September, 2019.
            </li>
            <li>
              Ravali Lingala, Dalapathi Gugulothu, Suraj Kumar Choudhary and
              Ramya Nagapuri, presented a poster entitled “Formulation
              development and In-Vitro evaluation of Silymarin loaded
              bioadhesive tablets for prolonged gastric retention” at DST-SERB
              sponsored Two day National level seminar on NMR Techniques and its
              Applications in Pharmaceutical Sciences organized by Jayamukhi
              College of Pharmacy, Narsampet, Warangal, Telangana, India, held
              on 20st -21st September, 2019.
            </li>
            <li>
              Dalapathi Gugulothu, Suraj Kumar Choudhary and Shivashankar,
              Evaluation of Fenugreek Dried Extracts For Pharmaceutical
              Applications, presented a poster at A Two Day National Conference
              on Advances in drug Delivery and Innovations in Pharma Industry
              organized by Balaji Institute of Pharmaceutical Sciences in
              Association with Telangana Academy of Sciences , Hyderabad , TS,
              India at Balaji Institute of Pharmaceutical Sciences, Narsampet,
              Warangal, TS, India, on 27-28 ,March , 2019.
            </li>
            <li>
              Dalapathi Gugulothu and Suraj Kumar Choudhary, Exploring Colacasia
              Esculanta Fruit Dried Extraxt As Pharmaceutical Excipients,
              presented a poster at A Two Day National Conference on Advances in
              drug Delivery and Innovations in Pharma Industry organized by
              Balaji Institute of Pharmaceutical Sciences in Association with
              Telangana Academy of Sciences , Hyderabad , TS, India at Balaji
              Institute of Pharmaceutical Sciences, Narsampet, Warangal, TS,
              India, on 27-28 ,March , 2019.
            </li>
            <li>
              Dalapathi Gugulothu, Suraj Kumar Choudhary and Durga prasad Bukka,
              Exploring Rumex Vasicarious Dried Extract As A Pharmaceutical
              Additives In Novel Drug Delivery System, presented a poster at A
              Two Day National Conference on Advances in drug Delivery and
              Innovations in Pharma Industry organized by Balaji Institute of
              Pharmaceutical Sciences in Association with Telangana Academy of
              Sciences , Hyderabad, TS, India at Balaji Institute of
              Pharmaceutical Sciences, Narsampet, Warangal, TS, India, on 27-28
              ,March , 2019.
            </li>
            <li>
              Dalapathi Gugulothu, Suraj Kumar Choudhary and Saikrishna
              Nalamasa,Extraction And Evaluation Of Coccinia Grandis Dried
              Extracts For Pharmaceutical Applications, presented a poster at A
              Two Day National Conference on Advances in drug Delivery and
              Innovations in Pharma Industry organized by Balaji Institute of
              Pharmaceutical Sciences in Association with Telangana Academy of
              Sciences , Hyderabad , TS, India at Balaji Institute of
              Pharmaceutical Sciences, Narsampet, Warangal, TS, India, on 27-28
              ,March , 2019.
            </li>
            <li>
              Dalapathi G, Soujanya S,SurajKumar Ch and Santhosh Gandamalla*
              Preparation and In-Vitro Evaluation of Glipizide Floating Tablets
              Using Coccinia grandis and Rumex visicarious Dried Mucilage
              Extracts presented a poster at A Two Day National Conference on
              Advances in drug Delivery and Innovations in Pharma Industry
              organized by Balaji Institute of Pharmaceutical Sciences in
              Association with Telangana Academy of Sciences , Hyderabad, TS,
              India at Balaji Institute of Pharmaceutical Sciences, Narsampet,
              Warangal, TS, India, on 27-28 ,March , 2019.
            </li>
            <li>
              Dalapathi G, Soujanya S, Suraj Kumar Ch and Syed Akhil P ,
              Preparation And In-Vitro Evaluation Of Glipizide Floating Tablets
              Using Natural Mucilges, at “17th International Symposium on
              Advances in Technology and Business Potential of New Drug Delivery
              Systems”, February 01-02, 2019, Mumbai, India.
            </li>
            <li>
              Dalapathi Gugulothu , Suraj Kumar Choudhary,Saikrishna Nalamasa,
              Exploring Plant Derived Mucilages as Pharmaceutical Excipients at
              1st Telangana State Science Congress, NIT Warangal, , 22-25
              December 2018.
            </li>
            <li>
              Dalapathi Gugulothu, Suraj Kumar Choudhary presented a poster
              entitled “Exploring Various Natural Mucilages as a Pharmaceutical
              Additives in Advanced Drug Delivery Systems”at first Controlled
              Release Asia (CRA) meeting, 24th- 25th September 2018, Singapore.
            </li>
            <li>
              Gugulothu Dalapathi, Choudhary Suraj Kumar presented a poster
              entitled Exploring Ziziphus Sps fruit mucilages as pharmaceutical
              excipients in Novel Drug Delivery Systems, at 3rd International
              Conference on Innovations in Pharmaceutical Sciences, 3-4th Aug
              2018 at Gurunanak Group of Institutions, Hyderabad, Telangana,
              India.
            </li>
            <li>
              Oral presentation : Dalapathi Gugulothu and Hymavathi A “To
              enhance the dissolution rate of poorly soluble drug “SILYMARIN” by
              using superdisintegtrants and Diluents” at Two Days National
              Conference “ PHARMA ASTHRA” on 21st-22nd April 2018, Balaji
              Institute of Pharmaceutical Sciences, Laknepally(V), Narsampet(M),
              Warangal Rural (D), Telangana State, India.
            </li>
            <li>
              Dalapathi Gugulothu and Suraj Kumar Choudhary, “To enhance the
              dissolution rate of poorly water soluble drug Glypizide: Using
              Solid Dsipresion technique with commonly used excipeints” at Two
              Days National Conference “ PHARMA ASTHRA” on 21st-22nd April 2018,
              Balaji Institute of Pharmaceutical Sciences, Laknepally(V),
              Narsampet(M), Warangal Rural (D), Telangana State, India.
            </li>
            <li>
              Gugulothu D, Pawar R, Pathak S, Sharma S, Patravale V
              “Anti-Malarial Efficacy Of Lipid Nanoparticles: Combination Of
              Curcumin-Arteether Vs Ellagic Acid-Arteether” at the 6th
              Indo-Japanese International Symposium Overcoming Intractable
              Infectious Diseases Prevalent in Asian Countries, September, 23
              -24, 2016, Goa, India.
            </li>
            <li>
              Dalapathi Gugulothu, Kavya Polasani, Jothshna Lingala, Raghunandan
              Nerella, “Formulation and Evaluation of Ondansetron Hydrochloride
              Loaded Fast Disintegrating Films for Sub Lingual Delivery” at
              Trend setting Innovations in Pharmaceutical Sciences, JNTU,
              Hyderabad, July 18-19th , 2016 , Hyderabad, Telangana, India.
            </li>
            <li>
              Dalapathi Gugulothu, Himavarsha Ponugati, Mounika Yedupati, Ambika
              Poloju, “Formulation and Evaluation of Fast Dissolving Tablets of
              Anagrelide Hydrochloride”, at Trend setting Innovations in
              Pharmaceutical Sciences, JNTU, Hyderabad, July 18-19th , 2016 ,
              Hyderabad, Telangana, India.
            </li>
            <li>
              Veena L. Thampy, Vijaya Nagini, G.Dalapathi and N.Raja,
              “Development and Evaluation of Ezetimibe Solid Dispersion for
              Improved Dissolution” at Trend setting Innovations in
              Pharmaceutical Sciences, JNTU, Hyderabad, July 18-19th , 2016 ,
              Hyderabad, Telangana, India.
            </li>
            <li>
              Dr.G.Dalapathi,G.Laxmi, D.Rajkumar,Manish Kuamr Thimmaraju on “
              Method Development and Validation of Condesatran by RP-HPLC” , in
              One Day National Conference Current Trends and Future Prospectives
              in Pharmaceutical Research on 26th March 2016 at Koringa college
              of Pharmacy, Korangi, East Godavari ,Andhra Pradesh , India.
            </li>
            <li>
              Dalapathi Gugulothu, Ankit Kumar Agrawal, Sulabha Pathak ,
              Shobhona Sharma, Vandana Patravale “Solid Lipid Nanoparticles of
              Ellagic Acid: A Novel Modality for the Treatment of Malaria”, 5th
              Indo-Japanese International Joint Symposium on Overcoming
              Intractable Infectious Diseases Prevalent in Asian Countries,
              September 16th &amp;17th 2014 , Tokyo University of Science,
              Tokyo, Japan.
            </li>
            <li>
              Dalapathi B. Gugulothu, Sudeep S. Pukale, Vandana B. Patravale,
              “Polymeric Nanoparticulate Delivery of Curcumin-Ellagic acid:
              Synergistic Potential for Inflammatory Bowel Disease Therapy”, at
              National Seminar on Advances in Pharmaceutical Technology and its
              Business Potential, Yadavrao Tasgaokar College of Pharmacy,
              February 21, 2014, Karjat, Raigad, India
            </li>
            <li>
              Parth J, Meenal G, Namrat K, Gugulothu D, Patravale VB, “Mendeley
              Desktop®: The unsolved way to manage your research” at the
              National level Seminar on Software in Drug Discovery and
              Development, January 21-22, 2014 , Warananagar, Kolhapur, India.
            </li>
            <li>
              Mahadev K, Swapnil T, Gugulothu D, Patravale VB, “Inclusion of pbd
              viewer and Hex for drug screening: A case study” at National level
              Seminar on Software in Drug Discovery and Development, January
              21-22, 2014 , Warananagar, Kolhapur, India.
            </li>
            <li>
              Mohurle S, Gite SM, Mirani A., Gugulothu D., Patravale V.B.,
              PCP-V3 Simplifying Dissolution studies” at the National level
              Seminar on Software in Drug Discovery and Development, January
              21-22, 2014 , Warananagar, Kolhapur, India.
            </li>
            <li>
              Mirani A, Kadwadkar N. A, Gite S. M, Gugulothu D, Patravale V.B,
              “Design Expert ® : An integral statistical tool for design of
              experiment” at National level Seminar on Software in Drug
              Discovery and Development, January 21-22, 2014 , Warananagar,
              Kolhapur, India.
            </li>
            <li>
              Gite S.M, Mirani A, Kadwadkar N. A, Chogale M.M, Gugulothu D,
              Patravale V. B, “Design Expert: An integration of software
              solution for nanoformulation experimentations” at the National
              level Seminar on Software in Drug Discovery and Development,
              January 21-22, 2014 , Warananagar, Kolhapur, India.
            </li>
            <li>
              Dalapathi Gugulothu, Prachi Kharkar, Meenal Ghune, Vandana
              Patravale “Hydrogel Nanoparticles of Curcumin: Potential in
              Treatment of Diabetes” at ICT-Nanobio 2013, Nov 23-24, 2013,
              Mumbai, India.
            </li>
            <li>
              Dalapathi Gugulothu, Namrata Kadwadkar, Parth Joshi, Vandana
              Patravale “Ellagic Acid loaded PLGA Vs pH sensitive nanoparticles:
              A therapy for treatment of inflammatory bowel disease” at
              ICT-Nanobio 2013, Nov 23-24, 2013, Mumbai, India.
            </li>
            <li>
              Dalapathi Gugulothu, Amit Mirani, Sulabha Pathak, Shobhona Sharma,
              Vandana Patravale “Nanostructured lipid carriers of
              Curcumin-Arteether Combination: Evaluating Drug Synergy in
              Malarial Model” at ICT-Nanobio 2013, Nov 23-24 , 2013, Mumbai,
              India.
            </li>
            <li>
              Dalapathi B. Gugulothu, Vandana B. Patravale, “Ellagic Acid loaded
              pH Sensitive Nanoparticles for the Treatment of Inflammatory Bowel
              Disease” poster presentation at“40th Annual Meeting and Exposition
              of the Controlled Release Society”, July 21-24, 2013, Hawaii
              Convention Center, Honolulu, Hawaii, U.S.A.
            </li>
            <li>
              Dalapathi Gugulothu, Namrata Kadwadkar, Sulabha Pathak, Shobhona
              Sharma, Vandana Patravale, “Solid lipid nanoparticles of
              Arteether/Ellagic acid: A novel combination therapy for treatment
              of malaria”, at Sixth annual international conference on
              “Innovation in the 21st Century: Clinical Pharmacology in current
              and Future environment”, April 21-22, 2013, Mumbai, India.
            </li>
            <li>
              Dalapathi B. Gugulothu, Vandana B.Patravale, “Curcumin-Ellagic
              acid loaded pH sensitive nanoparticles for treatment of
              inflammatory bowel disease”, at Sixth annual international
              conference on “Innovation in the 21st Century: Clinical
              Pharmacology in current and Future environment”, April 21-22,
              2013, Mumbai, India.
            </li>
            <li>
              Gugulothu D, Patel C, Pathak S, Suryavanshi S, Sharma S. S,
              Patravale V. B, “Solid lipid nanoparticles (SLNs) of arteether for
              improved therapy of malaria” at “Current Trends in Pharmaceutical
              Sciences: Advances in New Drug Discovery Technologies and
              Translational Research”, February 4-6th , 2013, Ahmadabad, India.
            </li>
            <li>
              Gugulothu Dalapathi, Kulkarni Aditya, Dandekar Prajakta, Patravale
              Vandana, “Curcumin-Celecoxib dual drug loaded pH-sensitive
              nanoparticulate combination therapy: a novel approach for the
              treatment of inflammatory bowel disease” at “13th International
              Symposium on Advances in Technology and Business Potential of New
              Drug Delivery Systems”, January 22-23, 2013, Mumbai, India.
            </li>
            <li>
              Dalapathi G, Sulabha P, Shital S, Shobona S, Vandana P,
              “Nanoencapsulated arteether: comparative anti-malarial efficacy
              studies” at “International Symposium on Drug Discovery for
              Infectious Diseases and Cancer”, January 16-17, 2013, Mumbai,
              India.
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>Observer TS-EAMCT-2020</li>
            <li>
              Received General Oral Presentation Grant (100 USD) by AOCE-SICEM
              2020 for presenting paper entitled “Design and Evaluation of
              Glipizide Loaded Bioadhesive and Buoyant Microspheres Using
              Natural Polysaccharides for Improved Anti Diabetic Efficacy”,
              AOCE-SICEM 2020, South Korea, October, 28-31, 2020.
            </li>
          </ul>
          <ul>
            <li>
              Received best poster presentation award for poster entitled “
              <strong>
                Effect of Natural Biopolymers and Synthetic Polymers on Floating
                tablets of Silymarin
              </strong>
              ”, Honey Jasmine, Dalapathi<strong> Gugulothu</strong>, Suraj
              Kumar Choudhary, and Ramya Nagapuri, at DST-SERB sponsored Two Day
              National level seminar on NMR Techniques and its Applications in
              Pharmaceutical Sciences organized by Jayamukhi College of
              Pharmacy, Narsampet, Warangal, Telangana, India, held on 20
              <sup>st </sup>-21<sup>st</sup> September, 2019.
            </li>
            <li>
              <strong>
                Received First Prize forpaper entitled Exploring{" "}
                <em>Colacasia Esculanta </em>Fruit Dried Extraxt As
                Pharmaceutical Excipients, <u>Dalapathi Gugulothu</u>
              </strong>{" "}
              and Suraj Kumar Choudhary, at A Two Day National Conference on
              Advances in drug Delivery and Innovations in Pharma Industry
              organized by Balaji Institute of Pharmaceutical Sciences in
              Association with Telangana Academy of Sciences , Hyderabad , TS,
              India at Balaji Institute of Pharmaceutical Sciences, Narsampet,
              Warangal, TS, India, on 27-28 ,March ,
            </li>
            <li>
              <strong>
                Received First Prize(E-Poster) Exploring{" "}
                <em>Rumex Vasicarious</em> Dried Extract As A Pharmaceutical
                Additives In Novel Drug Delivery System,{" "}
                <u>Dalapathi Gugulothu</u>
              </strong>
              , Suraj Kumar Choudhary and Durga prasad Bukka, at A Two Day
              National Conference on Advances in drug Delivery and Innovations
              in Pharma Industry organized by Balaji Institute of Pharmaceutical
              Sciences in Association with Telangana Academy of Sciences ,
              Hyderabad, TS, India at Balaji Institute of Pharmaceutical
              Sciences, Narsampet, Warangal, TS, India, on 27-28 ,March ,
            </li>
            <li>
              Elected as an “Associate Fellow of the Andhra Pradesh Akademy of
              Sciences” for the year 2018, Andhra Pradesh Akademy of Sciences,
              Amaravathi, Andhra Pradesh, India.
            </li>
            <li>
              Attended 3<sup>rd</sup> International Conference on Innovations in
              Pharmaceutical Sciences as a Resource person (Poster evaluator) at
              Gurunanak Group of Institutions, Hyderabad, Telangana, India, held
              on 3-4<sup>th</sup> Aug 2018.
            </li>
            <li>
              Elected as an “Associate Fellow of the Telangana Academy of
              Sciences”, Telangana Academy of Sciences, Hyderabad, India.
            </li>
            <li>
              Attended 69<sup>th</sup>Indian Pharmaceutical Congress, Rajpura,
              India as aResource Person (Poster Evaluator) held on 22- 24
              <sup>rd</sup>December 2017.{" "}
            </li>
            <li>
              Received Dr. Ambedkar National Fellowship Award from Bharathiya
              Dalith Sahithya Academy(BDSA), New Delhi, India at the 33
              <sup>rd</sup> NATIONAL CONFERENCE OF DALIT WRITERS, New Delhi,
              9-10<sup>th</sup> December 2017.
            </li>
            <li>
              Received Prof. Jayashankar Best teacher award from Bharathiya
              Dalith Sahithya Academy (BDSA), New Delhi, India at the 7
              <sup>th</sup> South Indian Conference held at Munnar, Kerala,
              India on 9-10<sup>th</sup> September 2017.
            </li>
            <li>
              Participated and acted as an adjudicator/Evaluator for National
              Seminar entitled{" "}
              <strong>
                “Essential Pharmacy Education, Research and Practice: Advanced
                Pharmaceutical Skills with Innovative Ideas”
              </strong>{" "}
              held on 10-02-2017 to <strong>11-02- 2017</strong>at M.A.M.
              College of Pharmacy, Narasaraopeta, Guntur, Andhra Pradesh, India.
            </li>
            <li>
              Received <strong>National Quality Excellence Award</strong> (Award
              for Best Quality Researcher of the Year 2017) by Praxis Media
              Group New Delhi, India.
            </li>
            <li>
              Received{" "}
              <strong>Savithribai Phule State level best teacher award</strong>{" "}
              on 03-01-2017 by Savithribai Phule Praja Sankshema Sangam(SPPSS),
              Hyderabad, India.
            </li>
            <li>
              Enrolled as{" "}
              <strong>FELLOW OF ACADEMY GENERAL EDUCATION (F.A.G.E),</strong>{" "}
              Manipal, Karnataka, India on 08/12/2016.
            </li>
            <li>
              Selected as a “Nominee”- Committee for the Purpose of Control and
              Supervision of Experiments (<strong>CPCSEA</strong>) by Ministry
              of Environment, Forest &amp; Climate Change (Animal Welfare
              Division), Government of India, New Delhi for Various Institutes
              in India.
            </li>
            <li>
              Awarded <strong>second prize</strong> for poster entitled
              “anti-malarial efficacy of lipid nanoparticles: combination
              ofcurcumin-arteether vs ellagic acid-arteether”{" "}
              <strong>Gugulothu D</strong>, Pawar R, Pathak S, Sharma S,
              Patravale V at the 6<sup>th</sup> Indo-Japanese International
              SymposiumOvercoming Intractable Infectious Diseases Prevalent in
              Asian Countries, September, 23 -24, 2016, Goa, India.
            </li>
            <li>
              Received <strong>Warangal District Best Teacher Award</strong> for
              the Year of 2015-2016 by the Srinivasa Ramanujan Foundation (SRF),
              Hyderabad, Telangana State, India.
            </li>
            <li>
              Awarded <strong>first prize </strong>for a poster entitled
              “Polymeric Nanoparticulate Delivery of Curcumin-Ellagic
              acid:Synergistic Potential for Inflammatory Bowel Disease
              Therapy”,{" "}
              <strong>
                <u>Dalapathi B. Gugulothu</u>
              </strong>
              ,Sudeep S. Pukale, Vandana B. Patravale at National seminar on
              Advances in Pharmaceutical Technology and its Business Potential,
              Yadavrao Tasgaokar college of Pharmacy, February 21, 2014, Karjat,
              Raigad, India.
            </li>
            <li>
              Awarded first<strong> poster prize</strong> for a paper entitled
              “Design Expert: An integration of software solution for
              nanoformulation experimentations”, Gite S.M, Mirani A, Kadwadkar
              N. A., Chogale M.M,{" "}
              <strong>
                <u>Gugulothu D</u>
              </strong>
              , Patravale V. B at National Level Seminar on Software in Drug
              Discovery and Development, January 21-22, 2014, Warananagar,
              Kolhapur, India.
            </li>
            <li>
              Received <strong>Financial Assistance</strong> from{" "}
              <strong>Controlled Release Society-Indian Chapter, Mumbai</strong>
              , <strong>India</strong>, for presenting research papers at “40
              <sup>th</sup> Annual Meeting and Exposition of the Controlled
              Release Society”, July 21-24, 2013, Hawaii Convention Center,
              Honolulu, Hawaii, U.S.A.
            </li>
            <li>
              Received <strong>Financial Assistance </strong>from
              <strong> SERB-DST, GOVT of India, New Delhi,</strong> for
              presenting research papers at “40<sup>th</sup> Annual Meeting and
              Exposition of the Controlled Release Society”, July 21-24, 2013,
              Hawaii Convention Center, Honolulu, Hawaii, U.S.A.
            </li>
            <li>
              Awarded second<strong> best poster prize</strong> for a paper
              entitled “Solid lipid nanoparticles of Arteether/Ellagic acid: A
              novel combination therapy for treatment of malaria”,{" "}
              <strong>
                <u>Dalapathi Gugulothu</u>
              </strong>
              <u>,</u> Namrata Kadwadkar, Sulabha Pathak, Shobhona Sharma,
              Vandana Patravale, at Sixth annual international conference on
              “Innovation in the 21<sup>st </sup>Century: Clinical Pharmacology
              in current and Future environment”, April 21-22,2013, Mumbai,
              India.
            </li>
            <li>
              Awarded <strong>Appreciation certificate</strong> for a PhD mentor
              for Industry Defined Problem event held from 10-13<sup>th</sup>{" "}
              January 2013 at the Institute of Chemical Technology by 7th Young
              Innovators Choice Competition (YICC-2013).
            </li>
            <li>
              Awarded <strong>best poster prize</strong> for a paper entitled
              “Curcumin-celecoxib dual drug loaded pH-sensitive nanoparticulate
              combination therapy: a novel approach for the treatment of
              inflammatory bowel disease”{" "}
              <strong>
                <u>Gugulothu Dalapathi</u>
              </strong>
              , Kulkarni Aditya, Dandekar Prajakta, Patravale Vandana at “13
              <sup>th</sup> International Symposium on Advances in Technology
              and Business Potential of New Drug Delivery Systems”, January
              22-23, 2013, Mumbai, India.
            </li>
            <li>
              Received <strong>Travelling grant from DBT-GOVT of India,</strong>{" "}
              for attending the conference, “COLLOIDS AND NANOMEDICINE- 2012”,
              July 15<sup>th</sup> – 17<sup>th</sup>, 2012, Amsterdam, The
              Netherlands.
            </li>
            <li>
              Awarded <strong>first prize for</strong> a paper entitled
              “Bioenhancement of Curcumin Using Hot Melt Extrusion Technology:
              Formulation Development, <em>In Vitro</em> Characterization and{" "}
              <em>In Vivo</em> Pharmacokinetic Studies”, Desai P. P., Gokarna V.
              S,{" "}
              <strong>
                <u>Gugulothu D. B</u>
              </strong>
              <u>,</u> Deshpande V. D. and Patravale V. B. At
              “DrugDeliveryIndia2012– Innovations in Pharmaceutical &amp;
              Manufacturing Sciences”, Hyderabad, India, February2012.
            </li>
            <li>
              Awarded <strong>first prize</strong> for a paper entitled “Freeze
              Drying: Exploring Potential in Development of Orodispersible
              tablets of Sumatriptan Succinate” Pandharipande P. P, Dalapathi
              B.G, Desai P. P, Patravale V.B, at “63<sup>rd Indian</sup>{" "}
              Pharmaceutical Congress”, December 13-18, 2011, Bangalore, India.
            </li>
            <li>
              Awarded{" "}
              <strong>Shri G.M Abhyankar Travel Assistance Award</strong> from
              Institute of Chemical Technology, Mumbai, for presenting research
              a paper at “37<sup>th</sup> Annual Meeting and Exposition of the
              Controlled Release Society”, July 10-14, 2010, Oregon Convention
              Center, Portland, Oregon, U.S.A by India.2010.
            </li>
            <li>
              <strong>Received Senior Research Fellowship </strong>(2009-2014)
              <strong> from </strong>University Grants Commission, New Delhi,
              GOVT of India.
            </li>
            <li>
              <strong>Received Junior Research Fellowship </strong>(2005-2007)
              from University Grants Commission, New Delhi, GOVT of India.
            </li>
            <li>
              <strong>
                Qualified Graduate Aptitude Test in Engineering (GATE-2005), All
                India Rank 928 (91.91 Percentile).
              </strong>
            </li>
            <li>
              Received{" "}
              <strong>certificate of merit for qualifying GATE-2005</strong>{" "}
              from Talla Padmavathi College of Pharmacy, Orus, Warangal,
              Telangana State.
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrDalapathiGugulothu;
