import React, { useState } from "react";

import profimg from "./IMG_0620-scaled-e1688470149347.jpg";

const DrNeetikaSharma = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Neetika Sharma</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor |  Department of Pharmaceutical Chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            
            neetika [dot] sharma1810 [at] dpsru [dot] edu [dot] in
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.com/citations?hl=en&user=qHSo4MwAAAAJ">Google Scholar </a></li>
            <li> <a href="https://www.scopus.com/authid/detail.uri?authorId=57213701819">Scopus</a></li>
            <li>
              <a href="https://orcid.org/0000-0002-0854-5609">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Ph.D.
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Pharmaceutical Analysis & Chemometrics
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        <p><span >Publications</span></p><ol><li ><span >Vasisht K, Sharma N, Karan M. Current Perspective in the International Trade of Medicinal</span></li><li ><span >Plants Material: an Update. Current Pharmaceutical Design. 2016; 22 (27): 4288-4336. I.F. 3.5.</span></li><li ><span >Jena AK, Vasisht K, Sharma N, Kaur R, Dhingra MS, Karan M. Amelioration of testosterone induced benign prostatic hyperplasia by Prunus species. Journal of Ethnopharmacology. 2016;190(22 August, 2016):33-45. I.F. 3.05</span></li><li ><span >Karan M, Jena AK, Sharma N, Vasisht K, A Systematic Analysis of Prunus Species with a Focus on Management Plan of Prunus africana (Hook.f.) Kalkman: An Autochthon Plant of Africa. European Journal of Medicinal Plants. 2017; 20 (3): 1-24.</span></li></ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         <ol><li ><span >Won second prize in Oral session on the theme “Emerging trends in Novel Formulations of Herbal Drugs (O-TNF)” at International Conference on Challenges for Global Competitiveness of Ayush Natural Products IASTAM Oration and award function, 2018, at DIPSAR, New Delhi.</span></li></ol>
        </div>
      ) : null}
    </div>
  );
};

export default DrNeetikaSharma;
