import React from "react";
import "./PushpPradarshanam.css";

import dpsruLogo from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/dpsru-logo.png";

import amritMahotsav from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/75-azadi-ka-amrit-mahotsav.png";
import nssLogo from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/national-service-scheme.png";

import pp1 from './Pushp Pradarshanam Datafiles/Pushp-Pradarshan-1.jpg'
import pp2 from './Pushp Pradarshanam Datafiles/pusp-pradarshan-2.jpg'
import pp3 from './Pushp Pradarshanam Datafiles/pusp-pradarshan-3.jpg'
import pp4 from './Pushp Pradarshanam Datafiles/pusp-pradarshan-4.jpg'
import pp5 from './Pushp Pradarshanam Datafiles/pusp-pradarshan-5.jpg'
import pp6 from './Pushp Pradarshanam Datafiles/pusp-pradarshan6.jpg'

const PushpPradarshanam = () => {
  return (
    <div className="G20MahaFoodFestival-component">
      <div className="upper-logo-container-of-g20-maha-food-festival">
        <img src={dpsruLogo} alt="Dpsru Logo" />
        <img src={amritMahotsav} alt="amrit Mahotsav" />
        <img src={nssLogo} alt="Nss" />
      </div>

      <h1 className="pushp-pradarshanam-dpsru-heading">
        Delhi Pharmaceutical Sciences and Research University
      </h1>
      <h2 className="g20-maha-food-festival-heading">
        “REPORT on PUSHP PRADARSHNAM 2023”
      </h2>
      <h4 className="g20-maha-food-festival-heading pushp-pradarsanam-date-heading">
        27-28 February, 2023
      </h4>

      <p className="g20-maha-food-festival-paragrauph">
        The <span style={{fontWeight:'900',color:'red'}}>PUSHP PRADARSHNAM 2023</span> was organised by National Service Scheme
        (NSS) unit under aegis of G20 Presidency of India at Delhi
        Pharmaceutical Sciences and Research University (DPSRU) on 27-28
        February, 2023. <br />
        <br />
        The chief guest Ms. Aaruchi Jain along with Prof. Ramesh K. Goyal
        (Vice-Chancellor), Dr. Harvinder Popli (Director of DPSRU SPS), Dr.
        Akshita Gupta (Dietician), Dr. Saurabh Dahiya (Nodal Officer
        G20)inaugurated the <span style={{fontWeight:'bolder',color:'red'}}>PUSHP PRADARSHNAM</span> by cutting the ribbon.
        <br />
        <br />
        The event was organized by faculty incharge Dr. Vijender Kumar
        accompanied by faculty co-ordinators Dr. Meenakshi Garg, Dr. Sumit
        Sharma, NSS programme co-ordinator Dr. Prabodh Chander Sharma and NSS
        programme officer, Dr. Sahil Kumar with support from NSS Volunteers and
        Student Co-ordinators. Around 450 flowers pots were arranged to decorate
        the campus with the aesthetic Aroma of flowers and beautiful pots.
        <br />
        <br />
        In this event, a total of 7 competitions in different categories had
        been conducted for students to participate and award with :
    
      <ol className="g20-maha-food-festival-paragrauph pushp-pradarshanam-ol">
        <li>
        Best decorated Indoor flower pot
        </li>
        <li>Best decorated medicinal plant</li>
        <li>Best decorated flower pot</li>
        <li>Best decorated hanging flower pot</li>
        <li>Best shayari / poetry on flower</li>
        <li>Best Floral Rangoli</li>
        <li>Best Poster Presentation</li>
      </ol>


      Some of the attractions of flower pots were Marigold Orange, Dianthus, Salvia, Lineraria, Dglaonema etc. <br /><br />
      The visitors discussed about the flower pots placed there with the participants such as KALE,ROSE, ANTIRRHINUM, SALVIA, AGLONEMA, MARIGOLD etc. <br /><br />

Prof. Ramesh K. Goyal (Vice Chancellor, DPSRU) asked the students to be innovative and aware of the medicinal plants. He encouraged the students with his motivated words.<br /><br />

A press reporter from “Times News Express” interviewed Prof. Ramesh K. Goyal (Vice Chancellor, DPSRU) about the Flower show event. He said that we are going to create a garden of various plants of various countries. He appreciated the faculties Dr. Vijender Kumar (Assistant Professor) and NSS team.<br /><br />

Then the reporter interviewed Dr. Vijender Kumar (Assistant Professor) and Dr. Saurabh Dahiya about the Flowers show. Later the  reporter interviewed the students participated in flower show. The students interviewed by the reporter are Vanshika (BSc), Saurabh Sharma and Ankit Rajan. The event was concluded by Dr. Sahil Kumar, NSS Programme officer after proposing vote of thanks on “PUSHPA PRADARSHNAM 2023”.
      </p>
      <div className="galery-conteiner-of-g20-maha-food-festival">
        <h3 className="galery-heading-of-g-20-maha-food-f">
        Gallery
        </h3>
      <div className="image-container-of-g-20-gallery pushp-pradarshan-galery">
          <img src={pp1} alt="galery" />
          <img src={pp2} alt="galery" />
          <img src={pp3} alt="galery" />
          <img src={pp4} alt="galery" />
          <img src={pp5} alt="galery" />
          <img src={pp6} alt="galery" />
          
        </div>
    </div>
    </div>
  );
};
export default PushpPradarshanam;
