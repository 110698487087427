import React from "react";
import "./Project.css";

const Project = () => {
  return (
    <div className="area-of-research-component rd-project-component">
      <h2 className="area-of-reserch-heading aboutUs-heading">
        FUNDED PROJECTS
      </h2>

      <div className="rd-project-table-container">
        {/* table */}
        <table>
          <tbody>
            <tr className="table-head-of-project-data">
              <td>
                <p>Title of the Project</p>
              </td>
              <td>
                <p>Principal Investigator/s</p>
              </td>
              <td>
                <p>Funding agency</p>
              </td>
              <td>
                <p>Department of PI</p>
              </td>
              <td>
                <p>Year of Grant</p>
              </td>
              <td>
                <p>Funds (INR in Lakhs)</p>
              </td>
              <td>
                <p>Duration</p>
                <p>(In Months)</p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  Pharmacological evaluation and comparison of antidiabetic
                  activity of Qurs-Ziabetus Khas, the Unani formulation with
                  metformin in experimental models of diabetes
                </p>
              </td>
              <td>
                <p>Dr Mukesh Nandave(PI)</p>
                <p>&nbsp;Prof. Ajay Sharma (Co-PI)</p>
              </td>
              <td>
                <p>CCRUM</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>31.9</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Development and evaluation of new intranasal nanoformulation
                  of anti-epileptic Unani Medicinal herbs
                </p>
              </td>
              <td>
                <p>Dr Gaurav Jain</p>
              </td>
              <td>
                <p>CCRUM</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>30.53</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>DST FIST Pharmaceutics</p>
              </td>
              <td>
                <p>Dr.Geeta Agarwal</p>
                <p>Dr Deepti Pandita</p>
                <p>Dr Gaurav Jain</p>
              </td>
              <td>
                <p>DST</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>200</p>
              </td>
              <td>
                <p>60</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>WCSC</p>
              </td>
              <td>
                <p>Dr Madhu Gupta</p>
              </td>
              <td>
                <p>Delhi Govt</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>123.73</p>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                <p>Center for Meditation and Yoga Science, DPSRU</p>
              </td>
              <td>
                <p>Dr Shilpa Jain</p>
              </td>
              <td>
                <p>Delhi Govt</p>
              </td>
              <td>
                <p>Physiotherapy</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>1000</p>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                <p>
                  Development and evaluation of Antidiabetic Formulation by
                  Targeting GPR120 and <br />
                  40 in Diabetes-Associated NAFLD: In silico, In Vitro and In
                  Vivo Approach
                </p>
              </td>
              <td>
                <p>Dr Mukesh Nandave</p>
              </td>
              <td>
                <p>ICMR</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>&nbsp;</td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Repurposing of recently FDA approved drugs alone and in
                  combination with standard antibiotics against bacterial
                  infections using network pharmacology and In-vitro techniques
                </p>
              </td>
              <td>
                <p>Dr.Anoop Kumar</p>
              </td>
              <td>
                <p>DST SERB</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>27</p>
              </td>
              <td>
                <p>24</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Novel leukocyte functionalized biomimetic lipid nanoparticles
                  platform for spatial and temporal delivery of therapeutic
                  agent to atherosclerotic plaques
                </p>
              </td>
              <td>
                <p>Dr.Gaurav Jain</p>
              </td>
              <td>
                <p>DST SERB</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2022</p>
              </td>
              <td>
                <p>30</p>
              </td>
              <td>
                <p>24</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>CCRH Collaborative Project</p>
              </td>
              <td>
                <p>Dr. Ajit Kumar Thakur</p>
              </td>
              <td>
                <p>CCRS</p>
              </td>
              <td>
                <p>Pharmacology &amp; Biotechnology</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>12.51</p>
              </td>
              <td>
                <p>6</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>DSIR</p>
              </td>
              <td>
                <p>Dr.Gaurav Jain</p>
                <p>Dr .Deepti Pandita</p>
                <p>Dr.Geeta Agarwal</p>
                <p>Prof. R.K. Goyal</p>
              </td>
              <td>
                <p>DSIR</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>500</p>
              </td>
              <td>
                <p>60</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To evaluate the protective role of medicinal plants against
                  breast cancer induced by 7,12 dimethyl benz anthracene, mobile
                  phone radiations and fructose drinking in aldoscent/young
                  female rodents
                </p>
              </td>
              <td>
                <p>Dr .Rajani Mathur</p>
              </td>
              <td>
                <p>ICMR</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>55.63</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Centre for Precision Medicine and Pharmacy</p>
              </td>
              <td>
                <p>Dr.Geeta Agarwal</p>
                <p>Dr Jaseela</p>
                <p>Dr.Ajit&nbsp; Thakur</p>
                <p>Prof .R.K. Goyal</p>
                <p>Prof .CR Patil</p>
              </td>
              <td>
                <p>Delhi Knowledge Development Foundation</p>
              </td>
              <td>
                <p>Pharmacy</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>500</p>
              </td>
              <td>
                <p>60</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Growth factor and silver nanoparticle embedded photo-curable
                  gelatin gels as tissue adhesives
                </p>
              </td>
              <td>
                <p>Dr. Mukesh Nandave</p>
              </td>
              <td>
                <p>ICMR</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>9.85</p>
              </td>
              <td>
                <p>3</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Comprehensive biological evaluation of different drug-loaded
                  surface exigneered dendrimer conjugates for treatment of
                  cancer
                </p>
              </td>
              <td>
                <p>Dr. Keerti Jain</p>
              </td>
              <td>
                <p>NIPER</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>17</p>
              </td>
              <td>
                <p>12</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Development of functionalized dual loaded herbal nano-colloial
                  carrier: Active targeting and repositioning of teriflunomide
                  of synergistically enhancing role of sulphoraphane in
                  combating triple negative breast cancer
                </p>
              </td>
              <td>
                <p>Dr Sushma Talegaonkar</p>
              </td>
              <td>
                <p>SERB</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2021</p>
              </td>
              <td>
                <p>27.19</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Tumor Model and Mechanistic Studies of the AC2, AC7 and AC26
                  for the treatment of Gliobastoma
                </p>
              </td>
              <td>
                <p>Dr. Mukesh Nandave</p>
              </td>
              <td>
                <p>SERB</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2020</p>
              </td>
              <td>
                <p>35.23</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Rivastigmine loaded nanostructured lipid carrier (NLC)
                  integrated scaffolds transdermal patch system for treatment of
                  Alzhemier’s disease and Parkinson disease dementia
                </p>
              </td>
              <td>
                <p>Dr Meenakshi &nbsp;K.Chauhan</p>
              </td>
              <td>
                <p>DST SERB</p>
              </td>
              <td>
                <p>Pharmaceutics</p>
              </td>
              <td>
                <p>2017</p>
              </td>
              <td>
                <p>20.54</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Impact of EMF radiations from mobile handset on brain glucose
                  transporter : An experimental study
                </p>
              </td>
              <td>
                <p>Dr Rajani Mathur</p>
              </td>
              <td>
                <p>ICMR</p>
              </td>
              <td>
                <p>Pharmacology</p>
              </td>
              <td>
                <p>2017</p>
              </td>
              <td>
                <p>14.05</p>
              </td>
              <td>
                <p>36</p>
              </td>
            </tr>
          </tbody>
        </table>
        {/* table */}
      </div>

      <h4 className="rd-project-second-heading">  Industry / Consultancy Projects</h4>
      <ul className="rd-project-second-ul-container">
        <li>Geeta Aggarwal. Industry sponsored (Cognitrex) consultancy project entitled ‘Market Research on Pharmaceutical and Biological Formulations’ completed in 2019-20.</li>
        <li>Madhu Gupta. Research grant received by TMA Pai University Research Fund under TMA Pai Seed Grant-Major on “Molecular characterization, detection of drug resistance genes, strain identification of MDR Acinetobacter baumanii and nanotechnology to identify novel natural products as antibiotic potentiators”: 10 Lakhs.
</li>
      </ul>

      <h4 className="rd-project-second-heading"> International Projects</h4>
      <ul className="rd-project-second-ul-container">
        <li>Mukesh Nandave, Pharmacological Evaluation of PHMZ Formulations in Experimental Rat Model of Traumatic Brain Injury funded by Pharmazz Inc., Illinois, USA.</li>
        <li>Mukesh Nandave, Pharmacological Evaluation of Topical Wound Healing Activity of Topical Gel and Biodegradable Hydrogel Film in Excision, Incision and Burn Injury Models of Wounds in Rats funded by Pharmazz Inc., Illinois, USA.
</li>
      </ul>
    </div>
  );
};

export default Project;
