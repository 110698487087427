import React, { useState } from "react";

import profimg from "../../DipsarFiles/Bodla.jpg";

const DrRameshBodla = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Ramesh Bodla</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutical Chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : 
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=vRHKJzIAAAAJ&hl=en">Google Scholar </a>
            </li>
            <li>
              <a>Scopus</a>
            </li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M. Pharm (Pharmaceutical Chemistry) , PhD (Pharmacy)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Design, docking, synthesis, characterization and biological evaluation
        of novel medicinal molecules using heterocyclic scaffolds and polymeric
        prodrug design and synthesis for targeted drug delivery
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Plant tissue culture studies involving static and cell suspension
              cultures for the Bio Production of secondary metabolites of
              medical / biological / commercial interest.
            </li>
            <li>
              Synthetic Medicinal Chemistry for potential drug molecules or drug
              analogs and their Biological screening.
            </li>
            <li>
              Photochemical investigations for the isolation, purification and
              characterization of its chemical constituents and screening for
              Biological activity.
            </li>
            <li>
              HPLC Method development and validation for Drug Molecules and
              their combinations.
            </li>
          </ul>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p></p>
          <ol>
            <li>
              Trends in Hyphenated Analytical Techniques Basics, Concepts,
              Different methods (2011) K. Nagarajan, Ramesh B. Bodla, L.K. Ghosh
              LAMBERT Academic Publishing ISBN:978-38443-9542-6
            </li>
            <li>
              Fundamental Principles of Drug Discovery, K. Nagarajan, Ramesh B.
              Bodla (2018) LAMBERT Academic Publishing ISBN 978-613-998-1441
            </li>
            <li>
              Practical Biochemistry for Pharmacy Students, Sachin Kumar and
              Ramesh Bodla, Paging Publishers (2018) ISBN: 978-81-936309-8-3
            </li>
          </ol>
          <p>Research Papers</p>
          <ol>
            <li>
              <ol>
                <li>
                  Ravi Kant, Ramesh Bodla, RubinaBhutani, GarimaKapoor.
                  Difference spectrophotometric method for the simultaneous
                  estimation of moxifloxacin and cefiximetrihydrate in bulk and
                  combined dosage form. Indian journal of pharmacy and
                  pharmaceutical sciences; (7): 316-321.
                </li>
                <li>
                  Ravi Kant, Ramesh Bodla, RubinaBhutani, GarimaKapoor.
                  Spectrophotometric absorbance correction method for the
                  estimation of Tazobactam and Cefepime in combined tablet
                  dosage forms. Journal of Chemical and Pharmaceutical Research,
                  2015;7:648-656.
                </li>
                <li>
                  JK Badajatiya, RB Bodla, UB Moon. Enhancement of Solubility of
                  Fenofibrate by using different solubilization techniques Asian
                  Journalof Pharmacy and Life Science. 2011;1:144-148.
                </li>
                <li>
                  Ravi Kant, Ramesh Bodla, RubinaBhutani, GarimaKapoor.
                  Development and validation of novel spectrophotometric methods
                  for simultaneous estimation of pioglitazone and metformin in
                  bulk and fixed dosage forms by area under curve and dual
                  wavelength mode
                </li>
                <li>
                  JK Badajatiya, RB Bodla.Enhancement of dissolution of poor
                  water soluble aceclofenac by preparing nanoparticles Inventi
                  Rapid: NDDS, 2012 (1): 1-2, 2012.
                </li>
                <li>
                  JK Badajatiya, RB Bodla, PrashantSoni. A method for
                  spectrophotometric determination of tianeptine in bulk and
                  Capsule Dosage form. Asian Journal of Pharmacy and Medical
                  Science 2012;2:83-85.
                </li>
                <li>
                  Mujoria Rajesh Z, RB Bodla, Jaiswal Swati, Dhamande
                  Kishor.Niosome – The Magic Bullet. Journal of Biology,
                  Agriculture and Healthcare 2011;1:8-14.
                </li>
                <li>
                  Mujoria Rajesh Z, RB Bodla. A review on Transdermal drug
                  delivery system. Indo-Global Research Library 2011;1:52-56.
                </li>
                <li>
                  Mujoria Rajesh Z, RB Bodla. Niosomes-Challenge in Preparation
                  for Pharmaceutical Scientist. International Journal of Applied
                  Pharmaceutics 2011;3:11-15.
                </li>
                <li>
                  Mujoria Rajesh Z, Diwaker Singh, RB Bodla, DhamandeKishor.
                  Niosome-A novel drug delivery system. Innovative systems
                  design and Engineering
                </li>
                <li>
                  Mujoria Rajesh Z, DhamandeKishor, RB Bodla. A review on study
                  of swine flu. Indo-Global Research Library 2011;1:47-51.
                </li>
                <li>
                  Mujoria Rajesh Z, RB Bodla, DhamandeKishor, Devendra Singh,
                  LokeshPatle. Niosomal Drug Delivery System: The Magic Bullet.
                  Journal of Applied Pharmaceutical Science 2011;1:20-23.
                </li>
                <li>
                  Mujoria Rajesh Z, DhamandeKishor, RB Bodla.Niosomal Drug
                  Delivery System: A review. International Journal of Applied
                  Pharmaceutics 2011;3:7-10.
                </li>
                <li>
                  Badjatya, J.K &amp; Bodla, R.B. (August, 2013). Harmonization
                  &amp; advancement in Pharmaceutical Industry.
                  <i>International Journal of Drug Regulatory Affairs,</i>
                  1(2),7-10.
                </li>
                <li>
                  Badjatya, J.K &amp; Bodla, R.B. (August, 2013). Drug Product
                  Registration in Semi-Regulated Market.
                  <i>International Journal of Drug Regulatory Affairs,</i>
                  1(2), 1-6.
                </li>
                <li>
                  Badjatya, J.K, Bodla, R. &amp; Musyuni, P. (2013,
                  January).Export Registration of Pharmaceuticals in Rest of
                  World Countries (ROW).
                  <i>Journal of Drug Delivery &amp; Therapeutics</i>, 3(1),
                  61-64.
                </li>
                <li>
                  Badjatya, J.K, Bodla, R.B. &amp; Soni, P. (2012). A Method for
                  Spectrophotometric determination of Tianeptine in Bulk and
                  Capsule Dosage Form.
                  <i>Asian Journal of Pharmacy and Medical Science</i>, 2 (5),
                  83-85.
                </li>
                <li>
                  Badjatya, J.K, Bodla, R.B. &amp; Moon, U.B. (2011).
                  Enhancement of solubility of Fenofibrate by using different
                  Solubilization techniques.
                  <i>Asian Journal of Pharmacy &amp; Life Science</i>, 1 (2),
                  144-148.
                </li>
                <li>
                  Badjatya, J.K, Bodla, R.B., Solanki, S. &amp; Kumar, D.
                  (2011). Enhancement of solubility of Paclitaxel by Solid
                  dispersions techniques.
                  <i>Asian Journal of Pharmacy &amp; Life Science</i>, 1 (2),
                  156-160.
                </li>
                <li>
                  Badjatya, J.K, Bodla, R.B. &amp;&amp; Kumar, S. (2012).
                  Preparation of dossier for the new formulation as per ICH
                  EU-CTD format: A Review.
                  <i>Asian Journal of Pharmacy and Medical Science</i>, 2 (5),
                  86-88.
                </li>
                <li>
                  Badjatya, J.K &amp; Bodla, R.B. (2012). Enhancement of
                  dissolution of poorly water soluble Aceclofenac by preparing
                  Nanoparticles.
                  <i>Inventirapid:NDDS,</i>
                  2012(1),1-2.
                </li>
                <li>
                  Nagarajan K., Bodla R.B.,et.al Accessibility of Topological
                  Descriptors In Ligand BasedDrug Design of Certain Nevirapine
                  Molecular Alalogues and Glycoproteins Against HIV Reverse
                  Transcriptase, Pharmacology online 3:497-505 (2010)
                </li>
                <li>
                  Nagarajan K., Bodla R.B.,et.al Activity Guided fractionation
                  and Identification of Fatty Acid Derivative from Alcoholic
                  Extract of Wrightia omentosa” Der Pharma Chemica. 3(6): 370-74
                  (2011)
                </li>
                <li>
                  Nagarajan K., Bodla R.B.,et.al. A Simple Precise Analytical
                  Method of Predicting Concentration of Common Fungicide in
                  Swimming Pools by MSA Calibration Technique, International
                  Journal of Chemical and Analytical Science, 2(6): 41-43 (2011)
                </li>
                <li>
                  Nagarajan K., Bodla R.B.,et.al. Phytochemical Extraction,
                  Optimization and Physico Chemical Characterization of two Bio
                  Active Isolates from the leaves and stem of Cissampelos
                  pareira, Der Pharma Chemica. 3(1): 327-337 (2011)
                </li>
                <li>
                  Nagarajan K., Bodla R.B.,et.al. Isolation of Mangiferin and
                  Amyloid beta-protein From n-Hexane Extract of Roots of
                  Wrightia Tomentosa, J of Medicinal Plants Research
                  6(40):5311-5316 (2012)
                </li>
                <li>
                  Kumar S., Bodla R. B., et al. Antioxidant activity of Leaf
                  extract of
                  <i>Aegle marmelos</i>
                  Correa ex Roxb. Pharmacog J. 8(5):447-450 (2016).
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>
              Paper published on “Studies on azabicyclo systems, synthesis and
              spasmolytic activity of analogs of 9-methyl-3, 9-diazabicyclo
              [4.3.1.] nonane and 10-methyl-3, 10-diazabicyclo [4.3.1.] decane”,
              <i>in (Eur.J.Med.Chem.,22 1987) pp.573-577.</i>
            </li>
          </ul>
          <ol>
            <li>
              <ol>
                <li>
                  Paper published on “Influence of Precursor Supplementation on
                  Bioproduction of Capsaicine in static cultures of C. annum,”
                  in
                  <i>Ind. J.of Pharm.Sci.</i>
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>
              Paper presented (oral) on “Influence of organogenesis on
              bioproduction of Emetine alkaloid in static cultures of Cephalis
              ipecacuahna” in International Research congress on natural
              products, 32 nd annual meeting of the
              <i>American Society of Pharmacognosy held at Chicago Illinois.</i>
            </li>
          </ul>
          <ol>
            <li>
              Paper Published on “Influence of age on growth and primary
              metabolites in static cultures of Capsicum annum” in
              <i>Ind. J. of Exp. Biol</i>.
            </li>
            <li>
              Kumar S, Bodla R B. et.al. Pre-clinical screening methods in
              cancer. Indian J Pharmacol (accepted).
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <ol>
            <li>
              Organising committee member for XXVII AICTE sponsored Quality
              Improvement Programme at DIPSAR during 2 nd April to 6 th April,
              2018.
            </li>
            <li>
              Resource person during for XIX AICTE sponsored Quality Improvement
              Programme at DIPSAR during 9 th to 20 th March, 2015.
            </li>
            <li>
              Organising committee member for XX AICTE sponsored Quality
              Improvement Programme at DIPSAR during 5 th to 9 th December,
              2016.
            </li>
            <li>
              Organising committee member for XXI AICTE sponsored Quality
              Improvement Programme at DIPSAR during 30 th January to 10 th
              February, 2017.
            </li>
            <li>
              Organising committee member for XXII AICTE sponsored Quality
              Improvement Programme at DIPSAR during 13 th to17th February,
              2017.
            </li>
            <li>
              Organising committee member for XXV AICTE sponsored Quality
              Improvement Programme at DIPSAR during 5 th to 9 th March, 2018.
            </li>
            <li>
              Organising committee member for XXVI AICTE sponsored Quality
              Improvement Programme at DIPSAR during 12 th to 16 th March, 2018.
            </li>
            <li>
              Participate in Indian Pharmacopeia Comission Conference 2022 on
              “Addressing Medicine Quality for Future”, held at Vigyan Bhawan,
              New Delhi on 1 st July, 2022.
            </li>
            <li>
              Received certificate of aooreciation for volunteering time and
              efforts in book releasing ceremony of “Pharmacologists of India:
              Their contributions” held at DIPSAR on 18 th November, 2016.
            </li>
            <li>
              Participated as Chairperson during International Conference on
              Challenges for Global Competitiveness of Ayush and Natural
              Products and IASTAM oration and award function held at DIPSAR
              during 2 nd to 4 th February, 2018.
            </li>
            <li>
              Participated as Organising Member during International Conference
              on Challenges for Global Competitiveness of Ayush and Natural
              Products and IASTAM oration and award function held at DIPSAR
              during 2 nd to 4 th February, 2018.
            </li>
            <li>
              Invited speaker during Faculty Development Programme on “Herbal
              Nanopharmacology” during 15 th to 19 th July, 2019 sponsored by
              Dr. A.P.J. Abdul Kalam Technical University held at KIET School of
              Pharmacy.
            </li>
            <li>
              Participated in 70 th Indian Pharmaceutical Congress Association
              held at Amity University, Noida during 21 st to 23 rd December,
              2018.
            </li>
            <li>
              Chaire Scientific session during 2 nd National Conference on
              Recent Advances and Challenges in Healthcare organized by SoMAS,
              held at GD Goenka University on 16 th March, 2019.
            </li>
            <li>
              Participated and presented a paper in 46 th Indian Pharmaceutical
              Congress Association held at Punjab University, Chandigarh during
              28 th to 30 th December, 1994.
            </li>
            <li>
              Organising Team Member during National Conference on
              Pharmacovigilance, Pharmacoeconomics and outcomes Research held at
              DIPSAR on 9 th to 10 th October, 2018.
            </li>
            <li>
              Chairperson in International Conference of Cardiovascular Sciences
              2020 held at DPSR University during 21 st to 23 rd February, 2020.
            </li>
            <li>
              Organizing Committee Member in International Conference of
              Cardiovascular Sciences 2020 held at DPSR University during 21 st
              to 23 rd February, 2020.
            </li>
            <li>
              Participated as Evaluator in 70 th Indian Pharmaceutical Congress
              Association held at Amity University, Noida during 21 st to 23 rd
              December, 2018.
            </li>
            <li>
              Participated in the AICTE sponsored National Seminar on “ Academia
              – Industry Interface in Pharmaceutical Sector organized by R K
              Goel Institute of Technlogy, Ghaziabad during 23 rd to 24 th
              October, 2010.
            </li>
            <li>
              Delivered a lecture in PCI sponsored Orientation Programme for
              Pharmacy Teachers on “ Challenges on Pharmaceutical Education and
              Research: Present, Past and Future held on 2 nd December, 2011.
            </li>
            <li>
              Participated as Co- Chairman of the Scientific session in 53 rd
              Indian Pharmaceutical Congress 2001, held in New Delhi during 21
              st to 23 rd December, 2001.
            </li>
            <li>
              Participated in “National Seminar on Microwave Synthesis”,
              organized by DIPSAR and Analytical Instruments Consortium held on
              16 th December, 2010.
            </li>
            <li>
              Presented lecture in “ National Conferenceon Recent aspects of
              Biological and Medicinal Chemistry (RABMC-2010) he at Lajpat Rai
              Post Graduate College, Sahibabad, Ghaziabad held during 20 th to
              21 st February, 2010.
            </li>
            <li>
              Participated as poster evaluator of the Scientific session in 65
              th Indian Pharmaceutical Congress 2013, held at Amity University
              Noida held during 20 th to 22 nd December, 2013.
            </li>
            <li>
              Participated as Co- Chairman of the Scientific session in 55 th
              Indian Pharmaceutical Congress 2003, held at Chennai during 19 th
              to 21 st December, 2003.
            </li>
            <li>
              Participated as Co- Chairman of the IPGA Conference 2005 held at
              FICCI Auditorium, New Delhi on 1 st October, 2005.
            </li>
            <li>
              Member Scientific Committee of the Scientific Committee during 53
              rd Indian Pharmaceutical Congress 2001, held in New Delhi during
              21 st to 23 rd December, 2001.
            </li>
            <li>
              Participated in the 26 th Annual conference of IPGA
              “Pharmaceutical opportunities and challenges of the New Decade
              held at New Delhi on 2 nd April, 2011.
            </li>
            <li>
              Rendered voluntary services during 45 th Indian Pharmaceutical
              Congress held at New Delhi during 23 rd to 25 th December, 1993.
            </li>
            <li>
              Co – Chairman Information Committee of the 53 rd Indian
              Pharmaceutical Congress 2001, held in New Delhi during 21 st to 23
              rd December, 2001.
            </li>
            <li>
              Participated in 65 th Indian Pharmaceutical Congress 2013, held at
              Amity University, Noida held during 20 th to 22 nd December, 2013.
            </li>
            <li>
              Participated in International symposium on “Recent advances in
              Pharmacotherapy of cardiovascular diseases” held on 16 th December
              at DIPSAR, New Delhi.
            </li>
            <li>
              . Participated in the 55 th Indian Pharmaceutical Congress 2003,
              held at Chennai during 19 th to 21 st December, 2003.
            </li>
            <li>
              Poster presentation in the 45 th Indian Pharmaceutical congress,
              held in New Delhi during 23 rd to 25 th December, 1993.
            </li>
            <li>
              Participated in the 45 th Indian Pharmaceutical congress, held in
              New Delhi during 23 rd to 25 th December, 1993.
            </li>
            <li>
              Chairperson in the India pharma convention organized by Udyog
              development foundation held at DIPSAR, New Delhi during 12 th
              February, 2012.
            </li>
            <li>
              Participated in the India pharma convention organized by Udyog
              development foundation held at DIPSAR, New Delhi during 12 th
              February, 2012.
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul><li ><span >Received a grant of Rs.5 lakhs under a major research project from UGC to carry out research on project entitled </span><b><i>“Biotechnological Investigations on few medicinal Plants for their secondary metabolite production”</i></b><span >.</span></li><li ><span >As in charge of Computer Center, responsible for setting up </span><b>128 kbph radio tower with the help of AICTE-ERNET</b><span > grant so that all the 60 terminals in the Delhi Institute of Pharmaceutical Sciences and Research in 2003.</span></li><li ><span >Received</span><b> SRF for three years, from Council of Scientific and Industrial Research</b><span >, New Delhi in 1990.</span></li><li ><span >Received </span><b>JRF from UGC</b><span > for two years 1983- 1985 during my Post graduation in Pharmacy</span></li><li ><span >Selected by UPSC, New Delhi, for the post of Lecturer at COP in 1992.</span></li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrRameshBodla;
