import React from 'react'
import './Committees.css'
import { Link } from 'react-router-dom'
import ugcRegulationsHarassment from './Committees Files/UGC_regulations-harassment.pdf'
import ministryoflawandjusticactonsexualharasment from './Committees Files/Ministry of law and justice ACT on sexual harassment shc_acts2013.pdf'
import handbookOnsexualHarasmentofWoment from './Committees Files/Handbook on Sexual Harassment of Women at Workplace.pdf'

function Committees() {
    
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">Committees</h2>

            <div className="committees-anti-ragging-container">


                <h4 className="dpsru-secondary-heading-for-every-page">
                    Anti-Ragging Committee</h4>
                <div className="anti-ragging-nodel-officer-container">
                    <h3 className="anti-ragging-head-name">
                        Nodal Officer for Anti Ragging Committee : </h3> <span>Prof. Rajiv Kumar Tonk, Dean</span>
                    <br /><br />

                    <h4 style={{ color: 'blue', fontSize: '1.2rem', textDecoration: 'underline', marginBottom: '2%' }}>Anti-Ragging Committee :-</h4>
                    {/* table */}
                    <table >
                        <tbody>
                            <tr style={{ backgroundColor: '#393939', color: 'white', fontSize: '1.3rem' }}>

                                <td >
                                    <p>Sr. No.</p>
                                </td>
                                <td >
                                    <p>Name of Officer</p>
                                </td>
                                <td >
                                    <p>Position in committee</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>1</p>
                                </td>
                                <td >
                                    <p> Dr. Harvinder Popli</p>
                                </td>
                                <td >
                                    <p>Chairman</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>2.</p>
                                </td>
                                <td >
                                    <p>Prof. S.R Wakode</p>
                                </td>
                                <td >
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>3.</p>
                                </td>
                                <td>
                                    <p>Prof. Geeta Aggarwal</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>4.</p>
                                </td>
                                <td>
                                    <p>Mr. Aditya Kaushik, Section Officer</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>5.</p>
                                </td>
                                <td >
                                    <p>Ms. Pritha Sarkar, PRO</p>
                                </td>
                                <td >
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>6.</p>
                                </td>
                                <td>
                                    <p>SHO, South area</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>7.</p>
                                </td>
                                <td>
                                    <p>Mr. Sanjay Sharma, Assistant Editor, Education – Times of India</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>8.</p>
                                </td>
                                <td>
                                    <p>Ms. Radhika Aggarwal, NGO</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>9.</p>
                                </td>
                                <td>
                                    <p>Class Representative of first year B.Pharm</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>10.</p>
                                </td>
                                <td>
                                    <p>Class Representative of first year BPT</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>11.</p>
                                </td>
                                <td>
                                    <p>Class Representative of First Semester M.Pharm</p>
                                </td>
                                <td >
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <p>12</p>
                                </td>
                                <td>
                                    <p>Class Representative of First Semester MBA</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>13</p>
                                </td>
                                <td>
                                    <p>Parents</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    {/* table */}

                    <h4 style={{ color: 'blue', fontSize: '1.2rem', textDecoration: 'underline', margin: '2% 0' }}>Anti-Ragging Committee :-</h4>

                    {/* table2 */}
                    <table>
                        <tbody>
                        <tr style={{ backgroundColor: '#393939', color: 'white', fontSize: '1.3rem' }}>
                                <td>
                                    <p>
                                        Sr. No.</p>
                                </td>
                                <td>
                                    <p>Name of Officer</p>
                                </td>
                                <td>
                                    <p>Position in committee</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>1</p>
                                </td>
                                <td>
                                    <p>Dr. Meenakshi K. Chauhan</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>2.</p>
                                </td>
                                <td>
                                    <p>Ms. Manju Vyas Singh, Girls Hostel Warden</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>3.</p>
                                </td>
                                <td>
                                    <p>Dr. Prabodh Chander Sharma</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>4.</p>
                                </td>
                                <td>
                                    <p>Dr. J. Swaminathan, Boys Hostel Warden</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* table2 */}

                    <p style={{margin:'2% 0', fontSize:"1rem"}}>The tenure/terms of the committee will be 3 years from the date of issue of this order.</p>

                    <p style={{margin:'2% 0', fontSize:"1rem"}}>In assistance on Ragging contact on:  <a href="tel:01129552040"> 011-29552040  </a>  &  <a href="tel:01129552039">011-29552039</a> (DPSR University) & toll free “helpline”  <a href="tel:18001805522">1800-180-5522</a> .</p>
                </div>

                <h4 className="dpsru-secondary-heading-for-every-page">
                Committee Against Sexual Harassment </h4>

                {/* Table3 */}
                <table>
                        <tbody>
                        <tr style={{ backgroundColor: '#393939', color: 'white', fontSize: '1.3rem' }}>
                                <td>
                                    <p>
                                        Sr. No.</p>
                                </td>
                                <td>
                                    <p>Name of Officer</p>
                                </td>
                                <td>
                                    <p>Position in committee</p>
                                </td>
                                <td>
                                    <p>Email</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>1</p>
                                </td>
                                <td>
                                    <p>Prof. Deepti Pandita</p>
                                </td>
                                <td>
                                    <p>Chairperson</p>
                                </td>
                                <td>
                                    <p>dpandita[at]dpsru[dot]edu[dot]in</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>2</p>
                                </td>
                                <td>
                                    <p>Dr. Saurabh Dahiya, Associate Professor</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                                <td>
                                    <p>saurabhdahiya[at]dpsru[dot]edu[dot]in</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>3</p>
                                </td>
                                <td>
                                    <p>Dr. Meenakshi Garg, Associate Professor</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                                <td>
                                    <p>minakshigarg[at]dpsru[dot]edu[dot]in</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>4</p>
                                </td>
                                <td>
                                    <p>Ms. Nisha, Assistant Registrar</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                                <td>
                                <p>nisha[at]dpsru[dot]edu[dot]in</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>5</p>
                                </td>
                                <td>
                                    <p>Ms. Rajrani Aggarwal, Saket Ward President, Ananya Foundation, Rani Laxmi Bai Brigade, NGO</p>
                                </td>
                                <td>
                                    <p>Member</p>
                                </td>
                                <td>
                                    <p>radhika[dot]26m[at]gmail[dot]com</p>
                                </td>
                            </tr>

                            </tbody>
                            </table>
                    
                {/* Table3 */}
                <p style={{margin:'2% 0', fontSize:"1rem", textAlign:'center'}}>The tenure/terms of the committee will be 3 years from the date of issue of this order.</p>

            </div>

            <div className="regulations-and-notifications-container-dpsru-commeettee">
                <h2 className="regulation-and-notifications-heading">
                Regulations / Notifications
                </h2>

                <ul className="regulation-and-notifications-ul">
                    <li>
                        <a href={ugcRegulationsHarassment}> UGC Regulations 2015-Prevention, probation & rederessal of sexual harresment of women employees and students in higher educational institutes.</a>
                    </li>
                    <li>
                        <a href={ministryoflawandjusticactonsexualharasment}>Ministry of law and justice ACT 2013 on sexual harassment at workplace.</a>
                    </li>
                    <li>
                        <a href={handbookOnsexualHarasmentofWoment}>Handbook on Sexual Harassment of Women at Workplace</a>
                    </li>
                </ul>
            </div>

            <p style={{margin:'3% auto', textAlign:'center', fontSize:'1.3rem' }}>Go to Administration page <Link to='/administration'>click Here </Link> </p>
        </div>
    )
}

export default Committees