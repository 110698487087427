import React, { useState } from "react";

import profimg from "./Dr.-MKC-Photo--150x150.jpg";

const DrMeenakshiKChauhan = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
        Dr. Meenakshi K.Chauhan
      </h2>
      <h3 className="facult-designation-for-each-container">
        Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a >
              mkchauhan [at] dpsru [dot] edu [dot] in
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=z6WcyOwAAAAJ&hl=en">
                Google Scholar
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=35326310300">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-7477-9761">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M. Pharm., Ph.D. (Faculty of Pharmaceutical Sciences)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Vesicular/particulate drug delivery- for improved bio-pharmaceutical
        performance through topical and oral routes. Ophthalmic drug delivery
        system. Formulation approaches to improve ocular bioavailability of
        drugs. Protein/peptide drug delivery technology.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>SI.N.</p>
                </td>
                <td>
                  <p>Research Project</p>
                </td>
                <td>
                  <p>Grants</p>
                </td>
                <td>
                  <p>Collaboration</p>
                </td>
                <td>
                  <p>Status</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>
                    Compartmentalized particles as a multiple drug delivery kit
                    for efficient management of Parkinson’s disease (PD)
                  </p>
                </td>
                <td>
                  <p>ICMR</p>
                  <p>(DMSE, IIT-Delhi)</p>
                </td>
                <td>
                  <p>DMSE,</p>
                  <p>IIT-Delhi</p>
                </td>
                <td>
                  <p>On-going</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>
                    Rivastigmine loaded nanostructured lipid carrier (NLC)
                    integrated scaffolds transdermal patch system for the
                    treatment of Alzheimer’s disease and Parkinson’s disease
                    dementia
                  </p>
                </td>
                <td>
                  <p>
                    Department of Science and Technology (DST) Fast tract Scheme
                    for Young Scientists, SERB
                  </p>
                </td>
                <td>
                  <p>DIPSAR</p>
                </td>
                <td>
                  <p>Completed</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>
                    Industrial specification, formulation characterization and
                    short-term toxicity effect evaluation of melatonin-based
                    formulations
                  </p>
                </td>
                <td>
                  <p>
                    INMAS, Govt of India, Ministry of Defence under CARS Project
                  </p>
                </td>
                <td>
                  <p>INMAS DRDO</p>
                </td>
                <td>
                  <p>Completed</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>
                    Tuberculosis chemotherapy: Novel Drug Delivery approach
                    using Bioadhesive Microspheres
                  </p>
                </td>
                <td>
                  <p>AICTE under Research Promotional Schemes (RPS)</p>
                </td>
                <td>
                  <p>DIPSAR</p>
                </td>
                <td>
                  <p>Completed</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Yadav N, Kumar U, Roopmani P, Krishnan UM, Sethuraman S, Chauhan
              MK, Chauhan VS. Ultrashort Peptide-Based Hydrogel for the Healing
              of Critical Bone Defects in Rabbits. ACS Applied Materials
              Interfaces. 2022 Nov 19.<b>(IF:10.38)</b>
            </li>
            <li>
              Kushwah H, Sandal N, Chauhan M, Mittal G. Fabrication,
              characterization and efficacy evaluation of natural gum-based
              bioactive haemostatic gauzes with antibacterial properties.
              Journal of Biomaterials Applications. 2022 Aug
              31:08853282221124366.<b>(IF:2.646)</b>
            </li>
            <li>
              Keerthana D, Mishra D, Chauhan MK, Juneja M. Effect of Propranolol
              Prophylaxis on Headache Frequency in Children with Migraine
              Without Aura: A Randomized, Double-Blind, Placebo-Controlled
              Trial. Indian Journal of Pediatrics. 2022 Jul 22:1-6.
              <b>(IF:5.319)</b>
            </li>
            <li>
              Yadav M, Kumar V, Sandal N, Chauhan MK. Quantitative evaluation of
              mercury adsorption and removal efficacy of Spirulina (Arthrospira
              platensis) powder in mice. Archives of Microbiology. 2022
              Jul;204(7):1-0.<b>(IF:2.667)</b>
            </li>
            <li>
              Yadav M, Rani K, Sandal N, Chauhan MK. An approach towards safe
              and sustainable use of the green alga Chlorella for removal of
              radionuclides and heavy metal ions. Journal of Applied Phycology.
              2022 Jun 7:1-7.<b>(IF:3.404)</b>
            </li>
            <li>
              Mittal S, Chakole CM, Sharma A, Pandey J, Chauhan MK. An Overview
              of Green Synthesis and Potential Pharmaceutical Applications of
              Nanoparticles as Targeted Drug Delivery System in Biomedicines.
              Drug Research. 2022 May 13.
            </li>
            <li>
              Yadav M, Kumar V, Jain S, Sandal N, Chauhan MK. Assessment of
              Adsorption and Removal Efficacy of Spirulina Powder for Strontium
              and Thallium. Research Square. 2022.
            </li>
            <li>
              Sharma PK, Sharma HP, Chakole CM, Pandey J, Chauhan MK.
              Application of Vitamin E TPGS in ocular therapeutics–Attributes
              beyond excipient. Journal of the Indian Chemical Society. 2022 Feb
              5:100387.<b>(IF:0.284)</b>
            </li>
            <li>
              Chakole CM, Sahoo PK, Pandey J, Chauhan MK. A green chemistry
              approach towards synthesizing hydrogel for sustained ocular
              delivery of brinzolamide: In vitro and ex vivo evaluation. Journal
              of the Indian Chemical Society. 2022 Feb 1;99(2):100323.
              <b>(IF:0.284)</b>
            </li>
            <li>
              Yadav M, Sharma P, Kushwah H, Sandal N, Chauhan MK. Assessment of
              the toxicological profile of Chlorella (C. vulgaris) powder by
              performing acute and sub-acute oral toxicity studies in mice.
              Journal of Applied Phycology. 2022 Feb;34(1):363-73.
              <b>(IF:3.404)</b>
            </li>
            <li>
              Yadav M, Kumar V, Sandal N, Chauhan MK. Quantitative evaluation of
              Chlorella vulgaris for removal of toxic metals from body. Journal
              of Applied Phycology. 2022 Feb 3:1-2. <b>(IF:3.404)</b>
            </li>
            <li>
              Mittal S, Kathuria I, Chauhan MK. In silico studies of
              prednisolone acetate derivatives for the treatment of dry eye
              disease. Journal of Advanced Scientific Research. 2021 Dec
              31;12(04 Suppl 1):242-9.
            </li>
            <li>
              Chakole CM, SahooPK, SharmaPK, ChauhanMK. Surface modified
              nanostructured lipid carrier for improved ocular delivery: in
              vitro and ex vivo study. Journal of Medical Pharmaceutical and
              Allied Sciences. 2021;1(1), 75-80.
            </li>
            <li>
              Chakole CM, Chauhan MK. Research progress of nanostructured lipid
              carriers in ocular drug delivery. Drug Delivery Letters. 2021 Sep
              1;11(3):203-19.<b>(IF:0.84)</b>
            </li>
            <li>
              Kushwah H, Sandal N, Chauhan MK, Mittal G. Pharmacological
              comparison of four biopolymeric natural gums as hemostatic agents
              for management of bleeding wounds: Preliminary in vitro and in
              vivo results. Future Journal of Pharmaceutical Sciences. 2021
              Dec;7(1):1-9.
            </li>
            <li>
              Yadav N, Chauhan MK, Chauhan VS. Conformationally constrained
              dipeptide-based hydrogel as a platform for 3D cell growth and
              tissue engineering applications. Applied Nanoscience. 2021
              Jul;11(7):2019-31.<b>(IF:3.869)</b>
            </li>
            <li>
              Jadhav NV, Singh N, Targhotra M, Chauhan MK. Impact of COVID-19 on
              Indian Pharmaceutical Industry and Way Forward. Infectious
              Disorders-Drug Targets (Formerly Current Drug Targets-Infectious
              Disorders). 2021 Jun 1;21(4):484-94.
            </li>
            <li>
              Mittal S, Sharma A, Aggarwal R, Chauhan MK. An overview of
              pathogenesis, emerging therapies, multi-organ manifestations and
              technologies in the treatment of COVID-19. Journal of Advanced
              Scientific Research. 2021 Apr 30;12(01 Suppl 2):15-33.
            </li>
            <li>
              Targhotra M, Aggarwal R, Chauhan MK. An Overview of FDA Regulatory
              Requirements for Vascular Devices. Applied Drug Research, Clinical
              Trials and Regulatory Affairs: Formerly Applied Clinical Research,
              Clinical Trials and Regulatory Affairs. 2021 Apr 1;8(1):53-62.
            </li>
            <li>
              Yadav N, Aggarwal R, Targhotra M, Sahoo PK, Chauhan MK. Natural
              and nanotechnology based treatment: an alternative approach to
              psoriasis. Current Nanomedicine (Formerly: Recent Patents on
              Nanomedicine). 2021 Mar 1;11(1):21-39.
            </li>
            <li>
              Aggarwal R, Targhotra M, Kumar B, Sahoo PK, Chauhan MK. Novel
              Polypseudorotaxanes Hydrogel based Nail Lacquer of Efinaconazole
              for Transungual Drug Delivery. Drug Delivery Letters. 2021 Mar
              1;11(1):52-61.
            </li>
            <li>
              Targhotra M, Aggarwal R, Chauhan MK. Bioactive Compounds for
              Effective Management of Drug-Resistant Tuberculosis. Current
              Bioactive Compounds. 2021 Feb 1;17(3):196-205.
            </li>
            <li>
              S Mittal, S Chand, A Kumari, Pandey. MK Chauhan, PK Sahoo.
              Assessment of prevalence and risk factors of hypertension in
              Indian population – an online pilot, J. Hosp. Pharmacy 16 ((2) 2-3
              Supplement) 2021.
            </li>
            <li>
              Sharma PK, Chauhan MK. Optimization and characterization of
              brimonidine tartrate nanoparticles-loaded in situ gel for the
              treatment of glaucoma. Current Eye Research. 2021 Nov
              2;46(11):1703-16.<b>(IF:2.555)</b>
            </li>
            <li>
              Yadav M, Soni R, Chauhan MK, Sandal N. Cellular and physiological
              approaches to evaluate the chelating effect of Chlorella on metal
              ion stressed lymphocytes. Biometals. 2021 Apr;34(2):351-63.
              <b>(IF:3.378)</b>
            </li>
            <li>
              Sharma A, Mittal S, Aggarwal R, Chauhan MK. Diabetes and
              cardiovascular disease: inter-relation of risk factors and
              treatment. Future Journal of Pharmaceutical Sciences. 2020
              Dec;6(1):1-9.
            </li>
            <li>
              Kumar N, Aggarwal R, Chauhan MK. Extended levobunolol release from
              Eudragit nanoparticle-laden contact lenses for glaucoma therapy.
              Future Journal of Pharmaceutical Sciences. 2020 Dec;6(1):1-4.
            </li>
            <li>
              Aggarwal R, Targhotra M, Sahoo PK, Chauhan MK. Efinaconazole nail
              lacquer for the transungual drug delivery: Formulation,
              optimization, characterization and in vitro evaluation. Journal of
              Drug Delivery Science and Technology. 2020 Dec 1; 60:101998.
              <b>(IF:5.062)</b>
            </li>
            <li>
              Targhotra M, Chauhan MK. An overview on various approaches and
              recent patents on buccal drug delivery systems. Current
              Pharmaceutical Design. 2020 Nov 1;26(39):5030-9.<b>(IF:3.310)</b>
            </li>
            <li>
              Sharma PK, Sandal N, Chauhan MK. Evaluation of precorneal
              residence time of brimonidine tartrate nanoparticles loaded in
              situ gel using gamma scintigraphy. Inorganic and Nano-Metal
              Chemistry. 2020 Aug 4;51(5):701-8.<b>(IF:1.716)</b>
            </li>
            <li>
              Sharma PK, Chauhan MK. Optimization and evaluation of encapsulated
              brimonidine tartrate-loaded nanoparticles incorporation in situ
              gel for efficient intraocular pressure reduction. Journal of
              Sol-Gel Science and Technology. 2020 Jul;95(1):190-201.
              <b>(IF:2.606)</b>
            </li>
            <li>
              Kushwah H, Hans T, Chauhan M, Mittal G, Sandal N. Development and
              Validation of the Spectrophotometric Method for the Determination
              of Menthol. Journal of Applied Spectroscopy. 2020 Jul;87(3):563-7.
              <b>(IF:0.816)</b>
            </li>
            <li>
              Aggarwal R, Targhotra M, Sahoo PK, Chauhan MK. Onychomycosis:
              Novel strategies for treatment. Journal of Drug Delivery Science
              and Technology. 2020 Jun 1; 57:101774.<b>(IF:5.062)</b>
            </li>
            <li>
              Shrivastav NK, Bajwan R, Chauhan MK. Inflammatory Bowel Disease: A
              Comprehensive Review. International Journal of Pharmaceutical
              Sciences and Nanotechnology. 2020 Mar 31;13(2):4808-14.
            </li>
            <li>
              Aggarwal R, Targhotra M, Kumar B, Sahoo PK, Chauhan MK. Treatment
              and management strategies of onychomycosis. Journal de
              MycologieMédicale. 2020 Jun 1;30(2):100949.<b>(IF:3.746)</b>
            </li>
            <li>
              Yadav N, Chauhan MK, Chauhan VS. Short to ultrashort peptide-based
              hydrogels as a platform for biomedical applications. Biomaterials
              science. 2020;8(1):84-100.<b>(IF:7.590)</b>
            </li>
            <li>
              Yadav M, Rani K, Chauhan MK, Panwar A, Sandal N. Evaluation of
              mercury adsorption and removal efficacy of pulverized Chlorella
              (C. vulgaris). Journal of applied phycology. 2020
              Apr;32(2):1253-62.<b>(IF:3.404)</b>
            </li>
            <li>
              Chakole CM, Chauhan MK. Role of Polyphenolic Compounds in
              Management of Oxidative Stress Associated with Glaucoma. European
              Journal of Molecular Clinical Medicine. 2020;7(09):2020.
            </li>
            <li>
              Chauhan MK, Sharma PK. Optimization and characterization of
              rivastigmine nanolipid carrier loaded transdermal patches for the
              treatment of dementia. Chemistry and physics of lipids. 2019 Nov
              1; 224:104794.<b>(IF:3.57)</b>
            </li>
            <li>
              Chauhan MK, Bhatt N. Bioavailability enhancement of polymyxin B
              with novel drug delivery: development and optimization using
              quality-by-design approach. Journal of Pharmaceutical Sciences.
              2019 Apr 1;108(4):1521-8.<b>(IF:3.784)</b>
            </li>
            <li>
              Aggarwal R, Targhotra M, Kumar B, Sahoo PK, Chauhan MK. Polyplex:
              a promising gene delivery system. International Journal of
              Pharmaceutical Sciences and Nanotechnology. 2019 Nov 27;12(6).
            </li>
            <li>
              Chauhan MK, JHA AK. Vesicular carrier loaded in-situ thixotropic
              formulations for glaucoma: an overview of recent advancement in
              ocular drug delivery system.Mintage Journal of Pharmaceutical
              Medical Sciences.2019 July-Sep 8;3.
            </li>
            <li>
              Dsa J, Goswami M, Singh BR, Bhatt N, Sharma P, Chauhan MK. Design
              and fabrication of a magnetically actuated non-invasive reusable
              drug delivery device. Drug development and industrial pharmacy.
              2018 Jul 3;44(7):1070-7.<b>(IF:3.727)</b>
            </li>
            <li>
              Yadav M, Chauhan M. A review on patented nanotechnology used for
              ocular drug delivery.World Journal of Pharmaceutical
              Research.2018,7 (14):533-542.
            </li>
            <li>
              Chauhan MK, Khanna G. Recent advancements of nanotechnology for
              the treatment of ocular disease. World J Pharm Res. 2018 Jun
              4;7(15):239-257.
            </li>
            <li>
              Rajput T, Chauhan MK. Bilosome: a bile salt based novel carrier
              system gaining interest in pharmaceutical research. Journal of
              Drug Delivery and Therapeutics. 2017 Sep 13;7(5):4-16.
            </li>
            <li>
              Chauhan MK<b>, </b>Verma A.Spanlasticsfuture of drug delivery and
              targeting. World Journal of Pharmaceutical Research.2017;
              6(12):429-446.
            </li>
            <li>
              Chauhan MK, Kumari M. Feracrylum: an effective and safe topical
              haemostatic agent. World Journal of Pharmaceutical Research.2017
              6(10):319-325.
            </li>
            <li>
              Chauhan MK,Kanika J<b>. </b>Bioadhesive microspheres for
              tuberculosis drug delivery. World Journal of pharmaceutical and
              Medical Research.2017; 3(7), 252-255.
            </li>
            <li>
              Yadav R, Chauhan M. Pharmaceutical diversity of chitin and
              chitosan: a review. Int. J. Pharm. Sci. Res. 2017;2:6-11.
            </li>
            <li>
              Yadav RB, Chauhan MK. A review: chitosan as 3D matrix for tissue
              engineering. Int J Res Pharm Sci. 2017;3:66-9.
            </li>
            <li>
              Sharma BG, Khanna K, Kumar N, Nishad DK, Basu M, Bhatnagar A.
              Development and gamma scintigraphy evaluation of gastro retentive
              calcium ion-based oral formulation: an innovative approach for the
              management of gastro-esophageal reflux disease (GERD). Drug
              Development and Industrial Pharmacy. 2017 Nov 2;43(11):1759-69.
            </li>
            <li>
              Chauhan M, Yenamandra J. Management of glaucoma: effective drug
              delivery via Niosomes. Journal of Drug delivery and therapeutics.
              2016 Nov 15;6(6):48-53.
            </li>
            <li>
              Chauhan MK, Gulati A. Aggrandized transdermal delivery of
              glimepiride via transfersomes: formulation, evaluation and
              statistical optimisation. Journal of Drug Delivery and
              Therapeutics. 2016 Jul 15;6(4):48-54.
            </li>
            <li>
              Chauhan MK, Gulati A, Gupta S. Nanovesicles via Transdermal route:
              an untrodden tactic for delivery of sulphonylureas antidiabetic
              drugs. International Journal of Research in Pharmacy Science. 2016
              Apr 1;6(2).
            </li>
            <li>
              Chauhan M, Bhatt N. Amphotericin-B loaded chitosan nanoparticles
              intended for oral delivery: biodistribution, pharmacokinetic and
              scintigraphy study in rabbit model. Int J Eng Tech Sci Res.
              2016;3(11):1-6.
            </li>
            <li>
              Chauhan MK, Singh A, Rawat A K. Probilosome: A Novel Carrier for
              Oral Delivery of Highly Soluble Gentamicin Molecules. Emerging
              Trends in Pharmaceutical Sciences.2016 March:5-22.
            </li>
            <li>
              Chauhan MK, Malik S.Formulation and optimization of novel elastic
              nano-vesicular carrier of vancomycin hydrochloride for enhanced
              corneal permeability.International Journal for Pharmaceutical
              Research Scholars.2016,5(4):88-100.
            </li>
            <li>
              Chauhan MK, Malik S. Regulatory guidelines for the approval of
              biosimilars in India. Europe, Brazil, and China: A comprehensive
              overview. http://innovareacademics. in/journals/index.
              php/ijpps/article/view/11753. 2016.
            </li>
            <li>
              Chauhan MK, Sahoo PK, Rawat AS, Kawadkar J, Kumar MS.
              Investigating Novel Liquid Crystalline Protransfersomal Gel as a
              Vehicle for Accentuated Transdermal Permeation and Pharmacokinetic
              Behavior of Lacidipine. Nanomedicine and Nanobiology. 2015 Dec
              1;2(1):28-37.
            </li>
            <li>
              Khan MA, Pandit J, Sultana Y, Sultana S, Ali A, Aqil M, Chauhan M.
              Novel carbopol-based transfersomal gel of 5-fluorouracil for skin
              cancer treatment: in vitro characterization and in vivo study.
              Drug delivery. 2015 Aug 18;22(6):795-802.<b>(IF:6.819)</b>
            </li>
            <li>
              Chauhan MK, Bhatt N. A simple and modified method development of
              vancomycin using high performance liquid chromatography. Journal
              of Chromatography and Separation Techniques. 2015 Jul 1; 6:296.
            </li>
            <li>
              Chauhan MK, Sahoo PK, Rawat AS, Duggal D, Kandwal M, Sandal N.
              Formulation, characterization and in vitro evaluation of
              tactically engineered proniosomes for successful oral delivery of
              ramipril. Der. Pharm. Lett. 2015; 7:93-7.
            </li>
            <li>
              Chauhan MK,Sharma KD, Arora S. Carrier centric approach of
              targeting drugs to the posterior segment of the eye. International
              Journal of Therapeutic Applications.2015 June 24(22): 20-35.
            </li>
            <li>
              Chauhan MK<b>,</b> Sahoo PK, Rawat AS, Singh A, Bamrara A, Sharma
              D. Bilosomes: A Novel Approach to Meet the Challenges in Oral
              Immunization. Recent patents on drug delivery formulation. 2015
              Jan 1;9(3):201-12.
            </li>
            <li>
              Chauhan MK, Nidhi Bhatt. Review Article on Pharmacokinetic/
              Pharmacodynamic Modelling, Pharma Times. 2015 October.
            </li>
            <li>
              Mahajan A, Rawat AS, Bhatt N, Chauhan MK. Structural modification
              of proteins and peptides. Indian J Pharm Educ Res. 2014 Jul
              1;48(3):34-47.
            </li>
            <li>
              Chauhan MK, Sahoo PK, Rawat AS, Bamrara A, Kandwal M, Sharma D.
              Simultaneous estimation of gliclazide and lacidipine using
              spectrophotometric methods and its application in nanostructured
              lipid carrier (NLC) development. Der Pharmacia Lettre.
              2014;6(6):352-8.
            </li>
            <li>
              Negi LM, Chauhan M, Garg AK. Nano-appended transdermal gel of
              Tenoxicam via ultradeformable drug carrier system. Journal of
              Experimental Nanoscience. 2013 Jul 1;8(5):657-69.<b>(IF:2.024)</b>
            </li>
            <li>
              Mohan Negi L, Fatma S, Talegaonkar S, Chauhan M. Development of
              ethanolic nano vesicles of tenoxicam, investigation of transdermal
              penetration efficiency and histological safety comparison with
              common penetration enhancers. Nanoscience and Nanotechnology
              Letters. 2013 May 1;5(5):600-5.
            </li>
            <li>
              Kawadkar J, Pathak A, Kishore R, Chauhan MK. Formulation,
              characterization and in vitro–in vivo evaluation of
              flurbiprofen-loaded nanostructured lipid carriers for transdermal
              delivery. Drug development and industrial pharmacy. 2013 Apr
              1;39(4):569-78.<b>(IF:3.225)</b>
            </li>
            <li>
              Kawadkar J, Jain R, Kishore R, Pathak A, Chauhan MK. Formulation
              and evaluation of flurbiprofen-loaded genipin cross-linked gelatin
              microspheres for intra-articular delivery. Journal of drug
              targeting. 2013 Feb 1;21(2):200-10.<b>(IF:5.016)</b>
            </li>
            <li>
              Chauhan MK<b>,</b>Kawadkar J, Kishore R, Pathak AM. Transferral of
              micro and macro molecules via nasal pathway. Pharmatutor. 2012
              July 12.
            </li>
            <li>
              Chauhan MK<b>,</b> Manchanda A, Khurana JK, Jain P, Jain S, Bansal
              S, Sharma D. The nano soldiers in the war against cancer. J
              Pharmacy Research. 2012;4(12):4420.
            </li>
            <li>
              Kawadkar J, Chauhan MK. Intra-articular delivery of genipin
              cross-linked chitosan microspheres of flurbiprofen: preparation,
              characterization, in vitro and in vivo studies. European journal
              of Pharmaceutics and Biopharmaceutics. 2012 Aug 1;81(3):563-72.
            </li>
            <li>
              Goyal T, Rawat AS, Chauhan M. Pharmacosomes: Opening new doors for
              drug delivery. Int. J. Pharm. Pharm. Sci. 2012; 4:25-9.
            </li>
            <li>
              Chauhan MK, Manchanda A, Khurana JK, Jain P, Sharma D, Jain S,
              Bansal S. Nanotechnology: The nano soldiers in the war against
              cancer. J. Pharm. Res. 2011 Dec;4(12):4420-3.
            </li>
            <li>
              Suman S, Chauhan M. Asymmetric Membrane Capsule: New Prospects in
              Osmotic Delivery. International Journal of Drug Delivery. 2011 Apr
              1;3(2):185.
            </li>
            <li>
              Chauhan MK, Manchanda A, Khurana JK, Jain P, Jain S. Bansal S,
              Sharma D. Iontophoresis- Injection without needle, Journal of
              Pharmacy Research, 2011;4(12):4413.
            </li>
            <li>
              Kawadkar JI, Chauhan MK, Maharana MA. Nanobiotechnology:
              application of nanotechnology in diagnosis, drug discovery and
              drug development. Asian Journal of Pharmaceutical and Clinical
              Research. 2011;4(1):23-5.
            </li>
            <li>
              Shilpa S, Srinivasan BP, Chauhan M. Niosomes as vesicular carriers
              for delivery of proteins and biologicals. International Journal of
              Drug Delivery. 2011 Jan 1;3(1).
            </li>
            <li>
              Sheer A, Chauhan M. Ethosomes as vesicular carrier for enhanced
              transdermal delivery of Ketoconazole-Formulation and Evaluation.
              IJPIs J. Pharm. Cosmetol. 2011;1(3):1-4.
            </li>
            <li>
              Kawadkar J, Chauhan MK, Ram A. Effect of Process Variables on Size
              Distribution of AlCl3 Cross-linked Sodium Alginate Microspheres.
              Journal of Pharmacy Research, 2010;3(12):2879-83.
            </li>
            <li>
              Kumar A, Chauhan MK, Sharma AK. Nutraceuticals and Their
              Applications: An Overview. Pharmacologyonline. 2010;3: 212-221.
            </li>
            <li>
              Kawadkar J, Chauhan MK. The potential of thiolated polymers for
              peptide drug delivery. Int. J. Pharm. Biol. Sci. Arch. 2010;
              1:209-18.
            </li>
            <li>
              Kawadkar J, Meenakshi KC, Ram A. Evaluation of potential of
              Zn-pectinate gel (ZPG) microparticles containing mesalazine for
              colonic drug delivery. DARU Journal of Pharmaceutical Sciences.
              2010;18(3):211-0.<b>(IF:3.117)</b>
            </li>
            <li>
              Garg AK, Negi LM, Chauhan M. Gel containing ethosomal vesicles for
              transdermal delivery of aceclofenac. International Journal of
              Pharmacy and Pharmaceutical Sciences. 2010;2(2):102-8.
            </li>
            <li>
              Anilkumar N, Chauhan MK, Agrawal SS. Product Optimization of
              Eudragit Coated Pantoprazole Sodium Sesquihydrate-Low Methoxy
              Pectin Gel Beads Using 3 (2) Factorial Design. Indian journal of
              pharmaceutical sciences. 2009 mar 1;71(2):188-188.
              <b>(IF:0.975)</b>
            </li>
            <li>
              Negi LM, Chauhan MK. Surfactant concentration: effect on particle
              size and polydispersity index of ultradeformable vesicles. Indian
              Journal of Pharmaceutical Sciences.2009;71(2):173-77.
              <b>(IF:0.975)</b>
            </li>
            <li>
              Sandeep KS. Span-60 niosomal oral suspension of fluconazole:
              formulation and in vitro evaluation. Asian journal of
              pharmaceutical research and health care. 2009;1(2).
            </li>
            <li>
              Negi LM, Garg AK, Chauhan M. Ultradeformable vesicles: concept and
              execution. Pharma Times. 2009;41(9):11-4.
            </li>
            <li>
              Pal Kaur I, Kanwar M. Ocular preparations: the formulation
              approach. Drug development and industrial pharmacy. 2002 Jan
              1;28(5):473-93.<b>(IF:3.727)</b>
            </li>
            <li>
              Kaur IP, Singh M, Kanwar M. Formulation and evaluation of
              ophthalmic preparations of acetazolamide. International journal of
              pharmaceutics. 2000 Apr 20;199(2):119-27.<b>(IF:6.51)</b>
            </li>
            <li>
              Bhardwaj TR, Kanwar M, Lal R, Gupta A. Natural gums and modified
              natural gums as sustained-release carriers. Drug development and
              industrial pharmacy. 2000 Jan 1;26(10):1025-38.<b>(IF:3.727)</b>
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <p>
            <b>
              Scientific Abstracts Publications/Presentations in National
              International Conferences
            </b>
          </p>
          <ul>
            <li>
              Himanshu Kushwah, Nidhi Sandal, Meenakshi K. Chauhan, Gaurav
              Mittal. “Fabrication And Characterization of Natural Gum Based
              Haemostatic Dressing for Their Physico-Chemical and Haemostatic
              Properties” in DST-SERB Sponsored National Conference on Teaching,
              Learning and Research in Pharmacy: The Way Ahead, GNSU on 16th
              -17th September 2022.
            </li>
            <li>
              Monika Targhotra, Meenakshi K. Chauhan. “Permeation studies of
              Transungual Drug Delivery System Across Porcine Hoof Membrane for
              Onychomycosis” DRPI 2022-Online, 9-16 July 2022.
            </li>
            <li>
              Disha Chatterjee, Meenakshi K. Chauhan. “In vitro Release Study of
              Levobunolol Loaded NLC for Treatment of Glaucoma” DRPI
              2022-Online, 9-16 July 2022.
            </li>
            <li>
              Ayaan, MD., and Chauhan, M. K., “Preparation and In Vitro
              characterization of Levodopa and Entacapone Loaded Nanoparticles
              for the management of PD via Nasal Administration” Abstract
              accepted for presentation at DRPI-2022, organized by AAPS and APTI
              on 9th July 2022.
            </li>
            <li>
              Himanshu Kushwah, Nidhi Sandal, Meenakshi K. Chauhan, Gaurav
              Mittal. “In vitro and In vivo Pharmacological Comparison of Four
              Exudate Gums as Potential haemostatic Agent for Management of
              Haemorrhagic Wounds” in 1st International conference on Innovation
              in Chemical, Biological and Pharmaceutical Sciences (ICBPS-2021)
              held at GLA University Mathura on 26-27 Nov 2021.
            </li>
            <li>
              Monika Targhotra, PK Sahoo, Meenakshi K. Chauhan. “Formulation,
              Characterization of Curcumin Loaded Nanoparticles for the
              Management of Tuberculosis”. ‘International Conference on Advances
              in Pharmaceutical and Health Sciences (APHS 2021)’ organized by
              KIET school of Pharmacy, Ghaziabad in collaboration with
              CSIR-Indian Institute of Toxicological Research Lucknow, India,
              Oct 7-8, 2021.
            </li>
            <li>
              Chandrashekhar M. Chakole, P.K. Sahoo, Meenakshi K. Chauhan.
              “Surface modified Nanostructured Lipid Carrier for Improved Ocular
              Delivery: <i>In Vitro</i> and <i>Ex Vivo</i> study” International
              e-conference on, ‘Pharmaceutical Research and Innovation to Tackle
              Future Health-care Arena’ organized by M.S. Gosavi College of
              Pharmaceutical Education and Research, Nashik, Maharashtra, India,
              Sep 27-29,2021.
            </li>
            <li>
              Chandrashekhar M. Chakole, PK Sahoo, Meenakshi K. Chauhan.
              “Carboxymethyl Chitosan Coated Nanostructured Lipid Carrier of
              Dexamethasone for Digital Eye Strain” AICTE sponsored online
              national conference on “Pharma QbD: Product Development
              Perspective” Organized by DadasahebBalpande College of Pharmacy,
              Besa, Nagpur, Sep 3-4, 2021.
            </li>
            <li>
              Shweta Mittal, Aman Sharma, Navneet Kumar, PK Sahoo, Meenakshi K.
              Chauhan, increased bioavailability from levobunolol laden contact
              lenses for glaucoma therapy “DRPI 2021, Society for pharmaceutical
              dissolution science, Jun- 2021.
            </li>
            <li>
              Aman Sharma, Meenakshi K. Chauhan. “Increased Permeation of
              Efinaconazole nail lacquer via transungual drug delivery for the
              treatment of Onychomycosis” Disso Research Presentation India
              2021- online, Society for Pharmaceutical Dissolution Science, 21st
              June 2021.
            </li>
            <li>
              Chandrashekhar M. Chakole, Meenakshi K. Chauhan.
              “Nanotechnology-Based Approach for Improved Ocular Delivery of
              Dexamethasone in the Rabbit Eye for Glaucoma Induction” GHGKCP-CON
              Virtual International Conference on ‘Optimizing Research
              Tomorrow’s Therapeutics including COVID-19 pandemic’ organised by
              G.H.G. Khalsa College of Pharmacy, GurusarSadhar, Ludhiana, Jun
              30, 2021.
            </li>
            <li>
              Rohan Aggarwal, Meenakshi K. Chauhan, P.K. Sahoo. “Formulation
              Development, Evaluation and Comparison of different Formulations
              of Nail Lacquer for the Treatment of Onychomycosis” International
              conference of cardiovascular sciences held at DPSRU, Delhi, India
              on Feb 21-23, 2020.
            </li>
            <li>
              Aman Sharma, Meenakshi K. Chauhan “CRISPR Cas 9 (genome editing
              technique) – upcoming technique to alter PCSK 9 gene”
              International conference of Cardiovascular Sciences 2020, Delhi
              Pharmaceutical Science and Research University, Delhi, Feb 21-23,
              2020.
            </li>
            <li>
              Jyoti Sharma, Meenakshi K. Chauhan “Vaccine development against
              the renin angiotensin system for the treatment of hypertension”
              International conference of Cardiovascular Sciences 2020, Delhi
              Pharmaceutical Science and Research University, Delhi, Feb 21-23,
              2020.
            </li>
            <li>
              Mohammad Asim, LukmanLawal, Meenakshi K. Chauhan “Use of natural
              herbs for the management of Hypertension” International conference
              of Cardiovascular Sciences 2020, Delhi Pharmaceutical Science and
              Research University, Delhi, Feb 21-23, 2020.
            </li>
            <li>
              Darpan Mishra, Meenakshi K. Chauhan “Management of angina pectoris
              through herbal drugs” International conference of Cardiovascular
              Sciences 2020, Delhi Pharmaceutical Science and Research
              University, Delhi, Feb 21-23, 2020.
            </li>
            <li>
              Nikita Vijay Jadhav, Meenakshi K. Chauhan “Nanotheranostics: new
              revolution in the management of cardiovascular disease”
              International conference of Cardiovascular Sciences 2020, Delhi
              Pharmaceutical Science and Research University, Delhi, Feb 21-23,
              2020.
            </li>
            <li>
              Nishu Yadav, Meenakshi K. Chauhan “Nanobars – approach to treat
              cardiovascular diseases” International conference of
              Cardiovascular Sciences 2020, Delhi Pharmaceutical Science and
              Research University, Delhi, Feb 21-23, 2020.
            </li>
            <li>
              Nishu Yadav, Meenakshi K. Chauhan “Ocular drug delivery- a
              sustained approach” International conference of Cardiovascular
              Sciences 2020, Delhi Pharmaceutical Science and Research
              University, Delhi, Feb 21-23, 2020.
            </li>
            <li>
              Shweta Mittal, Meenakshi K. Chauhan “Heart muscle bioengineering:
              A model for regenerative medicines” International conference of
              Cardiovascular Sciences 2020, Delhi Pharmaceutical Science and
              Research University, Delhi, February 21-23, 2020.
            </li>
            <li>
              Nisha Singh, Meenakshi K. Chauhan “Engineering precision
              biomaterials: a new approach for personalized medicine in
              cardiovascular healthcare” International conference of
              Cardiovascular Sciences 2020, Delhi Pharmaceutical Science and
              Research University, Delhi, February 21-23, 2020.
            </li>
            <li>
              C.M. Chakole, Meenakshi K. Chauhan “Brinzolamide loaded
              nanostructured lipid carrier for the management of glaucoma” 2nd
              International Conference 2020, DadasahebBalpande College of
              Pharmacy, Nagpur, Maharashtra, February 14-15, 2020.
            </li>
            <li>
              Rohan Aggarwal, Monika Targhotra, Meenakshi K. Chauhan. “Adapalene
              Loaded Nano Sized Vesicles: An Effective Approach for Acne
              Treatment” 24th APTICON, DIT University, Dehradun, India, Oct
              11-13, 2019.
            </li>
            <li>
              Navneet Kumar, Meenakshi K. Chauhan. “Extended Release of
              Brimonidine from Commercial Hydrogel Contact Lens for Glaucoma”
              24th APTICON, DIT University, Dehradun, India, Oct 11-13, 2019.
            </li>
            <li>
              Monika Targhotra, Meenakshi K. Chauhan. “Chitosan Niosomes for
              Controlled Delivery of Brimonidine Tartrate to the Ocular
              Membrane” 24th APTICON, DIT University, Dehradun, India on Oct
              11-13, 2019.
            </li>
            <li>
              Mohammad Asim, Aman Sharma, Meenakshi K. Chauhan
              “Pharmacogenetics- A personalized medicinal approach” National
              forum for pharmacy students on World pharmacist day, September 25,
              2019.
            </li>
            <li>
              Rohan Aggarwal, Meenakshi K Chauhan “Chitosan Niosomes for
              Controlled Delivery of Brimonidine Tartrate to the Ocular
              Membrane” organised by “National Forum of Pharmacy Students”
              during National Pharmacist Day’ September 25, 2019.
            </li>
            <li>
              Aman Sharma, Meenakshi K. Chauhan “Predictive biomarkers for
              cancer” National Seminar, KR Mangalam university, Gurugram,
              September 12, 2019.
            </li>
            <li>
              Nikita Vijay Jadhav, Meenakshi K. Chauhan “Bioelectronics medicine
              – approach to target molecular mechanism for disease management”
              National Seminar, KR Mangalam University, Gurugram, September 12,
              2019.
            </li>
            <li>
              Shweta Mittal, Meenakshi K. Chauhan “Microneedle delivery devices
              -approach for cancer therapy” presented at National Seminar, K R
              Mangalam University, Gurugram, September 12, 2019.
            </li>
            <li>
              Shweta Mittal, Meenakshi K. Chauhan “3D printing and its conflicts
              with intellectual property rights” International conference on
              Prospects of IPR in innovative practices, MDU Rohtak, August 27,
              2019.
            </li>
            <li>
              Navneet Kumar, Uday Prakash, Meenakshi K Chauhan, “Acetazolamide
              ethoniosomes as an ocular delivery system” International
              Conference on Nanotechnology and its Application to Ocular Drug
              Delivery System” Maliba Pharmacy College, Ukatarsadia University,
              Gujarat, India on January 26-28, 2019.
            </li>
            <li>
              Jayanti Mishra,Rohan Agarwal, Meenakshi K Chauhan, ” Chitosan
              Noisomes for controlled delivery of Brimonidinetartarate in ocular
              delivery of open angle glaucoma” International Conference on
              Nanotechnology and its Application to Ocular Drug Delivery System”
              held at Maliba Pharmacy College, Ukatarsadia University, Gujarat,
              India on January 26-28, 2019.
            </li>
            <li>
              Pankaj, Meenakshi K. Chauhan, JitenderPratap Yadav, “Kinetic
              Modelling of Drug Release from Diltiazem Matrix Tablet Based on
              Nature of Matrix forming natural and Synthetic Polymer ” at
              International Conference on “Nanotechnology and its Applications
              to Ocular Drug Delivery System” held at Maliba Pharmacy College,
              UkaTarsadia University, Gujarat, India on January 26-28, 2019.
            </li>
            <li>
              Uday Prakash, Navneet Kumar, Meenakshi K. Chauhan,
              “Brinzolamidespanlastics for glaucoma” presented at International
              Conference on Nanotechnology and it’s Application to Ocular Drug
              Delivery System” at Maliba Pharmacy College, Ukatarsadia
              University, Gujarat, India on January 26-28, 2019.
            </li>
            <li>
              Meenakshi K. Chauhan, JitenderPratap Yadav, Priyanka Singh,
              “Fabrication and Evaluation of Biomaterial Based Multipurpose 3D
              Scaffolds” at International Conference on “Nanotechnology and its
              Applications to Ocular Drug Delivery System” Maliba Pharmacy
              College, UkaTarsadia University, Gujarat, India January 26-28,
              2019.
            </li>
            <li>
              Meenakshi K. Chauhan, Manjusha, Geetanjali Khanna” Development of
              Protransferosomal gel of alendronate- a nitrogen containing
              bisphosphonate for transdermal route” International conference on
              nanotechnology and its application to ocular drug delivery system”
              Maliba Pharmacy College, Ukatarsadiq University, Gujarat, India on
              January 26-28, 2019.
            </li>
            <li>
              Rohan Aggarwal, Jayanti Mishra, Meenakshi K Chauhan, “Chitosan
              based antibacterial haemostatic scaffold for would healing”
              International conference on nanotechnology and it’s application to
              ocular drug delivery system” Maliba Pharmacy College, UkaTarsadia
              University, Gujarat, India on January 26-28, 2019.
            </li>
            <li>
              Meenakshi K. Chauhan, Rohan Aggarwal, Jayanti Mishra and Pankaj
              Kumar Sharma, “Nanoparticulate Encumbered Scaffolds for
              Neurodegenerative Induced Dementia<b>” </b>at Conference on
              “Science and Technology for the future of Mankind” VigyanBhawan,
              New Delhi January 7-10, 2019.
            </li>
            <li>
              Mahendra Yadav, Anuj Pawar, Meenakshi Kanwar Chauhan, Nidhi
              Sandal, Evaluation of <i>in vitro</i> binding efficacy of
              pulverized Chlorella for Mercury under different physiological
              conditions of GIT 70th Indian Pharmaceutical congress, Amity
              University, Noida, 21-23 December 2018.
            </li>
            <li>
              Himanshu Kushwaha, Meenakshi Chauhan, Gaurav Mittal, Nidhi Sandal,
              Development and Validation of UV Spectrophotometric method for
              determination of Menthol, 70th Indian Pharmaceutical congress,
              Amity University, Noida, 21-23 December 2018.
            </li>
            <li>
              Chitosan Niosomes for Controlled Delivery of Brimonidine Tartrate
              to the Ocular Membrane, 70th Indian Pharmaceutical congress, Amity
              University, Noida, 21-23 December 2018.
            </li>
            <li>
              Development of Protransferosomal Gel Containing Alendronate, a
              Nitrogen Containing Bisphosphonate for Transdermal Route, 70th
              Indian Pharmaceutical congress, Amity University, Noida, 21-23
              December 2018.
            </li>
            <li>
              Development and Evaluation of Probilosomes for Oral Delivery of
              Vancomycin, 70th Indian Pharmaceutical congress, Amity University,
              Noida, 21-23 December 2018.
            </li>
            <li>
              Fabrication and Evaluation of Biomaterial Based Multipurpose
              Three-Dimensional Scaffold, 70th Indian Pharmaceutical congress,
              Amity University, Noida, 21-23 December 2018.
            </li>
            <li>
              Novel Biodegradable Porous Chitosan Scaffold for Antibacterial
              Hemostatic, 70th Indian Pharmaceutical congress, Amity University,
              Noida, 21-23 December 2018.
            </li>
            <li>
              Kinetic Modelling of Drug Release from Diltiazem Matrix Tablets
              Based on Nature of Matrix Forming Synthetic and Natural Polymers,
              70th Indian Pharmaceutical congress, Amity University, Noida,
              21-23 December 2018.
            </li>
            <li>
              Ethioniosome as an Ocular Delivery System for Acetazolamide, 23rd
              Annual National Convention of Association of Pharmaceutical
              Teachers of India, 5-6 October 2018, Swami Keshvan and Institute
              of Pharmacy, Jaipur, India.
            </li>
            <li>
              BrinzolamideSpanlastics for Glaucoma, 23rd Annual National
              Convention of Association of Pharmaceutical Teachers of India, 5-6
              October 2018,Swami Keshvan and Institute of Pharmacy, Jaipur.
            </li>
            <li>
              Meenakshi K. Chauhan and Pankaj K. Sharma, “Deciphering the role
              of Rivastigmine Scaffolds for Parkinsonism Dementia” at 22nd
              Annual National Convention of Association of Pharmaceutical
              Teachers of India Conference” (APTICON), Lloyd Institute of
              Management Technology, Noida, 24 March 2018.
            </li>
            <li>
              Mayank K Dhawan, Sanyam Nasa, SmridhiKhurana, SumitChhangani and
              Meenakshi K Chauhan, Bupropion Hydrochloride for Major Depressive
              Disorder: A Novel Sustain Release Matrix Tablet based on
              Polyelectrolyte Complexes in National Symposium on Research
              Methodology, JamiaHamdard, New Delhi, March 7’ 2018.
            </li>
            <li>
              Mamta Yadav, Geetanjali Khanna and Meenakshi K Chauhan. Vesicular
              System of Brinzolamide for Delivery to the Interior of eye in
              Recent Advances in Pharmaceutical Sciences and Health,
              JamiaHamdard, New Delhi February 26’ 2018.
            </li>
            <li>
              Ritik Aggarwal, Chetna, Meenakshi K Chauhan, P K Sahoo,
              Clomipramine and Venlafaxine Multilayered Matrix Tablets in Recent
              Advances in Pharmaceutical Sciences and Health, JamiaHamdard, New
              Delhi February 26’ 2018.
            </li>
            <li>
              Pankaj K. Sharma and Meenakshi K. Chauhan, Exploring Integrated
              Topical Scaffold Patch System in Recent Advances in Pharmaceutical
              Sciences and Health, JamiaHamdard, New Delhi February 26’ 2018.
            </li>
            <li>
              Meenakshi K Chauhan, Meghna Amrita Singh, Aditi Chauhan, Emerging
              trends of Nanotechnology in Health Care System in Recent Advances
              in Pharmaceutical Sciences and Health, JamiaHamdard, New Delhi
              February 26’ 2018.
            </li>
            <li>
              Smridhi, Sanyam, Mayank and Meenakshi K Chauhan, Emerging trends
              in Transdermal Drug Delivery Systems in Recent Advances in
              Pharmaceutical Sciences and Health, JamiaHamdard, New Delhi
              February 26’ 2018.
            </li>
            <li>
              Pankaj K. Sharma and Meenakshi K. Chauhan in National Seminar on
              Nanomedicine and Nanotechnology in Heath Care organised at Apeejay
              Satya University, Gurugram, Feb 17’ 2018.
            </li>
            <li>
              Meenakshi K Chauhan, Meghna Amrita Singh, Aditi Chauhan, in
              National Seminar on Nanomedicine and Nanotechnology in Heath Care
              organised at Apeejay Satya University, Gurugram, Feb 17’ 2018.
            </li>
            <li>
              Nitin Yadav, C Thota, Meenakshi K Chauhan, Virander S Chauhan,
              Self-assembled Ultrashort Peptide based Hydrogels for Drug
              Delivery and Tissue Engineering, CHASCON (Chandigarh Science
              Congress) Panjab University, Chandigarh 12-14 Feb 2018.
            </li>
            <li>
              Meenakshi K. Chauhan, Pankaj K. Sharma, Anti-osteoporetic
              <i>Cissusquadrangularis</i> formulation: An integration of Novel
              Drug Delivery System and Indian Ayurvedic medicine to combat more
              serious diseased, International Conference on Challenges for
              Global Competitiveness of AYUSH and Natural Products at DPSRU, 2-4
              Feb2018.
            </li>
            <li>
              Manisha and Meenakshi K Chauhan, Pharmaceutical Intellectual
              Property: An Attractive Target of Cyber Attack A National seminar
              cum Workshop on IPR Awareness Programme New Delhi 2017.
            </li>
            <li>
              Nitin Yadav, Chaitanya K. Thota, Meenakshi K. Chauhan.
              Conformatinoally restricted ultrashort peptide-based Hydrogel for
              drug delivery and tissue engineering. An annual Scientific
              symposium Bio Epoch 2017, organised by School of Biotechnology
              Jawaharlal University, New Delhi, 23-24 Mar 2017.
            </li>
            <li>
              Meenakshi K. Chauhan and Pankaj K. Sharma, Rivastigmine loaded
              Nanostructured lipid carrier integrated scaffolds transdermal
              patch system for the treatment of Alzheimer’s disease and
              Parkinson’s disease dementia International Conference on Novel
              Formulation Strategies organized by SELECTBIO India, Hyderabad,
              20-21 Apr 2017.
            </li>
            <li>
              Meenakshi K. Chauhan and Nitin Yadav, Instantaneous self-assembly
              of a conformationally restricted ultrashort peptide into highly
              stable hydrogel for drug delivery and tissue engineering 5th
              Annual International Conference on Bioinformatics and Proteomics
              Driven Biomarker Developments, sponsor by Indian Council of
              Medical Research (ICMR), organized by Chitkara University Punjab,
              7- 8 April 2017.
            </li>
            <li>
              Meenakshi K. Chauhan and Pankaj K. Sharma, “Exploring Oral Route
              for Amphotericin-B using Nanoparticulate System “One day National
              Symposium on “Next Gen Challenges in Pharmaceutical Sciences”
              organized by Panjab University, Chandigarh, 17 March 2017.
            </li>
            <li>
              Meenakshi K. Chauhan and Pankaj K. Sharma, “Nanostructure lipid
              carrier integrated transdermal scaffold patch system for the
              treatment of dementia associated with various neurological
              disorders” International conference on “Challenges in drug
              discovery and delivery” organized by BITS Pilani, India, 2-4
              March, 2017.
            </li>
            <li>
              Meenakshi K. Chauhan andTanvi Rajput “Innovative techniques to
              meet industrial expectations” “Industry Expectation from Academia”
              organized by Lloyd Institute of Management Technology, Noida, on
              12 November 2016.
            </li>
            <li>
              Meenakshi K. Chauhan and Shipra Malik, “Formulation and
              optimization of novel elastic vesicular carrier of vancomycin
              hydrochloride for enhancing corneal permeability”, One day
              National Seminar on Advances in Technology, Regulatory and
              Clinical aspects in Controlled drug delivery systems, organized by
              DPSRU and Controlled Release Society at DPSRU, New Delhi 15 July
              2016.
            </li>
            <li>
              Meenakshi K. Chauhan andTanvi Rajput “Quality by Design: Current
              trends in Pharmaceutical Research” National Conference on “PAT and
              QbD” organized by JamiaHamdard, Delhi, 17th April 2016.
            </li>
            <li>
              Meenakshi K. Chauhan and ArpitaVerma “Development and evaluation
              of ethosomes loaded with carvedilol”, National conference on
              “Perspectives and Challenges in Pharmaceutical Science” sponsor by
              Indian Pharmacy Graduate Association (IPGA), conducted by Shree
              Ganapati Institute of Technology Ghaziabad, 5 March 2016.
            </li>
            <li>
              Meenakshi K. Chauhan and Ashwani Singh Rawat, “Fabrication and
              Evaluation of Nanostructured Lipid carrier Integrated Scaffolds
              Transdermal Patch for Sustain Release of Rivastigmine”
              International Conference, “NanoSciTech” Panjab University,
              Chandigarh, 18-20 Feb 2016.
            </li>
            <li>
              A.S. Rawat, M.K. Chauhan, Poster paper at Chitkara University
              Punjab on “International conference on “Molecular pharmacology,
              drug discovery and nanopharmaceuticals” (MPDDNP-2015) on 27thand
              28th, March 2015.
            </li>
            <li>
              A. Aggarwal, D. Gupta, S. Aggarwal, A.S. Rawat, M.K. Chauhan.
              Freeze-drying of nanoparticles: Process optimization, effect of
              various saccharides (Cryoprotectants) and its consequences on
              quality attributes. 6thInternational conference on recent advance
              in cardiovascular sciences, 2014, DIPSAR, New Delhi.
            </li>
            <li>
              R. Singhal, M. Kandwal, A.S. Rawat, M.K. Chauhan. Studying the
              influence of various parameters on stability of freeze-dried
              vancomycin-loaded liposomes. 6th International conference on
              recent advance in cardiovascular sciences, 2014, DIPSAR, New
              Delhi.
            </li>
            <li>
              A. Singh, A.S. Rawat, M.K. Chauhan. A novel provesicular carrier
              for enhanced oral delivery: Development, characterization and
              performance evaluation. 6thInternational conference on recent
              advance in cardiovascular sciences, 2014, DIPSAR, New Delhi.
            </li>
            <li>
              A.S. Rawat, A. Mahajan, A. Singh, M.K. Chauhan. Development of a
              novel provesicular carrier for oral delivery of glycopeptides.
              NanoSciTech, 2014, University Institute of Pharmaceutical
              Sciences, Panjab University, Chandigarh.
            </li>
            <li>
              M.K. Chauhan, A.S. Rawat, D. Duggal. Formulation and evaluation of
              ramipril based proniosomes. AICTE sponsored national Seminar on
              industry demand from pharmacy youth, sponsored by AICTE, 2013,
              Innovative College of Pharmacy, Greater Noida.
            </li>
            <li>
              Shelly, A.S. Rawat, M.K. Chauhan. Nanostructured lipid carrier
              (NLC) integrated scaffolds patch system for transdermal delivery
              of lovastatin. 65thIndian pharmaceutical congress, 2013, Amity
              University, Noida.
            </li>
            <li>
              M.K. Chauhan, A.S. Rawat, Aruna, A. Mahajan. Needle will be soon
              an archaic tool. International conference of pharmacoeconomics and
              outcome research, 2011, DIPSAR, New Delhi.
            </li>
            <li>
              M.K. Chauhan, A.S.Rawat, A. Mahajan, Aruna. Transdermal drug
              delivery via proniosomal gel system. International conference of
              pharmacoeconomics and outcome research,2011, DIPSAR, New Delhi.
            </li>
            <li>
              Shubhika, S.Sharma, M.K. Chauhan, 62nd Indian pharmaceutical
              congress, 2010,Manipal University, Manipal, Karnataka.
            </li>
            <li>
              G.Taneja, J. Kawadkar, M.K. Chauhan<b>. </b>Acomparative study of
              colon specific Zn-pectinate gel and Ca-pectinate gel
              microparticles.62nd Indian pharmaceutical congress, 2010, Manipal
              University, Manipal, Karnataka.
            </li>
            <li>
              N.Nasa, A. Garg, J. Kawadkar, M.K. Chauhan. Ethosomal vesicular
              carrierbased gel formulation for topical delivery of aceclofenac.
              62nd Indian pharmaceutical congress, 2010, Manipal University,
              Manipal, Karnataka.
            </li>
            <li>
              V. Thomas, J. Kawadkar, M.K. Chauhan. Effect of process variables
              on the size distribution of aluminium chloride cross-linked sodium
              alginate microspheres of diclofenac sodium.62nd Indian
              Pharmaceutical Congress, 2010, Manipal University, Manipal,
              Karnataka.
            </li>
            <li>
              J. Kaur, A. Sheer, M.K. Chauhan. Ethosomal topical gel of
              ketoconazole: Formulation and evaluation.62nd Indian
              Pharmaceutical Congress, 2010, Manipal University, Manipal,
              Karnataka.
            </li>
            <li>
              J. Kawadkar, M.K. Chauhan. Influence of Process variables on
              biodegradable Novel Microspheres prepared by
              Homogenization-w/o-emulsification solvent evaporation. 61stIndian
              Pharmaceutical Congress, 2009, Nirma University of Science and
              Technology, Gujarat.
            </li>
            <li>
              A. Kumar, M.K. Chauhan, S.S. Agrawal. Electrophoretic mobility of
              polymeric hydrogel at different pH.61stIndian Pharmaceutical
              Congress, 2009, Nirma University of Science and Technology,
              Gujarat.
            </li>
            <li>
              L.M. Negi, M.K. Chauhan. Surfactant concentration effects on
              particle size and polydispersity index of ultradeformable
              vesicles. 4thInternational Mini Symposium on Recent Advances in
              Cardiovascular Sciences, 2009, DIPSAR, New Delhi.
            </li>
            <li>
              A. Kumar, M.K. Chauhan, S.S. Agrawal. Product optimization of
              eudragit coated pantoprazole sodium sesquihydratemethoxy pectin
              gel beads using 32 factorial designs. 4th International Mini
              Symposium on Recent Advances in Cardiovascular Sciences, 2009,
              DIPSAR, New Delhi.
            </li>
            <li>
              J. Kawadkar, M.K. Chauhan. Influence of Process variables on
              biodegradable Novel Chitosan Microspheres prepared by
              Homogenization-w/o-emulsification method.4th International Mini
              Symposium on Recent Advances in Cardiovascular Sciences, 2009,
              DIPSAR, New Delhi.
            </li>
            <li>
              J. Kawadkar, M.K. Chauhan. Intra-articular injection: a fast
              growing approach for the treatment of arthritis. APTI sponsored
              national seminar on emerging trends in drug discovery and
              formulation development, 2009, JCDM College of pharmacy, Haryana.
            </li>
            <li>
              J. Kawadkar, M.K. Chauhan. Genipin: Herbal crosslinker for long
              acting injectable drug delivery. National Seminar on ‘Potential
              approaches in herbaceuticals, SOPH, CEC, 2009, Chhattisgarh.
            </li>
            <li>
              Anilkumar N., Chauhan M. K.,Agrawal, S.S., “Product optimization
              of eudragit coated pantoprazole sodium sesquihydrate alginate
              beads using 32 factorial designs” 60th Indian Pharmaceutical
              Congress, Theme: “Pharma Vision 2020: Regulations for Best Health
              Care” NSIT, New Delhi, Dec. 2008.
            </li>
            <li>
              J. Kawadkar, M.K. Chauhan. Colonic delivery of mesalazine loaded
              hydrogel microparticles based on low methoxyamidated pectin. 60th
              Indian Pharmaceutical Congress, Theme: “Pharma Vision 2020:
              Regulations for Best Health Care” NSIT,2008, New Delhi.
            </li>
            <li>
              Anilkumar N., Chauhan M. K.,Agrawal, S. S., “Product optimization
              of eudragit coated pantoprazole sodium sesquihydrate
              alginate-pectinate beads using 32 factorial designs” Third
              International Symposium on Recent Advances in Cardiovascular
              Sciences, DIPSAR, New Delhi, Dec. 2008.
            </li>
            <li>
              A. Kumar, M.K. Chauhan, S.S. Agrawal. Anti-inflammatory activity
              of Ibuprofen Tween vesicles.National Seminar on “Colloidal Carrier
              Site Specific Controlled Drug Delivery Systems ISFCP-Moga, 2008,
              Punjab.
            </li>
            <li>
              S.K. Sharma, M.K. Chauhan. Preparation and evaluation of niosomes
              as vesicular carrier for Fluconazole. National Seminar on
              ‘Colloidal Carrier Site Specific Controlled Drug Delivery Systems,
              ISFCP-Moga, 2008, Punjab.
            </li>
            <li>
              J.Kawadkar, M.K. Chauhan. Calcium pectinated hydrogel
              microparticles for the treatment of ulcerative colitis. National
              Seminar on ‘Colloidal Carrier Site Specific Controlled Drug
              Delivery Systems,ISFCP-Moga, 2008, Punjab.
            </li>
            <li>
              M. Kumar, P.K. Sharma, M.K. Chauhan, S.S.Agrawal, R. Chandra.
              Development and Evaluation of Sustained Release Matrix Tablets of
              Diltiazem using Biodegradable Polymers. Recent Advances in
              Biomedical and Therapeutic Sciences, Institute of Biomedical
              Sciences, 2005, Jhansi.
            </li>
            <li>
              T.R. Bhardwaj, M. Kumar, R.L. Sandal, M.K. Chauhan, N. Dhingra.
              Synthesis of 17α-Aza-D-Homo-Steroids as Potential 5α-Reductase
              Inhibitors. Indian Science Congress, 2004, Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, M. Kumar, R.L. Sandal, M.K. Chauhan, N. Mehta.
              Synthesis and Evaluation of Gastroprotective Non-steroidal
              Anti-inflammatory Agents. Indian Science Congress, 2004,
              Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, M. Kumar, R.L. Sandal, M.K. Chauhan. Synthesis of
              Poly[bis(4-formylphenoxy)] phosphazene for Controlled Release of
              Nimesulide. National Seminar on Polymers and Applications, 2003,
              Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, M. Kumar, R.L. Sandal, M.K. Chauhan. Synthesis
              characterization and Evaluation of Modified Gum Almond as
              Sustained Release Drug Carrier. National Seminar on Polymers and
              Applications, 2003, Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, R.L. Sandal, M.K. Chauhan. Synthesis and Evaluation
              of Biodegradable Polyphosphazene for Controlled Release of
              Nimesulide. National Seminar on Pharmaceutics in the Light of Drug
              Delivery Challenges, University Institute of Pharmaceutical
              Sciences, 2003, Panjab University, Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, R.L. Sandal, M.K. Chauhan. Development of Sustained
              Release Formulation of Diclofenac Sodium with Modified Gum Acacia.
              National Seminar on Pharmaceutics in the Light of Drug Delivery
              Challenges, University Institute of Pharmaceutical Sciences, 2003,
              Panjab University, Chandigarh.
            </li>
            <li>
              T.R. Bhardwaj, R.L. Sandal, M.K. Chauhan. Synthesis of
              Polymer-Linked Anticancer Drugs.53rd Indian Pharmaceutical
              Congress, 2001, New Delhi.
            </li>
            <li>
              T.R. Bhardwaj, M. Kumar, R.L. Sandal, M.K. Chauhan. Synthesis of
              Biodegradable Polyphosphazenes. 51st Indian Pharmaceutical
              Congress,1999, Indore.
            </li>
            <li>
              I.P. Kaur, B.S.Bhoop, R.L.Gupta, M.Kanwar. Development of Topical
              Dosage Forms of Acetazolamide for Glaucoma. International
              Symposium on New Drug Development,1998, JamiaHamdard, New Delhi.
            </li>
            <li>
              I.P. Kaur, M. Kanwar. Quantitative Structure Mutagenicity
              Relationship (QSMR) amongst Nitrodiphenylethers. National Seminar
              on Current Trends Future Challenges in Pharmaceutics, 1998, Panjab
              University, Chandigarh.
            </li>
          </ul>
          <p></p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <b>AWARDS</b>
          </p>
          <ul>
            <li>
              Conferred with <b>“Research Innovation Award”</b> in First
              Research Promotion Award ceremony organized by DPSRU for patent
              entitled “Vitamin E TPGS based pharmaceutical composition for
              sustained drug delivery”. Patent No. 376816 granted during 2021, 5
              March, 2022.
            </li>

            <li>
              Conferred with <b>“Notable Research Award” </b>in First Research
              Promotion Award Ceremony organized by DPSRU for her publication
              entitled “Conformationally constrained dipeptide-based hydrogel as
              a platform for 3D cell growth and tissue engineering
              applications.” 5 March, 2022.
            </li>

            <li>
              Certificate of Appreciation for the Outstanding Performance and
              Research Promotion during 2019-21.
            </li>

            <li>
              <b>Best Poster Paper Award</b> was presented for the paper
              entitled “Formulation, Characterization of Curcumin Loaded
              Nanoparticles for the Management of Tuberculosis” at International
              Conference on Advances in Pharmaceutical and Health Sciences (APHS
              2021)’ organized by KIET school of Pharmacy,Ghaziabad in
              collaboration with CSIR-Indian Institute of Toxicological Research
              Lucknow, India, Oct 7-8, 2021
            </li>
            <li>
              <b>Best Poster Paper Award</b>(North region) was presented for the
              research paper entitled “Increased bioavailability from
              levobunolol laden contact lenses for glaucoma therapy” at DRPI
              2021, Society for Pharmaceutical Dissolution Science, July 17,
              2021
            </li>
            <li>
              <b>2</b>
              <b>nd</b>
              <b>Best Poster Paper Award</b>(All India) was presented for the
              research paper entitled “Increased bioavailability from
              levobunolol laden contact lenses for glaucoma therapy” at DRPI
              2021, Society for Pharmaceutical Dissolution Science, July 17,
              2021
            </li>
            <li>
              <b>Best Poster award</b> was presented for the paper
              “Nanotechnology-Based Approach for Improved Ocular Delivery of
              Dexamethasone in the Rabbit Eye for Glaucoma Induction” GHGKCP-CON
              Virtual International Conference on ‘Optimizing Research
              Tomorrow’s Therapeutics including COVID-19 pandemic’ organised by
              G.H.G. Khalsa College of Pharmacy, GurusarSadhar, Ludhiana, Jun
              30, 2021
            </li>
            <li>
              <b>Women Researcher Award</b> by Association Vdgood Professional
              at 9th International Scientist Awards on Engineering, Science and
              Medicine, Trichy, India 12-13 Sep 2020.
            </li>
            <li>
              <b>Most Dedicated Training Placement Officer Award</b> by Golden
              AIM conference Awards for Excellence Leadership in Healthcare
              Education E-Learning, 7 Aug 2020.
            </li>
            <li>
              Selected as the <b>Best Professor in PharmaceuticsStudies</b>{" "}
              (Northern region category) by the Dewang Mehta National Education
              Leadership Awards (India’s largest Education Awards Contest held
              as a part of World Marketing Congress)<b> 2019</b>
            </li>
            <li>
              <b>3rd Best Poster award</b> was presented for the paper entitled
              “Chitosan Niosomes for Controlled Delivery of Brimonidine Tartrate
              to the Ocular Membrane” held by “National Forum of Pharmacy
              Students” during National Pharmacist Day’ September 25, 2019
            </li>
            <li>
              <b>2nd Best Poster Paper Award</b> was presented for the research
              paper entitled “Deciphering the role of Rivastigmine scaffolds for
              Parkinsonism Dementia” at 22nd Annual National Convention of
              Association of Pharmaceutical Teachers of India Conference”
              (APTICON), Lloyd Institute of Management Technology, Noida, 24
              March 2018
            </li>
            <li>
              <b>Best Poster Paper Award</b> was presented for the research
              paper entitled “Exploring Integrated Topical Scaffold Patch
              System” One-day National conference on “Recent Advances in
              Pharmaceutical Sciences and Health”, JamiaHamdard University, New
              Delhi, 26 Feb 2018.
            </li>
            <li>
              <b>Best Poster Paper award</b>for research paper entitled
              “Instantaneous self-assembly of a conformationally restricted
              ultrashort peptide into highly stable hydrogel for drug delivery
              and tissue engineering” in ICMR Sponsored 5th Annual International
              Conference Exhibition [BPDBD-2017] on “Bioinformatics and
              Proteomics Driven Biomarker Developments” 2017.
            </li>
            <li>
              <b>Best Oral Paper Award</b> was presented for the research paper
              entitled “Ethosomes for Enhanced Transdermal Delivery of
              Lovastatin: Characterization and Skin Penetration Studies” at
              Shree Ganapati Institute of Technology (SGIT), March 2016.
            </li>
            <li>
              <b>Best Poster Paper Award</b> was presented for the research
              paper entitled “Formulation and Evaluation of Ramipril Based
              Proniosomes” at AICTE sponsored National Seminaron Industry Demand
              from Pharmacy youth, Innovative College of Pharmacy, Greater
              Noida, 2013.
            </li>
            <li>
              <b>Best Poster Paper Award</b> was presented for the research
              paper entitled“Influence of Process variables on biodegradable
              Novel Chitosan Microspheres prepared by
              Homogenization-w/o-emulsification method” at the Fourth
              International Mini Symposium on Recent Advances in Cardiovascular
              Sciences, DIPSAR, New Delhi, 2009.
            </li>
            <li>
              <b>Best Poster Paper Award</b> was presented for the research
              paper entitled “Product optimization of eudragit coated
              pantoprazole sodium sesquihydrate alginate-pectinate beads using
              32 factorial designs” at the 3rdInternational Symposium on Recent
              Advances in Cardiovascular Sciences, DIPSAR, New Delhi, 2008.
            </li>
            <li>
              <b>Best Poster Paper Award</b> was presented for the research
              paper entitled “Synthesis of Biodegradable Polyphosphazenes” at
              the 51st Indian Pharmaceutical Congress, Indore,1999.
            </li>

            <p>
              <b>RECOGNITIONS/ACHIEVEMENTS</b>
            </p>

            <li>
              Featured in <b>Word’s Top 2% Scientist list-2022</b>by prestigious
              Stanford university, USA.
            </li>

            <li>
              Featured in World Scientist and University Rankings-2022 by AD
              Scientific Index.
            </li>
            <li>
              Appreciation Award in Oct 2021,in recognition as Faculty
              Coordinator for the MANAV Scientific Reading and Comprehension
              Self-Assessment Module and Mentoring the students to critically
              evaluate the scientific papers form peerreviewed Journals from
              Pharmacy.
            </li>

            <li>
              Recognition as NITTTR Certified Mentor for newly inducted faculty
              in AICTE approved institutions.
            </li>

            <li>
              <b>Resource person</b>/Member with various professional bodies
              like AICTE, SPDS, IPA, APTI, IPGA
            </li>
            <li>
              AICTE-NITTTR <b>Mentor</b> for newly inducted faculty in AICTE
              approved Institutions 2020, 2021
            </li>
            <li>
              <b>Chief Guest</b>, World Health Day Celebrations, Gurugram
              College of Pharmacy, Pt B D Sharma University of Health Sciences,
              Haryana April 2021
            </li>
            <li>
              <b>Evaluator</b>, Fast Track Young Scientist Scheme of AICTE 2021
            </li>
            <li>
              <b>Judge</b>atScienamite Event – Interschool Science Model Making
              Competitionorganized by GD Goenka World School 2020
            </li>
            <li>
              <b>Evaluator</b>, Travel Grant, AICTE 2020
            </li>
            <li>
              <b>Inspector</b>, Joint Inspection of Pharmacy College, UKTU,
              Dehradun, 2019.
            </li>
            <li>
              <b>Member</b>, Board of Studies, DPSR University 2018-2021
            </li>
            <li>
              <b>Judge, </b>for Smart India Hackathon(Software) event organised
              byMHRD in collaboration with AICTE at Kolkata, 2018.
            </li>
            <li>
              <b>Evaluator</b> for Delhi Govt Health section teams for Hackathon
              (Software) event organised by MHRD in collaboration with AICTE,
              2018.
            </li>
            <li>
              <b>Inspector</b>, Joint Inspection of Drug Testing laboratory by
              Govt. Medical store depot, New Delhi,2018.
            </li>
            <li>
              <b>Sector Officer</b>, MCD elections, Govt of Delhi 2017.
            </li>
            <li>
              <b>
                Appreciation from Honourable Vice Chancellor DPSRU for
                outstanding service for NIRF
              </b>{" "}
              ranking of DIPSAR render during 2016-17 at Delhi Pharmaceutical
              Sciences and Research University.
            </li>
            <li>
              <b>Expert Member</b>, Technical specifications of item for GeM,
              Dept. of Commerce, New Delhi, 2016.
            </li>
            <li>
              <b>Sector Officer</b>, MCD elections, Govt of Delhi 2012.
            </li>
            <li>
              <b>Paper citation</b> in International Book, Chapter 64 “Ocular
              Pharmacology”{" "}
              <b>
                <i>Goodman Gilman’s</i>
              </b>{" "}
              The Pharmacological Basis of Therapeutics, McGraw Hill
              Professional.
            </li>

            <p>
              <b>ACADEMIC ACHIEVEMENTS</b>
            </p>

            <li>
              <b>GATE 1996</b>, Indian Institute of Science, Bangalore
            </li>

            <p>Secured 24thAll India Rank (2ndrank in Punjab University)</p>

            <li>
              <b>M. Pharm. (Pharmaceutics)</b>, UIPS, Panjab University,
              Chandigarh
            </li>

            <p>Secured First Rank for the academic year 1998</p>

            <li>
              <b>B. Pharm. </b>UIPS, Panjab University, Chandigarh
            </li>

            <p>Secured Second Rank in academic year 1996</p>
            <p>
              <b>REVIEWER FOR INTERNATIONAL/NATIONAL JOURNALS</b>
            </p>

            <li>International Journal of Pharmaceutics, Elsevier</li>
            <li>Indian Journal of Pharmaceutical Sciences and Research</li>
            <li>
              International Journal of Pharmaceutical Research and Health Care
            </li>
            <li>European Journal of Pharmaceutical Sciences, Elsevier</li>
            <li>Drug Development and Industrial Pharmacy</li>

            <p>
              <b>PROFESSIONAL ACHIEVMENTS</b>
            </p>

            <li>
              NITTTR Recognised Mentor for newly inducted faculty in AICTE
              instituions-2020, 2021
            </li>
            <li>
              Summer Faculty Research Fellow, Department of Material Science
              IIT-Delhi 2018
            </li>
            <li>
              Fundamentals of Health Research, Online course, NPTEL by IIT
              Madras, 2018
            </li>
            <li>Selected through UPSC for the Post of Assistant Professor</li>

            <p>
              <b>MEMBER, BOARD OF EXAMINERS/STUDIES</b>
            </p>

            <li>Board of Studies, DPSR-University, New Delhi</li>
            <li>National Board of Examination, India</li>
            <li>
              University Institute of Pharmaceutical Sciences, Panjab
              University, Chandigarh
            </li>
            <li>
              Department of Pharmaceutical Sciences, JamiaHamdard, Hamdard
              University, New Delhi
            </li>
            <li>
              Student Research Academic Committee Member, JamiaHamdard, Hamdard
              University, New Delhi
            </li>
            <li>
              Pandit Bhagwat Dayal Sharma, University of Health Sciences,
              Rohtak, Haryana
            </li>
            <li>Faculty of Pharmacy, Integral University, Lucknow.</li>
            <li>Baba Farid University of Health Sciences, Faridkot, Punjab.</li>
            <li>Himachal Pradesh Technical University, Shimla.</li>
            <li>
              Department of Pharmaceutical Sciences, University of Kashmir,
              Srinagar
            </li>
            <li>JNTU, Kakinada, Andhra Pradesh</li>
            <li>Jyoti Vidyapeeth Women’s University, Jaipur, Rajasthan</li>
            <li>Amity University, Noida, Uttar Pradesh</li>
            <li>Council of Science Technology, Lucknow, Uttar Pradesh</li>
            <li>PDM University, Haryana</li>
            <li>Sharda University, Noida, Uttar Pradesh</li>

            <p>
              <b>REGISTERED PHARMACIST</b>
            </p>

            <li>
              Registered as a Pharmacist with Himachal Pradesh Pharmacy Council,
              Shimla
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrMeenakshiKChauhan;
