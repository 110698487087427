import React from 'react'
import './IqacPolicy.css'

import pd2 from './policy data/pd2.pdf'
import pd3 from './policy data/pd3.pdf'
import pd4 from './policy data/pd4.pdf'
import pd5 from './policy data/pd5.pdf'
import pd6 from './policy data/pd6.pdf'
import pd7 from './policy data/pd7.pdf'
import pd8 from './policy data/pd8.pdf'
import pd9 from './policy data/pd9.pdf'
import pd10 from './policy data/pd10.pdf'
import pd11 from './policy data/pd11.pdf'
import weedOutpolicy from './policy data/Weed-Out-Policy.pdf'

function IqacPolicy() {
    return (
        <div className='iaqc-introduction-component iqac-policy-container dpsru-every-component-css '>
            <h2 className="dpsru-primary-heading-for-every-page">
                Policy Documents
            </h2>

            <ul>
                <li>
                    <a href={weedOutpolicy}>Weed Out Policy</a>
                </li>
                <li>
                    <a href={pd2}>Research Promotion Policy</a>
                </li>
                <li>
                    <a href={pd3}>Policy Document of Green Campus Initiatives</a>
                </li>
                <li>
                    <a href={pd4}>Policy Document on Code of Conduct</a>
                </li>
                <li>
                    <a href={pd5}>Policy Document Code of Ethics</a>
                </li>
                <li>
                    <a href={pd6}>Grievance Redressal</a>
                </li>
                <li>
                    <a href={pd7}>E-Governance Policy</a>
                </li>
                <li>
                    <a href={pd8}>E-Communication Policy</a>
                </li>
                <li>
                    <a href={pd9}>Staff Quarter</a>
                </li>
                <li>
                    <a href={pd10}>Hostel Rules Final</a>
                </li>
                <li>
                    <a href={pd11}>Condemnation Policy</a>
                </li>
            </ul>
        </div>
    )
}

export default IqacPolicy