import React, { useState } from "react";
import "./DpsruHomeTabs.css";
import { Link } from "react-router-dom";

// File Import

// at 10/10/23
import meenuGSharma from "./TabFiles/WhatsNew/WhatsNew Data/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import proudMomentranked from "./TabFiles/WhatsNew/WhatsNew Data/proud-moment-for-our-university-ranked-first-in-North-zone.pdf";
import GpatScore from "./TabFiles/WhatsNew/WhatsNew Data/DSPRU-GPAT-Score-2023.pdf";
import ExaminationJune from "./TabFiles/WhatsNew/WhatsNew Data/NOTICE-OF-EXAMINATION-JUNE-2023.pdf";
import CycleRally from "./TabFiles/WhatsNew/WhatsNew Data/WhatsApp-Image-2023-05-29-at-8.07.24-AM-e1685359074892.jpeg";
import admissionAnnoucement from "./TabFiles/WhatsNew/WhatsNew Data/Advertisement-2023-24-1.pdf";






// new data at 17-05-2023

import Phd_Admission_202_24 from "./TabFiles/WhatsNew/WhatsNew Data/Phd_Admission_202_24.pdf";
import G20QuizCompetitionatDPSRU from "./TabFiles/WhatsNew/WhatsNew Data/G20QuizCompetitionatDPSRU.jpeg";
import iastamOration13new from "./TabFiles/WhatsNew/WhatsNew Data/13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL CONCLAVE.pdf";

import dkyn02 from "./TabFiles/WhatsNew/WhatsNew Data/dkyn02.pdf";
import dkyn01 from "./TabFiles/WhatsNew/WhatsNew Data/dkyn01.pdf";
import r5thannualc from "./TabFiles/WhatsNew/WhatsNew Data/r5thannualc.pdf";
import dpsruAnnualCon from "./TabFiles/WhatsNew/WhatsNew Data/Convocation-Announcement-2-1.pdf";
import vigilanceAwarenessWeekImage from "./TabFiles/WhatsNew/WhatsNew Data/vigilanceAwarenessWeekImage.jpeg";
import NoticeForStudentFacultyAndStaff from "./TabFiles/WhatsNew/WhatsNew Data/NoticeForStudentFacultyAndStaff.pdf";
import ApplicationsforadmissionsofPGprogramsextendedto29thAugust2022 from "./TabFiles/WhatsNew/WhatsNew Data/Applications-for-admissions-of-PG-programs-extended-to-29th-August-2022.jpg";
// import scanSRF from './TabFiles/WhatsNew/WhatsNew Data/scan-SRF.pdf'
// import DPSRUNIBJointCertificateProgramonQualityAssuranceinBiopharmaceuticals from './TabFiles/WhatsNew/WhatsNew Data/DPSRU-NIB-Joint-Certificate-Program-on-Quality-Assurance-in-Biopharmaceuticals.jpg'
// import ListoFStudentSeclectedForPrePhD from './TabFiles/WhatsNew/WhatsNew Data/ListoFStudentSeclectedForPrePhD.pdf'
// import PrePhdNoticeAnnexure2022 from './TabFiles/WhatsNew/WhatsNew Data/PrePhdNoticeAnnexure2022.pdf'
// import Listofcandidatesofphdentranceexam from './TabFiles/WhatsNew/WhatsNew Data/List-of-candidates-of-phd-entrance-exam.pdf'
// import NoticeForPrePhDEntreanceTest from './TabFiles/WhatsNew/WhatsNew Data/NoticeForPrePhDEntreanceTest.jpeg'
// import PhDAdmissionForm from './TabFiles/WhatsNew/WhatsNew Data/Ph.D-Admission-Form.pdf'

// student file import

import ProbvisionalMeritListONonD from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import ProbvisionalMeritListDelhiC from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";


import vivasewteparoha from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";
import evensemexamform from "./TabFiles/News Of Dpsru/News Of Dpsru Data/EVEN-SEMESTER-EXAMINATION-FORM-NOTICE-2023.pdf";
import consSche4thround from "./TabFiles/News Of Dpsru/News Of Dpsru Data/4th-Round-Counselling-Schedule.pdf";
import PhdSupplementaryFee from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Pre-Ph.D-Supplementary-Fee-Notice_1.pdf";
import ThirdRoudCons from "./TabFiles/News Of Dpsru/News Of Dpsru Data/THIRD-ROUND-Counselling-Schedule.pdf";
import firstRoundCons from "./TabFiles/News Of Dpsru/News Of Dpsru Data/First-round-online-counseling-2023.pdf";
import ExamFeeNotice from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Examination-Fee-Notice-2023.pdf";
import AnnouncementOfAdmission from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Advertisement-2023-24-1.pdf";
import HostalMessFee from "./TabFiles/News Of Dpsru/News Of Dpsru Data/WhatsApp-Image-2023-05-23-at-2.17.13-PM.pdf";


import dpsruHarGharTiranga3 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/dpsruHarGharTiranga3.pdf";
import ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Prof.-Ramesh-Goyals-visit-to-UCSI-University-as-Distinguished-Visiting-Faculty.pdf";
import DSIRDPSRUGujaratvisit1 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/DSIR-DPSRU-Gujarat-visit-1.pdf";
import CAFTConstitutiondaycelebrations from "./TabFiles/News Of Dpsru/News Of Dpsru Data/CAFT-Constitution-day-celebrations.pdf";
import pediatricunit from "./TabFiles/News Of Dpsru/News Of Dpsru Data/pediatric-unit.pdf";
import coviddrugnews from "./TabFiles/News Of Dpsru/News Of Dpsru Data/covid-drug-news.pdf";
import BookRelease from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Book-Release.pdf";
import highimpactresearchpapermodified1 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/high-impact-research-paper-modified-1.pdf";
import DPSRUStudentsAchievement from "./TabFiles/News Of Dpsru/News Of Dpsru Data/DPSRU-Students-Achievement.pdf";

import CONGRATULATIONS_GAURAV from "./TabFiles/News Of Dpsru/News Of Dpsru Data/CONGRATULATIONS_GAURAV.pdf";
import ReAppointmentOfProfessior from "./TabFiles/News Of Dpsru/News Of Dpsru Data/ReAppointmentOfProfessior.jpeg";

// archivment
import vivaMsNeha from "./TabFiles/News Of Dpsru/Ph.D-Viva-Voce-Notification-of-Ms.-Neha.pdf";
import vivaMrPrateek from "./TabFiles/News Of Dpsru/Ph.D-Viva-Voce-Notification-of-Mr.-Prateek-Sharma.pdf";

import walkinInterview3010 from "../Notifications/Notification Files/Advertisement.pdf";


import circularForTakingIntegrityPledge from '../Notifications/Notification Files/Circular for taking integrity pledge.pdf'
import CircularForTakinganOrganistionalOath from '../Notifications/Notification Files/Circular for taking an organisational Oath on 3 November 2023 in DPSRU.pdf'
import convoForm6th from '../Notifications/Notification Files/6th convocation registration form 2023.pdf'
import walkinInterviewOn17Nov from '../Notifications/Notification Files/Interview on 17.11.23.pdf'

import walkinInterviewResult from '../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf'


// File Import end

function DpsruHomeTabs() {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  // ActiveButton
  const [isActiveFirst, setIsActiveFirst] = useState(true);
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [isActiveThird, setIsActiveThird] = useState(false);

  const handleClickFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
    setIsActiveFirst(true);
    setIsActiveSecond(false);
    setIsActiveThird(false);
  };
  const handleClickSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
    setIsActiveFirst(false);
    setIsActiveSecond(true);
    setIsActiveThird(false);
  };
  const handleClickThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
    setIsActiveFirst(false);
    setIsActiveSecond(false);
    setIsActiveThird(true);
  };
  return (
    <div className="testing-tabs-component">
      <div className="testing-tab-container-of-full-content">
        <div className="button-box-of-tab">
          <button
            onClick={() => handleClickFirst()}
            className={
              isActiveFirst
                ? "tabsCompBtn isActiveTabBtn isActiveFirstBtn"
                : "tabsCompBtn"
            }
          >
            WHAT'S NEW
          </button>

          <button
            onClick={() => handleClickSecond()}
            className={
              isActiveSecond
                ? "tabsCompBtn isActiveTabBtn isActiveSecondBtn"
                : "tabsCompBtn"
            }
          >
            STUDENT'S NOTICE BOARD
          </button>

          <button
            onClick={() => handleClickThird()}
            className={
              isActiveThird
                ? "tabsCompBtn isActiveTabBtn isActiveThirdBtn"
                : "tabsCompBtn"
            }
          >
            ACHIEVEMENTS / NEWS OF DPSRU
          </button>
        </div>

        {/* First tab */}
        {first ? (
          <div className="inner-container-of-tab-button">
            <h2 className="inner-container-heading-of-tab-button">
              WHAT'S NEW
            </h2>
            <br />
            <div className="inner-content-container-of-tab-home-dpsru">
              {/* First section */}
              <ul className="first-ul-of-tab-container">


                
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={walkinInterviewResult}>
                      Result of Walk-in-Interview for Guest Faculties held on 03/11/2023
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   09-11-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}


                
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={walkinInterviewOn17Nov}>
                      Walk-in Interview for Guest Faculties of Pharmacy and Physiotherapy on 17/11/2023 (Reporting Time strictly from 10:00 AM to 10:30 AM)
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   09-11-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={convoForm6th}>
                      6th convocation registration form 2023
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   07-11-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}


                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CircularForTakinganOrganistionalOath}>
                      Observance of Vigilance Awareness Week 2023 in DPSRU from 30 Oct to 05 Nov 2023 (Circular for Organizational Oath)
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   31-10-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={circularForTakingIntegrityPledge}>
                      Circular for taking integrity pledge Individually
                              </a> || <a href="https://pledge.cvc.nic.in/" style={{color:"#c10000"}}>Click here..</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   31-10-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={walkinInterview3010}>
                        
                      "Walk-in- Interview for Guest Faculties on 03/11/2023 (Reporting Time strictly from 10:00 AM to 11:00 AM)
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   20-10-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href='/'>
                        
                      Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on  01.10.2023                    </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    04-10-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}
                
             
              
             
              
                {/* one section end */}
               
              </ul>

              {/* second section */}
              <ul className="first-ul-of-tab-container">

                   {/* one section */}
                   <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={meenuGSharma} >
                        
                      PhD Viva-Voce Notification of Ms. Meenu Grover Sharma                     </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    09-08-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                  {/* one section */}
                  <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={proudMomentranked}>
                        
                      Proud Moment for Our University Ranked First in North Zone                     </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    23-07-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}

                   {/* one section */}
                   <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href='/naac'>
                        
                      NAAC Peer Team Visit                    </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    19-07-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}


                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={GpatScore}>
                        
                      DPSRU GPAT Score 2023                 </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-07-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}

                {/* old 10-10-23 */}
                 {/* one section */}

  {/* one section */}
  <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ExaminationJune}>
                        
                      NOTICE OF EXAMINATION, JUNE 2023             </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    JUNE 2023   

                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CycleRally}>
                        
                      Celebrated G20 conferences through cycle rally           </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    JUNE 2023   

                    </li>
                  </ul>
                </li>

                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={admissionAnnoucement}>
                        
                      Announcement of Admissions (2023-24)         </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}



                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={Phd_Admission_202_24}>
                        
                        Notice for Ph.D Admission
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      13-05-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}

             
              
                             </ul>

              {/* third section */}
              <ul className="first-ul-of-tab-container">


   {/* one section */}
   <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={G20QuizCompetitionatDPSRU}>
                        G20 Quiz Competition at DPSRU
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      26-04-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
               

 {/* one section */}
 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link
                        to="/G20MahaFoodFestival"
                        className="whats-new-data"
                      >
                        G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at
                        DPSRU
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      04-03-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}



  {/* one section */}
  <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <Link to="/pushpPradarshnam" className="whats-new-data">
              PUSHP PRADARSHNAM 2023
            </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      27-02-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to="/indoBrazilFusionFest">G20 Indo Brazil Fusion Fest</Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      18-02-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}


                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                     <Link to='/g20PanelDiscussionReport'>
                        Panel Discussion @DPSRU on “TO INVEST IN PEOPLE,
                        PRIORITIZE EDUCATION”
                        </Link>
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={iastamOration13new}>
                        13 IASTAM ORATION AND AWARDS FUNCTION-2023 &
                        INTERNATIONAL CONCLAVE
                      </a>
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}
                {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href='/ucps'>
                        Rehearsal Timings For Convocation 2022.
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* one section end */}

                {/* old data */}
                {/* one section */}
                {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={dkyn02}> Dilli Ki Yogshala Notice 2</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      04-11-2022
                    </li>
                  </ul>
                </li> */}
                {/* one section end */}

                {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={dkyn01}>Dilli ki Yogshala Notice 1</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      04-11-2022
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={r5thannualc}>
                        Registration Form for the 5th Annual Convocation
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      02-11-2022
                    </li>
                  </ul>
                </li> */}
                {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={dpsruAnnualCon}>
                        DPSRU 5th Annual Convocation Announcement
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      02-11-2022
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href="https://pledge.cvc.nic.in/">
                        All the students, teaching & Non teaching Staff are
                        requested to take an integrity pledge on this link
                        mentioned
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={vigilanceAwarenessWeekImage}>
                        Vigilance Awareness Week Workshop Notice
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      27-10-2022
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href="https://forms.gle/j3sKgfBsgpnjvNbBA">
                        Link to Fill the Sports Survey Form by Faculty, Staff
                        and Students
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={NoticeForStudentFacultyAndStaff}>
                        Notice for Students, Faculty and Staff
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      26-10-2022
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a
                        href={
                          ApplicationsforadmissionsofPGprogramsextendedto29thAugust2022
                        }
                      >
                        Last Date of Applications for the admission of Executive
                        MPharm (DRA), MSc AI in Medicine, PGDCR and DPSRU NIB
                        Joint Certificate Course in Quality Assurance of
                        Biopharmaceuticals extended to Monday 29th August 2022
                      </a>
                    </li>
                  </ul>
                </li> */}

                {/* commented on 10/10/2023 */}
              </ul>
            </div>
            {/* Button of Read More */}
            <Link to="/whatsNew" class="animated-button1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Read More
            </Link>
          </div>
        ) : null}

        {/* second tab */}
        {/* second tab */}
        {second ? (
          <div className="inner-container-of-tab-button">
            <h2 className="inner-container-heading-of-tab-button">
              STUDENT'S NOTICE BOARD
            </h2>
            <br />
            <div className="inner-content-container-of-tab-home-dpsru">
              {/* First section */}
              <ul className="first-ul-of-tab-container">
   {/* one section */}
   <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={convoForm6th}>
                      6th convocation registration form 2023
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   07-11-2023

                    </li>
                  </ul>
                </li>
                {/* one section end */}


 {/* one section */}
 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={vivasewteparoha}>
                        
                      Ph.D Viva-Voce Notification of Ms. Shweta Paroha      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    27-09-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={evensemexamform}>
                        
                      Even Semester Examination Form Notice, 2023     </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    02-08-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={consSche4thround}>
                        
                      4th Round Online Counselling Schedule – 2023   </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    22-07-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={PhdSupplementaryFee}>
                        
                      Pre-Ph.D Supplementary Fee Notice   </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    22-07-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ThirdRoudCons}>
                        
                      Third round online counselling schedule-2023   </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
               
               
                
              </ul>

              {/* second section */}
              <ul className="first-ul-of-tab-container">



  {/* one section */}
  <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={firstRoundCons}>
                        
                      Second round online counselling schedule-2023 </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
  {/* one section */}
  <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={GpatScore}>
                        
                      DPSRU GPAT Score 2023 </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={firstRoundCons}>
                        
                      First round online counselling schedule-2023</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ProbvisionalMeritListONonD}>
                        
                      Provisional Merit Lists of Non-Delhi candidates for UG courses-2023</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ProbvisionalMeritListDelhiC}>
                        
                      Provisional Merit Lists of Delhi candidates for UG courses-2023</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ExamFeeNotice}>
                        
                      Examination Fee Notice 2023</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                
                
              </ul>

              {/* third section */}
              <ul className="first-ul-of-tab-container">

                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={AnnouncementOfAdmission}>
                        
                      Announcement of Admissions (2023-24)</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                 {/* one section */}
                 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={HostalMessFee}>
                        
                      Hostel Mess Fee</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li>
                {/* one section end */}



 {/* one section */}
 <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={Phd_Admission_202_24}>
                        {" "}
                        Notice for Ph.D Admission
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      13-05-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}


                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      {" "}
                      <Link to="/">
                        Ph.D Viva-Voce Notification of Ms. Anita Kumari
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      08-02-2023
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      {" "}
                      <Link to="/">
                        Examination Fees Notice for Pre. Ph.D. Students
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      03-02-2023
                    </li>
                  </ul>
                </li>
{/* 
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to="/ucps">
                        {" "}
                        Ph.D Viva-Voce Notification of Ms. Lovika Mittal
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      25-01-2023
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to="/ucps">
                        {" "}
                        Examination Fees Notice for Odd Semester Students
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      16-01-2023
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to="/iastamOration13" className="whats_new1">
                        {" "}
                        13 IASTAM ORATION AND AWARDS FUNCTION-2023 &
                        INTERNATIONAL CONCLAVE
                      </Link>
                    </li>
                  </ul>
                </li>




              <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href="/ucps">Selected Candidates DST</a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={dpsruHarGharTiranga3}>
                        DPSRU Celebrates Har Ghar Tiranga events under Azadi ka
                        Amrit Mahotsav
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a
                        href={
                          ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty
                        }
                      >
                        Hon'ble VC Prof. Ramesh Goyal's visit to UCSI University
                        as Distinguished Visiting Professor
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={DSIRDPSRUGujaratvisit1}>
                        DSIR-DPSRU-CRTDH CAFT Team Gujarat Visit
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CAFTConstitutiondaycelebrations}>
                        DSIR-DPSRU-CRTDH Constitution Day Celebration
                      </a>
                    </li>
                  </ul>
                </li>

              <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={pediatricunit}>
                        News on Inauguration of Pediatric Physiotherapy Unit
                      </a>
                    </li>
                  </ul>
                </li>  
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={coviddrugnews}>
                        DPSRU - The Research Partner Behind Scientifically
                        Formulated Breakthrough Drug that Works on Covid19
                        Co-Morbidities
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={BookRelease}>
                        Release of book entitled कोरोनाकाल - चिकित्सक की कलम से
                        , authored by Prof. (Dr.) Shridhar Dwivedi, Honourable
                        Chairman, BOG, DPSRU
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={highimpactresearchpapermodified1}>
                        High Impact Research Paper Published by DPSRU Faculty
                      </a>
                    </li>
                  </ul>
                </li> */} 
                {/* commented on 10-10-23 */}
              </ul>
            </div>
            {/* Button of Read More */}
            <Link to="/newsOfDpsru" class="animated-button1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Read More
            </Link>
          </div>
        ) : null}

        {/* Third tab */}
        {/* Third tab */}
        {third ? (
          <div className="inner-container-of-tab-button">
            <h2 className="inner-container-heading-of-tab-button">
              ACHIEVEMENTS / NEWS OF DPSRU
            </h2>
            <br />
            <div className="inner-content-container-of-tab-home-dpsru">
              {/* First section */}
              <ul className="first-ul-of-tab-container">


                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={vivaMsNeha}>
                      Ph.D Viva-Voce Notification of Ms. Neha
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-10-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={vivaMrPrateek}>
                      Ph.D Viva-Voce Notification of Mr. Prateek Sharma
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-10-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to='/Swachhta-Hi-seva'>
                      Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on 01.10.2023
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    04-10-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}

                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <Link to='/Medical-Laboratory-Technology-week'>
                      Medical Laboratory Technology week (17-23 July, 2023) celebrated by DPSRU at AIIMS, New Delhi
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    17-07-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}

                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={GpatScore}>
                      DPSRU GPAT Score 2023
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      -
                    </li>
                  </ul>
                </li>
                {/* one section end */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CycleRally}>
                      Celebrated G20 conferences through cycle rally
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                     -
                    </li>
                  </ul>
                </li>
                {/* one section end */}

               

              </ul>

              {/* second section */}
              <ul className="first-ul-of-tab-container">



 {/* one section */}


                {/* one section */}
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={G20QuizCompetitionatDPSRU}>
                        G20 Quiz Competition at DPSRU
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      26-04-2023
                    </li>
                  </ul>
                </li>
                {/* one section end */}

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <Link to="/iastamOration13">
                        13 IASTAM ORATION AND AWARDS FUNCTION-2023 &
                        INTERNATIONAL CONCLAVE
                      </Link>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      21-01-2023
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href="/">Selected Candidates DST</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      06-12-2022
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={dpsruHarGharTiranga3}>
                        DPSRU Celebrates Har Ghar Tiranga events under Azadi ka
                        Amrit Mahotsav
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      29-08-2022
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a
                        href={
                          ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty
                        }
                      >
                        Hon'ble VC Prof. Ramesh Goyal's visit to UCSI University
                        as Distinguished Visiting Professor
                      </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                      11-07-2022
                    </li>
                  </ul>
                </li>


               
              </ul>

              {/* third section */}
              <ul className="first-ul-of-tab-container">



              <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={DSIRDPSRUGujaratvisit1}>
                        DSIR-DPSRU-CRTDH CAFT Team Gujarat Visit
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CAFTConstitutiondaycelebrations}>
                        DSIR-DPSRU-CRTDH Constitution Day Celebration
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={pediatricunit}>
                        {" "}
                        News on Inauguration of Pediatric Physiotherapy Unit
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={coviddrugnews}>
                        DPSRU - The Research Partner Behind Scientifically
                        Formulated Breakthrough Drug that Works on Covid19
                        Co-Morbidities
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={BookRelease}>
                        Release of book entitled कोरोनाकाल - चिकित्सक की कलम से
                        , authored by Prof. (Dr.) Shridhar Dwivedi, Honourable
                        Chairman, BOG, DPSRU
                      </a>
                    </li>
                  </ul>
                </li>



              <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={highimpactresearchpapermodified1}>
                        High Impact Research Paper Published by DPSRU Faculty
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={DPSRUStudentsAchievement}>
                        DPSRU Student Shweta Mittal Wins Cash Prize and
                        Accolades at Young Pharmaceutical Researchers Award
                      </a>
                    </li>
                  </ul>
                </li> */}
{/* 
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={CONGRATULATIONS_GAURAV}>
                        DPSRU Faculty Dr. Gaurav Jain awarded IPA -ACG Worldwide
                        & Scitech Center Award for Innovative Product for Year
                        2020
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href="https://timesofindia.indiatimes.com/education/news/covid-19-third-wave-dpsru-opens-community-health-facility-at-university-premises/articleshow/84060430.cms">
                        DPSRU Community Health Facility Inauguration Ceremony
                        Covered by TOI Online
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ReAppointmentOfProfessior}>
                        Re-appointment of Professor R. K. Goyal as the
                        Honourable Vice Chancellor of DPSRU
                      </a>
                    </li>
                  </ul>
                </li> */} 
                {/* commented on 10-10023 */}
              </ul>
            </div>
            {/* Button of Read More */}
            <Link to="/newsOfDpsru" class="animated-button1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Read More
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DpsruHomeTabs;

// this is tab button like crome.... we can click any button and the releted content of the button will shown
// i created onClick event on button and set other buttons false and clicked button as true
// for active i created a className with same onClick function . when i click on that button active link of that button will be true and anothor's buttton className will false
