import React from "react";
import "./SubscribedResources.css";

const SubscribedResources = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Subscribed Resources
      </h2>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Electronic Resources (for Remote Access, use Login ID and Password)
      </h2>

      <ul className="subscribed-resources-ul">
        <li>
          <a href="http://www.eurekaselect.com/">Bentham Science</a> : Life
          science Collection:64
        </li>
        <li>
          <a href="https://www.proquest.com/">ProQuest Database</a> : Management
          Collection
        </li>

        <p>
          Print Books : <a href="http://dpsru.bestbookbuddies.com/">Web OPAC</a>
        </p>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Subscribed Pharma Journal/Magazine List (Print)
      </h2>

      <ul className="subscribed-resources-ul">
        <li>Current Science&nbsp;</li>
        <li>Pharma Times,&nbsp;</li>
        <li>
          <a href="http://www.saffronmedia.in/eMagazines/CP/2022/CP22092022/pageflip.aspx?p=qtDTVnI1vRs=">
            Chronicle Pharmabiz
          </a>
        </li>
        <li>India Today (E)</li>
        <li>India Today (H)</li>
        <li>Outlook</li>
        <li>Grihashobha</li>
        <li>Grihalakshmi</li>
        <li>Reader Digest</li>
        <li>Famina</li>
        <li>Sarita</li>
        <li>Sportstar</li>
        <li>The Week</li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Daily Newspapers in Print
      </h2>

      <ul className="subscribed-resources-ul">
        <li>The Times of India</li>
        <li>Hindustan Times</li>
        <li>Indian express</li>
        <li>Economics Times</li>
        <li>Employment news</li>
        <li>Dainik Jagran</li>
        <li>Navbharat Times</li>
        <li>Rojgaar Smachar</li>
      </ul>
    </div>
  );
};

export default SubscribedResources;
