import React from 'react'
import './ExaminatinSyllabus.css'

import phd from './examination Syllabus Data/Pre-Ph.D.-syllabus.pdf'

import mpqa from './examination Syllabus Data/Quality-Assurance-Syllabus-Merge.pdf'
import mpcr from './examination Syllabus Data/Clinical-Research-Syllabus-Merge.pdf'
import mppc from './examination Syllabus Data/Syllabus-of-MPH-new.pdf'
import mpp from './examination Syllabus Data/Syllabus-M.Pharm-Biotech-Merge.pdf'
import mppp from './examination Syllabus Data/Pharmacology-Syllabus-Merge.pdf'
import mphcp from './examination Syllabus Data/MSc-AIPM-Syllabus.pdf'
import mpincos from './examination Syllabus Data/Syllabus-Cosmeceutics-Merge.pdf'
import mpidra from './examination Syllabus Data/Syllabus_M-Pharm-DRA-Merge.pdf'
import industrialPharma from './examination Syllabus Data/Industrial-Pharmacy-Syllabus-Merge.pdf'
import pharaAnalysis from './examination Syllabus Data/Pharm.-Analysis-Syllabus-Merge.pdf'
import mpbiotech from './examination Syllabus Data/Pharmaceutics-Syllabus-Merge.pdf'
import pharmacologys from './examination Syllabus Data/Pharmacognosy-Syllabus-Merge.pdf'
import mph from './examination Syllabus Data/Syllabus-of-MPH-1.pdf'
import mbaItm from './examination Syllabus Data/MBA-ITM-Syllabus.pdf'
import mbahhm from './examination Syllabus Data/MBA-HHM.pdf'
import mscAipm from './examination Syllabus Data/MSc-AIPM-Syllabus (1).pdf'
import mpsm from './examination Syllabus Data/MPT-Sports-Medicine-Syllabus.pdf'
import mptps from './examination Syllabus Data/MPT-Pediatrics-Syllabus.pdf'
import mpcp from './examination Syllabus Data/MPT-Cardiovascular-and-Pulmonary-final.pdf'
import mscSS from './examination Syllabus Data/M.-Sc.-Sports-Science-Syllabus.pdf'
import mped from './examination Syllabus Data/Syllabus_Exicutive-M-Pharm-DRS-Merge.pdf'

// bachelors
import bopharma from './examination Syllabus Data/Syllabus_B_Pharm-3.pdf'
import bophusiothephy from './examination Syllabus Data/BPT-Syllabus.pdf'
import bscHonsss from './examination Syllabus Data/B.-Sc-Hons.-Sports-Science.pdf'
import sbayr from './examination Syllabus Data/Syllabus-B.Pharm-Ayu-New.pdf'
import sbayrold from './examination Syllabus Data/Syllabus-B.Pharm-Ayu-Old.pdf'
import bmltOld from './examination Syllabus Data/BMLT-old.pdf'
import bmltNew from './examination Syllabus Data/BMLT-New.pdf'
import bbahc from './examination Syllabus Data/BBA-HC-new.pdf'
import BscBs from './examination Syllabus Data/Revised-Syllabus-B.Sc-22-JUNE-23.-Biomedical.pdf'

//Diploma 
import dipnew from './examination Syllabus Data/14-55_ER_20-_syllabus_23092021.pdf'
import dipold from './examination Syllabus Data/D.Pharm-Old-Syllabus.pdf'
import dmltnew from './examination Syllabus Data/DMLT-new.pdf'
import dmltold from './examination Syllabus Data/DMLT-old.pdf'

// Certificate Programmes (WCSC)
import medicinem from './examination Syllabus Data/WCSC-syllabus-Medicine-management.pdf'
import Bwc from './examination Syllabus Data/Syllabus-Beauty-wellness-consultant_6th-feb-2022-1.pdf'
import saya from './examination Syllabus Data/WCSC-Sports-Assessment-and-yoga-application-syllabus.pdf'

const ExaminatinSyllabus = () => {
  return (
    <div className="dpsru-every-component-css">
      <h1 className="dpsru-primary-heading-for-every-page">
      Syllabus
      </h1>
      
      <div className="examination-syllabus-container">
            <ul>
                <li><a href={phd}>Ph D</a></li>
            </ul>


            <h2 className="dpsru-secondary-heading-for-every-page">PG Program</h2>

             <ul>
                <li><a href={mpqa}>Master of Pharmacy in Quality Assurance</a></li>
                <li><a href={mpcr}>Master of Pharmacy in Clinical Research</a></li>
                <li><a href={mppc}>Master of Pharmacy in Pharmaceutical Chemistry</a></li>
                <li><a href={mpbiotech}>Master of Pharmacy in Pharmaceutics</a></li>
                <li><a href={mppp}>Master of Pharmacy in Pharmacology</a></li>
                <li><a href={mphcp}>Master of Pharmacy in Hospital and Clinical Pharmacy</a></li>
                <li><a href={mpincos}>Master of Pharmacy in Cosmecutics</a></li>
                <li><a href={mpidra}>Master of Pharmacy in Drug Regulatory Affairs</a></li>
                <li><a href={industrialPharma}>Master of Pharmacy in Industrial Pharmacy</a></li>
                <li><a href={pharaAnalysis}>Master of Pharmacy in Pharmaceutical Analysis</a></li>

                <li><a href={mpp}>Master of Pharmacy in Pharmaceutical Biotechnology</a></li>

                <li><a href={pharmacologys}>Master of Pharmacy in Pharmacognosy and Phytochemistry</a></li>
                <li><a >Master of Hospital Administration</a></li>
                <li><a href={mph} >Master of Public Health</a></li>
                <li><a >MBA (Pharmaceutical Management)</a></li>
                <li><a href={mbaItm}>MBA - International Trade Management</a></li>
                <li><a >MBA (Pharma Economics and Trade)</a></li>
                <li><a href={mbahhm}>MBA (Healthcare & Hospital Management)</a></li>
                <li><a href={mscAipm}>M.Sc. (Artificial Intelligence and Precision Medicine)</a></li>
                <li><a >M. Sc Artificial Intelligence in Medicine</a></li>
                <li><a href={mpsm} >Master of Physiotherapy in Sports Medicine</a></li>
                <li><a href={mptps} >Master of Physiotherapy in Pediatrics</a></li>
                <li><a href={mpcp} >Master of Physiotherapy in Cardiovascular and Pulmonary</a></li>
                <li><a href={mscSS} >M.Sc. Sport Science</a></li>
                <li><a href={mped} >Master of Pharmacy (Executive – Drug Regulatory Science)</a></li>
            </ul>

            <h2 className="dpsru-secondary-heading-for-every-page">PG Diploma</h2>

             <ul>
                <li><a >PG Diploma in Plant Tissue Culture</a></li>
                </ul>


            <h2 className="dpsru-secondary-heading-for-every-page">UG Program</h2>

             <ul>
                <li><a href={bopharma}>Bachelor of Pharmacy</a></li>
                <li><a href={bophusiothephy}>Bachelor of Physiotherapy</a></li>
                <li><a href={bscHonsss}>B.Sc. (Hons.) Sports Science</a></li>
                <li><a href={sbayr}>Bachelor of Pharmacy (Ayurveda) (New)</a></li>
                <li><a href={sbayrold}>Bachelor of Pharmacy (Ayurveda) (old)</a></li>
                <li><a href={bmltOld}>BMLT (Old)</a></li>
                <li><a href={bmltNew}>BMLT (New)</a></li>
                <li><a href={bbahc}>BBA (Community Healthcare)(new)</a></li>
                <li><a href={BscBs}>B. Sc (Hons) Biomedical Science</a></li>
                </ul>


                <h2 className="dpsru-secondary-heading-for-every-page">Diploma programmes</h2>
                <ul>
                    <li><a href={dipnew}>Diploma in Pharmacy (New)</a></li>
                    <li><a href={dipold}>Diploma in Pharmacy (Old)</a></li>
                    <li><a href={dmltnew}>DMLT (New)</a></li>
                    <li><a href={dmltold}>DMLT(Old)</a></li>
                </ul>

                <h2 className="dpsru-secondary-heading-for-every-page">Certificate Programmes (WCSC)</h2>
                <ul>
                    <li><a href={medicinem}>Medicine Management</a></li>
                    <li><a href={Bwc}>Beauty Wellness Consultant</a></li>
                    <li><a href={saya}>Sports Assessment & Yoga Application</a></li>
                   
                </ul>

      </div>
      </div>
  )
}

export default ExaminatinSyllabus