import React from "react";
import "./RD.css";
import { Link } from "react-router-dom";
import dcpnp from "./RDFiles/Centre-for-Precision.jpg";
import dsir from "./RDFiles/dsir.jpeg";

import Code_of_Ethics_for_research from './RDFiles/Code-of-Ethics-for-research.pdf'
import List_of_Patents from './RDFiles/List-of-Patents.pdf'
import Books_and_chapters from './RDFiles/Books-and-chapters.pdf'

function RD() {
  return (
    <div className="dpsru-every-component-css">
      <h3 className="dpsru-primary-heading-for-every-page">R&D</h3>

      <div className="link-container-of-rd-component">
        <ul>
          <li>
            <Link to="/areaOfResearch" className="link-of-rd-container">
              Facultywise Areas of Research
            </Link>
          </li>
          <li>
            <Link to="/researchPaper" className="link-of-rd-container">
              Research Papers
            </Link>
          </li>
          <li>
            <a href={Code_of_Ethics_for_research} className="link-of-rd-container">
              Code of Ethics for researchs
            </a>
          </li>
          <li>
            <a href={List_of_Patents} className="link-of-rd-container">
              Patents
            </a>
          </li>
          <li>
            <a href={Books_and_chapters} className="link-of-rd-container">
              Books and Book Chapters
            </a>
          </li>
          <li>
            <Link to="/RD_project" className="link-of-rd-container">
              Projects
            </Link>
          </li>
        </ul>
      </div>

      <div className="center-of-excellence-container-of-rd-container">
        <h4 className="dpsru-secondary-heading-for-every-page">Centers of Excellence</h4>

        <div className="data-container-of-centers-of-excellence-of-rd">
          <h5 className="sub-heading-of-centers-of-excellence-of-rd">
            DPSRU Centre for Precision Medicine and Pharmacy
          </h5>
          <img
            src={dcpnp}
            alt="DPSRU Centre for Precision Medicine and Pharmacy"
          />

          <p>
            The newly incepted DSIR-DPSRU-CRTDH celebrated the Constitution Day
            and Azadi ka Amrit Mahotsav from November 26–29th, 2021 along with
            many other activites, workshop on ‘Teledyne Hanson Diffusion
            System’, development of Herbal Gulal, etc. DPSRU CAFT team comprises
            of Hon’ble Vice Chancellor Prof. Ramesh K. Goyal, DPSRU and his team
            comprising of Prof. Deepti Pandita, Prof. Geeta Aggarwal, Dr. Gaurav
            K. Jain.
          </p>
        </div>
        <div className="data-container-of-centers-of-excellence-of-rd">
          <h5 className="sub-heading-of-centers-of-excellence-of-rd">
            DSIR-DPSRU-CRTDH Centre for Advanced Formulation Technology(CAFT)
          </h5>
          <img
            src={dsir}
            alt="DPSRU Centre for Precision Medicine and Pharmacy"
          />

          <p>
            The newly incepted DSIR-DPSRU-CRTDH celebrated the Constitution Day
            and Azadi ka Amrit Mahotsav from November 26–29th, 2021 along with
            many other activites, workshop on ‘Teledyne Hanson Diffusion
            System’, development of Herbal Gulal, etc. <br />
            DPSRU CAFT team comprises of Hon’ble Vice Chancellor Prof. Ramesh K.
            Goyal, DPSRU and his team comprising of Prof. Deepti Pandita, Prof.
            Geeta Aggarwal, Dr. Gaurav K. Jain.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RD;
