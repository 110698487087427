import React from "react";
// import Login from "../Login/Login";
// import { NavLink } from "react-router-dom";
import './Admin.css'
import AdminNav from "../AdminNav/AdminNav";

const Admin = () => {
  return (
    <div className="dpsru-every-component-css admin-component">
      <h2 className=" admin-heading-new">A-D-M-I-N</h2>
      <AdminNav/>
    </div>
  );
};

export default Admin;
