import React from "react";
// import { Link } from "react-router-dom";
import "./DeveloperDetails.css";
import { FaInstagram, FaWhatsapp, FaLinkedinIn, FaPhone } from "react-icons/fa";
import { VscMail, VscLink } from "react-icons/vsc";

function DeveloperDetails() {
  return (
    <div className="developer-details-component">
      <h2 className="developer-component-heading">
        {" "}
        Please Don't Hesitate To Contact Me If You Have Any question or Feedback
      </h2>

      <p className="from-avcom-dev" >Avcom Network Technologies</p>
      <div className="dev-person-details">
        {/* <p className='dev-name'><span>Name : </span> Brajesh Kumar Thakur</p> */}
        <div class="developerName-of-dpsru">
  <div className="developer-name-text-box-of-dpsru">
 
    <span  style={{'--i': 1}}>B</span>
    <span  style={{'--i': 2}}>R</span>
    <span  style={{'--i': 3}}>A</span>
    <span  style={{'--i': 4}}>J</span>
    <span  style={{'--i': 5}}>E</span>
    <span  style={{'--i': 6}}>S</span>
    <span  style={{'--i': 7}}>H</span>
    
  </div>
</div>
        {/* <p className='dev-name'><span>Name : </span> Brajesh Kumar Thakur</p> */}
        <p className="dev-name">
          <span>Phone : </span>{" "}
          <a
            href="tel:7492992502"
            target="_blank" rel="noreferrer"
            style={{ textDecoration: "none", color: "none" }}
          >
            {" "}
            +91 74929 92502
          </a>
        </p>
        <p className="dev-email-details">
          <span>Email :</span>
          <a
            href="mailto:brajeshfrombr06@gmail.com?subject=Feedback%20on%20dpsru%20website&body=Hi,%20I%20wanted%20to%20provide%20some%20feedback%20on%20your%20website"
            target="_blank" rel="noreferrer"
          >
            brajeshfrombr06@gmail.com{" "}
          </a>{" "}
        </p>
      </div>

      <div className="developer-social-media-link">
        <ul>
          <li>
            <a href="https://instagram.com/lazyfrombr06" target="_blank" rel="noreferrer">
              <FaInstagram className="developer-social-media dev-insta" />
            </a>
          </li>

          <li>
            <a
              href="https://wa.me/7492992502?text=Hi..%20I'm%20from%20dpsru%20website%20and%20I%20have%20a%20...%20(suggestion or feedback)"
              target="_blank" rel="noreferrer"
            >
              <FaWhatsapp className="developer-social-media dev-whatsapp" />
            </a>
          </li>

          <li>
            <a
              href="https://www.linkedin.com/in/brajesh-kumar-881ab2236"
              target="_blank" rel="noreferrer"
            >
              <FaLinkedinIn className="developer-social-media dev-linkedin" />
            </a>
          </li>

          <li>
            <a href="tel:7492992502" target="_blank" rel="noreferrer">
              <FaPhone className="developer-social-media dev-phone" />
            </a>
          </li>

          <li>
            <a
              href="mailto:brajeshfrombr06@gmail.com?subject=Feedback%20on%20dpsru%20website&body=Hi,%20I%20wanted%20to%20provide%20some%20feedback%20on%20your%20website"
              target="_blank" rel="noreferrer"
            >
              <VscMail className="developer-social-media dev-mail" />
            </a>
          </li>

          <li>
            <a href="https://brajeshfrombr06.netlify.app/" target="_blank" rel="noreferrer">
              <VscLink className="developer-social-media dev-link" />
            </a>
          </li>
        </ul>
      </div>
     
    </div>
  );
}

export default DeveloperDetails;
