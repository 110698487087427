import React from 'react'

import bPharm from './Placement Record 2017-2018 Files/BPHARM-17-18.pdf'
import mba from './Placement Record 2017-2018 Files/MBA-17-18.pdf'
import mhm from './Placement Record 2017-2018 Files/MHM-17-18.pdf'
import mPharm from './Placement Record 2017-2018 Files/MPHARM-17-18.pdf'


const PlacementRecord20172018 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Placement Record 2017- 2018</h2>

    <div className="results-data-link-container">
      <ul>
        <li>
          <a href={bPharm}>
          B. Pharm 
          </a>
        </li>
        <li>
          <a href={mba}>
          MBA
          </a>
        </li>
        <li>
          <a href={mhm}>
      MHM
          </a>
        </li>
        <li>
          <a href={mPharm}>
          M.PHARM 
          </a>
        </li>
      
     
      </ul>
    </div></div>
  )
}

export default PlacementRecord20172018