import React from "react";
import "./Students.css";

import newGif from '../NEW/new.gif'

// new data on 11-10-23
import newSData01 from './StudentsData/DocScanner-Sep-25-2023-11-01.pdf'
import newSData02 from './StudentsData/notice-regarding-reopen-fee-portal.pdf'
import newSData03 from './StudentsData/CamScanner-08-09-2023-10.49.25_1.pdf'
import newSData04 from './StudentsData/DocScanner-Aug-10-2023-17-57.pdf'
import newSData05 from './StudentsData/DocScanner-Aug-10-2023-18-00.pdf'
import newSData06 from './StudentsData/Hostel-Admission-Form-2023-24.pdf'
import newSData08 from './StudentsData/DocScanner-Jul-31-2023-15-54.pdf'
import newSData09 from './StudentsData/hostel-notice.pdf'
import newSData10 from './StudentsData/hostel-allotment.pdf'
import newSData11 from './StudentsData/Extension-of-Hostel-fees-and-Mess-fess-portal.pdf'
import newSData12 from './StudentsData/Hostel-Fees-and-Mess-Fees-Portal-Opening.pdf'
import newSData13 from './StudentsData/Girls-hostel-mess-fees-portal.pdf'
import newSData14 from './StudentsData/Examination-Fee-Notice-2023.pdf'
import newSData15 from './StudentsData/WhatsApp-Image-2023-05-23-at-2.17.13-PM.pdf'
import newSData16 from './StudentsData/Adobe-Scan-22-May-2023-1.pdf'

import s1 from "./StudentsData/s1.pdf";
import s2 from "./StudentsData/s2.pdf";
import s3 from "./StudentsData/s3.pdf";
import s4 from "./StudentsData/s4.pdf";
import s5 from "./StudentsData/s5.jpeg";
import s6 from "./StudentsData/s6.pdf";
import s7 from "./StudentsData/s7.doc";
import s8 from "./StudentsData/s8.pdf";
import s9 from "./StudentsData/s9.pdf";
import s10 from "./StudentsData/s10.pdf";
import s11 from "./StudentsData/s11.pdf";
import s12 from "./StudentsData/s12.pdf";
import s13 from "./StudentsData/s13.pdf";
import s14 from "./StudentsData/s14.jpg";
import s16 from "./StudentsData/s16.pdf";
import s17 from "./StudentsData/s17.pdf";
import s18 from "./StudentsData/s18.pdf";
import s19 from "./StudentsData/s19.pdf";
import s20 from "./StudentsData/s20.pdf";
import s21 from "./StudentsData/s21.pdf";
import s22 from "./StudentsData/s22.pdf";
import s23 from "./StudentsData/s23.pdf";
import s24 from "./StudentsData/s24.pdf";
import s25 from "./StudentsData/s25.pdf";
import s26 from "./StudentsData/s26.pdf";
import s27 from "./StudentsData/s27.pdf";
import s28 from "./StudentsData/s28.pdf";
import s29 from "./StudentsData/s29.pdf";
import s30 from "./StudentsData/s30.pdf";
import s31 from "./StudentsData/s31.pdf";
import s32 from "./StudentsData/s32.pdf";
import s33 from "./StudentsData/s33.pdf";
import s34 from "./StudentsData/s34.pdf";
import s35 from "./StudentsData/s35.doc";
import s36 from "./StudentsData/s36.pdf";
import s38 from "./StudentsData/s38.jpeg";
import s39 from "./StudentsData/s39.pdf";
import s40 from "./StudentsData/s40.pdf";
import s41 from "./StudentsData/s41.pdf";
import s42 from "./StudentsData/s42.pdf";

// sacond container
import a2021 from "./StudentsData/a2021.pdf";
import a1920 from "./StudentsData/a1920.pdf";
import a1819 from "./StudentsData/a1819.pdf";
import a1718 from "./StudentsData/a1718.pdf";
import a1617 from "./StudentsData/a1617.pdf";
import a1516 from "./StudentsData/a1516.pdf";
import FeeNotice from "./StudentsData/FeeNotice.pdf";

import aicte from "./StudentsData/AICTE-Saksham-Scheme-guidelines.pdf";

// Hostel
// import h1 from "./HostelData/Hostel-circular07jan2021.pdf";
// import h2 from "./HostelData/girl-hostel-due-list2021.pdf";
// import h3 from "./HostelData/Boyshostel-due-list2021.pdf";
// import h4 from "./HostelData/medical-certificate.docx";
// import h5 from "./HostelData/leave-application.docx";
// import h6 from "./HostelData/Hostel-rules30-Dec-2019_madhu.docx";
// import h7 from "./HostelData/hostel-id-card.doc";
// import h8 from "./HostelData/Hostel-Admission-Form.docx";
// import h9 from "./HostelData/Gate-pass.docx";
// import h10 from "./HostelData/Declaration-by-the-students.docx";
// import h11 from "./HostelData/ANTI-RAGGING-AFFIDAVIT.doc";

// new data 16-05-2023
import ScholarshipforMinorityDateExtension from "./StudentsData/Scholarship-for-Minority-Date-Extension.pdf";
import FinancialAssistanceschemeforFWS from "./StudentsData/Financial-Assistance-scheme-for-FWS.pdf";
import PhdAdmissions from "./StudentsData/P.hd-Admissions.pdf";
import NoticeForSamesterFee from "./StudentsData/NoticeForSamesterFee.pdf";
import MinorityScholarship from "./StudentsData/Minority-Scholarship.pdf";
import MPharmAttendanceNotice from "./StudentsData/MPharm-Attendance-Notice.pdf";
import girlshostelfeedetails from "./StudentsData/girls-hostel-feedetails.pdf";
import boyshostelfeedetails from "./StudentsData/boyshostel-fee-details.pdf";
import RegardingFeeComponent from "./StudentsData/RegardingFeeComponent.pdf";

// after live
// import oddSemFee from './StudentsData/CamScanner 10-23-2023 15.59.55_2.pdf'
import oddSemFeeReplaced from './StudentsData/odd semester fee notice.pdf'
import hostelNoticeforfeepayment from './HostelData/Notice for pay hostel and mess fees before 4_11_2023.pdf'
import finalListOfEligibleCandidatesForstudentsCouncil from '../Notifications/Notification Files/Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU.pdf'
import HostalAccommodationduringsessionfy2324 from './StudentsData/Hostel Accommodation during session FY 2023-24.pdf'
import girlshostalAccommodationduringsessionFY2324 from './StudentsData/Girls hotel Accommodation during session FY 2023-24.pdf'
import ropeningoffeeportalforoddsem from './StudentsData/Reopening of fee portal for odd semester.pdf'
import noticeforprofssagarwaltropyforbptstudents from './StudentsData/Notice for PROF. S.S. AGRAWAL TROPHY for BPT students.pdf'
// import noduescertificateformdipsar from './StudentsData/NO DUES CERTIFICATE Form DIPSAR.pdf'
// import noduescertificateformdpsru from './StudentsData/NO DUES CERTIFICATE Form DPSRU.pdf'
import circularawarenessRegardingbanonSharpMana from './StudentsData/CIRCULAR, Awareness Regarding Ban on Sharp Manja.pdf'
import ReOpeningofoddsemfeeportalnotice from './StudentsData/Re-Opening of Odd Semester Fee Portal, NOTICE.pdf'
import noduesAppformdpsrunew from './StudentsData/No Dues Application Form_DPSRU.pdf'
import noDuesAppformdipsarnew from './StudentsData/No Dues Application Form_DIPSAR.pdf'
import openingofevensemfeenotice from './StudentsData/Opening of Even Semester fee Notice.pdf'
import ReOpeningofEvensemfeeSem from './StudentsData/Re-opening of Even Semester Fee Semester.pdf'
import BanSharpManja from './StudentsData/Ban Sharp Manjha.pdf'
import IcardNotice24 from './StudentsData/I-Card Notice.pdf'
import icardForm24 from './StudentsData/I-Card Form.pdf'
import OddSemFeeNotice24 from './StudentsData/ODD SEMESTER FEE NOTICE 2024.pdf'
import oddsemfeenotice2424 from './StudentsData/Odd Semester reopening fee notice 2024-25.pdf'
import finallistofboysHostel from './HostelData/Final list of Boys Hostel.pdf'
import ReopeningofODDsemfeePortal from './StudentsData/Reopening of Odd Semester Fee Portal.pdf'
import AwarenessnoticeforDrugAbuse from './StudentsData/Awareness notice for Drug Abuse.pdf'





import { Link } from "react-router-dom";

import { useState } from "react";
import { FaSortDown } from "react-icons/fa";

function Students() {
  const [isToggledsor, setIsToggledsor] = useState(false);
  const [isToggledpcbd, setIsToggledpcbd] = useState(false);
  // const [isToggledHostel, setIsToggledHostel] = useState(false);

  return (
    <div className="dpsru-every-component-css">
      <h1 className="dpsru-primary-heading-for-every-page">STUDENTS</h1>

      <div className="student-component-container ">
        {/* first container */}
        <div className="students-first-container inner-container">
       
            {/* 11-10-23 */}
            <ul>




             
              <li>
                <a href={AwarenessnoticeforDrugAbuse}>
                Awareness notice for Drug Abuse
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={ReopeningofODDsemfeePortal}>
                Reopening of Odd Semester Fee Portal
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={finallistofboysHostel}>
                Final list of Boys Hostel
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={oddsemfeenotice2424}>
                Odd Semester reopening fee notice 2024-25
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
             
             
              <li>
                <a href={OddSemFeeNotice24}>
                ODD SEMESTER FEE NOTICE 2024
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
             
             
              <li>
                <a href="https://forms.gle/JNWVaEAfy2ArHF1x6">
                Student's I-Card Google Form
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
             
              <li>
                <a href={icardForm24}>
                I-Card Form
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
             
             
              <li>
                <a href={IcardNotice24}>
                I-Card Notice
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
             
              <li>
                <a href={BanSharpManja}>
                Ban Sharp Manjha
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={ReOpeningofEvensemfeeSem}>
                Re-opening of Even Semester Fee Semester
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={openingofevensemfeenotice}>
                Opening of Even Semester fee Notice
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={noduesAppformdpsrunew}>
                No Dues Application Form_DPSRU
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={noDuesAppformdipsarnew}>
                No Dues Application Form_DIPSAR
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={ReOpeningofoddsemfeeportalnotice}>
                Re-Opening of Odd Semester Fee Portal, NOTICE
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={circularawarenessRegardingbanonSharpMana}>
                CIRCULAR, Awareness Regarding Ban on Sharp Manja
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              {/* <li>
                <a href={noduescertificateformdpsru}>
                NO DUES CERTIFICATE Form DPSRU
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li> */}
              {/* <li>
                <a href={noduescertificateformdipsar}>
                NO DUES CERTIFICATE Form DIPSAR
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li> */}
              <li>
                <a href={noticeforprofssagarwaltropyforbptstudents}>
                Notice for PROF. S.S. AGRAWAL TROPHY for BPT students
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={ropeningoffeeportalforoddsem}>
                Reopening of fee portal for odd semester
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={girlshostalAccommodationduringsessionFY2324}>
                Girls hostel Accommodation during session FY 2023-24
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={HostalAccommodationduringsessionfy2324}>
                Hostel Accommodation during session FY 2023-24
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={finalListOfEligibleCandidatesForstudentsCouncil}>
                Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>


              <li>
                <a href={hostelNoticeforfeepayment}>
                Notice for pay hostel and mess fees before 4/11/2023
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>




              <li>
                <a href={oddSemFeeReplaced}>
                Odd semester fee notice
                 {" "} <img src={newGif} alt="new" />
                </a>
              </li>






              <li>
                <a href={newSData01}>
                  Notice Pay Hostel and Mess fees on or before 30/09/2023 at
                  3:00pm{" "} <img src={newGif} alt="new" />
                </a>
              </li>
              <li>
                <a href={newSData02}>
                  Notice Regarding reopening fee portal for Even Semester
                  Students till 15th September 2023
                </a>
              </li>
              <li>
                <a href={newSData03}>
                  Reopening of semester fee with late fine
                </a>{" "}
                
              </li>
              <li>
                <a href={newSData04}>
                  Boys Hostel Fees and Mess Fees Portal Opening
                  
                </a>
              </li>
              <li>
                <a href={newSData05}>
                  Girls Hostel Fees and Mess Fees Portal Opening
                </a>
                 
              </li>
              <li>
                <a href={newSData06}>
                  Application Form for hostel admission (2023-24)
                </a>
              </li>
              <li>
                <a href="https://forms.gle/Th25xYdasT8wvKYK8">
                  DPSRU Hostel Registration form 2023-24{" "}
                </a>
              </li>
              <li>
                <a href={newSData08}>
                  Boys Hostel Notice: Date extended for Hostel and Mess Fees
                  before 03.08.2023 
                </a>
              </li>
              <li>
                <a href={newSData09}>
                  Hostel notice{" "}
                </a>
              </li>
              <li>
                <a href={newSData10}>
                  Extension of Girls Hostel allotment for the students of Hostel
                  and Mess fees portal opening
                </a>
              </li>
              <li>
                <a href={newSData11}>
                  Extension of Boys Hostel fees and Mess fess portal
                </a>
              </li>
              <li>
                <a href={newSData12}>
                  Boys Hostel Fees and Mess Fees Portal Opening
                  
                </a>
              </li>
              <li>
                <a href={newSData13}>
                  Girls Hostel Fees and Mess Fees Portal Opening
                </a>
                 
              </li>
              <li>
                <a href={newSData14}>
                  Examination Fee Notice 2023 
                </a>
              </li>
              <li>
                <a href={newSData15}>
                  Hostel Mess Fee 
                </a>
              </li>
              <li>
                <a href={newSData16}>
                  Fee Notice {" "}
                </a>
              </li>
          

            {/* new data date is 15may 2023 */}
            <li>
            
              <a href={ScholarshipforMinorityDateExtension}>
                Scholarship for Minority ( Date Extension){" "}
              </a>

              </li>
           
             <li>
              <a href={FinancialAssistanceschemeforFWS}>
                Financial Assistance scheme for FWS
              </a>{" "}
             
             </li>
             <li>
              <a href={PhdAdmissions}>Notice for PhD admission </a>

             
             </li>
             <li>
              <a href={NoticeForSamesterFee}>Notice for Semester Fee </a>

             
             </li>
             <li>
              <a href={MinorityScholarship}>
                Notice for Scholarship Schemes for Minority Students{" "}
              </a>

             
             </li>
             <li>
              <a href={MPharmAttendanceNotice}>
                Submission of Monthly Attendance for AICTE PG Scholarship{" "}
              </a>

             
             </li>
             <li>
              <a href={girlshostelfeedetails}>Girls Hostel Fee Details </a>

             
             </li>
             <li>
              <a href={boyshostelfeedetails}>Boys Hostel Fee Details </a>{" "}
             
             </li>
             <li>
              Fee Notice For The Sports Science Department Semester-4&amp;6{" "}
             
             </li>
             <li>
              <a href={RegardingFeeComponent}>Regarding Fee Component</a>{" "}
             </li>
            {/* new data date is 15may 2023 */}
             <li>
              <a href={s1}>Re-opening of Fee Portal</a>
             </li>
             <li>
              <a href={s2}>
                Re- Allotment List of Girls Hostel students upto July 2023
              </a>
             </li>
             <li>
              <a href={s3}>
                Accommodation List of Boys Hotel Session upto July 2023
              </a>
             </li>
             <li>
              <a href={s4}>
                MISC/ Sports Science 3rd semester fee portal till 7/12/2022
              </a>
             </li>
             <li>
              <a href={s5}>Course and Semesterwise Fee Structure</a>
             </li>
             <li>
              <a href={s6}>UFM Punishment Rules 2022</a>
             </li>
             <li>
              <a href={s7}>Hostel Admission Form 2022</a>
             </li>
             <li>
              <a href={s8}>
                Fee Notice for 3rd and 5th Semester Students of Batch 2020 and
                2021
              </a>
             </li>
             <li>
              <a href={s9}>
                Notice for 1st semester B. Pharm. Students regarding
                commencement of session dated 30.09.2022
              </a>
             </li>
             <li>
              <a href={s10}>Application Format for Issuance of Certificates </a>
             </li>
             <li>
              <a href={s11}>
                Notice for 5th and 7th semester B. Pharm Students regarding
                commencement of next session dated 28.09.2022
              </a>
             </li>
             <li>
              <a href={s12}>Applications Invited for Delhi Bravery Awards</a>
             </li>
             <li>
              <a href={s13}>
                DPSRU Celebrates Har Ghar Tiranga events under Azadi ka Amrit
                Mahotsav
              </a>
             </li>
             <li>
              <a href={s14}>DPSRU Celebrates Independence Day 2022</a>
             </li>
             <li>
              <a href="https://forms.gle/BS7BfRBp4rPMuhzp6">
                Interest form for Har Ghar Tiranga Contests – Independence Day
                Celebration{" "}
              </a>
             </li>
             <li>
              <a href={s16}>Unnati Scholarship</a>
             </li>
             <li>
              <a href={s17}>CSS Scholarships on NSP</a>
             </li>
             <li>
              <a href={s18}>AICTE Scholarships on NSP</a>
             </li>
             <li>
              <a href={s19}>
                Girls Hostel Notice: 2nd Re-allotment list of Girls Hostel for
                Session 2022
              </a>
             </li>
             <li>
              <a href={s20}>
                Boys Hostel Notice: 2nd Re-allotment list of Boys Hostel for
                Session 2022
              </a>
             </li>
             <li>
              <a href={s21}>
                Girls Hostel Notice : Submission of fees for cooler usage
              </a>
             </li>
             <li>
              <a href={s22}>
                Girls Hostel Notice: Submission of Hostel Mess Fees
              </a>
             </li>
             <li>
              <a href={s23}>
                Girls Hostel Notice: Re-allotment list of Boys Hostel for
                Session 2022
              </a>
             </li>
             <li>
              <a href={s24}>
                Hostel Notice : Submission of fees for cooler usage
              </a>
             </li>
             <li>
              <a href={s25}>Hostel Notice: Submission of Hostel Mess Fees</a>
             </li>
             <li>
              <a href={s26}>
                Hostel Notice: Re-allotment list of Boys Hostel for Session 2022
              </a>
             </li>
             <li>
              <a href={s27}>Hostel Notice</a>
             </li>
             <li>
              <a href={s28}>Ph.D. Admission Form</a>
             </li>
             <li>
              <a href={s29}>Applications Invited for Ph.D. Admissions</a>
             </li>
             <li>
              <a href={s30}>Third List – Boys Hostel</a>
             </li>
             <li>
              <a href={s31}>Second List – Boys Hostel</a>
             </li>
             <li>
              <a href={s32}>Second List – Girls Hostel</a>
             </li>
             <li>
              <a href={s33}>Hostel List – Boys</a>
             </li>
             <li>
              <a href={s34}>Hostel List – Girls</a>
             </li>
             <li>
              <a href={s35}>Application form for Hostel admission (2021-22)</a>
             </li>
             <li>
              <a href={s36}>Notice regarding Hostel admission 2021-22</a>
             </li>
             <li>
              <a href="https://forms.gle/xPdWBfjmstdcm5Y5A">
                Online link for submission of application form
              </a>
             </li>
             <li>
              <a href={s38}>NSS Registration Drive for DPSRU Students</a>
             </li>
             <li>
              <a href={s39}>
                Call for applications for ‘On the Spot Essay Writing
                Competition-2022’ for Post-Graduate Students of Statistics
              </a>
             </li>
             <li>
              <a href={s40}>
                National level quiz competition to commemorate the 75 years of
                progressive India
              </a>
             </li>
             <li>
              <a href={s41}>
                Applications Invited: 2022 Commonwealth Master’s Scholarship in
                the United Kingdom
              </a>
             </li>
             <li>
              <a href={s42}>
                Regarding Vacating the Hostel Rooms – Notice List
              </a>
             </li>

             </ul>
         
        </div>

        {/* second container */}
        <div className="student-section-second-container inner-container">
          <ul className="inner-first-ul-of-student">
            <li>
              <a
                href="https://www.antiragging.in/affidavit_university_form.php"
                style={{
                  textDecoration: "none",
                  fontSize: "1rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Online Anti-Ragging Affidavit for all Students (Compulsory){" "}
              </a>
            </li>
            <li>
              {" "}
              <h3 className="sub-heading-of-admissions">
                Yearwise list of Academic Toppers
              </h3>
              <ul className="second-ul-of-students-component">
                <li>
                  <a href={a2021}>2020-21</a>
                </li>
                <li>
                  <a href={a1920}>2019-20</a>
                </li>
                <li>
                  <a href={a1819}>2018-19</a>
                </li>
                <li>
                  <a href={a1718}>2017-18</a>
                </li>
                <li>
                  <a href={a1617}>2016-17</a>
                </li>
                <li>
                  <a href={a1516}>2015-16</a>
                </li>
              </ul>
            </li>
            <li>
              <a title="" href={FeeNotice}>
                Fee Notice
              </a>
            </li>
            <li>
              <Link to="/previousQuestionPapers">Previous Question Papers</Link>
            </li>
            <li>
              <Link to="/DisciplineRagging">
                Discipline Action against Ragging
              </Link>
            </li>
            <li>
              <button
                className="strikingOfRolls-button"
                onClick={() => setIsToggledsor(!isToggledsor)}
              >
                {" "}
                Striking off Rolls <FaSortDown />
              </button>
              {isToggledsor ? (
                <div className="strikingOffRolls-component">
                  <h3 className="strikingOfRolls-heading">
                    The name of the student may be struck off the rolls on
                    account of:{" "}
                  </h3>
                   <li>
                    (i) His own written request, endorsed by his
                    parent/guardian; <br />
                    (ii) Continued absence without obtaining leave for FIVE
                    consecutive working days; <br />
                    (iii) Irregular attendance and poor progress in studies;{" "}
                    <br />
                    (iv) If ordered to be EXPELLED or RUSTICATED from the
                    university;
                    <br />
                    (v) Non-payment of tuition fee and other dues for 30 days{" "}
                   </li>
                </div>
              ) : null}
            </li>
            <li>
              <button
                className="strikingOfRolls-button"
                onClick={() => setIsToggledpcbd(!isToggledpcbd)}
              >
                {" "}
                Prevention of Caste Based Discrimination <FaSortDown />
              </button>
              {isToggledpcbd ? (
                <div className="pcbd-component strikingOffRolls-component">
                  <h2 className="pcbd-heading">
                    Prevention of Caste Based Discrimination
                  </h2>

                   <li>
                    Any kind of discrimination on the basis of caste, creed,
                    religion, language, ethnicity, <br />
                    gender and disability is strictly prohibited in DPSRU. DPSRU
                    will be very sensitive <br />
                    while dealing with incidents of caste discrimination and a
                    strict action will be <br />
                    taken against such cases. The Officials/faculty members
                    should also desist from
                    <br /> any act of discrimination against SC/ST students on
                    grounds of their social origin.
                    <br /> DPSRU also ensures that no official/ faculty member
                    indulge in any kind of <br />
                    discrimination against any community or category of
                    students. DPSRU will take
                    <br /> strict action against the erring official/ faculty
                    member, if any such incident comes to the <br />
                    notice of the authorities. <br />
                    <br />
                    DPSRU students can lodge complaints on this page of caste
                    discrimination and also <br />
                    DPSRU has placed a complaint register in the Registrar/
                    Office for the same. Action
                    <br /> Taken Report will also be sent to University Grants
                    Commission and if any case of caste <br />
                    discrimination is reported. Link for the “Prevention of
                    Caste Based Discrimination” <br />
                    for DPSRU Students
                   </li>


                </div>
              ) : null}
            </li>
            <li>
              <a href={aicte}>For Differently Abled Students</a>
            </li>

            
            {/* <li>
              <button
                className="strikingOfRolls-button"
                onClick={() => setIsToggledHostel(!isToggledHostel)}
              >
                {" "}
                Hostel <FaSortDown />
              </button>

              {isToggledHostel ? (
                <div className="pcbd-component strikingOffRolls-component">
                  <h2 className="pcbd-heading">Hostel</h2>

                  // Hostel 
                  <div class="elementor-text-editor elementor-clearfix">
                     <li>
                      <br />
                     </li>
                     <li>
                      <a href={h1} rel="noopener">
                        Hostel Circular
                      </a>
                     </li>
                     <li>
                      <a href={h2}>Girls Hostel Due List 2020-21</a>
                     </li>
                     <li>
                      <a href={h3}>Boys Hostel Due List 2020-21</a>
                     </li>
                     <li>
                      <a href={h4} rel="noopener">
                        Medical Certificate
                      </a>
                     </li>
                     <li>
                      <a href={h5} rel="noopener">
                        Leave Application
                      </a>
                     </li>
                     <li>
                      <a href={h6} rel="noopener">
                        Hostel Rules
                      </a>
                     </li>
                     <li>
                      <a href={h7} rel="noopener">
                        Hostel ID Card
                      </a>
                     </li>
                     <li>
                      <a href={h8} rel="noopener">
                        Hostel Admission Form
                      </a>
                     </li>
                     <li>
                      <a href={h9} rel="noopener">
                        Gate pass
                      </a>
                     </li>
                     <li>
                      <a href={h10} rel="noopener">
                        Declaration by the Students
                      </a>
                     </li>
                     <li>
                      <a href={h11} rel="noopener">
                        ANTI-RAGGING AFFIDAVIT
                      </a>
                     </li>{" "}
                  </div>
                 // Hostel 
                </div>
              ) : null}
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Students;
