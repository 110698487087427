import React from "react";

import mbaPm from './Datesheet2022 Data/Date-Sheet-of-MBAMPHMHM-MHA-2022.pdf'
import mscAipm from './Datesheet2022 Data/Date-Sheet-of-M.Sc-AIPM-2022.pdf'
import bPharmaAyur from './Datesheet2022 Data/Date-Sheet-of-B.Pharmacy-Ayurveda-2022.pdf'
import mPharm from './Datesheet2022 Data/M.Pharm-2022-1.pdf'
import dPharm from './Datesheet2022 Data/Date-Sheet-of-D.Pharm-2022.pdf'
import bscHons from './Datesheet2022 Data/All-Date-Sheet-of-B.-Sc.-Hons.-2022.pdf'
import bpt from './Datesheet2022 Data/All-Date-Sheet-of-BPT-2022.pdf'
import bPharmDatesheet from './Datesheet2022 Data/B.PHARM-DATESHEET-2022-1.pdf'
import DatesheetofDMYS2022 from './Datesheet2022 Data/Date-Sheet-of-DMYS-2022.pdf'
import MscSportScience from './Datesheet2022 Data/Date-Sheet-of-M.-Sc.-Sports-Science-2022.pdf'
import MPTsportsMedicine from './Datesheet2022 Data/Date-Sheet-of-MPT-Sports-Medicine-2022.pdf'

const DateSheet2022 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Date Sheet - 2022</h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={mbaPm}>
            MBA-PM
            </a>
          </li>
          <li>
            <a href={mbaPm}>
            MHM
            </a>
          </li>
          <li>
            <a href={mbaPm}>
            MPH
            </a>
          </li>
          <li>
            <a href={mscAipm}>
            M.Sc. AIPM
            </a>
          </li>
          <li>
            <a href="">
            MBA-PET
            </a>
          </li>
          <li>
            <a href={bPharmaAyur}>
            B. Pharm Ayurveda
            </a>
          </li>
          <li>
            <a href={mbaPm}>
            MHA
            </a>
          </li>
          <li>
            <a href={mPharm}>
            M. Pharm
            </a>
          </li>
          <li>
            <a href={dPharm}>
            D. Pharm
            </a>
          </li>
          <li>
            <a href="">
            BMLT
            </a>
          </li>
          <li>
            <a href="">
            DMLT
            </a>
          </li>
          <li>
            <a href={bscHons}>
            B. Sc. (Hons.) Sports Science
            </a>
          </li>
          <li>
            <a href={bpt}>
            BPT
            </a>
          </li>
          <li>
            <a href="/">
            BBA-CH
            </a>
          </li>
          <li>
            <a href={bPharmDatesheet}>
            B. Pharm 
            </a>
          </li>
          <li>
            <a href={DatesheetofDMYS2022}>
            DMYS
            </a>
          </li>
          <li>
            <a href={MscSportScience}>
            M.Sc. Sport Science
            </a>
          </li>
          <li>
            <a href={MPTsportsMedicine}>
            MPT Sports Medicine 
            </a>
          </li>
          <li>
            <a href="/">
            WCSC
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DateSheet2022;
