import React from "react";

const OpenAccessResources = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Open Access Resources
      </h2>

      <h2 className="dpsru-secondary-heading-for-every-page">E- Books</h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://doabooks.org/">
            Directory of Open Access Books (DOAB)
          </a>
        </li>
        <li>
          <a href="https://books.google.ca/">Google Books</a>
        </li>
        <li>
          <a href="https://www.hathitrust.org/">Hathitrust</a>
        </li>
        <li>
          <a href="https://ndl.iitkgp.ac.in/">
            National Digital Library of India
          </a>
        </li>
        <li>
          <a href="https://www.gutenberg.org/">Project Gutenberg</a>
        </li>
        <li>
          <a href="https://www.oapen.org/home">Open Library</a>
        </li>
        <li>
          <a href="http://www.freefictionbooks.org/">
            FreeFictionBooks.org(E-Books)
          </a>
        </li>
        <li>
          <a href="http://www.oapen.org/home">OAPEN (E-Books)</a>
        </li>
        <li>
          <a href="http://docstore.mik.ua/orelly">O’Reilly’s CD Bookshelf</a>
        </li>
        <li>
          <a href="http://www.gutenberg.org/">Project Gutenberg (E-Books)</a>\
        </li>
        <li>
          <a href="https://www.ucl.ac.uk/ucl-press/browse-books">
            UCL Press (E-Books)
          </a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">E- Theses</h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="http://adt.caul.edu.au/">
            Australasia Digital Thesis Program
          </a>
        </li>
        <li>
          <a href="http://thesis.library.caltech.edu/">
            Caltech THESIS:&nbsp; A Caltech Library Service
          </a>
        </li>
        <li>
          <a href="http://www.dart-europe.eu/">DART-Europe E-theses Portal</a>
        </li>
        <li>
          <a href="https://macsphere.mcmaster.ca/">
            Digital Commons @ McMaster
          </a>
        </li>
        <li>
          <a href="http://shodhganga.inflibnet.ac.in/">Shodhganga</a>
        </li>
        <li>
          <a href="http://eprints.uni-mysore.ac.in/4778/">
            &nbsp;Vidyanidhi: Indian digital library of electronic theses
          </a>
        </li>
        <li>
          <a href="http://pqdtopen.proquest.com/">
            Dissertation and Theses (Proquest)
          </a>
        </li>
        <li>
          <a href="http://www.opendoar.org/search.php">
            Directory of Open Access Repositories (DOAR)
          </a>
        </li>
        <li>
          <a href="https://archive.org/">
            Internet Archive (E-books, movies, software, music, websites, and
            more)
          </a>
        </li>
        <li>
          <a href="https://ndl.iitkgp.ac.in/">National Digital Library (NDL)</a>
        </li>
        <li>
          <a href="http://nsdl.org/">National Science Digital Library</a>
        </li>
        <li>
          <a href="https://www.wdl.org/en">World Digital Library</a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Multimedia Resources
      </h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="http://www.nptel.iitm.ac.in/index.php">
            National Programme on Technology Enhanced Learning
          </a>
        </li>
        <li>
          <a href="http://www.ocwconsortium.org/">
            Open Course Ware Consortium
          </a>
        </li>
        <li>
          <a href="http://www.open.ac.uk/openlearn/">OpenLearn</a>
        </li>
        <li>
          <a href="https://open.umich.edu/">Open.Michigan</a>
        </li>
        <li>
          <a href="http://oyc.yale.edu/">Open Yel Courses</a>
        </li>
        <li>
          <a href="http://www.world-lecture-project.org/index.php?navId=1">
            World Lecture Project
          </a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Citation Tools (free version)
      </h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://www.mendeley.com/guides">Mendeley</a>
        </li>
        <li>
          <a href="https://www.zotero.org/support/quick_start_guide">Zotero</a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Patent Databases
      </h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://patents.google.com/">Google Patents</a>
        </li>
        <li>
          <a href="https://www.lens.org/">Lens</a>
        </li>
        <li>
          <a href="https://worldwide.espacenet.com/">Espacenet</a>
        </li>
        <li>
          <a href="https://www.wipo.int/patentscope/en">Patentscope</a>
        </li>
        <li>
          <a href="https://patft.uspto.gov/netahtml/PTO/index.html">USPTO</a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">E-Journals</h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://niscpr.res.in/periodicals/researchjournals">
            CSIR-NIScPR Journals
          </a>
        </li>
        <li>
          <a href="http://www.doaj.org/">
            Directory of Open Access Journal (DOAJ)
          </a>
        </li>
        <li>
          <a href="https://www.ias.ac.in/Journals/Overview">
            Indian Academy of Sciences (IAS) Journals
          </a>
        </li>
        <li>
          <a href="http://arxiv.org/">ArXiv</a>
        </li>
        <li>
          <a href="http://cshprotocols.cshlp.org/site/misc/sample.xhtml">
            Cold Spring Harbor&nbsp;<i>Protocols</i>
          </a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Research Data Repositories
      </h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="http://www.re3data.org/">re3data</a>
        </li>
        <li>
          <a href="http://journals.plos.org/plosone/s/data-availability#loc-recommended-repositories">
            PLOS Recommended
          </a>
          &nbsp;
        </li>
        <li>
          <a href="https://www.nature.com/sdata/policies/repositories#close">
            Scientific Data
          </a>
        </li>
        <li>
          <a href="http://oad.simmons.edu/oadwiki/Data_repositories">
            Simmons Data
          </a>
        </li>
        <li>
          <a href="https://www.lib.umn.edu/datamanagement/datacenters">
            University of Minnesota Data
          </a>
        </li>
      </ul>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Online Newspapers (free version)
      </h2>
      <h2 className="online-newspaper-heading-secondary">English</h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="http://epaper.business-standard.com/bsepaper/login.php">
            Business Standard
          </a>
        </li>
        <li>
          <a href="http://epaper.deccanchronicle.com/">Deccan Chronicle</a>
        </li>
        <li>
          <a href="http://epaper.dnaindia.com/">DNA</a>
        </li>
        <li>
          <a href="http://epaper.hindustantimes.com/">Hindustan Times</a>
        </li>
        <li>
          <a href="https://epaper.mailtoday.in/">Mail Today</a>
        </li>
        <li>
          <a href="http://epaper.livemint.com/epaper/viewer.aspx">Mint</a>
        </li>
        <li>
          <a href="http://onlineepaper.asianage.com/">The Asian Age</a>
        </li>
        <li>
          <a href="http://epaper.financialexpress.com/">
            The Financial Express
          </a>
        </li>
        <li>
          <a href="https://epaper.thehindu.com/">The Hindu</a>
        </li>
        <li>
          <a href="http://www.dailypioneer.com/home/online-channel/pdf-epaper.html">
            The Pioneer
          </a>
        </li>
        <li>
          <a href="http://www.thestatesman.net/">The Statesman</a>
        </li>
        <li>
          <a href="http://www.tribuneindia.com/">The Tribune</a>
        </li>
      </ul>

      <h2 className="online-newspaper-heading-secondary">Hindi</h2>
      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://epaper.amarujala.com/">Amar Ujala</a>
        </li>
        <li>
          <a href="http://epaper.bhaskar.com/">Dainik Bhaskar</a>
        </li>
        <li>
          <a href="http://paper.hindustantimes.com/epaper/viewer.aspx">
            Hindustan
          </a>
        </li>
        <li>
          <a href="http://navbharattimes.indiatimes.com/">Nav Bharat Times</a>
        </li>
      </ul>
    </div>
  );
};

export default OpenAccessResources;
