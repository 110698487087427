import React from "react";

import "./AnnualReport.css";
import "../Staff/Staff.css";
import newgif from "../NEW/new.gif";

// files
import ar01 from './Annual report files/Final-Pharma-Annual-Report_2021-22_10-03-22-for-approval.pdf'
import ar02 from './Annual report files/PHARMANNUAL-2020-21.pdf'
import ar03 from './Annual report files/Pharmannual 2019-20.pdf'
import ar04 from './Annual report files/Pharmannual 2018-19.pdf'
import ar05 from './Annual report files/Pharmannual 2017-18.pdf'
import ar06 from './Annual report files/Pharmannual 2016-17.pdf'
import ar07 from './Annual report files/Pharmannual 2015-16.pdf'

import newAnnualReport2023 from './Annual report files/Pharmannual 2022-2023.pdf'



function AnnualReport() {
  return (
    <div className="dpsru-every-component-css">
      <div className="inner-container">
        <h1 className="dpsru-primary-heading-for-every-page">Annual Reports</h1>{" "}
        <hr />
        <ul>
          <li>
            <a href={newAnnualReport2023}>
              Pharmannual 2022-2023
            </a>
           <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={ar01}>
              Pharmannual 2021-2022
            </a>
          
          </li>
          <li>
            <h2>
              <a href={ar02}>
                Pharmannual 2020-2021
              </a>
            </h2>
          </li>
          <li>
            <h2>
              <a href={ar03}>
                Pharmannual 2019-2020
              </a>
            </h2>
          </li>
          <li>
            <h2>
              <a href={ar04}>
                Pharmannual 2018-2019
              </a>
            </h2>
          </li>
          <li>
            <h2>
              <a href={ar05}>
                Pharmannual 2017-2018
              </a>
            </h2>
          </li>
          <li>
            <h2>
              <a href={ar06}>
                Pharmannual 2016-2017
              </a>
            </h2>
          </li>
          <li>
            <h2>
              <a href={ar07}>
                Pharmannual 2015-2016
              </a>
            </h2>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AnnualReport;
