import React from "react";
import "./PeerTeamVisit.css";

const PeerTeamVisit = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page peer-team-heading">
        Peer Team Visit
      </h2>
      <div className="peerTeamVisit-galary">
        <figure className="gallery-item-peerTeamVisit">
          <a
            data-elementor-open-lightbox="yes"
            data-elementor-lightbox-slideshow="46da418"
            data-elementor-lightbox-title="0J3A1643"
            href="/wp-content/uploads/2023/07/0J3A1643-scaled.jpg"
          >
            <img
              src="/wp-content/uploads/2023/07/0J3A1643-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1723-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1723-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1700-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1700-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1698-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1698-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1725-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1725-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1735-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1735-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1731-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1731-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
          {/* seventh */}
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1742-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1742-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
          {/* eight */}
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1749-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1749-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
          {/* ninth */}
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1959-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1959-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
          {/* tenth */}
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1989-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1989-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
          {/* eleven */}
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1849-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1849-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1824-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1824-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1817-1-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1817-1-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A1817-1-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A1817-1-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1533-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1533-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1484-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1484-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1469-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1469-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1446-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1446-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1356-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1356-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1333-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1333-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1461-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1461-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2602-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2602-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2667-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2667-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1881-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1881-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2265-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2265-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

{/* TWENTY SEVENT */}
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2136-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2136-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

{/* TWENTY Eight */}
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2039-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2039-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

{/* TWENTY Nine */}
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2718-min-1-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2718-min-1-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

{/* Thirty */}
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2770-min-1-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2770-min-1-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

{/* thirtyone */}
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2786-min-1-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2786-min-1-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2798-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2798-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2830-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2830-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2881-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2881-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A2913-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A2913-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3002-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3002-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3013-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3013-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3035-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3035-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3052-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3052-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3122-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3122-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3178-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3178-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1325-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1325-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1333-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1333-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1356-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1356-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1446-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1446-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1461-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1461-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1469-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1469-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1484-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1484-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/IMG_1500-min-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/IMG_1500-min-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3338-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3338-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3321-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3321-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3329-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3329-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>
        
        <figure className="gallery-item-peerTeamVisit">
          <a href="/wp-content/uploads/2023/07/0J3A3362-scaled.jpg">
            <img
              src="/wp-content/uploads/2023/07/0J3A3362-scaled.jpg"
              alt="Peer Team Visit"
            />
          </a>
        </figure>

        {/* <a href="/wp-content/uploads/2023/07/1.1.1.pdf"> */}
      </div>
    </div>
  );
};

export default PeerTeamVisit;
