import React, { useState } from "react";

import profimg from "./IMG_2071-scaled-e1689059370335.jpg";

const DrDeeptiPandita = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Deepti Pandita</h2>
      <h3 className="facult-designation-for-each-container">
        Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
              deeptipandita [at] yahoo [dot] co [dot] uk
            
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=RbEPdKsAAAAJ&hl=en">
                Google Scholar
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.scopus.com/home.uri">Scopus</a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-3644-7045">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        B.Pharm, M. Pharm, PhD, Postdoc (Portugal), MBA
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Nanomedicine &Nanotheranostics, Bioconjugates, Stem Cell Research for
        Tissue Engineering, Drug Discovery & Repurposing, 3D Printing for
        Personalized & Precision Medicine, Targeted Phytochemicals Delivery
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>Sr.</b>
                  </p>
                  <p>
                    <b>No.</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Title of the project</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>PI/Co-PI</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Name of the</b>
                  </p>
                  <p>
                    <b>funding agency</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Amount</b>
                  </p>
                  <p>
                    <b>Sanctioned (Rs.)</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Ongoing / completed</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 1. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Design and development of nanoparticulate systems for
                    delivery of anti-cancer agents{" "}
                  </p>
                  <p> [Ref: 8023/RID/RPS-62/Policy-III/2011-12] </p>
                </td>
                <td>
                  <p> PI </p>
                </td>
                <td>
                  <p> AICTE </p>
                </td>
                <td>
                  <p> 15.8 lacs </p>
                </td>
                <td>
                  <p> Completed </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 2. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Design and development of nanopharmaceuticals for delivery
                    of anti-cancer agents{" "}
                  </p>
                  <p> [Ref: SR/FT/LS-145/2011] </p>
                </td>
                <td>
                  <p> PI </p>
                </td>
                <td>
                  <p> SERB, DST </p>
                </td>
                <td>
                  <p> 17.14 lacs </p>
                </td>
                <td>
                  <p> Completed </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              {" "}
              Poonia N, Kaur Narang J, Lather V, Beg S, Sharma T, Singh B,{" "}
              <b>Pandita D</b> . Resveratrol loaded functionalized
              nanostructured lipid carriers for breast cancer targeting:
              Systematic development, characterization and pharmacokinetic
              evaluation. <b>Colloids and Surfaces B: Biointerfaces, </b> 2019,
              181, 756-766.{" "}
            </li>
            <li>
              {" "}
              Girdhar S, Girdhar A, Lather V, <b>Pandita D. </b> Aegle marmelos
              leaf extract ameliorates the cognitive impairment and oxidative
              stress induced by intracerebroventricular streptozotocin in male
              rats. <b>Life Sciences, </b> 2019, 221, 196–203.{" "}
            </li>
            <li>
              {" "}
              Chauhan A., Grewal A. S., <b>Pandita D</b> ., Lather V. Novel
              Cinnamic Acid Derivatives as Potential PPARδ Agonists for
              Metabolic Syndrome: Design, Synthesis, Evaluation and Docking
              Studies. <b>Current Drug Discovery Technologies, </b> 2019,
              Accepted.{" "}
            </li>
            <li>
              {" "}
              Poonia N., Lather V., <b>Pandita D., </b> Mesoporous silica
              nanoparticles: a smart nanosystem for management of breast cancer.{" "}
              <b>Drug Discovery Today, </b> 2018, 23(2), 315-332.{" "}
            </li>
            <li>
              {" "}
              Girdhar S, Girdhar A, Lather V, <b>Pandita D. </b> Biochanin A: A
              phytoestrogen with therapeutic potential.{" "}
              <b>Trends in Food Science &amp; Technology</b> , 2018, 79, 55-66.{" "}
            </li>
            <li>
              {" "}
              Singh H, Singla YP, Narang RS, Pandita D, Singh S, Narang JK,
              Frovatriptan loaded hydroxy propyl methyl cellulose/treated
              chitosan based T composite fast dissolving sublingual films for
              management of migraine. Journal of Drug Delivery Science and
              Technology, 2018, 47, 230-9.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Editorial- Global Regulatory Overview of
              Nanopharmaceuticals and Nanocosmetics, Part-I Applied Clinical
              Research, Clinical Trials and Regulatory Affairs, 2018, Vol. 5,
              Issue 2{" "}
            </li>
            <li>
              {" "}
              Pandita D., Editorial- Global Regulatory Overview of
              Nanopharmaceuticals and Nanocosmetics, Part-II Applied Clinical
              Research, Clinical Trials and Regulatory Affairs, 2018, Vol. 5,
              Issue 3{" "}
            </li>
            <li>
              {" "}
              Charaya N., Pandita D., Grewal A.S., Lather V., Design, synthesis
              and biological evaluation of novel thiazol-2-yl benzamide
              derivatives as glucokinase activators Computational Biology and
              Chemistry, 2018, 73, 221-229.{" "}
            </li>
            <li>
              {" "}
              Sharma H., Lather V., Grewal A.S., Pandita D., Synthesis,
              Anti-inflammatory Activity and Docking Studies of Some Newer 1,3-
              Thiazolidine-2,4-dione Derivatives as Dual Inhibitors of PDE4 and
              PDE7 Current Computer Aided Drug Design, 2018, Oct 3.{" "}
            </li>
            <li>
              {" "}
              Kumar A., Grewal A.S., Singh V., Narang R., Pandita D., Lather V.,
              Synthesis, Antimicrobial Activity and QSAR Studies of Some New
              Sparfloxacin Derivatives Pharmaceutical Chemistry Journal, 2018,
              52, 444–454.{" "}
            </li>
            <li>
              {" "}
              Grewal A.S., Lather V., Sharma N., Singh S., Narang R. S., Narang
              J.K., Pandita D. Recent Updates on Nanomedicine Based Products:
              Current Scenario and Future Opportunities Applied Clinical
              Research, Clinical Trials and Regulatory Affairs, 2018, 5,
              132-144.{" "}
            </li>
            <li>
              {" "}
              Narang J.K., Narang R.S., Pandita D., Lather V., Dogra A.
              Nano-Oncologicals: Regulatory Aspects and Safety Issues Applied
              Clinical Research, Clinical Trials and Regulatory Affairs, 2018,
              5, 122 – 131.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Sharma K., Singh S., Singh V., Pandita D., Lather V.
              Design, Synthesis and Antidiabetic Activity of Novel Sulfamoyl
              Benzamide Derivatives as Glucokinase Activators Journal of
              Pharmaceutical Technology Research and Management, 2018, 6, 115-
              124.{" "}
            </li>
            <li>
              {" "}
              Singh P., Grewal A.S., Pandita D., Lather V. Synthesis and
              evaluation of a series of caffeic acid derivatives as anticancer
              agents. Future Journal of Pharmaceutical Sciences, 2017, accepted.{" "}
            </li>
            <li>
              {" "}
              Lather V., Saini V., Pandita D.,Polymeric micelles of modified
              chitosan block copolymer as nanocarrier for delivery of paclitaxel
              Current Nanomedicine, 2017, accepted.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Kumari N., Lather V., A selfnanoemulsifying drug
              delivery system for poorly water soluble tolbutamide: development,
              optimization and pharmacodynamic studies Pharmaceutical
              Nanotechnology, 2017, accepted.{" "}
            </li>
            <li>
              {" "}
              Hooda R., Pandita D., Kumari P., Lather V.; Regulatory and quality
              aspects of herbal drugs Applied Clinical Research, Clinical Trials
              and Regulatory Affairs, 2017, 4(2), 107-113{" "}
            </li>
            <li>
              {" "}
              Girdhar S., Girdhar A., Lather V. , Pandita D.; Novel therapeutic
              targets for Human African Trypanosomiasis Current Treatment
              Options in Infectious Diseases, 2017, 9(2), 200-209.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Lather V., Pandita D., Bhayana G., Synthesis,
              docking and biological evaluation of phenylacetic acid and
              trifluoromethylphenyl substituted benzamide derivatives as
              potential PPARδ agonists Letters in Drug Design &amp; Discovery,
              2017, 14(11), 1239-1251.{" "}
            </li>
            <li>
              {" "}
              Grewal A.S., Lather V., Pandita D., Dalal R., Anti-Inflammatory
              &amp; Anti- Scopus, – 1875- Coauthor 2 . Synthesis, Docking and
              Anti-inflammatory Activity of Triazole Amine Derivatives as
              Potential Phosphodiesterase-4 Inhibitors Allergy Agents in
              Medicinal Chemistry, 2017, accepted.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Sharma S.K, Pandita D., Lather V., Synthesis,
              Docking and Evaluation of Novel Pyrazole Carboxamide Derivatives
              as Multifunctional Anti-Alzheimer’s Agents Journal of Medicinal
              Chemistry and Toxicology, 2017.{" "}
            </li>
            <li>
              {" "}
              Kumari P., Pandita D., Poonia N., Lather V.; Nanomaterials in
              Cosmetics: Regulatory, Quality and Safety Assessment Applied
              Clinical Research, Clinical Trials and Regulatory Affairs, 2017,
              4(2), 99-106.{" "}
            </li>
            <li>
              {" "}
              Singh R., Lather V., Pandita D., Judge V., Arumugam K. N., Grewal
              A. S.; Synthesis, docking and antidiabetic activity of some newer
              benzamide derivatives as glucokinase activators Letters in Drug
              Design &amp; Discovery, 13, 2017, 14, 5, 540-553.{" "}
            </li>
            <li>
              {" "}
              Madaan K., Lather V., Pandita D.; Evaluation of polyamidoamine
              dendrimers as potential carriers for Quercetin, a versatile
              flavonoid Drug Delivery, 2016 Jan, 23(1), 254-62.{" "}
            </li>
            <li>
              {" "}
              Poonia N., Kharb R., Lather V., Pandita D.; Nanostructured lipid
              carriers: versatile oral delivery vehicle Future Science OA, 2016,
              2(3): FSO135.{" "}
            </li>
            <li>
              {" "}
              Lather V., Sharma D., Pandita D.; Proniosomal gel mediated
              transdermal delivery of bromocriptine: in vitro and ex vivo
              evaluation Journal of Experimental Nanoscience, 2016, 11(13),
              1044-1057.{" "}
            </li>
            <li>
              {" "}
              Girdhar S., Pandita D., Girdhar A., Lather V.; Safety, quality and
              regulatory aspects of nutraceuticals. Applied Clinical Research,.
              Clinical Trials and Regulatory Affairs, 2017, 4(1), 36 – 42.{" "}
            </li>
            <li>
              {" "}
              Kumar S., Lather V., Pandita D.; A facile green approach to
              prepare core-shell hybrid PLGA nanoparticles for resveratrol
              delivery International Journal of Biological Macromolecules, 2016,
              84, 380-384.{" "}
            </li>
            <li>
              {" "}
              Kumar S., Lather V., Pandita D.; Stability indicating simplified
              HPLC method for simultaneous analysis of resveratrol and quercetin
              in nanoparticles and human plasma Food Chemistry, 2016, 197,
              959-964.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Pandita D., Bhardwaj S., Lather V., Recent updates
              on development of drug molecules for human African trypanosomiasis
              Current Topics in Medicinal Chemistry, 2016, 16(20), 2245-2265.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Beniwal M., Pandita D., Sekhon B. S., Lather V.;
              Recent Updates on Peroxisome Proliferator-Activated Receptor δ
              Agonists for the Treatment of Metabolic Syndrome Medicinal
              Chemistry, 2016, 12(1),3-21.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Lather V., Bhardwaj S., Pandita D.; Regulatory
              overview of biosimilars: current scenario and future opportunities
              Applied Clinical Research, Clinical Trials and Regulatory Affairs,
              2015, 2(3), 158-164.{" "}
            </li>
            <li>
              {" "}
              Girdhar S., Girdhar A., Verma S. K., Lather V., Pandita D.; Plant
              derived alkaloids in major neurodegenerative diseases: from animal
              models to clinical trials Journal of Ayurvedic and Herbal
              Medicine, 2015, 1(3), 91-100.{" "}
            </li>
            <li>
              {" "}
              Pareek R., Verma S., Lather V., Pandita D.; Co-polymerized gellan
              gum: a carrier for controlled drug delivery Drug Delivery Letters;
              2015, 5(3), 151-162.{" "}
            </li>
            <li>
              {" "}
              Kumari S., Pandita D., Poonia N., Lather V.; Nanostructured lipid
              carriers for topical delivery of an anti-acne drug:
              characterization and ex vivo evaluation Pharmaceutical
              Nanotechnology, 2015, 3(2), 122-133.{" "}
            </li>
            <li>
              {" "}
              Kumar S., Sangwan P., Lather V., Pandita D.; Biocompatible
              PLGA-oil hybrid nanoparticles for high loading and controlled
              delivery of resveratrol Journal of Drug Delivery Science and
              Technology, 2015, 30, 54-62.{" "}
            </li>
            <li>
              {" "}
              Grewal A. S., Bhardwaj S., Pandita D., Lather V., Sekhon B. S.;
              Updates on Aldose Reductase Inhibitors for Management of Diabetic
              Complications and Non-diabetic Diseases Mini-Reviews in Medicinal
              Chemistry, 2015, 16(2), 120- 162.{" "}
            </li>
            <li>
              {" "}
              Kumar S., Lather V., Pandita D.; Green synthesis of therapeutic
              nanoparticles: an expanding horizon Nanomedicine, 2015, 10(15),
              2451-2471.{" "}
            </li>
            <li>
              {" "}
              Chopra M., Rathore N. S., Kumar S., Kumer B., Pandita D., Manuja
              A.; World Journal of Pharmacy and Pharmaceutical Sciences World
              Journal of Pharmacy and Pharmaceutical Sciences, 2015, 8, 666-673.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Kumar S., Lather V.; Hybrid poly(lactic-co-glycolic
              acid) nanoparticles: design and delivery prospective Drug
              Discovery Today, 2015, 20 (1) 95-104.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Kumar S., Poonia N., Lather V.; Solid lipid
              nanoparticles enhance oral bioavailability of resveratrol, a
              natural polyphenol Food Research International, 2014, 62,
              1165-1174.{" "}
            </li>
            <li>
              {" "}
              Madaan K., Kumar S., Poonia N., Lather V., Pandita D.; Dendrimers
              in drug delivery and targeting: Drug-dendrimer interactions and
              toxicity issues Journal of Pharmacy And Bioallied Sciences, 2014,
              6 (3) 139-150.{" "}
            </li>
            <li>
              {" "}
              3 1 Lal R., Marwaha R. K., Pandita D., Dureja H.; Formulation and
              Optimization of 5- Fluorouracil Loaded Chitosan Nanoparticles
              Employing Central Composite Design. Drug Delivery Letters, 2012,
              2(4), 281-289.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Santos J.L., Rodrigues J., Pêgo A.P., Granja P.L.,
              Balian G., Tomás H.; Gene delivery into Mesenchymal Stem Cells: A
              biomimetic approach using RGD nanoclusters based on
              poly(amidoamine) dendrimers Biomacromolecules, 2011, 12 (2),
              472-481.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Ahuja A., Lather V., Benjamin B., Dutta T., Velpandian
              T., Khar R.K Development of lipid-based nanoparticles for
              enhancing the oral bioavailability of paclitaxel AAPS
              PharmSciTech, 2011, 12(2), 712-722.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Ahuja A., Velpandian T., Lather V., Dutta T., Khar
              R.K.; Development, characterization and in vitro assessment of
              stearylamine-based lipid nanoparticles of paclitaxel Die
              Pharmazie, 2011, 66, 1-7.{" "}
            </li>
            <li>
              {" "}
              Santos J.L., Pandita D., Rodrigues J, Pêgo AP, Granja PL, Tomás H.
              Non-viral gene delivery to mesenchymal stem cells: Methods,
              strategies and application in bone tissue engineering and
              regeneration Current Gene Therapy, 2011, 11(1), 46-57.{" "}
            </li>
            <li>
              {" "}
              Santos J.L., Pandita D., Rodrigues J., Pêgo A.P., Granja P.L.,
              Balian G., Tomás H.; Receptor-Mediated Gene Delivery using PAMAM
              Dendrimers Conjugated with Peptides Recognized by Mesenchymal Stem
              Cells Molecular Pharmaceutics, 2010, 7(3), 763-774.{" "}
            </li>
            <li>
              {" "}
              Santos J.L., Oliveira H., Pandita D., Rodrigues J., Pêgo A.P.,
              Granja P.L., Tomás H.; Functionalization of Poly(amidoamine)
              Dendrimers with Hydrophobic Chains for Improved Gene Delivery in
              Mesenchymal Stem Cells Journal of Controlled Release, 2010, 144
              (1), 55-64.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Ahuja A., Velpandian T., Lather V., Dutta T., Khar
              R.K.; Characterization and in vitro assessment of paclitaxel
              loaded lipid nanoparticles formulated using modified solvent
              injection technique Die Pharmazie, 2009, 64(5), 301-310.{" "}
            </li>
            <li>
              {" "}
              Dutta T., Garg M., Dubey V., Mishra D., Singh K., Pandita D.,
              Singh A.K., Ravi A. K., Velpandian T., Jain N.K.; Toxicological
              investigation of surface engineered fifth generation poly
              (propyleneimine) dendrimers in vivo Nanotoxicology, 2, 62-70,
              2008.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Alka Ahuja, T. Velpandian, Roop K. Khar.; Development
              and evaluation of Paclitaxel loaded solid lipid nanospheres (SLN)
              Indian Journal of Pharmacology, 2006, 38 (1) 87-88.{" "}
            </li>
            <li>
              {" "}
              Roop K. Khar, Pandita D.; Biopharmaceutical classification system
              and its importance The Indian Pharmacist, 2005, 4 (41) 25-30 .{" "}
            </li>
            <li>
              {" "}
              Pandita D., Dureja H., Kumar V. Lather V., An introduction to
              Combinatorial Chemistry- A rapid approach to lead development.
              pharmainfo.net,30/03/2005 .{" "}
            </li>
            <li>
              {" "}
              Pandita D., Shirwaikar A.; An update on the Global Programme for
              Vaccine and Immunization Indian Journal of Pharmaceutical
              Sciences, 2004, 66 (1), 1-10.{" "}
            </li>
            <li>
              {" "}
              Gupta S. K., Madaan R., Pandita D., Khan S. A.; Phytochemical and
              Pharmacological Investigations on Cassia fistula Linn. Journal of
              Science and Pharmacy, 2003, 4 (2) 57-60.{" "}
            </li>
            <li>
              <b>Book publications</b>
            </li>
            <li>
              {" "}
              Lather V., Poonia N., Pandita D., Mesoporous silica nanoparticles-
              A multifunctional nanocarrier for therapeutic applications
              Multifunctional Nanocarriers for Contemporary Healthcare
              Applications, IGI Global, 2017 .{" "}
            </li>
            <li>
              {" "}
              Pandita D., Munjal A., Godara S., Lather V., Nanocarriers in drug
              and gene delivery Advances in Animal Biotechnology and its
              Applications, Springer Publishers, 2017.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Kumar S., Lather V., Dureja H., Nanoparticles in
              Anticancer Drug Delivery Encyclopedia of Nanoscience and
              Nanotechnology (25-Volume set), H. S. Nalwa, American Scientific
              Publishers, 2016, U.S.A.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Poonia N., Lather V., Mesoporous Silica Nanoparticles
              in Drug Delivery and Targeting NanoBioMedicine Vol. 1
              NanoMedicine, Bhupinder Singh, Studium Press LLC, U.S.A., 2015,
              267-336 ISBN: 1- 62699- 051-4.{" "}
            </li>
            <li>
              {" "}
              Pandita D., Lather V., SilicaBased Nanoparticles for Theranostic
              Applications NanoBioMedicine, Vol. 3, NanoTheranostics, Bhupinder
              Singh, Studium Press LLC, U.S.A., 2015, 383-422 ISBN: 1- 62699-
              053-0{" "}
            </li>
            <li>
              {" "}
              Pandita D., Lather V., Nanotechnology: Novel Prospects and
              Perspectives Nanotechnology: Novel Prospects and Perspectives,
              Bhupinder Singh, Anupama Kaushik, S. K. Mehta, S. K. Tripathi,
              McGraw Hill Education Pvt. Ltd., 2015, 78- 84.{" "}
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>S.No.</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Role</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Conference</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Organized by</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Sponsored by</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>International/National</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 1. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    3rd Annual Conference of APTI Haryana State Branch on
                    Current Scenario and Challenges in Industrial-Academia
                    Interecation in Pharmaceutical Sector{" "}
                  </p>
                  <p> Nov 11-12, 2016 </p>
                </td>
                <td>
                  <p> APTI, Haryana &amp; JCDM College of Pharmacy, Sirsa </p>
                </td>
                <td>
                  <p> APTI, Haryana State Branch </p>
                  <p> &amp; JCDM College of Pharmacy, Sirsa </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 2. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Nanotechnology and its applications{" "}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    National Conference on Challenges and Opportunities in Drug
                    Design and Development{" "}
                  </p>
                  <p> Apr 23, 2016 </p>
                </td>
                <td>
                  <p>
                    {" "}
                    JCDM College of Pharmacy, Sirsa &amp; APTI, Haryana State
                    Branch{" "}
                  </p>
                </td>
                <td>
                  <p> APTI, Haryana &amp; JCDM College of Pharmacy </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 3. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Nanoparticles in drug and gene delivery{" "}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    National Conference on Biotechnology: Emerging Trends Feb
                    11-12, 2016{" "}
                  </p>
                </td>
                <td>
                  <p> Department of </p>
                  <p> Biotechnology, CDL Univ., Sirsa </p>
                </td>
                <td>
                  <p> Dept. of Biotech, CDLU, Sirsa </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 4. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p> Voice Against Female Foericide VFF-2016 </p>
                  <p> Feb 29, 2016 </p>
                </td>
                <td>
                  <p> JCD Inst. of Business Management, Sirsa </p>
                </td>
                <td>
                  <p> Indian Red Cross Society, Haryana </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 5. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    2nd Annual National Conference of APTI Haryana State Branch{" "}
                  </p>
                  <p> Oct. 25, 2015 </p>
                </td>
                <td>
                  <p> APTI, Haryana &amp; M. D. Univ., Rohtak </p>
                </td>
                <td>
                  <p> APTI, Haryana State Branch </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 6. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Advancements, Opportunities and Future vision of Indian
                    Scenario of Pharmaceutical Education &amp; Research, Apr
                    03-04, 2015{" "}
                  </p>
                </td>
                <td>
                  <p> Rayat Bahra Inst. Of Pharmacy, Hoshiarpur, Punjab </p>
                </td>
                <td>
                  <p> Rayat Bahra Inst. Of Pharmacy, Hoshiarpur, Punjab </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 7. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Nanotechnology in the delivery of
                    nutraceuticals: a case study{" "}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    National Conference in Food Technology: Emerging Trends{" "}
                  </p>
                  <p> Mar 24-25, 2015 </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Department of Food Science &amp; Technology, CDL Univ.,
                    Sirsa{" "}
                  </p>
                </td>
                <td>
                  <p> Dept. Of Food Sci. &amp; Tech., CDLU, Sirsa </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 8. </p>
                </td>
                <td>
                  <p> Resource Person </p>
                </td>
                <td>
                  <p>
                    {" "}
                    1st Annual Conference of APTI Haryana on Indian Scenario of
                    Pharmaceutical Education: Challenges &amp; Future
                    Perspectives{" "}
                  </p>
                  <p> Aug 22-23, 2014 </p>
                </td>
                <td>
                  <p> APTI, Haryana &amp; Ku University, Kurukshetra </p>
                </td>
                <td>
                  <p> APTI, Haryana &amp; Ku University, Kurukshetra </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 9. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Mesoporous silica nanoparticles as
                    promising carriers in drug delivery{" "}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    NanoSciTech 2014, International Conference on
                    ‘Nanotechnology in the Service of Health, Environment &amp;
                    Society’{" "}
                  </p>
                  <p> Feb 13-15, 2014 </p>
                </td>
                <td>
                  <p> Panjab University </p>
                </td>
                <td>
                  <p> Panjab Univ., Chandigarh </p>
                </td>
                <td>
                  <p> Inter- national </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 10. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Novel drug/gene delivery platforms:
                    engineering DNA as a polymer{" "}
                  </p>
                </td>
                <td>
                  <p> FDP: Recent advances in novel drug delivery systems </p>
                  <p> May 28- June 10, 2013 </p>
                </td>
                <td>
                  <p> JCDM College of Pharmacy, Sirsa </p>
                </td>
                <td>
                  <p> AICTE, New Delhi </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 11. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Current &amp; Future Scenarios in Drug Development &amp;
                    Delivery{" "}
                  </p>
                  <p> Aug 11-12. 2012 </p>
                </td>
                <td>
                  <p> JCDM College of Pharmacy, Sirsa </p>
                </td>
                <td>
                  <p> AICTE, New Delhi </p>
                </td>
                <td>
                  <p> Inter- national </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 12. </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Lecture delivered: Journal impact factors: leading or
                    misleading tool for benchmarking the quality of{" "}
                  </p>
                  <p> research </p>
                </td>
                <td>
                  <p>
                    {" "}
                    SDP: Current Status and Future Perspectives in Drug
                    Discovery and Delivery Technologies{" "}
                  </p>
                  <p> June 17-30, 2012 </p>
                </td>
                <td>
                  <p> JCDM College of Pharmacy, Sirsa </p>
                </td>
                <td>
                  <p> AICTE, New Delhi </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 13. </p>
                </td>
                <td>
                  <p> Lecture delivered: Nanotechnology in drug delivery </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Scope, Innovations and Challenges in Pharmacy Profession,
                    Rohtak March 30, 2012{" "}
                  </p>
                </td>
                <td>
                  <p> Shri Baba Mast Nath Institute of </p>
                  <p> Pharmaceutical Sciences &amp; Research, Rohtak </p>
                </td>
                <td>
                  <p> Shri Baba Mast Nath Institute of </p>
                  <p> Pharmaceutical Sciences &amp; Research, </p>
                  <p> Rohtak </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 14. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Nanoschool: Emerging Techniques, Novel and Innovative
                    Strategies Sep 24-25, 2011{" "}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Jan Nayak Ch. Devi Lal Memorial College of Pharmacy, Sirsa
                    (AICTE sponsored){" "}
                  </p>
                </td>
                <td>
                  <p> AICTE, New Delhi </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 15. </p>
                </td>
                <td>
                  <p> Academic Session Chair </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Current Trends in Pharmaceutical Education &amp; Research{" "}
                  </p>
                  <p> Mar 12, 2011 </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Jan Nayak Ch. Devi Lal Memorial College of Pharmacy, Sirsa{" "}
                  </p>
                </td>
                <td>
                  <p> IPGA </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p> 16. </p>
                </td>
                <td>
                  <p> Co- Chairperson </p>
                </td>
                <td>
                  <p>
                    {" "}
                    Pharmaceutical Opportunities and Challenges of the New
                    Decade{" "}
                  </p>
                </td>
                <td>
                  <p> IPGA, New Delhi </p>
                </td>
                <td>
                  <p> IPGA, New Delhi </p>
                </td>
                <td>
                  <p> National </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li> Best Pharmaceutical Scientist Award- 2014, </li>
            <li> Haryana State Branch, APTI </li>
            <li> Postdoc Fellowship, FCT, Portugal, 2008-2010 </li>
            <li>
              {" "}
              Principal Investigator, Young Scientist Scheme, SERB, DST,
              2012-15, Grant received{" "}
            </li>
            <li>
              {" "}
              Principal Investigator, Research Promotion Scheme, AICTE, 2012-14,
              Grant received{" "}
            </li>
            <li>
              {" "}
              Faculty Development Programme Grant, AICTE, Grant received (Rs.
              7.00 Lacs){" "}
            </li>
            <li> DST Travel Grant for PharmaTech, 2012, Malaysia </li>
            <li>
              {" "}
              ICMR Travel Grant for PharmaTech 2014, Bangkok h. DST Travel Grant
              for Controlled Release Society 2017, Boston, USA (Not availed).{" "}
            </li>
            <li>
              {" "}
              DST, Haryana Grant for IPR Workshop/Seminar, 2017-18, Grant
              received{" "}
            </li>
            <li>
              {" "}
              Best Paper Award, 2006, 58th Indian Pharmaceutical Congress,
              Mumbai, India{" "}
            </li>
            <li>
              {" "}
              Senior Research Fellowship Award, 2005, Indian Council of Medical
              Research, India{" "}
            </li>
            <li> Rank Holder, M. Pharm, Manipal University, Manipal </li>
            <li> Rank Holder, B. Pharm, Mysore University, Mysore </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrDeeptiPandita;
