import React from 'react'
import eventPdf from './Final-draft-book-exhibition-report-edited-on-14-sep.2022.pdf'

const LibraryEvent = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">
    Events
    </h2>
    <ul className="subscribed-resources-ul">
        <li>
          <a href={eventPdf}>DPSRU Library Book Exhibition on 8th September 2022.</a>
        </li>
        <li>
        Workshop on Turnitin on 8th August 2022.
        </li>
        </ul>
   
    </div>
  )
}

export default LibraryEvent
