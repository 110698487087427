import React, { useState } from "react";
import './Nirf.css'

import pharmacy2024 from './Nirf Data/PHARMACY2024.pdf'
import innovaton2024 from './Nirf Data/INNOVATION2024.pdf'
import overall2024 from './Nirf Data/OVERALL2024.pdf'

import o2023 from './Nirf Data/OVERALL-NIRF-2023.pdf'
import p2023 from './Nirf Data/PHARMACY-NIRF-2023.pdf'
import c2023 from './Nirf Data/Certificate-4_page-0001.jpg'

import o2022 from './Nirf Data/overall2022.pdf'
import p2022 from './Nirf Data/p2022.pdf'
import c2022 from './Nirf Data/WhatsApp-Image-2022-11-04-at-11.21.52-AM-300x230.jpeg'

import o2021 from './Nirf Data/Overall2021.pdf'
import p2021 from './Nirf Data/Pharmacy2021.pdf'
import c2021 from './Nirf Data/WhatsApp-Image-2022-11-04-at-11.21.52-AM-300x230.jpeg'

import nirfcerticifcate2024 from './Nirf Data/NIRF Certificate 2024.pdf'
import nirf2021certificatereplaced from './Nirf Data/NIRF 2021 Certificate r.pdf'



function Nirf() {
    const [status2024, setStatus2024] = useState(false)
    const [status2023, setStatus2023] = useState(false)
    const [status2022, setStatus2022] = useState(false)
    const [status2021, setStatus2021] = useState(false)
    return (
        <div className="nirf-component">
            <h2 className="dpsru-primary-heading-for-every-page">
                NIRF
            </h2>
            <div className="nirf-data-container">

                {/* section 1 */}
                <section className="nirf-data-section-eact data-2023 nirf-data-2024">
                    <button className="nirf-botton" onClick={() => setStatus2024(!status2024)}>2024</button>
                    {
                        status2024 ?
                            <div className="inner-container-of-nirf-data">

                                <ul>
                                    <li><a href={overall2024}>OVER-ALL</a></li>
                                    <li><a href={pharmacy2024}>PHARMACY</a></li>
                                    <li><a href={innovaton2024}> INNOVATION </a></li>
                                    <li><a href={nirfcerticifcate2024}> CERTIFICATE </a></li>
                                </ul>
                            </div>
                            : null
                    }
                </section>
                {/* section 1 */}
                <section className="nirf-data-section-eact data-2023">
                    <button className="nirf-botton" onClick={() => setStatus2023(!status2023)}>2023</button>
                    {
                        status2023 ?
                            <div className="inner-container-of-nirf-data">

                                <ul>
                                    <li><a href={o2023}>OVER-ALL</a></li>
                                    <li><a href={p2023}>PHARMACY</a></li>
                                    <li><a href={c2023}> CERTIFICATE </a></li>
                                </ul>
                            </div>
                            : null
                    }
                </section>

                {/* section 2 */}
                <section className="nirf-data-section-eact data-2022">
                    <button className="nirf-botton" onClick={() => setStatus2022(!status2022)} > 2022</button>
                    {
                        status2022 ?
                            <div className="inner-container-of-nirf-data">

                                <ul>
                                    <li><a href={o2022}>OVER-ALL</a></li>
                                    <li><a href={p2022}>PHARMACY</a></li>
                                    <li><a href={c2022}> CERTIFICATE </a></li>
                                </ul>
                            </div>
                            : null
                    }
                </section>

                {/* section 3 */}
                <section className="nirf-data-section-eact data-2021">
                    <button className="nirf-botton" onClick={() => setStatus2021(!status2021)}>2021</button>
                    {
                        status2021 ?
                            <div className="inner-container-of-nirf-data">

                                <ul>
                                    <li><a href={o2021}>OVER-ALL</a></li>
                                    <li><a href={p2021}>PHARMACY</a></li>
                                    <li><a href={nirf2021certificatereplaced}> CERTIFICATE </a></li>
                                </ul>
                            </div>
                            : null
                    }
                </section>


            </div>
        </div>
    )
}

export default Nirf