import React from "react";
import "./CampusAmenities.css";
import CentreforPrecision1 from "./Campus Amenities Data/Centre-for-Precision-1.jpg";
import centreForAdbancedFormulation from "./Campus Amenities Data/DSIR-DPSRU-CRTDH Centre for Advanced Formulation Technology(CAFT).jpeg";
import AnimalHouse from "./Campus Amenities Data/ANIMAL HOUSE.jpg";
import BOARDROOM from "./Campus Amenities Data/BOARD ROOM.jpg";
import centralInstrumentationFacility from "./Campus Amenities Data/CENTRAL INSTRUMENTATION FACILITY.jpg";
import WORLDCLASSSKILLCENTER from "./Campus Amenities Data/WORLD CLASS SKILL CENTER.jpg";
import INCUBATIONCENTER from "./Campus Amenities Data/INCUBATION CENTER.jpg";
import EXAMINATIONBRANCH from "./Campus Amenities Data/EXAMINATION BRANCH.jpeg";
import COMPUTERFACILITYITCENTER from "./Campus Amenities Data/COMPUTER FACILITY IT CENTER.jpg";
import LIBRARY from "./Campus Amenities Data/LIBRARY.png";
import MEDICINALHERBALGARDEN from "./Campus Amenities Data/MEDICINAL HERBAL GARDEN.jpeg";
import DELIBERATIONSHALL from "./Campus Amenities Data/DELIBERATIONS HALL.jpg";
import MUSEUM from "./Campus Amenities Data/MUSEUM.gif";
import GIRLSANDBOYSHOSTEL from "./Campus Amenities Data/GIRLS’ AND BOYS’ HOSTEL.jpg";
import AUDITORIUM from "./Campus Amenities Data/AUDITORIUM.jpg";
import PHYSIOTHERAPYOPDANDPEDIATRICPHYSIOTHERAPYUNIT from "./Campus Amenities Data/PHYSIOTHERAPY OPD AND PEDIATRIC PHYSIOTHERAPY UNIT.jpg";
import CENTREFORMEDITATIONANDYOGASCIENCES from "./Campus Amenities Data/CENTRE FOR MEDITATION AND YOGA SCIENCES.jpeg";
import NSSNationalServiceSchemeCENTER from "./Campus Amenities Data/NSS (National Service Scheme) CENTER.jpeg";
import FOODCOURTANDMOTHERDIARYBOOTH from "./Campus Amenities Data/FOOD COURT AND MOTHER DIARY BOOTH.jpeg";
import GYMNASIUM from "./Campus Amenities Data/GYMNASIUM.jpg";
import SPORTSCOMPLEX from "./Campus Amenities Data/SPORTS COMPLEX.jpg";
import MUSICALFOUNTAINS from "./Campus Amenities Data/MUSICAL FOUNTAINS.jpeg";
import SOLARPANEL from "./Campus Amenities Data/SOLAR PANEL.jpg";
import SECURITYSERVICES from "./Campus Amenities Data/SECURITY SERVICES.jpeg";
import RAINWATERHARVESTING from "./Campus Amenities Data/RAIN WATER HARVESTING.jpg";
import SBIATM from "./Campus Amenities Data/SBI ATM.jpg";

const CampusAmenities = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        INFRASTRUCTURE FACILITIES AT DPSRU
      </h2>

      {/* section start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        DPSRU Centre for Precision Medicine and Pharmacy
      </h3>

      <div className="data-container-of-campus-amenities">
        <img src={CentreforPrecision1} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The DPSRU Centre for Precision Medicine and Pharmacy (DCPMP) is the
            first centre in India for Research and Training in Precision
            Medicine and Pharmacy. This Centre is approved and funded by the
            Delhi knowledge Development Foundation (DKDF), DTTE, Govt. of NCT of
            Delhi. The specific mandate of the centre is to establish the human
            and technical infrastructure to foster transdisciplinary
            national-scale research collaborations to focus on precision
            medicine and treatment modalities for Indian patients. The Centre is
            committed to fostering knowledge and awareness about the potential
            and advancement in precision medicine-based research. Also dedicated
            to strengthening practical knowledge among young and active
            researchers and academicians who are enthusiastic about using
            genomes and proteomics in their studies. To achieve these goals, the
            centre frequently organizes hands-on training sessions and expert
            lectures in the domain area.
          </p>
        </div>
      </div>
      {/* section end here */}

      {/* section 2 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        DSIR-DPSRU-CRTDH Centre for Advanced Formulation Technology(CAFT)
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={centreForAdbancedFormulation} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The newly incepted DSIR-DPSRU-CRTDH celebrated the Constitution Day
            and Azadi ka Amrit Mahotsav from November 26–29th, 2021 along with
            many other activites, workshop on ‘Teledyne Hanson Diffusion
            System’, development of Herbal Gulal, etc. <br />
            DPSRU CAFT team comprises of Hon’ble Vice Chancellor Prof. Ramesh K.
            Goyal, DPSRU and his team comprising of Prof. Deepti Pandita, Prof.
            Geeta Aggarwal, Dr. Gaurav K. Jain.
          </p>
        </div>
      </div>
      {/* section 2 end here */}

      {/* section 3 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">ANIMAL HOUSE</h3>
      <div className="data-container-of-campus-amenities">
        <img src={AnimalHouse} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The students and faculty have access to the air-conditioned Animal
            House Facility, which is a newly constructed three-storied building
            with basement and houses a number of experimental animals.
          </p>
        </div>
      </div>
      {/* section 3 end here */}

      {/* section 4 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">BOARD ROOM</h3>
      <div className="data-container-of-campus-amenities">
        <img src={BOARDROOM} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The University has a state-of-the-art board room which serves as an
            arena for cultivating team spirit equipped with modern multimedia
            facilities for important official meetings and proceedings. It
            provides a forum for interactive sessions, workshops and
            presentations with a seating capacity of 50.
          </p>
        </div>
      </div>
      {/* section 4 end here */}

      {/* section 5 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        CENTRAL INSTRUMENTATION FACILITY
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={centralInstrumentationFacility} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The Central Instrumentation facility has a complement of various
            sophisticated instruments and facilities to promote
            interdisciplinary research. The facility caters the need of cutting
            edge research in many areas of science and technology. It provides a
            platform for training students and researchers with the latest
            instruments in the field of pharmaceutical sciences. A full time
            technician is appointed with specific expertise, to aid with the
            operation of the instruments. The facility is present at both DIPSAR
            and DPSRU.
          </p>
        </div>
      </div>
      {/* section 5 end here */}

      {/* section 6 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        WORLD CLASS SKILL CENTER
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={WORLDCLASSSKILLCENTER} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            In 2012, Government of NCT Delhi initiated the development of an
            ambitious green field project through “World Class Skill Centre
            (WCSC)” in Delhi to address the shortage of skilled manpower. It is
            a pioneering effort of national interest, and set a benchmark for
            professional skill training in the country. The approach of this is
            to provide world class skills to youth of India by imparting world
            class training, training technologies and world class physical
            environment.
          </p>
        </div>
      </div>
      {/* section 6 end here */}

      {/* section 7 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        INCUBATION CENTER
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={INCUBATIONCENTER} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The Delhi Pharmaceutical Sciences and Research University (DPSRU) is
            host to the DPSRU Innovation and Incubation Foundation (DIIF), an
            early stage incubator focusing on incubation and entrepreneurship in
            healthcare, pharmaceuticals and wellness.
          </p>
        </div>
      </div>
      {/* section 7 end here */}

      {/* section 8 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        EXAMINATION BRANCH
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={EXAMINATIONBRANCH} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Examination Branch of Delhi Pharmaceutical Sciences and Research
            University was inaugurated by Prof. Ramesh K. Goyal, Hon’ble
            Vice-Chancellor, DPSRU in the presence of Shri S.S. Gill, IAS,
            Director, DTTE and Shri Sugan Lal Meena, Chief Engineer, PWD
            (South), Govt. of NCT of Delhi as Chief Guests on 15th Feb. 2020.
          </p>
        </div>
      </div>
      {/* section 8 end here */}

      {/* section 9 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        COMPUTER FACILITY/ IT CENTER
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={COMPUTERFACILITYITCENTER} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Technology provides means for good governance and active learning
            and all the major research laboratories, faculty rooms,
            administration, library, animal house, seminar hall, lecture
            theatres, auditorium, guest house etc. are connected by LAN and
            internet connections. <br />
            The University has excellent state-of-the-art computing facilities
            and system analysis units in its computer centre equipped with more
            than 100 computers to cater to needs of students. <br />
            The information technology centre is well equipped with advanced
            computers along with all the necessary peripherals as well as
            requisite software facilities and operating systems and has internet
            facility at their disposal round the clock.
          </p>
        </div>
      </div>
      {/* section 9 end here */}

      {/* section 10 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">LIBRARY</h3>
      <div className="data-container-of-campus-amenities">
        <img src={LIBRARY} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The Library is established in 1979, which enables the users to find
            out the required document and information in minimum time. The
            Library covers a total area of 4400 sq. ft. and is fully
            air-conditioned. Library plays a vital role in furthering the
            academic, research and consultancy mission of DPSRU/DIPSAR and
            facilitates creation, dissemination and sharing of knowledge in the
            field of Pharmaceutical Sciences and its allied subjects.
          </p>
        </div>
      </div>
      {/* section 10 end here */}

      {/* section 11 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        MEDICINAL HERBAL GARDEN
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={MEDICINALHERBALGARDEN} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The medicinal herbal garden within the campus has important
            traditional, medicinal and aromatic plants, herbs, shrubs and trees.
            The herbal garden is used by M. Pharm. and Ph.D. students working on
            herbal projects for growing plants for use in their experiments and
            collection. The main purpose of the herbal garden is to facilitate
            the experimental work and initiate the conservation of rare
            medicinal plants. The herbs are cultivated according to their season
            to get maximum yield of useful component of herbal drug. At present
            one herb is used to get constant yield on mass scale, its sowing is
            done in April and is harvested in August. This helps in obtaining
            constant and good quality of active principles. It also helps in
            training PG students about collection and cultivation of natural
            products.
          </p>
        </div>
      </div>
      {/* section 11 end here */}

      {/* section 12 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        DELIBERATIONS HALL
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={DELIBERATIONSHALL} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Fully air-conditioned four deliberation rooms at DIPSAR block and
            six lecture theatres at DPSRU block with the capacity of more than
            60 students provide the most conducive atmosphere for dynamic and
            focused discussions. All lecture theatres are well ventilated and
            equipped with the latest audio-visual facilities which give the
            right ambience for the teacher and the taught. All blocks are
            equipped with fire fighting solutions. All classrooms and utility
            areas are provided with power backup. Special sessions or classes
            are held on effective communication, leadership and team work
            skills.
          </p>
        </div>
      </div>
      {/* section 12 end here */}

      {/* section 13 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">MUSEUM</h3>
      <div className="data-container-of-campus-amenities">
        <img src={MUSEUM} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The University has an organized museum which is facilitated with all
            kinds of educative anatomical models. Museum has been established as
            an integral part of the Pharmacology Department, aimed to make the
            students understand the link between the human body and
            pharmacological and therapeutic agents.
          </p>
        </div>
      </div>
      {/* section 13 end here */}

      {/* section 14 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        GIRLS’ AND BOYS’ HOSTEL
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={GIRLSANDBOYSHOSTEL} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Students have exclusive hostels for boys and girls with spacious,
            comfortable, well-furnished rooms and a 24-hour security system. A
            new PG block for girl’s hostel has been created along with the
            existing ones. A fully air-conditioned study hall and an IT room are
            in the pipeline.
          </p>
        </div>
      </div>
      {/* section 14 end here */}

      {/* section 15 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">AUDITORIUM</h3>
      <div className="data-container-of-campus-amenities">
        <img src={AUDITORIUM} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The University has beautifully designed auditoriums which serve as a
            platform of interface with a seating capacity of 450. Centrally
            air-conditioned, well-planned, well-equipped and elegantly furnished
            auditorium is provided with all the modern amenities for conducting
            scientific seminars and meetings. To cater the increased student
            strength, planning is underway to increase the seating capacity of
            the auditorium.
          </p>
        </div>
      </div>
      {/* section 15 end here */}

      {/* section 16 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        PHYSIOTHERAPY OPD AND PEDIATRIC PHYSIOTHERAPY UNIT
      </h3>
      <div className="data-container-of-campus-amenities">
        <img
          src={PHYSIOTHERAPYOPDANDPEDIATRICPHYSIOTHERAPYUNIT}
          alt="campus amenities"
        />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Physiotherapy OPD is well equipped and working on various
            sub-specialities of physiotherapy. The services are provided to the
            out patients and University staff free of charge as a part of
            community health care services. The variety of rehabilitation
            services are provided by experienced and well trained team of
            Physiotherapist and Medical Officer. The OPD also runs a Pediatric
            Physiotherapy Unit.
          </p>
        </div>
      </div>
      {/* section 16 end here */}

      {/* section 17 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        CENTRE FOR MEDITATION AND YOGA SCIENCESIT
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={CENTREFORMEDITATIONANDYOGASCIENCES} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            DPSRU, in collaboration with the Government of NCT of Delhi, runs
            the Centre for Meditation and Yoga Sciences, to trin Yoga
            Instructors who have taken Yoga to the doorstep of every citizen of
            the state free of cost.
          </p>
        </div>
      </div>
      {/* section 17 end here */}

      {/* section 18 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        NSS (National Service Scheme) CENTER
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={NSSNationalServiceSchemeCENTER} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            National Service Scheme Centre is established in University building
            with voluntary association of teaching, non-teaching staff and
            students. Various NSS activities are conducted.
          </p>
        </div>
      </div>
      {/* section 18 end here */}

      {/* section 19 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        FOOD COURT AND MOTHER DIARY BOOTH
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={FOODCOURTANDMOTHERDIARYBOOTH} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            A fully air-conditioned food court that offer nutritious and
            wholesome food and snacks for the students, faculty and staff. Also
            Mother Dairy Booth started functioning in the year 2020.
          </p>
        </div>
      </div>
      {/* section 19 end here */}

      {/* section 20 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">GYMNASIUM</h3>
      <div className="data-container-of-campus-amenities">
        <img src={GYMNASIUM} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            University has a state of art Gymnasium aiming at preparing healthy
            all-rounder professionals.
          </p>
        </div>
      </div>
      {/* section 20 end here */}

      {/* section 21 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">SPORTSCOMPLEX</h3>
      <div className="data-container-of-campus-amenities">
        <img src={SPORTSCOMPLEX} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The University has a spacious sports complex with facilities for
            playing outdoor games viz Cricket, Football, Volley ball, Basket
            Ball, Badminton and Athletic track, also possess the Indoor
            facilities viz: table tennis, chess, Carrom. The multipurpose sports
            ground of the University is in par with National standards of sports
            facilities.
          </p>
        </div>
      </div>
      {/* section 21 end here */}

      {/* section 22 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        MUSICAL FOUNTAINS
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={MUSICALFOUNTAINS} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            Two musical fountains are available in the campus, at the front and
            at the back.
          </p>
        </div>
      </div>
      {/* section 22 end here */}

      {/* section 23 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">SOLAR PANEL</h3>
      <div className="data-container-of-campus-amenities">
        <img src={SOLARPANEL} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            DPSRU made agreement with Clean Max Enviro Energy Solutions Pvt Ltd
            and capable of generating 108.80 KW of electricity.
          </p>
        </div>
      </div>
      {/* section 23 end here */}

      {/* section 24 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        SECURITY SERVICES
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={SECURITYSERVICES} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            The campus has a 24 hrs security service along with CCTV
            surveillances.
          </p>
        </div>
      </div>
      {/* section 24 end here */}

      {/* section 25 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        RAIN WATER HARVESTING
      </h3>
      <div className="data-container-of-campus-amenities">
        <img src={RAINWATERHARVESTING} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">
            RAIN WATER HARVESTING
          </p>
        </div>
      </div>
      {/* section 25 end here */}

      {/* section 26 start here */}
      <h3 className="dpsru-secondary-heading-for-every-page">SBI ATM</h3>
      <div className="data-container-of-campus-amenities">
        <img src={SBIATM} alt="campus amenities" />
        <br />
        <div className="text-conteiner-of-campus-amenities-updated">
          <p className="paragrauph-of-campus-amenities-updated">SBI ATM</p>
        </div>
      </div>
      {/* section 26 end here */}
    </div>
  );
};

export default CampusAmenities;
