import React from "react";

const InstitutionalMembership = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Institutional Membership
      </h2>

      <ul className="subscribed-resources-ul">
        <li>
          <a href="https://shodhganga.inflibnet.ac.in/simple-search">
            <b>Shodhganga</b>
          </a>
        </li>
        <li>
          <a href="https://shodhgangotri.inflibnet.ac.in/">
            <b>Shodhgangotri</b>
          </a>
        </li>
        <li>
          <a href="https://vidwan.inflibnet.ac.in/">
            <b>VIDWAN</b>
          </a>
        </li>
        <li>
          <a href="https://shodhchakra.inflibnet.ac.in/">
            <b>Shodh Chakra</b>
          </a>
        </li>
        <li>
          <a href="https://ess.inflibnet.ac.in/">
            <b>E- Shodh Sindhu</b>
          </a>
        </li>
        <li>
          <a href="https://ndl.iitkgp.ac.in/">
            <b>National Digital Library of India</b>
          </a>
        </li>
        <li>
          <a href="https://dpsru.irins.org/">
            <b>DPSRU @IRINS</b>
          </a>
        </li>
        <li>
          <a href="https://delnet.in/index.html">
            <b>DELNET</b>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default InstitutionalMembership;
