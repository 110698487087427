import React from 'react'

import mimg1 from './WhatsApp-Image-2023-07-28-at-10.36.50-AM-300x250.jpeg'
import mimg2 from './WhatsApp-Image-2023-07-28-at-10.36.52-AM-300x250.jpeg'

const MedicalLaboratoryTechnologyweek = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className='medicalLabTechWeek-heading' >
    Medical Laboratory Technology week (17-23 July, 2023) celebrated by DPSRU MLT students with faculty members Dr. Sachin Kumar, Dr. Saurabh Chaturvedi and Dr. Rashi Gupta at AIIMS, New Delhi.
    </h2>
    <div className="swachta-seva-gallery-container">
        <div className="swachta-seva-img-box">
            <img src={mimg1} alt="" />
        </div>
        <div className="swachta-seva-img-box">
            <img src={mimg2} alt="" />
        </div>
      </div>

</div>
  )
}

export default MedicalLaboratoryTechnologyweek