import React from 'react'
import './NationalRankingIAQC.css'

import nirf2023 from './National Ranking Images/Certificate-4_page-0001.jpg'
import nirf2022 from './National Ranking Images/nirf2022.jpeg'
import nirf2021 from './National Ranking Images/nirf2021.jpeg'
import nirf2020 from './National Ranking Images/nirf2020.jpeg'
import nirf2019 from './National Ranking Images/nirf2019.jpeg'
import nirf2017 from './National Ranking Images/nirf2017.jpeg'

// aishe
import aishe2021 from './National Ranking Images/aishe2021.jpg'
import aishe1920 from './National Ranking Images/aishe1920.jpg'
import aishe1819 from './National Ranking Images/aishe1819.jpg'
import aishe1718 from './National Ranking Images/aishe1718.jpg'
import aishe1617 from './National Ranking Images/aishe1617.jpg'

//ISO
import iso from './National Ranking Images/iso.jpg'
//ARIIA
import ariia from './National Ranking Images/ariia.jpg'

import nirfcertificatepdf24 from './National Ranking Images/nirf2024certificate.jpg'


function NationalRankingIAQC() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                NATIONAL RANKINGS
            </h2>

            <h3 className="dpsru-secondary-heading-for-every-page">
                NIRF
            </h3>


            <div className="campus-highlight-container-in-about-page">

                <figure>
                    <a href={nirfcertificatepdf24}> <img src={nirfcertificatepdf24} alt="Sports Complex" /></a>
                    <figcaption>2024</figcaption>
                </figure>
                <figure>
                    <a href={nirf2023}> <img src={nirf2023} alt="Sports Complex" /></a>
                    <figcaption>2023</figcaption>
                </figure>

                <figure>
                    <a href={nirf2022}> <img src={nirf2022} alt="Sports Complex" /></a>
                    <figcaption>2022</figcaption>
                </figure>

                <figure>
                    <a href={nirf2021}>  <img src={nirf2021} alt="Auditorium" /></a>
                    <figcaption>2021</figcaption>
                </figure>

                <figure>
                    <a href={nirf2020}><img src={nirf2020} alt="Swimming Pool" /></a>
                    <figcaption>2020</figcaption>
                </figure>
                <figure>
                    <a href={nirf2019}><img src={nirf2019} alt="Swimming Pool" /></a>
                    <figcaption>2019</figcaption>
                </figure>
                <figure>
                    <a href={nirf2017}><img src={nirf2017} alt="Swimming Pool" /></a>
                    <figcaption>2017</figcaption>
                </figure>
            </div>

            {/* aishe */}
            <h3 className="dpsru-secondary-heading-for-every-page">
                AISHE
            </h3>


            <div className="campus-highlight-container-in-about-page">

                <figure>
                    <a href={aishe2021}> <img src={aishe2021} alt="Sports Complex" /></a>
                    <figcaption>2020-21</figcaption>
                </figure>

                <figure>
                    <a href={aishe1920}>  <img src={aishe1920} alt="Auditorium" /></a>
                    <figcaption>2019-20</figcaption>
                </figure>

                <figure>
                    <a href={aishe1819}><img src={aishe1819} alt="Swimming Pool" /></a>
                    <figcaption>2018-19</figcaption>
                </figure>
                <figure>
                    <a href={aishe1718}><img src={aishe1718} alt="Swimming Pool" /></a>
                    <figcaption>2017-18</figcaption>
                </figure>
                <figure>
                    <a href={aishe1617}><img src={aishe1617} alt="Swimming Pool" /></a>
                    <figcaption>2016-17</figcaption>
                </figure>
            </div>

            {/* ISO */}
            <h3 className="dpsru-secondary-heading-for-every-page">
                ISO
            </h3>
            <div className="campus-highlight-container-in-about-page">
                <figure>
                    <a href={iso}><img src={iso} alt="Swimming Pool" /></a>
                    <figcaption>ISO</figcaption>
                </figure>
            </div>

            {/* ISO */}
            <h3 className="dpsru-secondary-heading-for-every-page">
               ARIIA
            </h3>
            <div className="campus-highlight-container-in-about-page">
                <figure>
                    <a href={ariia}><img src={ariia} alt="ARIIA" /></a>
                    <figcaption>ARIIA</figcaption>
                </figure>
            </div>
        </div>
    )
}

export default NationalRankingIAQC