import React from "react";

function DomesticVisit() {
  return (
    <div className="rti-act-component" style={{ marginBottom: "3%" }}>

        
      <h2 className="dpsru-primary-heading-for-every-page" style={{width:"90%" , margin:'2% auto'}}>
        Domestic Visit Expenses for FY 2023-24
      </h2>

      <table>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name of Faculty</th>
            <th>Place & Purpose</th>
            <th>Total amount Rs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Dr. Ajit Kr. Thakur</td>
            <td>IPSCON 2023 at SRM, Chennai w.e.f. 14th Dec to 16 Dec, 2023</td>
            <td>30159</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dr. Arya Lakshmi</td>
            <td>
              1. Rs. 8850-Registration fee to attend the Canna Biz-2023
              (participate in the conclave of Canna Bix-2023 and cannabis
              business which was held on 11th May to 12th May, 2023, New Delhi
              <br />
              2. Registration Fee of IASTM
            </td>
            <td>12350</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Dr. Sushma Talegaonkar</td>
            <td>
              A. For attending 1st women leadership conclave w.e.f. 28th & 29th
              April 2023 at Kenilworth Resort, Goa
              <br />
              B. Registration Fee attending IRCPAS 2.0 at USCI University, Kuala
              Lumpur w.e.f. 9th March to 14th March
            </td>
            <td>25615</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Dr. Amrita Mishra</td>
            <td>
              APTICON 2023 w.e.f. 2nd September to 3rd September, 2023 at Kanpur
            </td>
            <td>5114</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Dr. Simmi Vashistha</td>
            <td>
              Rs. 1180/- to attend the Innovation in Public Performance
              management (Gurgaon)
            </td>
            <td>1180</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Dr. Aakash Midha</td>
            <td>
              Rs. 1180/- to attend the Innovation in Public Performance
              management (Gurgaon)
            </td>
            <td>1180</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Dr. Foziyah Zakir</td>
            <td>
              APTICON 2023 w.e.f. 2nd September to 3rd September, 2023 at Kanpur
            </td>
            <td>10631</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Dr. Roshni Tandey</td>
            <td>
              A scientific session evaluator in a National Level on 17th & 18th
              June, 2023 at Chatrapati Shivaji Institute of Pharmacy, Durg,
              Chattisgarh
            </td>
            <td>29449</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Dr. Jaseela Majeed</td>
            <td>
              Expenditure made during attending the conference w.e.f. 2nd
              September to 4th September 2022 at JSS, Mysore
            </td>
            <td>24707</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Dr. Saurabh Chaturvedi</td>
            <td>
              1. For attending 19th world congress of basic & clinical
              pharmacology 2023 at Scotland
              <br />
              2. 10th Asia Pacific Chapter Meeting of the International Society
              for Peritoneal Dialysis (APCM ISPD 2023) hosted by PDSI and
              organized by Department of Nephrology, Sir Ganga Ram Hospital, New
              Delhi, India from 22th September to 24th September, 2023 for
              reimbursement of registration fee
              <br />
              3. Registration fee of GIS 2024, THSTI 15th Feb to 17 Feb,
              Faridabad
            </td>
            <td>54411</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Dr. Gaurav K Jain</td>
            <td>
              Expenditure incurred during attending IRCPAS 2.0 at USCI
              University, Kuala Lumpur w.e.f. 9th March to 14th March
            </td>
            <td>75000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DomesticVisit;
