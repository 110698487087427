import React from "react";
import "./BudgetAndProgramme.css";

// import budget1 from "./budget files/Budget2021-22_DPSRU.pdf";
import budget1 from "./budget files/Budget-Allocation-2022-23.pdf";

function BudgetAndProgramme() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        BUDGET AND PROGRAMME
      </h2>

      <div className="budgetAndProgramme-data-container">
        <table
          style={{
            border: "1px solid black",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid black" }}>S.No.</th>
              <th style={{ border: "1px solid black" }}>Item</th>
              <th style={{ border: "1px solid black" }}>
                Details of Disclosure
              </th>
              <th style={{ border: "1px solid black" }}>
                Remarks/Reference Points
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2.1</td>
              <td>
                Budget allocated to each agency including all plans, proposed
                expenditure, and reports on disbursements made etc.
              </td>
              <td>
                <tr>(i) Total Budget for the public authority</tr> <br />
                <tr>(ii) Budget for each agency and plan & programmes</tr>{" "}
                <br />
                <tr>(iii) Proposed expenditures</tr> <br />
                <tr>(iv) Revised budget for each agency, if any</tr> <br />
                <tr>
                  (v) Report on disbursements made and place where the related
                  reports are available
                </tr>{" "}
                <br />
              </td>
              <td>
                <tr>
                  (i) The details are available at{" "}
                  <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">
                    https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf
                  </a>
                </tr>{" "}
                <br />
                <tr>
                  (ii) There is no any separate budget for each agency.
                </tr>{" "}
                <br />
                <tr>
                  (iii) The details are available at{" "}
                  <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">
                    https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf
                  </a>
                </tr>{" "}
                <br />
                <tr>(iv) Revised budget for each agency, if any</tr> <br />
                <tr>(v) DDO, DPSRU</tr> <br />
              </td>
            </tr>
            <tr>
              <td>2.2</td>
              <td>Foreign and domestic tours</td>
              <td>
                <tr>(i) Budget</tr> <br />
                <tr>
                  (ii) Foreign and domestic Tours by ministries and officials of
                  the rank of Joint Secretary to the Government and above, as
                  well as the heads of the Department.
                </tr>{" "}
                <br />
                <tr>A. Places visited</tr> <br />
                <tr>B. The period of visit</tr> <br />
                <tr>
                  C. The number of members in the official delegation
                </tr>{" "}
                <br />
                <tr>D. Expenditure on the visit</tr> <br />
                <tr>(iii) Information related to procurements</tr> <br />
              </td>
              <td>
                <tr>
                  (i) The details are available at{" "}
                  <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">
                    https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf
                  </a>
                </tr>{" "}
                <br />
                <tr>
                  (ii) The details are available at{" "}
                  <a href="https://dpsru.edu.in/domesticVisit">
                    https://dpsru.edu.in/domesticVisit
                  </a>
                </tr>{" "}
                <br />
                <tr>
                  (iii) Detail of all the tenders by this office is available
                  on:
                  <ul style={{paddingLeft:'22px'}}>
                    <li>
                      <a href="https://dpsru.edu.in/static/media/Purchase%20Order%202023-24.d58a772f6972abd8c709.pdf">
                        Tenders Delhi Government of NCT of Delhi e-procurement
                        system portal
                      </a>
                    </li>
                    <li>
                      <a href="https://dpsru.edu.in/tenders">
                        https://dpsru.edu.in/tenders
                      </a>
                    </li>
                    <li>
                      <a href="https://gem.gov.in/">GEM portal</a>
                    </li>
                    <li>
                      <a href="https://govtprocurement.delhi.gov.in/nicgep/app">
                        https://govtprocurement.delhi.gov.in/nicgep/app
                      </a>
                    </li>
                  </ul>
                </tr>{" "}
                <br />
              </td>
            </tr>
            <tr>
              <td>2.3</td>
              <td>Manner of execution of subsidy programme</td>
              <td>
                <tr>(i) Name of the programme of activity</tr> <br />
                <tr>(ii) Objective of the programme</tr> <br />
                <tr>(iii) Procedure to avail benefits</tr> <br />
                <tr>(iv) Duration of the programme/scheme</tr> <br />
                <tr>
                  (v) Physical and financial targets of the programme
                </tr>{" "}
                <br />
                <tr>(vi) Nature/scale of subsidy/amount allotted</tr> <br />
                <tr>(vii) Eligibility criteria for grant of subsidy</tr> <br />
                <tr>
                  (viii) Details of beneficiaries of subsidy programme (number,
                  profile etc.)
                </tr>{" "}
                <br />
              </td>
              <td>
                <tr>(i) There is no any execution of subsidy programme</tr>{" "}
                <br />
                <tr>(ii) There is no objective of the programme</tr> <br />
                <tr>(iii) There is no procedure to avail benefits</tr> <br />
                <tr>(iv) There is no any duration</tr> <br />
                <tr>
                  (v) There are no physical and financial targets of the
                  programme
                </tr>{" "}
                <br />
                <tr>(vi) There is no any subsidy allotted</tr> <br />
                <tr>(vii) There is no eligibility criteria</tr> <br />
                <tr>
                  (viii) There are no details of beneficiaries of subsidy
                  programme
                </tr>{" "}
                <br />
              </td>
            </tr>
            <tr>
              <td>2.4</td>
              <td>Discretionary and non-discretionary grants</td>
              <td>
                <tr>
                  (i) Discretionary and non-discretionary grants/allocations to
                  State Govt./NGOs/other institutions
                </tr>{" "}
                <br />
                <tr>
                  (ii) Annual accounts of all legal entities who are provided
                  grants by public authorities
                </tr>{" "}
                <br />
              </td>
              <td>
                <tr>
                  (i) There are no Discretionary and non-discretionary grants/
                  allocations to State Govt./NGOs/other institutions
                </tr>{" "}
                <br />
                <tr>
                  (ii) Annual accounts of all legal entities for the financial
                  year is in process and it will update soon on website <br /> <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf</a>
                </tr>{" "}
                <br />
              </td>
            </tr>
            <tr>
              <td>2.5</td>
              <td>
                Particulars of recipients of concessions, permits, or
                authorizations granted by the public authority
              </td>
              <td>
                <tr>(i) Concessions, permits or authorization granted</tr>{" "}
                <br />
                <tr>
                  (ii) For each concessions, permit or Authorization granted
                </tr>{" "}
                <br />
                <tr>A. Eligibility criteria</tr> <br />
                <tr>
                  B. Procedure for getting the concession/grant and/or permits
                  of authorizations
                </tr>{" "}
                <br />
                <tr>
                  C. Name and address of the recipients given
                  Concessions/permits or authorisations
                </tr>{" "}
                <br />
                <tr>
                  D. Date of award of concessions/permits of authorizations
                </tr>{" "}
                <br />
              </td>
              <td>
               
                  (i) Only students travelling by bus, train may get fee
                  concession. by the guidelines of GNCTD & GoI{" "}
                  <a href="https://dtcpass.delhi.gov.in/faqs.jsp">
                    https://dtcpass.delhi.gov.in/faqs.jsp
                  </a>
                  ,{" "}
                  <a href="https://nwr.indianrailways.gov.in/uploads/files/1675832250244-English%20consession%20rules.pdf">
                    https://nwr.indianrailways.gov.in/uploads/files/1675832250244-English%20consession%20rules.pdf
                  </a>
                
                <br />
              </td>
            </tr>
            <tr>
              <td>2.6</td>
              <td>CAG & PAC paras</td>
              <td>
                CAG and PAC paras and the action taken reports (ATRs) after
                these have been laid on the table of both houses of the
                parliament.
              </td>
              <td>
                {" "}
                All such paras and ATR are sent to the concerned department of
                GoI for further action{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BudgetAndProgramme;
