import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import newGif from ".././NEW/new.gif";

// notification imported files
import vacantSeatforSpecial from "../Notifications/Notification Files/Vacant-Seats-for-Special-Spot-Round.pdf";
import specialSpotRoundC from "../Notifications/Notification Files/SPECIAL-SPOT-ROUND-COUNSELLING-Advertiesment-1.pdf";

import ReOpeningOfSemester from "../Notifications/Notification Files/CamScanner-08-09-2023-10.49.25_1.pdf";
import PhdVivaVocaNotiMsMeenuG from "../Notifications/Notification Files/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import Circular4823 from "../Notifications/Notification Files/Circular-4.8.23.pdf";
import DocVerificationNotice from "../Notifications/Notification Files/document-verification-notice.pdf";
import NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses from "../Notifications/Notification Files/WhatsApp-Image-2023-07-22-at-10.55.13-AM.jpeg";
import Circular02 from "../Notifications/Notification Files/Circular02.pdf";
import YogaAndFitness from "../Notifications/Notification Files/YogaAndFitness.pdf";
import Firstroundonlinecounsellingschedule202 from "../Notifications/Notification Files/First-round-online-counselling-schedule-2023.pdf";
import RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023 from "../Notifications/Notification Files/Revised-Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";
import RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023 from "../Notifications/Notification Files/Revised-Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import AlphabeticalListofcandidateswhohavenotfilledcoursePreferences from "../Notifications/Notification Files/Alphabetical-List-of-candidates-who-have-not-filled-course-Preferences.pdf";
import Commoninstructionsforskilltestfornonteachingposts from "../Notifications/Notification Files/Common-instructions-for-skill-test-for-non-teaching-posts.pdf";
import NoticeregardingUGcoursespreferencefilling202324 from "../Notifications/Notification Files/Notice-regarding-UG-courses-preference-filling-2023-24.pdf";
import NOTICEofSkillTest from "../Notifications/Notification Files/NOTICE-of-Skill-Test.pdf";
import Document570 from "../Notifications/Notification Files/Document-570.pdf";
import EmployementNotice from "../Notifications/Notification Files/Employement-Notice.pdf";
import Prospectus2023forweb from "../Notifications/Notification Files/Prospectus-2023-for-web.pdf";
import AdvertisementofJRF from "../Notifications/Notification Files/Advertisement-of-JRF.pdf";
import Advertisement2023241 from "../Notifications/Notification Files/Advertisement-2023-24-1.pdf";
import PhdAdmission202324 from "../Notifications/Notification Files/Phd-Admission-2023-24.pdf";
import CorrigendumofNTS1 from "../Notifications/Notification Files/Corrigendum-of-NTS-1.pdf";

// new data on 9of oct 2023

import newdata001 from "../Notifications/Notification Files/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";

import newdata002 from "../Notifications/Notification Files/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Meenu-Grover-Sharma.pdf";
import newdata003 from "../Notifications/Notification Files/Date-extended-Notice-for-Final-Admission-Pre-PhD-2023-24.pdf";
import newdata004 from "../Notifications/Notification Files/List-of-final-admission-Pre-PhD-2023-24.pdf";
import newdata005 from "../Notifications/Notification Files/Notice-for-final-admission-Pre-Ph.D-2023-24.pdf";
import newdata006 from "../Notifications/Notification Files/Result-of-Ph.D.-Entrance-Examination-2023-24.pdf";
import newdata007 from "../Notifications/Notification Files/Notice-and-List-for-candidates-of-Pre-PhD-Interview-September-2023.pdf";
import newdata008 from "../Notifications/Notification Files/List-for-approved-Supervisors-September-2023.pdf";
import newdata009 from "../Notifications/Notification Files/MPT-Entrance-Result.pdf";
import newdata010 from "../Notifications/Notification Files/Revised-list.pdf";

import convoFiles from "../Notifications/Notification Files/Convocation-Notice.pdf";
import phdCourseWork from "../Notifications/Notification Files/pre-PhD-course-work.pdf";
import walkinInterview3010 from "../Notifications/Notification Files/Advertisement.pdf";
import oddSemFeeNotice from "../Students/StudentsData/odd semester fee notice.pdf";

import NotificationPhdDegreeMsSweta from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Shweta Paroha_rotated.pdf";
import NotificationPhdDegreeMsNeha from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Neha_rotated.pdf";
import UFMNotice from "../Examination/ExaminatonData/UFM Notice.pdf";

import circularForTakingIntegrityPledge from "../Notifications/Notification Files/Circular for taking integrity pledge.pdf";
import CircularForTakinganOrganistionalOath from "../Notifications/Notification Files/Circular for taking an organisational Oath on 3 November 2023 in DPSRU.pdf";
import convoForm6th from "../Notifications/Notification Files/6th convocation registration form 2023.pdf";
import walkinInterviewOn17Nov from "../Notifications/Notification Files/Interview on 17.11.23.pdf";
import walkinInterviewResult from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf";
import finalListOfEligibleCandidatesForstudentsCouncil from "../Notifications/Notification Files/Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU.pdf";

import walkininterviewresulton17 from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 17-11-2023.pdf";
import ropeningoffeeportalforoddsem from "../Students/StudentsData/Reopening of fee portal for odd semester.pdf";
import finalspotroundformbaadmission from "../Admissions/newData Of admissions/Final spot round for MBA admission.pdf";
import noticeforprofssagarwaltropyforbptstudents from "../Students/StudentsData/Notice for PROF. S.S. AGRAWAL TROPHY for BPT students.pdf";
import callingsealedquotationsforflowerdecorrr from "../Notifications/Notification Files/WhatsApp Image 2023-12-16 at 8.23.27 AM.jpeg";
import recrutmentofjrffellow from "../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF).pdf";
import noticeforrehearsaltimingforcovo21stdecandceremony from "../Notifications/Notification Files/Notice for Rehearsal timings for convocation 21st December and  ceremony instructions for convocation 22nd December 2023.pdf";
import convoAwadiName from "../Notifications/Notification Files/List of Awardee’s for 6th Convocation 2023.pdf";
import weedingoutpolicyofficeorder from "../Notifications/Notification Files/weeding out the old record office order.pdf";
import ReOpeningofoddsemfeeportalnotice from "../Students/StudentsData/Re-Opening of Odd Semester Fee Portal, NOTICE.pdf";
import weedingoutpolicyofficeorder31jan24 from "../Notifications/Notification Files/Weeding out the old record DPSRU-1.pdf";
import reqruitnemrtForJrfdatafeb24 from "../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF) in a dST-SERB funded project.pdf";
import AwardpfPHDtomsRUchiTripathi from "../Notifications/Notification Files/Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi.pdf";
import engagementofaRetiredgovoffhome from "../Notifications/Notification Files/Engagement of a Retired Government Officer on Contract Basis.pdf";
import examformnoticeforprephdstudents from '../Examination/ExaminatonData/Examinations Form Notice for Pre. Ph.D. Students.pdf'
import openingofevensemfeenotice from '../Students/StudentsData/Opening of Even Semester fee Notice.pdf'
import admissioninformationforPGandUGcorses from '../Admissions/newData Of admissions/ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES_new.pdf'
import examFeeNoticeAprmay24 from '../Examination/ExaminatonData/Examination Fees Notice Even Semester Students.pdf'
import ReOpeningofEvensemfeeSem from '../Students/StudentsData/Re-opening of Even Semester Fee Semester.pdf'
import Ufmmeetiondecision from '../Examination/ExaminatonData/UFM Meeting Decision.pdf'
import noticeforsubmissionofsessionalmarkofdhpahrm from '../Notifications/Notification Files/Notice for submission of sessional Marks of D.Pharm. and Pre-PhD Examination.pdf'
import icmrprojectresearchscientisnosnsme from '../Notifications/Notification Files/Applications are invited for the post of ICMR-Project Research Scientist - I Non-Medical Last date May 27, 2024.pdf'
import faqofadminssioa from '../Admissions/newData Of admissions/Admission related FAQs.pdf'
import PhdvivavocaNotificationofmsritutiwari from './HomePageData/Ph.D Viva-Voce Notification of Ms. Ritu Tiwari.pdf'
import regardingObcNoncreamyadmission2425 from '../Admissions/newData Of admissions/REGARDING OBC NON CREAMY ADMISSIONS 2024-24.pdf'
// import revisedAdmissionScheduleofUgCourses2425 from '../Admissions/newData Of admissions/REVISED ADMISSION SCHEDULE OF UG COURSES 2024-25.pdf'
import revisedAdmissionScheduleofUgCourses2425 from '../Admissions/newData Of admissions/admission scheudule for all UG courses 2024-25.pdf'
import Feenoticeforevensemesterexam from '../Notifications/Notification Files/Fees Notice for Even Semester Examination.pdf'
// End
const HomePageFlashNotification = () => {
    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };
  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
  return (
    <div>
          <div className="marquee-bulletin-area">
          {/* Working now  */}
          <marquee
            ref={marqueeRef}
            behavior="scroll"
            direction="left"
            className="marquee-notification"
            loop=""
            Scrollamount="7"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div>
              {/* ****************************************** */}
              <a href={Feenoticeforevensemesterexam}>
                <span>
                Fees Notice for Even Semester Examination
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={revisedAdmissionScheduleofUgCourses2425}>
                <span>
                Admission schedule for all UG courses 2024-25
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={regardingObcNoncreamyadmission2425}>
                <span>
                REGARDING OBC NON CREAMY ADMISSIONS 2024-24
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdvivavocaNotificationofmsritutiwari}>
                <span>
                Ph.D Viva-Voce Notification of Ms. Ritu Tiwari
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={faqofadminssioa}>
                <span>
                Admission related FAQs
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={icmrprojectresearchscientisnosnsme}>
                <span>
                Applications are invited for the post of ICMR-Project Research Scientist - I (Non-Medical)" (Last date: May 27, 2024)"
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforsubmissionofsessionalmarkofdhpahrm}>
                <span>
                Notice for submission of sessional Marks of D.Pharm. and Pre-PhD Examination
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Ufmmeetiondecision}>
                <span>
                UFM Meeting Decision
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningofEvensemfeeSem}>
                <span>
                Re-opening of Even Semester Fee Semester
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href='https://dpsruonline.in/'>
                <span>
                Click here for Admissions to UG & PG Courses for the year 2024-25
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={examFeeNoticeAprmay24}>
                <span>
                Examination Fees Notice Even Semester Students
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={admissioninformationforPGandUGcorses}>
                <span>
                ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={openingofevensemfeenotice}>
                <span>
                Opening of Even Semester fee Notice
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={examformnoticeforprephdstudents}>
                <span>
                Examinations Form Notice for Pre. Ph.D. Students
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={engagementofaRetiredgovoffhome}>
                <span>
                  Engagement of a Retired Government Officer on Contract Basis
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AwardpfPHDtomsRUchiTripathi}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Ruchi
                  Tripathi
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a>
                <span>
                  {/* Admissions for the academic session 2024-25 in Diploma, UG,
                  and PG courses, EXCEPT M. Pharm. and B. Pharm. (LE), will be
                  conducted through CUET. Only candidates who have successfully
                  passed CUET will be considered, and the merit list will be
                  prepared based on the CUET-24 scores. */}
                  The admission for "All the UG Courses during this Academic
                  Year 2024-25, Will be held on the basis of merit at the
                  qualifying examination and the admissions for PG courses will
                  be held through CUET"
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={reqruitnemrtForJrfdatafeb24}>
                <span>
                  Advertisement for the Post of Junior Research Fellow (JRF) in
                  a dST-SERB funded project
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={weedingoutpolicyofficeorder31jan24}>
                <span>Weeding out the old record DPSRU-1</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningofoddsemfeeportalnotice}>
                <span>Re-Opening of Odd Semester Fee Portal, NOTICE</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={weedingoutpolicyofficeorder}>
                <span>Weeding out the old record - Office order</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://www.youtube.com/live/ukSLiTOVma0?feature=shared">
                <span>Alumni Meet 22 December 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://youtube.com/live/bdCky_0o9lw?feature=share">
                <span>Dpsru 6th Convocation Ceremony</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://auth.mygov.in/user/login?destination=oauth2/authorize">
                <span>Share your ideas for a Viksit Bharat</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoAwadiName}>
                <span>List of Awardee’s for 6th Convocation 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforrehearsaltimingforcovo21stdecandceremony}>
                <span>
                  Notice for Rehearsal timings for convocation on 21st December
                  and ceremony instructions for convocation on 22nd December
                  2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={recrutmentofjrffellow}>
                <span>
                  Advertisement for the Post of Junior Research Fellow (JRF)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={callingsealedquotationsforflowerdecorrr}>
                <span>
                  Calling sealed quotations for flower decorations at 6th
                  convocation in DPSRU
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforprofssagarwaltropyforbptstudents}>
                <span>
                  Notice for PROF. S.S. AGRAWAL TROPHY for BPT students
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={finalspotroundformbaadmission}>
                <span>Final spot round for MBA admission</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ropeningoffeeportalforoddsem}>
                <span>Reopening of fee portal for odd semester</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkininterviewresulton17}>
                <span>
                  Result of Walk-in-Interview for Guest Faculties held on
                  17/11/2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={finalListOfEligibleCandidatesForstudentsCouncil}>
                <span>
                  Final list of Eligible Candidates for Students Council
                  Elections 2023-24 DPSRU
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterviewResult}>
                <span>
                  Result of Walk-in-Interview for Guest Faculties held on
                  03/11/2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterviewOn17Nov}>
                <span>
                  Walk-in Interview for Guest Faculties of Pharmacy and
                  Physiotherapy on 17/11/2023 (Reporting Time strictly from
                  10:00 AM to 10:30 AM)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoForm6th}>
                <span>6th convocation registration form 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={CircularForTakinganOrganistionalOath}>
                <span>
                  Observance of Vigilance Awareness Week 2023 in DPSRU from 30
                  Oct to 05 Nov 2023 (Circular for Organizational Oath)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={circularForTakingIntegrityPledge}>
                <span>Circular for taking integrity pledge Individually</span>
              </a>{" "}
              ||
              <a href="https://pledge.cvc.nic.in/" style={{ color: "#000" }}>
                <span>Click here..</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={UFMNotice}>
                <span>UFM Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={oddSemFeeNotice}>
                <span>Odd semester fee notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NotificationPhdDegreeMsNeha}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Neha
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NotificationPhdDegreeMsSweta}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Shweta Paroha
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterview3010}>
                <span>
                  "Walk-in- Interview for Guest Faculties on 03/11/2023
                  (Reporting Time strictly from 10:00 AM to 11:00 AM)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                {" "}
                <span>
                  Admission open UG Courses Final Spot Round -{" "}
                  <b style={{ color: "black" }}>Apply Here</b>
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoFiles}>
                <span>6th Convocation Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={phdCourseWork}>
                <span>Pre PhD course work</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata001}>
                <span>Ph.D Viva-Voce Notification of Ms. Shweta Paroha</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata002}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Meenu Grover
                  Sharma
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata003}>
                <span>
                  Date extended Notice for Final Admission Pre-PhD 2023-24
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata004}>
                <span>
                  List of selected candidates for the Final Admissions to
                  Pre-PhD 2023-24{" "}
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata005}>
                <span>Notice for final admission Pre-PhD 2023-24 </span>
              </a>
              <img src={newGif} alt="new" />
              <a href={newdata006}>
                <span>Result of PhD Entrance Examination 2023-24</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata007}>
                <span>
                  Notice and List of candidates of Pre-PhD Interview, September
                  2023
                </span>
                <img src={newGif} alt="new" />
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata008}>
                <span>List of Approved Supervisors, September 2023</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata009}>
                <span>Result of MPT entrance exam. 2023</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata010}>
                <span>
                  Notice and Revised list of Candidates for Pre-PhD Entrance
                  test{" "}
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                <span>Special Spot Round for UG courses : Apply Here</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              {/* ****************************************** */}
              <a href={vacantSeatforSpecial}>
                Vacant Seats for Special Spot Round Starting from 25/8/2023
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={specialSpotRoundC}>
                Special spot round for UG courses (Fresh candidates can also
                apply) will start from 25/08/23{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningOfSemester}>
                Reopening of semester fee with late fine
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdVivaVocaNotiMsMeenuG}>
                PhD Viva-Voce Notification of Ms. Meenu Grover Sharma{" "}
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Circular4823}>Circular </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={DocVerificationNotice}>
                1st Notice regarding document verification at University for UG
                Courses{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses
                }
              >
                Notice for extension of the online application deadline for the
                B. Pharm LE and PG courses
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Circular02}> Circular</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={YogaAndFitness}>
                Yoga and Fitness center Admission Notice{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Firstroundonlinecounsellingschedule202}>
                First round online counselling schedule-2023
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                To apply for B. Pharm. (LE) and PG courses: Click here (Last
                date 10/08/23)
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023
                }
              >
                Provisional Merit Lists of Delhi candidates for UG courses-2023{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023
                }
              >
                Provisional Merit Lists of Non-Delhi candidates for UG
                courses-2023{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  AlphabeticalListofcandidateswhohavenotfilledcoursePreferences
                }
              >
                Alphabetical List of candidates who have not filled course
                Preference
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Commoninstructionsforskilltestfornonteachingposts}>
                Common instructions for skill test for non-teaching posts{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NoticeregardingUGcoursespreferencefilling202324}>
                Notice regarding UG courses preference filling 2023-24{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NOTICEofSkillTest}>
                Schedule of Skill Test for recruitment of Non-Teaching Posts
                (Group ‘B’ ‘C’ Ministerial Technical){" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Document570}>Circular</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={EmployementNotice}>Employment Notice</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Prospectus2023forweb}>Prospectus 2023 </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AdvertisementofJRF}>
                “Advertisement for the Post of CCRUM – JRF”{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Advertisement2023241}>
                “Announcement of Admissions (2023-24)”{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdAdmission202324}>Notice for PhD Admission </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={CorrigendumofNTS1}>
                Extension of dates of uploading of Documents for Group B C Post
                and Corrigendum of Junior Stenographer{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
            </div>
          </marquee>

          <Link
            className="see-all-notification-in-marquee-tab"
            to="/notifications"
          >
            All Notifications
          </Link>
        </div>
    </div>
  )
}

export default HomePageFlashNotification
