import React, { useState } from 'react'
import './Approvals.css'
import '../../Naac/Naac.css'

// import data
import aicte1a from './ApprovalsData/aicte1a.pdf'
import aicte1b from './ApprovalsData/aicte1b.pdf'
import aicte1c from './ApprovalsData/aicte1c.pdf'
import aicte1d from './ApprovalsData/aicte1d.pdf'
import aicte1e from './ApprovalsData/aicte1e.pdf'
import aicte1f from './ApprovalsData/aicte1f.pdf'

import pci1a from './ApprovalsData/pci1a.pdf'
import pci1b from './ApprovalsData/pci1b.pdf'
import pci1c from './ApprovalsData/pci1c.pdf'
import pci1d from './ApprovalsData/pci1d.pdf'
import pci1e from './ApprovalsData/pci1e.pdf'
import pci1f from './ApprovalsData/pci1f.pdf'
import pci1g from './ApprovalsData/pci1g.pdf'

import aicte2a from './ApprovalsData/aicte2a.pdf'
import aicte2b from './ApprovalsData/aicte2b.pdf'
import aicte2c from './ApprovalsData/aicte2c.pdf'
import aicte2d from './ApprovalsData/aicte2d.pdf'

// import pci2a from './ApprovalsData/pci2a.pdf'
import pci2a from './ApprovalsData/Decision Letter 2022-23.pdf'
import pci2b from './ApprovalsData/pca2b.pdf'
import pci2c from './ApprovalsData/pca2c.pdf'
import pci2e from './ApprovalsData/pci2e.pdf'
import pci2f from './ApprovalsData/pci2f.pdf'

import pal1 from './ApprovalsData/pal1.pdf'
import pal2 from './ApprovalsData/pal2.pdf'
import pal3 from './ApprovalsData/pal3.pdf'
import pal4 from './ApprovalsData/pal4.pdf'

import ual1a from './ApprovalsData/ual1a.pdf'

import pciaprvletter2324 from './ApprovalsData/DOC-20230607-WA0010..pdf'

function Approvals() {
    

    return (
        <div className='naac-container'>
            <h2 className="dpsru-primary-heading-for-every-page">APPROVALS</h2>

<div className="inner-container">

            <h2 className="dpsru-secondary-heading-for-every-page">DIPSAR APPROVAL LETTERS</h2>



            {/* for first data 1.1 */}
            <h2>AICTE</h2>
          
               
                  
                        <ul>
                            <li><a href={aicte1a}>AICTE APPROVAL LETTER DIPSAR  2022-23</a></li>
                            <li><a href={aicte1b}>AICTE APPROVAL LETTER DIPSAR  2021-22</a></li>
                            <li><a href={aicte1c}>AICTE APPROVAL LETTER DIPSAR  2020-21</a></li>
                            <li><a href={aicte1d}>AICTE APPROVAL LETTER DIPSAR  2019-20</a></li>
                            <li><a href={aicte1e}>AICTE APPROVAL LETTER DIPSAR  2018-19</a></li>
                            <li><a href={aicte1f}>AICTE APPROVAL LETTER DIPSAR  2017-18</a></li>
                        </ul>
                  

                  
            

            {/* for first data 1.2 */}
            <h2>PCI</h2>
           
                        <ul>

                            <li><a href={pci1a}>PCI APPROVAL LETTER DIPSAR 2022-23</a></li>
                            <li><a href={pci1b}>PCI APPROVAL LETTER DIPSAR 2021-22</a></li>
                            <li><a href={pci1c}>PCI APPROVAL LETTER DIPSAR 2020-21</a></li>
                            <li><a href={pci1d}>PCI APPROVAL LETTER DIPSAR 2019-20</a></li>
                            <li><a href={pci1e}>PCI APPROVAL LETTER DIPSAR 2018-19</a></li>
                            <li><a href={pci1f}>PCI APPROVAL LETTER DIPSAR 2017-18</a></li>
                            <li><a href={pci1g}>PCI DIPSAR DIPLOMA</a></li>
                        </ul>
                
</div>

<br /><br />

<div className="inner-container">
            <h2 className="dpsru-secondary-heading-for-every-page">DPSRU APPROVAL LETTERS</h2>

            {/* for first data 2.1 */}
            <h2>AICTE</h2>
           
                        <ul>
                            <li><a href={aicte2a}>AICTE APPROVAL LETTER SPS  2021-22</a></li>
                            <li><a href={aicte2b}>AICTE APPROVAL LETTER SPS  2020-21</a></li>
                            <li><a href={aicte2c}>AICTE APPROVAL LETTER SPS  2019-20</a></li>
                            <li><a href={aicte2d}>AICTE APPROVAL LETTER SPS  2018-19</a></li>

                        </ul>
                 

            {/* for first data 2.2 */}
            <h2>PCI</h2>
           
                        <ul>
                            <li><a href={pciaprvletter2324}>PCI APPROVAL LETTER SPS 2023-24</a></li>

                            <li><a href={pci2a}>PCI APPROVAL LETTER SPS 2022-23</a></li>
                            <li><a href={pci2b}>PCI APPROVAL LETTER SPS 2021-22</a></li>
                            <li><a href={pci2c}>PCI APPROVAL LETTER SPS 2020-21</a></li>
                            <li><a >PCI APPROVAL LETTER SPS 2019-20</a></li>
                            <li><a href={pci2e}>PCI APPROVAL LETTER SPS 2018-19</a></li>
                            <li><a href={pci2f}>PCI APPROVAL LETTER SPS 2017-18</a></li>

                        </ul>
               

                   
</div>

<br /><br />
<div className="inner-container">

            <h2 className="dpsru-secondary-heading-for-every-page">PHYSIOTHERAPY APPROVAL LETTERS</h2>

            
                        <ul>
                            <li><a href={pal1}>APPROVAL LETTER 2022-23</a></li>
                            <li><a href={pal2}>APPROVAL LETTER 2021-22</a></li>
                            <li><a href={pal3}>APPROVAL LETTER 2020-21</a></li>
                            <li><a href={pal4}>APPROVAL LETTER 2019-20</a></li>

                        </ul>
                
</div>
<br /><br />
<div className="inner-container">


            <h2 className="dpsru-secondary-heading-for-every-page">UGC  APPROVAL  LETTER</h2>

                        <ul>
                            <li>
                                <a href={ual1a}>APPROVAL LETTER</a>
                            </li>

                        </ul>
                    </div>
                    

                    
        </div>
    )
}

export default Approvals