import React, { useState } from "react";
import "./GoverningActs.css";

import DPSRUACT from "./Governing Acts Data/DPSRUACT.pdf";
import statutes from "./Governing Acts Data/statutes.pdf";
import SecondStatuitesofDPSRU from "./Governing Acts Data/SecondStatuitesofDPSRU.pdf";
import FirstOrdinances from "./Governing Acts Data/FirstOrdinances.pdf";
import SOPACADEMICDPSRU from "./Governing Acts Data/SOPACADEMICDPSRU.pdf";
import DPSRUACTc6ada29821cec1b77061 from "./Governing Acts Data/Standard Operating Procedure of DPSRU (2).pdf";

const GoverningActs = () => {
  const [sop, setSop] = useState(false);

  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Governing Acts</h2>

      <div className="mision-vission-objectives-quality-each-sec-container">
        <h2 className="governing-act-vision-mision-second-heading">
          These are links to some of the Important Documents related to the Acts
          affecting the Institute
        </h2>

        <ul className="governing-acts-vision-mision-data-container-ul">
          <li>
            <a href={DPSRUACT} target="_blank" rel="noreferrer">
              DPSRU Act
            </a>
          </li>
          <li>
            <a href={statutes} target="_blank" rel="noreferrer">
              First Statutes
            </a>
          </li>
          <li>
            <a href={SecondStatuitesofDPSRU}>Second Statutes</a>
          </li>
          <li>
            <a href={FirstOrdinances} target="_blank" rel="noreferrer">
              First Ordinances
            </a>
          </li>
          <li>
            <button onClick={() => setSop(!sop)}>
              {" "}
              Standard Operating Procedures
            </button>
            {sop ? (
              <ul className="governing-acts-vision-mision-data-container-ul">
                <li>
                  <a href={SOPACADEMICDPSRU} target="_blank" rel="noreferrer">
                  SOP (Academic) 
                  </a>
                </li>
                <li>
                  <a href={DPSRUACTc6ada29821cec1b77061} target="_blank" rel="noreferrer">
                  SOP (Administrations) 
                  </a>
                </li>
              </ul>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GoverningActs;
