import React from 'react'
import './Approval.css'

import aict01 from './Letter-Of-Approval-2018-19.pdf'
import aict02 from './AICTE-EOA-Letter-2019-20.pdf'
import aict03 from './EOA_Report_2020-21.pdf'
import aict04 from './EOA-Report-21-22.pdf'


function Approval() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">

                AICTE Approvals
            </h2>
            <div className="inner-container">
                <ul>
                    <li >
                        <a href={aict01} target="_blank" rel="noreferrer">
                            Letter Of Approval 2018-19
                        </a>
                    </li>
                    <li >
                        <a href={aict02} target="_blank" rel="noreferrer">
                            AICTE EOA Letter 2019-20
                        </a>
                    </li>
                    <li >
                        <a href={aict03} target="_blank" rel="noreferrer">
                            EOA Report 2020-21
                        </a>
                    </li>
                    <li >
                        <a href={aict04} target="_blank" rel="noreferrer">
                            EOA Report 2021-22
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Approval;