import React from 'react'

import atal from './ARI-U-0853.pdf'

function AtalRanking() {
    return (
        <div className="nirf-component">
            <h2 className="dpsru-primary-heading-for-every-page">
               Atal Ranking
            </h2>

            <div className="atalRanking-pdf-container" style={{textAlign:'center'}}>
                <a href={atal} style={{fontWeight:'bold',fontSize:'1.3rem'}} >Click here to download supporting file</a>

            </div>


        </div>
    )
}

export default AtalRanking