import React from "react";

import MbaPm from "./DateSheet Data 2021/Date-Sheet-of-MBAMPHMHM-2021.pdf";
import MPharm2021 from "./DateSheet Data 2021/M.Pharm-2021-1.pdf";
import DPharm2021 from "./DateSheet Data 2021/Date-Sheet-of-D.Pharm-2021.pdf";
import BscHons from "./DateSheet Data 2021/All-Date-Sheet-of-B.-Sc.-Hons.-2021.pdf";
import BPT2021 from "./DateSheet Data 2021/All-Date-Sheet-of-BPT-2021.pdf";
import BphrmDatesheet from "./DateSheet Data 2021/B.PHARM-DATESHEET-2021-1.pdf";
import DMYS from "./DateSheet Data 2021/Date-Sheet-of-DMYS-2021.pdf";
import PrePHD from "./DateSheet Data 2021/Date-Sheet-of-Ph.D-2021.pdf";

const DateSheet2021 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Date Sheet - 2021
      </h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={MbaPm}>MBA-PM</a>
          </li>
          <li>
            <a href={MbaPm}>MHM</a>
          </li>
          <li>
            <a href={MbaPm}>MPH</a>
          </li>
          <li>
            <a href={MPharm2021}>M. Pharm</a>
          </li>
          <li>
            <a href={DPharm2021}>D. Pharm</a>
          </li>
          <li>
            <a href={BscHons}>B. Sc. (Hons.) Sports Science</a>
          </li>
          <li>
            <a href={BPT2021}>BPT</a>
          </li>
          <li>
            <a href={BphrmDatesheet}>B. Pharm</a>
          </li>
          <li>
            <a href={DMYS}>DMYS</a>
          </li>
          <li>
            <a href={PrePHD}>Pre.-Ph.D</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DateSheet2021;
