import React, { useState } from 'react'
import './Wcsc.css'
import Carousel from 'nuka-carousel';
import { NavLink } from 'react-router-dom';

import wcscheading from './WcscFiles/wcsc-heading-img.jpg'
import aboutwcsc1 from './WcscFiles/about wcsc1.pdf'
import aboutwcsc2 from './WcscFiles/about wcsc2.pdf'
import BwcSyllabus from './WcscFiles/Bwc-syllabus.pdf'
import SayaSyllabus from './WcscFiles/Saya-syllabus.pdf'
import MmSyllabus from './WcscFiles/MmSyllabus.pdf'


import DrMGupta from './WcscFiles/IMG_8991-1-2048x1365.6e1ce8ad25d53bf0809c.jpg'
import AKUppal from './WcscFiles/AKUppal.jpg'
import MeeraS from './WcscFiles/MeeraS.jpg'
import MeghnaS from './WcscFiles/MeghnaS.jpg'

import wcscact1 from './WcscFiles/wcsc-act-1.jpg'
import wcscAct2 from './WcscFiles/wcscAct2.jpg'
import wcscAct3 from './WcscFiles/wcscAct3.jpg'
import wcscAct4 from './WcscFiles/wcscAct4.jpg'
import wcscAct5 from './WcscFiles/wcscAct5.jpg'
import wcscAct6 from './WcscFiles/wcscAct6.jpg'
import wcscAct7 from './WcscFiles/wcscAct7.jpg'
import wcscAct8 from './WcscFiles/wcscAct8.jpg'
import wcscAct9 from './WcscFiles/wcscAct9.jpg'
import wcscAct10 from './WcscFiles/wcscAct10.jpg'
import wcscAct11 from './WcscFiles/wcscAct11.jpg'
import wcscAct12 from './WcscFiles/wcscAct12.jpg'
import wcscAct13 from './WcscFiles/wcscAct13.jpg'
import wcscAct14 from './WcscFiles/wcscAct14.jpg'
import wcscAct15 from './WcscFiles/wcscAct15.jpg'
import wcscAct16 from './WcscFiles/wcscAct16.jpg'
import wcscAct17 from './WcscFiles/wcscAct17.jpg'
import wcscAct18 from './WcscFiles/wcscAct18.jpg'

// activities
import wcsc1a from './WcscFiles/wcsc1a.pdf'
import wcsc1b from './WcscFiles/wcsc1b.pdf'
import wcsc1c from './WcscFiles/wcsc1c.pdf'
import wcsc1d from './WcscFiles/wcsc1d.pdf'
import wcsc1e from './WcscFiles/wcsc1e.pdf'
import wcsc1f from './WcscFiles/wcsc1f.pdf'
import wcsc1g from './WcscFiles/wcsc1g.pdf'
import wcsc1h from './WcscFiles/wcsc1h.pdf'

//second
import wcsc2a from './WcscFiles/wcsc2a.pdf'
import wcsc2b from './WcscFiles/wcsc2b.pdf'
import wcsc2c from './WcscFiles/wcsc2c.pdf'
import wcsc2d from './WcscFiles/wcsc2d.pdf'
import wcsc2e from './WcscFiles/wcsc2e.pdf'
import wcsc2f from './WcscFiles/wcsc2f.pdf'
import wcsc2g from './WcscFiles/wcsc2g.pdf'
import wcsc2h from './WcscFiles/wcsc2h.pdf'
import wcsc2i from './WcscFiles/wcsc2i.pdf'
import wcsc2j from './WcscFiles/wcsc2j.pdf'
import wcsc2k from './WcscFiles/wcsc2k.pdf'

//Our Activities
import OAImg1 from './WcscFiles/OAImg1.jpg'
import OAImg2 from './WcscFiles/OAImg2.jpg'

import OAImg3 from './WcscFiles/OAImg3.jpg'
import OAImg4 from './WcscFiles/OAImg4.jpg'

import OAImg5 from './WcscFiles/OAImg5.jpg'

import wcscCovid from './WcscFiles/wcsc-covid.pdf'





function Wcsc() {
    const [status1, setStatus1] = useState(false)
    const [status2, setStatus2] = useState(false)
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">WORLD CLASS SKILL CENTER</h2>
            <h2 className='wcsc-text-heading-for-responsive'>DPSRU WCSC Wing - Affiliated by Government of NCT of Delhi</h2>
            <div className="img-box">
                <img src={wcscheading} alt="img" />
            </div>

            <section>
                <h3 className="dpsru-secondary-heading-for-every-page">About WCSC</h3>

                <ul className='about-ul'>
                    <li>WCSC JOB ASPECTS AND PREVIOUS YEAR EVENTS & ACTIVITIES : <a href={aboutwcsc1}>Click here</a></li>
                    <li>GLIMPSES OF DPSRU-WCSC CERTIFICATE COURSE ACADEMIC SESSION 2019-21 : <a href={aboutwcsc2}>Click here</a></li>
                </ul>

                <p className="wcsc-paragraph">The Government of NCT, Delhi initiated the development of “World Class Skill Centre” (WCSC) in Delhi to address the shortage of skilled manpower. The setting-up of this World Class Skill Centre in Delhi Pharmaceutical Sciences and Research University (DPSRU), New Delhi as a nodal centre under the sector<span style={{ fontWeight: 'bold' }}>“Health and Wellness” </span>  fulfilling its objective and create the skilled people that is the valued assets and sustain the economic growth and social development of the country. <br /><br />

                    DPSRU-WCSC centre provided better levels of skills that might be adjusted more effectively to the challenges and opportunities of globalization in terms of various types of certificates courses that are multi-disciplinary course in their respective field.</p>
            </section>

            <section>
                <h3 className="dpsru-secondary-heading-for-every-page">Beauty Wellness Consultant Programme</h3>
                <p>This program is aimed at training candidates in the “Beauty and Wellness” Sector/Industry.</p>
                <h4 className='wcsc-inner-heading'>Scope</h4>
                <p style={{ fontWeight: 'bold', margin: "1.4% 0" }}>After completing this training, course aspirants will be able to:</p>


                <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                    <li>Provide technical know-how about interventions related to aesthetics and beauty wellness.</li>
                    <li>Provide preliminary technical know-how about cosmetic products.</li>
                    <li>Beauty Wellness Consultant and Cosmetologists.</li>
                    <li>Assist in Sales and Management of products.</li>
                    <li>Assist the client to make purchase decisions of products related to Skin care, Hair care and Make-up.</li>
                    <li>Know how to manage the Front desk in Clinics Salon.</li>
                    <li>Maintain health and safety of end users.</li>
                </ul>

                <h4 className='wcsc-inner-heading'>Find job in</h4>
                <p style={{ fontWeight: 'bold', margin: "1.4% 0" }}>Cosmetic Industry</p>
                <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                    <li>Product and Formulation management</li>
                    <li>Products Sales and Marketing</li>
                </ul>
                <h4 className='wcsc-inner-heading-long'>Beauty and Wellness Clinics</h4>

                <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                    <li>Cosmetologist</li>
                    <li>Aesthetician</li>
                    <li>Beauty products sales merchandize</li>
                    <li>Beauty business Start Ups/Salon Owner</li>
                    <li>Salon/Clinics Units as a Counselor</li>
                    <li>Beauty Manager</li>
                    <li>Front Desk Management</li>
                    <li>Beauty Trainer</li>
                    <li>Beauty Therapist</li>
                    <li>Trade Technician</li>
                    <li>Salon/skin clinic Manager</li>
                    <li>Researcher: Assistant</li>
                    <li>Make-up Artist</li>
                </ul>

                <h3 className="dpsru-secondary-heading-for-every-page">Medicine Management Programme</h3>
                <p style={{ fontWeight: 'bold', margin: "2% 0" }}>This course provides the knowledge and an understanding of what medicines are, what governs their use, legal responsibilities when dealing with drugs for particular disease, Regulations and Guidance for Medicine’s Management and Handling , Safe Administration of Medicines and their Safeguarding.</p>

                <h4 className='wcsc-inner-heading'>Scope</h4>
                <p style={{ fontWeight: 'bold', margin: "2% 0" }}>After completing this training, course aspirants will be able to:</p>
            </section>

            <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>

                <li>Introduction to medicine, governing laws for their distribution</li>
                <li>Storage and dispensing</li>
                <li>Documentation and record keeping</li>
                <li>Administration of medication</li>
                <li>Reducing and eliminating medication errors</li>
                <li>Accountability and responsibility</li>
                <li>Legislation and requirements</li>
                <li>Regulations and Guidance for Medicine’s Management</li>
                <li>Medicine Handling in Care Settings</li>
                <li>Safe Administration of Medicines</li>
                <li>Safeguarding and Medicines Management</li>
                <li>Critical Conditions and Situations</li>
            </ul>

            <h4 className='wcsc-inner-heading'>FIND JOBS IN</h4>

            <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                <li>Assist to run the chemist shops, whole sale drug and medicine counters</li>
                <li>Assistant of even private consultant doctors who supplied their own prepared medicines</li>
                <li>Jan Aushadi outlets and Karunya Pharmacy Stores</li>
                <li>Vocational trainer in academic organizations if find the teaching</li>
                <li>Highly capable candidates may get job in pharmaceutical industries located in India as well as</li>
                <li>Other lab based jobs are laboratory-assistant, and Lab associate, etc</li>
                <li>Assist in medicine management in Pharmaceutical industry.</li>
                <li>Assist the doctors and get opportunity in Hospital for patient care and medicine management as Physician, Assistant, Hospital assistant</li>
                <li>Assist in Pathological lab and clinic based jobs</li>
                <li>Assist in clinics, particularly medical assistants to complete both administrative and clinical duties</li>
                <li>Front desk manager</li>
            </ul>

            {/* SPORTS ASSESSMENT & YOGA APPLICATION PROGRAMME */}
            <h3 className="dpsru-secondary-heading-for-every-page">SPORTS ASSESSMENT & YOGA APPLICATION PROGRAMME</h3>
            <p style={{ fontWeight: 'bold', margin: "2% 0" }}>This program is aimed at training candidates in the “Sports/ Health Fitness” Sector/Industry and aims at building the following key competencies amongst the learner.</p>
            <h4 className='wcsc-inner-heading'>SCOPE</h4>

            <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                <li>Provide immediate employability option to Indian youth who do not pursue university education and want to enter the work</li>
                <li>They will learn a new and practical approach of applying yoga in sports.</li>
                <li>They will understand exercise as a medicine for In which the trainee will not only execute the exercise and yoga asanas but understand them as a treatment for upliftment of the health of the population with specific focus on athletes.</li>
                <li>The student will be developing efficiency in fitness as management, learning to monitor the growth of the</li>
                <li>They will have the better understanding for recording the data and compare the assessment reports for the improvement of the athletes.</li>
                <li>The student will be able to set an example by setting up new ventures for</li>
                <li>Understanding about injuries and rehabilitation</li>
                <li>Awareness of the physiological responses to yogic</li>
                <li>The students will adopt knowledge and practices to lead a healthy life and promote and maintain healthy practices and assist the practices of preventive medicine.</li>
                <li>The knowledge and skill developed through this course will help the students to create a strong foundation to engage human subject of all ages, genders and abilities.</li>
            </ul>

            <h4 className='wcsc-inner-heading'>FIND JOBS IN</h4>

            <ul style={{ lineHeight: '30px', fontSize: '1.2rem' }}>
                <li>Assisting gym trainer, yoga trainer</li>
                <li>Assisting fitness and wellness expert in health club</li>
                <li>In school for games and sports trainer</li>
                <li>Assisting in setting up the health and wellness centre</li>
                <li>Sports and conditioning coach</li>
                <li>Yoga trainer</li>
                <li>Recorder</li>
                <li>Match Scorer</li>
                <li>Sports and fitness test analyser</li>
            </ul>

            <h3 className="dpsru-secondary-heading-for-every-page">Certificate Courses </h3>

            <h4 className='wcsc-beauty-wellness-responsive-h4'>Beauty Wellness Consultant : <a href={BwcSyllabus}>Syllabus </a></h4>
            <ul style={{ lineHeight: '26px', fontSize: '1.13rem' }}>
                <li>Total No. of Seats: 40</li>
                <li>Duration of Course: 1 year + 400 hours internship</li>
            </ul>

            <h4 className='wcsc-beauty-wellness-responsive-h4'>Sports Assessment and Yoga Application : <a href={SayaSyllabus}>Syllabus </a></h4>
            <ul style={{ lineHeight: '26px', fontSize: '1.13rem' }}>
                <li>Total No. of Seats: 40</li>
                <li>Duration of Course: 1 year + 400 hours internship</li>
            </ul>

            <h4 className='wcsc-beauty-wellness-responsive-h4'>Medicine Management : <a href={MmSyllabus}>Syllabus </a></h4>
            <ul style={{ lineHeight: '26px', fontSize: '1.13rem' }}>
                <li>Total No. of Seats: 40</li>
                <li>Duration of Course: 1 year + 400 hours internship</li>
            </ul>

            <h2 className="head-heading">HEAD</h2>


            {/* each faculty Box */}

            <NavLink
      to="/Dr-Madhu-gupta"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={DrMGupta} alt="faculty-img" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Madhu Gupta</h3>
                    <h4 className="faculty-position">COO, DPSRU-WCSC</h4>
                </div>
            </div> </NavLink> <hr />

            <h2 className="head-heading">GUEST FACULTY DETAILS</h2>

            {/* each faculty Box */}
            <NavLink
      to="/dr-ak-uppal"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={AKUppal} alt="faculty-img" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. A.K. Uppal</h3>
                    <h4 className="faculty-position">Sports Assessment & Yoga Application, DPSRU-WCSC</h4>
                </div>
            </div> </NavLink><hr />

            {/* each faculty Box */}

            <NavLink
      to="/dr-meera-sood"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={MeeraS} alt="faculty-img" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Dr. Meera Sood</h3>
                    <h4 className="faculty-position">Sports Assessment & Yoga Application, DPSRU-WCSC</h4>
                </div>
            </div> </NavLink><hr />

            <h2 className="head-heading">CONTRACTUAL FACULTY (FULL TIME)</h2>

            {/* each faculty Box */}
            <NavLink
      to="/ms-meghna-singh"
      className="each-faculty-box-navlink-container"
    >
            <div className="each-faculty-box">
                <div className="each-faculty-img">
                    <img src={MeghnaS} alt="faculty-img" />
                </div>
                <div className="each-faculty-name-and-post">
                    <h3 className="faculty-name">Ms. Meghna Singh</h3>
                    <h4 className="faculty-position">Medicine Management, DPSRU-WCSC</h4>
                </div>
            </div> </NavLink> <hr />

            <h3 className="dpsru-secondary-heading-for-every-page">EVENTS AND ACTIVITY WCSC</h3>

            {/* Photo Album */}
            <div className="wcsc-event-and-activity-img-gallery">

                <Carousel className='wcsc-gallary-carousel' autoplay='true' autoplayReverse='true' animation='zoom' pauseOnHover='true' wrapAround={true} autoplayInterval={5000}>

                    <img src={wcscact1} alt="logo" />
                    <img src={wcscAct2} alt="logo" />
                    <img src={wcscAct3} alt="logo" />
                    <img src={wcscAct4} alt="logo" />
                    <img src={wcscAct5} alt="logo" />
                    <img src={wcscAct6} alt="logo" />
                    <img src={wcscAct7} alt="logo" />
                    <img src={wcscAct8} alt="logo" />
                    <img src={wcscAct9} alt="logo" />
                    <img src={wcscAct10} alt="logo" />
                    <img src={wcscAct11} alt="logo" />
                    <img src={wcscAct12} alt="logo" />
                    <img src={wcscAct13} alt="logo" />
                    <img src={wcscAct14} alt="logo" />
                    <img src={wcscAct15} alt="logo" />
                    <img src={wcscAct16} alt="logo" />
                    <img src={wcscAct17} alt="logo" />
                    <img src={wcscAct18} alt="logo" />
                </Carousel>
            </div>
            {/* Photo Album closing */}

            <h2 className="head-heading">ACTIVITIES</h2>

            <button className='naac-button' onClick={() => setStatus1(!status1)}>2019-20</button>
            {
                status1 ?
                    <div className="inner-container">
                        <ul>
                            <li><a href={wcsc1a}>BOOSTING YOUR STAMINA</a></li>
                            <li><a href={wcsc1b}>INNOVATIVE APPROACH FOR BEAUTY THERAPY</a></li>
                            <li><a href={wcsc1c}>PHASE I ORIENTATION PROGRAME</a></li>
                            <li><a href={wcsc1d}>PHASE II ORIENTATION PROGRAME</a></li>
                            <li><a href={wcsc1e}>PRACTICAL DEMONSTRATION HANDS ON TRAINING IN HAIR CARE,HAIR STYLING AND HAIR CUT</a></li>
                            <li><a href={wcsc1f}>ROLE OF HERBAL CONSTITUENTS IN THE CARE AND CURE OF SKIN,HAIR AND BODY</a></li>
                            <li><a href={wcsc1g}>WORKSHOP ON ACCOUNTANCY STATISTICS AND EVENT MANAGEMENT </a></li>
                            <li><a href={wcsc1h}>WORKSHOP ON GRAPHIC DESIGNING TOOLS USE AND APPLICATION</a></li>
                        </ul>
                    </div> : null
            }

            {/* second */}
            <button className='naac-button' onClick={() => setStatus2(!status2)}>2021-22</button>
            {
                status2 ?
                    <div className="inner-container">
                        <h3 className="toggle-heading-wcsc">SPORTS ASSESSMENT AND YOGA APPLICATION</h3>
                        <ul>
                            <li><a href={wcsc2a}>EXPERT TALK ON A DESIGN FOR TRAINING SCHEDULE FOR BEGINNERS</a></li>
                            <li><a href={wcsc2b}>EXTENSION ACTIVITY DECATHLON SAYA</a></li>
                            <li><a href={wcsc2c}>PARENT TEACHER MEETING SAYA</a></li>
                            <li><a href={wcsc2d}>WORKSHOP ON AN OVERVIEW WHERE THE SCIENCE IS BEHIND THE RUNNING</a></li>
                        </ul>
                        <h3 className="toggle-heading-wcsc">MEDICINE MANAGEMENT</h3>
                        <ul>
                            <li><a href={wcsc2e}>CARDIAC MEDICICAL CHECKUP CAMP</a></li>
                            <li><a href={wcsc2f}>EXPERT TALK ON MEDICINE LOGISTICS</a></li>
                            <li><a href={wcsc2g}>EXPERT TALK ON MEDICINE MANAGEMENT ROLE IN HEALTH CARE SYSTEM</a></li>
                            <li><a href={wcsc2h}>EXPERT TALK ON VACCINE LOGISTICS</a></li>
                            <li><a href={wcsc2i}>EXTENSION ACTIVITY  MEDICAL SHOPS</a></li>
                            <li><a href={wcsc2j}>LECTURE SERIES ON HEALTH AND WELLNESS</a></li>
                            <li><a href={wcsc2k}>PARENT TEACHER MEETING MEDICINE MANAGEMENT</a></li>
                        </ul>
                    </div> : null
            }
            <section className="our-activity-wcsc-section">
                <h2 className="head-heading">OUR ACTIVITIES</h2>

                <div className="our-activities-img-container-wcsc">
                    <div className="our-activity-img-container-wcsc-each">
                        <img src={OAImg1} alt="Our activity imgage" />
                        <p style={{ textAlign: 'center', fontSize: '.7rem' }}>“Practical demonstration/ hands on training in hair care, hair styling and hair cut”</p>
                    </div>

                    <div className="our-activity-img-container-wcsc-each">
                        <img src={OAImg2} alt="Our activity imgage" />
                        <p style={{ textAlign: 'center', fontSize: '.7rem' }}>haircare workshop</p>
                    </div>
                </div>
                <h3 className="head-heading">INFRASTRUCTURE & FACILITIES - AN OVERVIEW</h3>

                <div className="our-activities-img-container-wcsc">
                    <div className="our-activity-img-container-wcsc-each">
                        <img src={OAImg3} alt="Our activity imgage" />
                    </div>

                    <div className="our-activity-img-container-wcsc-each">
                        <img src={OAImg4} alt="Our activity imgage" />
                    </div>
                </div>

                <h3 className="head-heading">OUR BATCHES</h3>

                <div className="our-activities-img-container-wcsc">
                        <img src={OAImg5} alt="Our activity imgage" style={{width:"50%",margin:'auto'}} />
                </div>
            </section>

           <div className="wcsc-covid-container">
           <a href={wcscCovid}>INITIATIVES to fight COVID by World Class Skill Center (WCSC)</a>
           </div>

        </div>
    )
}

export default Wcsc