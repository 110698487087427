import React from "react";

 import mbaPm from '../Result - 2022/Result 2022 Data/MBA-PM-2022.pdf'
 import mhm from '../Result - 2022/Result 2022 Data/MHM-2022.pdf'
 import mpm from '../Result - 2022/Result 2022 Data/MPH-2022.pdf'
 import mscAipm from '../Result - 2022/Result 2022 Data/M.Sc_.-AIPM-2022.pdf'
 import mbaPet from '../Result - 2022/Result 2022 Data/MBA-PET-2022.pdf'
 import bPharmAyrveda from '../Result - 2022/Result 2022 Data/B.Pharm-Ayurveda-2022.pdf'
 import mha from '../Result - 2022/Result 2022 Data/MHA-2022.pdf'
 import mPharm2022 from '../Result - 2022/Result 2022 Data/M.Pharm-2022.pdf'
 import dPharm2022 from '../Result - 2022/Result 2022 Data/D.Pharm2022.pdf'
 import bmlt from '../Result - 2022/Result 2022 Data/BMLT-2022.pdf'
 import dmlt from '../Result - 2022/Result 2022 Data/DMLT-2022.pdf'
 import bscHonsss from '../Result - 2022/Result 2022 Data/All-Main-Semester-Result-of-B.-Sc.-Hons.-Sports-Science-2022.pdf'
 import bpt from '../Result - 2022/Result 2022 Data/All-Main-Semester-Result-of-BPT-2022.pdf'
 import bbaCh from '../Result - 2022/Result 2022 Data/BBA-CH-2022.pdf'
 import bPharmSPS from '../Result - 2022/Result 2022 Data/B.Pharm-SPS-2022.pdf'
 import bPharmaDipsar from '../Result - 2022/Result 2022 Data/B.Pharm-Dipsar-2022.pdf'
 import MscSS from '../Result - 2022/Result 2022 Data/M.Sc_.-Sport-Science-2022.pdf'
 import mptSM from '../Result - 2022/Result 2022 Data/MPT-Sports-Medicine-2022.pdf'
 import wcsc from '../Result - 2022/Result 2022 Data/WCSC-2022.pdf'

const Result2022 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Examination Results - 2022</h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={mbaPm}>
            MBA-PM
            </a>
          </li>
          <li>
            <a href={mhm}>
            MHM
            </a>
          </li>
          <li>
            <a href={mpm}>
            MPH
            </a>
          </li>
          <li>
            <a href={mscAipm}>
            M.Sc. AIPM
            </a>
          </li>\
          <li>
            <a href={mbaPet}>
            MBA-PET
            </a>
          </li>
          <li>
            <a href={bPharmAyrveda}>
            B. Pharm Ayurveda
            </a>
          </li>
          <li>
            <a href={mha}>
            MHA
            </a>
          </li>
          <li>
            <a href={mPharm2022}>
            M. Pharm
            </a>
          </li>
          <li>
            <a href={dPharm2022}>
            D. Pharm
            </a>
          </li>
          <li>
            <a href={bmlt}>
            BMLT
            </a>
          </li>
          <li>
            <a href={dmlt}>
            DMLT
            </a>
          </li>
          <li>
            <a href={bscHonsss}>
            B. Sc. (Hons.) Sports Science
            </a>
          </li>
          <li>
            <a href={bpt}>
            BPT
            </a>
          </li>
          <li>
            <a href={bbaCh}>
            BBA-CH
            </a>
          </li>
          <li>
            <a href={bPharmSPS}>
            B. Pharm 
            </a>
          </li>
          <li>
            <a href={bPharmaDipsar}>
            B. Pharm (DIPSAR)
            </a>
          </li>
          <li>
            <a href={MscSS}>
            M.Sc. Sport Science
            </a>
          </li>
          <li>
            <a href={mptSM}>
            MPT Sports Medicine 
            </a>
          </li>
          <li>
            <a href={wcsc}>
            WCSC
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Result2022;
