import React, { useState } from "react";

import profimg from "./IMG_0045-scaled-e1686560994297-150x150.jpg";

const MsAmandeepKaur = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Ms. Amandeep Kaur
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Sports Science 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a href="mailto:amandeep.kaur@assrm.edu.in">
              {" "}
              amandeep.kaur@assrm.edu.in 
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Pursuing Doctor Of Philosophy in Physical Education from Theerthankar Mahveer University, Moradabad</li><li>Qualified UGC NET, June, 2019</li><li>&nbsp;Master of Physical Education (M.P.Ed), from Amity University, Noida in 2019 (Gold Medallist)</li><li>Bachelor of Physical Education (B.P.Ed), from Vikramajit Singh Sanatan Dharma College, Kanpur in 2015</li><li>Master of Commerce (M.Com) from from Vikramajit Singh Sanatan Dharma College, Kanpur in 2014</li><li>Bachelor of Commerce (B.Com), Acharya Narendra Dev Nagar Nigam Mahila Mahavidyalaya, Kanpur, in 2012</li></ul>
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Academic Area:</b> Sports Kinesiology and Biomechanics, Exercise and Sports Physiology, Sports and Exercise Psychology, Health Education, Fitness, Wellness and Aerobics, Performance Analysis and Sports Technology, Sports Administration
      </p>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Sports Interest:</b> Athletics, Basketball, Volleyball, Swimming, Gym Management
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">
      <ul><li>Presented a research paper on “A Comparative Study on Sports Competition Anxiety of National And Inter-University Level Female Javelin, 2023&nbsp;</li><li>Published a research paper on “A biomechanical relationship of the centre of gravity of female javelin thrower and javelin” in the Journal of Physical Education, Recreation and Sports in Sciences and Technology, ISSN: 0975-7414.</li><li>A biomechanical relationship of the centre of gravity of female javelin thrower and javelin in 2023</li><li>A Comparative Study on Sports Competition Anxiety of National and Inter-University Level Female Javelin Throwers in 2023</li><li>A Comparison of selected fitness and kinematic variables between shot-putters of Delhi region, ISSN 0973-3914 year, 2019</li></ul>
      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Conferences
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
       <ul><li>Attended an National Conference on “Innovative Scientific Culture in Physical Education, Sports and Yogic Sciences date 5th and 6th April, 2023 held at Banaras Hindu University, Banaras.</li><li>Attended an International Conference Physical Education &amp; Exercise Sciences dated 22nd to 24th&nbsp; March, 2023 held at Punjabi University, Patiala.</li><li>Attended Unani Day 2023 &amp; International Conference on MsAmandeepKaur“Unani Medicine for Public Health” dated 10th and 11th Feb, 2023, New Delhi.</li><li>Paper Presented in International Conference on Physical Education and Exercise Sciences. 2023</li><li>Paper Presented in National Conference on innovative Scientic Culture in Physical Education, Sports and Yogic Sciences. 2023</li><li>Attended Unani Day 2023 and International Conference on Unani Medicine, 2023</li><li>Attended Sports India Conference, 2022 organized by PEFI and Sports India Foundation, 2022</li><li>Participated FDP on Holistic Approaches Towards, Disease, treatment: Regulation and Pharmacological Strategies, 2022</li><li>National Conference on Effects of Doping organized by PEFI and NADA, 2018</li><li>Attended Conference on Psychology at Deshbandhu College, 2018</li><li>Media Volunteer in International FIFA Conference in 2018 at Amity University</li><li>Attended Workshop on Instrument Operation of Doping held at Amity University. Volunteered in Special Olympic Bharat Workshop of Special Need Children</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <ul><li>Volunteered in Special Olympic Bharat Workshop of Special Need Child, in 2018 Trainer for KVS Train the trainer Program 2019-2020</li><li>Speaker for Certified Course on Gym Management and Scientific Basis of Training in 2020</li><li>Facility Volunteer in the COVID awareness program (social service program ), 2021.</li></ul>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         <ul><li>Statistical Editor of “International Journal of Health Exercise and Sports Science” Peer reviewed Journal</li><li>University Gold Medalist in Masters’ in Physical education 2019</li><li>Best Organizational Abilities Award at Amity University, in 2019</li><li>Posse’s leadership skill and core committee head in Sangathan, 2018 at Amity University, Noida</li><li>Gold Medal in Amity Spardha in Volleyball in 2018</li><li>Coordinative Head at Sangathan, 2017 in Basketball</li><li>State Team Coach of Kanpur- twice in 2013</li><li>Professional Development Program on Companies Bill, 2012-Catalyst to sustainable growth of India, in 2013</li><li>Professional Development Program on Amendment in MCA-21 &amp; LLP as a Business Entity and its Integration with MCA-21, in 2012</li><li>University Basketball and Volleyball Player 2010-2014</li><li>Silver medalist at National Level Athlete meets in year 2009</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default MsAmandeepKaur;
