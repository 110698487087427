import React from 'react'
import './EDownload.css'
import { Link } from 'react-router-dom'

// import OT from './eDownloadData/OT.pdf'
import OT from './eDownloadData/Application for Official Transcript.pdf'
import PDC from './eDownloadData/Application for Provisional Degree Certificates.pdf'
// import PDC from './eDownloadData/PDC.pdf'
import MCRE from './eDownloadData/MCRE.pdf'
import CoCe from './eDownloadData/CoCe.pdf'
import DuCE from './eDownloadData/DuCE.pdf'
import ROM from './eDownloadData/ROM.pdf'
// import MigrationC from './eDownloadData/Application-Format-for-Migration-Certificates.pdf'
import MigrationC from './eDownloadData/Application for Migration Certificates.pdf'
import instructionforcertificate from './eDownloadData/Instruction for Certificates updated.pdf'
import applicationformigrationcertiicateupdated from './eDownloadData/Application for Migration Certificates updated.pdf'
import applicationforofficialtranscriptupdated from './eDownloadData/Application for Official Transcript updated.pdf'
import applicationforprovisionaldegreeecertupda from './eDownloadData/Application for Provisional Degree Certificates updated.pdf'


function EDownload() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                DOWNLOAD
            </h2>

            <div className="eDownload-link-container">
                <ul>
                    <li><p><a href={instructionforcertificate}>Instruction for Certificates</a></p></li>
                    <li><p><a href={applicationforofficialtranscriptupdated}>Official Transcript</a></p></li>
                    <li><p> <a href={applicationforprovisionaldegreeecertupda}>Provisional Degree Certificate</a></p></li>
                    <li><p> <a href={applicationformigrationcertiicateupdated}>Migration Certificate</a></p></li>
                    <li><p><a href={MCRE}>Mercey chance Reappear in Exam</a></p></li>
                    <li><p><a href={CoCe}>Correction In Certificate</a></p></li>
                    <li><p><a href={DuCE}>Duplicate Certificate</a></p></li>
                    <li><p><a href={ROM}>Retotaling Of Marks</a></p></li>




                    <li><p><Link to='/UCPs' className='link-of-e-download'>Syllabus</Link></p></li>

                    <li><p><Link to='/examinations-ordinance' className='link-of-e-download'>Ordinance</Link></p></li>
                    <li><p><Link to='/questionPapers' className='link-of-e-download'>Question Papers</Link></p></li>


                </ul>
            </div>
        </div>
    )
}

export default EDownload