import React, { useState } from "react";

import profimg from "./IMG_0087-scaled-e1686892790333.jpg";

const DrAbhishekDadhich = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] = useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Abhishek Dadhich</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
              abhishekdadhich [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=l90vg5UAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a>Scopus</a>
            </li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        B. Pharm, M.Pharm (Pharma Management and Drug Regulatory Affairs), MBA,
        Ph.D (Management)
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        14 Years
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research Area/ Project
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <p>
            Behavioral Sciences, Healthcare Supply Chain, Medicine Optimization,
            Pharmaceutical Policy
          </p>{" "}
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ol>
            <li>
              Dadhich A., Gurbani N.K., “COVID-19: unprecedented challenges for
              Indian pharmaceutical industry and pharma emerging markets”.
              International Journal of Scientific Reports, [S.l.], v. 7, n. 6,
              p. 319-324, May. 2021. ISSN 2454-2164.
            </li>
            <li>
              Dadhich A., “Economic Impact of Covid-19 Vaccines”, Double Helical
              Health Research Journal, March 03, 2021.
            </li>
            <li>
              Dadhich A., “The COVID-19 pandemic and the Indian pharmaceutical
              industry”, European Pharmaceutical Review, 2020
            </li>
            <li>
              Dadhich A., “Chemists’ Perception Towards Over-the-Counter Brand
              Medicine Marketing with Special Reference to Rajasthan”,
              Oorja-International Journal of Management and IT, Vol.15, Issue 2,
              December 2017.
            </li>
            <li>
              Dadhich A., Dixit K., “Consumer Perception and Brand Loyalty
              Towards Over-the-Counter Brand Medicines of Major Pharmaceuticals
              Companies with Special Reference to Rajasthan”, International
              Journal of Engineering Technology and Management Research, Volume
              4, Issue 9, September 2017.
            </li>
            <li>
              Dadhich A., Dixit K., “Consumer Selection and Buying Behaviour
              Towards Over the Counter (OTC) Medicine in Jaipur City”,
              Apeejay-Journal of Management Sciences and Technology, 4(2),
              February 2017.
            </li>
            <li>
              Dadhich A., Dixit K., “Trends in OTC Drug Marketing Strategies by
              Indian Pharmaceutical Companies” World Journal of Pharmaceutical
              Research, Vol.4, Issue 10, September 2015.
            </li>
            <li>
              Gurbani N.K., Dadhich A., “Pharmaceutical Services in Hospital”
              The Pharma Review, December 2013.
            </li>
            <li>
              Dadhich A., “Exploring Branded Generics Drugs by Indian
              Pharmaceutical Multinational Companies as a New Prospect” Pharma
              Times, Volume 44, August 2012
            </li>
            <li>
              Dadhich, A, Sharma, A (2011) “International Regulatory
              Documentations: Assuring Quality Process &amp; Services in
              Pharmaceutical Industry” Indo-Global Research Journal of
              Pharmaceutical Sciences
            </li>
            <li>
              Dadhich A, “Pharmacoeconomics in Health Care Services” published
              in „CBS E- Journal of Biz n Bytes”, September 2010
            </li>
            <li>
              Dadhich A, Gupta S.D, Sharma A. “Influence of Product Patent on
              Indian Pharmaceutical Industry” in International Journal of
              Teaching &amp; Research in Chemistry, June 2009
            </li>
            <li>
              Dadhich A., Sharma A., “Synthesis and anticancer activity of
              Nordihydroguaiaretic acid (NDGA) and analogues”, Poster Presented
              &amp; Abstract published in Souvenir of I.S.T.E Sponsored Seminar
              on Recent Advances in Drug Discovery &amp; Design, March 16-20,
              2009.
            </li>
            <li>
              Sharma A., Dadhich A., Shrivastava A.K., Samanta K.C., “Novel
              platforms for oral drug delivery”, Poster Presented &amp; Abstract
              published in Souvenir of I.S.T.E Sponsored Seminar on Recent
              Advances in Drug Discovery &amp; Design, March 16-20, 2009.
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Book/Book Chapters
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <p>
            {" "}
            Lodish H, Baltimore D, Berk A, Zipursky SL, Matsudaira P, Darnell J.
            Molecular cell biology. 3rd ed. New York: Scientific American; 1995.
          </p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ol>
            <li>
              <p>
                <strong>
                  Paper Presented &amp; Participated in Conference
                </strong>
              </p>
              <ul>
                <li>
                  Paper presented on{" "}
                  <strong>
                    “A Study on Consumer Perception for Devising Pharmaceutical
                    Over the Counter Marketing strategies”,
                  </strong>{" "}
                  in International Conference on Management &amp; IT, 12-13
                  April 2018, IIIM, Jaipur.
                </li>
                <li>
                  Paper presented on “
                  <strong>
                    The literature review; A base for research in pharmaceutical
                    management
                  </strong>
                  ”, National conference on ethical research and effective
                  report writing, 20-21 April 2019.Rajasthani Medical Library
                  Association, Federation of Health Science Library
                  Associations, Jaipur.
                </li>
                <li>
                  Participated in the National Conference on{" "}
                  <strong>
                    Issues Related to IPRs: opportunities &amp; challenges and
                    National Workshop on Patent Search Documentation, Drafting
                    &amp; Infringement Analysis
                  </strong>
                  , Birla Institute of Tech. &amp; Sc., Pilani, Rajasthan, India
                </li>
              </ul>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>
                <li>Conducted MDP</li>
              </strong>
              <ul>
                <li>
                  Conducted MDP on “Access to Essential Medicine in Low and
                  Middle-Income Countries” at IIHMR University, Jaipur, 2018.
                </li>
                <li>
                  Conducted MDP on “Procurement of Medicines and Health
                  Products”, at IIHMR University, Jaipur, 2017
                </li>
                <li>
                  Conducted MDP on “Patient Medication Safety and Communication
                  Skills for Hospital Pharmacist” at IIHMR University, Jaipur in
                  year 2015,2016,2017,2018,2019.
                </li>
                <li>
                  Conducted MDP on “Patient Medication Safety and Quality
                  Management” at IIHMR University, Jaipur in Year 2015.
                </li>
                <li>
                  Coordinate MDP on “Supply Chain Management with focus on ARVs
                  and Related Supplies “at IIHMR Jaipur in Year 2014.
                </li>
                <li>
                  Coordinate MDP on “Promoting Rational Use of Drug in
                  Community” at IIHMR Jaipur in 2014, 2013, 2012, 2011.
                </li>
                <li>
                  Coordinate MDP on “Pharmacovigilance in Clinical Research” at
                  IIHMR Jaipur in year 2013.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsAwardsandRecOpen(!isAwardsandRecOpen)}
      >
        Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Received best paper presentation award in International Conference
              on Management & IT, 12-13 April 2018, IIIM, Jaipur
            </li>
            <li>
              Received cash award for best paper publication on “Influence of
              Product Patent on Indian Pharmaceutical Market” in International
              Journal of Teaching & Research in Chemistry. October 2009.
            </li>
            <li>
              AICTE scholarship recipient during M. Pharm from (2006-2008).
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrAbhishekDadhich;
