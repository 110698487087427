import React, { useState } from "react";

import profimg from "./IMG_0035-scaled-e1686638323855.jpg";

const DrBeautyBehera = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Beauty Behera</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : 
           
              beauty [dot] behera [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=yLyLQYoAAAAJ&hl=en&oi=ao">
                Google Scholar
              </a>
            </li>
            <li> Scopus</li>
            <li>
              <a href="https://orcid.org/0000-0003-3756-0741">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        PhD Biotechnology and Medical Engineering
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Biomaterials and tissue engineering for regenerative medicine,
        Biopolymers for drugs, immunotherapy, and probiotics delivery,
        Peptide-based delivery systems.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <p>
              <li>
                I. Mohanty L.; Sharma S.; Behera B.; Panwar S.; Paliwal C.;
                Singh A.; Gupta A.; Chilkoti D.
              </li>
              <li>
                C. A randomized, open label trial to evaluate and compare the
                immunogenicity and safety of a
              </li>
              <li>
                novel liquid hexavalent DTwP-Hib/Hep B-IPV (EasySix™) to
                licensed combination vaccines in
              </li>
              <li>healthy infants. Vaccine. 2018. 36(17), 2378-2384.</li>
              <li>
                II. Mohanty L.; Sharma S.;Behera B.; Panwar S.;Paliwal C.; Singh
                A.; Gupta A.; ChilkotiD. C.
              </li>
              <li>
                A randomized, open-label clinical trial to evaluate
                immunogenicity and safety of an
              </li>
              <li>
                indigenously developed DTwP-Hib tetravalent combination vaccine
                (Easyfour ® -TT)
              </li>
              <li>
                with Quadrovax ® in Indian infants.Human Vaccines
                &amp;Immunotherapeutics. 2017,
              </li>
              <li>13(9),2025-2031.</li>
              <li>
                III. Pandey S.; Senthilguru K.; Uvanesh K.; Sagiri S.S.; Behera
                B.; Babu N.; Bhattacharyya M. K.;
              </li>
              <li>
                PalK.; Banerjee I.; Natural gum modified emulsion gel as single
                carrier for the oral delivery of
              </li>
              <li>
                probiotic-drug combination. International journal of biological
                macromolecules. 2016, 92, 504-
              </li>
              <li>514.</li>
              <li>
                IV. Mallick S. P.;Sagiri S. S.; Singh V. K.;Behera
                B.;Thirugnanam A.; Pradhan D. K.; Bhattacharya
              </li>
              <li>
                M. K.; Pal K. Genipin-Crosslinked Gelatin-Based Emulgels: an
                Insight into the Thermal,
              </li>
              <li>
                Mechanical, and Electrical Studies.AAPS Pharm SciTech.
                2015,16(6),1254-1262.
              </li>
              <li>
                V. BeheraB.; Biswal D.; Uvanesh K.; Srivastava A.K.;
                Bhattacharya M.K.; Paramanik K.;
              </li>
              <li>
                PalK.Modulating the properties of sunflower oil based novel
                emulgels using castor oil fatty acid
              </li>
              <li>
                ester: Prospects for topical antimicrobial drug delivery.
                Colloids and Surfaces B:
              </li>
              <li>Biointerfaces.2015, 128, 155-164.</li>
              <li>
                VI. Behera, B.;Singh V.K.; KulanthaivelSenthilguru; Bhattacharya
                M.K.; Paramanik K.; Banerjee I.;
              </li>
              <li>
                Pal K. Physical and mechanical properties of sunflower oil and
                synthetic polymers based bigelsfor
              </li>
              <li>
                the delivery of nitroimidazole antibiotic–A therapeutic approach
                for controlled drug delivery.
              </li>
              <li>European Polymer journal. 2015, 64, 253-264.</li>
              <li>
                VII. Behera, B.; Dey S.; Sharma V.; Pal K.Rheological and
                viscoelastic properties of novel
              </li>
              <li>
                sunflower oil-span 40-biopolymer based bigels and their role as
                functional material in the delivery
              </li>
              <li>
                of antimicrobial agents. Advances in Polymer Technology.
                2015,34(2).
              </li>
              <li>
                VIII. Behera B.; Sagiri S. S.; Pal K.; Pramanik K.; Usman Ali
                Rana; Imran Shaki; Arfat
              </li>
              <li>
                Anis.Sunflower oil and protein based novel bigels as matrices
                for drug delivery applications-
              </li>
              <li>
                Characterization and in vitro antimicrobial efficiency.
                Polymer-Plastics Technology and
              </li>
              <li>Engineering. 2015, 54(8), 837-850.</li>
              <li>
                IX. Behera B.; Sagiri S. S.; Singh V. K.; Pal K.; Anis A.
                Mechanical properties and delivery of
              </li>
              <li>
                drug/probiotics from starch and non-starch based novel bigels: A
                comparative study.
              </li>
              <li>Starch–Stärke.2014,66 (9-10), 865-879.</li>
              <li>
                X. Behera B.; Khade S. M.; Sagiri S.S.; Singh V. K.; Thirugnanam
                A.; Pal K.; Ray S.S.; Pradhan
              </li>
              <li>
                D.K.;Bhattacharya M.K. Gelatin–PEG based metronidazole-loaded
                vaginal delivery systems:
              </li>
              <li>
                preparation, characterization and in vitro antimicrobial
                efficiency. Iranian Polymer Journal.2014,
              </li>
              <li>23(3),171-184.</li>
              <li>
                XI. Sagiri S. S.;Behera B.; Rafanan R.; Bhattacharya C.; Pal K.;
                Banerjee I.; Rousseau D.Organogels
              </li>
              <li>
                as matrices for controlled drug delivery: a review on the
                current state.Soft materials.2014, 12(1),
              </li>
              <li>47-72.</li>
              <li>
                XII. Behera B.; Sagiri, S. S.; Pal K.; Srivastava A. Modulating
                the physical properties of
              </li>
              <li>
                sunflower oil and sorbitanmonopalmitate-based organogels.
                Journal of Applied Polymer
              </li>
              <li>Science.2013,127(6), 4910-4917.</li>
            </p>
            <p>
              <li>
                XIII. Shah, D.K.;Sagiri S.S.; Behera B.;Pal K.; Pramanik
                K.Development of olive oil based
              </li>
              <li>
                organogels using sorbitanmonopalmitate and Sorbitan
                monostearate: A comparative study.
              </li>
              <li>Journal of Applied Polymer Science.2013. 129(2), 793-805.</li>
              <li>
                XIV. Satapathy D.; Biswas D.; Behera B.;Sagiri S. S.; Pal
                K.;Pramanik K.Sunflower-oil-based
              </li>
              <li>
                lecithin organogels as matrices for controlled drug delivery.
                Journal of Applied Polymer
              </li>
              <li>Science.2013, 129(2), 585-594.</li>
              <li>
                XV. Sagiri S. S.;Behera B.; Pal K.; Basak P.Lanolin-based
                organogels as a matrix for topical
              </li>
              <li>
                drug delivery.Journal of Applied Polymer Science.2013,128(6),
                3831-3839.
              </li>
              <li>
                XVI. Sagiri S. S.; Behera B.;SudheepT.; Pal K. Effect of
                composition on the properties of the
              </li>
              <li>
                tween 80-span 80 based organogels.Designed Monomers
                Polymers.2012, 15(3), 253-273.
              </li>
              <li>
                XVII. Mallick S.; Sagiri S. S.; Behera B.; Pal K.; Ray S.
                S.Gelatin-based emulsion hydrogels as
              </li>
              <li>
                a matrix for controlled delivery system. Materials and
                manufacturing processes.2012,27 (11),
              </li>
              <li>1221-1228.</li>
              <li>
                XVIII. Behera B.; Patil V.; Sagiri S. S.; Pal K.; Ray S. S.
                Span-60 based organogels as probable
              </li>
              <li>
                matrices for transdermal/topical delivery systems.Journal of
                Applied Polymer
              </li>
              <li>Science.2011,125(2), 852-863.</li>
              <li>
                XIX. Pal K.;Behera B.; Roy S.; Ray S. S.; Thakur G. Chitosan
                based delivery systems on a
              </li>
              <li>
                length scale: nano to macro.Soft Materials.2011, 11(2), 125-142.
              </li>
              <li>
                XX. Kumar N.;Behera B.; Sagiri S. S.;Pal K.; Ray S. S.; RoyS.
                Bacterial vaginosis: Etiology
              </li>
              <li>
                and modalities of treatment-A brief note.Journal of Pharmacy and
                Bioallied Sciences.2011, 3(4),496.
              </li>
            </p>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              {" "}
              Sorbitan Esters and Sunflower Oil” in Colloids and
              Nanomedicine-2012, Amsterdam, Netherlands.
            </li>
            <li>
              {" "}
              Paper presentation entitled “Development of Novel sunflower oil
              span 40 organogels for topical drug delivery (ID 430)”in World
              Congress on Biomaterials, China 2012.
            </li>
            <li>
              {" "}
              Poster presentation entitled “Sunflower oil sorbitanmonopalmitate
              based organogels: Design and Analysis” International Conference on
              Biomaterials Implat Devices and Tissue Engineering – BIDTE 2012,
              Chennai.
            </li>
            <li>
              {" "}
              Poster presentation entitled “Development of Span-40 lipogels
              based on edible oils” at 69th CSIR foundation day, IMMT, CSIR
              Bhubaneswar, 2011.
            </li>
            <li>
              {" "}
              Poster presentation entitled ‘’Development of sorbitan ester based
              organogels’’ National conference on Tissue Engineering, Rourkela
              2010.
            </li>
            <li>
              {" "}
              Poster Presentation entitled “Development and evaluation of
              emulsion gel beads as gastroretentive delivery system ” in 60th
              IPC, NSIT,Delhi PH-474 2009.
            </li>{" "}
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              I. DST travel grant to attend “Colloids and Nanomedicine -2012”,
              Amsterdam,
            </li>
            <li>Netherlands.</li>
            <li>
              II. Jury Prize for presentation of poster in “Development of
              Span-40 lipogels based
            </li>
            <li>on edible oils’’ at 69 th CSIR foundation day 2011</li>
            <li>III. Institute fellowship by MHRD for pursuing PhD.</li>
            <li>
              IV. Scholarship offered by UGC during completion of M. Pharm.
            </li>
            <li>
              V. GATE 98.96 percentile, 2006, organized by HRD Ministry, Govt.
              of India.
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrBeautyBehera;
