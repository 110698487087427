import React from "react";


import MbaPm from "./Result2018 Data/MBA-PM.pdf";
import mhm from "./Result2018 Data/MHM.pdf";
import mph from "./Result2018 Data/MPH.pdf";
 import MPharm2021 from "./Result2018 Data/M.Pharm-2018.pdf";
 import DPharm2021 from "./Result2018 Data/D.Pharm-2018.pdf";
 import BscHons from "./Result2018 Data/All-Main-Semester-Result-of-B.-Sc.-Hons.-Sports-Science-2018.pdf";
 import BPT2021 from "./Result2018 Data/All-Main-Semester-Year-Result-of-BPT-2018.pdf";



import BphrmDatesheet from "./Result2018 Data/B.Pharm-SPS-2018.pdf";
 import bPharmDipsar from "./Result2018 Data/B.Pharm-Dipsar-2018.pdf";
 import PrePHD from "./Result2018 Data/Result-of-Pre.Phd-Examination-2018.pdf";

const Result2018 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
      Examination Results - 2019
      </h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={MbaPm}>MBA-PM</a>
          </li>
          <li>
            <a href={mhm}>MHM</a>
          </li>
          <li>
            <a href={mph}>MPH</a>
          </li>
          <li>
            <a href={MPharm2021}>M. Pharm</a>
          </li>
          <li>
            <a href={DPharm2021}>D. Pharm</a>
          </li>
          <li>
            <a href={BscHons}>B. Sc. (Hons.) Sports Science</a>
          </li>
          <li>
            <a href={BPT2021}>BPT</a>
          </li>
          <li>
            <a href={BphrmDatesheet}>B. Pharm (SPS)</a>
          </li>
          <li>
            <a href={bPharmDipsar}>B. Pharm (DIPSAR)</a>
          </li>
          <li>
            <a href={PrePHD}>Pre.-Ph.D</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Result2018;
