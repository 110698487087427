import React, { useState } from "react";

import profimg from "./IMG_1997-scaled-e1688796428648.jpg";
import award from "./Young-researcher-award-Dr.-Himanshu-Mittal_page-0001.jpg";

const DrHimanshu = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Dr. Himanshu</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
            himanshu [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=wNj1CmUAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a >
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/my-orcid?orcid=0000-0001-7534-5087">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      M.Sc., Ph.D.
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Queuing Theory, Mathematical modelling, Optimization techniques
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
                  </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
         <ol><li >Himanshu Mittal, Naresh Sharma “A simulation-based approach for minimizing waiting time in AIIMS, Delhi using Queuing model” International Journal of Health Sciences, vol 6(S5), pp 7037–7054, 2022.&nbsp;</li><li >Himanshu Mittal, Naresh Sharma “Operational Optimization of Toll Plaza Queue Length Using Microscopic Simulation VISSIM Model” Journal of Algebraic Statistics, vol 13(1), pp 418-425, 2022.</li><li >Himanshu Mittal, Naresh Sharma “Modeling of Communication Network With Queuing Theory Under Fuzzy Environment” Mathematical Statistician and Engineering Applications, vol 71(2), pp 122-137, 2022.</li><li >Himanshu Mittal, Naresh Sharma “A Probabilistic Model for the Assessment of Queuing Time of Coranavirus Disease (COVID – 19) Patients using Queuing Model” International Journal of Advance Research in Engineering and Technology, vol 11(8), pp 13-21, 2020.</li><li >Himanshu Mittal, Naresh Sharma “Assessment of Delhi NCR Traffic Using Queuing Model” International Journal of Advanced Science and Technology, vol 29(7), pp 5418-5424, 2020.</li></ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <span style={{fontSize:'1.2rem'}} data-sheets-value="{" data-sheets-userformat="{">1. Presented a paper on “Bolzano weierstrass theorm” in “International Conference in Mathematical Analysis and its Applications (ICMAA-2017)” held at Dayanand Science College, Latur (Maharashtra).<br/><br/>2. Presented a paper on “Flight Mechanics” in “Approximation Theory, Fractional Calculus and Computation with Applications in Engineering and Sciences (ATFCCAES-2017)” held at S.V. National Institute of Technology, Surat (Gujarat) India<br/><br/>3. Presented a paper on “Case Study of Banking Queuing Model” in “4th International Conference on Recent Advances in Mathematical Sciences and its Applications (RAMSA-2020)” held at Jaypee Institute of Information Technology, Noida, India.<br/><br/>4. Presented a paper on “Queuing Based Traffic Model of Delhi NCR” in “National Conference on Application of Mathematics in Physical Sciences in Today’s Era” held at Arya Kanya Mahavidyalya, Shahabad Markanda (Haryana).</span>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
       <img src={award} alt="award" />
        </div>
      ) : null}
    </div>
  );
};

export default DrHimanshu;
