import React, { useState } from "react";

import profimg from "./IMG_0615-scaled-e1688470066790.jpg";

const DrSLatha = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. S. Latha</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor  | Department of Pharmacology
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
        
              s [dot] latha [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.com/citations?user=3X1eahMAAAAJ&hl=en">Google Scholar</a></li>
            <li>Scopus</li>
            <li>
              ORCID URL
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      B.PHARM, M.PHARM, PH.D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Evaluation of natural products for it’s anticancer and anti angiogenic activity and neuroprotective potential.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul><li><a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=S%20L%5BAuthor%5D&amp;cauthor=true&amp;cauthor_uid=28922721">S Latha,</a>&nbsp;<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=Chaudhary%20S%5BAuthor%5D&amp;cauthor=true&amp;cauthor_uid=28922721">Chaudhary S heetal</a>,&nbsp;<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=R%20S%20R%5BAuthor%5D&amp;cauthor=true&amp;cauthor_uid=28922721">R S R</a>ay Hydroalcoholic extract of Stevia rebaudiana bert. leaves and stevioside ameliorates lipopolysaccharide induced acute liver injury in rats. <a href="https://www.ncbi.nlm.nih.gov/pubmed/28922721">Biomed Pharmacother.</a>&nbsp;2017 Nov;95:1040-1050. (IF 7.149)&nbsp;</li><li><b>S. Latha</b>, P. Yamini1, Rajani Mathur Evaluation of Antiangiogenic Potential of Psidium guajava Leaves using In-Ovo Chick Chorioallantoic Membrane Assay Pharmacogn. mag Volume 14 | Issue 57 | July-September 2018 (Supplement 2) (I.F 1.525)</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         
        </div>
      ) : null}
    </div>
  );
};

export default DrSLatha;
