import React from "react";
import "./IndustrialTraining.css";

const IndustrialTraining = () => {
  return (
    <div className="academic-unit-component">
      <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading">
        Industrial Training
      </h2>

      <div className="academic-units-data-container">
        <p>
          In today’s world, merely having a degree is no guarantee of
          employment, nor is it a reliable indicator of the individual’s
          competence in a job. Rather, graduates must have current and relevant
          knowledge, practical experience, soft skills and a positive attitude
          to allow them to be competitive in the job market. They must possess
          the necessary knowledge and skills before they go out into the working
          world. In line with this, the Industrial Training component of the
          undergraduate program constitutes a vital component in the drive to
          strengthen the key competencies required to improve the graduates’
          ability to work.
        </p>
        <p>
          Industrial Training refers to a program which aims to provide
          supervised practical training within a specified timeframe. This
          training can be carried out either in government organisations or in
          the private sector
        </p>
        <p>
          The main aim of the Industrial Training program is to produce
          graduates who are ready to face the working world. The program also
          aims to produce the knowledgeable, skilled and experienced graduates,
          demanded by employers, who are able to apply the knowledge acquired at
          university to the working world. The Industrial Training program
          provides opportunities for exposure to the working world, which will
          make graduates more aware of the hopes and expectations that industry
          has of them. The program will also equip students with real work
          experience. Placing students in industry also increases their chances
          of employment after graduation, as there is a strong possibility that
          they will be offered a job in the same place where they do their
          training. Apart from this, the training experience will further
          solidify the on-campus learning process and activities, while also
          providing students with relevant work experience
        </p>
        <p style={{fontWeight:'bold'}}>At the end of Industrial Training, students will be able to:</p>
        <ul>
          <li>
            improve their knowledge and skills relevant to their area of study
          </li>
          <li>
            relate the knowledge and skills acquired at the workplace, to their
            on-campus studies
          </li>
          <li>
            compete effectively in the job market, because they have been
            equipped with the requisite knowledge, skills, attitudes and
            practical experience
          </li>
        </ul>
        <p>
          <em>
            Duration of the Industrial Training program depends on the
            requirements of the program of study. Ideally, it should last from
            two to six months
          </em>
        </p>
      </div>
    </div>
  );
};

export default IndustrialTraining;
