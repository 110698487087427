import React, { useState } from "react";

import profimg from "./IMG_0617-scaled-e1688470111586.jpg";
import HimangingiAward from "./PhD-himangini-bansal.jpg";


const DrHimangini = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
Dr. Himangini</h2>
      <h3 className="facult-designation-for-each-container">
      Lecturer |  Department of Pharmaceutical Chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "} 
           
            himanginibansal [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.co.in/citations?user=R4PWvH8AAAAJ&hl=en">Google Scholar </a></li>
            <li> <a href="https://www.scopus.com/home.uri">Scopus</a></li>
            <li>
              <a href="https://orcid.org/my-orcid?orcid=0000-0002-5077-9417">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      PhD
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Design and synthesis of antimalarial Compounds
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        <p>1. Himangini, Hitesh chopra, Rupesh K Gautam, Chandragiri Siva, ShikhaJoon. “Natural Products for the Management of Castration Resistant Prostate Cancer: Special Focus on<br/><br/>Nanoparticles Based Studies” Current topics in Medicinal Chemistry, 2021; 9; 745177.<br/><br/>2. HimanginiBansal, Rajeev K. Singla, SaharBehzad, Hitesh Chopra, Ajmer S. Grewal, Ashok K. Dubey, BairongShen. “Unleashing the Potential of Microbial Natural Products in Drug Discovery: Focusing on Streptomyces Antibiotics as Goldmine”. Current topics in Medicinal Chemistry, 2021; 21; 2374-2396.<br/><br/>3. Sourabh Kumar, HimanginiBansal. “A review on synthesis and antimicrobialactivity of Schiff bases”. International Journal of Innovative Science, Engineering &amp; Technology, 2021; 8(7); 46-64.<br/><br/>4. Himangini, AnushkaGoel. “ Herbal drugs used in Traditional Management of Breast cancer”. International Journal of Innovative Science, Engineering &amp; Technology, 2021; 8(8); 382-414.<br/><br/>5. Sachin Kumar, FaizanaFayaz, FaheemHyderPottoo, Sakshi Bajaj, SatishManchanda, HimanginiBansal. “Nanophytomedicine Based Novel Therapeutic Strategies in Liver Cancer”. Current Topics in Medicinal Chemistry, 2020; 20(22); 1999-2024.<br/><br/>6. Sakshi Bajaj, ShivkanyaFuloria, VetriselvanSubramaniyan,<br/><br/>DhanalekshmiUnnikrishnanMeenakshi, SharadWakode, AvneetKaur, HimanginiBansal, SatishManchanda, Sachin Kumar, Neeraj Kumar Fuloria. “Chemical Characterization and<br/><br/>Anti-Inflammatory Activity of Phytoconstituents from Swertiaalata”. Plants, 2021; 10(6);1109.<br/><br/>7. Umesh Kumar, Sandeep Kumar, Himangini. “A review on recent development on fluoroquinolones”. The Pharma Innovation Journal, 2019; 8(7); 747-759<br/><br/>8. HimanginiBansal, Dharam Pal Pathak, Vidushi Sharma, Sachin Kumar “Designing novel inhibitors against falcipain-2 of Plasmodium falciparum”. Bioorganic &amp; medicinal chemistry letters, 2018, 28 (9), 1566-1569.<br/><br/>
        9. Uma Agarwal, Puja Jaiswal, Dharam Pal Pathak, HimanginiBansal. “Chalcone and their Heterocyclic Analogue: A Review Article”. Journal of Chemical and Pharmaceutical Research, 2018; 10(4); 160-173.<br/><br/>10. DeepaKumari, HimanginiBansal. “Benzohydrazides: As potential bio-active agents”. The Pharma Innovation, 2018; 7(8); 543-550.<br/><br/>11. HimanginiBansal, D.P. Pathak “Synthesis, characterization and in vitro antibacterial activity of new chalcones linked via coumarin ring”. Der PharmaChemica, 2016; 8 (15), 112-115.<br/><br/>12. HimanginiBansal, D.P. Pathak “Synthesis And In Vitro Antibacterial Activity Of Some Phenyl UrenylChalcones”. International Journal of Pharmaceutical Sciences and<br/><br/>Research, 2017; 8 (3), 1205.</p><p>13. HimanginiBansal, D.P. Pathak “Recent Advances in Pyrazoline Derivatives: A Review Indo Global Journal of Pharmaceutical Sciences, 2016; 6(1): 1-13<br/><br/>14. Sachin Kumar, Ramesh B Bodla, HimanginiBansal “Antioxidant Activity of Leaf Extract of Aeglemarmelos Correa ex Roxb”.Pharmacognosy Journal, 2016, 8 (5): 447-450.<br/><br/>15. Vipin Kumar, HimanginiBansal “QSAR study on estimation of MAO-A inhibitory activity using Topological Descriptors” in Med Chem Res, 2011; 20(2); 168-174.<br/><br/>16. HimanginiBansal, Anuradha Sharma, Vikas Sharma, Vipin Kumar “Pharmacophore modeling studing on Xanthone as Monoamine Oxidase –A Inhibitors”, Bulletin of Pharmaceutical Research 2011;1(1):15-21<br/><br/>17. Harsh Raghav, BabitaAggarwalb, HimanginiBansalc, and VipinChoudharyd “MucoadhesiveBuccal Drug Delivery: An Overview”. Journal of Pharmacy Research, 2011, 4(1), p72.</p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <img src={HimangingiAward} alt="HimangingiAward" />
        </div>
      ) : null}
    </div>
  );
};

export default DrHimangini;
