import React, { useState } from "react";

import profimg from "./IMG_0037-scaled-e1686561378714-150x150.jpg";

const MrNihalSingh = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
Mr. Nihal Singh
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Sports Science 

      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a href="mailto:">
              {" "}
            
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <strong>MBA- Sports Management Symbiosis School of Sports Sciences</strong><br/>A unique blend of Sports joining hands along with the skills of management.<br/>Mastering the skills of Sports to upscale the level.<br/><strong>BBA – MANAGEMENT SCIENCE National PG College</strong><br/>An undergraduate programme with a former focus on commerce and business Administration.
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Development and research of Athlete Assessment Programme.</li><li>Sports Management &amp; Sports Marketing</li><li>Talent Management &amp; Representation</li><li> Development or enhancement of the sports science centres in India.</li><li>Curriculum designing for sports institutes.</li></ul>
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">

      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
     Network and Collaborations n Research
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
  <p><strong>Conferences</strong></p><ul><li>Sports Analytics conference 2019, Juhu, Mumbai.</li><li>National Sports Science Congress 2019, Pune.</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
      
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         <ul><li>Youngest University Contingent Manager (UCM) at Khelo India Games 2021, representing DPSRU.</li><li>Review Editor in International Journal Health, Exercise &amp; Sports Science.</li><li> Core team member for Setup of Center of excellence for Athlete Evaluation &amp; Development (COE- AED)</li><li>Conducted successful sports-specific tournaments</li><li> Designed Sports Science curriculum for the institute.</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default MrNihalSingh;
