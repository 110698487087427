import React from 'react'
import './ChintanShibvir.css'
import chintanVideo from './WhatsApp-Video-2023-10-04-at-3.26.20-PM.mp4'

const ChintanShivir = () => {
  return (
    <div className="dpsru-every-component-css">
    <h1 className="dpsru-primary-heading-for-every-page">
    CHINTAN SHIVIR
    </h1>

    <div className="chintan-sivir-video-container">
    <video width="750" height="500" controls >
      <source src={chintanVideo} type="video/mp4"/>
     </video>
    </div>
<p className="mark-calender-chintan-shivir-p">Mark your calendars for October 13th 2023, as we prepare for an event, set to redefine the landscape of MSME’</p>
 <p className="intro-chintan-sivir">Introducing ” CHINTAN SHIVIR”</p>

 <div className="intro-text-of-chintan">In collaboration with the esteemed Department of Scientific & Industrial Research (DSIR), under the aegis of the Ministry of Science and Technology, and DSIR-DPSRU-CRTDH in collaboration with DIIF , the Delhi Pharmaceutical Sciences and Research University is proud to present an event meticulously tailored for the realm of Healthcare MSMEs and budding Startups.</div>

 <div className="what-is-chintan-shivir-about">What’s CHINTAN SHIVIR all about?</div>

<p className="bring-together-text">This event will bring together industry experts, entrepreneurs, and healthcare professionals to address and to resolve the multifaceted challenges besieging MSMEs in the healthcare domain.</p>

<div className="bring-together-container">
    <ul>
        <li>
        A Discerning Focus on Critical Arena:
        </li>
        <li>
        Pharmaceuticals 
        </li>
        <li>
        Nutraceuticals
        </li>
        <li>
        Cosmeceuticals
        </li>
        <li>
        Medical Devices
        </li>
        <li>
        Chemicals
        </li>
        <li>
        Raw Materials
        </li>
    </ul>
</div>

<p className="bring-together-text-2">An Insight into the Event’s Itinerary:</p>

<div className="bring-together-container">
    <ul>
        <li>
        Panel Discussions: Harness profound insights from venerable experts in selected tracks, including Manufacturing, Intellectual Property (IP) Protection, Access to Funding, Strategic Market Expansion, and the Complex Terrain of Regulatory Hurdles.
        </li>
        <li>
        Brainstorming Sessions: Engage in intellectually stimulating brainstorming sessions that foster innovation and novel problem-solving approaches, all within the healthcare milieu.
        </li>
        <li>
        Badlaav : A Proposition Revolution: Participate in “Badlaav,” our Problem-Solving B-Plan Competition, and stand a chance to exhibit your ingenious solutions, competing for a panoply of exciting opportunities.
        </li>
        <li>
        Tech Bridge: Traverse the realms of technology with “Tech Bridge,” where you’ll discover cutting-edge tech solutions and bespoke offerings meticulously designed to empower MSMEs in the healthcare arena.
        </li>
        <li style={{color:'#0035a6'}}>
        Venue: <br />
GK Narayan Auditorium, Delhi Pharmaceutical Sciences and Research University.
        </li>
    
    </ul>

</div>

    <div className="p-no-5th">This will be an exclusive rendezvous to foster invaluable networks, cultivate knowledge, and spearhead innovation, all within the venerated sphere of the healthcare industry.</div>
    <div className="p-no-5th">Do not miss CHINTAN SHIVIR, an event destined to sculpt the future trajectories of MSMEs and startups!!!</div>


    <div className="p-no-6th">Stay tuned for forthcoming details, and mark October 13th, 2023, into your calendar with indelible ink!</div>
    <div className="p-no-7th">#CHINTANSHIVIR #Healthcare #MSMEs #Startups #Innovation #DSIR #DPSRU #HealthcareIndustry #Networking #Entrepreneurship #EventAlert</div>

    </div>

    
  )
}

export default ChintanShivir