import React from 'react'
import { NavLink } from 'react-router-dom'
import './PageNotFound.css'
import pnfImg from './pageNotFound.gif'


function PageNotFound() {
    return (
        <div className='page-not-found-component'>

            <section className="page-404-section">

                <h1 className="text-404">404</h1>

                <div className="img-container-pnf">
                    <img src={pnfImg} alt="Page Not Found" />


                    {/* <a href="/wp-content/logo192.png"> download image</a> */}
                    {/* these file imported from out side of src folder  */}
                    {/* <a href="/wp-content/1.2.3.pdf">dwnld pdf files  </a> */}

                </div>


                <h3 className="look-like-u-r-lost">
                    Look like you're lost
                </h3>

                <p className='pnf-text-paragrauph'>the page you are looking for not avaible!</p>

                <NavLink to='/' className="link-404-nav-link">Go to Home</NavLink> <br /><br />

            </section>

        </div>
    )
}

export default PageNotFound