


export const SidebarData = [
 
  {
    title: "Notifications",
    path: "/notifications",
    // icon: <AiIcons.AiFillHome />,
    cName: "nav-text"
  },
  {
    title: "Administrative Staff",
    path: "/staff",
    // icon: <IoIcons.IoIosPaper />,
    cName: "nav-text"
  },
  {
    title: "Annual report",
    path: "/annualReport",
    // icon: <FaIcons.FaCartPlus />,
    cName: "nav-text"
  },
  // {
  //   title: "CONFERENCES",
  //   path: "/confercences",
  //   // icon: <IoIcons.IoMdPeople />,
  //   cName: "nav-text"
  // },
 
  {
    title: "NSS",
    path: "/nss",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Tenders",
    path: "/tenders",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Payment",
    path: "/payment",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Recruitments",
    path: "/recruitments",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
 
  {
    title: "Newsletters",
    path: "/newsletters",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Placement",
    path: "/placement",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "ContactUs",
    path: "/contactUs",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Forms",
    path: "/forms",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "Approval & Affilations",
    path: "/approvalAffiliations",
    // icon: <IoIcons.IoMdHelpCircle />,
    cName: "nav-text"
  },
  {
    title: "RTI",
    path: "/rtiAct",
    // icon: <FaIcons.FaEnvelopeOpenText />,
    cName: "nav-text"
  },
  {
    title: "TLP Reports",
    path: "/tlpReports",
    // icon: <FaIcons.FaEnvelopeOpenText />,
    cName: "nav-text"
  },
  
  {
    title: "HIV AIDS Preventions & Control",
    path: "/HIV_AIDS_Preventions_&_Control",
    // icon: <FaIcons.FaEnvelopeOpenText />,
    cName: "nav-text"
  }
 
];
