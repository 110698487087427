import React, { useState } from "react";

import profimg from "./Picture44.jpg";

const DrMinakshiGarg = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Dr. Minakshi Garg</h2>
      <h3 className="facult-designation-for-each-container">
      Associate Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
            minakshigarg [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?hl=en&user=0Ub4AmwAAAAJ">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=12801962300">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-5065-0832?lang=en">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      M. Pharm (Pharmaceutics), Ph.D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Solubility enhancement of water insoluble drugs</li><li>Regulatory submissions for EU, US and Pharmerging markets of medicinal drug products (parenterals)</li></ul>
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
                  </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <p>       Speaker: a. Speaker: Webinar on Vaccinate with Confidence by 3 Analytics. May 16, 2021 (platform for safety monitoring of vaccine shortlisted by WHO for global roll out) b. Keynote speaker in International Conference on ‘ADVANCEMENTS IN DRUG REGULATORY AFFAIRS: PHARMACEUTICAL INDUSTRY-ACADEMIA PERSPECTIVE’ (ADRA-2022), being hosted by the Chitkara College of Pharmacy, Chitkara University, Punjab, India, on 26-30 Sept 2022
</p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         
        </div>
      ) : null}
    </div>
  );
};

export default DrMinakshiGarg;
