import React from "react";
import "./AboutUs.css";
import sportsComplex from "./CampusDetailsImage/Sports-Complex.jpg";
import auditorium from "./CampusDetailsImage/Auditorium.jpg";
import swimmingPool from "./CampusDetailsImage/swimming-pool.jpg";
import musicalFountain from "./CampusDetailsImage/musical-fountain.jpg";
import guestHouse from "./CampusDetailsImage/guest-house.jpg";
import sbiAtm from "./CampusDetailsImage/sbi-atm.jpg";
import library from "./CampusDetailsImage/library.jpg";
import gym from "./CampusDetailsImage/gym.jpg";
import SideLinkUniversity from "./University Side Important Links/SideLinkUniversity";
import { NavLink } from "react-router-dom";
import { FaArchive } from "react-icons/fa";

function AboutUs() {
  return (
    <div className="dpsru-every-component-css ">
      <h2 className=" dpsru-primary-heading-for-every-page">
        About the University
      </h2>

      <div className="flex-box-for-side-imoportant-link-about-university">
        <div className="flex-box-first-container-main-content-of-page-with-size-70">
          <h4 className="dpsru-secondary-heading-for-every-page">
            About DPSRU at a Glance
          </h4>

          <p className="aboutUs-paragrauph">
            <span style={{ fontWeight: "bold" }}>
              Delhi Pharmaceutical Sciences and Research University
            </span>{" "}
            (DPSRU) is the first Pharmacy University in India and third in the
            world, established by Act 07 of 2008 State legislature of Delhi and
            functional from 2015. It is 100% grant-in-aid State Government
            University recognized under 2f and Section 22 of the UGC. The
            University has the legacy of 57 years starting with the D. Pharm. in
            polytechnic in 1964 to College of Pharmacy in 1972 and then the
            institute Delhi Institute of Pharmaceutical Sciences and Research
            (DIPSAR) in 2004 that got converted into DPSRU as it stands now. The
            Act, Statutes and Ordinances published by the Government of Delhi
            are being implemented and the administration is mentored by the
            General Council with Hon.’ble Lt. Governor as the Chancellor, the
            Board of Governors, the Academic Council and the Finance Committee
            having external distinguished personalities as the members. <br />
            <br />
            It has the campus spread over an area of 10.36 acres. Currently it
            has around 2600 students with 90 faculty and 136 supporting staff
            members, offering over 4 certificate, 3 diploma, 7 undergraduate, 24
            postgraduate, and 8 doctoral programs. Apart from this, the
            University is planning to start two new courses i.e. B.Sc. (Nursing)
            and B.Sc. in Biomedical Sciences. The University has the facilities
            to promote comprehensive teaching and learning and conduct research
            to cater to various sectors of industry. In addition to the strong
            academic programs, as in the name of the University, it has a strong
            research and innovation footing. There are 5 faculty members
            currently in the list of top 2% scientists in the world published by
            Stanford University. In a recent list published in PLOS Biology, 8
            faculty members of DPSRU are in the list of top scientists of the
            world (~ 20,000). The faculty publishes a large number of quality
            papers in the high impact factor journals. In addition to
            publications of research papers and books, and filing of the
            patents, there is a focus for the innovation and incubation for the
            entrepreneurship development. The University had nurtured 18
            startups with several products in the market. Notable being ‘Ezypro,
            a nutraceutical drink to provide essential amino acids and minerals
            with Ahlnich for the critical care patients and the ‘CoroQuil-Zn’
            with Therapeutics Inc., perhaps the first in the world herbal drug
            targeting the ACE-2, the main culprit for complications of COVID-19
            patients.
            <br />
            <br />
            DPSRU thrives to create the state-of-the-art infrastructure research
            facilities. Besides unique Ocular Pharmacology lab, Clinical
            research, Herbal Technology and Nanotechnology Centers, it has come
            up with BioNest Bioincubator Center of DBT-BIRAC, Precision Pharmacy
            & Medicine Center of DKDF, Govt. of Delhi and Centre for Advanced
            Formulation Technologies of DSIR-CRTDS, Govt. of India. This has
            enabled the University to establish linkages with industries for the
            promotion of science and currently, the University has more than 35
            MoUs signed with National Government institutions and Pharma
            Industry. The University has developed collaborations and tie-ups
            with countries like the UK, West Indies, Portugal, Philippines,
            Malaysia, Thailand and even China. The Vice Chancellor has a
            distinction of being the second only non-Russian conferred with the
            Honorary Professorship at the Stavropol State Medical University of
            Russia and the Distinguished Visiting Professorship at the UCSI
            University of Kula Lumpur, Malaysia.
            <br />
            <br />
            With the a comprehensive, multidisciplinary pedagogy of learning and
            research having holistic approach in pharmaceutical and allied
            health sciences students are being most benefitted in terms of
            campus placements and to excel in the competitive world. In the
            current year, 70 students qualified in the national competitive
            examination GPAT, with the 1st Rank and 4th All India Ranks grabbed
            by our students. We have now 123 Ph.D. students and one third of the
            Govt scholarships through agencies like ICMR, DST and CSIR and an
            equal number having research fellowships from DRDO, THISTI, IPC and
            pharma industries.
            <br />
            <br />
            DPSRU aims to cultivate the spirit of embracing internationalism,
            diversity and competitiveness amongst students, such that novel and
            creative ideas are harnessed, leading to unique discoveries and
            inventions for the advantage of society at large.
            <br />
          </p>
        </div>

        <div className="flex-box-second-container-side-link-content-container-of-page-with-size-30">
          <SideLinkUniversity />
        </div>
      </div>
      <h4 className="dpsru-secondary-heading-for-every-page">
        CAMPUS HIGHLIGHTS
      </h4>

      <div className="campus-highlight-container-in-about-page">
        <figure>
          <a href={sportsComplex}>
            {" "}
            <img src={sportsComplex} alt="Sports Complex" />
          </a>
          <figcaption>Sports-Complex</figcaption>
        </figure>

        <figure>
          <a href={auditorium}>
            {" "}
            <img src={auditorium} alt="Auditorium" />
          </a>
          <figcaption>Auditorium</figcaption>
        </figure>

        <figure>
          <a href={swimmingPool}>
            <img src={swimmingPool} alt="Swimming Pool" />
          </a>
          <figcaption>Swimming Pool</figcaption>
        </figure>

        <figure>
          <a href={musicalFountain}>
            <img src={musicalFountain} alt=" Musical-Fountain" />
          </a>
          <figcaption> Musical-Fountain</figcaption>
        </figure>

        <figure>
          <a href={guestHouse}>
            {" "}
            <img src={guestHouse} alt="Guest House " />
          </a>
          <figcaption> Guest House</figcaption>
        </figure>

        <figure>
          <a href={sbiAtm}>
            {" "}
            <img src={sbiAtm} alt=" Sbi-Atm" />
          </a>
          <figcaption> Sbi-Atm</figcaption>
        </figure>

        <figure>
          <a href={library}>
            {" "}
            <img src={library} alt=" Library" />
          </a>
          <figcaption> Library</figcaption>
        </figure>

        <figure>
          <a href={gym}>
            {" "}
            <img src={gym} alt=" Gym" />
          </a>
          <figcaption> Gym</figcaption>
        </figure>
      </div>
      <div className="archive-container-in-about-us-page">
        <NavLink to="/archive" className="archive-navlink-in-about-us">Archive <FaArchive className="archive-icon-in-aboutus"/></NavLink>
      </div>
    </div>
  );
}

export default AboutUs;
