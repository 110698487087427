import React, { useState } from "react";

import profimg from "./IMG_0097-scaled-e1686895860538.jpg";

const DrSarikaSingh = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] =useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Dr. Sarika Singh</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            sarikasingh [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a >
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a >
                Scopus
              </a>
            </li>
            <li>
              <a >ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      PhD
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      4.5 Years
      </p>
     

     

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Research Area/ Project
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
             <p>Environmental Health, Occupational Health</p>  </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        <ul><li>Singh S, Mani KV, Kapoor N. Effect of occupational EMF exposure from radar at two different frequency bands on plasma melatonin and serotonin levels. International Journal of Radiation Biology 2015; 91(5):426-434.</li><li>Singh S, Kapoor N. Occupational EMF exposure from radar at X and Ku frequency band and plasma catecholamine levels. Bioelectromagnetics 2015; 36:444-450.</li><li>Singh S, Kapoor N. Health Implications of Electromagnetic Fields, Mechanisms of Action, and Research Needs. Advances in Biology 2014; Vol. 2014: Article ID 198609</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
      Book/Book Chapters
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
       <p>Singh S. High altitude sickness – solutions from genomics, proteomics and antioxidant interventions. Singapore: Springer Nature; 2022.</p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
      Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
        <ul><li>Presented paper (poster) entitled, “EMF exposure from X and Ku band Radar on oxidative stress biomarkers in military personnel” in 107th Indian Science Congress, organized by The Indian Science Congress Association (Professional Body under Department of Science and Technology, Ministry of Science and Technology, Govt. of India) in University of Agricultural Sciences, Bengaluru, Karnataka from 3rd to 7th January, 2020.</li><li>Presented paper entitled, “Climate Change and Health Risks – A Harsh Reality” in National Conference on Climate Change and Sustainable Development: Issues and Policy Initiatives organized by S. S. Jain Subodh P.G. College, Jaipur from 23rd to 24th December, 2019.</li><li>Oral presentation at the 61st National Conference of Occupational Health organized by Indian Association of Occupational Health, Vadodara from 16th – 19th Feb., 2011 on the topic, “A pilot study on the Occupational Health and Safety Status of Sewage Workers in Delhi”.</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
            setIsAwardsandRecOpen(!isAwardsandRecOpen)
        }
      >
     Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
    <p>Apex India Visionary Leader Award 2022</p>
        </div>
      ) : null}
    </div>
  );
};

export default DrSarikaSingh;
