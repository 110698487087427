import React from "react";
import "./DpsruLibrary.css";
import { NavLink,Outlet } from "react-router-dom";

import lr1 from "./Library Data/lr1.jpg";
import lr2 from "./Library Data/lr2.jpg";
import lr3 from "./Library Data/lr3.jpg";
import lr4 from "./Library Data/lr4.jpg";
import lr5 from "./Library Data/lr5.jpg";
import lr6 from "./Library Data/lr6.jpg";

const DpsruLibrary = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">DPSRU Library</h2>
      <div className="nirf-data-container">

        
        <section className="nirf-data-section-eact library-section-each">
          <NavLink
            to="/dpsruLibrary"
            className="nirf-botton library-navlink library-navlink"
          >
            About Us
          </NavLink>
        </section>

        
        <section className="nirf-data-section-eact library-section-each">
          <NavLink
            to="/dpsruLibrary/libraryStatistics"
            className="nirf-botton library-navlink library-navlink"
          >
            Library Statistics
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each ">
          <NavLink to='/dpsruLibrary/subscribedResources' className="nirf-botton library-navlink">
          
            Subscribed Resources
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/openAccessResources' className="nirf-botton library-navlink">
            Open Access Resources
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/researchSupport' className="nirf-botton library-navlink">
            {" "}
            Research Support
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/institutionalMembership' className="nirf-botton library-navlink">
            Institutional Membership
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/libraryPlanningCommittee' className="nirf-botton library-navlink">
            {" "}
            Library Planning Committee
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/libraryTeam' className="nirf-botton library-navlink">
            Library Team
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/libraryRulesAndRegulations' className="nirf-botton library-navlink">
            {" "}
            Library Rules & Regulations
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/askALibrarian' className="nirf-botton library-navlink">
            {" "}
            Ask a Librarian
          </NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/libraryEvent' className="nirf-botton library-navlink">Events</NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <a href="https://youtu.be/EJrSwk3trGQ" className="nirf-botton library-navlink" target="_blank" rel="noreferrer">Library Virtual Tour</a>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <NavLink to='/dpsruLibrary/LibraryFacultyInterview' className="nirf-botton library-navlink">Faculty Interview</NavLink>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <a href="http://dpsruir.ucanapply.com/public" target="_blank" rel="noreferrer" className="nirf-botton library-navlink">Institutinal Repository</a>
        </section>
        <Outlet/>
      </div>

      <div className="libraru-about-us-container">
        <h2 className="dpsru-secondary-heading-for-every-page" style={{color:'orange',borderTop:'2px solid'}}>
          About Library
        </h2>
        <p className="library-paraqgrauph-about-us">
          Library plays a vital role in furthering the academic, research and
          consultancy mission of DPSRU/DIPSAR and facilitates creation,
          dissemination and sharing of knowledge in the field of Pharmaceutical
          Sciences and its allied subjects.
          <br /> <br />
          It has one of the richest Pharmacy Library collections in North India
          with over 20,000+ books covering all branches of Pharmaceutical
          Sciences. The Pharmacognosy and Indian Medicinal Plants book
          collection is noteworthy The Library also has a section devoted to the
          Drugs & Pharmaceutical Sciences collection of the publisher Marcel
          Dekker. In the coming future, the Library plans to add more books in
          the Physiotherapy and Allied Health Science areas and focus on the
          e-journal and e-book collection. The Library budget ensures that the
          latest editions of books and journals are continuously made available.
          There is a continuous effort of the university to increase the
          acquisition of library electronic resources.
        </p>
        <h2 className="dpsru-secondary-heading-for-every-page">Library Area</h2>
        <p className="library-paraqgrauph-about-us">
          {" "}
          The air conditioned Library covers a total area of 4400 square feet
          with a reading area of 2000 square feet and a stacking area of 2400
          square feet (100 student sitting capacity).
        </p>
        <h2 className="dpsru-secondary-heading-for-every-page">
          Library Timing
        </h2>
        <p className="library-paraqgrauph-about-us">
          {" "}
          <span className="library-timing-heading">
            Library Opening Hours:- {"   "}
          </span>
          9:00 A.M. to 5:30 P.M. (Monday to Friday). <br />
          <span className="library-timing-heading">
            Issue/Return Hours Timings:-{"   "}{" "}
          </span>
          9:30 A.M. to 5:00 P.M. (Monday to Friday).{" "}
        </p>

        <h2 className="dpsru-secondary-heading-for-every-page">
          Library Services (Online Mode)
        </h2>

        {/* table */}
        <table className="library-table-online-mode">
          <tbody>
            <tr>
              <td>
                <h4>
                  {" "}
                  <span>
                    <b>Library Services(Online) </b>
                  </span>
                </h4>
              </td>
              <td>
                <h4>
                  <span>
                    <b>Provided through URL/ E-mail</b>
                  </span>
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  Alert Services: DPSRU in News, E- News alert, New Arrivals
                  alerts, Faculty Publications Alerts
                </span>
              </td>
              <td>
                <span>By E- mail</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Full text access to subscribed E-Resources</span>
              </td>
              <td>
                <span>
                  <a href="http://www.eurekaselect.com/">
                    <span>1.Bentham Science</span>
                  </a>
                  <span>: </span>
                  <a href="http://www.eurekaselect.com/">
                    <span>http://www.eurekaselect.com/</span>
                  </a>
                  <span></span>
                </span>

                <span>
                  <a href="https://www.proquest.com/">
                    <span>2.ProQuest Database</span>
                  </a>
                  <span> : </span>
                  <a href="https://www.proquest.com/">
                    <span>https://www.proquest.com/</span>
                  </a>
                  <span></span>
                </span>

                <span>(for Remote Access, useLogin ID and Password)</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Research article request</span>
              </td>
              <td>
                <span>By E- mail</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Literature Search service</span>
              </td>
              <td>
                <span>By E- mail</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Registration in Turnitin by E-mail</span>
              </td>
              <td>
                <span>
                  <span>DPSRU portal: </span>
                  <a href="https://dpsru.turnitin.com/home/sign-in">
                    <span>https://dpsru.turnitin.com/home/sign-in</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Shodhganga: E- Thesis Repository</span>
              </td>
              <td>
                <span>
                  <a href="https://shodhganga.inflibnet.ac.in/">
                    <span>https://shodhganga.inflibnet.ac.in/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Shodhgangotri: E- synopsis Repository</span>
              </td>
              <td>
                <span>
                  <a href="http://shodhgangotri.inflibnet.ac.in/">
                    <span>http://shodhgangotri.inflibnet.ac.in/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>VIDWAN</span>
              </td>
              <td>
                <span>
                  <a href="https://vidwan.inflibnet.ac.in/">
                    <span>https://vidwan.inflibnet.ac.in/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>IRNIS (Under Process)</span>
              </td>
              <td>
                <span>
                  <a href="https://irins.org/irins/">
                    <span>https://irins.org/irins/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Koha Web OPAC cum Library Website</span>
              </td>
              <td>
                <span>
                  <a href="http://dpsru.bestbookbuddies.com/">
                    <span>http://dpsru.bestbookbuddies.com/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  Digital Library of India (NDLI) for access E-
                  Books/Journals/Audio/Video Study material
                </span>
              </td>
              <td>
                <span>
                  <a href="https://ndl.iitkgp.ac.in/">
                    <span>https://ndl.iitkgp.ac.in/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Course-wise Question Papers of Previous Years</span>
              </td>
              <td>
                <span>
                  <a href="https://dpsru.edu.in/question-papers/">
                    <span>https://dpsru.edu.in/question-papers/</span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>DPSRU Syllabus</span>
              </td>
              <td>
                <span>https://dpsru.edu.in/download/syllabus/</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>M. Pharm dissertations / Ph. D. Thesis database</span>
              </td>
              <td>
                <span>
                  <a href="http://dpsru.bestbookbuddies.com/cgi-bin/koha/opac-search.pl?idx=kw&amp;q=MTH&amp;sort_by=relevance_dsc&amp;count=20&amp;limit=itype:TH">
                    <span>
                      http://dpsru.bestbookbuddies.com/cgi-bin/koha/opac-search.pl?idx=kw&amp;q=MTH&amp;sort_by=relevance_dsc&amp;count=20&amp;limit=itype:TH
                    </span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Download Book Recommendation Form</span>
              </td>
              <td>
                <span>
                  <a href="https://dpsru.edu.in/wp-content/uploads/2021/07/Final-PDF-Recommendation-Form.pdf">
                    <span>
                      https://dpsru.edu.in/wp-content/uploads/2021/07/Final-PDF-Recommendation-Form.pdf
                    </span>
                  </a>
                  <span></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <h2 className="dpsru-secondary-heading-for-every-page">
          Library Services (Offline Mode)
        </h2>

        <ul className="dpsru-library-ul">
          <li>
            <span>Wi Fi Connectivity</span>
          </li>
          <li>
            <span>Digital Library</span>
          </li>
          <li>
            <span>Issue/Return of documents</span>
          </li>
          <li>
            <span>
              Consultation for Old question paper/syllabus/M. Pharm
              dissertations/Ph. D. Thesis&nbsp;
            </span>
          </li>
          <li>
            <span>News Clipping related to DPSRU/DIPSAR&nbsp;</span>
          </li>
          <li>
            <span>Photocopy Service&nbsp;</span>
          </li>
          <li>
            <span>
              User Awareness/Orientation Programme, Book Exhibition Etc.
            </span>
          </li>
        </ul>
      </div>

      {/* galery */}
      <h3 className="galery-heading-of-g-20-maha-food-f">Gallery</h3>
      <div className="image-container-of-g-20-gallery img-container-library">
        <img src={lr1} alt="galery" />
        <img src={lr2} alt="galery" />
        <img src={lr3} alt="galery" />
        <img src={lr4} alt="galery" />
        <img src={lr5} alt="galery" />
        <img src={lr6} alt="galery" />
      </div>
    </div>
  );
};

export default DpsruLibrary;
