import React from "react";

import programSyllabus from "./Revised-Syllabus-B.PharmAyu-Final-4.pdf";
import ajaysirPic from "./AJAY-sir.jpg";
import foziyaPic from "./Foziyah zakir.jpeg";
import roshinipic from "./roshini.jpeg";
import amritaPic from "./amritamam.jpeg";
import saveenaMam from "./saveena.jpeg";

import { NavLink } from "react-router-dom";

import actAyurveda01 from "./Ayurveda Files/plantation-drive-for-Ayurvedic-medicinal-plants-at-DPSRU-Campus-on-28.02.2023-1.pdf";
import actAyurveda02 from "./Ayurveda Files/Revised-DPSRU_AIIA-MoU_Facility-Inauguration.pdf";
import actAyurveda03 from "./Ayurveda Files/HAR-GHAR-AYURVEDA-updated.pdf";
import actAyurveda04 from "./Ayurveda Files/Hands-on-training-of-Dissolution-Testing-apparatus-on-24.05.23.pdf";
import actAyurveda05 from "./Ayurveda Files/Hand-on-Traing-on-GC-MS.pdf";

import gal1 from "./Ayurveda Files/Har-Din-Har-Ghar-Ayurved-scaled.jpg";
import gal2 from "./Ayurveda Files/1212.jpg";
import gal3 from "./Ayurveda Files/326737672_500134035385247_1618591754528647534_n.jpg";
import gal4 from "./Ayurveda Files/331509936_1200061574048664_4592499579146540341_n.jpg";
import gal5 from "./Ayurveda Files/326732203_3321872908064230_3880910011558586663_n.jpg";
import gal6 from "./Ayurveda Files/334248755_1154696898553791_7710643147788604053_new.jpg";

import milletAwarenessProgram2010 from './Ayurveda Files/Millet Awareness Program on 27.10.2023.pdf'
import inaugrationofnavgrahavatika from './Ayurveda Files/Inauguration of Navgraha Vatika, Pratima of Lord Dhanwantai & Ayurveda Museum with orientation program of B.Pharm Ayurveda.pdf'

function Ayurveda() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Department of B. Pharm Ayurveda
      </h2>
      <h2 className="dpsru-secondary-heading-for-every-page">Programmes</h2>
      <div className="undergradute-program-box">
        <ul>
          <li>
            {" "}
            Undergraduate Programs{" "}
            <span>
              <a href={programSyllabus}>Syllabus</a>
            </span>
            <ul>
              <li>B Pharm Ayurveda</li>
            </ul>
          </li>
        </ul>
      </div>
      {/* Faculty details */}
      <div className="faculty-details-box">
        <h2 className="dipsar-sub-heading">Faculty Details</h2> <hr />
        <h2 className="dpsru-secondary-heading-for-every-page">
          Co Ordinator of B. Pharm Ayurveda
        </h2>
      </div>
      {/*  faculty box */}
      {/* each faculty Box */}
      <NavLink to="/" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={ajaysirPic} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Prof. Ajay Sharma</h3>
            <h4 className="faculty-position">Professor</h4>
          </div>
        </div>
      </NavLink>{" "}
      <hr />
      <h2 className="dpsru-secondary-heading-for-every-page">Faculty</h2>
{/* each faculty Box */}
<NavLink to="/" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={amritaPic} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Amrita Mishra</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
{/* each faculty Box */}
<NavLink to="/" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={foziyaPic} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Foziyah Zakir</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
 {/* each faculty Box */}
 <NavLink to="/" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={roshinipic} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Roshni Tandey</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>{" "}
      <hr />
      {/* each faculty Box */}
      <NavLink to="/" className="each-faculty-box-navlink-container">
        <div className="each-faculty-box">
          <div className="each-faculty-img">
            <img src={saveenaMam} alt="faculty" />
          </div>
          <div className="each-faculty-name-and-post">
            <h3 className="faculty-name">Dr. Saveena Chauhan</h3>
            <h4 className="faculty-position">Assistant Professor</h4>
          </div>
        </div>{" "}
      </NavLink>
      <hr />
     
      
      
      <div className="dipsar-activity-container results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Activities</h2>

        <ul>
          <li>
            <a href={milletAwarenessProgram2010}>
            Millet Awareness Program on 27.10.2023
            </a>
          </li>
          <li>
            <a href={inaugrationofnavgrahavatika}>
            Inauguration of Navgraha Vatika, Pratima of Lord Dhanwantai & Ayurveda Museum with orientation program of B.Pharm Ayurveda
            </a>
          </li>
          <li>
            <a href={actAyurveda01}>
              Plantation drive for Ayurvedic medicinal plants at DPSRU Campus on
              28.02.2023
            </a>
          </li>
          <li>
            <a href={actAyurveda02}>
              DPSRU Signs MoU with All India Institute of Ayurveda
            </a>
          </li>
          <li>
            <a href={actAyurveda03}>
              DPSRU celebrates “Har Din Har Ghar Ayurveda” on 03-08 Oct, 2022
            </a>
          </li>
          <li>
            <a href={actAyurveda04}>
              Hands-on-training of Dissolution Testing apparatus on 24.05.23
            </a>
          </li>
          <li>
            <a href={actAyurveda05}>Hands-on training on GC/MS</a>
          </li>
        </ul>
      </div>
      <h2 className="dpsru-secondary-heading-for-every-page">Gallery</h2>
      <div className="cmyp-gallery-container">
        <img src={gal1} alt="gallary" />
        <img src={gal2} alt="gallary" />
        <img src={gal3} alt="gallary" />
        <img src={gal4} alt="gallary" />
        <img src={gal5} alt="gallary" />
        <img src={gal6} alt="gallary" />
      </div>
    </div>
  );
}

export default Ayurveda;
