import React from 'react'
import './InternationalAccolades.css'

import ia01 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-5_page-0001-1651x2048.jpg'
import ia02 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-4_page-0001-1655x2048.jpg'
import ia03 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-3_page-0001-1672x2048.jpg'
import ia04 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-2_page-0001-scaled.jpg'
import ia05 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-1_page-0001-scaled.jpg'
import ia06 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-7_page-0001-1647x2048.jpg'
import ia07 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-6_page-0001-1-1666x2048.jpg'
import ia08 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-9_page-0001-1-1661x2048.jpg'
import ia09 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-12_page-0001-scaled.jpg'
import ia10 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-13_page-0001-1646x2048.jpg'
import ia11 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-14_page-0001-1655x2048.jpg'
import ia12 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-17_page-0001-1682x2048.jpg'
import ia13 from './international Accolades files/Awards-of-Prof-Ramesh-K-Goyal-17_page-0001-1682x2048.jpg'
import ia14 from './international Accolades files/Screenshot-2023-07-10-142126.jpg'
import ia15 from './international Accolades files/Screenshot-2023-07-10-142321.jpg'
import ia16 from './international Accolades files/Screenshot-2023-07-10-142422-1.jpg'
import ia17 from './international Accolades files/Screenshot-2023-07-10-142711.jpg'
import ia18 from './international Accolades files/Screenshot-2023-07-10-142851.jpg'
import ia19 from './international Accolades files/Screenshot-2023-07-10-143019.jpg'

const InternationalAccolades = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className="dpsru-primary-heading-for-every-page">
    International Accolades
    </h2>

<div className="internation-accolades-image-container">

    <div className="internation-accolades-image-box">
        <img src={ia01} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia02} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia03} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia04} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia05} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia06} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia07} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia08} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia09} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia10} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia11} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia12} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia13} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia14} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia15} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia16} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia17} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia18} alt="international Accolades" />
    </div>

    <div className="internation-accolades-image-box">
        <img src={ia19} alt="international Accolades" />
    </div>


</div>


</div>
  )
}

export default InternationalAccolades