import React from "react";
import "./OrganizationAndFunction.css";
import biodata from "./BIODATA-July-2022.pdf";
import status from "./statutes.pdf";
import sopodpsru from "./Standard-Operating-Procedure-of-DPSRU_compressed.pdf";
import standardIperationProcedure from "./Standard-Operating-Procedure-of-DPSRU_compressed (1).pdf";
import payStructure from "./Pay-structure-DPSRU-1.pdf";

function OrganizationAndFunction() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Organization And Function
      </h2>

      <div className="organization-and-function" style={{ marginBottom: "3%" }}>
        <table border="1" cellpadding="5" cellspacing="0">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Item</th>
              <th>Details of Disclosure</th>
              <th>Remarks/Reference Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.1</td>
              <td>Particulars of its organization, functions and duties</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Name and address of the Organization</li>
                  <br />
                  <li>
                    Head of the organization
                    <br />
                  </li>
                  <br />
                  <li>
                    Vision, Mission and Key objectives
                    <br />
                  </li>
                  <br />
                  <li>
                    Function and duties
                    <br />
                  </li>
                  <br />
                  <li>
                    Organization Chart
                    <br />
                  </li>
                  <br />
                  <li>
                    Any other details-the genesis, inception, formation of the
                    department and the HoDs from time to time as well as the
                    committees/ Commissions constituted from time to time have
                    been dealt
                  </li>
                  <br />
                </ol>
              </td>
              <td>
                {" "}
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Delhi Pharmaceutical Sciences and Research University
                    (DPSRU/DIPSAR), Mehrauli-Badarpur road, Sector-3, Pushp
                    Vihar, New Delhi- 110017
                    <br />
                    <a href="https://dpsru.edu.in/contact-us/">Click Here</a>
                  </li>
                  <br />
                  <li>
                    Information is available on the website
                    <br />
                    <a href="https://dpsru.edu.in/vice-chancellor-dpsru">
                      Click Here
                    </a>
                  </li>
                  <br />
                  <li>
                    Information is available on the website
                    <br />
                    <a href="https://dpsru.edu.in/visionMissionObjectives&QualityPolicy">
                      Click Here
                    </a>
                  </li>
                  <br />
                  <li>
                    Information is available on the website <br />
                    Click Here
                    <a href="https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf">
                      https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf
                    </a>
                    <a href=" https://dpsru.edu.in/static/media/statutes.3bf5a69c5d7c9db1d5ff.pdf">
                      {" "}
                      https://dpsru.edu.in/static/media/statutes.3bf5a69c5d7c9db1d5ff.pdf
                    </a>
                    <a href=" https://dpsru.edu.in/static/media/SecondStatuitesofDPSRU.3396f1a47a088a50c717.pdf">
                      {" "}
                      https://dpsru.edu.in/static/media/SecondStatuitesofDPSRU.3396f1a47a088a5
                      0c717.pdf
                    </a>
                    <a href=" https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf">
                      {" "}
                      https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b
                      37.pdf
                    </a>
                    <a href="  https://dpsru.edu.in/static/media/Standard%20Operating%20Procedure%20of%20DPSRU%20(2).da634e58c324a160ef23.pdf">
                      {" "}
                      https://dpsru.edu.in/static/media/Standard
                      %20Operating%20Procedure%20of%20DPSRU%20(2).
                      da634e58c324a160ef23.pdf
                    </a>
                    <a href="https://dpsru.edu.in/static/media/FirstOrdinances.c9a8bf1a0f6f79c31ee4.pdf">
                      https://dpsru.edu.in/static/media/ FirstOrdinances.
                      c9a8bf1a0f6f79c31ee4.pdf
                    </a>
                  </li>
                  <br />
                  <li>
                    Click Here{" "}
                    <a href=" https://www.dpsru.edu.in/organogram">
                      {" "}
                      https://www.dpsru.edu.in/organogram
                    </a>{" "}
                    <br />
                    <a href="https://www.dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://www.dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    DPSR University was established on October 24, 2008 by state
                    legislature of Delhi as a state University. The DPSR
                    University Act, 2008, University was made functional by
                    notification on 24th July 2015. DPSR University is currently
                    offering 37 programmes through 10 departments and 8 centres.
                    Details of the genesis, inception, formation of department
                    and the HoDs, are available in the website. Details of
                    administrative heads are also available in the website. The
                    University forms various committees from time to time and
                    details of these committees are available off-line <br />{" "}
                    Click Here <br />
                    <a href="https://dpsru.edu.in/dipsar">
                      {" "}
                      https://dpsru.edu.in/dipsar
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/soPharmaceticalSciences">
                      https://dpsru.edu.in/soPharmaceticalSciences
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/sahsm">
                      https://dpsru.edu.in/sahsm
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/school-of-physiotherapy">
                      https://dpsru.edu.in/school-of-physiotherapy
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/doctoralPrograms">
                      https://dpsru.edu.in/doctoralPrograms
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/wcsc">
                      https://dpsru.edu.in/wcsc
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/assrm">
                      https://dpsru.edu.in/assrm
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/centresOfExcellence">
                      https://dpsru.edu.in/centresOfExcellence
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/certificateCourses">
                      https://dpsru.edu.in/certificateCourses
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf">
                      https://dpsru.edu.in/static/media/Acad
                      mics-sec-pdf-Skill-Hub-Inititative- under-PMKVY-4.0-in-
                      DPSRU.121b3fde0a06c3542a84.pdf
                    </a>
                    <br />
                  </li>
                  <br />
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.2</td>
              <td>Power and duties of its officers and employees</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Powers and duties of officers (administrative, financial and
                    judicial)
                  </li>
                  <br />

                  <li>Power and duties of other employees</li>
                  <br />

                  <li>
                    Rules/ orders under which powers and duty are derived and
                  </li>
                  <br />

                  <li>Exercised</li>
                  <br />

                  <li>Work allocation</li>
                  <br />
                </ol>
              </td>
              <td>
                {" "}
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Click Here <br />
                    <a href="https://dpsru.edu.in/staff">
                      https://dpsru.edu.in/staff
                    </a>
                  </li>
                  <br />

                  <li>
                    Duties of other employees are prescribed in the DPSR
                    University Act, Statutes and Ordinances of the University{" "}
                    <br />
                    Click Here <br />
                    <a href="https://www.dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://www.dpsru.edu.in/Authoritiesof theUniversity
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/dipsar">
                      https://dpsru.edu.in/dipsar
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/soPharmaceticalSciences">
                      https://dpsru.edu.in/soPharmaceticalSciences
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/sahsm">
                      https://dpsru.edu.in/sahsm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/school-of-physiotherapy">
                      https://dpsru.edu.in/school-of-physiotherapy
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/doctoralPrograms">
                      https://dpsru.edu.in/doctoralPrograms
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/wcsc">
                      https://dpsru.edu.in/wcsc
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/assrm">
                      https://dpsru.edu.in/assrm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/centresOfExcellence">
                      https://dpsru.edu.in/centresOfExcellence
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/certificateCourses">
                      https://dpsru.edu.in/certificateCourses
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf">
                      https://dpsru.edu.in/static/media/Acad
                      mics-sec-pdf-Skill-Hub-Inititative-
                      under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf
                    </a>{" "}
                    <br />
                    <a
                      href="https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf
"
                    >
                      https://dpsru.edu.in/static/media/SOP
                      ACADEMICDPSRU.c9d2c6f3daa835f99b 37.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Standard Operating Procedure of DPSRU (2).da634e58c324a160ef23.pdf">
                      https://dpsru.edu.in/static/media/Standard Operating
                      Procedure of DPSRU (2).da634e58c324a160ef23.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf">
                      https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf
                    </a>{" "}
                    <br />
                  </li>

                  <br />
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.3</td>
              <td>Procedure followed in decision making process</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Process of decision-making Identify key decision-making
                    points
                  </li>{" "}
                  <br />
                  <li>Final decision making authority</li> <br />
                  <li>Related provisions, acts, rules etc.</li> <br />
                  <li>Time limit for taking a decisions, if any</li> <br />
                  <li>Channel of supervision and accountability</li> <br />
                </ol>
              </td>
              <td>
                {" "}
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    <ul>
                      <li>
                        {" "}
                        To promote and encourage outstanding research work by
                        enabling research facilities to the faculty and
                        researchers.
                      </li>
                      <li>
                        {" "}
                        To conduct free/transparent and quality examinations to
                        enrol meritorious students
                      </li>
                      <li>
                        {" "}
                        To organise and to undertake extra- mural studies,
                        training and extension services
                      </li>
                      <li>
                        {" "}
                        To appoint persons of excellent academic credentials as
                        faculty
                      </li>
                      <li>
                        {" "}
                        To institute and award fellowships, scholarships,
                        studentships, medals and prizes
                      </li>
                      <li>
                        {" "}
                        To promote inclusive education Click Here
                        <br />
                        <a href="https://dpsru.edu.in/governingActs">
                          https://dpsru.edu.in/governingActs
                        </a>{" "}
                        <br />{" "}
                        <a href="https://dpsru.edu.in/visionMissionObjectives&QualityPolicy">
                          https://dpsru.edu.in/visionMissionObjectives&QualityPolicy
                        </a>
                      </li>
                      <br />
                    </ul>
                  </li>
                  <br />
                  <li>
                    The Board of Governors are the governing authorities of the
                    University. <br /> click here <br />
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    click here <br />
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf">
                      https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Standard Operating Procedure of DPSRU (2).da634e58c324a160ef23.pdf">
                      https://dpsru.edu.in/static/media/Standard Operating
                      Procedure of DPSRU (2).da634e58c324a160ef23.pdf
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf">
                      https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf
                    </a>{" "}
                    <br />
                  </li>{" "}
                  <br />
                  <li>Case to case basis</li> <br />
                  <li>
                    The University follows the procedure laid down in the DPSR
                    University Act 2008, and as prescribed in the Statutes and
                    Ordinances Click Here <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>
                  </li>{" "}
                  <br />
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.4</td>
              <td>Norms set for the discharge of functions</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Nature of functions/services offered</li> <br />
                  <li>Norms/ standards for functions/ service delivery</li>{" "}
                  <br />
                  <li>Process by which these services can be accessed</li>{" "}
                  <br />
                  <li>Time-limit for achieving the targets</li> <br />
                  <li>Process of redress of grievances</li> <br />
                </ol>
              </td>
              <td>
                {" "}
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    In accordance with the DPSR University Act 2008, and as
                    prescribed in the Statutes and Ordinances <br /> Click Here{" "}
                    <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf">
                      https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Standard Operating Procedure of DPSRU (2).da634e58c324a160ef23.pdf">
                      h ttps://dpsru.edu.in/static/media/Standard Operating
                      Procedure of DPSRU (2).da634e58c324a160ef23.pdf
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf">
                      https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf
                    </a>{" "}
                  </li>
                  <br />
                  <li>
                    The University and its employees discharge their functions
                    and duties in accordance with the DPSR University Act 2008,
                    and as prescribed in the Statutes and Ordinances
                    <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf">
                      https://dpsru.edu.in/static/media/SOPACADEMICDPSRU.c9d2c6f3daa835f99b37.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Standard Operating Procedure of DPSRU (2).da634e58c324a160ef23.pdf">
                      https://dpsru.edu.in/static/media/Standard Operating
                      Procedure of DPSRU (2).da634e58c324a160ef23.pdf
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf">
                      https://dpsru.edu.in/static/media/DPSRUACT.c6ada29821cec1b77061.pdf
                    </a>{" "}
                  </li>{" "}
                  <br />
                  <li>
                    The University services are open and transparent. Detailed
                    activities of the university are highlighted in Annual
                    Reports/Newsletters/Annual Accounts of the University, which
                    are available in the website: Click Here <br />
                    <a href="https://dpsru.edu.in/annualReport">
                      https://dpsru.edu.in/annualReport
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/newsletters">
                      https://dpsru.edu.in/newsletters
                    </a>
                  </li>{" "}
                  <br /> <li>Case to case basis</li> <br />
                  <li>
                    Online Grievance Registration System and presence of
                    Internal Complaints Committee. Information is available
                    online under the links: The University is also registered
                    with the public grievances portal of GNCT Delhi <br />
                    Click Here <br />{" "}
                    <a href="https://pgms.delhi.gov.in/">
                      https://pgms.delhi.gov.in/
                    </a>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.5</td>
              <td>
                Rules, regulations, instructions, manuals and records, held by
                it or under its control or used by its employees for discharging
                its functions
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Title and nature of the record/ manual /instruction</li>
                  <li>
                    List of Rules, regulations, instructions manuals and records
                  </li>
                  <li>Acts/ Rules manuals etc.</li>
                  <li>Transfer policy and transfer orders</li>
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  All rules and regulations are available on the University’s
                  website <br />
                  Click Here
                  <br />
                  <li>
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="https://dpsru.edu.in/static/media/rti1.f355da6ed16e9fa349b7.pdf">
                      https://dpsru.edu.in/static/media/rti1.f355da6ed16e9fa349b7.pdf
                    </a>{" "}
                    <br />
                  </li>
                  <li>
                    {" "}
                    <a href="https://dopt.gov.in/ccs-cca-rules-1965">
                      https://dopt.gov.in/ccs-cca-rules-1965
                    </a>{" "}
                    <br />
                  </li>
                  <li>
                    Not Applicable as there is no other branch/office of the
                    DPSRU/DIPSAR
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.6</td>
              <td>
                Categories of documents held by the authority under its control
                [Section 4(1)(b) (vi)]
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Categories of documents</li>
                  <li>Custodian of documents/categories</li>
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  {" "}
                  <li>
                    Vital - Act, Statute, Ordinance, Land related documents,
                    UGC, AICTE, PCI approval letter, Decision of the
                    authorities:
                    <br />
                    <b>Board of Studies, Master Plan of the University</b>
                    <br />
                    (a) Assets Register
                    <br />
                    (b) Stock Register
                    <br />
                    (c) Building Register
                    <br />
                    (d) Annual Accounts with AG (Audit) Report.
                    <br />
                    (e) Personal files/ Service records of all employees
                    <br />
                    (f) Contract / Agreement / Memorandum of Understandings
                    (MoUs)
                    <br />
                    (g) Students information
                    <br />
                    (h) Examination results
                    <br /> <br />
                    (2)<b> Routine :-</b>
                    <br />
                    (a) Correspondence with Public
                    <br />
                    (b) Other Organizations
                    <br />
                    (c) Employees
                  </li>{" "}
                  <br />
                  <li>
                    Registrar, Deans, Finance Office, Controller of Examinations
                    and Concerned Heads of Departments
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.7</td>
              <td>
                Boards, Councils, Committees and other Bodies constituted as
                part of the Public Authority
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Name of Boards, Council, Committee etc.</li> <br />
                  <br />
                  <li>Composition</li> <br />
                  <br />
                  <li>Date from which constituted</li> <br />
                  <br />
                  <li>Term/Tenure</li> <br />
                  <br />
                  <li>Powers and functions</li> <br />
                  <br />
                  <li>Whether their meetings are open to the public?</li> <br />
                  <br />
                  <li>
                    Whether the minutes of the meetings are open to the public?
                  </li>{" "}
                  <br />
                  <br />
                  <li>
                    Place where the minutes, if open to the public, are
                    available?
                  </li>{" "}
                  <br />
                  <br />
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    The information is available on the website under the head
                    Authorities of the University <br />
                    Click Here
                    <br />
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>
                    <br />
                    <a href="https://dpsru.edu.in/committees">
                      https://dpsru.edu.in/committees
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    Information is available on the website <br />
                    Click Here <br />
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/genralCounsil">
                      https://dpsru.edu.in/genralCounsil
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    <b>17.08.2022 </b>
                    <br />
                    Information is available on the website <br />
                    Click Here <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    As per Statutes and Ordinances <br /> Click Here <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />{" "}
                    <a href="https://dpsru.edu.in/genralCounsil">
                      https://dpsru.edu.in/genralCounsil
                    </a>
                  </li>{" "}
                  <br />
                  <li>
                    {" "}
                    In accordance with the Statutes and Ordinances <br /> Click
                    Here <br />{" "}
                    <a href="https://dpsru.edu.in/governingActs">
                      https://dpsru.edu.in/governingActs
                    </a>{" "}
                    <br />{" "}
                    <a href="https://dpsru.edu.in/genralCounsil">
                      https://dpsru.edu.in/genralCounsil
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Standard%20Operating%20Procedure%20of%20DPSRU%20(2).da634e58c324a160ef23.pdf">
                      https://dpsru.edu.in/static/media/Standard%20Operating%20Procedure%20of%20DPSRU%20(2).da634e58c324a160ef23.pdf
                    </a>
                  </li>{" "}
                  <br />
                  <li>No</li> <br />
                  <li>No</li> <br />
                  <li>Not Applicable</li> <br />
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.8</td>
              <td>Directory of its officers and employees</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Name and designation</li> <br />
                  <li>Telephone , fax and email ID</li>
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Available on the website under the head <br />
                    Click Here <br />
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/dipsar">
                      https://dpsru.edu.in/dipsar
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/soPharmaceticalSciences">
                      https://dpsru.edu.in/soPharmaceticalSciences
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/sahsm">
                      https://dpsru.edu.in/sahsm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/school-of-physiotherapy">
                      https://dpsru.edu.in/school-of-physiotherapy
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/doctoralPrograms">
                      https://dpsru.edu.in/doctoralPrograms
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/wcsc">
                      https://dpsru.edu.in/wcsc
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/assrm">
                      https://dpsru.edu.in/assrm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/centresOfExcellence">
                      https://dpsru.edu.in/centresOfExcellence
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/certificateCourses">
                      https://dpsru.edu.in/certificateCourses
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf">
                      https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf
                    </a>{" "}
                    <br />
                  </li>{" "}
                  <br />
                  <li>
                    Information is available on the website <br />
                    Click Here
                    <a href="https://dpsru.edu.in/AuthoritiesoftheUniversity">
                      https://dpsru.edu.in/AuthoritiesoftheUniversity
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/dipsar">
                      https://dpsru.edu.in/dipsar
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/soPharmaceticalSciences">
                      https://dpsru.edu.in/soPharmaceticalSciences
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/sahsm">
                      https://dpsru.edu.in/sahsm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/school-of-physiotherapy">
                      https://dpsru.edu.in/school-of-physiotherapy
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/doctoralPrograms">
                      https://dpsru.edu.in/doctoralPrograms
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/wcsc">
                      https://dpsru.edu.in/wcsc
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/assrm">
                      https://dpsru.edu.in/assrm
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/centresOfExcellence">
                      https://dpsru.edu.in/centresOfExcellence
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/certificateCourses">
                      https://dpsru.edu.in/certificateCourses
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf">
                      https://dpsru.edu.in/static/media/Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.121b3fde0a06c3542a84.pdf
                    </a>{" "}
                    <br />
                    <a href="https://dpsru.edu.in/staff/">
                      https://dpsru.edu.in/staff/
                    </a>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.9</td>
              <td>
                Monthly Remuneration received by officers & employees including
                system of compensation
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>List of employees with Gross monthly remuneration</li>{" "}
                  <br />
                  <li>System of compensation as provided in its regulations</li>
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Information is available on the website <br />
                    Click here <br />
                    <a href="https://dpsru.edu.in/static/media/List%20of%20employees%20of%20DPSRU%20January%202024.c656633632cf053cdcbc.pdf">
                      https://dpsru.edu.in/static/media/List%20of%20employees%20of%20DPSRU%20January%202024.c656633632cf053cdcbc.pdf
                    </a>
                  </li>
                  <br />
                  <li>
                    {" "}
                    As per the Rules and Regulations of Government of NCT of
                    Delhi <br />
                    <a href="https://dopt.gov.in/monthly-remuneration">
                      https://dopt.gov.in/monthly-remuneration
                    </a>
                  </li>
                </ol>
                <br />
              </td>
            </tr>
            <tr>
              <td>1.10</td>
              <td>
                Name, designation and other particulars of public information
                officers
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    Name and designation of the Public Information Officer
                    (PIO), Assistant Public Information Officer(s) (APIO(s)) &
                    Appellate Authority
                  </li>{" "}
                  <br />
                  <li>
                    Address, telephone numbers and email ID of each designated
                    official
                  </li>
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    <b> Public Information Officer:</b> <br />
                    Dr. J. Swaminathan, Assistant Professor <br />
                    <b>Assistant Public Information Officer:</b> <br />
                    Mr. Aditya Kaushik, Section Officer <br />
                    <b>First Appellate Authority:</b> <br />
                    Dr.P.K.Sahoo, Offg. Registrar <br />
                    Click Here <br />{" "}
                    <a href="https://dpsru.edu.in/cpioAndFaa">
                      https://dpsru.edu.in/cpioAndFaa
                    </a>
                  </li>
                  <br />
                  <li>
                    Click Here <br />{" "}
                    <a href="https://dpsru.edu.in/cpioAndFaa">
                      https://dpsru.edu.in/cpioAndFaa
                    </a>
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.11</td>
              <td>
                No. Of employees against whom Disciplinary action has been
                proposed/ taken
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    No. of employees against whom disciplinary action has been{" "}
                    <br />
                    (i) Pending for Minor penalty or major penalty proceedings
                    <br />
                    <br />
                    (ii) Finalised for Minor penalty or major penalty
                    proceedings
                  </li>
                </ol>
              </td>
              <td>
                (i) There is no pending case of disciplinary proceedings as on
                this date
                <br />
                <br />
                (ii) NIL
              </td>
            </tr>
            <tr>
              <td>1.12</td>
              <td>Programmes to advance understanding of RTI</td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>Educational programmes</li> <br />
                  <li>
                    Efforts to encourage public authority to participate in
                    these programmes
                  </li>{" "}
                  <br />
                  <li>Training of CPIO/APIO</li> <br />
                  <li>
                    Update & publish guidelines on RTI by the Public Authorities
                    concerned
                  </li>{" "}
                  <br />
                </ol>
              </td>
              <td>
                <ol style={{ padding: "0 18px" }}>
                  <li>
                    From time to time officers and stakeholders have been
                    undergoing training/workshops related to understanding of
                    RTI
                  </li>{" "}
                  <br />
                  <li>
                    From time to time officers and stakeholders have been
                    undergoing training/workshops related to understanding of
                    RTI
                  </li>{" "}
                  <br />
                  <li>Yes</li> <br />
                  <li>
                    Guideline is available under the link “ RTI” <br /> Click
                    Here <br />{" "}
                    <a href="https://dpsru.edu.in/rti-act-2/">
                      https://dpsru.edu.in/rti-act-2/
                    </a>
                  </li>{" "}
                  <br />
                </ol>
              </td>
            </tr>
            <tr>
              <td>1.13</td>
              <td>Transfer policy and transfer orders</td>
              <td>-</td>
              <td>
                DPSRU University is a first University dedicated to the
                Pharmaceutical Sciences in India. DIPSAR in same campus and it
                is constituent college. Hence, employees of DIPSAR are not
                transferred elsewhere. However, internal transfers are carried
                out from time to time. <br />
                Click Here <br />{" "}
                <a href="https://dpsru.edu.in/governingActs">
                  https://dpsru.edu.in/governingActs
                </a>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrganizationAndFunction;
