import React from "react";
import "./Forms.css";

import form01 from "./Forms File/IPR_-form.pdf";
import form02 from "./Forms File/part-time-guest-faculty-claim-form.pdf";
import form03 from "./Forms File/ID-card-format.pdf";
import form04 from "./Forms File/performa-for-taking-prior-permission-by-govt-servants-for-private-visit-abroad_rotated.pdf";
import form05 from "./Forms File/Conveyance-Bill-Charges-Form_rotated.pdf";
import form06 from "./Forms File/application-for-maternity-or-paternity-leave_rotated.pdf";
import form07 from "./Forms File/Application-for-Child-Care-Leave_rotated.pdf";
import form08 from "./Forms File/Application-for-Casual-Leave-Restricted-Holiday-Office-Duty-and-Compensatory-Leave_rotated.pdf";
import form09 from "./Forms File/no-dues-certificate-for-person-retiring-releasing-transfered.pdf";

// Annual Performance Assessment Report Forms

import aparForms01 from './Forms File/registrar.pdf'
import aparForms02 from './Forms File/apar-new.pdf'
import aparForms03 from './Forms File/group-C-officials.pdf'
import aparForms04 from './Forms File/library.pdf'
import aparForms05 from './Forms File/med-officer.pdf'
import aparForms06 from './Forms File/laboratory-attendants.pdf'
import aparForms07 from './Forms File/driver.pdf'
import leaveformatnew from './Forms File/leave perfoma.pdf'

function Forms() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">FORMS</h2>
      <div className="inner-container">
        <ol>
          <li>
            <a href={leaveformatnew}>
            Leave Format  CL/EL/Other
            </a>
          </li>
          <li>
            <a href={form01}>
              IPR(Immovable Property Return) form
            </a>
          </li>
          <li>
            <a href={form02}>
              Part Time Guest Faculty Claim Form
            </a>
          </li>
          <li>
            <a href={form03}>
              Staff ID Card Form
            </a>
          </li>
          <li>
            <a href={form04}>
              Performa for Taking Prior Permission by Govt Servants for Private
              Visit Abroad
            </a>
          </li>
          <li>
            <a href={form05}>
              Conveyance Bill Charges Form
            </a>
          </li>
          <li>
            <a href={form06}>
              Application for Maternity or Paternity Leave
            </a>
          </li>
          <li>
            <a href={form07}>
              Application for Child Care Leave
            </a>
          </li>
          {/* <li>
            <a href={form08}>
              Application for Casual Leave, Restricted Holiday, Office Duty and
              Compensatory Leave
            </a>
          </li> */}
          <li>
            <a href={form09}>
              No Dues Certificate for Person Retiring/Releasing/Transferred
            </a>
          </li>
        </ol>

        <h2 className="dpsru-secondary-heading-for-every-page">
          {" "}
          Annual Performance Assessment Report Forms
        </h2>
        <ol>
          <li>
            <a href={aparForms01}>
              Registrar/ Deputy Registrar/ Assistant Registrar/ Assistant
              Controller of Examination/Section Officer
            </a>
          </li>
          <li>
            <a href={aparForms02}>
              Professor/ Associate Professor/ Assistant Professor
            </a>
          </li>
          <li>
            <a href={aparForms03}>
              Officials Group C
            </a>
          </li>
          <li>
            <a href={aparForms04}>
              Assistant Librarian/ Library Assistant/ Library Attendant
            </a>
          </li>
          <li>
            <a href={aparForms05}>
              Medical Officer/Nurse
            </a>
          </li>
          <li>
            <a href={aparForms06}>
              Laboratory Attendants
            </a>
          </li>
          <li>
            <a href={aparForms07}>
              Driver
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}
export default Forms;
