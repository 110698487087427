import React, { useState } from "react";

import profimg from "./tab-new-pic-tabassum-saher-150x150.jpg";

const DrTabassumSaher = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] = useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Tabassum Saher</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :
           
              drtabassumsaher [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li style={{ textTransform: "lowercase" }}>
              <a href="https://scholar.google.com/citations?hl=en&user=oAtrDkoAAAAJ">
                Google Scholar
              </a>
            </li>
            <li style={{ textTransform: "lowercase" }}>
              <a>Scopus</a>
            </li>
            <li style={{ textTransform: "lowercase" }}>
              <a href="http://orcid.org/0000-0002-3020-275X">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        MPT (Cardiopulmonary)
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Teaching Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph"></p>
      <h2 className="academic-qualification-heading-for-every-page">
        Clinical Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph"></p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research Area
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <p>PULMONARY REHBALITATION, IMT TRAINING, PALLIATIVE CARE</p>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ol>
            <li style={{ textTransform: "lowercase" }}>
              <b></b>
              https://jmscr.igmpublication.org/home/index.php/archive/154-volume-06-issue-09-september-2018/5703-dyke-davidoff-masson-syndrome-a-case-report
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://jmscr.igmpublication.org/home/index.php/archive/154-volume-06-issue-09-september-2018/5730-a-comparative-study-of-effectiveness-between-diaphragmatic-breathing-and-resistive-inspiratory-muscle-training-on-pulmonary-functions-erv-during-pregnancy
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://jmscr.igmpublication.org/home/index.php/archive/154-volume-06-issue-09-september-2018/5741-effect-of-cpap-therapy-on-catathrenia-and-osa-a-case-report
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.researchgate.net/publication/ <br />
              327729122_A_comparative_study_of_Effectiveness_between_Diaphragmatic_Breathing_and_Resistive_Inspiratory_Muscle_Training_on_Pulmonary_Functions_ERV_during_Pregnancy
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.researchgate.net/publication/327435614_Role_and_Importance_of_Physiotherapy_during_Palliative_Care_in_India_A_systematic_Review
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.researchgate.net/publication/327435422_A_Study_of_Correlation_between_the_Backward_Tandem_Walk_Test_with_Balance_and_Functional_Mobility_in_Elderly_Population
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.wjrr.org/download_data/WJRR0704003.pdf
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.researchgate.net/publication/330467103_Relationship_between_Age_and_Balance_Performance_in_Elderly_Population_A_Review
            </li>
            <li style={{ textTransform: "lowercase" }}>
              https://www.wjrr.org/download_data/WJRR0704003.pdf
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Invited Lecture
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
         
            <p>
              <b>Oral/ Paper Presentations:</b>
            </p>
            <ul>
              <li >
                <ol>
                  <li>
                   Paper presented in
                    <b>
                      
                      International Conference on “ to compare the performance
                      of active and sedentary older adults on modified physical
                      performance test and fullerton advanced balance
                      scale” (2016).
                    </b>
                  </li>
                  <li>
                   Paper presented in
                    <b>
                      
                      International Conference on “ bhagavadgita as a basis of
                      treatment of Parkinson Disease”, Guru jambheshwar
                      university of science  technology, Hisar ( 2016)
                    </b>
                  </li>
                  <li>
                   Paper presented in
                    <b> 4</b>
                    <b>th</b>
                    <b>
                      
                      annual physiotherapy conference of Indian association of
                      physiotherapist “Physiocon Chandigarh 2017 on “balance
                      training versus core stability in improving balance”,
                      Chandigarh, ( 2017).
                    </b>
                  </li>
                  <li>
                   Paper presented
                    <b>
                      
                      in international conference on positive psychology for
                      health and well-being on “ the use of psychotherapy in
                      physiotherapy”, Guru jambheshwar university of science
                       technology, Hisar .( 2018)
                    </b>
                  </li>
                  <li>
                   Paper presented
                    <b>
                      
                      in first global higher educational conclave on “ The
                      Importance of religious education in therapeutic
                      conditions”, Murthal, Haryana. ( 2018)
                    </b>
                  </li>
                  <li>
                   Paper presented 
                    <b>
                      on Postural sway in Diabetic Neuropathy Patients using
                      swaymeter in International Conference of Physical Therapy,
                      AIIMS, 2017
                    </b>
                  </li>
                  <li>
                   Paper presented
                    <b>
                      
                      in national conference on “ the role of physiotherapy in
                      pain management” , G.D. goenka University, Gurugram.(2018)
                    </b>
                  </li>
                  <li>
                   Paper presented 
                    <b>
                      on “ Current approaches in the treatment of Parkinson’s
                      Disease” in 2nd International Conference of
                      Physiotherapists, PHYSIOHARCON 2018.
                    </b>
                  </li>
                  <li>
                   Paper presented on “ 
                    <b>
                      Emerging neuro-rehabilitation Technique In Sports:
                      The Trans-cranial Direct Current Stimulation”
                    </b>
                    <b>
                      in Recent advancement in sports Field”, Delhi (
                      2018)
                    </b>
                  </li>
                  <li>
                   Paper Presented on
                    <b>
                      
                      “ The treatment of autistic spectrum disorder : focus on
                      transcranial direct current stimulation “ in UPCON2018,
                      Lucknow ( 2018)
                    </b>
                  </li>
                  <li>
                   Paper presented on
                    <b>
                      
                      “ Multitarget transcranial direct current stimulation for
                      the treatment of parkinson’s disease. ( A review)” ,
                      INCPT-AIIMS 2018)
                    </b>
                  </li>
                  <li>
                   Paper presented
                    <b>
                      
                      on “ Comparative evaluation and Formulation of an
                      iontophoretic drug for the therapeutic treatment of knee
                      osteoarthritis
                    </b>
                   ” 
                    <b>in 2</b>
                    <b>nd</b>
                    <b>
                      
                      national conference on recent advances and challenges in
                      healthcare, 16 march 2019, G.D goenka university,
                      Gurugram.
                    </b>
                  </li>
                  <li>
                   Paper presented
                    <b>
                      
                      on “ Yoga as a therapeutic intervention in chronic
                      illness: a systematic review
                    </b>
                   
                      ” , FEMCON 2, 2019, Jaipur
                    
                  </li>
                  <li>
                   Paper Presented on
                    <b>
                      
                      “ Effect of Microgravity on Knee articular cartilage and
                      its Rehabilitation”,
                    </b>
                   
                      REACH 2020, GD Goenka University, Gurugram
                    
                    <b>.</b>
                  </li>
                  <li>
                   Paper presented on
                    <b>
                      
                      “ Scope of iontophoretic technique in sports
                      rehabilitation: A review article”, Virtual international
                      conference on recent advances and challenges in sports
                      rehabilitation”,
                    </b>
                   
                      Manav rachna international institute of research 
                      studies, 5
                    
                   th
                   -7
                   th
                    June 2021
                  </li>
                </ol>
              </li>
            </ul>
            <p>
              <b>POSTER PRESENTATIONS</b>
              <b>:</b>
            </p>
            <ol>
              <li >
               
                  Poster presented in National Seminar on
                
                <b>
                  
                  “ Manual treatment techniques in tight meninges”, PGIMS
                  Rohtak, Haryana ( 2017)
                </b>
              </li>
              <li >
                <b>Poster presented in </b>
               4
               th
               
                  
                  annual physiotherapy conference of Indian
                
                <b>
                  association of physiotherapist “Physiocon Chandigarh 2017 on
                  effect of mental fatigue on memory and retention”,
                  Chandigarh.(2017)
                </b>
              </li>
              <li >
               Poster presented in 6
               th
                International conference
                <b>
                  
                  of physical therapy AIIMS 2017 on “Postural sway in diabetic
                  neuropathy patients using swaymeter”, AIIMS, 2017
                </b>
              </li>
              <li >
               Poster Presented on 
                <b>
                  “Efficacy of pediatric balance scale in balance of Down’s
                  syndrome
                </b>
               ”
                <b>
                  
                  in 2nd International Conference of Physiotherapists,
                  PHYSIOHARCON 2018.
                </b>
              </li>
              <li >
               Poster presented on
                <b>
                  
                  “ efficacy of screening instruments in the diagnosis of
                  autistic spectrum disorders”, INCPT-AIIMS, 2018.
                </b>
              </li>
              <li >
               Poster presented on
                <b>
                  
                  “ Neurodoping – for sports performance????”, in national
                  conference sportsconnect2, 2019.
                </b>
              </li>
              <li >
               Poster presented on 
                <b>
                  “ Efficacy of the instruments used in the diagnosis of
                  restless legs syndrome.”, Neuroconnect-3, 2019
                </b>
              </li>
              <li >
               Poster presented on
                <b>
                  
                  “ Iontophoresis with Vitamin C  Osteoarthritis”,
                  International virtual conference on role of micronutrients:
                  Immunity  Health, 18-19 June, 2021
                </b>
              </li>
            </ol>
           
        
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsAwardsandRecOpen(!isAwardsandRecOpen)}
      >
        Awards and Recognition
      </button>

      {isAwardsandRecOpen ? <div className="inner-container"><ol><li >Second prize in paper presentation physio Baharat 2022 best paper presentation in GD GOENKA 2018.</li><li >Presented and secure third prize at University level in “Best Innovative Project” , Synergy 2018, SGT University.</li></ol></div> : null}
    </div>
  );
};

export default DrTabassumSaher;
