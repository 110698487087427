import React from "react";
import './LibraryOfAcademics.css'

import recommendationForm from './Final-PDF-Recommendation-Form.pdf'

const LibraryOfAcademics = () => {
  return (
    <div className="academic-unit-component">
      <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading">
      Library
      </h2>

      <p className="library-paragrauph">
      The DIPSAR Library covers a total area of 4400 sq. ft. With a reading of 2000 sq. ft. and a stacking area of 2400 sq. ft. is fully air-conditioned. It is situated on the first floor of the front wing of the Institute. The Library has been equipped with modern furniture, reprography facility and library software system. Established in 1979, the DIPSAR Library is vibrant, well organised information system which enables the user to find out the required information in minimum time. It has one of the richest Pharmacy Library collections in India with over 17,000 books covering all branches of Pharmaceutical Sciences like Pharmaceutics, Pharmaceutical Chemistry, Pharmacology, Pharmaceutical Biotechnology, Pharmacognosy and Pharmaceutical Technology. It also has a good collection of books of basic science related to Pharmacy like Medicine, Pathology, Biochemistry and Microbiology.
      </p>
      
      <div className="link-container-of-libraury">
        <a href={recommendationForm} target="_blank" rel="noreferrer" className="link-of-library-academics">Recommendation Form</a>
      </div>

      <p className="library-paragrauph"> Link Access to  <a href="https://dpl.gov.in/" target="_blank" rel="noreferrer" className="link-of-library-academics"> Delhi Public Library</a>, Ministry of Culture and governed by Delhi Library Board</p>

      <div className="link-container-of-libraury">
        <a href="http://dpsru.bestbookbuddies.com/" target="_blank" rel="noreferrer" className="link-of-library-academics">Library Web OPAC Link</a>
      </div>
    </div>
  );
};

export default LibraryOfAcademics;
