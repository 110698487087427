import React from "react";
import "./VisionforViksitBharat2047.css";
import { Link, Outlet } from "react-router-dom";
import Iframe from "react-iframe";
import { FaAngleDoubleRight } from "react-icons/fa";

import viksitbharatimg from "./viksitbharatimg.jpeg";
import {
  FaFacebook,
  FaTwitterSquare,
  FaLinkedin,
  FaInstagramSquare,
} from "react-icons/fa";

const VisionforViksitBharat2047 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        {" "}
        Viksit Bharat @2047
      </h2>
      <div className="social-media-container-of-viksit-bharat">
        <a
          href="https://x.com/NITIAayog/status/1735198778515464430?s=20 "
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitterSquare />
        </a>
        <a
          href="https://www.facebook.com/photo/?fbid=744576231031747&amp;set=a.358894352933272"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.linkedin.com/posts/nitiaayog_viksitbharat2047-viksitbharat-viksitbharat2047-activity-7140967544596713472-h7I1?utm_source=share&amp;utm_medium=member_desktop"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.instagram.com/p/C000tRtP41A/?igshid=MzRlODBiNWFlZA%3D%3D"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagramSquare />
        </a>
      </div>

      <div className="btn-container-of-viksit-bharat">
        <div className="viksit-bharatonly-btn-container-like-side-menu">
          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              About Viksit Bharat
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              Objectives
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              Team Members
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}
          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              Events
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/noticeOfViksitBharat"
            >
              {" "}
              Notices & Circulars
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              Viksit Bharat Ideation Club
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          {/* each button group starts here */}
          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/aboutViksitBharat"
            >
              {" "}
              Gallery
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          {/* each button group ends here */}

          <section>
            <Link
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
              to="/VisionforViksitBharat@2047/voiceOfYouthViksitBharat2047"
            >
              {" "}
              Voice of Youth - Viksit Bharat 2047{" "}
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </Link>
          </section>
          <section>
            <a
              href="https://auth.mygov.in/user/login?destination=oauth2/authorize"
              className="viksit-bharat-feedback-btn vision-for-viksit-bharat-btn-for-side-navigation-link"
            >
              {" "}
              Share your ideas for a Viksit Bharat{" "}
              <span className="red-color-in-btn">
                <FaAngleDoubleRight />
              </span>
            </a>
          </section>
        </div>
        <Outlet />
      </div>

      <h3 className="dpsru-secondary-heading-for-every-page">
        DELHI PHARMACEUTIAL SCIENCES AND RESEARCH UNIVERSITY
      </h3>

      <div className="visionforviksitBharat-container-for-img-and-video">
        <div className="vikist-bharat-banner-container">
          <img src={viksitbharatimg} alt="lgMsg" />
        </div>
        <div className="viksit-bharat-video-container">
          <Iframe
            url="https://www.youtube.com/embed/JMYAgB7iE3o?si=tw_fW-k8NY8TlEJg"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
          <h2 className="tat-heading-text viksitbharat-text-heading">
            DPSRU Organized 1st Workshop on Viksit Bharat@2047 by Honorable
            Vice-Chancellor DPSRU
          </h2>
        </div>
      </div>
    </div>
  );
};

export default VisionforViksitBharat2047;
