import React, { useState } from "react";

import profimg from "./IMG_0077-scaled-e1686895089769.jpg";

const DrSatishManchanda = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] =useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Dr. Satish Manchanda</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
            manchandasatish [at] dpsru [dot] edu [dot] in 
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=4MjwM84AAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a >
                Scopus
              </a>
            </li>
            <li>
              <a >ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      M. Pharm, PhD
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      13 Years 
      </p>
     
     

     

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Research Area/ Project
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
               <p>Ocular Nano Drug Delivery, Transdermal Drug Delivery, HPLC method
development and validation</p>  </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
         
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
      Book/Book Chapters
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
         <p>Lodish H, Baltimore D, Berk A, Zipursky SL, Matsudaira P,Darnell J. Molecular cell biology. 3rd ed. New York: Scientific American; 1995.</p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
    Conference
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
        
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
            setIsAwardsandRecOpen(!isAwardsandRecOpen)
        }
      >
     Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
       
        </div>
      ) : null}
    </div>
  );
};

export default DrSatishManchanda;
