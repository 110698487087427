import React from 'react'
import './IAQCIntroduction.css'
import iaqcIntroLogo from './iaqcLogo.png'

function IAQCIntroduction() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                IAQC
            </h2>

            <div className="iaqc-intro-logo-container">
                <img src={iaqcIntroLogo} alt="IAQC" />
            </div>

            <h3 className="dpsru-secondary-heading-for-every-page">
                INTRODUCTION
            </h3>

            <div className="iaqc-intro-text-box">
                <p>
                    DPSRU has developed a strong Internal Quality Assurance Cell (IQAC) mechanism over the years, in accordance with the National Assessment and Accreditation Council’s (an autonomous institution of the University Grants Commission) standards, to implement quality improvement strategies, include self and external evaluation, and promote, sustain, and raise awareness about quality. The IQAC has taken a number of actions to assess and improve the University’s teaching, learning, research, and governance. Academic monitoring (AM) is the first step in achieving quality, and it entails paying a surprise visit to ongoing theory and practical classes and evaluating the results.The IQAC was crucial in the reform of the component colleges’ and university departments’ Vision, Mission, Goals, and strategic plans. Academic monitoring meetings are held on a regular basis to discuss difficulties and necessary changes for improvement. Through orientation and training programs, all members are encouraged to participate in advanced training for professional development. Various processes such as curriculum design and development, receiving feedback from various stakeholders such as students, parents, instructors, industry experts, recruiters, and other peer groups were designed, refined, and optimized under the aegis of the Quality Cell. For the continual quality improvement, DPSRU uses the IQAC vehicle.

                </p>

                <h3 className="dpsru-secondary-heading-for-every-page">
                    OBJECTIVE
                </h3>

                <p>
                    The IQAC’s mission is to create a system that is aware, consistent, and prompts actions to improve the organization’s academic and administrative performance. Every department/center should have an IQAC Coordinator to raise awareness, promote IQAC activities, and build the necessary processes and mechanisms within the department/center.
                </p>

                <h3 className="dpsru-secondary-heading-for-every-page">
                    FUNCTION
                </h3>

                <ul>
                    <li>Development of Quality Culture in the institution</li>
                    <li>Development and application of quality benchmarks/parameters for various academic and administrative activities of the Institution</li>
                    <li>To disseminate the information on various quality parameters of higher education.</li>
                    <li>Optimization and integrations of modern methods of teaching and learning</li>
                    <li>To organize the seminars/workshops/symposiums on quality related themes.</li>
                    <li>To prepare documents related to various programs / activities leading to quality improvement</li>
                    <li>Arrangement for feedback response from students, parents and other stakeholders on quality-related institutional processes</li>
                    <li>onduct student Satisfaction survey. Conduct Alumni, Faculty and Employer Feedback.</li>
                    <li>Conduct Workshop/Seminar on Quality assurance, research databases and e-resources.</li>
                    <li>Annual Appraisal of the faculty.</li>
                    <li> Annual review of Academic and Research performance of all the departments/centers through an academic audit.</li>
                    <li> Consolidation of various Formats of forms for IQAC and Meeting within IQAC.
                    </li>
                    <li>Annual Quality Assurance Report (AQAR) reports are prepared and sent out. Students’ comments was evaluated and analyzed, followed by a conversation with the VC and all Deans.</li>
                </ul>
            </div>



        </div>
    )
}

export default IAQCIntroduction