import React from "react";

import dpsruLogo from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/dpsru-logo.png";

import amritMahotsav from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/75-azadi-ka-amrit-mahotsav.png";
import nssLogo from "../G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/Maha Food Festival G20 Data/national-service-scheme.png";

import ib1 from "./Indo Brazil fusion datafiles/ib1.jpg";
import ib2 from "./Indo Brazil fusion datafiles/ib2.jpg";
import ib3 from "./Indo Brazil fusion datafiles/ib3.jpg";
import ib4 from "./Indo Brazil fusion datafiles/ib4.jpg";
import ib5 from "./Indo Brazil fusion datafiles/ib5.jpg";
import ib6 from "./Indo Brazil fusion datafiles/ib6.jpg";
import ib7 from "./Indo Brazil fusion datafiles/ib7.jpg";

const IndoBrazilFusionFest = () => {
  return (
    <div className="G20MahaFoodFestival-component">
      <div className="upper-logo-container-of-g20-maha-food-festival">
        <img src={dpsruLogo} alt="Dpsru Logo" />
        <img src={amritMahotsav} alt="amrit Mahotsav" />
        <img src={nssLogo} alt="Nss" />
      </div>

      <h1 className="pushp-pradarshanam-dpsru-heading">
        Delhi Pharmaceutical Sciences and Research University
      </h1>
      <h2 className="g20-maha-food-festival-heading">
        G20 Indo Brazil Fusion Fest
      </h2>

      <p className="g20-maha-food-festival-paragrauph">
        माननीय डॉ अरविंद शर्मा जी ने दिल्ली फार्मास्यूटिकल साइंस एवं रिसर्च
        विश्वविद्यालय द्वारा G-20 के अंतर्गत आयोजित इंडो ब्राजील डांस एवं फूड
        फेस्टिवल में बतौर मुख्य अतिथि भाग लिया। <br />
        विश्वविद्यालय में पहुंचने पर विश्वविद्यालय की तरफ से कुलपति डॉ आर के
        गोयल एवं कुलसचिव डॉ ओ पी शुक्ला और G20 के नोडल अधिकारी डॉ. सौरभ दहिया
        द्वारा शॉल भेंट करके माननीय सांसद जी का स्वागत किया गया। <br />
        माननीय सांसद द्वारा विभिन्न प्रतियोगिताओं में विजेता रहे छात्र छात्राओं
        को सम्मानित किया गया। साथ ही उन्होंने दिल्ली फार्मास्यूटिकल साइंस एवं
        रिसर्च विश्वविद्यालय के प्रयासों की सराहना की जो की कुलपति डॉ आर के गोयल
        के कुशल मार्गदर्शन में G-20 के अंतर्गत विभिन्न कार्यक्रम आयोजित कर रहे
        हैं। अपने वक्तव्य में डॉ अरविंद शर्मा जी ने बताया कि माननीय प्रधानमंत्री
        नरेंद्र मोदी जी के कुशल नेतृत्व में भारत को यह स्वर्णिम मौका मिला है कि
        जब जी-20 देशों की अगुवाई कर रहा है, और यह भारत को विश्व गुरु की भूमिका
        में स्थापित कर रहा है। उन्होंने विश्वविद्यालय के तमाम फैकल्टी एवं छात्र
        छात्राओं से आग्रह किया कि वह फार्मास्यूटिकल साइंस एवं फिजियोथेरेपी के
        क्षेत्र में नित नए रिसर्च के माध्यम से निरंतर आगे बढ़ते जाएं। इस मौके पर
        डॉ दीप्ति पंडिता, डॉ सौरभ दहिया, डॉ शीतल कालरा, डॉ पुनीता अजमेरा, डॉ
        आरके मुदगिल इत्यादि विशिष्ट रूप से उपस्थित रहे।
      </p>

      <div className="galery-conteiner-of-g20-maha-food-festival">
        <h3 className="galery-heading-of-g-20-maha-food-f">Gallery</h3>
        <div className="image-container-of-g-20-gallery pushp-pradarshan-galery">
          <img src={ib1} alt="galery" />
          <img src={ib2} alt="galery" />
          <img src={ib3} alt="galery" />
          <img src={ib4} alt="galery" />
          <img src={ib5} alt="galery" />
          <img src={ib6} alt="galery" />
          <img src={ib7} alt="galery" />
        </div>
      </div>
    </div>
  );
};
export default IndoBrazilFusionFest;
