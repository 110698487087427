import React from 'react'
import './ExaminationOrdinance.css'
import dpsruLogo from '../eDownloadData/DPSRU-LOGO.png'

import firstOrdinance from './First-Ordinances.pdf'
import sop from './SOP-ACADEMIC-DPSRU.pdf'

const ExaminationOrdinance = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className="dpsru-primary-heading-for-every-page">
    DPSRU Examinations Ordinance
    </h2>

    <div className="logo-container-of-dpsru-ordinance">
        <img src={dpsruLogo} alt="dpsru" />
    </div>
    <div className="examination-ordinance-para-container">
    DELHI PHARMACEUTICAL SCIENCES & RESEARCH UNIVERSITY
(ESTABLISHED UNDER ACT-07 OF 2008, GOVT. OF NCT OF DELHI)
PUSHP VIHAR, SECTOR – III, M.B. ROAD, NEW DELHI – 110017


<ul>
    <li><a href={firstOrdinance}>First Ordinance </a></li>
    <li><a href={sop}>Standard Operating Procedures (Academics) </a></li>
</ul>

    </div>
</div>
  )
}

export default ExaminationOrdinance