import React, { useState } from "react";
import "./GrievanceForm.css";

const GrievanceForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enrollment, setEnrollment] = useState("");
  const [courseSemester, setCourseSemester] = useState("");
  const [category, setCategory] = useState("");
  const [commentOrMsg, SetCommentOrMsg] = useState("");
  const [CurrentDate, SetCurrenctDate] = useState("");

  // const currentDateTime = new Date();
  // const CurrentDate = currentDateTime.toISOString();
  // const CurrentDate = new Date().toISOString().slice(0, 10);

  const handleSubmit = (e) => {
    e.preventDefault();
    FormSubmit();
    clearInpFieds();
    alert("Form Submitted");
  };

  // For clear all Imput field after click submit
  const clearInpFieds = () => {
    setName("");
    setEmail("");
    setPhone("");
    setEnrollment("");
    setCourseSemester("");
    setCategory("");
    SetCommentOrMsg("");
    SetCurrenctDate("");
  };

  // api for add form data from browser
  const FormSubmit = async () => {
    let result = await fetch("http://localhost:8080/dpsru-form", {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        phone,
        enrollment,
        courseSemester,
        category,
        commentOrMsg,
        CurrentDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    result = await result.json();
    console.warn(result);
  };

  // email deconstruciton

  return (
    <div className="graviance-form-component">
      <div className="dpsru-every-component-css">
        <h2 className="dpsru-primary-heading-for-every-page">Grievance Form</h2>

        <div className="form-container-of-grevance-form">
          {/* <form onSubmit={handleSubmit}> 
          upper comment activate when  backend and api activated and lower line for form submit co *

         
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                // placeholder="Enter Your Name"
                required
                autoComplete="off"
              />
              <label htmlFor="name">Name:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // placeholder="Enter Your Email"
                style={{ textTransform: "lowercase" }}
                required
                autoComplete="off"
              />
              <label htmlFor="email">Email:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                // placeholder="Enter Your Mobile no."
                required
                autoComplete="off"
              />
              <label htmlFor="phone">Phone:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="Enrollment"
                value={enrollment}
                onChange={(e) => setEnrollment(e.target.value)}
                // placeholder="Enter Your Enrollment No."
                required
                autoComplete="off"
              />
              <label htmlFor="enrollment">Enrollment No.:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="courseSemester"
                value={courseSemester}
                onChange={(e) => setCourseSemester(e.target.value)}
                // placeholder="Enter Your Course and Semester"
                required
                autoComplete="off"
              />
              <label htmlFor="courseSemester">Course & Semester :</label>
            </div>

            <div className="box-of-form-container-of-grevance-form">
              <input
                type="textarea"
                id="courseSemester"
                value={commentOrMsg}
                onChange={(e) => SetCommentOrMsg(e.target.value)}
                // placeholder="Write Your Comment Or Message"
                required
                autoComplete="off"
              />
              <label htmlFor="commentOrMessage">Comment Or Message :</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="currentDataTime"
                value={CurrentDate}
                onChange={(e) => SetCurrenctDate(e.target.value)}
                required
                autoComplete="off"
              />
              <label htmlFor="commentOrMessage">
                Write Current Date & Time :
              </label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              {/* <label htmlFor="category">Category:</label> */}
              {/* <select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                autoComplete="off"
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="OBC">OBC</option>
                <option value="SC/ST">SC/ST</option>
              </select>
            </div>
            <input type="hidden" name="_captcha" value="false" />

            <input type="hidden" name="_next" value="https://dpsru.edu.in" /> */}
            {/* upper line for By default, after submitting a form the user is shown the FormSubmit "Thank You" page. You can provide an alternative URL for "Thank You" page. */}

            {/* <button type="submit" className="submit-button-of-grievance-form">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </button>
          </form> */} 



{/* upper comment for form submit working as api and backend and lower working with form submit.co */}





          {/* <form onSubmit={handleSubmit}> */}
          {/* upper comment activate when  backend and api activated and lower line for form submit co */}

          <form
            action="https://formsubmit.co/dsw@dpsru.edu.in"
            method="POST"
          >
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="name"
                name="name"
                // placeholder="Enter Your Name"
                required
                autoComplete="off"
              />
              <label htmlFor="name">Name:</label>
            </div>


            <div className="box-of-form-container-of-grevance-form" style={{ textTransform: "lowercase" }}>
              <input
                type="email"
                id="email"
                name="email"
               
                style={{ textTransform: "lowercase" }}
                required
                autoComplete="off"
              />
              <label htmlFor="email">Email:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="tel"
                id="phone"
               name="phone"
                // placeholder="Enter Your Mobile no."
                required
                autoComplete="off"
              />
              <label htmlFor="phone">Phone:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="Enrollment"
              name="enrollment"
                // placeholder="Enter Your Enrollment No."
                required
                autoComplete="off"
              />
              <label htmlFor="enrollment">Enrollment No.:</label>
            </div>
            <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="courseSemester"
             name="courseSemester"
                // placeholder="Enter Your Course and Semester"
                required
                autoComplete="off"
              />
              <label htmlFor="courseSemester">Course & Semester :</label>
            </div>

            <div className="box-of-form-container-of-grevance-form">
              <input
                type="textarea"
                id="comment_or_Msg"
              name="comment_or_Msg"
                required
                autoComplete="off"
              />
              <label htmlFor="comment_or_Msg">Comment Or Message :</label>
            </div>
            {/* <div className="box-of-form-container-of-grevance-form">
              <input
                type="text"
                id="currentDataTime"
               
                required
                autoComplete="off"
              />
              <label htmlFor="commentOrMessage">
                Write Current Date & Time :
              </label>
            </div> */}
            <div className="box-of-form-container-of-grevance-form">
              {/* <label htmlFor="category">Category:</label> */}
              <select
                id="category"
              name="category"
                required
                autoComplete="off"
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="OBC">OBC</option>
                <option value="SC/ST">SC/ST</option>
              </select>
            </div>
            <input type="hidden" name="_captcha" value="false" />

            <input type="hidden" name="_next" value="https://www.dpsru.edu.in/grievanceForm" />
            {/* upper line for By default, after submitting a form the user is shown the FormSubmit "Thank You" page. You can provide an alternative URL for "Thank You" page. */}

            <button type="submit" className="submit-button-of-grievance-form">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GrievanceForm;
