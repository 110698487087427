import React, { useState } from "react";

import profimg from "./IMG_2044-scaled-e1688797474139.jpg";

const DrAmritaParle = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Amrita Parle</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutical chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : amrita [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              
              <a>Google Scholar </a>
            </li>
            <li>
              
              <a>Scopus</a>
            </li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M Pharm
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Pharmaceutical chemistry, pharmacovigilance, Bioequivalence
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <b>Books Authored</b>
          </p>
          <ul>
            <li>
              <b>Pharmaceutical Chemistry-II Practical Manual. </b>Published in
              2007 by CBS Publishers, New Delhi.
            </li>
            <li>
              <b>Pharmaceutical Chemistry-I Practical Manual. </b>Published in
              2008 by CBS Publishers, New Delhi.
            </li>
            <li>
              <b>Viva Voce in Organic and Pharmaceutical Chemistry.</b>
              Published in 2009 by CBS Publishers, New Delhi.
            </li>
            <li>
              <b>Viva-voce in inorganic &amp; Pharmaceutical Chemistry</b>.
              Published by CBS Publishers in 2012
            </li>
            <li>
              <b>Pharmaceutical Chemistry Laboratory manual</b> Published in
              2022 by CBS Publishers, New Delhi.
            </li>
          </ul>
          <p>
            <b>
              <i>Papers/ Review articles published</i>
            </b>
          </p>
          <ul>
            <li>
              Parveen and Parle. A comprehensive review of xanthene and
              thioxanthene. IJPSR 2022;13(7): 2550-2561.
            </li>
            <li>
              Suraj Kumar Prajapati, Amrita Parle. Quality evaluation and
              comparison of amoxicillin antibiotic under governmentsupply of
              delhi and branded formulation of india. JETIR 2021;8(8):e200-e209.
            </li>
            <li>
              Aanchal Arora, Amrita Parle, Meenakshi Dahiya, Reetika Rani.
              Comparative evaluation of Metformin tablets available under
              government supply and brands available in open market in Delhi,
              India. IOSR Journal Of Pharmacy 2021;11(5):08-20.
            </li>
            <li>
              Arora and Parle. Bioavailability and bioequivalence of
              biosimilars. IJPSR 2021;12(4):1989-2000.
            </li>
            <li>
              Arora A and Parle A: Coronavirus Disease 2019 (COVID – 19): A
              review. International Journal of Innovative Science, Engineering
              and Technology 2020;7(7):135-153.
            </li>
            <li>
              Parveen, Parle and Srivastava. Anti-inflamatory activity of novel
              xanthone derivatives. International Journal of Pharmaceutical
              chemistry and analysis 2021;8(4):174-181.
            </li>
            <li>
              Sahil Kalyan and Amrita Parle. A Validated LC-MS/MS Method for
              Simultaneous Estimation of Dapagliflozin and Metformin in
              Pharmaceutical Dosage Form. IOSR Journal Of Pharmacy 2020;10(11):
              31-38.
            </li>
            <li>
              Nitish Kumar and Amrita Parle. Synthesis, characterization and
              evaluation of 3- acetylindole derivatives as potential
              antiinflammatory agents. The Pharma Innovation Journal
              2020;9(6):461-467.
            </li>
            <li>
              Kalyan and Parle. Dapagliflozin: an anti-diabetic drug with
              cardiovascular benefits. IJPSR 2020;11(5):1986-1993.
            </li>
            <li>
              Singh VK and Parle A. Synthesis, characterization and
              antimicrobial activity of 2-aryl -Benzimidazole derivatives.
              International journal of pharmaceutical
              sciences2020;13(6):1211-19.
            </li>
            <li>
              Singh VK and Parle A. Synthesis, characterization and
              anti-inflammatory activity of 2-aryl Benzimidazole
              derivatives.International journal of pharmaceutical sciencesand
              research2020;11(6);1344-53.
            </li>
            <li>
              Vineet Kumar Singh, Amrita Parle. Synthesis, Characterization and
              Antioxidant Activity of 2-Aryl Benzimidazole Derivatives. Asian
              Journal of Pharmaceutical Research and Development.
              2020;8(2):35-44.
            </li>
            <li>
              Singh Vineet and Parle Amrita. Computational approaches in drug
              discovery. International journal of chemical study
              2020;8(5);1883-90.
            </li>
            <li>
              Sahil Kalyan and Amrita Parle. A Validated LC-MS/MS Method for
              Determination of Dapagliflozin in Tablet Formulation. IOSR Journal
              Of Pharmacy 2019;9(8):01-06.
            </li>
            <li>
              Nitish Kumar and Amrita Parle. Cinnamic acid derivatives: An ERA.
              The Pharma Innovation Journal 2019;8(5):580-595.
            </li>
            <li>
              Singh and Parle. The intriguing benzimidazole: a review. IJPSR
              2019;10(4):1540-1552.
            </li>
            <li>
              Nitish Kumar and Amrita Parle. Versatility in pharmacological
              actions of 3-substituted indoles. Int. J. Chem. Stud.
              2019;7(3):2248-2457.
            </li>
            <li>
              Nitish Kumar and Amrita Parle. 3-Substituted indole: A review
              International Journal of Chemical Studies 2019;7(2):848-856.
            </li>
            <li>
              Kalyan and Parle. Quality by design: changing outlook of
              pharmaceutical development. IJPSR 2019;10(9):4100-4108.
            </li>
            <li>
              Shaista Amin, Amrita Parle. Synthesis, Characterization and
              Antioxidant Activity of 2-aryl Benzothiazole Derivatives.
              International Journal of Current Pharmaceutical Research
              2018;10(5):3-8.
            </li>
            <li>
              Parle and Amin. Synthesis, Characterization and Evaluation of
              Novel 2-aryl Benzothiazole Derivatives as Potential Antifungal
              agents. International Journal of Pharmaceutical Sciences and
              Research 2018; 9(10):4332-4337.
            </li>
            <li>
              Shaista Amin, Amrita Parle. Synthesis, Characterization and
              Evaluation of Novel 2-Aryl Benzothiazole Derivatives as Potential
              Antibacterial Agents. International Journal of Current
              Pharmaceutical Review and Research 2017;8(5);342-349.
            </li>
            <li>
              Amrita Parle and Gurmeet Singh. Treatment and advances in
              Tuberculosis Research. International Journal of Pharmaceutical
              Sciences and Research. 2017;2(4):14-21.
            </li>
            <li>
              Parle Amrita and Arora Tejpal. Synthesis and antibacterial
              screening of some novel cinnamic acid derivatives. International
              Journal of Chemical Studies. April 2017;5(3):643-647.
            </li>
            <li>
              Parle Amrita and Arora Tejpal. Docking, synthesis and NDM-1
              inhibitory activity of some novel cinnamic acid derivatives. The
              Pharma Innovation Journal 2017;6(6):119-123.
            </li>
            <li>
              Amrita Parle and Gurmeet Singh. Synthesis, Characterization and
              Biological Evaluation of Novel Isonicotinic Acid Hydrazide
              Derivatives. European Journal of Biotechnology and Bioscience
              2017;5(3):21-27.
            </li>
            <li>
              Deepika Sondh, Amrita Parle. Antiwrinkle agents – the way of
              regaining beauty. The pharma innovation international journal
              2017;6(6):7-13.
            </li>
            <li>
              Amin Shaista and Parle Amrita. Benzothiazole – A Magic Molecule.
              International Journal of Pharmaceutical Sciences and Research
              2017;8(12): 4909-4929.
            </li>
            <li>
              Arora Tejpal and Parle Amrita. Jackfruit: A Health Boon. Int. J.
              Res. Ayurveda Pharm 2016;7(3):59-64.
            </li>
            <li>
              Singh Gurmeet and Parle Amrita. Unique Pandanus – Flavour, Food
              and Medicine. Journal of Pharmacognosy and Phytochemistry 2016;
              5(3): 08-14.
            </li>
            <li>
              Amin Shaista and Parle Amrita. Delicate, fragrant lady of the
              night- A medicinal gift. Journal of Medicinal Plants Studies
              2016;4(6):13-17.
            </li>
            <li>
              Arora Tejpal and Parle Amrita. NDM-1 Antibacterial resistance: A
              challenge. European Journal of Biotechnology and Bioscience
              2016;4(9): 10-15.
            </li>
            <li>
              Deepika Sondh, Amrita Parle. Facial rejuvenation and correction of
              facial deformities using dermal fillers and botulinum toxins.
              International Journal of Pharmaceutical Science and Research
              2016;1(7);17-23.
            </li>
            <li>
              Komal Sharma, Amrita Parle. The Most Effective and Safer Answer to
              Diabetes Mellitus: Alogliptin Benzoate. Am. J. PharmTech Res.
              2015; 5(4), 60-80.
            </li>
            <li>
              Komal Sharma, Amrita Parle. Development and Validation of HPTLC
              Method for Estimation of Alogliptin Benzoate in Bulk Drugs And
              tablet dosage forms. International Bulletin of Drug Research
              2015;5(8):81-89.
            </li>
            <li>
              Komal Sharma, Amrita Parle, Development and Validation of HPTLC
              Method for Simultaneous Estimation of Alogliptin Benzoate and
              Pioglitazone Hydrochloride in Bulk Drugs and Combined Dosage
              Forms. International Journal of Pharma Research &amp; Review
              2015;4(11):35-42.
            </li>
            <li>
              Komal Sharma, Amrita Parle and Sayeed Ahmad. Development and
              validation of HPTLC method for simultaneous estimation of
              Metformin hydrochloride and alogliptin benzoate in bulk drugs and
              combined dosage forms. Der Pharmacia Lettre 2015,7(7):321-328.
            </li>
            <li>
              Chirag and Amrita Parle. Development and validation of UV
              spectrophotometric method for simultaneous estimation of metformin
              hydrochloride and alogliptin benzoate in bulk drugs and combined
              dosage forms. Der Pharma Chemica 2014,6(1):303-311.
            </li>
            <li>
              Amrita P, Simar P.S, Vineet K. Status of Adverse Drug Reaction
              Reporting by Health Care Professionals of Delhi. IJOPP
              2012;5(4):42-50.
            </li>
            <li>
              Amrita P, Kharbanda B. Knowledge, Attitude and Skills of nurses of
              Delhi towards adverse drug reaction reporting. IJOPP 2012;
              5(1):45-51.
            </li>
            <li>
              Amrita P, Roomi M T. Scenario of Pharmacovigilance and ADR
              Reporting among Pharmacists in Delhi. IJOPP 2011; 4(4):29-38
            </li>
            <li>
              Amrita P, Singh SP. Status of spontaneous reporting of adverse
              drug reaction by physicians in Delhi. IJOPP 2011;4(2):29-36
            </li>
            <li>
              Milind Parle, Ashwani &amp; Amrita Parle. Music therapy- a
              pleasant way of managing depression. Asian Journal of Biosciences
              2008;3(2):364-368.
            </li>
            <li>
              M Parle &amp; A Parle. Music improves quality of life of
              Alzheimer’s patient. The Pharmacos 2008;35:53-61.
            </li>
            <li>
              Ashwini, Amrita Parle &amp; Milind Parle. Cardioprotective Effect
              of Music Therapy. Abstract book of International Symposium on
              Recent Advances in Cardiovascular Sciences (RACS) 2008;58-59.
            </li>
            <li>
              Amrita Parle &amp; Milind Parle. Can Music Therapy Help in
              Hypertension? Proceedings of International Symposium on Recent
              Advances in Cardiovascular Sciences (RACS) 2007;242.
            </li>
            <li>
              Amrita Parle, Milind Parle, Ashwini, Music Therapy- a Pleasant Way
              of Managing Depression. Proceedings of IPGA National seminar on
              Recent Trends in Pharmaceutical Education &amp; Research
              2007;27-28.
            </li>
            <li>
              Amrita Parle &amp; Milind Parle. Music Therapy for Alzheimer’s
              disease. proceedings of National Seminar on Management of
              Neurodegenerative Disorders – challenges &amp; opportunities
              2006;16-19.
            </li>
            <li>
              Amrita Parle. Role of Obesity in Genesis of Osteoarthritis.
              proceedings of 8th National Conference of Biomechanics
              2004;194-199.
            </li>
            <li>
              Parle Milind and Parle Amrita. Blood-Brain Barrier: An Update.
              Indian J. Hospital Pharmacy 1990;27:115-117.
            </li>
            <li>
              Sangeeta Bahl, Jyoti Kulkarni, ( alias Amrita Parle), S. K.
              Unadkat, S. Pannicker &amp; H.P. Tipnis. Comparison of Plasma And
              Urinary Level Kinetics of Ethambutol. Indian J. Pharm.Sci.
              1988;50(3),181-182.
            </li>
          </ul>
          <p>
            <br /> <br />
            <br /> <br />
          </p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <p>
            <span title="" data-sheets-value="{" data-sheets-userformat="{">
              <strong>INVITED TALKS</strong>
            </span>
          </p>
          <p>
            1. Invited speaker in the quality improvement program conducted at
            DIPSAR New Delhi, 1-5 April 2019.
            <br /> <br />
            2. Invited speaker at DDR College of pharmacy, Gurgaon, Patoudi
            road, Jan 2019, The topic was Road to Success.
            <br /> <br />
            3. Invited speaker at SMBT College of pharmacy, Nasik, Dec 2015, The
            topic was How to improve reading and listening skills. <br /> <br />
            4. Invited speaker in the quality improvement program conducted at
            DIPSAR New Delhi, Feb- Mar 2015 The topic was Role of communication
            skill in becoming effective teacher
            <br /> <br />
            5. Invited speaker at Jayawant Institute of Pharmacy,Tathiwade, Pune
            on 7th Nov 2011. Communication – a tool for sucessful career.
            <br /> <br />
            6. Invited speaker at Sharda Vilas College of Pharmacy, Mysore on
            3rd Jan 2011. The topic was Effective communication Skills: A
            prerequisite for success.
            <br /> <br />
            7. Guest Speaker in AICTE sponsored Staff Developement program for
            pharmacy teachers at Ram Gopal College of Pharmacy on 9th Dec 2010.
            The topic was “Effective delivery of Lectures”.
            <br /> <br />
            8. Was invited speaker at Directorate of Training, New Delhi as per
            the details given in table
          </p>
          <p></p>
          <br /> <br />
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <span>1</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>18.06.09</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1. Leadership Skills</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2.Communication skills</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>2</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>27.08.09</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1. Leadership Skills</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2.Communication skills</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>3</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>15.10.09</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1. Leadership Skills</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2.Communication skills</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>4</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>16.11.09</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>
                      1.positive peer relationship &amp;effective communication
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>
                      2. Behavioral Styles &amp; handling disagreements
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>5</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2.12.09</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1.Key factors in interpersonal communication –I</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>
                      2.Key factors in interpersonal communication –II
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>6</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>6.4.10</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Communication Skills &amp; Public Deal</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <br /> <br />
            9. Invited Speaker in Reorientation Training Program for Doctors,
            conducted by AYUSH at B R Sur Medical College on 7th Dec 2009. The
            topic was “Professional Development &amp; Soft Skills”.
            <br /> <br />
            10. Delivered a guest lecture on “Communication Skills” in
            continuing education programme conducted by Indian Hospital
            Pharmacists Association, for Hospital Pharmacists at St. Stephen’s
            Hospital, 7th October 2009.
            <br /> <br />
            11. Invited Speaker at Ramgopal Verma College of Pharmacy,
            Sultanpur, Gurgaon on 29th Aug 09.
            <br /> <br />
            12. Guest speaker during Faculty development program for pharmacy
            teachers at Surajmal Institute of Technology, Janakpuri, New Delhi
            on 23rd July 2009. The topic was “Road to Success”.
            <br /> <br />
            13. Medical Teachers Reorientation Programme at Tibbiya College,
            Aligarh Muslim University. Delivered seminar on ‘Effective
            communication – an essential tool for efficient teaching’ on 31st
            Jan 2009
            <br /> <br />
            14. Delivered a guest lecture on “Listening to devotional music
            relieves depression” in a National Seminar on ‘Naturopathy, Yoga and
            Unani Medicine for Promotion of Health’ at Aligarh Muslim
            University, Aligarh on 20th Dec 2008.
            <br /> <br />
            15. Delivered a guest lecture on “Communication Skills” in
            continuing education programme for Hospital Pharmacists at St.
            Stephen’s Hospital, October 2008.
            <br /> <br />
            16. Delivered a guest lecture on “Role of Music in Our Life” at
            Manav Institute of Pharmacy on 11th December 2008.
            <br /> <br />
            17. Delivered a guest lecture on “Music Therapy for Alzheimer’s
            disease” in a National Seminar On Management Of Neurodegenerative
            Disorders-Challenges &amp; opportunity at Sonia Education Trust’s
            College of Pharmacy, Dharwad, Karnataka on 5th March 2006.
            <br /> <br />
            18. Was invited as resource person in 14th QIP at DIPSAR New Delhi
            in Feb.2006. delivered a lecture on “Music Therapy”.
            <br /> <br />
            19. Was Invited Speaker during 12th QIP programme sponsored by
            AICTE, for pharmacy teachers at DIPSAR, New Delhi, Dec 2004.
            Delivered a lecture on “Obesity-A Global Epidemic”.
            <br /> <br />
            20. Was Resource person during 11th QIP programme sponsored by
            AICTE, for pharmacy teachers at DIPSAR, New Delhi, Nov 2004.
            Delivered lecture on “Communication Skills”.
            <br /> <br />
            21. Was invited speaker at Shivalik College of Pharmacy, Nangal,
            (Punjab) Nov 2001. Delivered a speech on “Entrepreneurship
            Development”.
            <br /> <br />
            22. Delivered lecture on “Entrepreneurship Development” for Teachers
            at DIPSAR, 2000.
            <br /> <br />
            <strong>
              WORK SHOPS/REFRESHER COURSES CONDUCTION AND PARTICIPATION
            </strong>
            <br /> <br />
          </p>
          <p>
            1. Organising committee member /Co-ordinator for conducting four
            online quality improvement program held at DIPSAR in 14-21 Sept,
            22-29 Sept, 30 Sept to 8th Oct, 9th Oct -16 Oct 2020.
            <br /> <br />
            2. Organising committee member during International conference on
            cardiovascular sciences- 2020
            <br /> <br />
            3. Participapted in one week Teachers training program on digital
            pedagogy conducted by SVKM’s Dr Bhanuben Nanavati college of
            pharmacy from 5-10 Oct 2020.
            <br /> <br />
            4. Co-ordinator for conducting four quality improvement program held
            at DIPSAR 11-15 March 2019, 25-29 March 2019, 1-5 April 2019, 8-12
            April 2019
            <br /> <br />
            5. Organising committee member for conducting National conference on
            Pharmacovigilance, Pharmacoeconomics and outcome research Oct
            9&amp;10 2018
            <br /> <br />
            6. Four Quality Improvement Programmes, held at DIPSAR, New Delhi
            from 19-23 Feb 2018, 5-9 mar 2018, 12-16 Mar 2018, 2-6 Apr 2018
            <br /> <br />
            7. Organising committee member during International conference on
            challenges for global competitiveness of Ayush andnatural products
            Feb 2018
            <br /> <br />
            8. Four Quality Improvement Programmes, held at DIPSAR, New Delhi
            from 5th -9th Dec 2016, 30th Jan to 10th Feb 2016, 13Th Feb to 17th
            Feb 2017, 27th Feb -10th March 2017
            <br /> <br />
            9. Quality Improvement Programme held at DIPSAR, New Delhi from Feb-
            Mar, 2015.
            <br /> <br />
            10. Quality Improvement Programme held at DIPSAR, New Delhi from
            17-21Oct, 2011.
            <br /> <br />
            11. Quality Improvement Programme held at DIPSAR, New Delhi from 21
            Nov to 2 Dec, 2011.
            <br /> <br />
            12. Attended training at UTCS, Delhi on “Good governance
            &amp;Transperancy- RTI &amp; Citizen Charter” 7-9 March 2011.
            <br /> <br />
            13. Training Program on Right to Information at Directorate of
            Training, New Delhi, 15 &amp; 16 oct 2009.
            <br /> <br />
            14. Short term training course on ‘Motivation for Excellence’ at
            Directorate of Training, New Delhi, 8 &amp; 9 June 2009.
            <br /> <br />
            15. Short term course conducted by NITTTR, Chandigarh at Meerabai
            Polytechnic, Delhi on the topic “Effective Curriculum
            implementation”, from 26 to 30th May, 2008.
            <br /> <br />
            16. Quality Improvement Programme held at DIPSAR, New Delhi from
            27th Feb to 3rd March 2006.
            <br /> <br />
            17. Short-term training programme on Communications Skills conducted
            by NITTTR, Chandigarh, from 19th to 23rd Jan 2004.
            <br /> <br />
            18. Quality Improvement Programme held at DIPSAR, New Delhi from
            17th Nov to 21st Nov 2003.
            <br /> <br />
            19. New Avenues in Drug Design at AISSMS College of Pharmacy Pune
            from 14th to 19th May 2001.
            <br /> <br />
            20. Training course of “Internet Concepts and its Applications” at
            Delhi University Computer Center from 16th to 20th April 2001.
            <br /> <br />
            21. AICTE-ISTE short-term training programme on Emerging Areas in
            Pharmaceutical Education and Practice organized by Nagpur College of
            Pharmacy, Nagpur from 21st Oct – 1st Nov 2002.
            <br /> <br />
            22. Faculty Development Programme conducted by Hardicon at New Delhi
            from 6th to 18th March 2000.
            <br /> <br />
            23. Faculty Development Programme held at Jamia Hamdard, New Delhi
            from 1st to 22nd June 1996.
          </p>
          <p>
            <strong>PAPER PRESENTATION IN THE CONFERENCES</strong>
          </p>
          <p>
            1. Poster presentation in pharmanest ’21 International virtual fest
            on Data science, computational tools, artificial intelligence and
            more novel technologies: Evolution and contribution in
            Pharmaceutical Research held from 26th May to 12 June 2021.
            <br /> <br />
            2. Presented a paper -Prajapati S and Parle A. Regenerative Medicine
            : The Future of Biomedical Sciences. 9 th International Conference
            on Food Processing And Fortification with emphasis on vitamin D;
            2020 March 5-6; New Delhi,
            <br /> <br />
            India.
            <br /> <br />
            3. Presented a paper -Arora A, Kalyan S and Parle A. Method
            development and validation for determination of dapagliflozin and
            metformin in tablet formulation; International Conference of
            Cardiovascular Sciences; 2020 February 21-23; New Delhi, India.
            <br /> <br />
            4. Presented a paper -Arora A, Kalyan S and Parle A. Method
            development and validation for determination of dapagliflozin and
            metformin in tablet formulation; Impact of Artificial Intelligence
            in Healthcare; 2020 February 21-22; Ghaziabad, Uttar Pradesh, India.
            <br /> <br />
            5. Presented a paper -Prajapati S and Parle A. Synthesis
            ,Characterization and Antifungal Activity of Aryl Benzimidazole
            Derivatives. International Conference on Personalized Medicine :
            Concept &amp; Role Player; 2020 February 19-20; Lucknow, Uttar
            Pradesh, India.
            <br /> <br />
            6. Presented a paper -Arora A, Kalyan S and Parle A. Method
            development and validation for determination of dapagliflozin in
            tablet formulation; Association of Pharmaceutical Teachers of India
            Convention; 2019 October 11-13;
            <br /> <br />
            Dehradun, Uttrakhand, India.
            <br /> <br />
            7. Presented a paper -Singh VK and Parle A. Computational approaches
            in drug discovery at 2 nd National conference on recent advances and
            challenges in healthcare; 2019 held on March 16, 2019 at GD Goenka
            University, Gurugram, India.
            <br /> <br />
            8. Presented a paper -Kalyan S and Parle A. India’s e-pharma
            industry: evolution and challenges. Proceedings of the national
            conference on online pharmacy: regulatory perspectives; 2019 Mar 1;
            Gurugram, Haryana, India.
            <br /> <br />
            9. Presented a paper- Singh VK. and Parle A. The intriguing
            benzimidazole: a review, Proceedings of the 23 rd Annual National
            Convention of Association of Pharmaceutical Teachers of India; 2018
            Oct 5-6; Jaipur, Rajasthan, India.
            <br /> <br />
            10. Learnt new skills by participating in Train the trainer Impact
            week 2018.
            <br /> <br />
            11. Presented a paper -Kalyan S and Parle A. Quality by design:
            changing outlook of pharmaceutical development. Proceedings of the
            23rd Annual National Convention of Association of Pharmaceutical
            Teachers of India; 2018 Oct 5-6; Jaipur, Rajasthan, India.
            <br /> <br />
            12. Presented a paper- Kumar N, Amin S and Parle A. Synthesis,
            Characterization and Evaluation of novel 2-aryl benzothiazole
            derivatives as potential antifungal agents. Association of
            Pharmaceutical Teachers of India Convention; 2018 March 24; Greater
            Noida, Uttar Pradesh, India.
            <br /> <br />
            13. Presented a paper -Kumar N, Amin S and Parle A. Synthesis,
            Characterization and Evaluation of novel 2-aryl benzothiazole
            derivatives as potential antibacterial agents. Association of
            Pharmaceutical Teachers of India Convention; 2018 March 24; Greater
            Noida, Uttar Pradesh, India.
            <br /> <br />
            14. Presented a paper in Conference of Society of pahrmacovigilance
            of India held at Nalanda Medical College from 18-20 Nov 2011. The
            title of the paper was “Knowledge, Attitude &amp; Skills of nurses
            towards adverse drug reaction reporting.”
            <br /> <br />
            15. Presented a paper in Conference of Society of pharrmacovigilance
            of India held at Nalanda Medical College from 18-20 Nov 2011. The
            title of the paper was “Status of adverse drug reaction monitoring
            and pharmacovigilance among healthcare professionals of Delhi.”
            <br /> <br />
            16. Presented paper in an International Conference on
            ‘Pharmacovigilance &amp; rational Use of Medicines: An Integrated
            Approach’ held at LHMC, Delhi, from 26-28 Nov 2010. The title of the
            paper was”Physician’s proactive participation: A key to enhance ADR
            reporting.”
            <br /> <br />
            17. Presented paper in an ‘International Conference on Patient
            Safety’ organized at AIIMS, New Delhi, in Oct 2010. The title of the
            paper was “Scenario of Pharmacovigilance &amp; ADR reporting Among
            Pharmacists in Delhi.”
            <br /> <br />
            18. Presented a research paper entitled ‘Cardioprotective Effect of
            Music Therapy’ in International Symposium on Recent Advances in
            Cardiovascular Sciences (RACS), at DIPSAR, New Delhi in Feb 2008.
            <br /> <br />
            19. Presented a research paper entitled ‘Music Therapy- a Pleasant
            Way of Managing Depression’ at IPGA National seminar on Recent
            Trends in Pharmaceutical Education &amp; Research, at Rajendra
            Institute of Technology, Sirsa.
            <br /> <br />
            20. Presented a research paper at IIT Delhi on “Role of Obesity in
            Genesis of Osteoarthritis” at 8t National Conference of
            Biomechanics, Nov 2004.
            <br /> <br />
            21. Presented a research paper on “Bioavailability of Different
            Ethambutol Formulation’s marketed in India” at 37th Indian
            Pharmaceutical Congress, New Delhi, 1985.
          </p>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              <b>Best paper award in </b>
              <span>
                23rd Annual National Convention of Association of Pharmaceutical
                Teachers of India; 2018 Oct 5-6; Jaipur, Rajasthan, India.
              </span>
            </li>
            <li>
              <b>Upsala Best paper award </b>
              <span>
                in conference of Society of Pharmacovigilance of India, Patna
                2011.
              </span>
            </li>
            <li>
              <b>Inspector of Pharmacy Council</b>
              <span>
                
                of India to conduct inspections of Pharmacy Institutions in
                India.
              </span>
            </li>
            <li>
              <b>IDMA GOLD MEDAL</b>
              <span> with certificate of Merit for standing 1</span>
              <span>st</span>
              <span> in Shivaji University, Kolhapur, at the </span>
              <b>Final year B.Pharm</b>
              <span> Examination.</span>
            </li>
            
            <li>
              <span>“</span>
              <b>Akhil Bhartiya Vidyarthi Parishad Award</b>
              <span>
                ” for standing first in Shivaji University, Kolhapur, at the
              </span>
              <b>2</b>
              <b>nd</b>
              <b> year B.Pharm</b>
              <span>. Examination.</span>
            </li>
            <li>
              <b>Junior Research Fellow of U.G.C.</b>
              <span> (1984-1986).</span>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrAmritaParle;
