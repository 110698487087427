import React, { useState } from "react";

import profimg from "./IMG_0614-scaled-e1688471005508.jpg";

const DrSachinAssistantProfessor = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Sachin</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor  | Department of Pharmacology
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              {" "}
              sachinkr [at] dpsru [dot] edu [dot] in
            
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.com/citations?user=0c11soP9a90C&hl=en"> Google Scholar</a></li>
            <li> <a href="https://www.scopus.com/feedback/author/reviewAuthorProfile.uri?authorIds=35262062000">Scopus</a> </li>
            <li>
              <a href="https://orcid.org/0000-0002-8052-401X">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Ph.D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Cancer, inflammation, lung fibrosis, leishmaniasis, malaria


      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul><li>S Kumar, S Bajaj, RB Bodla. Preclinical screening methods in cancer. Indian journal of pharmacology 48 (5), 481, 2016.</li><li>DP Pathak, V Sharma, S Kumar. Designing novel inhibitors against falcipain-2 of Plasmodium falciparum</li><li>Bioorganic &amp; Medicinal Chemistry Letters 28 (9), 1566-1569, 2018.</li><li>S Kumar, R Bodla, H Bansal. Antioxidant Activity of Leaf Extract of Aegle marmelos Correa ex Roxb. Pharmacognosy Journal. 8 (5), 447-50, 2016.</li><li>S Bajaj, SR Wakode, W Khan, S Manchanda, S Kumar. Simultaneous HPTLC analysis and in vitro antileishmanic activity of various secondary metabolites in extract of the traditional medicinal herb Artabotrys hexapetalus (Lf). Ayu 39 (2), 92, 2018.</li><li>S Kumar, F Fayaz, FH Pottoo, S Bajaj, S Manchanda, H Bansal. Nanophytomedicine based novel therapeutic strategies in liver cancer. Current Topics in Medicinal Chemistry 20 (22), 1999-2024,2020.</li><li>S Sahu, CR Patil, S Kumar, S Apparsundaram, RK Goyal. Role of ACE2-Ang (1–7)-Mas axis in post-COVID-19 complications and its dietary modulation. Molecular and Cellular Biochemistry 477 (1), 225-240, 2022.</li><li>S Kumar, C Barua, S Das. Evaluation of anti-inflammatory activity of Alternanthera brasiliana leaves. International Journal of Pharma and Bio Sciences 5, 33-41, 2014.</li><li>S Kumar, R Bodla, R Kant. Non-targeted analysis and cytotoxic activity of Hamelia patens JACQ. Int J Pharm Sci Res 9, 1093-1099, 2018.</li><li>S Kumar, RB Bodla. Nontargeted analysis and cancer cells cytotoxicity of Aegle marmelos Correa Ex Roxb. Pharmacognosy Magazine 14 (55), 40, 2018.</li><li>S Wakode, A Kaur, H Bansal, S Manchanda, S Kumar, N Fuloria. Chemical characterization and anti-inflammatory activity of phytoconstituents from Swertia alata. Plants 10, 1109, 2021.&nbsp;</li></ul>        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>Participated as Guest Speaker in a National Webinar program on “Single nucleotide polymorphism in cancer”, held on 5th September, 2021, Bharat Technology (Anjali Health Education and Charitable Trust).</li><li><p>Book prize for securing II position in Masters at NIPER, Guwahati National merit scholarship (2008-2010).</p></li></ul><p><b>Other activities:</b></p><ul><li>2013 Attended 10th Knowledge Millennium Summit on ‘Curing the Incurables: Sharing of Innovations’ organized by ASSOCHAM India at Vigyan Bhawan, New Delhi</li><li>2009 Attended 61st Indian Pharmaceutical Congress-2009 at Nirma University, Ahemdabad, India</li><li>2009 Attended workshop on ‘Scientific paper writing and research methodology’ at Gauhati Medical College, Guwahati, India<br/></li><li>2009 Attended symposium on ‘Clinical research and training’ at National Institute of Pharmaceutical Education and Research, S A S Nagar, Mohali, India</li><li>2008 Attended preconference workshop on ‘Getting started in</li><li>research and scientific communication’ at All India Institute of Medical Sciences, New Delhi, India</li><li>2008 Attended International conference on translational pharmacology and 41st annual conference of Indian Pharmacological Society at All India Institute of Medical Sciences, New Delhi, India</li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrSachinAssistantProfessor;
