import React from "react";
import './EGovernance.css'

function EGovernance() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">E-GOVERNANCE</h2>

      <div className="organization-and-function" style={{ margin: "3% 0" }}>
        <table width="820">
        <table>
  <thead>
    <tr>
      <th>S.No.</th>
      <th>Item</th>
      <th>Details of disclosure</th>
      <th>Remarks/ Reference Points</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4.1</td>
      <td>Language in which Information Manual/Handbook Available</td>
      <td>(i) English</td>
      <td>
        Annual Reports are available in both English. Admission Prospectus is in English only <br />
        <a href="https://dpsru.edu.in/static/media/Prospectus-2023-for-web.9f4015acd60b2b98e7a5.pdf">Prospectus 2023</a><br />
        <a href="https://dpsru.edu.in/admissions">Admissions</a><br />
        <a href="https://dpsru.edu.in/annualReport">Annual Report</a><br />
        <a href="https://dpsru.edu.in/newsletters">Newsletters</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ii) Vernacular/ Local Language</td>
      <td>Hindi</td>
    </tr>
    <tr>
      <td>4.2</td>
      <td>When was the information Manual/Handbook last updated?</td>
      <td>Last date of Annual Updation</td>
      <td>
        Updated in every financial year<br />
        <a href="https://dpsru.edu.in/static/media/Prospectus%202024-25-updated.bce5cf1f7faad8ecbcde.pdf">Prospectus 2024-25</a>
      </td>
    </tr>
    <tr>
      <td>4.3</td>
      <td>Information available in electronic form</td>
      <td>(i) Details of information available in electronic form</td>
      <td>
        RTI Act 2005, Annual Accounts, Annual Reports, Examination Results/ Grade card/ Entrance Examination Results and the other details available on the website <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">Annual report 2023-24</a><br />
        <a href="https://dpsru.edu.in/annualReport">Annual Report</a><br />
        <a href="https://dpsru.edu.in/examination">Examination</a><br />
        <a href="https://dpsru.edu.in/">DPSRU</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ii) Name/ title of the document/record/ other information</td>
      <td>
        As answered above<br />
        <a href="https://dpsru.edu.in/">DPSRU</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(iii) Location where available</td>
      <td>
        The details are available with concerned department Heads.<br />
        <a href="https://dpsru.edu.in/">DPSRU</a><br />
        <a href="https://dpsru.edu.in/RD_project">RD Project</a>
      </td>
    </tr>
    <tr>
      <td>4.4</td>
      <td>Particulars of facilities available to citizen for obtaining information</td>
      <td>(i) Name & location of the faculty</td>
      <td>
        The University provides all relevant information through its website. However, one may approach the following officials for obtaining information:<br />
        - About courses offered by the University – Students section/ PRO/ Concerned department<br />
        - Admission and admission related queries – Office of Assistant Controller of Examinations<br />
        - Purchase department – Assistant Registrar (Admin)<br />
        - Construction works (Engineering cell) – Registrar/ University Financial Office of the Finance Officer<br />
        (A) Working Hours:<br />
        The teaching departments and administrative offices of the University function five days a week from Monday to Friday as per Government working hour pattern. Normal working hours are 9.00 a.m. to 5.30 p.m.<br />
        (B) Library working hours:<br />
        9.00 a.m. to 12.00 (All days). The Library facility (reading only) is available to the members of the public. Any persons may visit the Library with the permission of the Librarian and use the reading room facility on all working days.<br />
        <a href="https://dpsru.edu.in/contactUs">Contact Us</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ii) Details of information made available</td>
      <td>
        The University Act, Rules and Regulations, Annual Reports, Annual Accounts, Directory of Officers and Employees of University, Vision & Mission<br />
        <a href="https://dpsru.edu.in/visionMissionObjectives&QualityPolicy">Vision & Mission</a><br />
        <a href="https://dpsru.edu.in/static/media/Annual%20report%202023-24-compressed.2a7f7ab30675d705cf6a.pdf">Annual Report 2023-24</a><br />
        <a href="https://dpsru.edu.in/governingActs">Governing Acts</a><br />
        <a href="https://dpsru.edu.in/staff/">Staff</a><br />
        <a href="https://dpsru.edu.in/">DPSRU</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(iii) Working hours of the facility</td>
      <td>
        Normal working hours are 9.00 a.m. to 5.30 p.m. However, the Central Library remains open on all days except national holidays.<br />
        <a href="https://dpsru.edu.in/contactUs">Contact Us</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(iv) Contact person & contact details (Phone, fax, email)</td>
      <td>
        Information is available on the website <a href="https://dpsru.edu.in/staff/">Staff</a><br />
        <a href="https://dpsru.edu.in/">DPSRU</a><br />
        <a href="https://dpsru.edu.in/dipsar">DIPSAR</a><br />
        <a href="https://dpsru.edu.in/soPharmaceticalSciences">School of Pharmaceutical Sciences</a><br />
        <a href="https://dpsru.edu.in/sahsm">School of Allied Health Sciences & Management</a><br />
        <a href="https://dpsru.edu.in/school-of-physiotherapy">School of Physiotherapy</a><br />
        <a href="https://dpsru.edu.in/doctoralPrograms">Doctoral Programs</a>
      </td>
    </tr>
    <tr>
      <td>4.5</td>
      <td>Such other information</td>
      <td>(i) Grievance redressal mechanism</td>
      <td>
        Online Grievance redressal mechanism <a href="https://pgms.delhi.gov.in/">Click Here</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ii) Details of applications received under RTI and information provided</td>
      <td>
        <a href="https://dpsru.edu.in/static/media/RTI%202023-24.312816741790f16a6ccb.pdf">RTI 2023-24</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(iii) List of completed schemes/ projects/ Programmes</td>
      <td>
        These are the ongoing processes with regard to approval to institutions<br />
        <a href="https://dpsru.edu.in/RD_project">Click Here</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(iv) List of schemes/projects/ programmes underway</td>
      <td>
        Scheme for outsourcing scientific work of DPSRU/DIPSAR<br />
        <a href="https://dpsru.edu.in/RD_project">Click Here</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(v) Details of all contracts entered into including name of the contractor, amount of contract, and period of completion of contract</td>
      <td>
        <a href="https://dpsru.edu.in/static/media/Purchase%20Order%202023-24.d58a772f6972abd8c709.pdf">Click Here</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(vi) Annual Report</td>
      <td>
        DPSRU/DIPSAR being a subordinate office, its Annual Report is an integral part of the Govt. Of NCT of Delhi. No separate Annual Report prepared in this regard.<br />
        <a href="https://dpsru.edu.in/annual-report/">Click Here</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(vii) Frequently Asked Question (FAQs)</td>
      <td>
        <a href="https://dpsru.edu.in/">Click Here</a><br />
        <a href="https://dpsru.edu.in/static/media/Admission%20related%20FAQs.e04937c54bdb49710276.pdf">Admission related FAQs</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(viii) Any other information such as Citizen’s Charter</td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ix) Result Framework Document (RFD)</td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(x) Six monthly reports on the Performance against the benchmarks set in the Citizen’s Charter</td>
      <td>
        <a href="https://dpsru.edu.in/">Click Here</a><br />
        <a href="https://dpsru.edu.in/examination/examination-result">Examination Result</a>
      </td>
    </tr>
    <tr>
      <td>4.6</td>
      <td>Receipt & Disposal of RTI applications & appeals</td>
      <td>(i) Details of applications received and disposed</td>
      <td>
        Total RTI received during 2023-2024. All queries have been disposed<br />
        <a href="https://dpsru.edu.in/static/media/RTI%202023-24.312816741790f16a6ccb.pdf">Click Here</a><br />
        <a href="https://dpsru.edu.in/annualReportOfRti">Annual Report of RTI</a>
      </td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>(ii) Details of appeals received and orders issued</td>
      <td>
        Appeals received during 2023-2024. All appeals have been disposed<br />
        <a href="https://dpsru.edu.in/static/media/Annual%20details%20of%20first%20appeal%20of%20DPSRU%20from%202023%E2%80%9324.d24592e0218beff338b9.pdf">Click Here</a>
      </td>
    </tr>
    <tr>
      <td>4.7</td>
      <td>Replies to questions asked in the parliament</td>
      <td>Details of questions asked and replies given</td>
      <td>
        <a href="https://dpsru.edu.in/static/media/parliamentary.6dbd2c5120c8bd198928.pdf">Click Here</a>
      </td>
    </tr>
  </tbody>
</table>
        </table>
      </div>
    </div>
  );
}

export default EGovernance;
