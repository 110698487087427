import React from 'react'
import './IqacEvent.css'

import greenAudit from './iqac event data/greenAudit.png'
import inep2 from './iqac event data/inep2.jpg'
import lvt3 from './iqac event data/lvt3.webp'
import herbalMuseum4 from './iqac event data/herbalMuseum4.jpeg'
import placeholder5 from './iqac event data/placeholder5.png'
import hdhg6 from './iqac event data/hdhg6.jpg'
import sop7 from './iqac event data/sop7.jpg'
import pi8 from './iqac event data/pi8.jpg'
import rmm9 from './iqac event data/rmm9.png'
import iiel10 from './iqac event data/iiel10.jpeg'
import dhm11 from './iqac event data/dhm11.jpeg'

import DPSRUFLORA from './iqac event data/DPSRU-FLORA.pdf'

function IqacEvent() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                IQAC Event
            </h2>


            {/* event figure */}
            <div className="campus-highlight-container-in-about-page event-figure-container">

                <figure>
                    <a href={greenAudit}> <img src={greenAudit} alt="Sports Complex" /></a>
                    <figcaption>Green Audit</figcaption>
                </figure>

                <figure>
                    <a href={inep2}>  <img src={inep2} alt="Auditorium" /></a>
                    <figcaption>Implementation of National Education Policy</figcaption>
                </figure>

                <figure>
                    <a href={lvt3}><img src={lvt3} alt="Swimming Pool" /></a>
                    <figcaption>Teaching Learning and Quality Initiatives</figcaption>
                </figure>

                <figure>
                    <a href={DPSRUFLORA}><img src={herbalMuseum4} alt=" Musical-Fountain" /></a>
                    <figcaption> Medicinal plant garden establishment and Identification of DPSRU Flora</figcaption>
                </figure>

                <figure>
                    <a href={placeholder5}><img src={placeholder5} alt=" Musical-Fountain" /></a>
                    <figcaption> Introduction of Active Learning Corridors</figcaption>
                </figure>

                <figure>
                    <a href={hdhg6}><img src={hdhg6} alt=" Musical-Fountain" /></a>
                    <figcaption> Har Din Har Ghar Ayurved</figcaption>
                </figure>

                <figure>
                    <a href={sop7}><img src={sop7} alt=" Musical-Fountain" /></a>
                    <figcaption> Standard Operating Procedures [SOP ]</figcaption>
                </figure>

                <figure>
                    <a href={pi8}><img src={pi8} alt=" Musical-Fountain" /></a>
                    <figcaption> Policy Implementation</figcaption>
                </figure>

                <figure>
                    <a href={rmm9}><img src={rmm9} alt=" Musical-Fountain" /></a>
                    <figcaption> Mentor Mentee Program</figcaption>
                </figure>

                <figure>
                    <a href={iiel10}><img src={iiel10} alt=" Musical-Fountain" /></a>
                    <figcaption> INSTITUTIONAL INITIATIVES FOR ELECTORAL LITERACY</figcaption>
                </figure>

                <figure>
                    <a href={dhm11}><img src={dhm11} alt=" Musical-Fountain" /></a>
                    <figcaption> Development of Herbal museum</figcaption>
                </figure>
            </div>
        </div>
    )
}

export default IqacEvent