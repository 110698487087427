import React from "react";
import "./VisionMission.css";
import { Link } from "react-router-dom";
import AboutOurHonourableChancellor from './VisionMissionData/AboutOurHonourableChancellor.pdf'
import AboutOurHonourableViceChancellor from './VisionMissionData/AboutOurHonourableViceChancellor.pdf'
import StrategyPage from './VisionMissionData/StrategyPage.jpg'

const VisionMission = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Vision, Mission, Objectives & Quality Policy
      </h2>

      <div className="mision-vission-objectives-quality-each-sec-container">
        <h2 className="dpsru-secondary-heading-for-every-page">VISION</h2>
        <p>
          “To be the ultimate destination for education, training and research
          in pharmaceutical sciences and allied areas and thereby, cater to the
          health needs of the people at large”
        </p>
      </div>

      <div className="mision-vission-objectives-quality-each-sec-container">
        <h2 className="dpsru-secondary-heading-for-every-page">MISSION</h2>
        <p>
          “To develop core competency in pharmaceutical sector and contribute in
          building leaders, administrators and personnel who can take up
          responsibilities as pharmaceutical science professionals, appropriate
          for community, institutions and industries on health and management
          related fields”.
        </p>
      </div>

      <div className="mision-vission-objectives-quality-each-sec-container">
        <h2 className="dpsru-secondary-heading-for-every-page">OBJECTIVES</h2>
        <p>
          In line with its adopted vision and mission, DPSR University places a
          strong emphasis on an interdisciplinary approach that encourages
          collaboration among diverse professional streams, industry, and
          stakeholders and led the following objectives:
          <ol>
            <li>
              To have a comprehensive, multidisciplinary institute for learning
              and research with holistic approach in pharmaceutical and sciences
            </li>
            <li>
              To provide infrastructure and research facilities for the
              dissemination of the advanced knowledge to the students in various
              branches pharmaceutical sciences through innovative
              teaching-learning processes with inter-disciplinary approach such
              that they propagate their wisdom to acquire all kinds of knowledge
              and generate new ideas
            </li>
            <li>
              To educate and train manpower for the development of the country
              and establish linkages with industries for the promotion of
              science and technology through several health related courses in
              MLT, GNM, and X-Ray Technology
            </li>
            <li>
              To cultivate the spirit of embracing internationalism/ diversity
              and competitiveness/ competition amongst students such that novel
              and creative ideas are harnessed, leading to unique discoveries
              and inventions, for the advantage of society at large
            </li>
          </ol>
        </p>
      </div>

      <div className="mision-vission-objectives-quality-each-sec-container quality-policy-container-in-ms-viss-page">
        <h2 className="dpsru-secondary-heading-for-every-page">
          QUALITY POLICY
        </h2>
        <p>
          <span>Curricular Designing </span> : Unique Courses with Global
          Thinking & Local Needs
        </p>
        <p>
          <span>Teaching Learning Process </span> : Pedagogy with Participative
          Learning
        </p>
        <p>
          <span>Research & Innovation </span> : Research Excellence with
          Innovation & Incubation
        </p>
        <p>
          <span>Infrastructure </span> : Ambience for Rigorous Learning
        </p>
        <p>
          <span>Student Progression </span> : Encaging the Dreams by Job
          Creation{" "}
        </p>
        <p>
          <span>Governance </span> : Leadership without Power & Power without
          Hierarchy
        </p>
        <p>
          Best Practices <br />
        </p>
        <p>
          <span>
            स्वास्थ्य त्रिवेणी: योग, भोत्तिक- चिकित्सा एवं- औसध् विज्ञान{" "}
          </span>{" "}
          : Holistic Development Centred Around Quality Education i.e.
          Integration of Yoga, Sports and Physiotherapy for the holistic
          development of students
        </p>
        <p>
          <span>” लोकाः समस्ताः सुखिनो भवन्तु”</span> : Community Engaged
          University: Reaching the Community
        </p>
      </div>

      <div className="mision-vission-objectives-quality-each-sec-container quality-policy-container-in-ms-viss-page">
        <h2
          className="dpsru-secondary-heading-for-every-page"
          style={{ border: "2px solid orange" }}
        >
          OTHER IMPORTANT LINK
        </h2>
        <ul>
          <li>
            <a href={AboutOurHonourableChancellor}>About Our Honourable Chancellor</a>
          </li>
          {/* <li>
            <a href={AboutOurHonourableViceChancellor}>About Our Honourable Vice Chancellor</a>
          </li> */}
          <li>
            <Link to='/AuthoritiesoftheUniversity'>Authorities of the University</Link>
          </li>
          <li>
            <Link to="/governingActs">Governing Acts</Link>
          </li>
          <li>
            <a href={StrategyPage}>Strategic Plan of DPSRU</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VisionMission;
