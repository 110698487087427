import React from "react";
import "./NewsOfDpsru.css";

// data pdf File Import
import dpsruHarGharTiranga3 from "./News Of Dpsru Data/dpsruHarGharTiranga3.pdf";
import ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty from "./News Of Dpsru Data/Prof.-Ramesh-Goyals-visit-to-UCSI-University-as-Distinguished-Visiting-Faculty.pdf";
import DSIRDPSRUGujaratvisit1 from "./News Of Dpsru Data/DSIR-DPSRU-Gujarat-visit-1.pdf";
import CAFTConstitutiondaycelebrations from "./News Of Dpsru Data/CAFT-Constitution-day-celebrations.pdf";
import pediatricunit from "./News Of Dpsru Data/pediatric-unit.pdf";
import coviddrugnews from "./News Of Dpsru Data/covid-drug-news.pdf";
import BookRelease from "./News Of Dpsru Data/Book-Release.pdf";
import highimpactresearchpapermodified1 from "./News Of Dpsru Data/high-impact-research-paper-modified-1.pdf";
import DPSRUStudentsAchievement from "./News Of Dpsru Data/DPSRU-Students-Achievement.pdf";
import CONGRATULATIONS_GAURAV from "./News Of Dpsru Data/CONGRATULATIONS_GAURAV.pdf";
import ReAppointmentOfProfessior from "./News Of Dpsru Data/ReAppointmentOfProfessior.jpeg";
import DPSRUinNew_CMYS from "./News Of Dpsru Data/DPSRUinNew_CMYS.jpeg";
import Newsletter20thfebinternationalconfrence from "./News Of Dpsru Data/Newsletter-20th-feb-international-confrence.pdf";
import finishingSchoolOfPGdpsru from "./News Of Dpsru Data/finishingSchoolOfPGdpsru.jpg";

import { Link } from "react-router-dom";

function NewsOfDpsru() {
  return (
    <div className="newsOfDpsru-component">
      <h1 className="whatsnew-heading inner-container-heading-of-tab-button">
      ACHIEVEMENTS / NEWS OF DPSRU
      </h1>

      <div className=" inner-container" style={{background:'none'}}>
        <ul>
          <li>
            <Link to="/iastamOration13" className="whats_new1">
              13 IASTAM ORATION AND AWARDS FUNCTION-2023 &amp; INTERNATIONAL
              CONCLAVE
            </Link>
          </li>

          <li>
            <a href="/ucps" className="whats_new1">
              Selected Candidates DST
            </a>
          </li>

          <li>
            <a href={dpsruHarGharTiranga3} className="whats_new1">
              DPSRU Celebrates Har Ghar Tiranga events under Azadi ka Amrit
              Mahotsav
            </a>
          </li>

          <li>
            <a
              href={ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty}
              className="whats_new1"
            >
              Hon'ble VC Prof. Ramesh Goyal's visit to UCSI University as
              Distinguished Visiting Professor
            </a>
          </li>

          <li>
            <a
              href={DSIRDPSRUGujaratvisit1}
              className="whats_new1"
            >
              DSIR-DPSRU-CRTDH CAFT Team Gujarat Visit
            </a>
          </li>

          <li>
            <a
              href={CAFTConstitutiondaycelebrations}
              className="whats_new1"
            >
              DSIR-DPSRU-CRTDH Constitution Day Celebration
            </a>
          </li>

          <li>
            <a
              href={pediatricunit}
              className="whats_new1"
            >
              News on Inauguration of Pediatric Physiotherapy Unit
            </a>
          </li>

          <li>
            <a
              href={coviddrugnews}
              className="whats_new1"
            >
              DPSRU - The Research Partner Behind Scientifically Formulated
              Breakthrough Drug that Works on Covid19 Co-Morbidities
            </a>
          </li>

          <li>
            <a
              href={BookRelease}
              className="whats_new1"
            >
              Release of book entitled कोरोनाकाल - चिकित्सक की कलम से , authored
              by Prof. (Dr.) Shridhar Dwivedi, Honourable Chairman, BOG, DPSRU
            </a>
          </li>

          <li>
            <a
              href={highimpactresearchpapermodified1}
              className="whats_new1"
            >
              High Impact Research Paper Published by DPSRU Faculty
            </a>
          </li>

          <li>
            <a
              href={DPSRUStudentsAchievement}
              className="whats_new1"
            >
              DPSRU Student Shweta Mittal Wins Cash Prize and Accolades at Young
              Pharmaceutical Researchers Award
            </a>
          </li>

          <li>
            <a
              href={CONGRATULATIONS_GAURAV}
              className="whats_new1"
            >
              DPSRU Faculty Dr. Gaurav Jain awarded IPA -ACG Worldwide &amp;
              Scitech Center Award for Innovative Product for Year 2020
            </a>
          </li>

          <li>
            <a
              href="https://timesofindia.indiatimes.com/education/news/covid-19-third-wave-dpsru-opens-community-health-facility-at-university-premises/articleshow/84060430.cms"
              className="whats_new1"
            >
              DPSRU Community Health Facility Inauguration Ceremony Covered by
              TOI Online
            </a>
          </li>

          <li>
            <a
              href={ReAppointmentOfProfessior}
              className="whats_new1"
            >
              Re-appointment of Professor R. K. Goyal as the Honourable Vice
              Chancellor of DPSRU
            </a>
          </li>

          <li>
            <a
              href={DPSRUinNew_CMYS}
              className="whats_new1"
            >
              Inauguration of Center for Meditation and Yoga Sciences by CM
              Arvind Kejriwal
            </a>
          </li>

          <li>
            <a
              href={Newsletter20thfebinternationalconfrence}
              className="whats_new1"
            >
              International Conference of Cardiovascular Sciences 2020
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Alniche launched Novel Product in Collaboration with DPSRU.
              Published in The Economics Times (Heath World) On 27th July 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              DPSRU signs MOU with DIIF-RCDC-RDK-IITD and DIIF-PHD Chamber.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Hand on Training Programme on quality control of Biologicals.
              Published in Accuprec News.com on 29th June 2019.
            </a>
          </li>

          <li>
            <a
              href={finishingSchoolOfPGdpsru}
              className="whats_new1"
            >
              FINISHING SCHOOL OF PG STUDENTS in DPSRU. Published in Accuprec
              News.com on 12th May 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              DPSRU Organized Orientation Day for World Class Skill Centre
              Students. Published in Accuprec News.com on 12th May 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Exploration of Erstwhile Biodiversity of Andaman &amp; Nicobar
              Islands by DPSRU. Published in Accuprec News.com on 26th May 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              DPSRU Physiotherapy School Organized Finishing School Program.
              Published in Accuprec News.com on 29thApril 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              First Management Forum at DPSRU, New Delhi. Published in Accuprec
              News.com on 13th April 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Repository of Indigenous Remedies. Published in the Double Helical
              Magazine Special Issue August – September 2019 Vol. 5, Issue no.
              9. At page no. 12.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Delhi Pharmaceutical Sciences and Research University collaborates
              with China Pharma University for free research. Published in the
              /business.medicaldialogues.in on 10th Nov. 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Indore: When will vacancies be filled: NAAC team. Published in the
              Free press journal. 21st November 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              Indore: UTD, college syllabus should be same: NAAC team. Published
              in the Free press journal. 24th November 2019.
            </a>
          </li>

          <li>
            <a href="/ucps" className="whats_new1">
              Hon.VC of DPSRU gets ‘Pharma Ratan Award. Published in
              accuprec.online. on 1st December 2019.
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary
            </a>
          </li>

          <li>
            <a
              href="/ucps"
              className="whats_new1"
            >
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks as necessary
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NewsOfDpsru;
