import React from 'react'
import './YoutubeLink.css'
import { FaYoutube } from "react-icons/fa";

function YoutubeLink() {
    return (
        <div className='youtube-link-of-footer-dpsru'>


            <h2 className="youtube-link-heading">
                YouTube
            </h2>

            {/* youtube channal link */}
            {/* <a href="https://www.youtube.com/channel/UC1HJbrrrelw3nZy0ZHO0mgw" className='footer-youtube-icon youtube-link-youtube-logo'><FaYoutube /> </a> */}
            <a href="https://www.youtube.com/channel/UC1HJbrrrelw3nZy0ZHO0mgw" className='footer-youtube-icon youtube-link-youtube-logo'><FaYoutube /> </a>

            <div className="youtube-links-of-dpsru-container">
                <h3 className="youtube-link-sub-heading">
                    More Important YouTube Links
                </h3>
                <div className="links-container-youtube-dpsru">

                    <ul>
                        <li>
                            <a href="https://youtu.be/0WDB3qd4sxg">Hello Doctor| हैलो डॉक्टर | Arthritis Symptoms Prevention | Episode-44 | October 13, 2022</a>
                        </li>

                        <li>
                            <a href="https://youtu.be/LGo-RV2v5Gk">Hello Doctor| हैलो डॉक्टर | आज़ादी का अमृत महोत्सव | Episode- 23 | May 19, 2022</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=-3ZJ5425uys">Exclusive Interview with Prof Ramesh K Goyal, Vice Chancellor, DPSRU, New Delhi</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=5agYdspjqsQ">Apna Mulk Apna Aaeen Part-II | अपना मुल्क अपना आईन | Drugs and cosmetic Act,1940 | Feb 11, 2022</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default YoutubeLink