import React from "react";

const InformationDisclosedOnOwnInitiative = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        INFORMATION DISCLOSED ON OWN INITIATIVE
      </h2>

      <div className="information-as-be-prescribed-table-container">
        <table width="607">
          <tbody>
            
            <tr></tr>
            <tr>
              <td width="65">
                <p >
                  <strong>S.No.</strong>
                </p>
              </td>
              <td  width="160">
                <strong>Item</strong>
              </td>
              <td  width="195">
                <strong>Details of disclosure</strong>
              </td>
              <td  width="187">
                <strong>Remarks/ Reference Points</strong>
              </td>
            </tr>
            <tr>
              <td rowspan="3" width="65">
                <p >6.1</p>
              </td>
              <td  rowspan="3" width="160">
              Item /
information
disclosed so
that public
have
minimum
resort to use of
RTI Act to
obtain
information
              </td>
              <td  rowspan="3" width="195">
              As per Section 4
of RTI act 2005,
maximum of
information has
been disclosed
in Suo Moto
Disclosure for
transparency.
              </td>
              <td rowspan="3" width="187">
                <p >
                RTI given in the website of
DPSRU. All pertinent
information is made
available on the website so
that public can access the
information without
resorting RTI act <a href="https://rtionline.delhi.gov.in
/"><b> https://rtionline.delhi.gov.in </b>
/</a>

                </p>
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr>
              <td rowspan="8" width="65">
                <p >6.2</p>
              </td>
              <td  rowspan="8" width="160">
              Guidelines for
Indian
Government
Websites
(GIGW) is
followed
(released in
February, 2009
and included in
the
Central
Secretariat
Manual of
Office
Procedures
(CSMOP) by
Department of
Administrative
Reforms and
Public
Grievances,
Ministry of
Personnel,
Public
Grievance and Pensions, Govt. Of India)
              </td>
              <td  rowspan="6" width="195">
              (i) Whether
STQC
certification
obtained and its
validity.
              </td>
              <td  rowspan="6" width="187">
                (i) Obtaining of STQC certificate is under process
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr>
              <td  rowspan="2" width="195">
                (ii) Does the website show the certificate on the Website?
              </td>
              <td rowspan="2" width="187">
                <p >(ii) No</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InformationDisclosedOnOwnInitiative;
