import React from 'react'
import './AboutViksitBharat.css'
const AboutViksitBharat = () => {
  return (
    <div className="dpsru-every-component-css inner-container" style={{background:'none', marginTop:'0'}}>

        <h1 className="about-viksit-bharat-heading general-heading-for-viksit-bharat ">About Viksit Bharat</h1>
      <p className="about-viksit-bharat-paragrauph">
      Viksit Bharat@2047 is the vision of the Government of India to make India a developed nation by 2047, the 100th year of its independence. This overarching vision encompasses multiple facets of development, encompassing economic prosperity, social advancement, environmental sustainability, and effective governance. For the fulfilment of this vision there is enormous work that needs to be done to make India Viksit Bharat by 2047. With a population of 144 crores, India is one of the youngest nations with a median age of 29 years. It accounts for nearly 20% of the world’s total young population. To channelise the energy of today’s youth towards this goal of Developed India, on December 11, 2023 at 10 30 am Honourable Prime Minister Shri Narendra Modi launched “Viksit Bharat@2047: Voice of Youth” ideas portal and invite them to contribute for the vision of Viksit Bharat@2047.
      </p>
    </div>
  )
}

export default AboutViksitBharat
