import React from 'react'


import gov10 from "../Library Planning Committee/Library Planning Data/gov10.jpg";
import gov11 from "../Library Planning Committee/Library Planning Data/gov11.jpg";
import gov12 from "../Library Planning Committee/Library Planning Data/gov12.jpg";
import lt1 from "../Library Planning Committee/Library Planning Data/lt1.webp";
import lt2 from "../Library Planning Committee/Library Planning Data/lt2.jpg";
import lt3 from "../Library Planning Committee/Library Planning Data/lt3.jpg";


const LibraryTeam = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">
      Library Team
    </h2>
      
    <div className="planning-committee-img-container">

    <div className="p-c-each-img-container">
          <img src={gov10} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Dr. Gaurav Jain,</p>
            <p className="planning-committee-img-designation">
              Library Incharge
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov11} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Ms. Yogita Ahuja,</p>
            <p className="planning-committee-img-designation">
              Assistant Librarian
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov12} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Mr. Shankar Sengupta,</p>
            <p className="planning-committee-img-designation">Librarian</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={lt1} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Ms. Namrata Bhardwaj,
</p>
            <p className="planning-committee-img-designation">
            Library Attendant
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={lt2} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Ms. Sarita Rani,
</p>
            <p className="planning-committee-img-designation">Library Attendant</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={lt3} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Mr. Manoj Kumar,
</p>
            <p className="planning-committee-img-designation">
            Lab Attendant (Library)
            </p>
          </div>
        </div>

       
    </div>
    </div>
  )
}

export default LibraryTeam
