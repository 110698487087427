import React from "react";
import './TLPReports.css'

import tlp1 from './TLP Data/TLP-Alert-Feb-2023-1-1.pdf'
import tlp2 from './TLP Data/TLP-new.pdf'
import tlp3 from './TLP Data/TLP-alert_1st-Jan-2023-31st-jan-2023-1.pdf'
import tlp4 from './TLP Data/14.03.22-to-27.03.22.pdf'
import tlp5 from './TLP Data/07.03.22-to-13.03.22.pdf'
import tlp6 from './TLP Data/21-27-Feb-2022.pdf'
import junetoaug from './TLP Data/TLP report june to august.pdf'

const TLPReports = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">TLP Reports</h2>

      <div className="nirf-data-container">
        <section className="nirf-data-section-eact tlp-reports-section-data">
          <a href={junetoaug} className="nirf-botton" >june to august 2023</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
          <a href={tlp1} className="nirf-botton" >28 may 2023</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
        <a href={tlp2} className="nirf-botton" >11 - 16 January 2023</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
        <a href={tlp3} className="nirf-botton" >01 - 31January 2023</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
        <a href={tlp4} className="nirf-botton" >14- 27 March 2022</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
        <a href={tlp5} className="nirf-botton" >07- 13 March 2022</a>
        </section>
        <section className="nirf-data-section-eact tlp-reports-section-data">
        <a href={tlp6} className="nirf-botton" >21-27 February 2022</a>
        </section>
      </div>
    </div>
  );
};

export default TLPReports;
