import React from "react";
import "./DoctoralPrograms.css";

function DoctoralPrograms() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Doctoral Program</h2>
      <div className="inner-container">

        <ol>
        <li>Pharmacology</li>
        <li>Pharmaceutics</li>
        <li>Clinical Pharmacy</li>
        <li>Pharmaceutical Chemistry</li>
        <li>Pharmacognosy</li>
        <li>Pharmaceutical Quality Assurance</li>
        <li>Industrial Pharmacy</li>
        <li>Pharmaceutical Analysis</li>
        <li>Pharmaceutical Bio-Technology</li>
        <li>Pharmaceutical Management</li>
        <li>Public Health</li>
        <li>Drug Regulatory Affairs</li>
        <li>Physiotherapy</li>
        <li>Sports Science</li>
        </ol>
      </div>
    </div>
  );
}

export default DoctoralPrograms;
