import React,{useState} from 'react'
import './Rules.css'
import DpsruLogo from './DPSRU-LOGO.png'

import mPharm from './RulesFiles/mPharm.pdf'
import bPharm from './RulesFiles/bPharm.pdf'
import BoPhy from './RulesFiles/BoPhy.pdf'
import BScOld from './RulesFiles/BScOld.pdf'
import BScNew from './RulesFiles/BScNew.pdf'
import DoP from './RulesFiles/DoP.pdf'

function Rules() {
    

    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                DPSRU Examinations Rule Book
            </h2>
            <img src={DpsruLogo} alt="dpsru logo" className='dpsru-logo-rules' />
            <p className="rules-paragrauph-heading">DELHI PHARMACEUTICAL SCIENCES & RESEARCH UNIVERSITY
                (ESTABLISHED UNDER ACT-07 OF 2008, GOVT. OF NCT OF DELHI)
                PUSHP VIHAR, SECTOR – III, M.B. ROAD, NEW DELHI – 110017</p>

            <div className="rules-data-container">
                <h4 className="dpsru-secondary-heading-for-every-page">
                    PG PROGRAM
                </h4>
                <p><a href={mPharm}>Mater In Pharmacey</a></p>
            </div>

            <div className="rules-data-container">
                <h4 className="dpsru-secondary-heading-for-every-page">
                    UG PROGRAM
                </h4>
                <p><a href={bPharm}>Bachelor of Pharmacy</a></p>
                <p><a href={BoPhy}>Bachelor of Physiotherapy</a></p>
                <p><a href={BScOld}>B. Sc. Old Marking scheme</a></p>
                <p><a href={BScNew}>B. Sc. New Marking scheme 2020-23</a></p>
            </div>

            <div className="rules-data-container">
                <h4 className="dpsru-secondary-heading-for-every-page">
                   Diploma programmes
                </h4>
                <p><a href={DoP}>Diploma in Pharmacy</a></p>
            </div>
        </div>
    )
}

export default Rules