import React from "react";

import dipsarOldNotice01 from './Dipsar Notice files/sessional-exam-notice-6799-6804-1.pdf'
import dipsarOldNotice02 from './Dipsar Notice files/B.pharma-8th-semester-2nd-sessional-1.pdf'
import dipsarOldNotice03 from './Dipsar Notice files/B.pharma-8th-semester-2nd-sessional-1-1.pdf'
import dipsarOldNotice04 from './Dipsar Notice files/notice-of-M-pharm-3-semester-.pdf'
import dipsarOldNotice05 from './Dipsar Notice files/examination-notice-m.phrama-1.pdf'
import dipsarOldNotice06 from './Dipsar Notice files/sessional-exam-Dpharma-1st-year-1.pdf'
import dipsarOldNotice07 from './Dipsar Notice files/practice-school-session-B-pharma-7th-semester.pdf'
import dipsarOldNotice08 from './Dipsar Notice files/examination-duty-notice.pdf'
import dipsarOldNotice09 from './Dipsar Notice files/notice.pdf'
import dipsarOldNotice10 from './Dipsar Notice files/B-pharma-7th-semester-practice-school.pdf'
import dipsarOldNotice11 from './Dipsar Notice files/1sr-sessional-exam-D-pharma-2nd-year.pdf'
import dipsarOldNotice12 from './Dipsar Notice files/notice-for-commencement-of-classes-for-5-and-7-semester-.pdf'
import dipsarOldNotice13 from './Dipsar Notice files/office-order-.pdf'

const DipsarNotice = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Notice</h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={dipsarOldNotice01}>
              Notice For Exam 21.7.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice02}>
              Notice For B. Pharm Exam 25.4.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice03}>
              Notice For B. Pharm 13.4.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice04}>
              Notice For M. Pharm 3rd Semester 21.3.23
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice05}>
              Sessional Examination Notice for M. Pharm 1.2.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice06}>
              Notice For D. Pharm 1st year 3.2.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice07}>
              Notice 2.2.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice08}>
              Circular 9.2.23
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice09}>
              Notice 10.2.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice10}>
              Notice For B. Pharm 1.2.2023
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice11}>
              Notice For D. Pharm 2nd year 15.12.2022
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice12}>
              Circular 28.9.2022
            </a>
          </li>
          <li>
            <a href={dipsarOldNotice13}>
              Office order 15.7.2022
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DipsarNotice;
