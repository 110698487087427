import React from 'react'

import mbaPm from './DateSheet Data 2018/Date-Sheet-of-MBAMPHMHM-2018.pdf'
import MPharm from './DateSheet Data 2018/M.Pharm-2018-1.pdf'
import dPharm from './DateSheet Data 2018/Date-Sheet-of-D.Pharm-2018.pdf'
import BSc2018 from './DateSheet Data 2018/All-Date-Sheet-of-B.-Sc.-Hons.-2018.pdf'
import BPT from './DateSheet Data 2018/Date-Sheet-of-BPT-2018.pdf'
import BPharm from './DateSheet Data 2018/B.PHARM-DATESHEET-2018-1.pdf'
import prePhd from './DateSheet Data 2018/Date-Sheet-of-Ph.D-2018.pdf'

const DateSheet2018 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">
      Date Sheet - 2018
    </h2>

    <div className="results-data-link-container">
      <ul>
      <li>
            <a href={mbaPm}>MBA-PM</a>
          </li>
          <li>
            <a href={mbaPm}>MHM</a>
          </li>
          <li>
            <a href={mbaPm}>MPH</a>
          </li>
          <li>
            <a href={MPharm}>M. Pharm</a>
          </li>
          <li>
            <a href={dPharm}>D. Pharm</a>
          </li>
          <li>
            <a href={BSc2018}>B. Sc. (Hons.) Sports Science</a>
          </li>

          <li>
            <a href={BPT}>BPT</a>
          </li>
          <li>
            <a href={BPharm}>B. Pharm </a>
          </li>
          <li>
            <a href={prePhd}>Pre.-Ph.D </a>
          </li>
        </ul>
        </div>
        </div>
  )
}

export default DateSheet2018