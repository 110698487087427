import React from "react";
import "./LibraryPlanningCommittee.css";

import gov1 from "./Library Planning Data/rajib.png";
import gov2 from "./Library Planning Data/gov2.jpg";
import gov3 from "./Library Planning Data/gov3.jpg";
import gov4 from "./Library Planning Data/gov4.jpg";
import gov5 from "./Library Planning Data/gov5.jpg";
import gov6 from "./Library Planning Data/gov6.jpg";

import gov7 from "./Library Planning Data/gov7.jpg";
import gov8 from "./Library Planning Data/gov8.jpg";
import gov9 from "./Library Planning Data/gov9.jpg";
import gov10 from "./Library Planning Data/gov10.jpg";
import gov11 from "./Library Planning Data/gov11.jpg";
import gov12 from "./Library Planning Data/gov12.jpg";
import noimg from '../../../Central resources/Computer Center/Images/no img.webp'

const LibraryPlanningCommittee = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Library Planning Committee
      </h2>

      <div className="p-c-each-img-container pc-img-container-main">
        <img src={noimg} alt="Planning Committee" />
        <div className="planning-committee-text-container p-c-text-container-for-head">
          <p className="planning-committee-img-name"> -  </p>
          <p className="planning-committee-img-designation">
            Honourable Vice- Chancellor
          </p>
        </div>
      </div>

      {/* ohters */}
      <div className="planning-committee-img-container">
        <div className="p-c-each-img-container">
          <img src={noimg} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">  - </p>
            <p className="planning-committee-img-designation">Registrar</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov3} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">
              Prof. Harvinder Popli,
            </p>
            <p className="planning-committee-img-designation">Director, SPS</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov4} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Prof. P.K. Sahoo,</p>
            <p className="planning-committee-img-designation">
              Director DIPSAR
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov5} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Prof. Geeta Aggarwal,</p>
            <p className="planning-committee-img-designation">Dean Academics</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov6} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Prof. Rajiv Tonk,</p>
            <p className="planning-committee-img-designation">
              Dean Student Affairs
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov7} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Dr. Shilpa Jain,</p>
            <p className="planning-committee-img-designation">
              HOD Physiotherapy
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov8} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Dr. Jaseela Majid,</p>
            <p className="planning-committee-img-designation">HOD SAHS</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov9} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Dr. Probhodh Sharma,</p>
            <p className="planning-committee-img-designation">IT Incaharge</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov10} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Dr. Gaurav Jain,</p>
            <p className="planning-committee-img-designation">
              Library Incharge
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov11} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Ms. Yogita Ahuja,</p>
            <p className="planning-committee-img-designation">
              Assistant Librarian
            </p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={gov12} alt="Planning Committee" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Mr. Shankar Sengupta,</p>
            <p className="planning-committee-img-designation">Librarian</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryPlanningCommittee;
