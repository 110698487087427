import React from 'react'


import mba from './Placement Record 2019-2020 Files/MBA-19-20.pdf'
import mhm from './Placement Record 2019-2020 Files/MHM-19-20.pdf'
import mPharm from './Placement Record 2019-2020 Files/MPHARM-19-20.pdf'



const PlacementRecord20192020 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Placement Record 2019- 2020</h2>

    <div className="results-data-link-container">
      <ul>
        <li>
          <a >
          B. Pharm 
          </a>
        </li>
        <li>
          <a href={mba}>
          MBA
          </a>
        </li>
        <li>
          <a href={mhm}>
          MHM
          </a>
        </li>
        <li>
          <a href={mPharm}>
          M.PHARM 
          </a>
        </li>
      
     
      </ul>
    </div></div>
  )
}

export default PlacementRecord20192020