import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./AdminNav.css";

const AdminNav = () => {
  const auth = sessionStorage.getItem("admin");
  const navigate = useNavigate();

  //Logout function
  const logout = () => {
    sessionStorage.clear();
    navigate("/admin/admin-signup");
  };
  return (
    <div>
      <div className="navigation-container-admin">
        {/* authention */}

        {auth ? (
          <ul>
            <li>
              <NavLink
                to="/admin/gravience-form-data"
                className="navlink-of-admin-pannel-dpsru"
              >
                Gravience Form Data
              </NavLink>
            </li>
            <li className="logged-person-name-admin-dpsru"><span style={{color:'#b9a8a8'}}>Hello {"  "}  </span> {JSON.parse(auth).name} !</li>
            <li>
              <NavLink
                onClick={logout}
                to="/admin/admin-login"
                className="navlink-of-admin-pannel-dpsru"
              >
                Logout
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <NavLink
                to="/admin/admin-login"
                className="navlink-of-admin-pannel-dpsru"
              >
                Login
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default AdminNav;
