import React from "react";
import "./Nss.css";

import newGif from '../NEW/new.gif'

import nss01 from './NSS files/Report.pdf'
import nss05 from './NSS files/Report-Unity-Day-2022.pdf'
import nss06 from './NSS files/Report-NSS-ACTIVITY23-6-2022-1.pdf'
import nss07 from './NSS files/World-Malaria-Day-2022.pdf'
import nss08 from './NSS files/World-Malaria-Day-2022.pdf'
import nss09 from './NSS files/NSS-Application-Form-DPSRU.pdf'
import NSSEventreport from './NSS files/NSS Event report - Copy.pdf'

function Nss() {
  return (
    <div className="dpsru-every-component-css">
        <h4 className="dpsru-primary-heading-for-every-page">NSS</h4>
      <div className=" inner-container">
        <ul>
       <li>
          <a href={NSSEventreport}>
            {" "}
            NSS Event report
          </a> 
        <img src={newGif} alt="new" />
       </li>
       <li>
          <a href={nss01}>
            {" "}
            PUSHP PRADARSHNAM 2023{" "}
          </a> 
        <img src={newGif} alt="new" />
       </li>
       <li>
          <a href={nss01}>
            {" "}
            HAR DIN HAR GHAR AYURVED
          </a>
        
       </li>
       <li>
          <a href={nss01}>
            {" "}
            NSS Registration Drive for DPSRU Students
          </a>
        
       </li>

       <li>
          <a href={nss01}>
            {" "}
            NSS Awareness Camp Report – November 2022{" "}
          </a>
        
       </li>
       <li>
          <a href={nss05}>
            National Unity Day 2022 Report
          </a>
       </li>
       <li>
          <a href={nss06}>
            World Malaria Day 2022 Report
          </a>
       </li>
       <li>
          <a href={nss07}>
            {" "}
            World Malaria Day 2022 – Relevant Information and Schedule{" "}
          </a>
       </li>
       <li>
          <a href={nss08}>
            {" "}
            Notice: NSS Registration Drive for DPSRU Students{" "}
          </a>
       </li>
       <li>
          <a href={nss09}>
            Application form for Registration of NSS Volunteers
          </a>
       </li>
       </ul>
      </div>
    </div>
  );
}

export default Nss;
