import React, { useState, useEffect } from "react";

import "./GravienceFormData.css";

const GravienceFormData = () => {
  const [formData, setFormData] = useState([]);
  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    let result = await fetch("http://localhost:8080/graviance-data");

    result = await result.json();
    setFormData(result);
  };
  console.warn("form Data", formData);

  // const CurrentDate= new Date().toLocaleString() ;
  return (
    <div className="dpsru-every-component-css">
      <h2 className="admin-logged-in-pages-heading">GRAVIENCE FORM</h2>

      {/* <table>
          <thead>
            <tr>
              <th>Sl No. :</th>
              <th>Name :</th>
              <th>Email :</th>
              <th>Phone :</th>
              <th>Enrollment No. :</th>
              <th>Course Semester :</th>
              <th>Catergory :</th>
              <th>Comment or Message :</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((item, index) => (
              <tr>
                <th>{index + 1}</th>
                <th>{item.name}</th>
                <th>{item.email}</th>
                <th>{item.phone}</th>
                <th>{item.enrollment}</th>
                <th>{item.courseSemester}</th>
                <th>{item.category}</th>
                <th>{item.commentOrMsg}</th>
              </tr>
            ))}
          </tbody>
        </table> */}
      <div className="data-container-of-graviance-form">
        {formData.map((item, index) => (
          <ul className="inner-data-of-get-gravience-form-admin">
            <li><span className="graviance-data-key-admin"> Name : </span>{item.name}</li>
            <li style={{textTransform:'lowercase'}}><span className="graviance-data-key-admin"> Email : </span>{item.email}</li>
            <li><span className="graviance-data-key-admin"> Phone No. :</span>{item.phone}</li>
            <li><span className="graviance-data-key-admin"> Enrollment No. :</span>{item.enrollment}</li>
            <li><span className="graviance-data-key-admin"> Course & Semester :</span>{item.courseSemester}</li>
            <li><span className="graviance-data-key-admin"> Category : </span>{item.category}</li>
            <li><span className="graviance-data-key-admin"> Comment Or Message :</span>{item.commentOrMsg}</li>
            <li><span className="graviance-data-key-admin">  Date & Time :</span>{Date.now()}</li>
            <li><span className="graviance-data-key-admin"> Sl No. : </span>{index + 1}</li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default GravienceFormData;
