import React from "react";

import mbaPm from './DateSheet 2019 data/Date-Sheet-of-MBAMPHMHM-2019.pdf'
import MPharm from './DateSheet 2019 data/M.Pharm-2019-1.pdf'
import dPharm from './DateSheet 2019 data/Date-Sheet-of-D.Pharm-2019.pdf'
import BSc2019 from './DateSheet 2019 data/All-Date-Sheet-of-B.-Sc.-Hons.-2019.pdf'
import BPT from './DateSheet 2019 data/All-Date-Sheet-of-BPT-2019.pdf'
import BPharm from './DateSheet 2019 data/B.PHARM-DATESHEET-2019_rotated.pdf'
import prePhd from './DateSheet 2019 data/Date-Sheet-of-Ph.D-2019.pdf'

const DateSheet2019 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Date Sheet - 2019
      </h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={mbaPm}>MBA-PM</a>
          </li>
          <li>
            <a href={mbaPm}>MHM</a>
          </li>
          <li>
            <a href={mbaPm}>MPH</a>
          </li>
          <li>
            <a href={MPharm}>M. Pharm</a>
          </li>
          <li>
            <a href={dPharm}>D. Pharm</a>
          </li>
          <li>
            <a href={BSc2019}>B. Sc. (Hons.) Sports Science</a>
          </li>
          <li>
            <a href={BPT}>BPT</a>
          </li>
          <li>
            <a href={BPharm}>B. Pharm </a>
          </li>
          <li>
            <a href={prePhd}>Pre.-Ph.D </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DateSheet2019;
