import React from 'react'
import './IaqcFeedback.css'

import aluminiFeedback from './IAQC Feedback Data/1.4.2-Alumni-Feedback-Analysis.pdf'
import FacultyFeedback from './IAQC Feedback Data/1.4.2-Faculty-Feedback-Analysis.pdf'
import industryFeedback from './IAQC Feedback Data/1.4.2-Industry-Feedback-Analysed.pdf'
import parentsFeedback from './IAQC Feedback Data/1.4.2-Parents-Feedback-Analysis.pdf'
import studentFeedback from './IAQC Feedback Data/1.4.2-Student-Feedback-Analysis.pdf'

function IaqcFeedback() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">
                IQAC Feedback
            </h2>

            <div className="iaqc-feedback-inner-container">
                <h3 className="dpsru-secondary-heading-for-every-page">
                    Links for Feedback Forms
                </h3>

                <ol>
                    <li>

                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSew72tY1b-v2rsY3BwBrIJ5var_9TtKG1Za4hiA4OeKiucysA/closedform">
                            Faculty Feedback for Curricular Design and Development</a>
                    </li>
                    <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLScYsyBK3LC63ARFn_dn0-LYR7B45AIug2eBQ9-1PoW6khgnxQ/viewform">
                        Employer’s Feedback form</a>
                    </li>
                    <li>

                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfK29fA6GnKA7hE-PEX4gvAyaMMtDmhen6QSULco0g3mWpvig/viewform">
                            Student’s Feedback Form</a>
                    </li>
                    <li>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScEBiG8PSMKYrJpcfdp54Z8u0YMLRypdQg8jOkQ0u2oaIwFrQ/viewform">Parent’s Feedback form</a>
                    </li>
                    <li>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeRgNAc7pLi877PL5gZBEHlQ4SS1IgF7J_0W7Pc5EainQv0uQ/viewform">Alumni Feedback Form</a>
                    </li>
                </ol>
            </div>

            <div className="iaqc-feedback-inner-container">
                <h3 className="dpsru-secondary-heading-for-every-page">
                    Feedback Analysis Reports
                </h3>

                <ol>
                    <li>
                        <a href={aluminiFeedback}> Alumni Feedback Analysis</a>
                    </li>
                    <li>
                        <a href={FacultyFeedback}>Faculty Feedback Analysis</a>
                    </li>
                    <li>
                        <a href={industryFeedback}>Industry Feedback Analysis</a>
                    </li>
                    <li>
                        <a href={parentsFeedback}>Parent’s Feedback Analysis</a>
                    </li>
                    <li>
                        <a href={studentFeedback}>Student Feedback Analysis</a>
                    </li>
                </ol>

            </div>
        </div>
    )
}

export default IaqcFeedback