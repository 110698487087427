import React, { useState } from "react";

import profimg from "./IMG_0618-scaled-e1688470129183.jpg";

const DrRuhiAli = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Ruhi Ali</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutical chemistry
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :  ruhi [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.co.in/citations?user=GZbX55kAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.scopus.com/authid/detail.uri?authorId=36112917400">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0003-4889-3453">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">PhD</p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Design And Development Of Heterocyclic Compounds For Anticonvulsant
        Activity
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ol>
            <li>
              Haider M, Chauhan A, Tariq S, Pathak DP, Siddiqui N, Ali S, Pottoo
              FH, Ali R. Application of In silico Methods in the Design of Drugs
              for Neurodegenerative Diseases. Curr Top Med Chem.
              2021;21(11):995-1011. doi: 10.2174/1568026621666210521164545.
              PMID: 34061002.
            </li>
            <li>
              Ali R, Tariq S, Kareem O, Fayaz F, Aziz T, Dinesh M, Pottoo FH,
              Siddiqui N. Nutraceuticals for Sleep Disorders. Comb Chem High
              Throughput Screen. 2021 Jan 20. doi:
              10.2174/1386207324666210121111446. Epub ahead of print. PMID:
              33475068.
            </li>
            <li>
              Bhutani R, Kapoor G, Pathak DP, Husain A, Kant R, Ali R. Mini
              Review on Recent Advancements in the Therapeutic Potentials of
              Benzothiazoles. Current Bioactive Compounds. 2021; 16 [Ahead of
              Print] doi : .2174/1573407216666191223093550
            </li>
            <li>
              Shafi S, Khan S, Hoda F, Fayaz F, Singh A, Khan MA, Ali R, Pottoo
              FH, Tariq S, Najmi AK. Decoding novel mechanisms and emerging
              therapeutic strategies in breast cancer resistance. Current drug
              metabolism. 2020 Mar 1;21(3):199-210.
            </li>
            <li>
              Nusrat B, Siddiqui N, Sahu M, Naim MJ, Yar MS, Ali R, Alam
              O.Anticonvulsant evaluation of 2-pyrazolines carrying naphthyl
              moiety: An insight into synthesis and molecular docking study.
              Braz. J. Pharm. Sci. 2019; 55 Epub Aug 12, 2019 (IF-0.512)
            </li>
            <li>
              Khan MA., Ali R., Najmi A.K., Ahmad S. Pharmacological evidences
              for cytotoxic and antitumor properties of Boswellic acids from
              Boswellia serrata. J Ethnopharmacol. 2016;191:315-323 (IF-2.981)
            </li>
            <li>
              Siddiqui N., Alam M.S., M.S., Ali R.,Alam O. Synthesis of new
              benzimidazole and phenylhydrazinecarbothiomide hybrids and their
              anticonvulsant activity. Med Chem Res 2016; 25: 1390-1402 [DOI:
              10.1007/s00044-016-1570-6]. (IF-1.277)
            </li>
            <li>
              Ali R., Khan MA, Siddiqui N. Past, present and future of
              antiepileptic drug therapy – finding a place for heterocyclics.
              Mini Rev Med Chem. 2015;15(12):1024-50. (IF- 2.66)
            </li>
            <li>
              Ali R.,SiddiquiN.NewBenzo[d]thiazol-2-yl-aminoacetamides as
              potential anticonvulsants: Synthesis, activity and molecular
              properties prediction. Arch Pharm (Weinheim). 2015, 348, 1-12. (IF
              1.994)
            </li>
            <li>
              Ali R., Siddiqui N. Development of 1, 3 benzothiazol-2-yl
              hydrazine derivatives containing semicarbazone and
              thioamidepharmacophore as anticonvulsants. Lett Drug Des Disc.
              2014; 11 (10): 1214-1224. (IF 1.17)
            </li>
            <li>
              Ali R., Siddiqui N. Preliminary Anticonvulsant and Toxicity
              Screening of Substituted Benzylidenehydrazinyl-N-(6-substituted
              benzo[d]thiazol-2-yl)propanamides. The Sci World J. 2014: 194652.
            </li>
            <li>
              Nusrat B., Ali R., Siddiqui N., Habib A. Some heterocyclics with
              anticonvulsant Properties. Bull Pharm Res. 4(1); 21-36. (2014).
              <br />
              Ali R., Siddiqui N. Biological aspects of emerging benzothiazole:
              a short review. J Chem. 2013: 345198. (IF 1.3)
            </li>
            <li>
              Siddiqui N., Ahsan W., M.S.Alam., Ali R., Srivastava K. Design,
              synthesis and Evaluation of Anticonvulsant Activity of
              Pyridinyl-Pyrrolidones: A Pharmacophore Hybrid Approach. Arch
              Pharm (Weinheim). 2012; 345 (3): 185-194. (IF 1.994)
            </li>
            <li>
              Siddiqui N., Arshad M.F., Khan S.A., Ahsan W., Ali R.,Alam MS.,
              Ahmed S. Synthesis Of New PiperidinylIndanone Derivatives As
              Anticonvulsant Agents. Med Chem Res. 2012; 21: 726-733. (IF 1.277)
            </li>
            <li>
              Bhanupriya B., Siddiqui N., Pathak D., Alam M.S., Ali R., Azad B.
              Anticonvulsant Evaluation of Some newer Benzimidazole derivatives:
              Design and Synthesis, Acta Pol Pharm Drug Res. 2012; 60(1): 53-62.
              (IF 0.745)
            </li>
            <li>
              Siddiqui N., Rana A., Khan S.A., Alam O., Ahsan W., Ali R. Design,
              Synthesis and Anticonvulsant Screening of Newer
              Benzothiazole-Semicarbazones. Asian J Biomed Pharm Sci.2012;2(10):
              8-17.
            </li>
            <li>
              Siddiqui N., Ahsan W., Srivastava K., Alam S., Ali R.
              Anticonvulsant activity of a combined pharmacophore of
              pyrazolo-pyridines with lesser toxicity in mice. Bull Kor. Chem.
              Soc. 2011; 32(2): 576-582. (IF 0.602)
            </li>
            <li>
              Siddiqui N., Ahsan W., Alam M.S., Ali R., Jain S., Azad B., Akhtar
              J. Triazoles: As Potential Bioactive Agents. Int J Pharm Sci Rev
              Res. 2011; 8(1): 161-169.
            </li>
            <li>
              Siddiqui N., Akhter J., Ali R., Azad B., Andalip., An updated
              review: Emerging Anticonvulsants. Int J Pharm Bio Arch. 2010;
              1(5): 404-415.
            </li>
            <li>
              Siddiqui N., Andalip, Bawa S., Ali R., Afzal O., Akhtar M.J.,
              Kumar R. Antidepressant Potential of nitrogen-containing
              heterocyclic moieties: An Updated Review. J Pharm Bioall Sci.
              2011; 2(3): 194-212.
            </li>
            <li>
              Siddiqui N., Bhrigu B., Pathak D., Alam M.S., Ali R. Cytotoxicity
              and enzyme estimation of some newer benzimidazoles. Ann Biol Res.
              2011; 2(3): 194-199.
            </li>
            <li>
              Siddiqui N., Ali R., Arshad MF, Ahsan W., Ahmed S., Alam M.S.
              Anticonvulsant and Toxicity Evaluation of Newer
              4H-Benzo[1,4]oxazin-3-ones: The Effect of Two Hydrogen Bonding
              Domains. Arch Pharm (Weinheim). 2010; 10: 657-663.
            </li>
            <li>
              Siddiqui N., Ali R., Alam, MS., Ahsan, W. Pharmacological Profile
              of Benzoxazines: A short review. J Chem Pharm Res. 2010; 2(4):
              309-316.
            </li>
            <li>
              Siddiqui N., Azad B., Alam M.S. Ali R. Indoles: Role in diverse
              biological activities. Int J Pharm Clin Res. 2010; 2(4): 121-127.
            </li>
            <li>
              Siddiqui, N., Alam, M.S., Ahmed, S., Ali R., Arshad, M.F., and
              Ahsan, W. Pharmacological and computational approach to
              eslicarbazepine: A short review. Biomed Pharmacol J. 2009; 2(1):
              13-16.
            </li>
            <li>
              Professional membership
              <br />
              Registered Pharmacist, Delhi Pharmacy Council, India <br />
              Life Member of Indian Pharmacy Graduates’ Association
              <br />
              Participated in various extracurricular activities and served as
              member of Organising Committee of Faculty of Pharmacy’s Annual
              Fest- Rx-2006 and 2007.
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <ol><li><b>Ruhi Ali, </b><span >Nadeem Siddiqui (2015). Design, synthesis and </span><i><span >in vivo</span></i><span > screening of substituted 1, 3-benzo</span><i><span >[d]</span></i><span >thiazol-2-yl-propanamides as anticonvulsants.Poster presented at one day symposium entitled “Current Trends in Drug Discovery&nbsp; Research in India”, held on 11</span><span >th</span><span > April, 2015 at Department of Chemistry, School of Natural Sciences, Shiv Nadar University, India.</span></li><li><b>Ruhi Ali</b><span >, Nadeem Siddiqui (2014). Design, synthesis and </span><i><span >in vivo</span></i><span > screening of substituted 1, 3-benzo</span><i><span >[d]</span></i><span >thiazol-2-yl propanamides as anticonvulsants. Poster presented in 9</span><span >th</span><span > Symposium on Frontiers in Biomedical Research, held on 14-16</span><span >th</span><span > Feb 2014 in Dr. B. R. Ambedkar Center for Biomedical Research (ACBR), University of Delhi.</span></li><li><b>Ruhi Ali</b><span >, Nadeem Siddiqui (2012). Design synthesis and anticonvulsant screening of substituted benzothiazolespropanamides. Poster presented in INDIA PHARMA CONVENTION organized by Udyog Development Foundation”, held on 12</span><span >th</span><span > Feb 2012 in Delhi Institute of Pharmaceutical Sciences and Research (DIPSAR) New Delhi.</span></li></ol>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrRuhiAli;
