import React from "react";
import "./AboutUsExamination.css";
import khatrisir from "./unnamed.jpg";
import HussainAlam from  './examination team pic/HussainAlam.jpg'

import lakshyaDahiya from './examination team pic/lakshaykk.jpeg'
import sukritiGarg from './examination team pic/Ms. Sukriti Garg.png'
import MsKomal from './examination team pic/Ms. Komal.jpg'
import AkashK from './examination team pic/Mr. Akash Kumar.jpg'
import RohitSingh from './examination team pic/Mr. Rohit Singh.jpg'
import MrGaurav from './examination team pic/Mr. Gaurav.jpg'

const AboutUsExamination = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">About Us</h2>

      <div className="examination-about-us-container">
        <p className="examination-first-para">
          <p>
            Examination branch is the most crucial and inevitable part of any
            institution. It is actively involved in the evaluation process of
            students. Teaching and Assessment are the two major processes of any
            Academic Institution. Examination Branch plays a key role in the
            process of assessing the students with a high level of
            confidentiality and transparency. The Examination Branch is a well
            established and maintained branch at Delhi pharmaceutical Sciences
            and research university, Delhi with a team of committed and
            dedicated staff member. The Examination Branch in DPSRU is headed by
            the Assistant Controller of Examinations with a team of experts as,
            Examination Consultant, with sufficient administrative and
            supporting staff.
          </p>{" "}
          <img src={khatrisir} alt="" />
        </p>
        <br />
        <br />
        The Prime responsibilities of Examination Branch is to notify the
        examinations schedule, processing of examination applications, issuance
        of Hall Tickets, appointment of Superintendent, Deputy Superintendent
        conduct of all examinations in fair and systematic manner, to conduct
        Evaluation process, to publish results, issuance of Mark sheets,
        Migration Certificates , Provisional Certificates and Degree
        Certificates, and to Conduct Convocation, under the timely directions
        and approvals of Honorable Vice Chancellor. Examination Branch maintains
        all records pertaining to Examinations.
        <br />
        <p className="ramchander-khatri-message">
          (Dr. Ramchander Khatri) <br />
          Asstt. Controller of Examinations
        </p>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Examination Team
      </h2>


{/* Examination team  */}
{/* Head examinatin team */}
<div className="head-of-examination-team">
  <div className="head-examination-team-box">
  <img
            src={khatrisir}
            alt="img"
            className="head-examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Dr. Ramchander Khatri <br />Asstt. Controller of Examinations
          </p>{" "}
          <hr /> 
  </div>
  <div className="head-examination-team-box">
  <img
            src={HussainAlam}
            alt="img"
            className="head-examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Mr. Hussain Alam <br />Examinations Consultant
          </p>{" "}
          <hr /> 
  </div>
</div>


      <div className="examination-team-container">
        <div className="examination-team-box">
          <img
            src={lakshyaDahiya}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Mr. Lakshay Dahiya,<br /> LDC
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="examination-team-box">
          <img
            src={sukritiGarg}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Ms. Sukriti Garg,<br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="examination-team-box">
          <img
            src={MsKomal}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Ms. Komal,<br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="examination-team-box">
          <img
            src={AkashK}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Mr. Akash Kumar,<br /> D.E.O
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="examination-team-box">
          <img
            src={RohitSingh}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Mr. Rohit Singh ,<br /> MTS
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="examination-team-box">
          <img
            src={MrGaurav}
            alt="img"
            className="examination-team-img"
          />
          <p className="governingBoard-box-para boardOfGovernors-para">
          Mr. Gaurav,<br /> Examination Attendant
          </p>{" "}
          <hr /> <br />
        </div>

       
      </div>
    </div>
  );
};

export default AboutUsExamination;
