import React from "react";

import MbaPm from "./Result2020 Data/MBA-PM-2020.pdf";
import mhm from "./Result2020 Data/MHM-2020.pdf";
import mph from "./Result2020 Data/MPH-2020.pdf";
 import MPharm2021 from "./Result2020 Data/M.Pharm-2020.pdf";
 import DPharm2021 from "./Result2020 Data/D.Pharm-2020.pdf";
 import BscHons from "./Result2020 Data/All-Main-Semester-Result-of-B.-Sc.-Hons.-Sports-Science-2020-1.pdf";
 import BPT2021 from "./Result2020 Data/All-Main-Semester-Year-Result-of-BPT-2020.pdf";
import BphrmDatesheet from "./Result2020 Data/B.Pharm-SPS-2020.pdf";
 import bPharmDipsar from "./Result2020 Data/B.Pharm-Dipsar-2020.pdf";
 import PrePHD from "./Result2020 Data/Result-of-Pre.Phd-Examination-2020.pdf";


// import BphrmDatesheet from "./Result2020 Data/B.Pharm-SPS-2019.pdf";
//  import bPharmDipsar from "./Result2020 Data/B.Pharm-Dipsar-2019.pdf";
//  import PrePHD from "./Result2020 Data/Pre-Ph.D-2019.pdf";

const Result2020 = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
      Examination Results - 2020
      </h2>

      <div className="results-data-link-container">
        <ul>
          <li>
            <a href={MbaPm}>MBA-PM</a>
          </li>
          <li>
            <a href={mhm}>MHM</a>
          </li>
          <li>
            <a href={mph}>MPH</a>
          </li>
          <li>
            <a href={MPharm2021}>M. Pharm</a>
          </li>
          <li>
            <a href={DPharm2021}>D. Pharm</a>
          </li>
          <li>
            <a href={BscHons}>B. Sc. (Hons.) Sports Science</a>
          </li>
          <li>
            <a href={BPT2021}>BPT</a>
          </li>
          <li>
            <a href={BphrmDatesheet}>B. Pharm (SPS)</a>
          </li>
          <li>
            <a href={bPharmDipsar}>B. Pharm (DIPSAR)</a>
          </li>
          <li>
            <a href={PrePHD}>Pre.-Ph.D</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Result2020;
