import React from 'react'
import './ContactUs.css'

function Contactus() {
    return (
        <div className='dpsru-every-component-css'>

            <div className="contactus-container">
                <h2 className="dpsru-primary-heading-for-every-page">
                    Contact Us
                </h2>

                <div className="contactus-text">
                    <p className='dpsru-secondary-heading-for-every-page'> Delhi Pharmaceutical Sciences & Research University (DPSRU)</p>

                    <div className="contactus-details">
                        <span style={{ fontWeight: 'bold' }}>Location:</span> Mehrauli-Badarpur Road, Puspvihar Sector 3 New Delhi 110017, India <br />
                        <span style={{ fontWeight: 'bold' }}>Phone:</span> 011-29552039
                    </div>

                    <p className='dpsru-secondary-heading-for-every-page'> Asstt. Controller of Examinations</p>

                    <div className="contactus-details">
                        Telephone No.: <a href="tel:011-29552058" style={{color:'black',textDecoration:'none'}}>Call us at 011-29552058</a>
                        <br /> email: acoe [at] dpsru [dot] edu [dot] in


                    </div>
                </div>

                <h2 className="contact-us-note">Contact between 09:30 AM to 06:00 PM (Monday to Friday)</h2>
            </div>


        </div>
    )
}

export default Contactus