import React from "react";
import Iframe from "react-iframe";
import './LibraryFacultyInterview.css'

const LibraryFacultyInterview = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Faculty Interview
      </h2>

      <div className="container-of-video-library">
        <div className="video-container-library">
          <Iframe
           src="https://www.youtube.com/embed/5agYdspjqsQ"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          
          />
        </div>
        
        <div className="video-container-library">
          <Iframe
           src="https://www.youtube.com/embed/0WDB3qd4sxg"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
        </div>

        <div className="video-container-library">
          <Iframe
          src="https://www.youtube.com/embed/-mCbs0N_7QE"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
        </div>

        <div className="video-container-library">
          <Iframe
          src="https://www.youtube.com/embed/-3ZJ5425uys"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
        </div>

        <div className="video-container-library">
          <Iframe
         src="https://www.youtube.com/embed/aljk8tVGd6k"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
        </div>

        <div className="video-container-library">
          <Iframe
        src="https://www.youtube.com/embed/EJrSwk3trGQ"
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
          />
        </div>
      </div>
    </div>
  );
};

export default LibraryFacultyInterview;
