import React from 'react'
import './IastamOration13.css'

import Brochure from './Brochure.pdf'
import FinalProgram from './Final-Program.pdf'

function IastamOration13() {
  return (
    <div className='iastamOration-component'>
    <h2 className="iastamOration-heading">13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL CONCLAVE</h2>
<div className="iastamOration-data-container">
    <ul>
        <li><a href={Brochure}>13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL CONCLAVE</a></li>
        <li><a href={FinalProgram}>Scientific Schedule</a></li>
    </ul>
</div>

    </div>
  )
}

export default IastamOration13
