import React from "react";
import "./Staff.css";
import "./ResponsiveStaff.css";
import { Link } from "react-router-dom";


import noimg from './Staff Data/Administrative Staff/blank-profile-picture-973460_640.webp'
// Administrative Staff
import vcImg from "./Staff Data/Administrative Staff/download-46.png";
import popliMadam from "./Staff Data/Administrative Staff/PopliMadam.jpg";
import registrar from './Staff Data/Administrative Staff/PopliMadam.245f27493e38cdeeb86d.jpg'
import sahooSir from "./Staff Data/Administrative Staff/profPKSahooDirector.jpeg";
import GeetaAg from "./Staff Data/Administrative Staff/Picture3-e1686040434582.jpg";
import RajivTonk from "./Staff Data/Administrative Staff/IMG_6334-scaled-e1685079586344-150x150.jpg";


// new images dat 20feb2024
import newvcimgmaheshverma from '../Home/HomePageData/vc-sir-Mahesh-verma.png'


// ADMINISTRATIVE STAFF
import KhatriSir from "./Staff Data/Administrative Staff/khatiSir.jpg";
import nishaAssistant from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-20-at-1.05.35-PM-e1690276756631 (1).jpeg";
import RanjeetK from "./Staff Data/Administrative Staff/New-Doc-07-17-2023-18.28_2-1_page-0001.jpg";
import DhruvArora from "./Staff Data/Administrative Staff/IMG_20230720_111343-scaled-e1689832119495.jpg";
import PankajOSDsir from "./Staff Data/Administrative Staff/OSD-SIr-scaled-e1689832218556.jpg";
import Adityakaushik from "./Staff Data/Administrative Staff/IMG_2548-1-scaled-e1689762383179.jpg";
import jyotiPA from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-10-at-11.00.56-AM.jpeg";
import NishantKaushik from "./Staff Data/Administrative Staff/IMG_2659-scaled-e1690036853346.jpg";
import kishanK from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-20-at-12.16.52-PM.jpeg";
import rajeshKumar from "./Staff Data/Administrative Staff/IMG_2658-scaled-e1690036881980.jpg";
import lataDutt from "./Staff Data/Administrative Staff/IMG_2623-scaled-e1689833059564.jpg";
import shresthBagga from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-19-at-8.33.59-PM-e1689833152814-262x300.jpeg";
import arunKsharm from "./Staff Data/Administrative Staff/srfserfw4r2.jpeg";
import mdRizwan from "./Staff Data/Administrative Staff/IMG_2620-scaled-e1689833472831.jpg";
import mrRahul from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-20-at-2.34.28-PM.jpeg";
// import lakshayDahiya from "./Staff Data/Administrative Staff/IMG_1890-1892x2048.jpg";
import lakshayDahiya from "../Examination/Examination About Us/examination team pic/lakshaykk.jpeg";
import vikashYadav from "./Staff Data/Administrative Staff/WhatsApp-Image-2023-07-20-at-1.23.08-PM-e1689840245268.jpeg";

// LIBRARY STAFF

import sengupta from './Staff Data/Administrative Staff/IMG_0162-scaled-e1687759197697.jpg'
import yogitaAhuja from './Staff Data/Administrative Staff/IMG_0160-scaled-e1687759166652.jpg'
import namrata from './Staff Data/Administrative Staff/IMG_2072-scaled-e1689059563718.jpg'
import saritaRani from './Staff Data/Administrative Staff/IMG_0164-scaled-e1687759988337.jpg'
import manojK from './Staff Data/Administrative Staff/IMG_0168-scaled-e1687760040959.jpg'


// Medical Unit

import priyankaSonam from './Staff Data/Administrative Staff/IMG_2576-1-2048x1365.jpg'
import suhasiniD from './Staff Data/Administrative Staff/IMG_0318-scaled-e1689837701580.jpg'
import ramjilal from './Staff Data/Administrative Staff/ramjilal.jpeg'

import ProfVRavichandiranVC from '../Home/HomePageData/prof v ravichandiran.jpeg'

function Staff() {
  return (
    <div className="dpsru-every-component-css">
      {/* Upper section of staff component */}

      <h2 className="dpsru-primary-heading-for-every-page ">
        DELHI PHARMACEUTICAL SCIENCES AND RESEARCH UNIVERSITY
      </h2>

      <h2 className="dpsru-secondary-heading-for-every-page">
        Administrative Staff
      </h2>

      <div className="staff-each-image-container">


        <div className="staff-each-image-box">
          <img src={ProfVRavichandiranVC} alt="img" className="staff-each-image-img-main" />
          <p className="staff-each-image-paragrauph">
          Prof. Ravichandiran V. <br />
            Vice-Chancellor
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="staff-each-image-box">
          <img src={sahooSir} alt="img" className="staff-each-image-img-main" />
          <p className="staff-each-image-paragrauph">
          Prof. P. K. Sahoo <br />
            Registrar, DPSRU & Director, DIPSAR
          </p>{" "}
          <hr /> <br />
        </div>

        {/* <div className="staff-each-image-box">
          <img src={sahooSir} alt="img" className="staff-each-image-img-main" />
          <p className="staff-each-image-paragrauph">
            Prof. P. K. Sahoo <br />
            Director, DIPSAR
          </p>{" "}
          <hr /> <br />
        </div> */}

        <div className="staff-each-image-box">
          <img src={noimg} alt="img" className="staff-each-image-img-main" />
          <p className="staff-each-image-paragrauph">
             <br />
            Dean, Academics
          </p>{" "}
          <hr /> <br />
        </div>

        <div className="staff-each-image-box">
          <img src={noimg} alt="img" className="staff-each-image-img-main" />
          <p className="staff-each-image-paragrauph">
            <br />
            Dean, Student Welfare
          </p>{" "}
          <hr /> <br />
        </div>
      </div>


{/* section 2 */}
      <h2 className="dpsru-secondary-heading-for-every-page">
        ADMINISTRATIVE STAFF
      </h2>

      <div className="staff-each-image-container">
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={KhatriSir} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Dr. Ramchander Khatri, <br /> Asst. Controller of Examination
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
 
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={nishaAssistant} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Nisha, <br /> Assistant Registrar
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        {/* <div className="staff-each-image-box-secondary-box-small">
          <img src={RanjeetK} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Ranjeet Kumar, <br /> Sr. Accounts Officer
          </p>{" "}
          <hr /> <br />
        </div> */}
 {/* each image box end*/}
 {/* each image box start*/}
 <div className="staff-each-image-box-secondary-box-small">
          <img src={PankajOSDsir} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Pankaj Kumar, <br /> OSD to Vice Chancellor
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={DhruvArora} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Shri Dhruv Arora, <br /> Assistant Accounts Officer
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

      


       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={Adityakaushik} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Aditya Kaushik, <br /> Section Officer
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={jyotiPA} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Jyoti, <br /> PA to Vice Chancellor
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

   {/* each image box start*/}
   <div className="staff-each-image-box-secondary-box-small">
          <img src={kishanK} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Kirshan Kumar <br /> Security In-charge cum Caretaker

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/} 

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={NishantKaushik} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Nishant Kaushik, <br /> Jr. Steno.
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        {/* <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Rajesh Rawal, <br /> Consultant (Purchase)

          </p>{" "}
          <hr /> <br />
        </div> */}
 {/* each image box end*/}

       {/* each image box start*/}
        {/* <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. S. S. Mathur, <br /> Consultant (Store)

          </p>{" "}
          <hr /> <br />
        </div> */}
 {/* each image box end*/}

     

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={rajeshKumar} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Rajesh Kumar, <br />  Cashier

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={lataDutt} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Lata Dutt, <br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={shresthBagga} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Shresth Bagga, <br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={arunKsharm} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Arun Kumar Sharma, <br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={mdRizwan} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Mohd. Rizwan,<br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={mrRahul} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Rahul, <br />
Office Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={lakshayDahiya} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Lakshay Dahiya- <br />(LDC)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}


       {/* each image box start*/}
        {/* <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Rahul Prasad Nautiyal, <br /> Office Assistant
          </p>{" "}
          <hr /> <br />
        </div> */}
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Deepanshu, <br /> Assistant

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Anjali Singh, <br /> Data Entry Operator

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Sahil, <br /> Data Entry Operator
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Vikram Singh, <br /> Data Entry Operator

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={vikashYadav} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Vikas Yadav, <br /> IT Assistant
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Supriya Singh, <br /> Office Executive 
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
      </div>

      {/* LIBRARY STAFF */}
      {/* section three */}
      <h2 className="dpsru-secondary-heading-for-every-page">
      LIBRARY STAFF
      </h2>

      <div className="staff-each-image-container">
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={sengupta} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. S. Sengupta <br />(Librarian)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={yogitaAhuja} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mrs. Yogita Ahuja <br /> (Assistant Librarian)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={namrata} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Namrata <br /> (Library Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={saritaRani} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mrs. Sarita Rani <br /> (Library Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={manojK} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Manoj Kumar, <br /> Lab Attendant (Library)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
    </div>


    {/* Medical Unit */}
    {/* sec 04 */}
    <h2 className="dpsru-secondary-heading-for-every-page">
    Medical Unit
      </h2>

      <div className="staff-each-image-container">
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={priyankaSonam} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Dr. Priyanka Sonam, <br /> Medical Officer
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={suhasiniD} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Suhasini D, <br /> Nurse

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
    </div>


 {/* LABORATORY AND OTHER STAFF */}
    {/* sec 05 */}
    <h2 className="dpsru-secondary-heading-for-every-page">
    LABORATORY AND OTHER STAFF
      </h2>

      <div className="staff-each-image-container">
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Man Singh <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Pradeep Kumar <br /> (Laboratory Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Ram Narayan  <br />(Laboratory Attendant)

          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Devender Kumar  <br />(Lab Assistant)


          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Jatin Gautam  <br />(Lab Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={ramjilal} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Ramji Lal <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Avinash Bansal, <br /> (Lab. Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Rajju <br /> (CLASS IV)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Bimla Rawat <br />(Store keeper)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Dinesh <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Mohit <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Deepali <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Shalini Pathania-  <br />(Lab Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Ram Kumar- <br /> (Lab Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Amit Mehto <br />(Lab Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Prashant Kumar <br /> (Lab Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Ms. Neeta Rathore <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Sujoy Robinson <br /> (Laboratory Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Mohit Kumar <br /> (Office Assistant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mrs. Pramlata- <br />(Girls Hostel Matron)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Naresh <br /> (OPD Attendant)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Sunil Kumar <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Naresh Chauhan <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Gaurav <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Gaurav Kumar <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Manish <br />(MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}

       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Deepak <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Priyadarshee Pathak <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Yogesh Kumar <br /> (MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Ranjit Paswan <br /> (Guest House Attendant/Cook)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Jasveer <br />(MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}
       {/* each image box start*/}
        <div className="staff-each-image-box-secondary-box-small">
          <img src={noimg} alt="img" className="staff-each-image-img" />
          <p className="staff-each-image-paragrauph">
          Mr. Sandeep <br />(MTS)
          </p>{" "}
          <hr /> <br />
        </div>
 {/* each image box end*/}




    </div>
    </div>
  );
}

export default Staff;
