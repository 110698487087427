import React from 'react'
import './IaqcMoM.css'
import iaqcCellPic from './iaqcCellPic.jpg'

// iqac COMPOSITION data
import comp1 from './MoMData/Composition-of-IQAC-2018.pdf'
import comp2 from './MoMData/IQAC-Composition-2020.pdf'

// iqac mom data
import mom1 from './MoMData/Minutes-of-Meeting-8-12-2020.pdf'
import mom2 from './MoMData/Minutes-of-Meeting-01-03-2021.pdf'
import mom3 from './MoMData/Minutes-of-Meeting-9-3-2021.pdf'
import mom4 from './MoMData/Minutes-of-Meeting-16-08-2021-1.pdf'
import mom5 from './MoMData/Minutes-of-Meeting-27-10-2021.pdf'
import mom6 from './MoMData/Minutes-of-Meeting-27-10-2021.pdf'
import mom7 from './MoMData/naac-meeting-feb-25.pdf'
import mom8 from './MoMData/naac-meeting-all-criteria.pdf'
import mom9 from './MoMData/202206231.pdf'
import mom10 from './MoMData/may-2022.pdf'
import mom11 from './MoMData/june-2022.pdf'
import mom12 from './MoMData/20220909174915_removed.pdf'
import mom13 from './MoMData/MOM-NAAC-07-11-2022-1.pdf'
import mom14 from './MoMData/MOM-12-12-2022.pdf'


function IaqcMoM() {
    return (
        <div className='dpsru-every-component-css' >
            <h2 className="dpsru-primary-heading-for-every-page">
                COMPOSITION AND MOM
            </h2>

            <div className="iaqc-mom-image-container">
                <img src={iaqcCellPic} alt="IAQC" />
            </div>

            {/* COMPOSITION */}
            <div className="composition-iaqc-container composition-and-mom-iaqc-container ">
                <h3 className="dpsru-secondary-heading-for-every-page">
                    COMPOSITION
                </h3>

                <ul>
                    <li> <a href={comp1}> Composition of IQAC 2018 </a> </li>
                    <li> <a href={comp2}> Composition of IQAC 2020 </a> </li>
                </ul>
            </div>

            {/* MINUTES OF MEETING */}
            <div className="mom-iaqc-container composition-and-mom-iaqc-container ">
                <h3 className="dpsru-secondary-heading-for-every-page">
                    MINUTES OF MEETING
                </h3>

                <ul>
                    <li>
                        <a href={mom1}>Minutes of Meeting – 08-12-2020</a>
                    </li>
                    <li>
                        <a href={mom2}>Minutes of Meeting – 01-03-2021</a>
                    </li>
                    <li>
                        <a href={mom3}>Minutes of Meeting – 09-03-2021</a>
                    </li>
                    <li>
                        <a href={mom4}>Minutes of Meeting – 16-08-2021</a>
                    </li>
                    <li>
                        <a href={mom5}>Minutes of Meeting – 27-10-2021</a>
                    </li>
                    <li>
                        <a href={mom6}>Minutes of Meeting – 27-10-2021</a>
                    </li>
                    <li>
                        <a href={mom7}>Minutes of Meeting – 25-02-2022</a>
                    </li>
                    <li>
                        <a href={mom8}>Minutes of Meeting – 07-03-2022, 08-03-2022, 09-03-2022</a>
                    </li>
                    <li>
                        <a href={mom9}>Minutes of Meeting – 27.04.2022 28.04.2022</a>
                    </li>
                    <li>
                        <a href={mom10}>Minutes of Meeting – 26.05.2022 27.05.2022</a>
                    </li>
                    <li>
                        <a href={mom11}>Minutes of Meeting – 21.06.2022 22.06.2022</a>
                    </li>
                    <li>
                        <a href={mom12}>Minutes of Meeting – 24.08.2022 25.08.2022</a>
                    </li>
                    <li>
                        <a href={mom13}>Minutes of Meeting – 07.11.2022</a>
                    </li>
                    <li>
                        <a href={mom14}>Minutes of Meeting – 12-12-2022 13-12-2022</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default IaqcMoM