import React from "react";
import "./CpioAndFaa.css";

const CpioAndFaa = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Public Information Officer(PIO), Assistant Public Information
        Officer(APIO), First Appellate Authority(FAA), Nodal Officer- RTI Cell
      </h2>

      <div className="cpio-and-faa-data-container">
        {/* box-1 */}
        <div className="cpio-and-faa-data-box">
          <h3 className="dpsru-secondary-heading-for-every-page">
            Public Information Officer (PIO)
          </h3>
          <h4 className="cpio-and-faa-faculty-name">Dr. J. Swaminathan,</h4>

          <p className="cpio-and-faa-faculty-details">
            Public Information Officer/Assistant Professor, Delhi Pharmaceutical
            Sciences and Research University (DPSRU/DIPSAR),Govt. of NCT of
            Delhi
          </p>

          <p className="cpio-and-faa-faculty-address">
            Mehrauli-Badarpur Road, Sector-3, Pushpvihar, New Delhi- 110017
            Phone: 011-29553675 Email:swamilingam@dpsru.edu.in
          </p>
        </div>

        {/* box-2 */}
        <div className="cpio-and-faa-data-box">
          <h3 className="dpsru-secondary-heading-for-every-page">
            First Appellate Authority (FAA)
          </h3>
          <h4 className="cpio-and-faa-faculty-name">Prof. PK Sahoo,</h4>

          <p className="cpio-and-faa-faculty-details">
            First Appellate Authority (FAA)/ Registrar Delhi Pharmaceutical
            Sciences and Research University (DPSRU/DIPSAR),Govt. of NCT of
            Delhi
          </p>

          <p className="cpio-and-faa-faculty-address">
            Mehrauli-Badarpur road, Sector-3, Pushpvihar, New Delhi- 110017
            Telephone: 29552039, 29552040 E-Mail: registrar@dpsru.edu.in
          </p>
        </div>

        {/* box-3 */}
        <div className="cpio-and-faa-data-box">
          <h3 className="dpsru-secondary-heading-for-every-page">
            Assistant Public Information Officer (APIO)
          </h3>
          <h4 className="cpio-and-faa-faculty-name">Shri Aditya Kaushik,</h4>

          <p className="cpio-and-faa-faculty-details">
            Assistant Public Information Officer/Section Officer,Delhi
            Pharmaceutical Sciences and Research University (DPSRU/DIPSAR),Govt.
            of NCT of Delhi
          </p>

          <p className="cpio-and-faa-faculty-address">
            Mehrauli-Badarpur Road, Sector-3, Pushpvihar, New Delhi- 110017
            Phone: 011-29553675
          </p>
        </div>

        {/* box-4 */}
        <div className="cpio-and-faa-data-box">
          <h3 className="dpsru-secondary-heading-for-every-page">
            Nodal Officer- RTI Cell
          </h3>
          <h4 className="cpio-and-faa-faculty-name">Dr. J. Swaminathan,</h4>

          <p className="cpio-and-faa-faculty-details">
            Public Information Officer/Assistant Professor, Delhi Pharmaceutical
            Sciences and Research University (DPSRU/DIPSAR),Govt. of NCT of
            Delhi
          </p>

          <p className="cpio-and-faa-faculty-address">
            Mehrauli-Badarpur Road, Sector-3, Pushpvihar, New Delhi- 110017
            Phone: 011-29553675 Email:swamilingam@dpsru.edu.in
          </p>
        </div>
      </div>
    </div>
  );
};

export default CpioAndFaa;
