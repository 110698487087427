import React, { useState } from "react";

import profimg from "./Picture1-e1686033825457.jpg";

const DrHarvinderPopli = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Harvinder Popli</h2>
      <h3 className="facult-designation-for-each-container">
        Dean (Research Innovation & International Affairs)
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
              popli [dot] harvinder [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?hl=en&user=risDGy0AAAAJ&view_op=list_works">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=6506528182">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/my-orcid?orcid=0000-0001-9030-5462">
                ORCID URL
              </a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Post Doctorate from University of Manchester, U.K. in 1996-97 2. •
        Doctor of Philosophy from Delhi University in 1991 3. • Postgraduate
        Diploma in Business Management in 1986 from IMS, Delhi-Gold medalist 4.
        • Master of Pharmacy (M.Pharm) from Delhi University in 1986 5.•
        Bachelor of Pharmacy (B. Pharm) from Delhi University in 1984
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Application of Nanotechnology for development of differentiated drug
        Delivery Systems for topical and Parental Release, Regulatory policies
        for Medical Devices, IVD and Biological
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Research Project&nbsp;</p>
                </td>
                <td>
                  <p>Grant</p>
                  <p>&nbsp;</p>
                </td>
                <td>
                  <p>Collaboration</p>
                  <p>&nbsp;</p>
                </td>
                <td>
                  <p>Status</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Development of Formulation for Chronic Kidney Disease and
                    oral nutrition for critical care
                  </p>
                </td>
                <td>
                  <p>Pharmaceutical company</p>
                  <p>Alniche&nbsp;</p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>Ongoing&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Interventional studies with involvement of Yoga and
                    Hydrotherapy in management of Stress , anxiety and
                    depression for youth Pursuing Higher Education
                  </p>
                </td>
                <td>
                  <p>Ministry of AYUS&nbsp;</p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>Ongoing&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Oral co-delivery of Amphotericin B with Immunostimulatos
                    conjugated Lipid Nanoparticles
                  </p>
                </td>
                <td>
                  <p>CSIR&nbsp;</p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>ongoing</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Establishment of DPSRU Centre for advanced formulation
                    technology
                  </p>
                </td>
                <td>
                  <p>
                    under common research and technology development hubs
                    (CRTDH)-DSIR Scheme” 2020.&nbsp;&nbsp;
                  </p>
                </td>
                <td>
                  <p>
                    Dr. Madhu Gupta (PI), Dr. Ajay Sharma (Co-PI), Prof.
                    Harvinder Popli (Co-PI), Dr. Saurabh Dahiya (Co-PI).
                  </p>
                  <p>&nbsp;</p>
                </td>
                <td>
                  <p>Completed&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Improving the administration of medicines to children
                    globally through a multi country approach
                  </p>
                </td>
                <td>
                  <p>
                    Society for pediatric medicine and healthcare initiative and
                    UCLA
                  </p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>Completed&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>External Consultancy for IPR&nbsp;</p>
                </td>
                <td>
                  <p>DJS legal -Attorneys at Law&nbsp;</p>
                </td>
                <td>&nbsp;</td>
                <td>
                  <p>Ongoing&nbsp;</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <span
            style={{ fontSize: "1.2rem" }}
            data-sheets-value='{"1":2,"2":"•\tKeynote Speaker, workshop on medical coding: Organized by Sanskar College of Pharmacy and Research. 21-23rd January 2021.  \n•\tOrganiser &amp; speaker, Opportunities after pharmacy. 10th January 2021.\n•\tCo-Chairperson (Atma Nirbhar in medical technology). Webinar on Development of National Technology Roadmap. 22 December 2020.\n•\tCo-Chairperson Webinar on Virtual incubation. Organized by start - up committee, PHD Chamber, 18th December 2020, Delhi. \n•\tJoint stakeholder’ Roundtable of India, UK &amp; Kenya on the Emerging need of skilled workforce during pandemics; 18th December 2020, Delhi \n•\tCo - Chairperson &amp; Panellist, Digital Health: The Future: Mobile digital health device: The covid-19 pandemic impact and mobile digital health Roadmap. Series 3rd. Organized by Health committee, PHD Chamber, 11 November 2020, Delhi.    \n•\tSpeaker in E-conclave on Government intervention in encouraging innovation, entrepreneurship and venture development (IEV). Organized by start - up committee, PHD Chamber, 20th October 2020, Delhi.\n•\tOnline Yoga session: ANHATA organised by Yoga Guru, Yoga and wellness centre, DPSRU: 4TH October 2020, Delhi.\n•\tSpeaker in E-Conclave on My India My Startup: 100 unicorns by 2025. 17th September 2020, Delhi.\n•\tPanelist, Webinar on Digital health: The future Series –II- Bridging the Gap: AI’s role in combating inequalities in Health. P.H.D chamber, 26 June 2020, Delhi.\n•\tPanelist, Webinar on COVID-19 hugely alters work environment for pharma &amp; healthcare as re-skilling takes centre stage. P.H.D chamber, 30th June 2020, Delhi.\n•\tPanelist, Atma Nirbhar in medical technology. Webinar on Innovation and Incentivisation of Indigenous Medtech Manufacturing. Series 3rd. Organized by Health committee, PHD Chamber, 15th January 2021, Delhi\n"}'
            data-sheets-userformat='{"2":513,"3":{"1":0},"12":0}'
          >
            • Keynote Speaker, workshop on medical coding: Organized by Sanskar
            College of Pharmacy and Research. 21-23rd January 2021. <br />
            <br />• Organiser &amp; speaker, Opportunities after pharmacy. 10th
            January 2021.
            <br />
            <br />• Co-Chairperson (Atma Nirbhar in medical technology). Webinar
            on Development of National Technology Roadmap. 22 December 2020.
            <br />
            <br />• Co-Chairperson Webinar on Virtual incubation. Organized by
            start – up committee, PHD Chamber, 18th December 2020, Delhi. <br />
            <br />• Joint stakeholder’ Roundtable of India, UK &amp; Kenya on
            the Emerging need of skilled workforce during pandemics; 18th
            December 2020, Delhi <br />
            <br />• Co – Chairperson &amp; Panellist, Digital Health: The
            Future: Mobile digital health device: The covid-19 pandemic impact
            and mobile digital health Roadmap. Series 3rd. Organized by Health
            committee, PHD Chamber, 11 November 2020, Delhi. <br />
            <br />• Speaker in E-conclave on Government intervention in
            encouraging innovation, entrepreneurship and venture development
            (IEV). Organized by start – up committee, PHD Chamber, 20th October
            2020, Delhi.
            <br />
            <br />• Online Yoga session: ANHATA organised by Yoga Guru, Yoga and
            wellness centre, DPSRU: 4TH October 2020, Delhi.
            <br />
            <br />• Speaker in E-Conclave on My India My Startup: 100 unicorns
            by 2025. 17th September 2020, Delhi.
            <br />
            <br />• Panelist, Webinar on Digital health: The future Series –II-
            Bridging the Gap: AI’s role in combating inequalities in Health.
            P.H.D chamber, 26 June 2020, Delhi.
            <br />
            <br />• Panelist, Webinar on COVID-19 hugely alters work environment
            for pharma &amp; healthcare as re-skilling takes centre stage. P.H.D
            chamber, 30th June 2020, Delhi.
            <br />
            <br />• Panelist, Atma Nirbhar in medical technology. Webinar on
            Innovation and Incentivisation of Indigenous Medtech Manufacturing.
            Series 3rd. Organized by Health committee, PHD Chamber, 15th January
            2021, Delhi
            <br />
            <br />
          </span>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Award for SPER-WF Eminent Teacher Award during 10
              th
              
                {" "}
                Annual International Conference &amp; Exhibition [SPER 2021]
                held at Ganpat University, Mehsana on 8
              
              th
               October 2021
            </li>
            <li>
              
                BLOOD DONATION GOODWILL AMBASSADOR in recognition by Rotary
                International during year 2021-2022
              
            </li>
            <li>
              
                Business Interview in International Journal , India Empire ,
                32-34, Vol 17 No.5, Oct 2021
              
            </li>
            <li>
              
                Awarded distinguished Service Award in Cardiovascular Science
                from International Medicine and Surgery, Winnipeg, Canada during
                ICCS, Feb 21st, 2020.
              
            </li>
            <li>
              
                Double Helical National Health award for contribution in
                research for ageing population in Feb-2020.
              
            </li>
          </ul>
          <ul>
            <li>
              Lifetime Achievement Award on 5
              th
              
                {" "}
                Annual National Conference at Lloyd as a Pharma Speaker by Lloyd
                Institute of Management &amp; Technology, Noida, 2019.
              
            </li>
            <li>
              
                Award received from Hon’ble Lieutenant Governor for Development
                of 1
              
              st
              
                {" "}
                Innovative Oral Intradialytic Product which was commercialized
                by Alniche at Vigyan Bhawan on 26
              
              th
               July, 2019.
            </li>
          </ul>
          <ul>
            <li>
              
                Leading/Mentored&nbsp; winner team at Lufthansa Impact Week.
                October 8-11-2018,2019 and 2020.Nominated by Institute of
                Economics studies 2019- Award for International Icon.
              
            </li>
            <li>
              Pharma Ratna Life Time Achievement Award by RDM. 2017
            </li>
            <li>
              
                International Icon Award for dedication towards Education Sector
                by –Institute of Economic Studies presented by H.E. Datuk Seri
                Dr S. Subramaniam Honourable Minister, Ministry of Health, Govt.
                of Malaysia. Sep – 2017.
              
            </li>
            <li>
              
                Award for Skill development by Indus Foundation on behalf of
                Delhi Pharmaceutical Research sand Sciences University.
              
            </li>
          </ul>
          <ul>
            <li>
              
                Award for significant contribution for the cause of Rotary and
                be the inspiration by Rotary, District Governor 2018-19 and
                2019-2020
              
            </li>
          </ul>
          <ul>
            <li>
              
                Double Helical National Health Awards for contribution in Public
                Healthcare Science ,Dec 2018
              
            </li>
            <li>
              
                Nominated by Women Economic Forum WEF-Hauge 2018 – Award for
                Exceptional Women of Excellence.
              
            </li>
          </ul>
          <ul>
            <li>
              
                International Leadership Innovative Excellence award for
                Contribution in&nbsp; Corporate Pharmaceutical Sector , by
                Institute of Economic Studies, April 2017
              
            </li>
            <li>
              
                Excellence award&nbsp; under Unification of Skill and Knowledge
                by Confluence for Contribution to&nbsp; Pharma corporate
                Research and Education
              
            </li>
            <li>
              
                Received “Team Excellence” award four times&nbsp; in 2006, 2008,
                2009 and 2010 in Ranbaxy for successful launch of&nbsp; high
                ended oncology products like Gliadel wafers for Gliomas ,
                SirSpheres for hepatic cell carcinoma to bridge unmet medical
                need, closing deal to support&nbsp; launch for rabies
                vaccine&nbsp; in record time and bringing first ever NCE
                Lulliconazole from Japan for multiple markets.
              
            </li>
          </ul>
          <ul>
            <li>
              
                Nominated for partnering excellence in drug delivery partnership
                at Orlando in Jan 2010 amongst all global NDDS companies.
              
            </li>
            <li>
              
                Chosen as among the Top 20 business development and
                licensing&nbsp; professionals&nbsp; out of 20,000 globally in
                Life Sciences Sector as listed in reputed European business
                development newsletter : EuroPlx Business developer Nov/Dec’2009
                edition; web-link is-
              
              <a href="http://www.europlx.com/pid/a96c6p0/Newsletter.html">
                http://www.europlx.com/pid/a96c6p0/Newsletter.html
              </a>
            </li>
            <li>
              
                Received Commonwealth fellowship from British Council to pursue
                Post doctorate. (1996-1997)
              
            </li>
            <li>
              
                Nagai foundation (Japan) award for best Oral presentation in
                Controlled Release Society, UK Chapter 1997.
              
            </li>
          </ul>
          <ul>
            <li>
              
                Appreciation award in IPA Convention held at Vigyan Bhawan in
                11-12 September, 2019.
              
            </li>
            <li>
              
                Appreciation award in&nbsp; Pharma speaker by Lloyd Institute of
                Management&nbsp; &amp; Technology, Noida, 2019
              
            </li>
          </ul>
          <ul>
            <li>
              
                Appreciation Award&nbsp; by Indian Pharmaceutical Association
                Convection 2019 for contribution in Regulatory Science
              
            </li>
          </ul>
          <ul>
            <li>
              
                Appreciation for contribution in continuing Education Programme
                (CEP) on Resurgence in Pharmaceutical Education, B.S Anangpuria
                Inst. of Pharmacy, 2019
              
              .
            </li>
          </ul>
          <ul>
            <li>
              
                Appreciation Award by Ph.D. Chamber, my India my start-up 100
                chamber by 2015. September 20, 2018.
              
            </li>
            <li>
              
                PHD Chamber Appreciation award for contribution in Education
                segment.&nbsp;
              
            </li>
            <li>
              
                Cover story in Pharma Express Journal of Indian Express listed
                as Women in leadership VOL.13NO.9 PAGES 74 , 2018
              
            </li>
            <li>
              
                Minor research project of UGC for controlled drug delivery
                technologies (1993)
              
            </li>
            <li>
              
                UGC Fellowship for PhD in Pharmaceutics, Delhi university
                (1987-1990)
              
            </li>
            <li>
              
                Gold Medal from Institute of Management, YMCA , Delhi for post
                graduate Diploma in Business Management (1986)
              
            </li>
            <li>
              
                UGC Fellowship for M.Pharm in Pharmaceutics (1984-1986)
              
            </li>
            <li>
              
                Gold Medal and Scholarship for First position in B.Pharm 1
              
              st
              ,2
              nd
               and Final year (1981-1984)
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrHarvinderPopli;
