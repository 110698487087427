import React from "react";
import "./TimeTableAcademics.css";

import newgif from '../../Logo/new.gif'

import tt1 from './TimeTableData/tt1.jpg'
import tt2 from './TimeTableData/tt2.jpg'
import tt3 from './TimeTableData/tt3.jpg'
import tt4 from './TimeTableData/tt4.jpg'
import tt5 from './TimeTableData/tt5.jpg'
import tt6 from './TimeTableData/tt6.jpg'
import tt7 from './TimeTableData/tt7.jpg'
import tt8 from './TimeTableData/tt8.jpg'
import tt9 from './TimeTableData/tt9.jpg'
import tt10 from './TimeTableData/tt10.jpg'
import tt11 from './TimeTableData/tt11.jpg'
import tt12 from './TimeTableData/tt12.jpg'
import tt13 from './TimeTableData/tt13.jpg'
import tt14 from './TimeTableData/tt14.pdf'
import tt15 from './TimeTableData/tt15.pdf'
import tt16 from './TimeTableData/tt16.pdf'
import tt17 from './TimeTableData/tt17.pdf'
import tt18 from './TimeTableData/tt18.pdf'
import tt19 from './TimeTableData/tt19.pdf'
import tt20 from './TimeTableData/tt20.jpg'
import tt21 from './TimeTableData/tt21.jpg'
import tt22 from './TimeTableData/tt22.jpeg'
import tt23 from './TimeTableData/tt23.pdf'
import tt24 from './TimeTableData/tt24.pdf'
import tt25 from './TimeTableData/tt25.pdf'

import tt26 from './TimeTableData/tt26.pdf'
import tt27 from './TimeTableData/tt27.pdf'
import tt28 from './TimeTableData/tt28.pdf'
import tt29 from './TimeTableData/tt29.pdf'
import tt30 from './TimeTableData/tt30.pdf'
import tt31 from './TimeTableData/tt31.pdf'
import tt32 from './TimeTableData/tt32.jpg'
import tt33 from './TimeTableData/tt33.jpg'
import tt34 from './TimeTableData/tt34.jpg'
import tt35 from './TimeTableData/tt35.jpg'
import tt36 from './TimeTableData/tt36.pdf'
import tt37 from './TimeTableData/tt37.pdf'
import tt38 from './TimeTableData/tt38.pdf'
import tt39 from './TimeTableData/tt39.pdf'
import tt40 from './TimeTableData/tt40.pdf'

import tt41 from './TimeTableData/tt41.pdf'
import tt42 from './TimeTableData/tt42.pdf'
import tt43 from './TimeTableData/tt43.jpg'
import tt44 from './TimeTableData/tt44.jpg'
import tt45 from './TimeTableData/tt45.pdf'
import tt46 from './TimeTableData/tt46.pdf'
import tt47 from './TimeTableData/tt47.pdf'
import tt48 from './TimeTableData/tt48.pdf'
import tt49 from './TimeTableData/tt49.pdf'
import tt50 from './TimeTableData/tt50.pdf'
import tt51 from './TimeTableData/tt51.jpg'
import tt52 from './TimeTableData/tt52.jpg'
import tt53 from './TimeTableData/tt53.jpg'
import tt54 from './TimeTableData/tt54.jpg'
import tt55 from './TimeTableData/tt55.pdf'
import BPharm_SPS from './TimeTableData/BPharm_SPS.pdf'

import mp1 from './TimeTableData/mp1.jpeg'
import mp2 from './TimeTableData/mp2.jpeg'
import mp3 from './TimeTableData/mp3.jpeg'
import mp4 from './TimeTableData/mp4.jpeg'
import mp5 from './TimeTableData/mp5.jpeg'
import mp6 from './TimeTableData/mp6.jpeg'

const TimeTableAcademics = () => {
  return (
    <div className="academic-unit-component">
      <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading">
        Time Table
      </h2>

      <div className="timeTable-data-container">
        {/* data */}
        <p>
          <a href={tt1}>
            Time Table DMLT – IIIrd sem Time
          </a> <img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt2}>
            Time Table BMLT – IIIrd sem Time
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt3}>
            Time Table MPH 3rd Semester Session 2022-23 ,November 11, 2022
            onwards
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt4}>
            Time Table BBA- CH 3rd Semester Session 2022-23 ,November 11, 2022
            onwards
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt5}>
            Time Table MHA 3rd Semester Session 2022-23 ,November 11, 2022
            onwards
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt6}>
            Time Table MBA- PET 3rd Semester Session 2022-23 ,November 11, 2022
            onwards
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt7}>
            Time Table MBA PM 3rd Semester Session 2022-23 ,November 11, 2022
            onwards
          </a><img src={newgif} alt="new" />
        </p>
        <p>
          <a href={tt8}>
            Time Table BMLT 2nd Semester Session 2021-22 ,September 5, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt9}>
            Time Table DMLT 2nd Semester Session 2021-22 ,September 5, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt10}>
            Time Table MPH 2nd Semester Session 2021-22 ,September 5, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt11}>
            Time Table BBA 2nd Semester Session 2021-22 ,September 5, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt12}>
            Time Table MBA – PET 2nd Semester Session 2021-22 ,September 5, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt13}>
            Time Table M.Sc. (AIPM) Session 2021-22 , July 1, 2022 onwards
          </a>
        </p>
        <p>
          <a href={tt14}>
            Time Table Masters in Hospital Adminsitration, 2nd Semester Session
            2021-22 , July 1, 2022 onwards
          </a>
        </p>
        <p>
          <a href={tt15}>
            Time Table Masters in Public Health, 2nd Semester Session 2021-22 ,
            July 1, 2022 onwards
          </a>
        </p>
        <p>
          <a href={tt16}>
            Time Table M.Sc. AI 2nd Semester Session 2021-22 , July 1, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt17}>
            Time Table BBA – COMMUNITY HEALTHCARE 2nd Semester Session 2021-22 ,
            July 1, 2022 onwards
          </a>
        </p>
        <p>
          <a href={tt18}>
            Time Table MBA – PET 2nd Semester Session 2021-22 ,July 1, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt19}>
            Time Table MBA – PM 2nd Semester Session 2021-22 , July 1, 2022
            onwards
          </a>
        </p>
        <p>
          <a href={tt20}>
            Time Table DMLT 2nd Semester Session 2021-22 wef June 14, 2022
          </a>
        </p>
        <p>
          <a href={tt21}>
            Time Table BMLT 2nd Semester Session 2021-22 wef June 14, 2022
          </a>
        </p>
        <p>
          <a href={tt22}>
            Time Table B. Pharm Even Semester Session 2021-22 wef May 23, 2022
          </a>
        </p>
        <p>
          <a href={tt23}>
            Time Table B. Pharm Even Semester Session 2021-22
          </a>
        </p>
        <p>
          <a href={tt24}>
            Time Table for BBA – CH : 1st Sem Session 2021-22
          </a>
        </p>
        <p>
          <a href={tt25}>
            Time Table for Masters in Pharmaceutical Management – IV Sem
            (Elective Subject) Session 2021-22
          </a>
        </p>
        <p>
          <a href={tt26}>
            Time Table for Masters in Public Health – IV Sem (Elective Subject)
            Session 2021-22
          </a>
        </p>
        <p>
          <a href={tt27}>
            Time Table for Masters in Hospital Management – IV Sem (Elective
            Subject) Session 2021-22
          </a>
        </p>
        <p>
          <a href={tt28}>
            M. Pharm-Pharmaceutical Analysis 1st Semester Time Table Session
            2021-22 (Offline Classes)
          </a>
        </p>
        <p>
          <a href={tt29}>
            Time Table, Executive M. Pharm. (Drug Regulatory Affairs), First
            Semester
          </a>
        </p>
        <p>
          <a href={tt30}>
            Time Table (Offline Classes) for DMLT, First Semester
          </a>
        </p>
        <p>
          <a href={tt31}>
            Time Table (Offline Classes) for BMLT, First Semester
          </a>
        </p>
        <p>
          <a href={tt32}>
            Revised Time Table (Offline Classes) for Masters in Hospital
            Administration, First Semester
          </a>
        </p>
        <p>
          <a href={tt33}>
            Revised Time Table (Offline Classes) for Masters in Pharma Economics
            &amp; Trade, First Semester
          </a>
        </p>
        <p>
          <a href={tt34}>
            Revised Time Table (Offline Classes) for MBA in Pharmaceutical
            Management, First Semester
          </a>
        </p>
        <p>
          <a href={tt35}>
            Revised Time Table (Offline Classes) for Masters in Public Health,
            First Semester
          </a>
        </p>
        <p>
          <a href={tt36}>
            M. Pharm – Pharmaceutical Biotechnology 1st Semester Time Table
            Session 2020-21 dated 11.02.2022
          </a>
        </p>
        <p>
          <a href={tt37}>
            M. Pharm-Drug Regulatory Affairs 1st Semester Time Table Session
            2020-21 dated 11.02.2022
          </a>
        </p>
        <p>
          <a href={tt38}>
            M. Pharm-Pharmacognosy &amp; Phytochemistry 1st Semester Time Table
            Session 2020-21 dated 11.02.2022
          </a>
        </p>
        <p>
          <a href={tt39}>
            M. Pharm-Industrial Pharmacy 1st Semester Time Table Session 2020-21
            dated 11.02.2022
          </a>
        </p>
        <p>
          <a href={tt40}>
            M. Pharm-Cosmeceutics 1st Semester Time Table Session 2020-21 dated
            11.02.2022
          </a>
        </p>
        <p>
          <a href={tt41}>
            B. Pharm Odd Semester Time Table wef 07.02.2022
          </a>
        </p>
        <p>
          <a href={tt42}>
            B. Pharm Odd Semester Time Table wef 12.01.2022
          </a>
        </p>
        <p>
          <a href={tt43}>
            Diploma in Medical Lab Technology(BMLT), dated 7/1/2022
          </a>
        </p>
        <p>
          <a href={tt44}>
            Bachelors in Medical Lab Technology(BMLT), dated 7/1/2022
          </a>
        </p>
        <p>
          <a href={tt45}>
            M. Pharm – Drug Regulatory Affairs (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt46}>
            M. Pharm – Pharmaceutical Analysis (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt47}>
            M. Pharm -Biotechnology (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt48}>
            M. Pharm – Cosmeceutics (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt49}>
            M. Pharm – Industrial Pharmacy (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt50}>
            M. Pharm – Pharmacognosy (1st Semester), Dated 30.12.21
          </a>
        </p>
        <p>
          <a href={tt51}>
            Masters in Hospital Administration, First Semester, Dated 28.12.21
          </a>
        </p>
        <p>
          <a href={tt52}>
            Masters in Pharma Economics &amp; Trade, First Semester, Dated
            28.12.21
          </a>
        </p>
        <p>
          <a href={tt53}>
            MBA in Pharmaceutical Management, First Semester, Dated 28.12.21
          </a>
        </p>
        <p>
          <a href={tt54}>
            Masters in Public Health, First Semester, Dated 28.12.21
          </a>
        </p>
        <p>
          <a
            style={{ fontWeight: "bold", fontSize: "1.5em" }}
            href={tt55}
          >
            Pre-PhD. Coursework wef. 23/03/2021
          </a>
        </p>
        <h2 className="time-table-secondary-heading">
          School of Pharmaceutical Sciences
        </h2>
        <p>
          <a href={BPharm_SPS}>
            B.Pharm (II, IV, VI, VIII Sem)
          </a>
        </p>
        <h2 className="time-table-secondary-heading">
          M.Pharm (II Semester) w.e.f. 24.05.2021
        </h2>

        <ul>
          <li>
            <a href={mp1}>
              Regulatory Affairs
            </a>
          </li>
          <li>
            <a href={mp2}>
              Pharmacognosy and Phytochemistry
            </a>
          </li>
          <li>
            <a href={mp3}>
              Pharmaceutical Biotechnology
            </a>
          </li>
          <li>
            <a href={mp4}>
              Industrial Pharmacy
            </a>
          </li>
          <li>
            <a href={mp5}>
              Pharmaceutical Analysis
            </a>
          </li>
          <li>
            <a href={mp6}>
              Cosmeceutics
            </a>
          </li>
        </ul>
      </div>
      {/* data */}
    </div>
  );
};

export default TimeTableAcademics;
