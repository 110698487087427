import React from "react";
import "./AcademicUnits.css";

const AcademicUnits = () => {
  return (
    <div className="academic-unit-component">
      <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading">
        The Academic Units
      </h2>

      <div className="academic-unit-date-container">
        <div className="academic-unit-first-container-of-data">
          <ul>
            <li>
              Delhi Institute of Pharmaceutical Sciences and Research (DIPSAR)
            </li>
            <li>School of Pharmaceutical Sciences</li>
            <li>School of Allied Health Sciences and Management</li>
            <li>School of Physiotherapy</li>
            <li>Academy of Sports, Sciences and Research Management (AASRM)</li>
            <li>Innovation and Incubation Foundation (DIIF)</li>
            <li>Yoga &amp; Fitness Centre</li>
            <li>World Class Skill Centre, DTTE, New Delhi</li>
            <li>DPSRU Bioincubator Centre</li>
            <li>Centre for Meditation and Yoga Sciences</li>
          </ul>
        </div>

        <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading">
          Centres of Excellence in the University
        </h2>
        <div className="academic-unit-first-container-of-data">
          <p>
            The university is committed to achieving/ accomplishing new
            benchmarks with global standards/ideals of excellence in the field
            of Pharmaceutical Science and Technology, Management and Allied
            Health Care areas by dessiminating knowledge through cutting-edge
            research, education and innovation.
          </p>

          <ul>
            <li>Ocular Pharmacology </li>
            <li>Clinical Research (BA/BE Centre)</li>
            <li>Advanced Herbal Drug Formulation &amp; Technology</li>
            <li>Bio Nest Center</li>
            <li>DIIF</li>
            <li>Nanotechnology</li>
            <li>Drug Discovery Center</li>
            <li>Precision Medicine Center</li>
            <li>Centre for Meditation and Yoga Sciences</li>
            <li>Pharmacovigilance and Outcome Research</li>
            <li>DPSRU-CRTDH-CAFT Centre</li>
            <li>DST-FIST</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AcademicUnits;
