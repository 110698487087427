import React, { useState } from "react";

import profimg from "./IMG_0024-scaled-e1686635295411.jpg";

const DrGauravKumarJain = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Gaurav Kumar Jain</h2>
      <h3 className="facult-designation-for-each-container">
        Associate Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          gkjdpsru [at] gmail [dot] com
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.com/citations?user=e616LMcAAAAJ&hl=en&oi=ao">
                Google Scholar
              </a>
            </li>
            <li>Scopus</li>
            <li>
              <a href="https://orcid.org/0000-0003-1719-238X">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        B. Pharma, M. Pharma, Ph. D
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph"></p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <b>Authors</b>
                </td>
                <td>
                  <b>Title</b>
                </td>
                <td>
                  <b>Publication</b>
                </td>
                <td>
                  <b>Vol</b>
                </td>
                <td>
                  <b>No.</b>
                </td>
                <td>
                  <b>Pages</b>
                </td>
                <td>
                  <b>Year</b>
                </td>
              </tr>
              <tr>
                <td>
                  Singh, Sima; Numan, Arshid; Sharma, Dinesh; Shukla, Rahul;
                  Alexander, Amit; Jain, Gaurav Kumar; Ahmad, Farhan Jalees;
                  Kesharwani, Prashant;&nbsp;
                </td>
                <td>
                  Epidemiology, virology and clinical aspects of hantavirus
                  infections: an overview
                </td>
                <td>International Journal of Environmental Health Research</td>
                <td>32</td>
                <td>8</td>
                <td>1815-1826</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Kawish, SM; Qadir, Abdul; Saad, Suma; Beg, Sarwar; Jain,
                  Gaurav K; Aqil, Mohd; Alanazi, Amer M; Khan, Azmat Ali;
                  Rashid, Md Abdur; Rab, Rehan A;&nbsp;
                </td>
                <td>
                  A Validated, Rapid and Cost-Efficient HPTLC Method for
                  Quantification of Gamma-Linolenic Acid in Borage Oil and
                  Evaluation of Antioxidant Activity
                </td>
                <td>Journal of Chromatographic Science</td>
                <td>60</td>
                <td>4</td>
                <td>364-371</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Modi, Deepika; Nirmal, Jayabalan; Warsi, Musarrat Husain;
                  Bhatia, Meenakshi; Hasan, Nazeer; Kesharwani, Prashant; Jain,
                  Gaurav K;&nbsp;
                </td>
                <td>
                  Formulation and development of tacrolimus-gellan gum
                  nanoformulation for treatment of dry eye disease
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>211</td>
                <td></td>
                <td>112255</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Nirmal, Jayabalan; Warsi, Musarrat H; Pandita,
                  Deepti; Kesharwani, Prashant; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  Topical Tacrolimus Progylcosomes Nano-Vesicles As a Potential
                  Therapy for Experimental Dry Eye Syndrome
                </td>
                <td>Journal of Pharmaceutical Sciences</td>
                <td>111</td>
                <td>2</td>
                <td>479-484</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Das, Amiya; Agarwal, Pallavi; Jain, Gaurav K; Aggarwal, Geeta;
                  Lather, Viney; Pandita, Deepti;&nbsp;
                </td>
                <td>
                  Repurposing Drugs as Novel Triple-negative Breast Cancer
                  Therapeutics
                </td>
                <td>Anti-Cancer Agents in Medicinal Chemistry&nbsp;</td>
                <td>22</td>
                <td>3</td>
                <td>515-550</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Malani, Manisha; Salunke, Prerana; Kulkarni, Shraddha; Jain,
                  Gaurav K; Sheikh, Afsana; Kesharwani, Prashant; Nirmal,
                  Jayabalan;&nbsp;
                </td>
                <td>
                  Repurposing pharmaceutical excipients as an antiviral agent
                  against SARS-CoV-2
                </td>
                <td>Journal of Biomaterials Science, Polymer Edition</td>
                <td>33</td>
                <td>1</td>
                <td>110-136</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Lim, Chooi Ling; Raju, Chandramathi S; Mahboob, Tooba;
                  Kayesth, Sunil; Gupta, Kamal K; Jain, Gaurav Kumar; Dhobi,
                  Mahaveer; Nawaz, Muhammad; Wilairatana, Polrat; de Lourdes
                  Pereira, Maria;&nbsp;
                </td>
                <td>
                  Precision and advanced nano-phytopharmaceuticals for
                  therapeutic applications
                </td>
                <td>Nanomaterials</td>
                <td>12</td>
                <td>2</td>
                <td>238</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Hasan, Nazeer; Imran, Mohammad; Sheikh, Afsana; Saad, Suma;
                  Chaudhary, Gaurav; Jain, Gaurav Kumar; Kesharwani, Prashant;
                  Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Cannabis as a potential compound against various malignancies,
                  legal aspects, advancement by exploiting nanotechnology and
                  clinical trials
                </td>
                <td>Journal of Drug Targeting</td>
                <td></td>
                <td></td>
                <td>Jan-17</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Singh, Tej Pratap; Verma, Navneet; Ahmad, Farhan Jalees;
                  Chandra, Phool; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  In vivo Wound Healing Potential of Raloxifene Nanoemulsion Gel
                  for the Management of Postmenopausal Cutaneous Wounds
                </td>
                <td>INDIAN JOURNAL OF PHARMACEUTICAL EDUCATION AND RESEARCH</td>
                <td>56</td>
                <td>2</td>
                <td>589-599</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Danish, Syed Mohammad; Gupta, Anshul; Khan, Urooj Ahmad;
                  Hasan, Nazeer; Ahmad, Farhan Jalees; Warsi, Musarrat Husain;
                  Ali, Ahmed M Abdelhaleem; Zafar, Ameeduzzafar; Jain, Gaurav
                  Kumar;&nbsp;
                </td>
                <td>
                  Intranasal Cerium Oxide Nanoparticles Ameliorate Cognitive
                  Function in Rats with Alzheimer’s via Anti-Oxidative Pathway
                </td>
                <td>Pharmaceutics</td>
                <td>14</td>
                <td>4</td>
                <td>756</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Ahmed, Mohammad Zubair; Gupta, Anshul; Warsi, Musarrat Husain;
                  Ali, Ahmed M Abdelhaleem; Hasan, Nazeer; Ahmad, Farhan J;
                  Zafar, Ameeduzzafar; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Nano Matrix Soft Confectionary for Oral Supplementation of
                  Vitamin D: Stability and Sensory Analysis
                </td>
                <td>Gels</td>
                <td>8</td>
                <td>5</td>
                <td>250</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Mahajan, Harshal; Jain, Gaurav K; Dhoot, Dhiraj; Deshmukh,
                  Gaurav A; Barkate, Hanmant V;&nbsp;
                </td>
                <td>
                  Serum and sebum pharmacokinetics evaluation of a novel
                  formulation of itraconazole in healthy volunteers
                </td>
                <td>Indian Journal of Drugs in Dermatology</td>
                <td>8</td>
                <td>1</td>
                <td>7</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Tiwari, Nidhi; Aggarwal, Geeta; Jain, Gaurav K; Mittal,
                  Gaurav;&nbsp;
                </td>
                <td>
                  Multi-drug loaded microneedles for emergency treatment of
                  snakebite envenomation
                </td>
                <td>Medical Hypotheses</td>
                <td>165</td>
                <td></td>
                <td>110908</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Kesharwani, Prashant; Chadar, Rahul; Shukla, Rahul; Jain,
                  Gaurav K; Aggarwal, Geeta; Abourehab, Mohammed AS; Sahebkar,
                  Amirhossein;&nbsp;
                </td>
                <td>
                  Recent advances in multifunctional dendrimer-based nanoprobes
                  for breast cancer theranostics
                </td>
                <td>Journal of Biomaterials Science, Polymer Edition</td>
                <td></td>
                <td></td>
                <td>Jan-39</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Kawish, SM; Hasan, Nazeer; Beg, Sarwar; Qadir, Abdul; Jain,
                  Gaurav K; Aqil, Mohd; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Docetaxel-loaded borage seed oil nanoemulsion with improved
                  antitumor activity for solid tumor treatment: Formulation
                  development, in vitro, in silico and in vivo evaluation
                </td>
                <td>Journal of Drug Delivery Science and Technology</td>
                <td>75</td>
                <td></td>
                <td>103693</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Hasan, Nazeer; Imran, Mohammad; Jain, Dhara; Shamim, Athar;
                  Beg, Sarwar; Kesharwani, Prashant; Jain, Gaurav; Ahmad, Farhan
                  J;&nbsp;
                </td>
                <td>
                  Rapid analytical method development and validation for the
                  simultaneous estimation of 5-Fluorouracil and Cannabidiol in
                  plasma and lipid-based nanoformulations
                </td>
                <td>Current Analytical Chemistry</td>
                <td>18</td>
                <td>7</td>
                <td>798-808</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Fatima, Mahak; Abourehab, Mohammed AS; Aggarwal, Geeta; Jain,
                  Gaurav K; Sahebkar, Amirhossein; Kesharwani, Prashant;&nbsp;
                </td>
                <td>
                  Advancement of cell-penetrating peptides in combating
                  triple-negative breast cancer
                </td>
                <td>Drug Discovery Today</td>
                <td></td>
                <td></td>
                <td>103353</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Arora, Gargi; Mishra, Abha; Chandra, Nilesh; Kesharwani,
                  Prashant; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  In vitro and in vivo scalp retention and penetration of
                  99mTc-minoxidil solution
                </td>
                <td>Journal of Pharmaceutical Sciences</td>
                <td></td>
                <td></td>
                <td></td>
                <td>2022</td>
              </tr>
              <tr>
                <td>
                  Singh, Pooja; Khan, Tooba; Ahmad, Farhan Jalees; Jain, Gaurav
                  Kumar; Bora, Jinku;&nbsp;
                </td>
                <td>
                  Development of chitosan edible coatings incorporated with
                  clove essential oil nanoemulsions and its effect on shelf life
                  of fresh-cut mangoes.
                </td>
                <td>Songklanakarin Journal of Science &amp; Technology</td>
                <td>43</td>
                <td>5</td>
                <td></td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Gaurav; Hasan, Nazeer; Malik, Ankit Kumar; Singh, Vanshikha;
                  Raza, Kaisar; Ahmad, Farhan J; Kesharwani, Prashant; Jain,
                  Gaurav K;&nbsp;
                </td>
                <td>
                  Recent update of 3D printing technology in pharmaceutical
                  formulation development
                </td>
                <td>Journal of Biomaterials Science, Polymer Edition</td>
                <td>32</td>
                <td>17</td>
                <td>2306-2330</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Saad, Suma; Beg, Sarwar; Jain, Gaurav K; Ahmad, Farhan
                  J;&nbsp;
                </td>
                <td>
                  Nanostructured Therapeutic Systems of PUFAs for the Treatment
                  of Glioblastoma Multiforme
                </td>
                <td>Current Drug Metabolism</td>
                <td>22</td>
                <td>14</td>
                <td>1087-1102</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Hasan, Nazeer; Imran, Mohammad; Kesharwani, Prashant; Khanna,
                  Kushagra; Karwasra, Ritu; Sharma, Nitin; Rawat, Sonalika;
                  Sharma, Deeksha; Ahmad, Farhan Jalees; Jain, Gaurav
                  Kumar;&nbsp;
                </td>
                <td>
                  Intranasal delivery of Naloxone-loaded solid lipid
                  nanoparticles as a promising simple and non-invasive approach
                  for the management of opioid overdose
                </td>
                <td>International journal of pharmaceutics</td>
                <td>599</td>
                <td></td>
                <td>120428</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Gorain, Bapi; Pandey, Manisha; Choudhury, Hira; Jain, Gaurav
                  K; Kesharwani, Prashant;&nbsp;
                </td>
                <td>Dendrimer for solubility enhancement</td>
                <td>Dendrimer-based nanotherapeutics</td>
                <td></td>
                <td></td>
                <td>273-283</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Khanna, Kushagra; Sharma, Nitin; Rawat, Sonalika; Khan, Nazia;
                  Karwasra, Ritu; Hasan, Nazeer; Kumar, Abhishek; Jain, Gaurav
                  Kumar; Nishad, Dhruv Kumar; Khanna, Sakshum;&nbsp;
                </td>
                <td>
                  Intranasal solid lipid nanoparticles for management of pain: A
                  full factorial design approach, characterization &amp; Gamma
                  Scintigraphy
                </td>
                <td>Chemistry and Physics of Lipids</td>
                <td>236</td>
                <td></td>
                <td>105060</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Bisht, Rohit; Nirmal, Sonali; Agrawal, Rupesh; Jain, Gaurav K;
                  Nirmal, Jayabalan;&nbsp;
                </td>
                <td>
                  Injectable in-situ gel depot system for targeted delivery of
                  biologics to the retina
                </td>
                <td>Journal of Drug Targeting</td>
                <td>29</td>
                <td>1</td>
                <td>46-59</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Sharma, Nitin; Karwasra, Ritu; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>Advanced drug delivery system in colorectal cancer</td>
                <td>
                  Advanced Drug Delivery Systems in the Management of Cancer
                </td>
                <td></td>
                <td></td>
                <td>259-266</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Sharma, Rahul; Iqbal, Zafar Ahmad; Kalra, Hemant; Guliani,
                  Abhinav; Singh, Anil K; Budhraja, Akshay; Jain, Gaurav;
                  Mehrotra, Bharat; Mangla, Loveleen; Kumar, Avi;&nbsp;
                </td>
                <td>
                  An Indian perspective on the use of fluticasone/formoterol in
                  asthma and chronic obstructive pulmonary disease patients
                </td>
                <td>Indian Journal of Respiratory Care</td>
                <td>10</td>
                <td>2</td>
                <td>196</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Das, Amiya; Pandita, Deepti; Jain, Gaurav Kumar; Agarwal,
                  Pallavi; Grewal, Ajmer Singh; Khar, Roop K; Lather,
                  Viney;&nbsp;
                </td>
                <td>Role of phytoconstituents in the management of COVID-19</td>
                <td>Chemico-biological interactions</td>
                <td>341</td>
                <td></td>
                <td>109449</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Chaurasiya, Akash; Singh, Ajeet K; Jain, Gaurav K; Warsi,
                  Musarrat H; Sublet, Emmanuelle; Ahmad, Farhan J; Borchard,
                  Gerrit; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Dual approach utilizing self microemulsifying technique and
                  novel P-gp inhibitor for effective delivery of taxanes
                  (Retraction of Vol 29, Pg 583, 2012)
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Modi, Deepika; Mohammad; Warsi, Musarrat H; Garg, Vaidehi;
                  Bhatia, Meenakshi; Kesharwani, Prashant; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Formulation development, optimization, and in vitro assessment
                  of thermoresponsive ophthalmic pluronic F127-chitosan in situ
                  tacrolimus gel
                </td>
                <td>Journal of Biomaterials Science, Polymer Edition</td>
                <td>32</td>
                <td>13</td>
                <td>1678-1702</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Malik, Kailash Chander; Karwa, Manoj; Jain, Gaurav K; Dutt,
                  Rohit;&nbsp;
                </td>
                <td>
                  Development and Validation of UPLC-MS/MS Method for the
                  determination of Rivaroxaban in human plasma using
                  Liquid-liquid extraction
                </td>
                <td>Research Journal of Pharmacy and Technology</td>
                <td>14</td>
                <td>6</td>
                <td>3239-3243</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Gupta, Anshul; Mathur, Rashi; Singh, Sweta; Bag, Narmada;
                  Khan, Urooj A; Ahmad, Farhan J; Gabr, Gamal A; Kesharwani,
                  Prashant; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  99mTc-methionine gold nanoparticles as a promising biomaterial
                  for enhanced tumor imaging
                </td>
                <td>Journal of Pharmaceutical Sciences</td>
                <td>110</td>
                <td>2</td>
                <td>888-897</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Hoda, Ubedul; Jain, Seema; Samim, Mohammed; Jain, Gaurav
                  Kumar; Agarwal, Nidhi Bharal;&nbsp;
                </td>
                <td>
                  Embelin ameliorates cognitive dysfunction and progression of
                  kindling in pentylenetetrazol-induced kindling in mice by
                  attenuating brain inflammation
                </td>
                <td>Epilepsy &amp; Behavior</td>
                <td>116</td>
                <td></td>
                <td>107788</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Nirmal, Jayabalan; Riadi, Yassine; Kesharwani,
                  Prashant; Kohli, Kanchan; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  Amelioration of endotoxin-induced uveitis in rabbit by topical
                  administration of tacrolimus proglycosome nano-vesicles
                </td>
                <td>Journal of Pharmaceutical Sciences</td>
                <td>110</td>
                <td>2</td>
                <td>871-875</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Singh, Tej Pratap; Ahmad, Farhan Jalees; Jain, Gaurav Kumar;
                  Verma, Navneet;&nbsp;
                </td>
                <td>
                  Formulation development and characterization of
                  nanoemulsion-based gel for topical application of raloxifene
                  hydrochloride
                </td>
                <td>Indian J Pharm Educ Res</td>
                <td>55</td>
                <td>4</td>
                <td>996-1007</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Suri, Reshal; Neupane, Yub Raj; Mehra, Nikita; Nematullah, Md;
                  Khan, Farah; Alam, Ozair; Iqubal, Ashif; Jain, Gaurav Kumar;
                  Kohli, Kanchan;&nbsp;
                </td>
                <td>
                  Sirolimus loaded chitosan functionalized poly
                  (lactic-co-glycolic acid)(PLGA) nanoparticles for potential
                  treatment of age-related macular degeneration
                </td>
                <td>International Journal of Biological Macromolecules</td>
                <td>191</td>
                <td></td>
                <td>548-559</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Bisht, Rohit; Raveena, Pinto; Nirmal, Sonali; Gayen,
                  Shovanlal; Jain, Gaurav K; Nirmal, Jayabalan;&nbsp;
                </td>
                <td>
                  Biopolymeric hydrogels prepared via click chemistry as
                  carriers of therapeutic modalities
                </td>
                <td>Tailor-Made and Functionalized Biopolymer Systems</td>
                <td></td>
                <td></td>
                <td>463-499</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Zafar, Sobiya; Beg, Sarwar; Panda, Sunil K; Rahman, Mahfoozur;
                  Alharbi, Khalid S; Jain, Gaurav K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Novel therapeutic interventions in cancer treatment using
                  protein and peptide-based targeted smart systems
                </td>
                <td>Seminars in cancer biology</td>
                <td>69</td>
                <td></td>
                <td>249-267</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Srivastava, Vaibhavi; Singh, Ajit; Jain, Gaurav Kumar; Ahmad,
                  Farhan Jalees; Shukla, Rahul; Kesharwani, Prashant;&nbsp;
                </td>
                <td>
                  Viral vectors as a promising nanotherapeutic approach against
                  neurodegenerative disorders
                </td>
                <td>Process Biochemistry</td>
                <td>109</td>
                <td></td>
                <td>130-142</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav; M Kewatker, Shailesh; Nayak, Govind; Nayak,
                  Amit;&nbsp;
                </td>
                <td>
                  Evaluation of the Antigenotoxic Potential of Methanolic Leaves
                  Extract of Triticum aestivum in Mice
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Musyuni, Pankaj; Kumar, Deepak; Pandita, Deepti; Jain, Gaurav
                  K; Nagpal, Manju; Aggarwal, Geeta;&nbsp;
                </td>
                <td>
                  Application of Nutraceuticals in Managing Osteoarthritis and
                  Osteoporosis
                </td>
                <td>Recent Patents on Food, Nutrition &amp; Agriculture</td>
                <td>12</td>
                <td>2</td>
                <td>88-103</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav; Agarwal, Pawan; Sharma, Dhananjaya; Agrawal,
                  Vikesh; Yadav, Sanjay K;&nbsp;
                </td>
                <td>
                  Workplace violence towards resident doctors in Indian teaching
                  hospitals: A quantitative survey
                </td>
                <td>Tropical doctor</td>
                <td>51</td>
                <td>3</td>
                <td>463-465</td>
                <td>2021</td>
              </tr>
              <tr>
                <td>
                  Suri, Reshal; Neupane, Yub Raj; Kohli, Kanchan; Jain, Gaurav
                  Kumar;&nbsp;
                </td>
                <td>
                  Polyoliposomes: Novel polyol-modified lipidic nanovesicles for
                  dermal and transdermal delivery of drugs
                </td>
                <td>Nanotechnology</td>
                <td>31</td>
                <td>35</td>
                <td>355103</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Suri, Reshal; Neupane, Yub Raj; Mehra, Nikita; Jain, Gaurav
                  Kumar; Kohli, Kanchan;&nbsp;
                </td>
                <td>
                  Sirolimus loaded polyol modified liposomes for the treatment
                  of Posterior Segment Eye Diseases
                </td>
                <td>Medical Hypotheses</td>
                <td>136</td>
                <td></td>
                <td>109518</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Raghuvanshi, Ashish; Khan, Urooj Ahmed; Parveen, Uzma; Gupta,
                  Anshul; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  An injectable sustained release lipid based in situ gel system
                  of aripiprazole for the management of schizophrenia
                </td>
                <td>Journal of Young Pharmacists</td>
                <td>12</td>
                <td>4</td>
                <td>303</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Zafar, Sobiya; Akhter, Sohail; Garg, Nupur; Selvapandiyan,
                  Angamuthu; Jain, Gaurav Kumar; Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  Co-encapsulation of docetaxel and thymoquinone in
                  mPEG-DSPE-vitamin E TPGS-lipid nanocapsules for breast cancer
                  therapy: Formulation optimization and implications on cellular
                  and in vivo toxicity
                </td>
                <td>European Journal of Pharmaceutics and Biopharmaceutics</td>
                <td>148</td>
                <td></td>
                <td>Oct-26</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Suri, Reshal; Neupane, Yub Raj; Jain, Gaurav Kumar; Kohli,
                  Kanchan;&nbsp;
                </td>
                <td>
                  Recent theranostic paradigms for the management of Age-related
                  macular degeneration
                </td>
                <td>European Journal of Pharmaceutical Sciences</td>
                <td>153</td>
                <td></td>
                <td>105489</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Zafar, Sobiya; Akhter, Sohail; Ahmad, Iqbal; Hafeez, Zubair;
                  Rizvi, M MoshahidAlam; Jain, Gaurav Kumar; Ahmad, Farhan
                  Jalees;&nbsp;
                </td>
                <td>
                  Improved chemotherapeutic efficacy against resistant human
                  breast cancer cells with co-delivery of Docetaxel and
                  Thymoquinone by Chitosan grafted lipid nanocapsules:
                  Formulation optimization, in vitro and in vivo studies
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>186</td>
                <td></td>
                <td>110603</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Ahmed, Mohammad Zubair; Khan, Urooj A; Haye, Abdul; Agarwal,
                  Nidhi B; Alhakamy, Nabil A; Alhadrami, Hani A; Warsi, Musarrat
                  Husain; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Liquid crystalline nanoparticles for nasal delivery of
                  rosuvastatin: Implications on therapeutic efficacy in
                  management of epilepsy
                </td>
                <td>Pharmaceuticals</td>
                <td>13</td>
                <td>11</td>
                <td>356</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Muheem, Abdul; Zafar, Sobiya; Jahangir, Mohammed Asadullah;
                  Warsi, Musarrat Husain; Imam, Syed Sarim; Jain, Gaurav Kumar;
                  Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  Recent Advances in the Development of Parenteral Dosage Forms
                </td>
                <td>
                  Pharmaceutical Drug Product Development and Process
                  Optimization
                </td>
                <td></td>
                <td></td>
                <td>97-124</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Dutt, Rohit; Malik, Kailash Chander; Karwa, Manoj; JAIN,
                  Gaurav Kumar;&nbsp;
                </td>
                <td>
                  Development and Validation of UPLC-MS/MS Method for Rapid
                  Simultaneous Determination of Levothyroxine and Liothyronine
                  in Human Serum
                </td>
                <td>Journal of Drug Delivery and Therapeutics</td>
                <td>10</td>
                <td>3-s</td>
                <td>176-181</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Iqbal, Ramsha; Ahmed, Shakeeb; Jain, Gaurav; Vohora,
                  Divya;&nbsp;
                </td>
                <td>
                  Brain targeting of letrozole nanoemulsion: Pharmacodynamics
                  evaluation of anticonvulsant action against kainic
                  acid-induced status epilepticus in mice
                </td>
                <td>British journal of pharmacology</td>
                <td>177</td>
                <td>11</td>
                <td>2629-2629</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Saad, Suma; Ahmad, Iqbal; Kawish, Syed M; Khan, Urooj A;
                  Ahmad, Farhan J; Ali, Asgar; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Improved cardioprotective effects of hesperidin solid lipid
                  nanoparticles prepared by supercritical antisolvent technology
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>187</td>
                <td></td>
                <td>110628</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Zafar, Sobiya; Jain, Gaurav Kumar; Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  Nanomedicine approaches for the delivery of herbal anticancer
                  drugs
                </td>
                <td>Nanomedicine for Bioactives</td>
                <td></td>
                <td></td>
                <td>201-229</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Khan, Urooj A; Parveen, Uzma; Hasan, Nazeer; Ahmed, Mohammad
                  Zubair; Saad, Suma; Ahmad, Farhan J; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Parenteral sustained release lipid phase-transition system of
                  ziprasidone: Fabrication and evaluation for schizophrenia
                  therapy
                </td>
                <td>Drug Design, Development and Therapy</td>
                <td>14</td>
                <td></td>
                <td>2237</td>
                <td>2020</td>
              </tr>
              <tr>
                <td>
                  Shekar, Nithish; Gowda, Dv; Kumar, Hitesh; Jain, Gaurav K;
                  Jain, Vikas;&nbsp;
                </td>
                <td>
                  Recent trends in management of keratoconjunctivitis sicca (dry
                  eye disease)
                </td>
                <td>International Journal of Applied Pharmaceutics</td>
                <td></td>
                <td></td>
                <td>30-36</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Yadav, Shiv Kumar; Jain, Gaurav K; Mazumder, Avijit; Khar,
                  Roop K;&nbsp;
                </td>
                <td>
                  Antimicrobial activity of a novel polyherbal combination for
                  the treatment of vaginal infection
                </td>
                <td>
                  Journal of Advanced Pharmaceutical Technology &amp; Research
                </td>
                <td>10</td>
                <td>4</td>
                <td>190</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Singh, SK; Jain, Gaurav; Siddiqui, Asfa; Naik, Smruti;
                  Rathore, BP; Garg, Vaibhav; Snehmani, Vinay Kumar; Bahuguna,
                  IM; Sharma, SA; Shekhar, Chander;&nbsp;
                </td>
                <td>
                  Characterization and retrieval of snow and urban land cover
                  parameters using hyperspectral imaging
                </td>
                <td>Current Science</td>
                <td>116</td>
                <td>7</td>
                <td>1182</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Tuteja, FC; Jain, Gaurav K; Singh, AP; Narnaware, SD; Sawal,
                  RK; Patil, NV;&nbsp;
                </td>
                <td>
                  A zoonotic case of camel dermatophytosis caused by
                  Trichophyton violaceum
                </td>
                <td>Journal of Camel Practice and Research</td>
                <td>26</td>
                <td>1</td>
                <td>87-90</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav Kumar; Singh, AP; Tuteja, FC; Sumnil, Marwaha;
                  Ankita, Sharma; Kachhawa, JP; Gupta, SR; Gaur, AK; Jitendra,
                  Tanwar;&nbsp;
                </td>
                <td>
                  Study of dermal mycosis in camels (Camelus dromedarius) in and
                  around Bikaner.
                </td>
                <td>Veterinary Practitioner</td>
                <td>20</td>
                <td>2</td>
                <td>195-198</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Sharma, Nitin; Sharma, Anjana; Bhatnagar, Aseem; Nishad,
                  Dhruv; Karwasra, Ritu; Khanna, Kushagra; Sharma, Deeksha;
                  Kumar, Neeraj; Jain, Gaurav Kumar;&nbsp;
                </td>
                <td>
                  Novel gum acacia based macroparticles for colon delivery of
                  Mesalazine: Development and gammascintigraphy study
                </td>
                <td>Journal of Drug Delivery Science and Technology</td>
                <td>54</td>
                <td></td>
                <td>101224</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Shakeel, Kashif; Raisuddin, Sheikh; Ali, Sadath; Imam, Syed
                  Sarim; Rahman, Md Akhlaquer; Jain, Gaurav Kumar; Ahmad, Farhan
                  Jalees;&nbsp;
                </td>
                <td>
                  Development and in vitro/in vivo evaluation of artemether and
                  lumefantrine co-loaded nanoliposomes for parenteral delivery
                </td>
                <td>Journal of Liposome Research</td>
                <td>29</td>
                <td>1</td>
                <td>35-43</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Iqbal, Ramsha; Ahmed, Shakeeb; Jain, Gaurav K; Vohora,
                  Divya;&nbsp;
                </td>
                <td>
                  Design and development of letrozole nanoemulsion: A
                  comparative evaluation of brain targeted nanoemulsion with
                  free letrozole against status epilepticus and
                  neurodegeneration in mice
                </td>
                <td>International journal of pharmaceutics</td>
                <td>565</td>
                <td></td>
                <td>20-32</td>
                <td>2019</td>
              </tr>
              <tr>
                <td>
                  Iqbal, Ramsha; Jain, Gaurav K; Siraj, Fouzia; Vohora,
                  Divya;&nbsp;
                </td>
                <td>
                  Aromatase inhibition by letrozole attenuates kainic
                  acid-induced seizures but not neurotoxicity in mice
                </td>
                <td>Epilepsy Research</td>
                <td>143</td>
                <td></td>
                <td>60-69</td>
                <td>2018</td>
              </tr>
              <tr>
                <td>
                  Muheem, Abdul; Shakeel, Faiyaz; Zafar, Sobiya; Jahangir,
                  Mohammed Asadullah; Warsi, Musarrat Husain; Jain, Gaurav
                  Kumar; Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  Development and validation of stability indicating liquid
                  chromatographic (RP-HPLC) method for estimation of
                  ubidecarenone in bulk drug and formulations using quality by
                  design (QBD) approach
                </td>
                <td>Brazilian Journal of Pharmaceutical Sciences</td>
                <td>53</td>
                <td></td>
                <td></td>
                <td>2018</td>
              </tr>
              <tr>
                <td>
                  Yadav, Shiv K; Jain, Gaurav; Mazumder, Avijit; Khar, Roop
                  K;&nbsp;
                </td>
                <td>
                  Development and validation of a HPTLC method for simultaneous
                  estimation of quercetin, Chlorogenic acid and trigonelline in
                  polyherbal antibacterial formulation
                </td>
                <td>Pharma Innov</td>
                <td>7</td>
                <td>12</td>
                <td>75-81</td>
                <td>2018</td>
              </tr>
              <tr>
                <td>
                  Yadav, Deepika; Yadav, Shiv K; Jain, Gaurav; Mazumder, Avijit;
                  Khar, Roop K;&nbsp;
                </td>
                <td>
                  Protective effect of eugenol against electrical induced
                  seizure model of epilepsy
                </td>
                <td>Pharm Innov J</td>
                <td></td>
                <td></td>
                <td></td>
                <td>2018</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav; Singh, CS; Singh, Ashok Kumar; Singh, SK; Puran,
                  AN;&nbsp;
                </td>
                <td>
                  Effect of nitrogen levels and weed management practices on
                  growth, yield and uptake of rice under aerobic conditions
                </td>
                <td>J. Pharmacogn. and Phytochem</td>
                <td></td>
                <td></td>
                <td>381-385</td>
                <td>2018</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Suri, Reshal; Jain, Gaurav K; Kohli,
                  Kanchan;&nbsp;
                </td>
                <td>
                  Proglycosomes: A novel nano-vesicle for ocular delivery of
                  tacrolimus
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>157</td>
                <td></td>
                <td>40-47</td>
                <td>2017</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Suri, Reshal; Jain, Gaurav K; Kohli,
                  Kanchan;&nbsp;
                </td>
                <td>
                  Proglycosomes: A novel nano-vesicle for ocular delivery of
                  tacrolimus B Biointerfaces
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>2017</td>
              </tr>
              <tr>
                <td>
                  Ashhar, Muhammad Usama; Ahmad, Mohd Zubair; Jain, Vikas;
                  Agarwal, Nidhi B; Ahmad, Farhan J; Jain, Gaurav K;&nbsp;
                </td>
                <td>
                  Intranasal pitavastatin attenuates seizures in different
                  experimental models of epilepsy in mice
                </td>
                <td>Epilepsy &amp; Behavior</td>
                <td>75</td>
                <td></td>
                <td>56-59</td>
                <td>2017</td>
              </tr>
              <tr>
                <td>
                  Muheem, Abdul; Shakeel, Faiyaz; Warsi, Musarrat Husain; Jain,
                  Gaurav Kumar; Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  A combinatorial statistical design approach to optimize the
                  nanostructured cubosomal carrier system for oral delivery of
                  ubidecarenone for management of doxorubicin-induced
                  cardiotoxicity: in vitro–in vivo investigations
                </td>
                <td>Journal of Pharmaceutical Sciences</td>
                <td>106</td>
                <td>10</td>
                <td>3050-3065</td>
                <td>2017</td>
              </tr>
              <tr>
                <td>Jain, Gaurav; Kumar, Nitesh;&nbsp;</td>
                <td>
                  A Multi Band EBG Resonator Antenna for Performance Enhancement
                  in UHF Band Frequency Range
                </td>
                <td>International Journal of Computer Applications</td>
                <td>156</td>
                <td>3</td>
                <td></td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Garg, V; Awasthi, RC; Jain, G; Pandey, S; Maheshwari,
                  MK;&nbsp;
                </td>
                <td>Giant vesical calculus</td>
                <td>International Journal of Medical and Dental Sciences</td>
                <td></td>
                <td></td>
                <td>1265-1268</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Gupta, Amit; Jain, Gaurav; Kaur, Manpreet; Jaryal, Ashok
                  Kumar; Deepak, Kishore Kumar; Bhowmik, Dipankar; Agarwal,
                  Sanjay Kumar;&nbsp;
                </td>
                <td>
                  Association of impaired baroreflex sensitivity and increased
                  arterial stiffness in peritoneal dialysis patients
                </td>
                <td>Clinical and experimental nephrology</td>
                <td>20</td>
                <td>2</td>
                <td>302-308</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Pathak, Mallika; Sharma, Malti; Ojha, Himanshu; Kumari, Rekha;
                  Sharma, Navneet; Roy, Bani; Jain, Gaurav;&nbsp;
                </td>
                <td>
                  Green synthesis, characterization and antibacterial activity
                  of silver nanoparticles
                </td>
                <td>Green Chem Technol Lett</td>
                <td>2</td>
                <td></td>
                <td>103-109</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Muheem, Abdul; Shakeel, Faiyaz; Jahangir, Mohammad Asadullah;
                  Anwar, Mohammed; Mallick, Neha; Jain, Gaurav Kumar; Warsi,
                  Musarrat Husain; Ahmad, Farhan Jalees;&nbsp;
                </td>
                <td>
                  A review on the strategies for oral delivery of proteins and
                  peptides and their clinical perspectives
                </td>
                <td>Saudi Pharmaceutical Journal</td>
                <td>24</td>
                <td>4</td>
                <td>413-428</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>Nirmal, Jayabalan; Jain, Gaurav K;&nbsp;</td>
                <td>Drug Delivery Systems for Ocular Use</td>
                <td>Pharmacology of Ocular Therapeutics</td>
                <td></td>
                <td></td>
                <td>479-515</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Mahtab, Asiya; Anwar, Mohammed; Mallick, Neha; Naz, Zrien;
                  Jain, Gaurav K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Transungual delivery of ketoconazole nanoemulgel for the
                  effective management of onychomycosis
                </td>
                <td>AAPS PharmSciTech</td>
                <td>17</td>
                <td>6</td>
                <td>1477-1490</td>
                <td>2016</td>
              </tr>
              <tr>
                <td>
                  Warsi, Musarrat H; Anwar, Mohammed; Garg, Vaidehi; Jain,
                  Gaurav K; Talegaonkar, Sushama; Ahmad, Farhan J; Khar, Roop
                  K;&nbsp;
                </td>
                <td>
                  Dorzolamide-loaded PLGA/vitamin E TPGS nanoparticles for
                  glaucoma therapy: Pharmacoscintigraphy study and evaluation of
                  extended ocular hypotensive effect in rabbits
                </td>
                <td>Colloids and surfaces B: Biointerfaces</td>
                <td>122</td>
                <td></td>
                <td>423-431</td>
                <td>2014</td>
              </tr>
              <tr>
                <td>Jain, Gaurav; Argal, Ameeta;&nbsp;</td>
                <td>
                  Pharmacognostic and phytochemical investigation of young
                  leaves of Triticum aestivum Linn.
                </td>
                <td>International Current Pharmaceutical Journal</td>
                <td>3</td>
                <td>6</td>
                <td>280-285</td>
                <td>2014</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Jain, Gaurav K; Nirmal, Jayabalan; Warsi,
                  Musarrat H; Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Development of poly lactide-co-glycolidenanodispersions for
                  enhanced ocular delivery of moxifloxacin
                </td>
                <td>Science of Advanced Materials</td>
                <td>6</td>
                <td>5</td>
                <td>990-999</td>
                <td>2014</td>
              </tr>
              <tr>
                <td>
                  Nirmal, Jayabalan; Jain, Gaurav K; Garg, Vaidehi; Warsi,
                  Musarrat H; Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Development and characterization of nanoplex topical ocular
                  delivery of moxifloxacin to treat bacterial keratitis
                </td>
                <td>Investigative Ophthalmology &amp; Visual Science</td>
                <td>55</td>
                <td>13</td>
                <td>1454-1454</td>
                <td>2014</td>
              </tr>
              <tr>
                <td>
                  Singh, Anjali; Ahmad, Iqbal; Akhter, Sohail; Jain, Gaurav K;
                  Iqbal, Zeenat; Talegaonkar, Sushama; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Nanocarrier based formulation of Thymoquinone improves oral
                  delivery: stability assessment, in vitro and in vivo studies
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>102</td>
                <td></td>
                <td>822-832</td>
                <td>2013</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Jain, Gaurav K; Nirmal, Jayabalan; Kohli,
                  Kanchan;&nbsp;
                </td>
                <td>
                  Topical tacrolimus nanoemulsion, a promising therapeutic
                  approach for uveitis
                </td>
                <td>Medical Hypotheses</td>
                <td>81</td>
                <td>5</td>
                <td>901-904</td>
                <td>2013</td>
              </tr>
              <tr>
                <td>
                  Anwar, M; Asfer, M; Akhter, S; Mohapatra, S; Warsi, MH; Jain,
                  N; Mallick, N; Jain, GK; Ali, A; Panigrahi, PK;&nbsp;
                </td>
                <td>
                  Aqueous phase transfer of oleic acid coated iron oxide
                  nanoparticles: influence of solvents and surfactants on
                  stability and pharmaceutical applications of ferrofluid
                </td>
                <td>Magnetohydrodynamics</td>
                <td>49</td>
                <td>03-Apr</td>
                <td>339-343</td>
                <td>2013</td>
              </tr>
              <tr>
                <td>
                  Gahoi, Sachin; Jain, Gaurav K; Tripathi, Renu; Pandey, Swaroop
                  K; Anwar, Mohammed; Warsi, Musarrat H; Singhal, Mayank; Khar,
                  Roop K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Enhanced antimalarial activity of lumefantrine nanopowder
                  prepared by wet-milling DYNO MILL technique B Biointerfaces
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Warsi, Musarrat H; Jain, Gaurav K; Pathan, Shadab A; Anwar,
                  Mohammed; Mallick, Neha; Ahmad, Niyaz; Talegaonkar, Sushama;
                  Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  UPLC/Q-TOF-MS/MS method for evaluation of moxifloxacin loaded
                  nanoplexes as vehicles for ocular drug delivery
                </td>
                <td>
                  Journal of liquid chromatography &amp; related technologies
                </td>
                <td>35</td>
                <td>13</td>
                <td>1826-1841</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Satheeshkumar, Nanjappan; Nisha, Narayanan; Sonali, Nirmal;
                  Nirmal, Jayabalan; Jain, Gaurav K; Spandana, Vudataneni;&nbsp;
                </td>
                <td>
                  Analytical profiling of bioactive constituents from herbal
                  products, using metabolomics-a review
                </td>
                <td>Natural Product Communications</td>
                <td>7</td>
                <td>8</td>
                <td>1934578X1200700837</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Chaurasiya, Akash; Singh, Ajeet K; Jain, Gaurav K; Warsi,
                  Musarrat H; Sublet, Emmanuelle; Ahmad, Farhan J; Borchard,
                  Gerrit; Khar, Roop K;&nbsp;
                </td>
                <td>
                  RETRACTED ARTICLE: Dual approach utilizing self
                  microemulsifying technique and novel P-gp inhibitor for
                  effective delivery of taxanes
                </td>
                <td>Journal of microencapsulation</td>
                <td>29</td>
                <td>6</td>
                <td>583-595</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Singh, Ajeet; Chaurasiya, Akash; Warsi, Musarrat H;
                  Chaurasiya, Manika; Jain, Gaurav K; Asati, Dinesh; Khar, Roop
                  K; Mukherjee, Rama;&nbsp;
                </td>
                <td>
                  Oral pharmacokinetic study of exemestane SMEDDS and suspension
                  in rat plasma by liquid chromatography-mass spectrometric
                  analysis
                </td>
                <td>
                  Journal of liquid chromatography &amp; related technologies
                </td>
                <td>35</td>
                <td>15</td>
                <td>2162-2174</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Gahoi, Sachin; Jain, Gaurav K; Tripathi, Renu; Pandey, Swaroop
                  K; Anwar, Mohammed; Warsi, Musarrat H; Singhal, Mayank; Khar,
                  Roop K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Enhanced antimalarial activity of lumefantrine nanopowder
                  prepared by wet-milling DYNO MILL technique
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>95</td>
                <td></td>
                <td>16-22</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Zaki Ahmad, Mohammad; Akhter, Sohail; Anwar, Mohammad; Singh,
                  Anjali; Ahmad, Iqbal; Ruhal Ain, Mohammad; Kumar Jain, Gaurav;
                  Krishen Khar, Roop; Jalees Ahmad, Farhan;&nbsp;
                </td>
                <td>
                  Feasibility of Assam Bora rice starch as a compression coat of
                  5-fluorouracil core tablet for colorectal cancer
                </td>
                <td>Current Drug Delivery</td>
                <td>9</td>
                <td>1</td>
                <td>105-110</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Jain, GK; Pathan, SA; Zaidi, SMA; Vohora, D; Kumar, N;
                  Bhatnagar, A; Ahmad, FJ; Khar, RK;&nbsp;
                </td>
                <td>
                  Mucoadhesive Nanoemulsion of Safranal for the Treatment of
                  Epilepsy
                </td>
                <td>Planta Medica</td>
                <td>78</td>
                <td>5</td>
                <td>P_112</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav K; Warsi, Musarrat H; Nirmal, Jayabalan; Garg,
                  Vaidehi; Pathan, Shadab A; Ahmad, Farhan J; Khar, Roop
                  K;&nbsp;
                </td>
                <td>
                  Therapeutic stratagems for vascular degenerative disorders of
                  the posterior eye
                </td>
                <td>Drug discovery today</td>
                <td>17</td>
                <td>13-14</td>
                <td>748-759</td>
                <td>2012</td>
              </tr>
              <tr>
                <td>
                  Pai, Raveendra; Kohli, Kanchan; Jain, Gaurav; Srivastava,
                  Birendra;&nbsp;
                </td>
                <td>
                  In vitro and in vivo evaluations of ketoprofen extended
                  release pellets prepared using powder layering technique in a
                  rotary centrifugal granulator
                </td>
                <td>Archives of pharmacal research</td>
                <td>34</td>
                <td>7</td>
                <td>1135-1142</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>Jain, Gaurav; Khar, RoopKrishen; Ahmad, Farhan J;&nbsp;</td>
                <td>Theory and Practice of Physical Pharmacy-E-Book</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav K; Pathan, Shadab A; Akhter, Sohail; Jayabalan,
                  Nirmal; Talegaonkar, Sushma; Khar, Roop K; Ahmad, Farhan
                  J;&nbsp;
                </td>
                <td>
                  Microscopic and spectroscopic evaluation of novel
                  PLGA–chitosan Nanoplexes as an ocular delivery system
                </td>
                <td>Colloids and Surfaces B: Biointerfaces</td>
                <td>82</td>
                <td>2</td>
                <td>397-403</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Akhter, Sohail; Zaki Ahmad, Mohammad; Singh, Anjali; Ahmad,
                  Iqbal; Rahman, Mahfoozur; Anwar, Mohammad; Kumar Jain, Gaurav;
                  Jalees Ahmad, Farhan; Krishen Khar, Roop;&nbsp;
                </td>
                <td>
                  Cancer targeted metallic nanoparticle: targeting overview,
                  recent advancement and toxicity concern
                </td>
                <td>Current pharmaceutical design</td>
                <td>17</td>
                <td>18</td>
                <td>1834-1850</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>Khar, Roop K; Ahmad, Farhan J; Jain, Gaurav K;&nbsp;</td>
                <td>
                  Pharmacy education: Gearing up, to meet industrial needs
                </td>
                <td>Pharmacology Times</td>
                <td>43</td>
                <td>3</td>
                <td></td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Anwar, Mohammed; Warsi, Musarrat H; Mallick, Neha; Akhter,
                  Sohail; Gahoi, Sachin; Jain, Gaurav K; Talegaonkar, Sushma;
                  Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Enhanced bioavailability of nano-sized chitosan–atorvastatin
                  conjugate after oral administration to rats
                </td>
                <td>European journal of pharmaceutical sciences</td>
                <td>44</td>
                <td>3</td>
                <td>241-249</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Pathan, Shadab A; Jain, Gaurav K; Zaidi, Syed MA; Akhter,
                  Sohail; Vohora, Divya; Chander, Prakash; Kole, Prashant L;
                  Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Stability‐indicating ultra‐performance liquid chromatography
                  method for the estimation of thymoquinone and its application
                  in biopharmaceutical studies
                </td>
                <td>Biomedical Chromatography</td>
                <td>25</td>
                <td>5</td>
                <td>613-620</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Jain, Nitin; Akhter, Sohail; Jain, Gaurav K; Khan, Zeenat I;
                  Khar, Roop K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Antiepileptic intranasal Amiloride loaded mucoadhesive
                  nanoemulsion: Development and safety assessment
                </td>
                <td>Journal of biomedical nanotechnology</td>
                <td>7</td>
                <td>1</td>
                <td>142-143</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Akhter, Sohail; Talegaonkar, Sushama; Khan, Zeenat I; Jain,
                  Gaurav K; Khar, Roop K; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Assessment of ocular pharmacokinetics and safety of
                  Ganciclovir loaded nanoformulations
                </td>
                <td>Journal of Biomedical Nanotechnology</td>
                <td>7</td>
                <td>1</td>
                <td>144-145</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Negi, Purnima; Ahmad, Farhan J; Ahmad, Dabeer; Jain, Gaurav K;
                  Singh, Gyanendra;&nbsp;
                </td>
                <td>
                  Development of a novel formulation for transdermal delivery of
                  an antidepressant drug
                </td>
                <td>
                  International Journal of Pharmaceutical Sciences and Research
                </td>
                <td>2</td>
                <td>7</td>
                <td>1766</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav K; Pathan, Shadab A; Akhter, Sohail; Ahmad,
                  Niyaz; Jain, Nilu; Talegaonkar, Sushma; Khar, Roop K; Ahmad,
                  Farhan J;&nbsp;
                </td>
                <td>
                  Mechanistic study of hydrolytic erosion and drug release
                  behaviour of PLGA nanoparticles: Influence of chitosan
                </td>
                <td>Polymer degradation and stability</td>
                <td>95</td>
                <td>12</td>
                <td>2360-2366</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Pathan, Shadab A; Jain, Gaurav K; Akhter, Sohail; Vohora,
                  Divya; Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>
                  Insights into the novel three ‘D’s of epilepsy treatment:
                  drugs, delivery systems and devices
                </td>
                <td>Drug discovery today</td>
                <td>15</td>
                <td>17-18</td>
                <td>717-732</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Pathan, Shadab Ahmad; Alam, Sanjar; Jain, Gaurav K; Zaidi,
                  Syed MA; Akhter, Sohail; Vohora, Divya; Khar, Roop K; Ahmad,
                  Farhan J;&nbsp;
                </td>
                <td>
                  Quantitative analysis of safranal in saffron extract and
                  nanoparticle formulation by a validated high‐performance
                  thin‐layer chromatographic method
                </td>
                <td>
                  Phytochemical Analysis: An International Journal of Plant
                  Chemical and Biochemical Techniques
                </td>
                <td>21</td>
                <td>3</td>
                <td>219-223</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Khar, RK; Jain, GK; Warsi, MH; Mallick, N; Akhter, S; Pathan,
                  SA; Ahmad, FJ;&nbsp;
                </td>
                <td>
                  Nano-vectors for the ocular delivery of nucleic acid-based
                  therapeutics
                </td>
                <td>Indian journal of pharmaceutical sciences</td>
                <td>72</td>
                <td>6</td>
                <td>675</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav K; Jain, Nilu; Pathan, Shadab A; Akhter, Sohail;
                  Talegaonkar, Sushma; Chander, Prakash; Khar, Roop K; Ahmad,
                  Farhan J;&nbsp;
                </td>
                <td>
                  Ultra high-pressure liquid chromatographic assay of
                  moxifloxacin in rabbit aqueous humor after topical
                  instillation of moxifloxacin nanoparticles
                </td>
                <td>Journal of pharmaceutical and biomedical analysis</td>
                <td>52</td>
                <td>1</td>
                <td>110-113</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Khar, RoopKrishen; Pathan, SA; Jain, Gaurav Kumar; Ahmad,
                  SAFJ;&nbsp;
                </td>
                <td>Microemulsion: Practical applications and concepts</td>
                <td>Pharmstudent</td>
                <td>25</td>
                <td></td>
                <td>26-32</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Ahmad, Mohammad Zaki; Akhter, Sohail; Jain, Gaurav Kumar;
                  Rahman, Mahfoozur; Pathan, Shadab Ahmad; Ahmad, Farhan Jalees;
                  Khar, RoopKrishen;&nbsp;
                </td>
                <td>
                  Metallic nanoparticles: technology overview &amp; drug
                  delivery applications in oncology
                </td>
                <td>Expert opinion on drug delivery</td>
                <td>7</td>
                <td>8</td>
                <td>927-942</td>
                <td>2010</td>
              </tr>
              <tr>
                <td>
                  Ali, Raisuddin; Jain, Gaurav K; Iqbal, Zeenat; Talegaonkar,
                  Sushma; Pandit, Pragati; Sule, Sunita; Malhotra, Geena; Khar,
                  Roop K; Bhatnagar, Aseem; Ahmad, Farhan J;&nbsp;
                </td>
                <td>
                  Development and clinical trial of nano-atropine sulfate dry
                  powder inhaler as a novel organophosphorous poisoning antidote
                </td>
                <td>Nanomedicine: Nanotechnology, Biology and Medicine</td>
                <td>5</td>
                <td>1</td>
                <td>55-63</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Zaidi, SMA; Pathan, SA; Jain, GK; Ahmad, FJ; Jamil, S; Singh,
                  S; Khar, RK;&nbsp;
                </td>
                <td>
                  Anticonvulsant and neuropharmacological studies of Anacyclus
                  pyrethrum root extract
                </td>
                <td>Neuroscience Research</td>
                <td></td>
                <td>65</td>
                <td>S250</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Pathan, SA; Zaidi, SMA; Jain, GK; Vohora, D; Ahmad, FJ; Khar,
                  RK;&nbsp;
                </td>
                <td>
                  Anticonvulsant evaluation of safranal in
                  pentylenetetrazole-induced status epilepticus in mice.
                </td>
                <td>International Journal of Essential Oil Therapeutics</td>
                <td>3</td>
                <td>02-Mar</td>
                <td>106-108</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Ahmad, S; Jain, GK; Faiyazuddin, Md; Iqbal, Z; Talegaonkar, S;
                  Sultana, Y; Ahmad, FJ;&nbsp;
                </td>
                <td>
                  Stability-indicating high-performance thin-layer
                  chromatographic method for analysis of terbinafine in
                  pharmaceutical formulations
                </td>
                <td>Acta Chromatographica</td>
                <td>21</td>
                <td>4</td>
                <td>631-639</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Ahmad, Farhan Jalees; Mittal, Gaurav; Jain, Gaurav K;
                  Malhotra, Geena; Khar, Roop K; Bhatnagar, Aseem;&nbsp;
                </td>
                <td>
                  Nano-salbutamol dry powder inhalation: a new approach for
                  treating broncho-constrictive conditions
                </td>
                <td>European Journal of Pharmaceutics and Biopharmaceutics</td>
                <td>71</td>
                <td>2</td>
                <td>282-291</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Pathan, Shadab A; Iqbal, Zeenat; Zaidi, Syed; Talegaonkar,
                  Sushma; Vohra, Divya; Jain, Gaurav K; Azeem, Adnan; Jain,
                  Nitin; Lalani, Jigar R; Khar, Roop K;&nbsp;
                </td>
                <td>CNS drug delivery systems: novel approaches</td>
                <td>Recent patents on drug delivery &amp; formulation</td>
                <td>3</td>
                <td>1</td>
                <td>71-89</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Singh, Ajeet Kumar; Chaurasiya, Akash; Jain, Gaurav K;
                  Awasthi, Anshumali; Asati, Dinesh; Mishra, Gautam; Khar, Roop
                  K; Mukherjee, Rama;&nbsp;
                </td>
                <td>
                  High performance liquid chromatography method for the
                  pharmacokinetic study of bicalutamide SMEDDS and suspension
                  formulations after oral administration to rats
                </td>
                <td>Talanta</td>
                <td>78</td>
                <td>######</td>
                <td>1310-1314</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Nilu, JAIN; JAIN, Gaurav Kumar; IQBAL, Zeenat; TALEGAONKAR,
                  Sushma; AHMAD, Farhan Jalees; KHAR, RoopKrishen;&nbsp;
                </td>
                <td>
                  An HPTLC Method for the Determination of Minocycline in Human
                  Plasma, Saliva, and Gingival Fluid after Single Step Liquid
                  Extraction
                </td>
                <td>Analytical Sciences</td>
                <td>25</td>
                <td>1</td>
                <td>57-62</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Jain, Nilu; Jain, Gaurav Kumar; Iqbal, Zeenat; Talegaonkar,
                  Sushma; Ahmad, Farhan Jalees; Khar, RoopKrishen;&nbsp;
                </td>
                <td>
                  An HPTLC method for the determination of minocycline in human
                  plasma, saliva, and gingival fluid after single step liquid
                  extraction
                </td>
                <td>Analytical sciences</td>
                <td>25</td>
                <td>1</td>
                <td>57-62</td>
                <td>2009</td>
              </tr>
              <tr>
                <td>
                  Jain, Nilu; Jain, Gaurav K; Javed, Shamama; Iqbal, Zeenat;
                  Talegaonkar, Sushama; Ahmad, Farhan J; Khar, Roop K;&nbsp;
                </td>
                <td>Recent approaches for the treatment of periodontitis</td>
                <td>Drug discovery today</td>
                <td>13</td>
                <td>21-22</td>
                <td>932-943</td>
                <td>2008</td>
              </tr>
              <tr>
                <td>
                  Akhter, Sohail; Jain, Gaurav K; Ahmad, Farhan J; Khar, Roop K;
                  Jain, Neelu; Khan, Zeenat I; Talegaonkar, Sushama;&nbsp;
                </td>
                <td>
                  Investigation of nanoemulsion system for transdermal delivery
                  of domperidone: ex-vivo and in vivo studies
                </td>
                <td>Current Nanoscience</td>
                <td>4</td>
                <td>4</td>
                <td>381-390</td>
                <td>2008</td>
              </tr>
              <tr>
                <td>
                  Ahmad, Farhan J; Iqbal, Zeenat; Jain, Nilu; Jain, Gaurav K;
                  Talegaonkar, Sushama; Ahuja, Alka; Khar, Roop K;&nbsp;
                </td>
                <td>Dental therapeutic systems</td>
                <td>Recent Patents on Drug Delivery &amp; Formulation</td>
                <td>2</td>
                <td>1</td>
                <td>58-67</td>
                <td>2008</td>
              </tr>
              <tr>
                <td>Jain, Gaurav K; Ahmed, Farhan J;&nbsp;</td>
                <td>
                  Adapalene pretreatment increases follicular penetration of
                  clindamycin: In vitro and in vivo studies
                </td>
                <td>Indian journal of dermatology venereology and leprology</td>
                <td>73</td>
                <td>5</td>
                <td>326</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav K; Jain, N; Iqbal, Z; Talegaonkar, S; Ahmad, FJ;
                  Khar, RK;&nbsp;
                </td>
                <td>
                  Development and validation of an HPTLC method for
                  determination of minocycline in human plasma
                </td>
                <td>Acta Chromatographica</td>
                <td>19</td>
                <td></td>
                <td>197</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav; Argal, A; Pathak, AK; Singh, VK; Kannojia,
                  P;&nbsp;
                </td>
                <td>Hepatoprotective activity of wheatgrass juice</td>
                <td>The pharmacist</td>
                <td>2</td>
                <td>1</td>
                <td>29-30</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Singh, Vimal Kumar; Argal, Ameeta; Aeri, Vidhu; Jain, Gaurav;
                  Kannojia, Pushpendra;&nbsp;
                </td>
                <td>Anti-inflammatory activity of Gloriosa superba Linn</td>
                <td>Pharmacist</td>
                <td>2</td>
                <td>1</td>
                <td>19-20</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Jain, Nilu; Jain, Gaurav Kumar; Ahmad, Farhan Jalees; Khar,
                  RoopKrishen;&nbsp;
                </td>
                <td>
                  Validated stability-indicating densitometric thin-layer
                  chromatography: Application to stress degradation studies of
                  minocycline
                </td>
                <td>Analytica chimica acta</td>
                <td>599</td>
                <td>2</td>
                <td>302-309</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Jain, GK; Khar, RK; Ahmad, FJ; Talegaonkar, S; Jain, N;
                  Bansal, T;&nbsp;
                </td>
                <td>
                  Effect of adapalene pretreatment on in vitro skin penetration
                  and distribution of topically applied clindamycin phosphate
                </td>
                <td>JournalofPharmacy and Pharmacology</td>
                <td>59</td>
                <td></td>
                <td>A14-A14</td>
                <td>2007</td>
              </tr>
              <tr>
                <td>
                  Jain, Gaurav Kumar; Singh, AP; Tanwer, Jitendra; Marwaha,
                  Sumnil; Chahar, Anju;&nbsp;
                </td>
                <td>
                  Epidemiological studies on sarcoptic mange in camels (Camelus
                  dromedarius) in Bikaner district (West Rajasthan)
                </td>
                <td>Journal of Parasitic Diseases</td>
                <td>29</td>
                <td>1</td>
                <td>67-70</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>
                  Garg, Vaidehi; Jain, Gaurav Kumar; Ahmad, Farhan Jalees; Khar,
                  RoopKrishen;&nbsp;
                </td>
                <td>
                  Quantitative determination of moxifloxacin in rabbit ocular
                  fluids by high-performance thin-layer chromatography
                </td>
                <td>Asian Journal of Pharmacy and Life Science ISSN</td>
                <td>2231</td>
                <td></td>
                <td>4423</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>S. No</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Name of Award</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Awarding Agency</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Year</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>AAiPS Young Researcher Award 2022</p>
                </td>
                <td>
                  <p>AAiPS, IPA</p>
                </td>
                <td>
                  <p>2022</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>
                    IPA-ACG Scitech Innovation Award for Best Solid
                    Product&nbsp;
                  </p>
                </td>
                <td>
                  <p>
                    Indian Pharmaceutical Association &amp; ACG Scitech
                    Worldwide
                  </p>
                </td>
                <td>
                  <p>2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>Nominated as Vice President, IPA-DB</p>
                </td>
                <td>
                  <p>IPA-Delhi Branch</p>
                </td>
                <td>
                  <p>2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>Best Contributor Award</p>
                </td>
                <td>
                  <p>Indian Pharmaceutical Association</p>
                </td>
                <td>
                  <p>2019</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>5</p>
                </td>
                <td>
                  <p>Elected as Secretary, IPA-DB</p>
                </td>
                <td>
                  <p>IPA-Delhi Branch</p>
                </td>
                <td>
                  <p>2014-2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>6</p>
                </td>
                <td>
                  <p>Nominated as Joint Secretary</p>
                </td>
                <td>
                  <p>SPDS, Mumbai</p>
                </td>
                <td>
                  <p>2014-2016</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>7</p>
                </td>
                <td>
                  <p>Pharma Expert at INMAS</p>
                </td>
                <td>
                  <p>DRDO</p>
                </td>
                <td>
                  <p>2016</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>8</p>
                </td>
                <td>
                  <p>Nominated as General Secretary</p>
                </td>
                <td>
                  <p>Good Society For Ethical Research</p>
                </td>
                <td>
                  <p>2012 -2020</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>9</p>
                </td>
                <td>
                  <p>Chairman, Scientific Committee</p>
                </td>
                <td>
                  <p>Indian Pharmaceutical Congress</p>
                </td>
                <td>
                  <p>2010</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>10</p>
                </td>
                <td>
                  <p>International Travel Grant, Malaysia</p>
                </td>
                <td>
                  <p>UGC</p>
                </td>
                <td>
                  <p>2010</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>11</p>
                </td>
                <td>
                  <p>Gold Medal, Securing 1st Rank in PG&nbsp;</p>
                </td>
                <td>
                  <p>Jamia Hamdard, New Delhi</p>
                </td>
                <td>
                  <p>2008</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>12</p>
                </td>
                <td>
                  <p>International Travel Grant, Vienna</p>
                </td>
                <td>
                  <p>Controlled Release Society</p>
                </td>
                <td>
                  <p>2006</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default DrGauravKumarJain;
