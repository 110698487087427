import React from 'react'
import './NationalScinceDay.css'
import nsd from './nsd.jpg'

const NationalScinceDay = () => {
  return (
    <div className='dpsru-every-component-css'>
    <h2 className='dpsru-primary-heading-for-every-page'>
    DPSRU celebrated National Science Day 2023 on 28th Feb 2023 in Dr G.K.Narayanan Auditorium, DPSRU
    </h2>

    <div className="national-science-day-image-container">
        <img src={nsd} alt="National Science Day" />
    </div>

    <div className="national-science-day-text-container container-of-workshop"><b> DPSRU celebrated National Science Day</b> also commemorating Azadi Ka Amrit Mahotsav and G20 events,  on 28th  February 2023. This day is celebrated each year to mark ‘the discovery of the Raman effect by Indian physicist Sir C. V. Raman on 28 February 1928’.The theme for NSD of the year 2023 was  “Global Science for Global wellbeing”. <br /><br />

The program was graced by the presence of <b>Chief Guest Padma Bhushan Prof. Nirmal Kumar Ganguly, former Director General, ICMR and Guest of Honour Shri Manoj Kumar Garg, IAS (Rtd.) currently working as advisor in GGSIPU.</b> The program began with a warm welcome extended by Prof P.K.Sahoo, Dean Research and Innovation. Dr Sushama Talegaonkar, RPAward event Coordinator, gave an Introduction to the Research Promotion scheme. This was followed by an intriguing address by Honourable Vice Chancellor Prof Ramesh K Goyal where he shared his new ideas on Pharma and related, Sports Sciences, Physiotherapy and Allied Health and stressed on adapting an integrated approach to address and encompass the issues of the health industry. Research Promotion booklet and Newsletter of DPSRU was then released by the dignitaries on and off the dais. Shri Manoj Kumarji addressed the audience with his motivational words which directed the students to think out of the box. Prof. Ganguly abreasted the upcoming and budding scientists, the Post Graduate and PhD students of DIPSAR and DPSRU, on modern scientific methods in Pharmaceutical Sciences and the various prospects of Microbiology and Pharmaceutics which still lay unexplored not forgetting the strengths of Indian Culture and Language. A strength of almost 200 audience including faculty and students gave a patient listening to his profound words. <br /><br />

This was followed by the Research Promotion Award Ceremony, conducted by Prof. Richa H Rai and Dr Sushama Talegaonkar, where around 47 students and research scholars were felicitated and 37 faculty were awarded for their patents granted and publications in high Impact Factor Journals.  This was followed by special awards from the Vice Chancellors desk to faculty members and recognition granted to administrative staff for their continued efforts. Prof. Geeta Aggarwal delivered the Vote of thanks. The program concluded with the National Anthem. The program was well executed by the spectacular arrangements done by faculty, staff and Students from DPSRU family and Organising Committee, Prof P K Sahoo, Prof Geeta Agarwal, Prof Richa H Rai, Prof Rajiv K Tonk, Dr Sushama Talegaonkar, Prof. Sharad Wakode, Dr Vijender, Prof Ruchika Malhotra (DTU), Dr Suraj Pal Verma, Dr Anoop Kumar, Dr Varsha Chorsiya, Dr Madhu Gupta, Dr Prabodh C Sharma, Dr Sheetal Kalra,  Dr. Ajit  K Thakur,  Dr Sheetal Yadav, Dr Abhishek Dadhich , Dr. Amrita Mishra,  Dr. Ruhi Ali, Dr Sumit Sharma, Dr Priti Jain, Dr. Anshul, Dr Sakshi Bajaj, Dr Foziyah Zakir, Dr. Sachin, Dr J. Swaminathan,  Dr Sahil Kumar, Dr Atul Jain, Dr. Roshni Tandey, Dr Akash Midha, Mr Ranjeet Kumar, Mr Dhruv Arora, Dr Anubha Agrawal, Mr Harish Meghwani, Mr Pankaj, Mr Rawal, Ms Lata, Mr S.S. Mathur, Ms Shivani Dutta, Ms Jyoti, Mr Krishan Kumar, Mr Deepanshu, Mr Ashish and Mr Kuldeep, Mr Vikram, Mr Vikas (irrespective of the order in which mentioned) worked hard to make this program a huge success, under the guidance of Registrar Dr O.P.Shukla and Honourable VC. Everyone then enjoyed the Refreshments before they dispersed for the day which was no less than any other festival one celebrates in India.</div>
    </div>
  )
}

export default NationalScinceDay
