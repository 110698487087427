import React from 'react'
import './Confrence.css'
import InternationalConf from './ConferenceData/International Conference-2020.pdf'
import conf2022 from './ConferenceData/Conference-2022-1.pdf'
import conf2023 from './ConferenceData/conf2023.pdf'

const Conference = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Conference</h2>

    <div className="data-container-of-conference">
        <a href={InternationalConf} className='nirf-botton'>ICCS-2020</a>
        <a href={conf2022} className='nirf-botton'>2020</a>
        <a href={conf2023} className='nirf-botton'>2023</a>
    </div>
    </div>
  )
}

export default Conference
