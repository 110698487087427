import React from 'react'
import './Conferences.css'
import conf01 from './International Conference-2020.pdf'
import conf02 from './ICCS-2020 Abstract submission, Oral_Poster Presentation Guidelines.docx'
import conf03 from './Tentative Progam.doc'

function Conferences() {
    return (
        <div className='dpsru-every-component-css'>
            <div className="confercences-container">

                <h2 className='dpsru-primary-heading-for-every-page'>Conferences</h2> <hr />
                <h2 className='dpsru-secondary-heading-for-every-page'>Other Important Information</h2>
                <p>
                    International Conference of Cardiovascular Sciences (ICCS) -2020 held on Feb 2020</p>
                <ul>
                    <li>
                        <a href={conf01} target="_blank" rel="noopener noreferrer">Link 1</a>
                    </li>
                    <li>
                        <a href={conf02} target="_blank" rel="noopener noreferrer">Link 2</a>
                    </li>
                    <li>
                        <a href={conf03} target="_blank" rel="noopener noreferrer">Link 3</a>
                    </li>
                </ul>
            </div>

        </div>


    )
}

export default Conferences