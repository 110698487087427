import React, { useState } from "react";

import profimg from "./IMG_0031-scaled-e1686638011713.jpg";
import CSIR_Letter from "./CSIR-Letter-Dr.-Pooja-raj_page-0001.jpg";

const DrPoojaMongiaRaj = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Pooja Mongia Raj</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            
  
              pooja [dot] raj [at] dpsru [dot] edu [dot] in
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.com/citations?user=LeXDX5YAAAAJ&hl=en-"> Google Scholar</a></li>
            <li> <a href="https://www.scopus.com/authid/detail.uri?authorId=57188837729">Scopus</a></li>
            <li>
              <a href="https://orcid.org/0000-0002-0069-7421">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      PhD
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      Nanotechnology, Oral Drug Delivery
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
    <ul> <li> Sahu Suresh Kumar, Raj Rakesh, Raj Pooja Mongia, Ram Alpana. Topical Lipid Based Drug Delivery Systems for Skin Diseases: A Review. Current drug Therapy, 2020 15(4) 283-298.</li> <li> Raj Rakesh, Raj Pooja Mongia, Ram Alpana. Nanosized ethanol based malleable liposomes of cytarabine to accentuate transdermal delivery: formulation optimization, in vitro skin permeationand in vivo bioavailability. Artificial Cells, Nanomedicine, and Biotechnology, 2018 46 S2 951-963.</li> Raj Pooja Mongia, Raj Rakesh, Ram Alpana. Biodistribution and targeting potential assessment of mucoadhesive chitosan nanoparticles via scintigraphy designed for ulcerative colitis. RSC Advances 2018 8 20809.<li> Raj Rakesh, Raj Pooja Mongia, Ram Alpana. Preparation and characterization of solid lipid nanoparticles loaded with cytarabine via a micellar composition for leukemia. RSCAdvances 2016 6 53578-86.</li> <li> Raj Rakesh, Raj Pooja Mongia, Ram Alpana. Lipid based noninvasive vesicular formulation of cytarabine: Nanodeformable liposomes. European Journal of Pharmaceutical Sciences, 2016 88 83-90.</li> <li> Ram Alpana, Raj Pooja Mongia, Raj Rakesh, Kumar Nitish. Comparative study of Eudragit RS100 and RL100 nanoparticles as ophthalmic vehicle for fungal infection.Pharmaceutical Nanotechnology 2016 4 1-13. </li> <li> Raj Rakesh, Mongia Pooja, Ram Alpana. Nanocarriers Based Anticancer Drugs: Current Scenario and Future Perceptions. Current Drug Targets, 2016 17 206-228.</li> <li> Raj Rakesh, Mongia Pooja, Ram Alpana, NK Jain. Enhanced skin delivery of aceclofenac via hydrogel based solid lipid nanoparticles. Artificial Cells, Nanomedicine, and Biotechnology, 2016 44(6) 1434-9.</li> <li> Mongia Pooja, Khatik Renuka, Raj Rakesh, Jain Nidhi, Pathak A K. pH-Sensitive Eudragit S-100 Coated Chitosan Nanoparticles of 5-Amino Salicylic Acid for Colon Delivery. Journal of Biomaterial &amp; Tissue Engineering, 2014 4 738-743. </li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          
          <img src={CSIR_Letter} alt="Awards" />
        </div>
      ) : null}
    </div>
  );
};

export default DrPoojaMongiaRaj;
