import React from "react";
import './G20PanelDiscussionReport.css'
import pd1 from './Panel Discussion Datafiles/pd1.jpeg'
import pd2 from './Panel Discussion Datafiles/pd2.jpg'
import pd3 from './Panel Discussion Datafiles/pd3.jpg'
import pd4 from './Panel Discussion Datafiles/pd4.jpg'
import pd5 from './Panel Discussion Datafiles/pd5.jpg'
import pd6 from './Panel Discussion Datafiles/pd6.jpg'

const G20PanelDiscussionReport = () => {
  return (
    <div className="g20-panel-discussion-component">
      <h1 className="pushp-pradarshanam-dpsru-heading">
      G20 Report on Panel Discussion
      </h1>
      <h2 className="g20-maha-food-festival-heading">
      Panel Discussion @DPSRU on “TO INVEST IN PEOPLE, PRIORITIZE EDUCATION”
      </h2>

      <div className="panel-discussion-first-img-container">
<img src={pd1} alt="panel Discussion" />
      </div>

      <p className="g20-maha-food-festival-paragrauph">

      A panel discussion was organized by Delhi Pharmaceutical Sciences and Research University on 24th January, 2023 on the theme- To Invest in People, Prioritize Education at DPSRU auditorium. The  program was attended by approximately 150 students, among 50 faculty members and alumni of the  university. The program was conducted under the guidance and vision of the patron Prof. Ramesh K.  Goyal Hon’ble Vice Chancellor DPSRU and co-patron Dr. O.P. Shukla, Registrar DPSRU and  coordinated by Dr. Saurabh Dahiya, G20 Nodal Officer, DPSRU. Five panel discussions were held on the topics as suggested by DTTE, wherein the moderators Prof. Geeta Aggarwal, Prof. H. Popli, Prof.  Deepti Pandita, Prof. P.K. Sahoo, and Dr. Saurabh Dahiya started the discussions by elaborating on the  topics for the convenience of the students, followed by active participation by panellists and students.  Each panel discussion lasted for about thirty minutes. The key outcomes of each session are as follows
      </p>

      <ol className="panel-discussion-ol">
        <li>
        Inter University Collaborations
        </li>
        <p className="g20-maha-food-festival-paragrauph">
        The panel discussion summarized that the collaborations should be  student centric so that students become skilful and better trained. At the end of the discussion, the panel concluded that one of the main goals of inter-university collaborations is to support universities efforts to globalise their curricula and make their faculties, students, and academic staff more competitive in  international markets. Interuniversity collaborations can be done in the form of research and knowledge  sharing, organizing joint certificate and internship programs, short term programs etc. These  collaborations offer free flow and exchange of ideas and thoughts where institutions work together to  create something innovative or engage in a coordinated manner to solve a complex problem and to  develop higher level thinking. These collaborations help to ensure that scientific and technological  developments are available to a wide range of users.  
        </p>

        <li>
        Industry oriented future courses
        </li>
        <p className="g20-maha-food-festival-paragrauph">
        The need to have courses which cater to the needs of the industry  emerged in the discussion, also to upgrade the courses from time to time such that the students taking  these courses are industry ready. Industry-oriented courses are courses for knowledge, technology and skills  being used in the industry and will give a professional edge over the competition. These courses provide knowledge and skills relevant to the industry. Traditional Pharmacy is moving towards new age Pharmacy wherein technical knowledge is not only sufficient but also the new courses need to be a part of current B. Pharm  / M. Pharm curriculum like Drug Regulatory Software, QbD learning and their practical implementation,  Artificial intelligence, Communication skills, Biotechnology, Data Science, Health Information Technology,  Health Administration, Medical Science & Tech., Nursing. Students are aware of theoretical aspects however  their practical implementation needs to be improvised so that they are aware of everything before they enter the  Industry. Training in Institute should be imparted to the students instead of a separate internship as a part of  curriculum 
            </p>

            <li>
            Promoting entrepreneurship in HEIs
        </li>

        <p className="g20-maha-food-festival-paragrauph">
        There is need of Entrepreneurship Skills in HEIs so that the  candidates are not the just job seekers but the Job providers. Panelist briefed on success stories of  Unicorns which is inspiring and will be helpful to build the culture of Startups iof they are taught the  skills. It was very evident from the fresh undergraduates that they were unaware of the Government  schemes and Startup Momentum in the state. From the discussion it emerged that there is need of more  Mentors so faculty should also be trained to teach Entrepreneurship. Panelist also discussed that how  Entrepreneurship can also help the Research based HEIs
            </p>

            <li>
            Aligning skills and education
        </li>

        <p className="g20-maha-food-festival-paragrauph"> The technical education goes hand in hand with theoretical  knowledge and hands on skill of the techniques learnt in theory. Stand alone theoretical knowledge may  not be fully useful. The skill adds to the competency, employability and brand value of a person. Even  people are recognised in society based on their skill in their field. Overall a student stands out in a  group based on the distinctive skill acquired.</p>

        <li>
        Discussion on draft credit framework
        </li>

        <p className="g20-maha-food-festival-paragrauph">The discussion provided insights on the National Credit  Framework which provides for creditisation of all learning and assignment, accumulation, storage,  transfer and redemption of credits, subject to assessment. The NCrF removes distinction and establishes  academic equivalence between vocational and general education, it enables mobility within & between  them, and its operationalising through the Academic Bank of Credits (ABC). The students took keen  interest in understanding about the Academic Bank of Credits and various other flexibilities offered in  the draft policy such as entry exit options. </p>
      </ol>

      <div className="galery-conteiner-of-g20-maha-food-festival">
        <h3 className="galery-heading-of-g-20-maha-food-f">
        Gallery
        </h3>

        <div className="image-container-of-g-20-gallery pushp-pradarshan-galery">
          <img src={pd1} alt="galery" />
          <img src={pd2} alt="galery" />
          <img src={pd3} alt="galery" />
          <img src={pd4} alt="galery" />
          <img src={pd5} alt="galery" />
          <img src={pd6} alt="galery" />
        </div>
      </div>
    </div>
  );
};

export default G20PanelDiscussionReport;
