import React from 'react'
import './CentersOfExcellence.css'

function CentersOfExcellence() {
    return (
        <div className='dpsru-every-component-css'>
            <h2 className="dpsru-primary-heading-for-every-page">CENTERS OF EXCELLENCE</h2>

            <div className="ce-container-box">
                <ul>
                    <li>
                        Ocular Pharmacology
                    </li>
                    <li className='alternative-li-in-cc'>
                        Clinical Research (BA/BE Center)
                    </li>
                    <li>
                        Advanced Herbal Drug Formulation and Technology
                    </li>
                    <li className='alternative-li-in-cc'>
                        Bio NEST Center
                    </li>
                    <li>
                        DIIF
                    </li>
                    <li className='alternative-li-in-cc'>
                        Nanotechnology
                    </li>
                    <li>
                        Drug Discovery Center
                    </li>
                    <li className='alternative-li-in-cc'>
                        Precision Medicine Center
                    </li>
                    <li>
                        Center for Meditation and Yoga Sciences
                    </li>
                    <li className='alternative-li-in-cc'>
                        Pharmacovigilance and Outcome Research
                    </li>
                    <li>
                        DPSRU-CRTDH-CAFT Center
                    </li>
                    <li className='alternative-li-in-cc'>
                        DST-FIST
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default CentersOfExcellence