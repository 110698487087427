import React, { useState } from "react";

// ICONS
import { IconContext } from "react-icons";

// ROUTING

import { Link } from "react-router-dom";

// DATA FILE
import { SidebarData } from "./SidebarData";

// STYLES
import "./SideNav.css";
import './ResponsiveSideNav.css'

export default function SideNav() {
  const [sidebar, setSidebar] = useState(true);
  const [status, setStatus] = useState(false)

  const showSidebar = () => setSidebar(sidebar);

  return (
    < div className="side-nav-full-comp" onMouseLeave={() => setStatus(!status)}>
      <IconContext.Provider value={{ color: "#FFF" }}>
       
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
             
            </li>

            <div className="xxxxx" style={{ display: 'flex' }}>
              <div className="map-data">
                {

                  status ? SidebarData.map((item, index) => {
                    return (
                      <div className="sidebar-animation-effect-example-new">
                      <li key={index} className={item.cName}>
                        <Link to={item.path}>
                          {item.icon}
                          <span>{item.title}</span>
                        </Link>
                      </li>
                      </div>
                    );
                  }) : null}

              </div>
              <div className="toggle-btn-box" >
                {status ?
                  <button className="sidenav-toggle-btn" style={{ fontWeight: 'bold', fontFamily: 'cursive', display:'none'}}>Quick links </button>
                  :
                  // <button className="sidenav-toggle-btn" onMouseOver={()=>setStatus(!status)} style={{border:'none'}}><BsFillCaretRightFill className='sidenav-toggle-button-class-for-responsive-data' /></button>}
                  <button className="sidenav-toggle-btn" onMouseOver={() => setStatus(!status)} style={{ border: 'none', fontWeight: 'bold' }} >Quick links</button>}
              </div>
            </div>



          </ul>
        </nav>
      </IconContext.Provider>
    </div>
  );
}


// pahle sidenav onclick pr toggle hota tha jiska code {onClick={()=>setStatus(!status)}} ye hai lekin ab maine pure component me onMouseleave pr status ko false karwa diya hai 