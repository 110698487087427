import React from 'react'
import './Organogram.css'

import om1 from './Organogramfiles/om1.jpg'
import om2 from './Organogramfiles/om2.jpg'


function Organogram() {
  return (
    <div className='dpsru-every-component-css'>
        

        <h2 className="dpsru-primary-heading-for-every-page">
        Organogram
        </h2>

        <div className="organoram-container-image">
            <a href={om1}><img src={om1} alt='Organogram management' /></a>
            
            <a href={om2}><img src={om2} alt='Organogram management' /></a>
            
        </div>
    </div>
  )
}

export default Organogram