import React from "react";
import "./Academics.css";

import academicsCalender from "./academics-calendar.pdf";
import { Link } from "react-router-dom";

function Academics() {
  return (
    <div className="dpsru-every-component-css">
      <div className="dpsru-primary-heading-for-every-page">Academics</div>
      <div className="academics-component">
        <div className="academics-container-1">
          {/* <p>
            <a href={academicsCalender}>Academic Calendar 2023-24</a>
          </p> */}
          {/* <p>
            <Link to="/scholorShip">Scholarships</Link>
          </p> */}
          <p> Post Doctoral Programs</p>
          <ul>
            <li>DSc (Post Doctoral)</li>
          </ul>
        </div>

        <hr className="displayNoneForBigScreen" />

        <div className="academics-container-2">
          <ul className="menu nav">
            <li className="first leaf">
              <Link to="/academicUnits">
                Academic Units and Centres of Excellences
              </Link>
            </li>
            <li className="leaf">
              <Link to="/doctoralProgramOfAcademics">Doctoral Programmes</Link>
            </li>
            <li className="leaf">
              <Link title="" to="/industrialTraining">
                Industrial Training
              </Link>
            </li>
            <li className="leaf">
              <Link
                title=""
                to="/AllTimeTable"
              >
                All Time Tables (Current Previous)
              </Link>
            </li>
            <li className="leaf">
              <Link title="" to="/library">
                Library
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Academics;
