import React from 'react'

const PlacementRecord20222023 = () => {
  return (
    <div className="dpsru-every-component-css">
    <h2 className="dpsru-primary-heading-for-every-page">Placement Record 2022-2023</h2>

    <div className="results-data-link-container">
      <ul>
        <li>
          <a >
          M. Pharm Clinical Research
          </a>
        </li>
        <li>
          <a >
          M.Pharm DRA
          </a>
        </li>
        <li>
          <a >
          M. Pharm Industrial Pharmacy
          </a>
        </li>
        <li>
          <a>
          M.PHARM Pharmaceutical Analysis
          </a>
        </li>
        <li>
          <a >
          M. Pharm Pharmaceutics
          </a>
        </li>
        <li>
          <a>
          M. Pharm Pharmacology
          </a>
        </li>
        <li>
          <a >
          M. Sc AIPM
          </a>
        </li>
        <li>
          <a >
          MBA PET
          </a>
        </li>
        <li>
          <a >
          MBA PM
          </a>
        </li>
        <li>
          <a >
          MPH
          </a>
        </li>
        <li>
          <a >
          M.PHRAM QA
          </a>
        </li>
        <li>
          <a >
          M. Pharm Industrial Pharmacy
          </a>
        </li>
        <li>
          <a>
          M.PHARM. Biotechnology
          </a>
        </li>
        <li>
          <a >
          MPH
          </a>
        </li>
     
      </ul>
    </div></div>
  )
}

export default PlacementRecord20222023