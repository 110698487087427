import React from "react";
import "./DoctoralProgramOfAcademics.css";

const DoctoralProgramOfAcademics = () => {
  return (
    <div className="academic-unit-component DoctoralProgramOfAcademics-component">
      <h2 className="academic-units-heading scholorship-main-heading academic-unit-main-heading DoctoralProgramOfAcademics-heading">
        Doctoral Programmes
      </h2>

      <div className="doctroalProgrammes-data-container">
        <p>
          In the present world there are number of pharmaceuticals companies,
          hospitals and nursing homes all over the globe exhibits rapid growth
          of the pharmacy sector. Pharmacy offers sensible job opportunities for
          their graduates in terms of business or by opening their own business.
          Candidates can open their own druggists shop or a pharmacy medical
          shop after completion of their degree or diploma program in pharmacy
          and after registering with a pharmacy council.
        </p>{" "}
        <br />
        <p>
          The majority of the job opportunities for a graduate in this field are
          available in a research institute, government deptartment,
          universities, pharmaceutical industry and teaching hospitals. Doctor
          of philosophy and Master of pharmacy graduates are usually engaged in
          the research field for developing new drugs and analyzing them for
          strength and purity.
        </p>{" "}
        <br />
        <p>
          A number of MNC’s work together with top Indian companies to develop
          numerous new drugs for the welfare of people.
        </p>
        <p>Some Job Types: –</p>
        <ul>
          <li>Lecturer</li>
          <li>Data Manager</li>
          <li>Customs Officer</li>
          <li>Drug Inspector</li>
          <li>Analytical Chemist</li>
          <li>Regulatory Manager</li>
          <li>Researcher</li>
          <li>Medical Transcriptionist</li>
        </ul>
      </div>
    </div>
  );
};

export default DoctoralProgramOfAcademics;
