import React, { useState, useRef } from "react";
import "./Home.css";
import './ReDesignHome.css'
import "./ResponsiveHome.css";
// import HomePageNotice from "../Home page Notice/HomePageNotice";
import DpsruHomeTabsUpdated from "../A tabs creation/DpsruHomeTabsUpdated";
import newGif from ".././NEW/new.gif";

import { Link, NavLink } from "react-router-dom";

import vc from "../Logo/VC.jpg";
import chanslor from "../Logo/Vinai-Kumar-Saxena.webp";
import registrar from "../Logo/RegistrarImage.jpg";

import newvcsirMaheshverma from "./HomePageData/vc-sir-rounded-modified.png";
import newregistrarShahooSIr from "./HomePageData/profPKSahooDirector.jpeg";

import { SidebarData } from "../Nav/SideNav/SidebarData";
import TakeATour from "../TakeATour/TakeATour";

// files
import { FaListAlt, FaRegWindowClose } from "react-icons/fa";
import DpsruHomeTabs from "../A tabs creation/DpsruHomeTabs";
import HomePageFlashNotification from "./HomePageFlashNotification";

import dpsrufrontImg from '../Logo/dpsru-front.jpg'

import seprattor from '../Logo/seprattor1.png'
import Results from '../Examination/Results/Results'
import DateSheet from "../Examination/DateSheet/DateSheet";


function ReDesigningHome() {
 

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  return (
    <>
      {/* <HomePageNotice/> */}
      {/* this is full container of marquee notice of home page */}

      {/* <TakeATour /> */}

   

      <div className="home-full-component">
      {/* <div className="re-design-home-comp-img-gallary-front">
        <img src={dpsrufrontImg} alt="Dpsru" />
      </div> */}

      <div className="redesign-logo-of-all-sub-schools-container">
        <div className="each-redesign-logo-home-page">

        </div>
      </div>
        <div className="mission-vision-about-us-and-vision-for-viksit-bharat-contaier">
          <div className="mission-vision-and-aboutus-container-home-page">
            <div className="about-us-home-page-container Redesign-about-us-home-container">
              <h2 className="mission-heading Redesign-mission-heading-home-page">About Us </h2> 
              <img src={seprattor} className="image-seprator-redesign" />

              <p className="mission-text">
                Delhi Pharmaceutical Sciences and Research University (DPSRU) is
                the first Pharmacy University in India and third in the world,
                established by Act 07 of 2008 State legislature of Delhi and
                functional from 2015. It is 100% grant-in-aid State Government
                University recognized under 2f and Section 22 of the UGC. The
                University has the legacy of 57 years starting with the D.
                Pharm. in polytechnic in 1964 to College of Pharmacy in 1972 and
                then the institute Delhi Institute of Pharmaceutical Sciences
                and Research (DIPSAR) in 2004 that got converted into DPSRU as
                it stands now. The Act, Statutes and Ordinances published by the
                Government of Delhi are being implemented and the administration
                is mentored by the General Council with Hon.’ble Lt. Governor as
                the Chancellor, the Board of Governors, the Academic Council and
                the Finance Committee having external distinguished
                personalities as the members.
              </p>
              <NavLink
                className="read-more-vc-message read-more-btn-for-about-us-home-page"
                to="/aboutUs"
              >
                Read More...
              </NavLink>
            </div>{" "}
            <hr className="hr-partition-for-about-and-mission-container" />
            <div className="mission-and-vission-container">
              <div className="mission-container Redesign-mission-home-conainer Redesign-mission-home-conainer-margin-top">
                <h3 className="mission-heading Redesign-mission-heading-home-page">MISSION</h3>
                <img src={seprattor} className="image-seprator-redesign" />
               
                <p className="mission-text">
                  “To develop core competency in pharmaceutical sector and
                  contribute in building leaders, administrators and personnel
                  who can take up responsibilities as pharmaceutical science
                  professionals, appropriate for community, institutions and
                  industries on health and management related fields”.
                </p>
              </div>

              <div className="vission-container Redesign-mission-home-conainer">
                <h3 className="vission-heading mission-heading Redesign-mission-heading-home-page">VISION</h3>{" "}
                <img src={seprattor} className="image-seprator-redesign" />
               
                <p className="vision-text mission-text">
                  “To be the ultimate destination for education, training and
                  research in pharmaceutical sciences and allied areas and
                  thereby, cater to the health needs of the people at large”
                </p>
              </div>
              <NavLink
                className="read-more-vc-message read-more-btn-for-about-us-home-page"
                to="/visionMissionObjectives&QualityPolicy"
              >
                Read More...
              </NavLink>
            </div>
          </div>
<div className="flash-box-re-design-home-page-conainer">
  <HomePageFlashNotification/>
</div>
          <div className="vision-for-viksit-bharat-home-page-container">
            <h2 className="mission-heading mission-heading Redesign-mission-heading-home-page">Vision for Viksit Bharat @2047</h2>{" "}
            {/* <hr /> */}  <img src={seprattor} className=" seprator-redesign-for-viksit-bharat-heading" alt="seprator" style={{ margin:'0 auto'}}/>
            <p className="mission-text">
              {" "}
              <b>The Indian Pharmaceuticals industry</b> has emerged globally as
              one of the fastest growing industries. India is ranked 3rd
              worldwide in the production and 13th in terms of value with over
              60,000 generic drugs in different 60 therapeutic categories and
              about 60% of the global demand for vaccines The pharma export was
              $25.3 billion in the 2022-2023 financial year. It is valued to be
              approximately as US$45 billion in 2023 and is estimated to reach
              $130 billion by 2030. India It is also emerging as the world’s
              iconic centre for Traditional Medicine and even medical devices
              <br />
              Education plays an important role as mentioned by Hon.’ble Prime
              Minister Shri Narendra Modi about starting of L. M. College of
              Pharmacy in 1947 in Ahmedabad. In Delhi we have Delhi
              Pharmaceutical Sciences & Research University as the first and
              only Pharmacy University of India and third in the world’s
              dedicated to pharmaceutical and allied health sciences. DPSRU is
              thriving to create the state-of-the-art infrastructure research
              facilities to see that it contributes to the Vision of Viksit
              Bharat 2047.
            </p>
            <NavLink
              className="read-more-vc-message read-more-btn-for-about-us-home-page"
              to="/VisionforViksitBharat@2047"
              style={{ width: "15%" }}
            >
              Read More...
            </NavLink>
          </div>
        </div>

        <br />
        {/* <p className="bulletain-text">Recent Notification</p> */}
    

        <div className="home-component">
          <div className="sidenav-and-chancellor-flex-box-new">
            {/* Side nav data of mar03 2023 */}
            {/* <div className="responsive-home-side-nav-for-small-screen">
              {
                // sidenav open toggle
                isSideNavOpen ? (
                  <div
                    className="sidenav-comp-home"
                    style={{ overflow: "hidden", zIndex: "999" }}
                  >
                    <button
                      onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                      className="sidenav-responsive-button"
                    >
                      <FaRegWindowClose />
                    </button>

                    {SidebarData.map((item, index) => {
                      return (
                        <li key={index} className={item.cName}>
                          <Link to={item.path}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                      className="sidenav-responsive-button"
                    >
                      <FaListAlt style={{ background: "none" }} />
                    </button>

                    <div
                      className="sidenav-comp-home-display-none"
                      style={{ display: "none" }}
                    >
                      {SidebarData.map((item, index) => {
                        return (
                          <li key={index} className={item.cName}>
                            <Link to={item.path}>
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </div>
                  </>
                )
              }
            </div> */}
              {/* // sidenav open toggle end here */}

            {/* Side nav big screen codes */}
            {/* <div
              className="responsive-home-side-nav-for-big-screen"
              style={{
                background: " var(--primaryBlue)",
                width: "45%",
                paddingTop: "2%",
              }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </div> */}

            <div className="upper-cont-comp redesign-home-administrative-container ">
              {/* vc area */}
              <div className="vc-container each-chancelor-and-vc-container-for-redesign">
                <div className="vc-container-data redesing-chancellor-and-vc-container-data-inner">
                <h4 className="img-heading ReDesign-heading-of-chanslor-and-vc-container mission-heading Redesign-mission-heading-home-page">Chancellor Speaks</h4>
                <img src={seprattor} className="image-seprator-redesign seprator-redesign-for-chancelor" alt="seprator" style={{ margin:'0 auto'}}/>

                  <div className="photos-comp Redesign-chancellor-and-vc-photo-comp">
                    <img
                      src={chanslor}
                      alt="Vice Chancellor"
                      className="vc-img vc-img-home-page-redesign-landing-page"
                    />
                    <h3 className="vc-intro">
                      Vinay Kumar Saxena <br />
                      Lieutenant Governor Delhi
                      <br />
                      Chancellor
                    </h3>
                  </div>
                  <div className="vc-text-area redesign-vc-and-chancellor-text-box-area">
                    <p className="vc-message">
                      Pharmaceutical Science is a dynamic and rapidly changing
                      field that combines a board range of scientific
                      disciplines critical to the discovery and development of
                      new drugs and therapies. 
                      <br />
                    
                    </p>
                    <Link
                      to="/chancellorDpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              {/* vc area end here*/}

              {/* Chancellor container start here */}

              <div className="chancellor-container each-chancelor-and-vc-container-for-redesign">

                <div className="chancellor-container-data vc-container-data redesing-chancellor-and-vc-container-data-inner">
                <h4 className="img-heading ReDesign-heading-of-chanslor-and-vc-container mission-heading Redesign-mission-heading-home-page">Vice-Chancellor</h4> <img src={seprattor} className="image-seprator-redesign seprator-redesign-for-chancelor" alt="seprator" style={{ margin:'0 auto'}}/>
                  <div className="photos-comp Redesign-chancellor-and-vc-photo-comp">
                    <img
                      src={newvcsirMaheshverma}
                      alt="Vice Chancellor"
                      className="vc-img vc-img-home-page-redesign-landing-page"
                    />
                    <h3 className="vc-intro">
                      Prof. (Dr.) Mahesh Verma <br />
                      Vice Chancellor
                    </h3>
                  </div>

                  <div className="vc-text-area redesign-vc-and-chancellor-text-box-area">
                    <p className="vc-message">
                      Padma Shri Awardee <br />
                      National Science and Technology Awardee <br />
                      DR. B. C. Roy Awardee <br />
                      BDS, MDS, MBA, FAMS, FDSRCS (England), FDSRCPSG (Glasgow),
                      FDSRCS <br />
                     
                    </p>{" "}
                    <Link
                      to="/vice-chancellor-dpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              {/* Registrar container start here */}

              <div className="chancellor-container each-chancelor-and-vc-container-for-redesign">

                <div className="chancellor-container-data vc-container-data redesing-chancellor-and-vc-container-data-inner">
                <h4 className="img-heading ReDesign-heading-of-chanslor-and-vc-container mission-heading Redesign-mission-heading-home-page">Registrar Speaks</h4>  <img src={seprattor} className="image-seprator-redesign seprator-redesign-for-chancelor" alt="seprator" style={{ margin:'0 auto'}}/>
                  <div className="photos-comp Redesign-chancellor-and-vc-photo-comp">
                    <img
                      src={newregistrarShahooSIr}
                      alt="Registrar"
                      className="vc-img vc-img-home-page-redesign-landing-page"
                    />
                    <h3 className="vc-intro">
                      Prof. P.K. Sahoo, <br /> Offg. Registrar (DPSRU) &
                      Director (DIPSAR)
                    </h3>
                  </div>

                  <div className="vc-text-area redesign-vc-and-chancellor-text-box-area">
                    <p className="vc-message">
                    Delhi Pharmaceutical Sciences and Research University (DPSRU) is offering UG and PG education in the field of Pharmacy, Allied health and Physiotherapy. 
                    </p>
                    <Link
                      to="/registrar-of-dpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Registrar container end here */}
          </div>


<div className="redesign-home-examination-and-admission-details-container">
<div className="container-for-examination-result-and-datesheet-for-redesign-home-page">
  <Results/>
</div>
<div className="container-for-examination-result-and-datesheet-for-redesign-home-page">
  <DateSheet/>
</div>
</div>





          {/* DpsruHomeTabs imported here */}
          {/* <DpsruHomeTabs /> */}
          <DpsruHomeTabsUpdated />
          {/* DpsruHomeTabs imported here */}
        </div>
      </div>
    </>
  );
}

export default ReDesigningHome;
