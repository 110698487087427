import React from "react";
import newblink from '../../../NEW/new.gif'
import ideasfromyouthviksitbharat227 from './Notice of viksit bharat Data/Ideas from Youth for Viksit Bharat (2047).517e6560dab8f49fd976.pdf'
import viksitbharatNoticeforalluniversitys from './Notice of viksit bharat Data/Viksit Bharat letter to all Universities.6aec26b27f41c459dd19.pdf'
import vikasitbharatLGfinalfff from './Notice of viksit bharat Data/Vikasit Bharat LG Final 11.12.23.9b8097d640f2d7763910.pdf'
import bbannerofviksitbharat from './Notice of viksit bharat Data/B-Banner.jpg'
import dposterofviksitbharat from './Notice of viksit bharat Data/D-Poster.jpeg'
import conceptofviksitbharar from './Notice of viksit bharat Data/Concept Note for Discussion with Universities on Vision for 2047.pdf'
import sopOfviksitbharat from './Notice of viksit bharat Data/C-Viksit Bharat-SoP.cb168b77a1fbe0ee2270.pdf'
import aCollaborativeapprohforadevnation from './Notice of viksit bharat Data/A collaborative approach for a Developed Nation.pdf'
const NoticeOfViksitBharat = () => {
  return (
    <div className="dpsru-every-component-css">
      <div className="inner-container" style={{background:'none'}}>
        <h1 className="about-viksit-bharat-heading general-heading-for-viksit-bharat">
          Notice of Viksit Bharat 2047
        </h1>
        <hr />
        <ol className=" ">
          <div className="viksitbharat-realtime-content-data">
            <li> DPSRU Organized 1st Workshop on Viksit Bharat@2047  by Honorable Vice-Chancellor DPSRU 
              <a href='https://www.youtube.com/watch?v=JMYAgB7iE3o&t=1s'>
              https://www.youtube.com/watch?v=JMYAgB7iE3o&t=1s
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={bbannerofviksitbharat}>
               Banner of viksit bharat
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={dposterofviksitbharat}>
               Poster of viksit bharat
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={conceptofviksitbharar}>
              Concept Note for Discussion with Universities on Vision for 2047
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={sopOfviksitbharat}>
              Viksit Bharat-SoP
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={aCollaborativeapprohforadevnation}>
              A collaborative approach for a Developed Nation
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={ideasfromyouthviksitbharat227}>
                Ideas from Youth for Viksit Bharat (2047)
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              <a href={viksitbharatNoticeforalluniversitys}>
                Viksit Bharat letter to all Universities
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              अभिव्यक्ति कुलपति के दिल से{" "}
              <a href={vikasitbharatLGfinalfff}>
                Click here..
              </a> <img src={newblink} alt="new" />
            </li>
            <li>
              Please join a talk by Hon'ble Vice Chancellor on Viksit
              Bharat@2047 st 8.30 pm 14/12/2023 on above google meet link{" "}
              <a href="https://meet.google.com/hri-gbig-fwt">Click here..</a>
            </li> 
           
          </div>
        </ol>
      </div>
    </div>
  );
};

export default NoticeOfViksitBharat;
