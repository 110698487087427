import React, { useState } from "react";

import profimg from "./Picture3-e1686040434582.jpg";

const ProfGeetaAggarwal = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
const [isPetentsOpen, setIsPetentsOpen] = useState(false);
const [isBookOpen, setIsBookOpen] = useState(false)

  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Prof. Geeta Aggarwal</h2>
      <h3 className="facult-designation-for-each-container">
        Dean Academics & Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : geetaaggarwal [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=i6vU5yQAAAAJ">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=34879335600">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-0568-4267">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M. Pharm. (Pharmaceutics), Ph.D.
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Drug targeting by nanocarriers, Transdermal and topical drug delivery by
        colloidal carriers, Herbal cosmeceuticals, nutraceuticals, Precision
        medicine, Translational and reverse translational research
      </p>


{/* Patents */}

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsPetentsOpen(!isPetentsOpen)}
      >
        Petents
      </button>

  {
    isPetentsOpen?
    <div className="inner-container">
          <p>
            <b>Petents</b>
          </p>

          <ul>
  <li>International patent <b>‘Novel pharmaceutical excipient for enhancing solubility of poorly water soluble drugs and method thereof’</b>. PCT filing done for the same. Pub no. WO/2017/163260. International application no. PCT/IN2017/000064.</li>
  <li>National Patent on <b>“Improved efficacy of antiepileptic drug Lamotrigine solid dispersions using modified locust bean gum”</b>. Application no. 201911004379.</li>
  <li>National patent on <b>“Sustained release pharmaceutical composition of Glimepride”</b>. Application no. 201911028988 (Patent Granted)</li>
  <li>National patent on <b>“Transdermal pharmaceutical composition of Diclofenac”</b>. Application no. 201911040114. (Patent Granted in 2022)</li>
  <li>National patent on <b>“Stable pharmaceutical nasal composition for Rhinnorhea”</b>. Application number: 202011007732 (Complete application filed in Jan 2021)</li>
  <li>National patent on <b>“A muscle relaxant pharmaceutical composition for combating muscle pain and injuries”</b>. Application number 202011018974 (Complete application filed in May 2021) (Granted in 2023)</li>
  <li>National patent on <b>“Antimicrobial and antiviral nanofiber/nanomaterial based composition and method of preparation thereof”</b>. Application number 202211024842 (Filed in May 2022) Published</li>
</ul>
          </div>:null
  }

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container"> 
       
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Research projects</p>
                </td>
                <td>
                  <p>Grants</p>
                </td>
                <td>
                  <p>Collaborations</p>
                </td>
                <td>
                  <p>Status</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Coordinator for DSIR-DPSRU-CRTDH Centre for Advanced
                    Formulation and Technology from DSIR in common research and
                    technology development hubs (CRTDH) under “Affordable
                    Health”
                  </p>
                </td>
                <td>
                  <p>500 Lacs from DSIR</p>
                </td>
                <td>
                  <p>–</p>
                </td>
                <td>
                  <p>Ongoing (Granted in September 2021</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Team member for Establishment of Centre for Precision
                    Medicine and Pharmacy under Delhi Knowledge Development
                    Foundation (DKDF)
                  </p>
                </td>
                <td>
                  <p>
                    545 Lacs from DKDF division, DTTE, Govt. of NCT of Delhi
                  </p>
                </td>
                <td>
                  <p>–</p>
                </td>
                <td>
                  <p>Ongoing (Granted in Jan 2021)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Team member for Establishment of state-of-art Dermal
                    Technology facility (DTF)
                  </p>
                </td>
                <td>
                  <p>200 Lacs from DST-FIST</p>
                </td>
                <td>
                  <p>–</p>
                </td>
                <td>
                  <p>Ongoing (Granted in March 2022)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    PI for Start-up project entitled ‘Validated Cold Chain
                    Shipping solution’
                  </p>
                </td>
                <td>
                  <p>4 Lacs from DIIF</p>
                </td>
                <td>
                  <p>–</p>
                </td>
                <td>
                  <p>Completed in 2020</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
          <li>
          Development and optimization of pharmaceutical formulations for chronic kidney diseases with Industry Alniche (2018-2020)
          </li>
        </ul>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <b>Publications</b>
          </p>

        


          <ul>
  <li>Maheshwari K, Musyuni P, Moulick A, Mishra H, Ekielski A, Mishra PK, <b>Aggarwal G</b>*. Unveiling the Microbial Symphony: Next-Gen Sequencing and Bioinformatics Insights into the Human Gut Microbiome. Health Science Review. 2024 April</li>
  <li>Mangla B, Mittal P, Kumar P, Javed S, Ahsan W, <b>Aggarwal G</b>. Development of Erlotinib-Loaded Nanotransferosomal Gel for the Topical Treatment of Ductal Carcinoma in situ. Nanomedicine. 2024 Apr;19(10):855-874 (Impact factor 5.5)</li>
  <li>Jain GK, Raina V, Grover R, Sharma J, Warsi HH, <b>Aggarwal G</b>, Kesharwani P. Revisiting the significance of nano-vitamin D for food fortification and therapeutic application. Drug Dev Ind Pharmacy. 2024 Jan (Impact factor 3.7)</li>
  <li>Kaur A, Sharma K, Sharma N, <b>Aggarwal G</b>. An Insight into the Repurposing of Phytoconstituents obtained from Delhi’s Aravalli Biodiversity Park as antifungal agents. Infectious Disorders - Drug Targets. 2024 Feb</li>
  <li>M Bhuyan, M Kaur, U Kaushal, M Nagpal, M Singh, G <b>Aggarwal</b>. Recent Avenues in Treatment of Liver Diseases: Role of Nanotechnology. Current Drug Targets. 2023; 24(4):320-331 (Impact factor 3.2)</li>
  <li>JK Singh, S Chakraborty, M Nagpal, G <b>Aggarwal</b>. Herbal Approach for Diabetic Cure and Futuristic Dimension. Curr Drug Res Rev. 2023;15(3):207-221</li>
  <li>Dabas R, Musyuni P, <b>Aggarwal G</b>. An Overview of Regulatory Perspectives of Nanoparticles and Liposomal Products in India and USA. J Generic Medicines. 2023</li>
  <li>Panchal A, Musyuni P, <b>Aggarwal G</b>. Regulatory Perspective of Cancer Drug Development: An Overview. J Generic Medicines 2023</li>
  <li>Kumari A, Kaur A, <b>Aggarwal G</b>. The Emerging Potential of siRNA Nanotherapeutics in Treatment of Arthritis. Asian J Pharm Sci. 2023;18(5):100845 (Impact factor 10.2)</li>
  <li>Kumar P, Mangla B, Javed S, Ahsan W, <b>Aggarwal G</b>. Amelioration of the therapeutic potential of gefitinib against breast cancer using nanostructured lipid-based systems. Nanomedicine. 2023; 18(18) (impact factor 5.5)</li>
  <li>Tiwari N, Jaimini A, Jain GK, <b>Aggarwal G</b>, Mittal G. Evaluation of three different 99mTc-sulfur colloid, 99mTc- based mock-venom agents for lymphoscintigraphy studies in preclinical models of peripheral snakebite envenomation. J Pharmacol Toxicol Methods. 2023;122:107280 (impact factor 2.28)</li>
  <li>Sah A, <b>Aggarwal G</b>, Jain GK, Zaidi SMA, Naseef PP, Kuruniyan MS, Zakir F. Design and Development of a Topical Nanogel Formulation Comprising of a Unani Medicinal Agent for the Management of Pain. Gels 2023, 9(10), 794. (Impact factor 4.6)</li>
  <li>Kumar P, Mangla B, Javed S, Ahsan W, Musyuni P, Sivadasan D, Alqahtani S, <b>Aggarwal G</b>. A review of nanomaterials from synthetic and natural molecules for prospective breast cancer nanotherapy. Front. Pharmacology. 19 May, 2023 (Impact factor 5.6)</li>
  <li>Kalra S, <b>Aggarwal G</b>, Pawaria S, Yadav S, Ajmera P. Psychological health of postmenopausal women: a bibliometric analysis in the recent decade. Climacteric. April 2023 (impact factor 3.02)</li>
  <li>Kaur M, Sharma A, Puri V, <b>Aggarwal G</b>, Maman P, Huanbutta K, Nagpal M, Sangnim T. Chitosan-Based Polymer Blends for Drug Delivery Systems. Polymers 2023, 15, 2028. https://doi.org/10.3390/polym15092028 (Impact factor 5)</li>
  <li>Tiwari N, Mittal G, Priyadarshani A, Kumar D, Abourehab MAS, Jain GK, Kesharwani P, <b>Aggarwal G</b>. Recent progress in polymeric biomaterials and their potential applications in skin regeneration and wound care management. Journal of Drug Delivery Science and Technology. 2023;82:104319 (Impact factor 5.02)</li>
  {/* <li>Musyuni P, Sharma R, <b>Aggarwal G</b>. Optimizing drug discovery: An opportunity and application with reverse translational research. Health Science Review. 2023;6:100074 (Impact Factor 0.75)</li>
  <li>Grover R, Drall S, Poonia N, Jain GK, <b>Aggarwal G</b>, Lather V, Pandita D, Goyal RK. CD44 and CD133 aptamer conjugated nanocarriers for targeting cancer stem cells. European Polymer Journal. 2023;183:111770. (Impact factor 5.54)</li>
  <li>Tomar S, Musyuni P, <b>Aggarwal G</b>. An overview of regulation for nutraceuticals and concept of personalized nutraceuticals. Journal of Generic Medicine. 2023</li>
  <li>Jindal M, Kaur M, Nagpal M, Singh M, <b>Aggarwal G</b>, Dhingra GA. Skin cancer management: Current scenario and future perspectives. Current Drug Safety. 2023;18(2):143-158 (Impact factor 1.5)</li>
  <li>Kaur M, <b>Aggarwal G</b>, Nagpal M. Nanotechnology for targeted drug delivery to treat osteoporosis. Current Drug Targets. 2023;24(1):2-12 (Impact factor 2.93)</li>
  <li>Mohit, Kumar P, Solanki P, Mangla B, <b>Aggarwal G</b>. Formulation development, optimization by Box Behnkem design and in vitro characterization of Geftinib phospholipid complex based nanoemulsion drug delivery system. J Pharmaceutical Innovation. 2023 Apr 11;9(4):322 (Impact factor: 2.538)</li>
      <li>Kumar P, Mangla B, Javed S, Ahsan W, Musyuni P, Ahsan A, <b>Aggarwal G</b>. Gefitinib: An updated review of its role in the cancer management, its nanotechnological interventions, recent patents and clinical trials. Recent Pat Anticancer Drug Discov. 2023;18(4):448-469. (Impact factor 3.038)</li> */}
</ul> 


<ul>
  <li>Maheshwari K, Musyuni P, Moulick A, Mishra H, Ekielski A, Mishra PK, <b>Aggarwal G</b>*. Unveiling the Microbial Symphony: Next-Gen Sequencing and Bioinformatics Insights into the Human Gut Microbiome. Health Science Review. 2024 April</li>
  <li>Mangla B, Mittal P, Kumar P, Javed S, Ahsan W, <b>Aggarwal G</b>. Development of Erlotinib-Loaded Nanotransferosomal Gel for the Topical Treatment of Ductal Carcinoma in situ. Nanomedicine. 2024 Apr;19(10):855-874 (Impact factor 5.5)</li>
  <li>Jain GK, Raina V, Grover R, Sharma J, Warsi HH, <b>Aggarwal G</b>, Kesharwani P. Revisiting the significance of nano-vitamin D for food fortification and therapeutic application. Drug Dev Ind Pharmacy. 2024 Jan (Impact factor 3.7)</li>
  <li>Kaur A, Sharma K, Sharma N, <b>Aggarwal G</b>. An Insight into the Repurposing of Phytoconstituents obtained from Delhi’s Aravalli Biodiversity Park as antifungal agents. Infectious Disorders - Drug Targets. 2024 Feb</li>
  <li>M Bhuyan, M Kaur, U Kaushal, M Nagpal, M Singh, G <b>Aggarwal</b>. Recent Avenues in Treatment of Liver Diseases: Role of Nanotechnology. Current Drug Targets. 2023; 24(4):320-331 (Impact factor 3.2)</li>
  <li>JK Singh, S Chakraborty, M Nagpal, G <b>Aggarwal</b>. Herbal Approach for Diabetic Cure and Futuristic Dimension. Curr Drug Res Rev. 2023;15(3):207-221</li>
  <li>Dabas R, Musyuni P, <b>Aggarwal G</b>. An Overview of Regulatory Perspectives of Nanoparticles and Liposomal Products in India and USA. J Generic Medicines. 2023</li>
  <li>Panchal A, Musyuni P, <b>Aggarwal G</b>. Regulatory Perspective of Cancer Drug Development: An Overview. J Generic Medicines 2023</li>
  <li>Kumari A, Kaur A, <b>Aggarwal G</b>. The Emerging Potential of siRNA Nanotherapeutics in Treatment of Arthritis. Asian J Pharm Sci. 2023;18(5):100845 (Impact factor 10.2)</li>
  <li>Kumar P, Mangla B, Javed S, Ahsan W, <b>Aggarwal G</b>. Amelioration of the therapeutic potential of gefitinib against breast cancer using nanostructured lipid-based systems. Nanomedicine. 2023; 18(18) (impact factor 5.5)</li>
  <li>Tiwari N, Jaimini A, Jain GK, <b>Aggarwal G</b>, Mittal G. Evaluation of three different 99mTc-sulfur colloid, 99mTc- based mock-venom agents for lymphoscintigraphy studies in preclinical models of peripheral snakebite envenomation. J Pharmacol Toxicol Methods. 2023;122:107280 (impact factor 2.28)</li>
  <li>Sah A, <b>Aggarwal G</b>, Jain GK, Zaidi SMA, Naseef PP, Kuruniyan MS, Zakir F. Design and Development of a Topical Nanogel Formulation Comprising of a Unani Medicinal Agent for the Management of Pain. Gels 2023, 9(10), 794. (Impact factor 4.6)</li>
  <li>Kumar P, Mangla B, Javed S, Ahsan W, Musyuni P, Sivadasan D, Alqahtani S, <b>Aggarwal G</b>. A review of nanomaterials from synthetic and natural molecules for prospective breast cancer nanotherapy. Front. Pharmacology. 19 May, 2023 (Impact factor 5.6)</li>
  <li>Kalra S, <b>Aggarwal G</b>, Pawaria S, Yadav S, Ajmera P. Psychological health of postmenopausal women: a bibliometric analysis in the recent decade. Climacteric. April 2023 (impact factor 3.02)</li>
  <li>Kaur M, Sharma A, Puri V, <b>Aggarwal G</b>, Maman P, Huanbutta K, Nagpal M, Sangnim T. Chitosan-Based Polymer Blends for Drug Delivery Systems. Polymers 2023, 15, 2028. https://doi.org/ 10.3390/polym15092028 (Impact factor 5)</li>
  <li>Tiwari N, Mittal G, Priyadarshani A, Kumar D, Abourehab MAS, Jain GK, Kesharwani P, <b>Aggarwal G</b>. Recent progress in polymeric biomaterials and their potential applications in skin regeneration and wound care management. Journal of Drug Delivery Science and Technology. 2023;82:104319 (Impact factor 5.02)</li>
  <li>Musyuni P, Sharma R, <b>Aggarwal G</b>. Optimizing drug discovery: An opportunity and application with reverse translational research. Health Science Review. 2023;6: 100074 (Impact Factor 0.75)</li>
  <li>Grover R, Drall S, Poonia N, Jain GK, <b>Aggarwal G</b>, Lather V, Pandita D, Goyal RK. CD44 and CD133 aptamer conjugated nanocarriers for targeting cancer stem cells. European Polymer Journal. 2023;183: 111770. (Impact factor 5.54)</li>
  <li>Tomar S, Musyuni P, <b>Aggarwal G</b>. An overview of regulation for nutraceuticals and concept of personalized nutraceuticals. Journal of Generic Medicine. 2023</li>
  <li>Jindal M, Kaur M, Nagpal M, Singh M, <b>Aggarwal G</b>, Dhingra GA. Skin cancer management: Current scenario and future perspectives. Current Drug Safety. 2023;18(2):143-158 (Impact factor 1.5)</li>
  <li>Kaur M, <b>Aggarwal G</b>, Nagpal M. Nanotechnology for targeted drug delivery to treat osteoporosis. Current Drug Targets. 2023;24(1):2-12 (Impact factor 2.93)</li>
  <li>Mohit, Kumar P, Solanki P, Mangla B, <b>Aggarwal G</b>. Formulation development, optimization by Box Behnkem design and in vitro characterization of Geftinib phospholipid complex based nanoemulsion drug delivery system. J Pharmaceutical Innovation. 2023 Apr 11;9(4):322 (Impact factor: 2.538)</li>
  <li>Kumar P, Mangla B, Javed S, Ahsan W, Musyuni P, Ahsan A, <b>Aggarwal G</b>. Gefitinib: An updated review of its role in the cancer management, its nanotechnological interventions, recent patents and clinical trials. Recent Pat Anticancer Drug Discov. 2023;18(4):448-469. (Impact factor 3.038)</li>
</ul>



{/* <hr /> */}


<ul>
            
            <li>
              Sharma R, Kumar A, Mazeed J, Thakur AK, <b>Aggarwal G</b>. Drugs
              acting on Renin-angiotensin-aldosterone system (RAAS) and deaths
              of Covid-19 patients: A systematic review and metaanalysis of
              observational studies. Egypt Heart J. 2022 Sep 6;74(1):64 (Impact
              factor 1.2)
            </li>
            <li>
              Sharma R, Patil CR, Mazeed J, <b>Aggarwal G</b>. Next Generation
              Sequencing in the biodiversity conservation of endangered
              medicinal plants. Environ Sci Pollut Res Int. 2022
              Oct;29(49):73795-73808 (Impact factor 5.19)
            </li>
            <li>
              Sah A, Naseef PP, Kuruniyan MS, Jain GK, Zakir F,{" "}
              <b>Aggarwal G</b>. A Comprehensive Study of Therapeutic
              Applications of Chamomile. Pharmaceuticals (Basel). 2022 Oct
              19;15(10):1284 (Imapct factor 5.2)
            </li>
            <li>
              Kumar P, Mangla B, Beg S, Afzal O, Altamini ASA, Almalki WH, Ullah
              SNMN, <b>Aggarwal G</b>. Optimization and validation of stability
              indicating RP-HPLC method for the quantification of gefitinib in
              bulk drug and nanoformulations: An application towards in vitro
              and ex vivo performance evaluation. Arabian Journal of Chemistry.
              2022: 15(10)104333 (Impact factor 6.2)
            </li>
            <li>
              Kaur M, <b>Aggarwal G</b>, Nagpal M. Nanotechnology for targeted
              drug delivery to treat osteoporosis. Current Drug Targets. 2022
              Oct 4. Online ahead of print
            </li>
            <li>
              <b>Kumar P, </b>Mangla<b> B,</b> Javed<b> S,</b> Ahsan
              <b> W,</b> Musyuni<b> P, </b>Ahsan<b> A,</b> Aggarwal G.
              Gefitinib: An updated review of its role in the cancer management,
              its nanotechnological interventions, recent patents and clinical
              trials. Recent Pat Anticancer Drug Discov. 2022 Oct 26
            </li>
            <li>
              Musyumi P, Bai J, Sheikh A, Vasanthan KS, Jain GK, Abourehab MAS,
              Lather V, <b>Aggarwal G,</b> Kesharwani P, Pandita D. Precision
              medicine: A ray of hope for overcoming multi-drug resistance in
              cancer. Drug Resistance Updates. 2022;65:100889 (Impact factor
              22.6)
            </li>
            <li>
              Rathi R, Nagpal M, Kaur M, Ballouria P, Dutta R, <b>Aggarwal G</b>
              , Arora S. Treatment of tuberculosis in nanoera: recent advances.
              Current Bioactive Compounds. 2022;18(7): Article ID: e270122200564
            </li>
            <li>
              Mohit, Kumar P, Solanki P, Mangla B, <b>Aggarwal G</b>.
              Formulation development, optimization by Box Behnkem design and in
              vitro characterization of Geftinib phospholipid complex based
              nanoemulsion drug delivery system. J Pharmaceutical Innovation Nov
              2022 (Impact factor: 2.5)
            </li>
            <li>
              Musyumi P, Nagpal M, Singh M, Goyal RK, <b>Aggarwal G</b>.
              Nanotechnology enabled solutions to comabat Covid-19: Prevention,
              Treatment and Diagnosis. Current Pharmaceutical Biotechnology.
              2022;23(1):98-111 (Impact factor: 2.829)
            </li>
            <li>
              Das A, Agarwal P, Jain GK, <b>Aggarwal G,</b> Pandita D.
              Repurposing drugs as novel triple negative breast cancer
              therapeutics. Anti-Cancer Agents in Medicinal Chemistry.
              2022;22(3):515-550 (Impact factor: 2.527)
            </li>
            <li>
              Bharti S, Zakir F, Mirza MA, <b>Aggarwal G</b>. Anti fungal
              biofilm strategies: a less explored area of wound management. Curr
              Pharm Biotechnol 2022;23(12):1497-1513 (Impact factor: 2.829)
            </li>
            <li>
              Mangla B, Javed S, Sultan MH, Ahsan W, <b>Aggarwal G</b>, Kohli K.
              Nanocarriers-Assisted Needle-Free Vaccine Delivery Through Oral
              and Intranasal Transmucosal Routes: A Novel Therapeutic Conduit.
              Front Pharmacol. 2022;12: Article 757761. (Impact factor 5.988)
            </li>
            <li>
              <a href="https://www.sciencedirect.com/science/article/abs/pii/S0254629921005548#!">
                Maddheshiya S,{" "}
              </a>
              Ahmad A,{" "}
              <a href="https://www.sciencedirect.com/science/article/abs/pii/S0254629921005548#!">
                Ahmad W, Zakir F,{" "}
              </a>
              <b>Aggarwal G</b>. Essential oils for the treatment of skin
              anomalies: Scope and potential” South African Journal of Botany.
              2022;151:187-191 (Impact factor: 3.11)
            </li>
            <li>
              Kumar R, Mirza MA, Naseef PP, Kuruniyan MS, Zakir F,{" "}
              <b>Aggarwal G</b>. Exploring the potential of natural products
              based nanomedicine for maintaining oral health. Molecules. 2022;
              27(5): 1725 (Impact factor: 4.927)
            </li>
            <li>
              Jindal M, Kaur M, Nagpal M, Singh M, <b>Aggarwal G</b>, Dhingra
              GA. Skin cancer management: Current scenario and future
              perspectives. Current Drug Safety. 2022; 17(4)
            </li>
            <li>
              Fatima M, Abourehab MAS, <b>Aggarwal G</b>, Jain GK, Sahebkar A,{" "}
              <a href="https://www.tandfonline.com/author/Kesharwani%2C+Prashant">
                Kesharwani
              </a>{" "}
              P. Advancement of cell-penetrating peptides in combating
              triple-negative breast cancer. Drug Discovery Today, 2022 Sep
              12;27(11):103353 (Impact factor 7.9)
            </li>
            <li>
              <h4>
                Nidhi, <b>Aggarwal G</b>, Jain GK, Mittal G. Multi-drug loaded
                microneedles for emergency treatment of snakebite envenomation..
                Medical Hypotheses. 2022;165:110908 (Impact factor: 4.4)
              </h4>
            </li>
            <li>
              <a href="https://www.tandfonline.com/author/Kesharwani%2C+Prashant">
                Kesharwani
              </a>{" "}
              P, Chadar R, Shukla R,{" "}
              <a href="https://www.tandfonline.com/author/Jain%2C+Gaurav+K">
                Jain
              </a>{" "}
              GK,{" "}
              <a href="https://www.tandfonline.com/author/Aggarwal%2C+Geeta">
                <b>Aggarwal</b>
              </a>
              <b> G</b>,{" "}
              <a href="https://www.tandfonline.com/author/Abourehab%2C+Mohammad+AS">
                Abourehab
              </a>
              e MAS.
              <a href="https://www.tandfonline.com/author/Sahebkar%2C+Amirhossein">
                {" "}
                Sahebkar
              </a>{" "}
              A. Recent advances in multifunctional dendrimer-based nanoprobes
              for breast cancer theranostics. Journal of Biomaterials Science,
              Polymer Edition Online 17th July, 2022. (Impact factor: 3.68)
            </li>
            <li>
              Saini C, Miglani A, Mysumi P and Aggarwal G. Review of form 483s
              and warning letters to pharmaceutical manufacturers issued by
              USFDA. Journal of Generic Medicine. 2022;18(1):32-41
            </li>
            <li>
              Miglani A, Saini C, Musyuni P, Aggarwal G. A review and analysis
              of product recall for pharmaceutical drug product. Journal of
              Generic Medicines. 2022;18(2):72-81
            </li>
            <li>
              Ahmad S, Musyuni P, <b>Aggarwal G.</b> A retrospective analysis on
              the product recall: An overview of solvent and mineral residue as
              per USFDA. J. of Generic Medicine. Accepted in Dec 2022
            </li>
            <li>
              Grover R, .Drall S, Poonia N, Jain GK, <b>Aggarwal G</b>, Lather
              V, Pandita D, Goyal RK. CD44 and CD133 aptamer conjugated
              nanocarriers for targeting cancer stem cells. European Polymer
              Journal. 2022 Accepted.
            </li>
            <li>
              Musyuni P, Sharma R, <b>Aggarwal G</b>. Optimizing drug discovery:
              An opportunity and application with reverse translational
              research. Health Science Review. Dec. 2022 (Accepted)
            </li>
            <li>
              Musyuni P, Kumar D, Pandita D, Jain GK, Nagpal M, Aggarwal G.
              Nutraceuticals for osteoarthritis. Recent patents on Food,
              Nutrition and Agriculture.2021;12 (12): 88-103.
            </li>
            <li>
              Katoch A, Nagpal M, Kaur M, Singh M, Aggarwal G and Dhingra GA.
              Development and Characterization of LBG-PVA Interpenetrating
              Networks Incorporating Gliclazide for Sustained Release. Current
              Drug Therapy. 2021;16(1): 54-63
            </li>
            <li>
              Kaur M, Nagpal M, Singh M, <b>Aggarwal G</b>, Singh TG, Dhingra
              GA. Improved antibacterial activity of topical gel-based on
              nanosponge carrier of cinnamon oil. BioImpacts 2021;
              <a href="https://bi.tbzmed.ac.ir/Archive/11/1">11(1)</a>: 23-31
            </li>
            <li>
              Bakshi H, Nagpal M, Singh M, Dhingra GA and <b>Aggarwal G</b>.
              Propitious profile of Peppery Piperine. Curr Mol Pharmacol.
              2021;14(4):477-48
            </li>
            <li>
              <b>Aggarwal G</b>, Nagpal G, Sharma A, Puri V and Dhingra GA.
              Upcoming drifts in bio-similars. Current Clinical Pharmacology
              <b>. </b>2021;16(1): 39-51
            </li>
            <li>
              Yadav P, Mishra H, Nagpal M, <b>Aggarwal G</b>.. Expanding
              opportunities in treatment of leukemia by solid lipid
              nanoparticles. Letters in Drug Delivery Discovery. 2021;18:212-221
            </li>
            <li>
              Musyumi P, <b>Aggarwal G</b>, Nagpal M, Goyal RK. A Case Study:
              Analysis of Patents of Coronaviruses and Covid-19 for Technology
              Assesment and Future Research. Curr Pharm Des. 2021;27(3):423-439.
            </li>
            <li>
              Singla P,{" "}
              <a href="https://www.cureus.com/users/256935-pankaj-musyuni">
                Musyuni
              </a>{" "}
              P, <b>Aggarwal G</b>,{" "}
              <a href="https://www.cureus.com/users/256734-harjinder-singh">
                Singh
              </a>{" "}
              H. <a href="mailto:harjinder427@yahoo.com"> </a>Precision Medicine
              an Emerging Paradigm for Improved Diagnosis and Safe Therapy in
              Pediatric Oncology. Cureus. 2021: 13(7): e16489
            </li>
            <li>
              Jain S, Goyal RK, Ajmera P, Aggarwal G, Dhiman S. Development of a
              valid and reliable tool to measure skeletal muscle spasm in
              myofascial pain syndrome. Psychology and Education Journal. 2021;
              58(5).
            </li>
            <li>
              Jain S., Goyal RK., Ajmera P, Aggarwal G and Dhiman S. Recent
              advances in diagnosis and management of myofascial pain syndrome:
              A Narrative review. J Clinical and Diagnostic Research. 2021;
              15(10): YE04-YE08
            </li>
            <li>
              Puri V, Nagpal M, Sharma A, Thakur GS, Singh M and
              <b> Aggarwal G</b>. Development of binary dispersion and
              nanocomposites of irbesartan with enhanced antihypertensive
              activity. BioImpacts. 2020;10(4): 41-46.
            </li>
            <li>
              Verma R, Nagpal M, Singh TG, Singh M and <b>Aggarwal</b> G.
              Improved efficacy of lovastatin from soluplus-PEG hybrid polymer
              based binary dispersions. Current Bioactive Compound. 2020;16 (8):
              1164 – 1171
            </li>
            <li>
              Bakshi H, Nagpal M, Singh M, Dhingra GA and
              <b> Aggarwal G. </b>Treatment of psoriasis: a comprehensive review
              of entire therapies. Curr Drug Saf. 2020;15(2):82-104
            </li>
            <li>
              Jindal M, Nagpal M, Singh M, <b>Aggarwal G</b>, Dhingra GA. Gold
              Nanocarriers- Boon in Cancer Theranostics. Curr Pharm Des.
              2020;26(40):5134-5151.
            </li>
            <li>
              Mansi, Nagpal M and <b>Aggarwal G</b>. An updated review on
              Materiovigilance for safe use of medical devices.IJDRA. 2020; 8(4)
            </li>
            <li>
              Jain GK, Nirmal J, Aggarwal G, Popli H. Bioscintigraphy: A
              branding opportunity for drug products. Pharma Times. 2020;
              52(8):27-31
            </li>
            <li>
              Kaur M, Devi G, Nagpal M, Singh M, Dhingra GA and Aggarwal G.
              Antiviral essential oils incorporated in nanocarriers: strategy
              for prevention from Covid-19 and future infectious pandemics.
              Pharmaceutical Nanotechnology. 2020; 8(6):437-451
            </li>
            <li>
              <b>Aggarwal G</b>, Nagpal M, Gupta M and Verma S. Local drug
              delivery applications for the treatment of periodontitis: patient
              compliant approach. Current Drug Therapy. 2019;14(2):135-152.
            </li>
            <li>
              Sharma V, Kapoor A and<b> Aggarwal G</b>. Overview of chronic
              kidney disease: assessment, nutritional management and treatment.
              Int J. Current Advanced Research. 2019; 8(2):71145-71149
            </li>
            <li>
              Kaur H, Afsana, Nagpal M and <b>Aggarwal G.</b> Potential benefits
              of phytochemicals for treatment of hyper-pigmentation. JDDT. 2019;
              9(2): 420-427
            </li>
            <li>
              Pushkarna N, Arora A, <b>Aggarwal G</b>, Arora S and Nagpal M.
              Binary dispersions of telmisartan using natural modified neem gum:
              development and characterization. RJPT. 2019:12 (9): 4387-4393.
            </li>
            <li>
              Arora A<b>, Aggarwal G</b>, Chander J, Manan P and Nagpal M. Drug
              eluting sutures: potential and its applications. Journal of
              Applied Pharmaceutical Sciences. 2019;9(7):111-123.
            </li>
            <li>
              Verma R, Katoch A, Dhingra GA, Chander J, <b>Aggarwal G</b>, and
              Nagpal M. Rheumatoid arthritis therapeutics: The neoteric
              approach. Plant Achives. July 2019; 19(2)
            </li>
            <li>
              Arora R, <b>Aggarwal G</b>, Dhingra GA and Nagpal M. Herbal active
              ingredients used in skin cosmetics. Asian J Pharmaceutical
              Clinical Research. 2019;12(9)
            </li>
            <li>
              Kapoor A, Nagpal M and <b>Aggarwal G</b>. Pre-filled syringes in
              developed and developing region: An insight into regulatory
              considerations. International J Drug Regulatory Affairs.
              2019;7(2):42-50.
            </li>
            <li>
              Rawat S, Gupta M and<b> Aggarwal G</b>. Similar Biologics: An
              Overview. International J Current Advanced Research.
              2019;8(5):18623-18627
            </li>
            <li>
              Arora A, <b>Aggarwal G</b>, Singh TG, Singh M, Dhingra GA and
              Nagpal M. Inclusion complexes of Atorvastatin Calcium – Sulfobutyl
              ether β cyclodextrin with enhanced hypolipidemic activity. Journal
              of Applied Pharmaceutical Sciences. 2019;9(11):60-68
            </li>

            <li>
              Nagpal M, Raj N, Thakur GS and <b>Aggarwal G</b>. Improved
              solubility of Itraconazole using neem gum: Development and
              characterization of topical gel. Current Bioactive Compounds.
              2019;15(4): 399-407
            </li>

            <li>
              Chandra A,<b> Aggarwal G,</b> Manchanda S and Narula A.
              Development of topical gel of Methotrexate incorporated ethosomes
              and salicylic acid for treatment of Psoriasis. Pharmaceutical
              Nanotechnology. 2019;7(5):362-374
            </li>
            <li>
              Dhingra GA, <b>Aggarwal G</b> and Nagpal M. Lock Stock and Barrel
              of Wound Healing. Current Pharmaceutical Design.
              2019;25(38):4090-4107.
            </li>
            <li>
              Nagpal P, <b>Aggarwal G</b>, Jindal M, Jain UK, Chandra R and
              Madan J. Ultrasound, microwave and Box-Behnken Design amalgamation
              offered superior yield of gum from Abelmoschus esculentus:
              Electrical, chemical and functional peculiarity. Computers and
              Electronics in Agriculture. 2018;145:169-178
            </li>
            <li>
              Nagpal M, Maman P <b>Aggarwal G</b> and Gilhotra RM. Nano era of
              dentistry-an update. Curr Drug Deliv. 2018;15(2):186-204.
            </li>
            <li>
              Gupta M., Chandra A., Popli H. and <b>Aggarwal G</b>. Curcumin:
              Potential therapeutic moiety for fungal infections. Current
              Traditional Medicine. 2018;4(4) : 249-262
            </li>
            <li>
              Verma S, Gupta M, Popli H and <b>Aggarwal G</b>. Diabetes mellitus
              treatment using herbal drugs. International J Phytomedicine. 2018;
              10(1): 1-10
            </li>
            <li>
              Sukhbir K, Arora D, Narang R K and <b>Aggarwal G</b>. Statistical
              optimization for development of colon targeted pH dependant
              metronidazole granules using full factorial design. Int. J. Pharm.
              Sci. Res. 2018; 9(3):1152-1159.
            </li>
            <li>
              Dhamoon RK, Popli H, Gupta M and <b>Aggarwal G</b>. Particle size
              characterization- techniques, factors and quality-by-design
              approach. Int J Drug Deliv. 2018; 10(1).
            </li>
            <li>
              Jain S, Popli H, <b>Aggarwal G,</b> Gupta M. Green Tea – An
              antioxidant mystic herb. Pharma Tutor. 2018: 6 (7): 23-31
            </li>
            <li>
              Kapoor A and <b>Aggarwal G. </b>A systematic review on decisive
              facets of age-related macular degneration and future paradigms
              <b>. </b>Int J Current Advanced Research. 2018;7(12):16654-16659
            </li>
            <li>
              Chandra A., Joshi,<b> Aggarwal G</b>. Topical nanodrug delivery
              for treatment of psoriasis: Progressive and novel delivery. Asian
              J. Pharmaceutics. 2018;12(3); S835-S848
            </li>
            <li>
              Midha K, Nagpal M, Singh G and <b>Aggarwal G</b>. Prospectives of
              solid self-microemulsifying systems in novel drug delivery. Curr
              drug deliv. 2017; 14(8): 1078-1096.
            </li>
            <li>
              Kaur S, Narang RK and <b>Aggarwal G.</b> Formulation and
              development of colon targeted mucopenetrating metronidazole
              nanoparticles. Trop J Pharm Res. 2017; 16(5):967-973.
            </li>
            <li>
              Sukhbir K, Chawla V, Narang RK, <b>Aggarwal G, </b>Bhartwaj TR.
              Comparative mucopenetration ability of metronidazole loaded
              chitosan and pegylated chitosan nanoparticles. Asian J
              Pharmaceutical Clinical research. 2017;10(6):125-130
            </li>
            <li>
              Sharma P, Nagpal M<b>, Aggarwal G, </b>Maman P and Thakur GS. Neem
              gum based solid dispersion in development of aceclofenac tablet
              with enhanced bioavailability. Drug Delivery letters. 2017; 7(2):
              115-124
            </li>
            <li>
              Kaur S, Chawla V, Narang R K and<b> Aggarwal G. </b>
              Preparation and characterization of Metronidazole-β-Cyclodextrin
              inclusion complex. Research J. Pharmaceutical, Biological Chemical
              Sciences. 2017; 18(3): 1590-1596
            </li>
            <li>
              Nagpal M, <b>Aggarwal G,</b> Jain UK and Madan J. Extraction of
              gum from abelmoschus esculentus: physicochemical peculiarity and
              antioxidant prepatent. Asian J Pharmaceutical Clinical Research.
              2017; 10 (9).
            </li>
            <li>
              Nagpal M, <b>Aggarwal G,</b> Jain UK and Madan J. Okra fruit
              gum-chitosan
              <br />
              impregnated polymer network films: formulation and substantial
              depiction. Asian J Pharmaceutical Clinical Research. 2017; 10(10):
              219-222.
            </li>
            <li>
              Nagpal M, Manan P and <b>Aggarwal G. </b>Miliaria- An Update.
              Research J. Pharmaceutical, Biological Chemical Sciences. 2017;
              8(4): 1161-1168
            </li>
            <li>
              Hardatt R, Nagpal M, Kumar N and <b>Aggarwal G</b>. Solid
              dispersion tablets of loratadine using locust bean gum and skimmed
              milk- a comparative study. Der Pharmacia Lettre. 2016, 8(6).
            </li>
            <li>
              <b>Aggarwal G, </b>Nagpal M and Kaur G. Development and comparison
              of nanosponge and niosome based gel for the topical delivery of
              tazarotene. Pharmaceutical Nanotechnology. 2016;4:213-228.
            </li>
            <li>
              Midha K, Singh G, Nagpal M, <b>Aggarwal G </b>and Singh TG.
              Formulation evaluation of solid self-microemulsifying drug
              delivery system of Aceclofenac by using neusilin. Current
              Nanomedicine 2016;7(2):142-157.
            </li>
            <li>
              Midha K, Nagpal M, <b>Aggarwal G</b> and Singh G. Development of
              dispersible self-microemulsifying tablet of atorvastatin.
              Pharmaceutical Methods. 2015; 6(1): 9-25.
            </li>
            <li>
              Khindri S, <b>Aggarwal G </b>and Harikumar SL. Role of niosomes
              and proniosomes for enhancing bioavailability of drugs. J Drug
              Deliv Ther. 2015; 5(1): 28-33.
            </li>
            <li>
              Kapil A, <b>Aggarwal G</b> and Harikumar SL. Formulation and
              evaluation of nanosuspension and nanoemulsion of Rosuvastatin and
              their comparative study. AJBPR. 2015; 5(1)
            </li>
            <li>
              Kumari N, <b>Aggarwal G</b> and HariKumar SL. Comparison studies
              of chitosan xanthangum and chitosan guargum interpolymerpolymer
              complexation based mucoadhesive buccal films of Atenolol. AJBPR.
              2015; 5(1)
            </li>
            <li>
              Gupta V, Nagpal M, Khan I, <b>Aggarwal G</b>, Kaur R, Singh S,
              Behl T and Jain UK. A review on non-ionic surfactant based
              organogel for transdermal delivery. IJIPSR 2014; 2 (7): 1315-132
            </li>
            <li>
              Thakur K, Nagpal M, <b>Aggarwal G</b>, Kaur R and Jain UK.
              Development and evaluation of solid dispersion using binary and
              ternary complexes. IJIPSR 2014; 2 (8): 1793-1812.
            </li>
            <li>
              Gupta V, Nagpal M, <b>Aggarwal G</b>, Kaur R, Singh S, Behl T and
              Jain UK. Formulation, development and evaluation of non-ionic
              surfactant organogel for transdermal delivery of Acyclovir. IJIPSR
              2014; 2 (7): 1270-1283.
            </li>

            <li>
              Ritika, Harikumar SL and Aggarwal G. Nanoemulsion based hydrogel
              for enhanced transdermal delivery of Ketoprofen. Advances in
              Pharmaceutics 2014; Article ID 468456
            </li>

            <li>
              Jaiswal P, <b>Aggarwal G,</b> Singh K and HariKumar SL.
              Development of self microemulsifying drug delivery system (SMEDDS)
              solid-SMEDDS of Telmisartan. Int J Pharm Invest. 2014
            </li>
            <li>
              Bhavna, <b>Aggarwal G </b>and HariKumar SL. Enhanced transdermal
              permeability of Piroxicam via novel nanoemulgel formulation. Int J
              Pharm Invest. 2014; 4(2):65-76.
            </li>
            <li>
              Prashar M, <b>Aggarwal G</b> and HariKumar SL. Formulation and
              evaluation of transdermal drug delivery system of Simvastatin
              using natural and synthetic permeation enhancer. Der Pharmacia
              Lettre. 2014; 6 (5): 358-368
            </li>
            <li>
              Kumar Amit, <b>Aggarwal G</b>, Singh K and HariKumar SL.
              Comparison of vegetable and volatile oils as skin permeation
              enhancers for transdermal delivery of losartan potassium. Der
              Pharmacia Lettre. 2014; 6 (1):199-213.
            </li>
            <li>
              Heer D, <b>Aggarwal G</b> and HariKumar SL. Development of fast
              dissolving oral films and tablets of cinnarizine: Effect of
              superdisintegrants. Int J Pharmacy Pharm Sci. 2014; 6 (2)
            </li>
            <li>
              <b>Aggarwal G</b>, Dhawan S and Harikumar, SL. Formulation,{" "}
              <i>in vitro</i> and <i>in vivo</i> evaluation of matrix type
              patches containing Olanzapine. Pharm Dev Technol. 2013; 18(4):
              916-925.
            </li>
            <li>
              <b>Aggarwal G</b>, Dhawan S and Harikumar SL. Formulation,{" "}
              <i>in vitro</i> and <i>in vivo</i> evaluation of transdermal
              patches containing Risperidone. Drug Dev Ind Pharm. 2013; 39(1)
              :39-50.
            </li>
            <li>
              Bansal S, <b>Aggarwal G</b> and HariKumar SL. Design and
              development of Cefdinir niosomes for oral delivery. J Pharmacy
              Bioallied Sciences. 2013; 5(4): 318-325.
            </li>
            <li>
              Heer D, <b>Aggarwal G</b> and HariKumar SL. Recent trends of fast
              dissolving drug delivery system – An overview of formulation
              technology. Pharmacophore. 2013; 4 (1): 1-9
            </li>
            <li>
              Jaiswal P, <b>Aggarwal G,</b> HariKumar SL and Kaur A.
              Bioavailability enhancement of poorly soluble drugs by SMEDDS: A
              Review. J Drug Deliv Ther. 2013; 3(1): 98-109
            </li>
            <li>
              <b>Aggarwal G</b>, Dhawan S and Harikumar SL. Natural oils as skin
              permeation enhancers for transdermal delivery of Olanzapine:{" "}
              <i>in vitro</i> and <i>in vivo</i> evaluation. Curr Drug Deliv,
              2012; 9(2): 172-181.
            </li>
            <li>
              Goyal U, Arora R and <b>Aggarwal G</b>. Formulation design and
              evaluation of self-microemulsifying drug delivery system of
              lovastatin. Acta Pharmaceutica. 2012; 62 (3).
            </li>
            <li>
              Choudhary A, Rana AC, <b>Aggarwal G</b>, Kumar V and Zakir F.
              Development and characterization of Atorvastatin solid dispersion
              using skimmed milk for improved oral bioavailability. Acta
              Pharmaceutica Sinica B. 2012; 2(4): 421-428.
            </li>
            <li>
              Gupta A, <b>Aggarwal G</b>, Singla S and Ritika. Transfersomes: A
              novel vesicular carrier for enhanced transdermal delivery of
              Sertaline: development, characterization and performance
              evaluation. Scientia Pharmaceutica. 2012; 80(4): 1061–1080
            </li>
            <li>
              Singla S, HariKumar SL and <b>Aggarwal G</b>. Proniosomes for the
              effective topical delivery of clotrimazole: Development,
              Characterization and Performance evaluation<b>.</b> Asian J Pharm
              Sci. 2012; 7(4): 257-262
            </li>
            <li>
              Goyal U, Gupta A, Rana AC and <b>Aggarwal G</b>. Self
              microemulsifying drug delivery system: A method for enhancement of
              bioavailability. Int J Pharmaceutical Sciences Research.2012,
              3(1); 66-79.
            </li>
            <li>
              Kaur J, <b>Aggarwal G</b>, Singh G and Rana AC. Formulation and
              evaluation of fast dissolving tablets containing amlodipine
              besylate solid dispersion. Int J Pharmacy Pharm Sci. 2012, 4(1),
              409-416
            </li>
            <li>
              Jatwani S, Rana AC and <b>Aggarwal G</b>. An overview on
              solubility enhancement techniques for poorly soluble drugs and
              solid dispersions as an eminent strategic approach. Int J
              Pharmaceutical Research.2012, 3(4), 942-956.
            </li>
            <li>
              Singla S, HariKumar SL and <b>Aggarwal G</b>. Proniosomes for
              penetration enhancement in transdermal delivery. IJDDR. 2012;
              4(2): 1-13.
            </li>
            <li>
              Jothi M, Harikumar SL and <b>Aggarwal G</b>. In situ opthalmic
              gels for the treatment of eye diseases. Int J. Pharmaceutical
              Sciences Research. 2012; 3(7): 1891-1904
            </li>
            <li>
              Bansal S, Kashyap CP, <b>Aggarwal G</b> and HariKumar SL. A
              comparative review on vesicular drug delivery system and stability
              issues<b>. </b>Int J Research in Pharmacy Chemistry, 2012; 2(3).
            </li>
            <li>
              Ritika, Harikumar SL and <b>Aggarwal G</b>. Microemulsion system
              in role of expedient vehicle for dermal application. J Drug Deliv
              Ther. 2012; 2(4)
            </li>

            <li>
              Ritika, <b>Aggarwal G</b> and Harikumar SL. Formulation tactics
              for the delivery of poorly soluble drugs. International J Pharm
              Tech Res. 2012; 4(3): 915-919.
            </li>

            <li>
              Sharma N, <b>Aggarwal</b> G, Rana AC, Bhat ZA and Kumar D. A
              review: Transdermal drug delivery system: A tool for novel drug
              delivery system. IJDDR. 2011; 3(3): 70-84
            </li>
            <li>
              Mahajan A and <b>Aggarwal</b> G. Smart polymers: Innovations in
              novel drug delivery. IJDDR. 2011; 3(3): 16-30
            </li>
            <li>
              Khullar R, Goel A and <b>Aggarwal G</b>. Generic drugs: A ground
              discussion. IJDDR. 2011; 3(1): 178-184
            </li>
            <li>
              Kumar V, <b>Aggarwal G</b>, Zakir F and Choudhary A. Buccal
              bioadhesive drug delivery: A novel technique. Int J Pharm Bio Sci.
              2011; 1(3): 129-143
            </li>
            <li>
              Kumar V, Zakir F, <b>Aggarwal G</b> and Choudhary A. Formulation
              and evaluation of buccal patches of venlafaxine. Int J Pharm Bio
              Sci. 2011;1(3): 170-182
            </li>
            <li>
              Choudhary A, <b>Aggarwal G</b>, Zakir F and Kumar V. Mini review:
              Journey of solid dispersion technique from bench to scale. Int Res
              J Pharm. 2011; 2(8): 46-51
            </li>
            <li>
              Mahajan A, Chhabra N and <b>Aggarwal G.</b> Formulation and
              characterization of fast dissolving buccal films: A review. Der
              Pharmacia Lettre. 2011; 3(1): 152-165
            </li>
            <li>
              Jatwani S, Rana AC, Singh G and <b>Aggarwal G</b>. Solubility and
              dissolution enhancement of Simvastatin using synergistic effect of
              hydrophilic carriers. Der Pharmacia Lettre. 2011, 3 (6); 280-293
            </li>
            <li>
              <b>Aggarwal G</b>, and Dhawan S. Psychotropic drugs and
              transdermal drug delivery – An overview. International J Pharma
              Bio Sciences. 2010; 2: 1-12
            </li>
            <li>
              Sharma S, <b>Aggarwal G</b> and Dhawan S. Design and evaluation of
              Olanzapine transdermal patches containing vegetable oils as
              permeation enhancers. Der Pharmacia Lettre. 2010; 2 (6): 84-98
            </li>
            <li>
              <b>Aggarwal G</b> and Sharma A. Controlled release of a poorly
              water soluble drug Prednisolone from heterodisperse hydrogel
              system. International J Pharma Biosciences. 2010; 2: 1-8
            </li>
            <li>
              Sharma A, Jindal M, <b>Aggarwal G</b> and Jain S. Development of a
              novel method for fabrication of solid lipid nanoparticles: using
              high shear homogenization and ultrasonication. Research J Pharma
              Bio Chem Sciences. 2010; 1(2): 265- 274
            </li>
            <li>
              Singh A and <b>Aggarwal G</b>. Technology transfer in
              pharmaceutical industry: A discussion. International J Pharma Bio
              Sciences. 2010; 1(3): 1-5
            </li>
            <li>
              Sharma S and <b>Aggarwal G</b>. Novel technologies for oral
              delivery of poorly soluble drugs. Research J Pharm, Bio Chem
              Sciences. 2010; 1(4): 292- 305
            </li>
            <li>
              <b>Aggarwal G</b>, Goel A, Sharma A and Dhawan S. Carriers/vesicle
              based approaches for penetration enhancement in transdermal drug
              delivery system. Pharmaceutical Reviews (www.pharminfo.net). 2010;
              8 (1)
            </li>
            <li>
              <b>Aggarwal G</b>, Garg A and Dhawan S. Transdermal drug delivery:
              evolving technologies and expanding opportunities. Ind J Pharm
              Educ Res. 2009; 43 (3): 251-259
            </li>
            <li>
              <b>Aggarwal G</b> and Dhawan S. Development, Fabrication and
              evaluation of transdermal drug delivery system – A review.
              Pharmaceutical Reviews (www.pharminfo.net). 2009; 7 (5).
            </li>

            <p>
              <br />
              <br />
            </p>
            <p>
              <b>Book chapters</b>
            </p>

            <li>
              Ramesh K. Goyal and <b>Geeta Aggarwal</b>. Biomarker based drug
              discovery with Translational Approach. In: Contemporary issues in
              Biomedical Science: Futuristic Thought. Springer publication.
              Biomedical Translational Research. 2022; 123-140
            </li>

            <li>
              <b>Geeta Aggarwal</b>, Gaurav, Manzari Singh, Ramesh K. Goyal.
              Organ on a chip: Novel in vitro model in Drug Discovery. In: Sobti
              RC, Dhalla, NS, Watanabe, M Eds. Deleating health and health
              systems: Mechanistic insights into COVID 19 complications.
              Springer Nature Singapore. Biomedical Translational Research,
              2022; 73-96
            </li>
            <li>
              Foziyah Zakir, Harshita Mishra,Mohammad Azharuddin,M. Aamir Mirza,
              <b>Geeta Aggarwal</b>, Zeenat Iqbal.Gastrointestinal abnormalities
              and Nigella sativa: A Narrative Review of Preclinical and Clinical
              Studies. Chapter in Black seeds (Nigella Sativa): Pharmacological
              and Therapeutic Applications”, by Elsevier Inc. publications USA.
              Black Seeds (Nigella Sativa). 2022; 355-386
            </li>
            <li>
              <b>Geeta Aggarwal, </b>Pankaj Musyuni, Bharti Mangla, Ramesh K.
              Goyal<b>. </b>Reverse Translational Approach in Repurposing of
              Drugs for Anticancer Therapy. Springer Nature Singapore. Ranbir
              Sobti et al. (Eds): Drug Repurposing for Emerging Infectious
              Diseases and Cancer (In printing press) 2022
            </li>
            <li>
              Foziyah Zakir, S Mohapatra, B Aftab, Z Iqbal,
              <b>Geeta Aggarwal</b>. Book chapter entitled ‘Clinical trials,
              future prospects and challenges of drug delivery in combating
              metabolic disorders’ (Chapter 32) in the Book- Drug Delivery
              Systems for Metabolic disorders. In printing press 2022
            </li>

            <li>
              <b>Geeta Aggarwal</b> and Manju Nagpal. Nanomaterials to overcome
              emergence and re-emergence of superbugs. Book chapter in book
              entitled ‘Strategies to Overcome Superbug Invasions:Emerging
              Research and Opportunities’ 2020. ISBN 1799803074, 9781799803072.
              Chopra Dimple Sethi Chopra. Publisher: IGI Global.
            </li>

            <li>
              Manju Nagpal, Arju Dhawan, Malkeet Dhiman and{" "}
              <b>Geeta Aggarwal</b>. Chapter ‘Microgels/nanogels for patient
              compliant drug delivery’ in Book ‘Advances in Medicine and
              Biology’, Vol 137, Pages 75-136, Nova Science Publishers, Inc.,
              2018.
            </li>
            <li>
              Manju Nagpal, Paramjot Singh and <b>Geeta Aggarwal</b>. Resorbable
              polymer fiber reinforced composites in biomedical application.
              Book chapter in the Multi-Volume SET (1-9) ” Composites in
              Biomedical Engineering” Elsevier, 2018.
            </li>
            <li>
              <b>Geeta Aggarwal </b>and Manju Nagpal. Pharmaceutical Polymer
              Gels in Drug Delivery. Book Chapter in Springer Handbook on
              Polymer Gels. 2018
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container">

      <ul>
  <li>Expert speaker in Pharma Workshop Series TPDTP 2021 being organized by Chitkara College of Pharmacy for topic entitled ‘Opportunities in Formulation Development’ on 9th August, 2021</li>
  <li>Chairperson for Webinar on Precision Medicine: Health and Care of the 21st Century by Dr. Sooraj Ratnakar, Founder Scientist, Swageneon 13th August, 2021 organised by DCPMP.</li>
  <li>Chairperson for Webinar series on Precision Medicine and Wellness Genetics: Applications in daily life on 23rd August, 2021 being organized by DCPMP, DPSRU</li>
  <li>Speaker in Webinar on Healthcare Entrepreneurship: Lessions from Covid 19 on 23rd August, 2021 being organized by DIIF.</li>
  <li>Moderator for Panel discussion on Mitigating Entrepreneurial Challenges posed by Covid 19 on 24th August 2021 organized by DIIF.</li>
  <li>Scientific team member for Brain Storming on DPSRU-NIB Course on Quality Control of Biopharmaceuticals on 13th August, 2021</li>
  <li>Speaker in webinar and workshop on IP a Regulatory framework for healthcare entrepreneurship being organized by DIIF and PHD chamber of Commerce and Industry on 27th-28th Sept., 2021.</li>
  <li>Team member for workshop on Triggering ideas to solve technical challenges of herbal drug formulations organized by DSIR-DPSRU-CRTDH centre, DPSRU.</li>
  <li>Team member for committee to formulate policy and guidelines for operations and activities related to IPR in NSUT, New Delhi from Nov., 2021</li>
  <li>Resource person for National webinar entitled ‘Analytical Solutions for Herbal Applications-An Overview on 10th Jan., 2022 organized by DSIR-DPSRU-CRTDH and Spinco Biotech Pvt Ltd.</li>
  <li>Resource person during workshop on Evolving Market Dynamics in Healthcare Industry in India on 11th Feb, 2022 organized by DSIR-DPSRU-CRTDH Centre and Cognitrex.</li>
  <li>Invited speaker for the topic Innovation and Accademia: creating cultures of Technology transfer and Start-ups’ during workshop at Maharaja Agrasen School of Pharmacy, Maharaja Agrasen University, Baddi on the theme “Industrial Aspects of Academia- Filling the gap between Academia and Industry” on 17th February, 2022.</li>
  <li>Invited speaker for lecture entitled ‘Pharmaceutical Validation’ on 26th Feb., 2022 in NCRD’s Sterling Institute of Pharmacy, Nerul, Mumbai</li>
  <li>Co-chairperson for Hands on Training on Multicolur Flow Cytometery being held in DCPMP on 30th-31st March, 2022</li>
  <li>Chairperson for International Symposium on safety assessment under Materiovigilance and pharmacovigilance programs on 20th May, 2022 being held in DPSRU</li>
  <li>Resource person for Leadership and Personality Development programme as value added course from 27th June to 1st July, 2022</li>
  <li>Speaker for value added course on Standard Operating Procedures and its preparation from 14th July to 25th July 2022</li>
  <li>Resource person and speaker during FDP on Innovations in Quality by Design and its implementation in Pharmaceutical Industry for topic entitled ‘Quality parameters in research for commercialization of Pharmaceutical product from academia’ being held on 27th June, 2022 by Chitkara University, Baddi.</li>
  <li>External expert member for Research Advisory Board of Lloyd Institute of Management and Tecnology, Noida from October 2021</li>
  <li>Advisory Committee member at 2nd International Conference on Natural Products and Human Health 2022 being held from 4th to 6th November, 2022 in Deshbandhu Colege, University of Delhi.</li>
  <li>Chairperson for the symposium on Ayurveda for Happy and Healthy life of Senior Citizens from 3rd Oct to 8th October being organized by DPSRU.</li>
  <li>Chairperson in Pre-conference workshop on Role of Pharmacognosy and Regulations for development of traditional medicine conducted by DPSRU and Deshbandhu College, DU on 3rd Nov., 2022</li>
  <li>Resource person in National Conferenc on the theme Journey of a molecule from research to patient in IPS Bhaddal, Punjab being held on 25th and 26th Nov, 2022</li>
  <li>Invited speaker on topic entitled ‘Innovation and Academia: Creating culture of technology transfer and Start-ups’ for Pharmacy week in Sanskar College of Pharmacy, Ghaziabad on 23rd Nov., 2022</li>
  <li>Joint organizing secretary for DST-STUTI Programme on Developling skills and knowledge for Nanotechnology in Drug Delivery in DPSRU from 23rd to 29th Dec., 2022.</li>
  <li>Chaiperson in the workshop on ‘Comparing,contrasting and advancing ayurvedic and unani concepts with contemporary genomic biology’ organized by DCPMP on 15th Dec., 2022</li>
  <li>Chairperson for National Conference on Opportunities for MSMEs from Drug Discovery to Product Development organized by DCPMP from 10th-14th Jan, 2023</li>
  <li>Panelist for session on Developing India as the Global Education Hub for Rest of the World during Indian Education and Edtech Summit being held in Hyatt Regency, Gurgaon on 20th Jan, 2023</li>
  <li>Chairperson for Scientific committee and panel discissions for conference on Regulatory Aspects of Ayush Products for Glkobal Research and Competitiveness from 12st -22nd Jan., 2023 being held in DPSRU</li>
  <li>Invited Panelist during the Panel Discussion on the theme “DigitALL: Innovation and technology for gender equality organized by DSIR under “Technology Development and Utilization Program for Women (TDUPW)” on 7th March, 2023</li>
  <li>Invited speaker during International Conference on ‘Innovation and Advances in Drug Development & Clinical Research’ (IADDCR-2023) being held on 24th & 25th April 2023 at Chitkara College of Pharmacy, Chitkara University, Rajpura, Punjab, India</li>
  <li>Invited speaker for National Seminar on Breaking barriers: Empowering Regulatory Affairs through Brainstorming’ on 15th May, 2023</li>
  <li>Panelist for Action plan on CRTDH building a suppoty system for MSMEs for R&D & manufacturing during Chintan Shivir with DSIR officials and industry experts on 13th Oct., 2023 being held at DPSRU CRTDH.</li>
  <li>Invited speaker for DRDO sponsored national conference on Recent trends in research & innovation: an application of AI in Pharmaceutical science, healthcare system of the development of API on 27th October, 2-23 at Galgotias University, Noida</li>
  <li>Invited speaker during Global Women Breakfast on Role and Achievements of Women in Science on title ‘Women in Innovation’ organized by Baba Farid University of Health Sciences, Faridkot, Punjab</li>
  <li>Invited speaker in 2nd PharMAIR international conference on Pharm 360- Emerging trends and Future perspectives in Pharmaceuticalson 22nd to 23rd March organized by DD Vispute College of Pharmacy, Navi Mumbai</li>
  <li>Invited Speaker for an IBRO sponsored meeting cum international conference on “Uplifting the Translational Value of New Drug Molecules in Neuroscience” on 16th & 17Th April, 2024 in Chitkara University.</li>
  <li>Resource person in National conference to celebrate Technology day 2024 on 18th May, 2024 being held in MDU, Rohtak</li>
</ul>




      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <b>Awards and Recognitions</b>
          </p>
          <ul>
            <li>
              Award for Outstanding contribution to DPSRU in Research Promotion
              on 5th April 2022
            </li>
            <li>
              Notable Research award for publication entitled ‘A case study:
              Analysis of patents on Coronaviruss and Covid-19 for Technological
              Assessment and Future Research in Curr. Pharm. Des.’ on 5th April,
              2022 by DPSRU.
            </li>
            <li>
              Best Professor Award by Operant Pharmacy Federation on 25th Sept.,
              2020
            </li>
            <li>
              Graduated for successful development of Validated Cold Chain
              Shipping Solution by DIIF, DPSRU in July, 2020
            </li>
            <li>
              Award to Complipro as the innovative product launched during CPhI
              2019 on 26th November, 2019
            </li>
            <li>
              Awarded for <b>Launch of 1</b>
              <b>st</b>
              <b> Oral Intradialytic hyperprotein supplement </b>for Dialysis
              and Critical Care patients in collaboration with Alniche{" "}
              <b>by Sh. Anil Baijal, Hon’ble Lt. Governor,</b> Govt. of NCT of
              Delhi on 26th July, 2019.
            </li>
            <li>
              Awarded for Launch of 1st Oral Intradialytic hyperprotein
              supplement for Dialysis and Critical Care patients in
              collaboration with Alniche{" "}
              <b>by Sh. Manish Sisodia, Hon’ble Dy. Chief Minister</b>, Govt. of
              NCT of Delhi on 13th August, 2019.
            </li>
            <li>
              <b>Young Teacher Award </b>by OPF in March, 2019
            </li>
            <li>
              Awarded for <b>academics and maximum number of publications</b> in
              2017-18 by DPSRU on 9th May, 2018.
            </li>
            <li>
              Awarded for services for <b>Industry networking</b> by DPSRU on
              9th May, 2018
            </li>
            <li>
              Awarded for <b>grant of start-up proposal</b> entitled ‘Validated
              Cold Chain Shipping Solutions’ by DPSRU Innovation Incubation
              Foundation on 17th October, 2017.
            </li>
            <li>
              Awarded for <b>outstanding services</b> rendered during 2016-17
              for placement of students by DPSRU.
            </li>
            <li>
              Awarded as <b>best faculty of the University</b> by Rayat Bahra
              University in May 2014
            </li>
            <li>
              Approved <b>co-supervisor of PG of Faculty of Medical Sciences</b>
              , University of Delhi
            </li>
            <li>
              BOS expert member for School of Medical&nbsp;and Allied Sciences,
              Sanskriti University
            </li>
            <li>BOS member for USPS, Rayat Bahra University</li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <br />
            <br />
          </p>
        </div>
      ) : null}


<button
        className="naac-button"
        onClick={() =>
          setIsBookOpen(!isBookOpen)
        }
      >
       Books/Book chapters/Articles
      </button>

      {
        isBookOpen?
        <div className="inner-container">

<ul>
  <li><b>Geeta Aggarwal</b>, Pankaj Musyuni, Bharti Mangla, Ramesh K. Goyal. Reverse Translational Approach in Repurposing of Drugs for Anticancer Therapy. Springer Nature Singapore. Ranbir Sobti et al. (Eds): Drug Repurposing for Emerging Infectious Diseases and Cancer. 2023</li>
  <li>Dipesh Sharma, Sima Singh, Piyush Kumar, Gaurav K Jain, <b>Geeta Aggarwal</b>, Waleed H Almalki, Prashant Kesharwani. Mechanisms of photodynamic therapy in Book Nanomaterials for Photodynamic Therapy. Woodhead Publishing. 2023</li>
  <li>Foziyah Zakir, Mamta Bishnoi, <b>Geeta Aggarwal</b>. Arabinogalactan latex based drug delivery systems. Book chapter in the book entitled “Natural Polymeric Materials based Drug Delivery Systems in Lung Diseases" pp 183-198. 2023</li>
  <li>Deepika Sharma, Smruti Rekha Rout, Gowtham Kenguva, Mahender Khatravath, Gaurav K. Jain, <b>Geeta Aggarwal</b>, Prashant Kesharwani, Rambabu Dandela. PLGA based nanoparticles as regenerative medicine. Book Chapter in Poly (Lactic-co-glycolic acid) PLGA nanoparticles for drug delivery. Elsevier March 2023</li>
  <li><b>Geeta Aggarwal</b>. Pharmaceutics IV- Physical Pharmacy. 2012-2013. Thakur publishers, Ludhiana, Punjab</li>
  <li><b>Geeta Aggarwal</b> and S. L. Harikumar. Book on Industrial training for B. Pharm. and M. Pharm. students. 2015 & 2016</li>
  <li>Ganesan V. and <b>Geeta Aggarwal</b>. Physical Pharmaceutics-I. B. Pharm. 3rd semester as per PCI syllabus. Edition 2018. ISBN - 9789387880719. Thakur publication Pvt. Ltd., Lukhnow.</li>
  <li>Foziyah Zakir and <b>Geeta Aggarwal</b>. Lab manual of Pharmaceutical Engineering (B. Pharm. 3rd semester) as per PCI norms. SR Health Sciences Pvt. Ltd., New Delhi. ISBN: 978-93-93221-02-5, Edn 2022</li>
</ul>


          </div>:null
      }
    </div>
  );
};

export default ProfGeetaAggarwal;
