import React, { useState } from "react";

import profimg from "./IMG_6334-scaled-e1685079586344.jpg";

const ProfRajivTonk = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Prof. Rajiv Tonk</h2>
      <h3 className="facult-designation-for-each-container">
        Dean Student Welfare & Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           rajiv [dot] tonk [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=uf-rC1wAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=54685351100">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-0842-5121">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Ph.D. (Pharmaceutical Chemistry)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Designing, synthesis, and characterization of new chemical entities
        incorporating heterocyclic scaffolds as potential anti-inflammatory
        (COX-1, COX-2 & ITK inhibitors), Anti-Alzheimer & Anticancer (ALK &
        PD-L1 Inhibitors) agents. Development and validation of analytical
        methods for drugs and drug formulations.
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Research Projects</p>
                </td>
                <td>
                  <p>Grants</p>
                </td>
                <td>
                  <p>Collaboration</p>
                </td>
                <td>
                  <p>Status</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Potential Role of metformin in Cognitive and Affective
                    Abnormalities
                  </p>
                  <p>Associated with Diabetic-Obese Mice</p>
                </td>
                <td>
                  <p>AICTE sponsored RPS grant</p>
                </td>
                <td>
                  <p>Department of</p>
                  <p>Pharmacology, SPS</p>
                  <p>DPSRU</p>
                </td>
                <td>
                  <p>On-going</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Public Engagement on Health in India</p>
                </td>
                <td>
                  <p>Bill Gates foundation</p>
                </td>
                <td>
                  <p>IIT, Delhi</p>
                </td>
                <td>
                  <p>Completed</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ol>
            <li>
              <b> </b>Verma S, <b>Tonk RK</b>, Albratty M, Alhazmi HA, Najmi A,
              Kumar R, Kumar M, Taleuzzaman M, Swami G, Alam, MS. Design and
              evaluation of sustained release mucoadhesive film of sumatriptan
              succinate containing grafted co-polymer as the platform,{" "}
              <i>Saudi Pharmaceutical Journal </i>(2022), doi:
              https://doi.org/10.1016/j.jsps.2022.07.014 <b>IF 4.56</b>
            </li>
            <li>
              <b> </b>Munjal Y, <b>Tonk RK</b>, Sharma R. Analytical Techniques
              Used in Metabolomics: A Review. Sys Rev Pharm 2022; 13(5):
              550-556.
            </li>
            <li>
              <b> </b>Mittal L, <b>Tonk R</b>, Awasthi A, Asthana S. Traversing
              through the Dynamic Protein–Protein Interaction Landscape and
              Conformational Plasticity of PD-1 for Small-Molecule Discovery.
              Journal of Medicinal Chemistry. 2022; 65(8): 5941-5953.{" "}
              <b>IF 7.5</b>
            </li>
            <li>
              <b> </b>Gaur A and <b>Tonk R</b>. Covid-19 pandemic and
              digitalization of healthcare among youth: a marketing perspective.
              EPRA International Journal of Economics, Business and Management
              Studies (EBMS). 2021; 8(8): 52-60.
            </li>
            <li>
              <b> </b>Mittal L, <b>Tonk RK</b>, Awasthi, Asthana S. Targeting
              cryptic-orthosteric site of PD-L1 for inhibitor identification
              using structure-guided approach, Archives of Biochemistry and
              Biophysics. 2021; 713: 109059.
            </li>
            <li>
              <b> </b>Sharma P, <b>Tonk RK</b>, Malik M, Chaudhary V, Sharma S,
              Thukral N. The Effect of Space Microgravity on Knee Joint and its
              Rehabilitation. Turkish Journal of Physiotherapy and
              Rehabilitation. 2021; 32(3): 23101-23106.
            </li>
            <li>
              <b> </b>Kumar S, <b>Tonk RK</b>, Sharma PC. Some Quality Control
              Analysis Parameters for Parenteral Formulations. International
              Journal of Trend in Scientific Research and Development. 2021;
              5(4): 944-948.
            </li>
            <li>
              <b> </b>Sharma D, Sharma V, Sharma A, Goyal R, <b>Tonk RK</b>,
              Thakur VK and Sharma PC. Green chemistry approaches for thiazole
              containing compounds as a potential scaffold for cancer therapy.
              Sustainable Chemistry and Pharmacy. 2021; 23:100496.
            </li>
            <li>
              <b> </b>Singh G, Pathania R, Khan M, <b>Tonk RK</b>, Kumar D and
              Dash AK. Identification and quantification of some natural
              compounds of Pinus gerardiana leaf extracts and its antimicrobial
              and antioxidant activities. Archives, 2021; Vol.2: 333-351.
            </li>
            <li>
              <b> </b>Mittal L, Srivastava M, Kumari A, <b>Tonk RK</b>, Awasthi
              A and Asthana S. Interplay among Structural Stability, Plasticity,
              and Energetics Determined by Conformational Attuning of Flexible
              Loops in PD-1. Journal of Chemical Information and Modeling. 2021;
              61(1): 358– 384.
            </li>
            <li>
              <b> </b>Yadav V, Reang J, Sharma V, Majeed J, Jambulingam S, Goyal
              R, <b>Tonk RK. </b>Repositioning of Drugs as a promising strategy
              to fight COVID-19. Coronaviruses. 2021; 2: 1-15.
            </li>
            <li>
              <b> Tonk RK</b>, Yadav V, Hasan M. Efficient One-Pot Synthesis of
              3-Substituted Phthalides via Additive Arylation of Organozinc
              Intermediate. Letters in Org Chem, 2021;18: 1-7.{" "}
              <b>IF 0.7 13. </b>Goyal RK, Majeed J, <b>Tonk R</b>, Dhobi M,
              Patel B, Sharma K, Apparsundaram S. Current targets and drug
              candidates for prevention and treatment of SARS-CoV-2 (COVID-19)
              infection.
            </li>

            <p>
              Reviews in Cardiovascular Medicine 2020, 21(3): 365-384.{" "}
              <b>IF 0.8</b>
            </p>

            <li>
              <b> </b>Singh RB; Fedacko Jan; Goyal RK; Rai RH; Nandave M;{" "}
              <b>Tonk RK </b>et al. Pathophysiology and Significance of Troponin
              T in Heart Failure, with Reference to Behavioral Risk Factors.
              World Heart Journal; Hauppauge 2020, 12 (1): 15-21.
            </li>
            <li>
              <b> </b>Vivek Yadav, <b>Rajiv Kumar Tonk</b>, Ramchander Khatri;
              Molecular Docking, 3D-QSAR, Fingerprint-Based 2D-QSAR Analysis of
              Pyrimidine Analogs of ALK (Anaplastic Lymphoma Kinase) Inhibitors
              as an Anticancer Agent. Letters in Drug Design; Discovery; DOI:
              10.2174/1570180817999201123163617.
            </li>
            <li>
              <b> </b>Saini N, Sharma A, Thakur VK, Makatsoris H, Dandia A,
              Bhagat M, <b>Tonk RK </b>and Sharma PC. Microwave Assisted Green
              synthesis of Thiazolidin-4-One derivatives: A Perspective on
              Potent Antiviral and Antimicrobial Activities, Current Research in
              Green and Sustainable Chemistry. 2020; 3: 100021-26.
              https://doi.org/10.1016/j.crgsc.2020.10002. <b>17. </b>Shikha
              Goswami, Ozaifa Kareem, Ramesh K Goyal, Sayed M Mumtaz, Rajiv K
              Tonk, Rahul Gupta, Faheem H Pottoo. Role of Forkhead Transcription
              Factors of the O Class (FoxO) in Development and Progression of
              Alzheimer’s disease; CNS Neurol Disord Drug Targets. 2020;
              19(9):709-721. doi: 10.2174/1871527319666201001105553. <b>18. </b>
              Mumtaz SM, Bhardwaj G, <b>Tonk RK</b>, Goyal RK, Pottoo FH.
              Management of Glioblastoma Multiforme by Phytochemicals:
              Applications of Nanoparticle Based Targeted Drug Delivery System.{" "}
              <i>Current Drug Targets </i>2020; 21. DOI:
              10.2174/1389450121666200727115454. <b>IF 2.8</b>
            </li>

            <li>
              <b> Tonk RK</b>, Bawa S, Kumar D, Therapeutic Potential of
              Cinnoline Core: A comprehensive review. Mini-Review in Med Chem.
              2020;20(3):196-218. <b>IF 3.0</b>
            </li>
            <li>
              <b> Tonk RK</b>, Sahu K, et al, Method development and validation
              for detection of Procaterol in human urine using gas
              chromatography-Tandem Mass spec. IJPER 2020;54(1): 150-154.{" "}
              <b>IF 0.5</b>
            </li>
            <li>
              <b> R. Tonk</b>, Sharma P, et al, Use of current therapeutic
              devices in the treatment of restless legs syndrome: A review.
              Medico-Legal Update 2020;20(2):140-144.{" "}
              <b>H. Index -08 22. Tonk RK</b>, Biswas D, Patent grand on
              anticancer phytoconstituent in last five years (2014- 2018)-A
              Review. Int J Pharm and Bio Sci, <b>2019</b>, 9(3), 932-940.{" "}
              <b>H. Index- 27 23. </b>A Sharma, <b>R Tonk</b>, V Sharma, Liquid
              chromatography tandem mass spectrometry determination of
              prohibited diuretics and other acidic drugs in human urine: A
              Review, The Pharma Innovation Journal <b>2018</b>; 7(7): 57-61.{" "}
              <b>H. Index- 04</b>
            </li>
            <li>
              <b> </b>Iontophoretic Treatment in Knee Osteoarthritis:
              Non-Pharmacological Therapy. <b>Rajiv Tonk</b>, Parul Sharma. Int
              J of Sci  Engg Res. <b>2018</b>, Volume 9, Issue 7,
              1823-1831, <b>H. Index 04</b>
            </li>
            <li>
              <b> </b>Active stretching of hip flexors increases hip extension
              range of motion more than passive stretching. Jain S, Bhat AM,{" "}
              <b>Tonk RK. </b>
              <i>Int J Dev Res. </i>
              <b>2018</b>; 8 (3): 19654-659. <b>H. Index – 17</b>
            </li>
            <li>
              <b> </b>Amin H, Wakode S, <b>Tonk RK. </b>Synthesis,
              Characterization and Antimicrobial Activity of Morpholine Mannich
              Base Derivatives. Int J Pharm sci  Res. <b>2018</b>; 9 (7):
              2760-2766. <b>IF 1.81</b>
            </li>
            <li>
              <b> </b>Amin H, T<b>onk RK, </b>Synthesis, Characterization and
              Antioxidant Activity of Morpholine Mannich Base Derivatives. Int J
              Curr Pharm Rev and Res. <b>2017</b>; 8 (5): 354-360.{" "}
              <b>H. Index 09</b>
            </li>
            <li>
              <b> </b>Phenothiazines and related drugs as Multi Drug Resistance
              Reversal Agents in cancer chemotherapy mediated by p-glycoprotein.{" "}
              <b>Current Cancer Therapy Reviews</b>, 1: 13 <b>(2017)</b>, 28-
              42. <b>H. Index- 12</b>
            </li>
            <li>
              <b> </b>An overview on Olmesarten. World Journal of Pharmaceutical
              Research, 6:15, <b>(2017)</b>, 423-448
            </li>
            <li>
              <b> </b>Feronia Limonia –A Wonder Drug. H. Amin, Dr. S. Wakode and
              Dr. R. K. Tonk, W.J.P.  Pharma. Sci., 6: 4<b>(2017)</b>,
              1982-1994. <b>IF- 0.63</b>
            </li>
            <li>
              <b> </b>Synthesis and application of Thiobarbituric Acid
              Derivatives as antifungal agents. P. Rathee, <b>R.K.Tonk </b>
              <i>et al</i>,{" "}
              <b>
                <i>Cellular  Molecular Biology</i>
              </b>
              , 62: 141<b>(2016)</b>. <b>IF- 0.60</b>
            </li>
            <li>
              <b> </b>A review on anticancer potential of bioactive heterocycle
              quinoline. Obaid Afzal, Manu Jaggi, <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Eur J Med Chem</i>
              </b>
              , 97 <b>(2015)</b>, 871-910. <b>IF- 5.57</b>
            </li>
            <li>
              <b> </b>Docking based virtual screening and molecular dynamics
              study to identify potential monoacylglycerol lipase inhibitors.
              Obaid Afzal, Sandhya Bawa, Suresh Kumar, <b>Rajiv Kumar </b>
              <i>et al. </i>
              <b>
                <i>Bioorg  Med Chem Lett</i>
              </b>
              , 24 <b>(2014) </b>3986-96. <b>IF – 2.8</b>
            </li>
            <li>
              <b> </b>3D-QSAR study of benzotriazol-1-yl carboxamide scaffold as
              monoacylglycerol lipase inhibitors. Obaid Afzal,{" "}
              <b>Rajiv Kumar, </b>Sandhya Bawa.{" "}
              <b>
                <i>J Pharm  Bioall Sci,</i>
              </b>
              6 (2014), 260. <b>H. Index 27</b>
            </li>
            <li>
              <b> </b>Design, synthesis and evaluation of novel 2-piperidinyl
              quinoline chalcones/amines as potential antidepressants agents.
              Obaid Afzal, sandhya Bawa, Suresh Kumar, <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Lett in Drug Design  Discovery</i>
              </b>
              , 10 <b>(2013)</b>, 75-85. <b>IF – 0.87</b>
            </li>
            <li>
              <b> </b>Child Resistant Packaging: A Prime Concern for Packaging
              of Medicinal Products, Sarika Malhotra, Rajiv K. Arora,{" "}
              <b>Rajiv Tonk</b>.{" "}
              <b>
                <i>Int J Pharmaceu Sci Rev  Res</i>
              </b>
              , 22 <b>(2013) </b>79-88. <b>H. Index 16</b>
            </li>
            <li>
              <b> </b>Pyrazole incorporated 1,2-diazanaphthalene derivatives:
              Synthesis and <i>in-vivo </i>pharmacological screening as
              anti-inflammatory and analgesic agents. <b>Rajiv K. Tonk</b>,
              Sandhya Bawa, Suresh Kumar <i>et al</i>.{" "}
              <b>
                <i>Afr J Pharm  Pharmacol</i>
              </b>
              , 6 <b>(2012)</b>, 425-433. <b>IF – 0.81</b>
            </li>
            <li>
              <b> </b>Synthesis and pharmacological evaluation of pyrazolo[4,3-
              <i>c</i>]cinnoline derivatives as potential anti inflammatory and
              antibacterial agents. <b>Rajiv K. Tonk</b>, Sandhya Bawa, Gita
              Chawla, Suresh Kumar <i>et al</i>.{" "}
              <b>
                <i>Eur J Med Chem</i>
              </b>
              , 57 <b>(2012)</b>, 176-184. <b>IF- 5.57</b>
            </li>
            <li>
              <b> </b>
              N-([2-(Piperidin-1-yl)quinolin-3-yl]methylene)pyridine-4-carbohydrazide.
              Obaid Afzal, Sandhya Bawa, Suresh Kumar and <b>Rajiv K. Tonk. </b>
              <b>
                <i>Molbank, </i>
              </b>
              M748, <b>(2012)</b>. <b>IF- 0.25</b>
            </li>
            <li>
              <b> </b>1-(8-Chloro-3-methyl-1<i>H</i>-pyrazolo[4,3-<i>c</i>
              ]cinnolin-1-yl)-2-(2-chlorophenyl)ethanone. S. Bawa,{" "}
              <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Molbank, </i>
              </b>
              M744, (<b>2011)</b>. doi:10.3390/M744. <b>IF- 0.5</b>
            </li>
            <li>
              <b> </b>Synthesis, antidepressant  antifungal evaluation of
              novel 2-chloro-8-methylquinoline amine derivatives. S. Kumar,{" "}
              <b>R. Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Eur J Med Chem</i>
              </b>
              , 46 <b>(2011), </b>670-75. I<b>F – 5.57 42. </b>Antidepressant
              potential of nitrogen-containing heterocyclic moieties: An updated
              review. S. Bawa, <b>Rajiv Kumar </b>
              <i>et al. </i>
              <b>
                <i>J Pharm  Bioall Sci, </i>
              </b>
              2 (<b>2011</b>), 194-212. <b>H. Index 27 43. </b>
              (8-Chloro-3-methyl-1<i>H</i>-pyrazolo[4,3-<i>c</i>]cinnolin-1-yl)
              (pyridin-4-yl) methanone. S. Bawa, <b>Rajiv Kumar</b>, G. Chawla,
              S. Kumar and R. Mishra.{" "}
              <b>
                <i>Molbank, </i>
              </b>
              M688, <b>(2010)</b>. <b>IF- 0.5 44. </b>Synthesis and{" "}
              <i>in-vivo </i>anticonvulsant evaluation of
              2-chloroquinolinyl–hydrazone Derivatives. Suresh Kumar, Sandhya
              Bawa, Sushma Drabu, <b>Rajiv Kumar</b>, Lalit Machawal.{" "}
              <b>
                <i>Acta Pol Pharmaceu-Drug Res</i>
              </b>
              , 67 <b>(2010)</b>, 567-573. <b>IF – 0.87</b>
            </li>
            <li>
              <b> </b>New 2-chloro-7-methylquinoline amine analogues as possible
              antimycotic agents. Sandhya Bawa, <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Lat Amer J Pharm, </i>
              </b>
              29 <b>(2010)</b>, 968-975. <b>IF- 0.40</b>
            </li>
            <li>
              <b> </b>6-Oxo-3-phenyl-5,6-dihydropyridazine-1(4<i>H</i>
              )-carbohydrazide. Ravinesh Mishra, Anees Ahmed Siddiqui,{" "}
              <b>Rajiv Kumar </b>and Suresh Kumar. Short note.{" "}
              <b>
                <i>Molbank. </i>
              </b>
              M652, (<b>2010)</b>. <b>IF- 0.5 47. </b>Structural modifications
              of quinoline- based antimalarial agents: recent developments.
              Sandhya Bawa, Suresh Kumar, <b>Rajiv Kumar</b>.{" "}
              <b>
                <i>J Pharm  Bioall Sci, </i>
              </b>
              2 <b>(2010)</b>, 64-71. <b>H. Index 27 48. </b>Career
              opportunities in pharmacy profession: An informative note. Sandhya
              Bawa, <b>Rajiv K. Tonk</b>, Jyoti A. Tonk, Suresh Kumar, Sumit
              Singla.{" "}
              <b>
                <i>Chronicles of Young Scientists</i>
              </b>
              , 1 <b>(2010) </b>7-11. <b>H. Index-08</b>
            </li>
            <li>
              <b> </b>Synthesis, Spectral characterization and pharmacological
              screening of some 4-[(1- (aryl)
              methylidine)-amino]-3-(4-pyridyl)-5-mercapto-4<i>H</i>
              -1,2,4-triazole derivatives. Anees A. Siddiqui,{" "}
              <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Journal of Pharmacy and Bioallied Sciences</i>
              </b>
              . 2 (<b>2010</b>) 109-112. <b>H. Index 27</b>
            </li>
            <li>
              <b> </b>Synthesis and <i>in vitro </i>antimicrobial activity of
              some triazole derivatives. Ravinesh Mishra, <b>Rajiv Kumar</b>,
              Suresh Kumar <i>et al</i>.{" "}
              <b>
                <i>J of the Chilean Chem Soc, </i>
              </b>
              55 <b>(2010)</b>, 283-286. <b>IF – 0.45 51. </b>Synthesis and
              antimicrobial activity of 2-chloro-6-methylquinoline hydrazone
              derivatives. S. Bawa, <b>Rajiv Kumar </b>
              <i>et al. </i>
              <b>
                <i>Journal of Pharmacy and Bioallied Sciences. </i>
              </b>
              1<b>(2009) </b>27-31. <b>H. Index 27 52. </b>Biological Activities
              of Pyrazoline Derivatives -A Recent Development. Sandhya Bawa,{" "}
              <b>Rajiv Kumar </b>
              <i>et al</i>.{" "}
              <b>
                <i>Rec Patents on Anti-Infec Drug Discov</i>
              </b>
              <i>, </i>4 <b>(2009)</b>, 154-163. <b>H. Index 23 53. </b>
              Synthesis and antimicrobial activity of 2-Chloroquinoline
              incorporated pyrazoline derivatives. Sandhya Bawa,{" "}
              <b>Rajiv Kumar </b>
              <i>et al. </i>
              <b>
                <i>J Pharm  Bioall Sci, </i>
              </b>
              1 <b>(2009)</b>, 32-36. <b>H. Index 27</b>
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <span
            style={{ fontSize: "1.2rem" }}
            data-sheets-value='{"1":2,"2":"1.\t“Research  Innovation in Drug Discovery” in National Seminar RISEM-2022 organized by DPGITM, Gurgaon, 11/05/22 to 12/05/22.\n2.\t“Impact of Chemical exposure on health and Environment” in Interdisciplinary FDP on Environmental Sciences and Public Health organized by Kirori Mal College, DU, 04/02/22.\n3.\t“Effective Pedagogy for better Learning of Chemistry” in Webinar 43 PGT Chemistry: 19th Jan’22 organized by Army Welfare Education Society (AWES), Shankar Vihar, New Delhi.\n4.\t“Career Opportunities in Pharmacy Profession” in Virtual orientation program at Gurugram Global College of Pharmacy 14th Oct.’2021.\n5.\t“ABC of Self-Management for Pharmacist” Four weeks online short-term training program on Drug store management at Maharaj Agrasen University, Solan, HP, 26 July-20 August, 2021. \n6.\t“Holistic Development by Understanding The Universal Human Values” 2nd FIDP-2020, on “Pedagogy, Research,  Administrative Tools” at DPSRU, August 21-27, 2020 \n7.\t“Medication errors in pharmacovigilance and its impact on public health” 16/11/2020 at the national level conference organized by Deshbandhu college, Delhi University.\n8.\t“Intro.  Importance of Chemistry Subjects in Pharmacy” 23/8/19 at CBS COP, FBD. \n9.\t\"Pharmacist: A life to turn difficulties in to opportunities” 13/2/19 at D.V. COP, Tigaon, FBD.\n10.\t\"Pharmacy: A reputed profession with social Impact\" 22/2/18 at D.V. COP, Tigaon, FBD.\n11  Scientific workshop on “HANDS ON WORKSHOP ON MOLECULAR BIOLOGY TECHNIQUES  DNA FINGERPRINTING ANALYSIS METHOD” at SDCOP on 10th May 2022.\n12.   Online AICTE sponsored ATAL FDP on Drug Engineering at DPSRU on 2nd July 2021.\n13.  AICTE sponsored XXXV-QIP on “Frontiers in Pharm. Edu  Research: Emerging Trends in Phytopharmaceuticals” at DIPSAR, 9th to 16th October, 2020.\n14.  International conference on cardiovascular Sciences at DPSRU during 21-23 Feb. 2020."}'
            data-sheets-userformat='{"2":513,"3":{"1":0},"12":0}'
          >
            1. “Research  Innovation in Drug Discovery” in National Seminar
            RISEM-2022 organized by DPGITM, Gurgaon, 11/05/22 to 12/05/22.
            <br />
            <br />
            2. “Impact of Chemical exposure on health and Environment” in
            Interdisciplinary FDP on Environmental Sciences and Public Health
            organized by Kirori Mal College, DU, 04/02/22.
            <br />
            <br />
            3. “Effective Pedagogy for better Learning of Chemistry” in Webinar
            43 PGT Chemistry: 19th Jan’22 organized by Army Welfare Education
            Society (AWES), Shankar Vihar, New Delhi.
            <br />
            <br />
            4. “Career Opportunities in Pharmacy Profession” in Virtual
            orientation program at Gurugram Global College of Pharmacy 14th
            Oct.’2021.
            <br />
            <br />
            5. “ABC of Self-Management for Pharmacist” Four weeks online
            short-term training program on Drug store management at Maharaj
            Agrasen University, Solan, HP, 26 July-20 August, 2021. <br />
            <br />
            6. “Holistic Development by Understanding The Universal Human
            Values” 2nd FIDP-2020, on “Pedagogy, Research,  Administrative
            Tools” at DPSRU, August 21-27, 2020 <br />
            <br />
            7. “Medication errors in pharmacovigilance and its impact on public
            health” 16/11/2020 at the national level conference organized by
            Deshbandhu college, Delhi University.
            <br />
            <br />
            8. “Intro.  Importance of Chemistry Subjects in Pharmacy”
            23/8/19 at CBS COP, FBD. <br />
            <br />
            9. “Pharmacist: A life to turn difficulties in to opportunities”
            13/2/19 at D.V. COP, Tigaon, FBD.
            <br />
            <br />
            10. “Pharmacy: A reputed profession with social Impact” 22/2/18 at
            D.V. COP, Tigaon, FBD.
            <br />
            <br />
            11 Scientific workshop on “HANDS ON WORKSHOP ON MOLECULAR BIOLOGY
            TECHNIQUES  DNA FINGERPRINTING ANALYSIS METHOD” at SDCOP on
            10th May 2022.
            <br />
            <br />
            12. Online AICTE sponsored ATAL FDP on Drug Engineering at DPSRU on
            2nd July 2021.
            <br />
            <br />
            13. AICTE sponsored XXXV-QIP on “Frontiers in Pharm. Edu 
            Research: Emerging Trends in Phytopharmaceuticals” at DIPSAR, 9th to
            16th October, 2020.
            <br />
            <br />
            14. International conference on cardiovascular Sciences at DPSRU
            during 21-23 Feb. 2020.{" "}
          </span>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <br/> 🗐 Received Three notable research awards and Cash prize of Rs.
            4028/- during 1st Research Promotion award-2021 ceremony at DPSRU on
            05-04-2022. <br/> 🗐 Received appreciation award from Mrs. Seema Trikha,
            Chairperson (Haryana) Education, Haryana Medical Education
             Health services Committee (MLA, BADKHAL 87) on
            5-9-2020.
          </p>
          <p>
            <br/> 🗐 Received Appreciation award from Deputy chief minister Delhi Shri
            Manish Sisodia Ji, for excellent academic and administrative
            work during session 2019- 20.
          </p>
          <p>
            <br/> 🗐 Received appreciation award by The Airports Authority of India
            (AAI) and Central Vigilance Commission (CVC) during
            celebration of Vigilance Awareness Week. <br/> 🗐 Received appreciation
            certificate from Hon’ble VC, DPSRU, for improvement in online
            admissions.
          </p>
          <p>
            <br/> 🗐 Received certificate of appreciation from Hon’ble VC, DPSRU, for
            outstanding services rendered related to students Affairs,
            B.P.T. computer sanction follow up on 06/04/17{" "}
            <b>(2016-17).</b>
          </p>
          <p>
            <br/> 🗐 CSIR sanctioned foreign travel grant to present paper at the{" "}
            <b>Structural  Computational Chemistry 2012, Germany </b>
            during 13-14th March 2012. <br/> 🗐 Received{" "}
            <b>certificate of recognition  cash prize of Rs. 5000/- </b>for
            Ph. D research work and its publication in high impact factor
            journal (EJMC) published in the year 2012.
          </p>
          <p>
            <br/> 🗐 Got <b>1</b>
            <b>st </b>
            <b>prize in oral paper presentation </b>in AICTE sponsored National
            Seminar at MSIP, New Delhi-58, (19-20th August 2011).
          </p>
          <p>
            <br/> 🗐 Got <b>1</b>
            <b>st </b>
            <b>prize in Poster presentation </b>in National Seminar on “Emerging
            Trends in Pharma. Sciences” at F/O Pharmacy, Jamia Hamdard,
            New Delhi, (21-22nd March 2009).
          </p>
          <p>
            <br/> 🗐 Got <b>1</b>
            <b>st </b>
            <b>prize in Group discussion </b>on “Nuclear Weapons: A Boon or A
            Bane” held on 6th Aug. 2012 at BMU, Rohtak,
            Haryana
          </p>
          <p>
            <br/> 🗐 Selected for the award of UGC-RGN Junior  Senior Research
            Fellows during (2008- 2012)
          </p>
          <p>
            <br/> 🗐 Selected for the award of AICTE-GATE fellowship during M. Pharmacy
            (2005-2007).
            </p>
            <p> <br/> 🗐 Got <b>2</b>
            <b>nd </b>
            <b>Position in 100 meters race </b>during annual sports meet, 22nd
            Feb’2019 at DPSRU
            </p>
        
          <p>
            <br/> 🗐 Got <b>2</b>
            <b>nd </b>
            <b>Position in 100 meters race </b>during annual sports meet, 17th
            Feb’2018 at DPSRU.
          </p>
          <p>
            <br/> 🗐 Got <b>3</b>
            <b>rd </b>
            <b>Position in 50 meters race </b>during annual sports meet, 23rd
            Feb’2017 at DPSRU.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ProfRajivTonk;
