import React, { useState } from "react";

import profimg from "./Prof.-P.K-Sahoo-scaled-e1686903307416.jpg";

const ProfPKSahoo = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Prof. PK Sahoo</h2>
      <h3 className="facult-designation-for-each-container">
        Director, DIPSAR{" "}
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              director [dot] dipsar [at] gamil [dot] com
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li> <a href="https://scholar.google.com/citations?hl=en&user=VU3S23AAAAAJ">Google Scholar </a></li>
            <li> <a>Scopus</a></li>
            <li>
              <a href="https://orcid.org/0000-0003-2220-9617">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M.Pharm., Ph.D,MBA (Xavier Institute of Management),Chartered Management
        Institute London-CMI- Level 5
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Development of formulations (niosome, intranasal bypass DDS,
        niosomalantiacne gel, emulgel, transungual drug delivery). Gold
        nanoparticles based supramolecular architecture for non-Hodgkin lymphoma
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  Recent trends in the developments of orally disintegrating
                  tablet technology. S Kundu, PK Sahoo. Pharma Times 40 (4),
                  11-15, 2008
                </td>
              </tr>
              <tr>
                <td>
                  Treatment and management strategies of onychomycosis. R
                  Aggarwal, M Targhotra, B Kumar, PK Sahoo, MK Chauhan. Journal
                  de Mycologie Médicale 30 (2), 100949, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Effects of conservation agriculture on crop productivity and
                  water-use efficiency under an irrigated pigeonpea–wheat
                  cropping system in the western Indo-Gangetic Plains. TK Das,
                  KK Bandyopadhyay, R Bhattacharyya, S Sudhishri, AR Sharma. The
                  Journal of Agricultural Science 154 (8), 1327-1342, 2016
                </td>
              </tr>
              <tr>
                <td>
                  Recent advances and prospects in gemcitabine drug delivery
                  systems. S Paroha, J Verma, RD Dubey, RP Dewangan, N Molugulu,
                  RA Bapat. International Journal of Pharmaceutics 592, 120043,
                  2021
                </td>
              </tr>
              <tr>
                <td>
                  Overview of biopolymers as carriers of antiphlogistic agents
                  for treatment of diverse ocular inflammations. AK Sharma, A
                  Arya, PK Sahoo, DK Majumdar. Materials Science and
                  Engineering: C 67, 779-791, 2016.
                </td>
              </tr>
              <tr>
                <td>
                  Topical delivery of acetazolamide by encapsulating in
                  mucoadhesive nanoparticles. S Manchanda, PK Sahoo. Asian
                  journal of pharmaceutical sciences 12 (6), 550-557, 2017
                </td>
              </tr>
              <tr>
                <td>
                  Fabrication and evaluation of lipid nanoparticulates for
                  ocular delivery of a COX-2 inhibitor. AK Sharma, PK Sahoo, DK
                  Majumdar, N Sharma, RK Sharma, A Kumar. Drug delivery 23 (9),
                  3364-3373, 2016
                </td>
              </tr>
              <tr>
                <td>
                  Liposomes: novel drug delivery approach for targeting
                  Parkinson’s disease. B Kumar, M Pandey, FH Pottoo, F Fayaz, A
                  Sharma, PK Sahoo. Current Pharmaceutical Design 26 (37),
                  4721-4737, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Onychomycosis: Novel strategies for treatment. R Aggarwal, M
                  Targhotra, PK Sahoo, MK Chauhan. Journal of Drug Delivery
                  Science and Technology 57, 101774, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Fabrication and characterization of mucoadhesive topical
                  nanoformulations of dorzolamide HCl for ocular hypertension. S
                  Manchanda, PK Sahoo. Journal of Pharmaceutical Investigation
                  48 (3), 323-332, 2018
                </td>
              </tr>
              <tr>
                <td>
                  Formulation and in vitro evaluation of bi-layer tablet of
                  cyclobenzaprine hydrochloride ER and diclofenac potassium IR-A
                  novel fixed dose combination. V Jamunadhevi, PK Sahoo, P
                  Kailasam. Int J Res Pharm Sci 2 (2), 170-8, 2011
                </td>
              </tr>
              <tr>
                <td>
                  Evaluation of subgrade soils using dynamic cone penetrometer.
                  PK Sahoo, KS Reddy. International Journal of Earth Sciences
                  and Engineering 2 (4), 384-388, 2009.&nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  Mucoadhesive chitosan-dextran sulfate nanoparticles of
                  acetazolamide for ocular hypertension. S Manchanda, PK Sahoo,
                  DK Majumdar. Nanotechnology Reviews 5 (5), 445-453, 2016
                </td>
              </tr>
              <tr>
                <td>
                  Topical ocular delivery of a COX-II inhibitor via
                  biodegradable nanoparticles. AK Sharma, PK Sahoo, DK Majumdar,
                  AK Panda. Nanotechnology Reviews 5 (5), 435-444, 2016
                </td>
              </tr>
              <tr>
                <td>
                  Carbon Nano Tubes: Novel drug delivery system in amelioration
                  of Alzheimer’s disease. J Mishra, B Kumar, M Pandey, FH
                  Pottoo, F Fayaz, FA Khan, PK Sahoo. Combinatorial Chemistry
                  &amp; High Throughput Screening 24 (10), 1528-1543, 2021.
                </td>
              </tr>
              <tr>
                <td>
                  Conventional and nanomaterial-based techniques to increase the
                  bioavailability of therapeutic natural products: a review. S
                  Paroha, RP Dewangan, RD Dubey, PK Sahoo. Environmental
                  Chemistry Letters 18 (6), 1767-1778, 2020.
                </td>
              </tr>
              <tr>
                <td>
                  Polyplex: a promising gene delivery system. R Aggarwal, M
                  Targhotra, B Kumar, PK Sahoo, MK Chauhan. International
                  Journal of Pharmaceutical Sciences and Nanotechnology 12 (6),
                  2019
                </td>
              </tr>
              <tr>
                <td>
                  Efinaconazole nail lacquer for the transungual drug delivery:
                  Formulation, optimization, characterization and in vitro
                  evaluation. R Aggarwal, M Targhotra, PK Sahoo, MK Chauhan,
                  2020. Journal of Drug Delivery Science and Technology 60,
                  101998
                </td>
              </tr>
              <tr>
                <td>
                  Ribociclib nanostructured lipid carrier aimed for breast
                  cancer: formulation optimization, attenuating in vitro
                  specification, and in vivo scrutinization. A Sartaj, L Biswas,
                  AK Verma, PK Sahoo, S Baboota, J Ali. BioMed Research
                  International 2022
                </td>
              </tr>
              <tr>
                <td>
                  A green chemistry approach towards synthesizing hydrogel for
                  sustained ocular delivery of brinzolamide: In vitro and ex
                  vivo evaluation. CM Chakole, PK Sahoo, J Pandey, MK Chauhan.
                  Journal of the Indian Chemical Society 99 (2), 100323, 2022
                </td>
              </tr>
              <tr>
                <td>
                  Lipid nanocarrier of selegiline augmented anti-Parkinson’s
                  effect via P-gp modulation using quercetin. Z Qamar, MU
                  Ashhar, FF Qizilibash, PK Sahoo, A Ali, J Ali, S Baboota.
                  International Journal of Pharmaceutics 609, 121131, 2021
                </td>
              </tr>
              <tr>
                <td>
                  Novel Polypseudorotaxanes Hydrogel based Nail Lacquer of
                  Efinaconazole for Transungual Drug Delivery. R Aggarwal, M
                  Targhotra, B Kumar, PK Sahoo, MK Chauhan. Drug Delivery
                  Letters 11 (1), 52-61, 2021
                </td>
              </tr>
              <tr>
                <td>
                  Applications of exosomes in targeted drug delivery for the
                  treatment of Parkinson’s disease: a review of recent advances
                  and clinical challenges. B Kumar, M Pandey, F Fayaz, TA
                  Izneid, FH Pottoo, S Manchanda. Current Topics in Medicinal
                  Chemistry 20 (30), 2777-2788, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Pharmaceutical technology for improving the bioavailability of
                  natural products. S Paroha, RP Dewangan, PK Sahoo. Sustainable
                  Agriculture Reviews 43, 1-32, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Curcumin loaded ethosomal gel for improved topical delivery:
                  formulation, characterization and ex-vivo studies. B Kumar, PK
                  Sahoo, S Manchanda. Pharmaceutical Nanotechnology 9 (4),
                  281-287, 2021
                </td>
              </tr>
              <tr>
                <td>
                  Modelling of water loss and solute uptake during osmotic
                  drying of carrots using weibull distribution approach. PK
                  Sahoo, AK Sharma. International Food Research Journal 25 (1),
                  2018
                </td>
              </tr>
              <tr>
                <td>
                  Effect of formulation factors on transcorneal permeation of
                  acetazolamide from aqueous drops: in vitro and in vivo study.
                  S Manchanda, PK Sahoo, DK Majumdar. Asian J Pharm 10, 177-182,
                  2016
                </td>
              </tr>
              <tr>
                <td>
                  Farming system-based composite pisciculture in harvested pond
                  water under eastern Indian situations. UK Behera, SK Rautaray,
                  PK Sahoo. Journal of Soil and Water Conservation 9, 143-147,
                  2010
                </td>
              </tr>
              <tr>
                <td>
                  Fabrication and testing of sodium-ion full cell with P2-Na0.
                  67 Ni0. 167Co0. 167 Mn0. 67O2 (Na-NCM) and hard carbon in coin
                  cell and 2 Ah prismatic cell configuration. M Senthilkumar, T
                  Satyavani, K Jagadish, PK Sahoo, AS Kumar. International
                  Journal of Hydrogen Energy 47 (3), 1790-1803, 2022
                </td>
              </tr>
              <tr>
                <td>
                  An Overview of Second-Generation Nanoparticles− Nano-structure
                  Lipid Carrier Drug Delivery System. PK Sahoo, SL Neha, A
                  Pannu. International Journal of Pharmaceutical Sciences and
                  Nanotechnology 13 (6), 2020
                </td>
              </tr>
              <tr>
                <td>
                  Energy use in production agriculture. PK Sahoo, UK Behera.
                  Resource conserving techniques in crop production, 397-414,
                  2017
                </td>
              </tr>
              <tr>
                <td>
                  Nanoparticle mediated gene therapy: a trailblazer armament to
                  fight CNS disorders. S Rehman, B Nabi, A Sartaj, S Md, PK
                  Sahoo, S Baboota, J Ali. Current Medicinal Chemistry 30 (3),
                  304-315, 2023
                </td>
              </tr>
              <tr>
                <td>
                  Formulation, characterization and ex vivo study of curcumin
                  nano-invasomal gel for enhanced transdermal delivery. B Kumar,
                  PK Sahoo, S Manchanda. OpenNano 7, 100058, 2022
                </td>
              </tr>
              <tr>
                <td>
                  Amelioration of oxidative stress utilizing nanoemulsion loaded
                  with bromocriptine and glutathione for the management of
                  Parkinson’s disease. MU Ashhar, P Vyas, D Vohora, PK Sahoo, K
                  Nigam, S Dang, J Ali. International Journal of Pharmaceutics
                  618, 121683, 2022
                </td>
              </tr>
              <tr>
                <td>
                  Advanced and futuristic approaches for breast cancer
                  diagnosis. J Mishra, B Kumar, M Targhotra, PK Sahoo. Future
                  Journal of Pharmaceutical Sciences 6 (1), 1-8, 2020
                </td>
              </tr>
              <tr>
                <td>
                  Novel Topical Drug Delivery Approaches for the Management of
                  Psoriasis. B Kumar, M Targhotra, R Aggarwal, PK Sahoo.
                  International Journal of Pharmaceutical Sciences and
                  Nanotechnology 13 (5), 2020
                </td>
              </tr>
              <tr>
                <td>
                  Dorzolamide in situ Gel Forming System: Characterization and
                  Evaluation for Glaucoma Treatment. P Kataria, R Katara, PK
                  Sahoo, S Sachdeva. Madridge J. Pharm. Res. 1, 13-21, 2017
                </td>
              </tr>
              <tr>
                <td>
                  Feasibility of biodiesel production from Chlorella vulgaris
                  grown in flat plate photobioreactor under outdoor conditions.
                  AK Sharma, PK Sahoo, S Shailey. International Journal of
                  ChemTech Research 8 (6), 671-678, 2015
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
            <li>APTI President, Delhi State Branch of APTI 2008-11,2022-27</li>
            <li>
              As APTI President conducted Northern Zone conference for
              Development of Herbal Monograph.in collaboration with NMPB 2009.
            </li>
            <li>Best Professor award 2019 by Debang Meheta Foundation</li>
            <li>Executive committee Member IPA Delhi</li>
            <li>Member BOG DPSRU and MemberGeneral Council DPSRU</li>
            <li>Member BOG Sanskar Group Inst, Member BoG ABESIT</li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default ProfPKSahoo;
