import React from "react";
import "./InformationAsMayBePrescribed.css";

const InformationAsMayBePrescribed = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        INFORMATION AS MAY BE PRESCRIBED
      </h2>

      <div className="information-as-be-prescribed-table-container">
        <table width="679">
          <tbody>
            <tr>
              <td width="77">
                <p>
                  <strong>S.No.</strong>
                </p>
              </td>
              <td width="131">
                <strong>Item</strong>
              </td>
              <td width="261">
                <strong>Details of disclosure</strong>
              </td>
              <td width="210">
                <p>
                  <strong>
                    Remarks/
                    <br />
                    Reference Points
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td rowspan="5" width="77">
                5.1
              </td>
              <td rowspan="5" width="131">
                <p>Such other information as may be prescribed</p>
              </td>
              <td width="261">
                <p>
                  (i) Name &amp; details of
                  <br />
                  a. Current CPIOs &amp; FAAs
                </p>
                <p>b. Earlier CPIO &amp; FAAs from1.1.2015</p>
              </td>
              <td width="210">
                <p>
                  <strong>Public Information Officer:&nbsp; </strong>Dr. J.
                  Swaminathan, Assistant Professor
                </p>
                <p>
                  &nbsp;<strong>Assistant Public Information Officer</strong>
                  :Mr. Aditya Kaushik, Section Officer
                </p>
                <p>
                  <strong>First Appellate Authority:
                  Dr. P.K.Sahoo, Offg. Registrar </strong>
                </p>
                
                <p>Earlier FAA:
Prof. Harvinder Popli </p>
<p>
Earlier FAA:
Dr.O.P.Shukla </p> <p>
Earlier APIO: O.P.Bhati
</ p> <p>
Earlier APIO:
Mrs. AnjanaBirudhi </p> <p>
Earlier FAA
Dr. D.P. Pathak</p>
               <p><a href="https://dpsru.edu.in/cpioAndFaa" style={{fontWeight:'bold'}}>https://dpsru.edu.in/cpioAndFaa</a></p>
              </td>
            </tr>
            <tr>
              <td width="261">
                <p>
                  (ii) Details of third party audit of voluntary disclosure
                  <br />
                  a. Dates of audit carried out
                  <br />
                  b. Report of the audit carried out
                </p>
              </td>
              <td width="210">
                <p><b>June - August 2024</b></p>
              </td>
            </tr>
            <tr>
              <td width="261">
                <p>
                  (iii) Appointment of Nodal Officers not below the rank of
                  Joint Secretary/ Additional HoD
                  <br />
                  a. Date of appointment
                  <br />
                  b. Name &amp; Designation of the officers
                </p>
              </td>
              <td width="210">
             <b> Dr. J. Swaminathan,</b> <br />
Nodal Officer, RTI Cell
Delhi Pharmaceutical Sciences and Research
University (DPSRU/DIPSAR),Govt. of NCT of
Delhi <br />
a. 2017-2024 <br />
b.  <a href="https://dpsru.edu.in/static/media/Transf
er%20Order.582cd1a332f5de5c9ede.pdf"><b> https://dpsru.edu.in/static/media/Transf
er%20Order.582cd1a332f5de5c9ede.pdf </b></a>

               
              </td>
            </tr>
            <tr>
              <td width="261">
                <p>
                  (iv) Consultancy committee of key stake holders for advice on
                  suo-moto disclosure
                  <br />
                  a. Dates from which constituted
                  <br />
                  b. Name &amp; Designation of the officers
                </p>
              </td>
              <td width="210">
                <p>Details available in website</p>
                <p>
                  Click Here: <a href="https://dpsru.edu.in" ><b>dpsru.edu.in</b></a> <br />
                  <span>
                    <strong>
                    <a href="https://dpsru.edu.in/static/media/Tran
sfer%20Order.582cd1a332f5de5c9ede
.pdf">
                    https://dpsru.edu.in/static/media/Tran
sfer%20Order.582cd1a332f5de5c9ede
.pdf
                      </a>
                    </strong>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td width="261">
                <p>
                  (v) Committee of PIOs/FAAs with rich experience in RTI to
                  identify frequently sought information under RTI
                  <br />
                  a. Dates from which constituted
                  <br />
                  b. Name &amp; Designation of the Officers
                </p>
              </td>
              <td width="210"> 
                <p><a href="https://dpsru.edu.in/cpioAndFaa">https://dpsru.edu.in/cpioAndFaa</a></p> <br />

                  <strong>2017 onwards</strong> <br />
                
                
                
                 <a href="https://dpsru.edu.in/static/media/Transfer%20Order.582cd1a332f5de5c9ede.pdf">
                        https://dpsru.edu.in/static/media/Transfer%20Order.582cd1a332f5de5c9ede.pdf
                      </a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default InformationAsMayBePrescribed;
