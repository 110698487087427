import React, { useState } from "react";

import profimg from "./WhatsApp-Image-2023-01-30-at-2.49.24-PM-150x150.jpeg";

const MsSuhasiniD = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
 
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Ms. Suhasini D</h2>
      <h3 className="facult-designation-for-each-container">
      Nurse
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
            d_hasini [at] yahoo [dot] com
          
          </h4>
        </div>
        
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      B.sc (hons) Nursing
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Teaching Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      3 Years 
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Industry Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      6 Years
      </p>

     

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
      Certificates
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
            <ol><li>AIIMS TRAUMA ASSESSMENT AND MANAGEMENT -2018</li><li>AIIMS TRIAGE PROTOCOL -2019</li><li>UPSKILLING FOR NURSES ON COVID-19</li><li>EMPOWERING CORONA WARRIORS -STRENGTHENING RESPONSE TASKFORCE</li><li>REFRESHER COURSE IN FIRST AID</li><li>HEPATITIS UPDATE PROGRAM-2020</li></ol>
               </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Awards
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
       <p>Awarded  as Corona Warrior on 15 th August 2020 by Honorable Vice Chancellor DPSRU.</p>
        </div>
      ) : null}

    </div>
  );
};

export default MsSuhasiniD;
