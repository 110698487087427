import React, { useState } from "react";

import profimg from "./IMG_0080-scaled-e1686895037100.jpg";

const DrSubodhKumar = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] = useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Subodh Kumar</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              subodh [dot] kumar [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com.sg/citations?user=RbxOW3kAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a>Scopus</a>
            </li>
            <li>
              <a href="https://www.scopus.com/results/authorNamesList.uri?sort=count-f&src=al&orcidId=0000-0001-5515-9287">
                ORCID URL
              </a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M.Sc., Ph.D (BHU), Post Doc (NTU, Singapore)
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
        Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Post PhD, 8 Years of Research and 2.5 Years of Teaching
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research Area/ Project
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <p>
            Parasitology, Biomarker discovery, Molecular and Cellular
            Immunology, Metabolic Disorders, Health Policy Research, Analytical
            method development of biomolecule
          </p>{" "}
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">


          
          <p>
            <strong>J 13: </strong>
            Das K, Paltani M, Tripathi PK, Kumar R, Verma S, Kumar S, Jain C*. et al. Current implications and challenges of
artificial intelligence technologies in therapeutic intervention of colorectal cancer. Explor Target Antitumor Ther.
2023;4:1286–300. <a href="https://doi.org/10.37349/etat.2023.00197">https://doi.org/10.37349/etat.2023.00197</a>
          </p>
          <p>
            <strong>J 12: </strong>
            Sharma R, Patil C, Majeed J,  <strong>Kumar S*</strong>, Aggarwal G. Next Generation Sequencing in the biodiversity conservation of endangered medicinal plants. Environ Sci Pollut Res Int. 2022; 29(49):73795 – 73808.
            <strong>(IF 5.19)</strong>
          </p>

          <p>
            <strong>J 11: Kumar S</strong>
            <strong>1*</strong>, Prasad N, Thakur S, Sharma A. Evaluation of
            anti-oxidant property of <em>Acoras calamus </em>l. and{" "}
            <em>Commiphora mukul </em>(hook. ex stocks) engl. for medicinal use.{" "}
            <strong>Adv Biores 2022; 13(2): 200 – 207.(RGIF: 0.36)</strong>
          </p>



          <p>
            <strong>
              J 10: <u>Kumar S</u>
            </strong>
            , Dutta V, Sobti RC. Nanomedicine with reduced drug toxicity
            capacity against Leishmaniasis: Current and Future Prospects.{" "}
            <strong>
              Advanced Science, Engineering and Medicine 2018; 10 (7): 805-809.(IF NA)
            </strong>
          </p>
          <p>
            {" "}
            <strong>J 9: </strong>Dutta B, Park JE
            <strong>
              , <u>Kumar S</u>,{" "}
            </strong>
            Piliang H, Xavier GP, Serra A, Yan R, Vitaly S, Chuen Neng L, Hee
            HH, Dominique DK, Sze SK. Monocyte adhesion to atherosclerotic
            matrix proteins is enhanced by Asn-Gly-Arg deamidation.{" "}
            <strong>Scientific Reports 2017; 7: 5765. (IF 4.9)</strong>
          </p>
          <p>
            <strong>J 8 Kumar S</strong>
            <strong>1*</strong>
            <strong>, </strong>Mittal P. Selected Reaction Monitoring: A Valid
            Tool for Targeted Quantitation of Protein Biomarker Discovery.{" "}
            <strong>J Anal Bioanal Tech 2017; 8: 362. (IF NA)</strong>
          </p>
          <p>
            <strong>
              J 7: <u>Kumar S</u>
            </strong>
            , Chawla D, Tripathi AK. An Improved and Sensitive Method for
            Vitamin D3 Estimation by RP-HPLC.{" "}
            <strong>Pharm Anal Acta 2015, 6:8.(IF 1.4)</strong>
          </p>
          <p>
            <strong>
              J 6: <u>Kumar S</u>,{" "}
            </strong>
            Kumar D, Chakravarty J, Rai M, Sundar S<strong>. </strong>
            Identification and Characterization of a Novel{" "}
            <em>Leishmania donovani </em>Antigen for Serodiagnosis of Indian{" "}
            <em>
              Visceral leishmaniasis<strong>. </strong>
            </em>
            <strong>Am J Trop Med Hyg 2012; 86(4): 601-605.(IF 3.7)</strong>
          </p>
          <p>
            <strong>J 5: </strong>Chakravarty J<sup>a1</sup>,{" "}
            <strong>Kumar S</strong>
            <strong>a1</strong>, Trivedi S<sup>a</sup>, Rai VK<sup>a</sup>,
            Singh A<sup>a</sup>, Ashman JA<sup>b</sup>, Laughlin EM<sup>b</sup>,
            Coler RN<sup>b</sup>, Kahn SJ<sup>b</sup>, Beckmann AM<sup>b</sup>,
            Cowgill KD<sup>b</sup>, Reed SG<sup>b</sup>, Sundar S<sup>a</sup>,
            Piazza FM<sup>b*</sup>. A clinical trial to evaluate the safety and
            immunogenicity of the LEISH-F1 + MPL- SE vaccine for use in the
            prevention of visceral leishmaniasis.{" "}
            <strong>VACCINE 2011; </strong>29: 3531
          </p>
          <p>
            – 3537. <strong>(a1 = Joint First Author) (IF 5.5)</strong>
          </p>
          <p>
            <strong>
              J 4: <u>Kumar S</u>
            </strong>
            , Kumar D, Chakravarty J, Rai M, Sundar S. Identification and
            characterization of a novel 37 kDa <em>Leishmania donovani </em>
            Antigen for Diagnosis of Indian Visceral Leishmaniasis.{" "}
            <strong>Clin Vaccine Immunol 2011; </strong>18(5): 772 – 775.(IF 2.59)
          </p>
          <p>
            {" "}
            <strong>J 3: </strong>Kumar D*,{" "}
            <strong>
              <u>Kumar S*,</u>{" "}
            </strong>
            Chakravarty J, Rai M, Sundar S. A novel 12.6 kDa protein of{" "}
            <em>Leishmania donovani </em>for the diagnosis of Indian{" "}
            <em>Visceral leishmaniasis</em>.{" "}
            <strong>Vector Borne Zoonotic Dis 2011; </strong>11(10): 1359 –
            1364.(IF 2.5)
          </p>
          <p>
            <strong>J 2: </strong>Chakravarty J,{" "}
            <strong>
              <u>Kumar S</u>,{" "}
            </strong>
            Kumar R, Gautum S, Rai M, Sundar S. Evaluation of rk39
            immunochromatographic test with Urine for diagnosis of visceral
            leishmaniasis. <strong>Trans R Soc Trop Med Hyg 2011; </strong>105:
            537 – 539.(IF 2.45)
          </p>
          <p>
            {" "}
            <strong>J 1: </strong>Manandhar KD, Yadav TP, Prajapati VK,{" "}
            <strong>
              <u>Kumar S</u>
            </strong>
            , Rai M, Dube A, Srivastava ON, Sundar S. Antileishmanial Activity
            of Nano-Amphotericin-B deoxylate. <strong>J Antimicrob</strong>
            <strong>Chemother 2008; </strong>62: 376–380.(IF 5.75)
          </p>

          <p>
            <h3>
            ACKNOWLEDGED IN A RESEARCH PAPER OF BASIC SCIENCE 
            </h3>
            S Nylén, R Maurya, L Eidsmo, KD Manandhar, S Sundar, D Sacks. Splenic accumulation of IL-10 mRNA in T cells distinct from CD4+CD25+ (Foxp3) regulatory T cells in human visceral leishmaniasis <strong>
            . The Journal of Experimental Medicine 2007; 204 (4), 805-817. (IF 15.3)
            </strong>

          </p>

          
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Book/Book Chapters
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          <ul>

            <h3>BOOK</h3>
            <li>
            	Goyal RK, Patel BM, Patel NM, <strong> Kumar S.</strong> Practical Anatomy and Physiology. B.S. Shah Prakashan. <strong> Nineteenth Edition: 2023-2024. ISBN No. 81-8416-049-6.</strong>

            </li>
            <li>
            	Sobti RC, Dutta V, Singh RP, Saraf SA, Morya VK, <strong>Kumar S,</strong> Baghel K, Vimal M, Saini M, Adeeba, Sharma K, Kausal S, Ali QSW, Singh S, Verma A, Khan F. STI for Inclusive growth in India: Building Strategic Perspective from Evidences. DST Centre for Policy Research, BBA Central University, Lucknow, Government of India,<strong> 2018 (ISBN: 9788193260593).</strong>
            </li>
          </ul>

          <h3>BOOK CHAPTER</h3>
          <ul>
            <li>
            <strong>	Kumar S*</strong>, Nandave M. High Fat Diet (HFD) Induced Oxidative Stress via NADPH Oxidase (NOX) and PPARγ in Metabolic Syndrome (MetS).<strong> Nova Science Publishers, Inc., USA; 2023; 157 – 170. ISBN: 9798886976014.</strong>
            </li>
            <li>
         		Mishra RR, Mishra P, <strong> Kumar S,</strong> Gupta G. Application of essential oils for the production of dietary supplements and as traditional self-medication purposes. <strong>Elsevier 2023;</strong> Book: Essential oils in medication. Adetunji-AEOFI-1633689 978-0-323-98340-2. <strong>(Accepted)</strong> 
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>Conference (Speaker, Chairperson, presenter)</p>
          <ul>
            <li>
            	Chaired the Scientific Session as a Panellist in a National Conference on “Recent Trends in Medical Diagnostic Technology (RTMDT 2023)” 2-3 November, 2023 at Subharti Medical College, Swami Vivekanand Subharti University, Meerut, UP, India.
            </li>
            <li>
            	Delivered a scientific talk on National Level Awareness Program on “BLACK FEVER i.e KALA AZAR” at RGSC, Banaras Hindu University on 26th September 2023. 
            </li>
            <li>
            	Chaired the Scientific session of 40th All India AIMLTA MEDILABCON – 2014 (29– 30th December, 2014) Conference at Jawaharlal Auditorium, AIIMS, New Delhi.
            </li>
            <li>
            	Invited as a speaker in an International Conference on, “7th International Conference on “Challenges and Opportunities in Medical, Environmental and Biotechnological Research” GenoPro2020; Organized by Invertis University, Bareilly, Uttar Pradesh, India.
            </li>
            <li>
            	Invited as a speaker in a national workshop on “Emerging Field of Paramedical Sciences in Technological Era” on 27 April 2019 at NIMT Institute of Medical & Paramedical Sciences, Greater Noida, India.
            </li>
            <li>
            	Invited as a speaker in a National Conference “ABHAYUDAY 2015” at Ashoka Institute of Engineering & Technology, Varanasi.
            </li>
          </ul>
          <p>ORAL PRESENTATION (INTERNATIONAL CONFERENCE)</p>
          <ul>
            <li>
              Kumar S, Sundar S. Anti-parasitic activity of Nano-Medicine
              against Visceral leishmaniasi. International Conferences on
              Nanosciences &amp; Nanotechnology (ICNN2017). OP-60, 22-24
              September, 2017, BBAU Central University Lucknow.
            </li>
            <li>
              Kumar S, Singh OP, Chakravorty J, Sundar S. Immunogenicity of
              Leish – 111 F +MPL – SE Vaccine in Healthy Volunteers in India.
              4th World LEISH Conference, 3-7February, 2009.
            </li>
            <li>
              Kumar S, Chakravorty J, Sundar S. Identification of rK – 39
              antibody in the urine of Visceral leishmaniasis patients : A novel
              approach in the diagnosis of Kala – azar through Non- invasive
              procedure. 4th World LEISH Conference-3-7 February,2009
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsAwardsandRecOpen(!isAwardsandRecOpen)}
      >
        Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
          <ul>
            <li>
            	Faculty Co-ordinator in 2nd Board of studies (BOS) held on 17th November 2022 for the revision of Syllabus of MLT Course. 
            </li>
            <li>
            	Member of Organizing Committee in a National Conference on “Opportunities for MSME’s from Drug Discovery to Formulation Development” held on 11 – 14th January, 2023, organized by DCPMP and CAFT at DPSRU
            </li>
            <li>	Resource person for Hands-on training on PCR & PAGE in a National Conference on “Opportunities for MSME’s from Drug Discovery to Formulation Development” held on 11 – 14th January, 2023, organized by DCPMP and CAFT at DPSRU.</li>
            <li>
            	Selected and participated in a National Conference on, “HUMAN MICROBIOME IN HEALTH AND DISEASE” and Hands-on Workshop on, “NEXT-GENERATION SEQUENCING & DATA ANALYSIS” organised on 15-17 February, 2023 at Translational Health Science and Technology Institute (THSTI), Faridabad.
            </li>
            <li>
           	Received the prestigious DPSRU Distinguished Research Award on 28 February 2023 for publication from Hon’ble Vice Chancellor Prof. (Dr.) Ramesh K. Goyal and Padma Bhushan Awardee and Former Director General, ICMR, Dr. N. K. Ganguli.
            </li>
            <li>
            	Appointed as a technical expert committee member by Morarji Desai National Institute of Yoga (MDNIY), Ministry of Ayush, Govt. of India on 5th June 2023.
            </li>
            <li>
            	NABET-QCI (Quality Council of India) Appointed as a Technical Inspector for the project title, “Assessment of Uttar Pradesh Nursing Colleges” an Initiative by Uttar Pradesh State Medical Faculty (UPSMF) from 9 -14 August 2023.
            </li>
            <li>
              	Invited as Guest of Honour in a National level Awareness Program on Kala Azar Elimination from India by 2023 on 26 September 2023, at RGSC Banaras Hindu University, Varanasi, India.
              
            </li>

            <li>
            	Supervised a DST INSPIRE Fellow, Jiwaji University, Gwalior, for three months (July to Sep., 2023) of research training on In-silico study.
            </li>
            <li>
            	Supervised two students of B. Tech Biotechnology from Jaypee Institute of Information Technology (JIIT), Noida for one month (June – July, 2023) summer training on biotechnology techniques
            </li>

           <li> 	Faculty Co-ordinator to organize a seminar on, “Advance Technologies in Medical Diagnosis” on 22 September 2023 at DPSRU. </li>
           <li>	Invited as External Examiner for 2nd and 4th Semester’s B.Voc MLT and for the evaluation of final year M.Voc MLT student’s dissertation study, RGSC, Banaras Hindu University, from 23 – 26 September 2023.</li>
           <li>	Invited as an External Expert to take extension lecture of BMLT students of Department of Paramedical Sciences, Chaudhary Bansi Lal University, Bhiwani, Haryana in November, 2023.</li>
           <li>	Supervised two Post Graduation students of M.Sc. AIPM (Artificial Intelligence and Precision Medicine) for her dissertation study. 
(Status: Viva-Voce successfully completed, Nov. 2023)</li>
<li>	Successfully completed one-week National FDP on “Recent Trends in Pharmaceutical Innovation and Research” organized by Shri Vishnu College of Pharmacy (Autonomous), Bhimavaram from 20-24 November, 2023.</li>
<li>	Recognize as Associate Editor of “Journal of Applied Pharmaceutical Sciences and Research” URL http://www.japsr.in/index.php/journal</li>
<li>	Selected as Junior Research Fellow (2006) in a European Commission funded research project at Department of Medicine, Institute of Medical Sciences, Banaras Hindu University, Varanasi.</li>
<li>	 Awarded Senior Research Fellow of Council of Scientific and Industrial Research (CSIR, New Delhi) (2009 – 2011), INDIA.</li>
<li>	 Qualified GATE-2004 with All India Rank 711 conducted by IIT-Delhi.</li>
<li>	 Received Travel/Financial support from Bill & Melinda Gates funded Indo-US research project, sponsored by Infectious Disease Research Institute (IDRI), Seattle, Washington, USA, as visiting research fellow for six weeks (11/2006 – 01/2007) research training.</li>
<li>	 Shortlisted Under Top 10 Best Research Proposals in Bio-Asia Innovation Award-2012.</li>
<li>	 Organized a National Workshop, on “Advance Molecular Techniques (NWAMT-19, 11– 15th February 2019)”, in Association with Agilent Technologies, Delhi, as a Workshop Coordinator.</li>
<li>	Selected for two weeks (02-17 January 2019) Science Academies’ Refresher course on, “Fundamental and Application of Chemical Sciences in Material and Biological Sciences: A Recent Trends” sponsored by three National Science Academies (IASc, INSA & NASI) of India and organised by Surendranath College, Kolkata.</li>
	<li>Selected and successfully completed two weeks (9-23 May 2011) training on Immunotechnology, sponsored by UGC-NRCBS (National Resource Centre for Biological Sciences) and organised by Madurai Kamraj University, Tamil Nadu.</li>



          </ul>

          <h2>MEMBERSHIP</h2>
          <ul>
            <li>
            	Member of Association of Clinical Biochemists of India. Membership No. S-1058/23.
            </li>
          </ul>
          <h2>PATENT FILED</h2>
          <ul>
            <li>
            Title of Invention: Identification of L. donovani Specific 70 KDa, 37 KDa And 12.6 KDa Promastigote Antigens for the Diagnosis Of Indian Visceral Leishmaniasis. <br />
Name of Inventors: Shyam Sundar, Subodh Kumar, Dinesh Kumar <br />
File No.: 2939/DEL/2010

            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrSubodhKumar;
