import React, { useState } from "react";

import profimg from "./Ajit-Web2-1831x2048.jpg";

const DrAjitKumarThakur = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Ajit Kumar Thakur</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
           
              ajit [dot] thakur [at] dpsru [dot] edu [dot] in
          
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.co.in/citations?user=IBIRTyMAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=55840537000">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-1187-602X">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        B.Pharm; M.Pharm; Ph.D.; DST-INSPIRE Fellow
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Neuropharmacology, Metabolic Disorders, Ethnopharmacology, Pre-clinical
        Research
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>
                      <i>Research Project</i>
                    </b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Grants</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Collaboration</b>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Status</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>
                      Investigation of the anti-neuropathic efficacy of
                      Homeopathic dilutions of{" "}
                    </span>
                    <i>
                      <span>Toxicodendron pubescens</span>
                    </i>
                    <span> using preclinical models</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Collaborative Research Grant</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>CCRH, Min. of AYUSH, Govt. of India</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Completed</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Precision Medicine Centre, DPSRU, New Delhi</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>DTTE, Govt. of NCT, New Delhi</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>NA</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Ongoing</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>
                      Potential Role of Metformin in Cognitive and Affective
                      Abnormalities Associated with Diabetic-Obese Mice
                    </span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>AICTE-Research Promotion Scheme, Govt. of India</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>NA</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Ongoing</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <h2>
            <b>Book Chapters</b>
          </h2>
          <ol>
            <li>
            	<strong><strong>Thakur AK </strong>,</strong> Shekhar N, Tyagi S, Rani S. Role of probiotics in brain health. In: Nutraceutical Fruits and Foods for Neurodegenerative Disorders. Eds: Raj K. Keservani, Rajesh K. Kesharwani, Mila Emerald, Anil K. Sharma. Academic Press, 2024, Pages 173-198, ISBN 9780443189517 doi: <a href="https://doi.org/10.1016/B978-0-443-18951-7.00009-8">https://doi.org/10.1016/B978-0-443-18951-7.00009-8. </a> 
            </li>
            <li>
            <strong> Thakur AK</strong>, Joshi M, Goyal RK, Patel BM. Emerging role of ACE-2 in Cerebrovascular and Neurological Disorders: Lessons learnt from COVID-19. In: Renin Angiotensin System in Cardiovascular Disease. Eds: Naranjan S. Dhalla, Sukhwinder K. Bhullar, Anureet K. Shah. Springer, Cham., 2022. https://doi.org/10.1007/978-3-031-14952-8_25. Online ISBN978-3-031-14952-8.
              .
            </li>

            <li>3.	Kumar V, <strong>Thakur AK</strong>, Chatterjee SS. Obesity, cancer and psychopathology: can vegetarian diet be of help? In: "Nutrition, Diet and Cancer", by Shankar, Sharmila; Srivastava, Rakesh (Eds). Springer Netherlands, 2012; pp. 459-491. ISBN: 978-94-007-2922-3. doi: http://link.springer.com/chapter/10.1007/978-94-007-2923-0_18.</li>
          </ol>
          <h2>
            <strong>Research Articles</strong>
          </h2>
          <ol>
          <li>	Tyagi S, <strong>Thakur AK </strong>. Beneficial Role of Capsaicin through Modulation of Mitochondrial Functions in MPTP-injected Mice. Neuroscience and Behavioral Physiology, 2024. doi: 10.1007/s11055-024-01560-7.</li>
          <li>	Tyagi S, <strong>Thakur AK </strong>. Neuropharmacological Study on Capsaicin in Scopolamine-injected Mice. Current Alzheimer Research, 2023; 20:9:660-676. IF: 2.100</li>
          <li>	Kamboj P, <strong>Thakur AK </strong>. Ameliorative potential of Glycyrrhiza glabra extracts on memory impairments in stress triggered rats. Current Traditional Medicine, 2021;7(5): e141220189125. doi: 10.2174/2215083806999201214155503. IF: 0.600 </li>
          <li>	Kumar Y, <strong>Thakur AK </strong>, Goyal RK. Evaluation of alpha glucosidase inhibitor (miglitol) for its efficacy in diabetes and associated constipation. Indian Journal of Pharmaceutical Education and Research 2019;53(3s):s387-s391. doi:10.5530/ijper.53.3s.110. IF: 0.800 </li>
          <li>	<strong>Thakur AK </strong>, Kumar V. Neurotransmitters Modulating Effect of Andrographis paniculata Extract and Isolated Pure Andrographolide in Diabetic Rodents. Pharmacologia, 2018;9(2): 46-54. doi: 10.5567/pharmacologia.2018.46.54. </li>
          <li>	<strong>Thakur AK </strong>, Rai G, Chatterjee SS, Kumar V. Beneficial effects of an Andrographis paniculata extract and andrographolide on cognitive functions in streptozotocin-induced diabetic rats. Pharmaceutical Biology, 2016;54(9):1528-1538. doi: 10.3109/13880209.2015.1107107. IF: 3.800 </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Adaptogenic Potential of Andrographolide: an Active Principle of the King of Bitters (Andrographis paniculata). Journal of Traditional and Complementary Medicine, 2015;5(1):42-50. doi: 10.1016/j.jtcme.2014.10.002. IF: 4.500 </li>
          <li>	<strong>Thakur AK </strong>, Soni UK, Rai G, Chatterjee SS, Kumar V. Andrographolide Modulate some Toll-like Receptors and Cytokines Expressions in HL-60 Cell Line. Pharmacy & Pharmacology International Journal, 2015;2(3):1-5. doi: 10.15406/ppij.2015.02.00027.</li>
          <li>	<strong>Thakur AK </strong>, Dey A, Chatterjee SS, Kumar V. Reverse Ayurvedic pharmacology of Ashwagandha as an adaptogenic anti-diabetic plant: A pilot study. Current Traditional Medicine, 2015;1(1):51-61. doi: 10.2174/2215083801999150527115205. IF: 0.600 </li>
          <li>	<strong>Thakur AK </strong>, Rai G, Chatterjee SS, Kumar V. Analgesic and anti-inflammatory activity of Andrographis paniculata and andrographolide in diabetic rodents. EC Pharmaceutical Science, 2015;1(1):19-28.</li>
          <li>	Rauniyar BK, Shakya A, <strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Anti-stress Activity of Phloroglucinol: A Transient Metabolite of Some Plant Polyphenolics. Pharmacologia, 2015;6(1):21-30. </li>
          <li>	<strong>Thakur AK </strong>, Soni UK, Rai G, Chatterjee SS, Kumar V. Protective effects of Andrographis paniculata extract and pure andrographolide against chronic stress-triggered pathologies in rats. Cellular and Molecular Neurobiology, 2014;34(8):1111-1121. doi: 10.1007/s10571-014-0086-1. IF: 4.000 </li>
          <li>	Kumar V, <strong>Thakur AK </strong>. Role of Andrographolide in Analgesic and Anti-inflammatory activity of Andrographis paniculata: an experimental study in diabetic rodents (Abstract). Basic & Clinical Pharmacology & Toxicology, 2014;115(S1):145. doi: 10.1111/bcpt.12259_3. IF: 3.100</li>
          <li><strong>Thakur AK </strong>, Rai G, Chatterjee SS, Kumar V. Ameliorative Effects of Andrographis paniculata Extract and Andrographolide on Memory Impairments in Streptozotocin-induced Diabetic Rats. Indian Journal of Pharmacology, 2014;46(Suppl. 1):S-46-47. IF: 2.833 </li>
          <li>	Husain GM, Rai R, Rai G, Singh HB, <strong>Thakur AK </strong>, Kumar V. Potential mechanism of anti-diabetic activity of Picrorhiza kurroa. TANG (Humanitas Medicine), 2014;4(4):e27.1-5. doi: 10.5667/tang.2014.0013.  </li>
          <li>	Langstieh AJ, Verma P, <strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Desensitization of mild stress triggered responses in mice by a Brassica juncea leaf extract and some ubiquitous secondary plant metabolites. Pharmacologia, 2014,5(9):326-338. doi: 10.5567/pharmacologia.2014.326.338.  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Neuropsychopharmacology of a therapeutically used Andrographis paniculata extract: a preclinical study. Oriental Pharmacy and Experimental Medicine, 2014;14(2):181-191. doi: 10.1007/s13596-013-0140-4. IF: 2.000  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Antidepressant-like activity of Andrographis paniculata in type-2 diabetic rats. Clinical Pharmacology and Biopharmaceutics, 2014;S2:1-9. doi: 10.4172/2167-065X.S2-003.   </li>
          <li><strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Therapeutic potential of traditionally used medicinal plant Andrographis paniculata (Burm. F.) against diabesity: an experimental study in rats. TANG (Humanitas Medicine), 2014;4(1):e7.1-8. doi: 10.5667/tang.2014.0001.   </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Antidepressant-like effects of Brassica juncea leaves in diabetic rodents. Indian Journal of Experimental Biology, 2014;52(6):613-622. URI: http://nopr.niscair.res.in/handle/123456789/28868. IF: 0.944  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Beneficial effects of Brassica juncea on cognitive functions in rats. Pharmaceutical Biology, 2013;51(10):1304-1310. doi: 10.3109/13880209.2013.789917. IF: 3.800 </li>
          <li>	Kumar A, Agarwal P, Shakya A, <strong>Thakur AK </strong>, Kumar V. Key Role of carnosic acid in the anxiolytic-like activity of Rosmarinus officinalis Linn. in rodents. Journal of Pharmacology & Clinical Toxicology, 2013;1(2):1013.1-1013.5.  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Anxiolytic-like activity of leaf extract of traditionally used Indian-mustard (Brassica juncea) in diabetic rats. TANG (Humanitas Medicine), 2013;3:e7.1-7.7. doi: 10.5667/tang.2012.0042. </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Antidiabetic activity of standardised extract of Andrographis paniculata in rodents (Abstract). PharmaNutrition, 2013;2(3):91-92. doi: 10.1016/j.phanu.2013.11.047. IF: 3.200  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. General neuropharmacological screening of standardized extract of Andrographis paniculata in rodents. Annals of Neurosciences, 2012;19(Suppl. 4):36-37. IF: 1.5  </li>
          <li>	<strong>Thakur AK </strong>, Kumar V. Beneficial effect of Brassica juncea in co-morbid anxiety associated with alloxan induced diabetes in rats. Indian Journal of Pharmacology, 2010;42(Suppl. 2):223. IF: 2.400  </li>
          </ol>
          <h2>
            <strong>Review Publications </strong>
          </h2>
          <ol>
          <li>	Tyagi S, Shekhar N, <strong>Thakur AK </strong>. Alternative Approaches for the Management of Huntington’s Disease: A Narrative Review. Alternative Therapies in Health and Medicine. 2023:AT6568. PMID: 37883773. </li>
          <li>	Shekhar N, Tyagi S, <strong>Thakur AK </strong>. Alternative Approaches for the Management of Autism: A Narrative Review. Current Psychiatry Research and Reviews. 2024;20(1):14-36. 10.2174/2666082219666230106143424. IF: 0.400  </li>
          <li>	Shekhar N, Tyagi S, Rani S, <strong>Thakur AK </strong>. Potential of Capric Acid in Neurological Disorders: An Overview. Neurochemical Research. 2023;48(3):697-712. doi: 10.1007/s11064-022-03809-4. IF: 4.414.  </li>
          <li>	Sharma R, Kumar A, Majeed J, <strong>Thakur AK </strong>, Aggarwal G. Drugs acting on the renin–angiotensin–aldosterone system (RAAS) and deaths of COVID-19 patients: a systematic review and meta-analysis of observational studies. Egypt Heart J 74, 64 (2022). https://doi.org/10.1186/s43044-022-00303-8.  </li>
          <li>	Tyagi S, Shekhar N, <strong>Thakur AK </strong>. Protective Role of Capsaicin in Neurological Disorders: An Overview. Neurochemical Research. 2022;47(6):1513-1531. doi: 10.1007/s11064-022-03549-5. IF: 4.414.  </li>
          <li>Tyagi S, Shekhar N, <strong>Thakur AK </strong>. An evidence based review of Medicinal Plants in the overall management of Chronic Fatigue. Current Psychiatry Research and Reviews. 2021; 17(3): 154-171. IF: 0.400  </li>
          <li>	Kumari R, Shekhar N, Tyagi S, <strong>Thakur AK </strong>. Mitochondrial dysfunctions and neurodegenerative diseases: a mini-review. Journal of Analytical & Pharmaceutical Research. 2021;10(4):147-149.  </li>
          <li>	Yadav N, <strong>Thakur AK </strong>, Shekhar N, Ayushi. Potential of Antibiotics for the Treatment and Management of Parkinson's Disease: An Overview. Current Drug Research Reviews. 2021;13(3):166-171.  </li>
          <li>	Kumar M, <strong>Thakur AK </strong>. Neurological manifestations and comorbidity associated with COVID-19: an overview. Neurological Sciences. 2020;41(12):3409-3418. doi: 10.1007/s10072-020-04823-6. IF: 3.300  </li>
          <li>Goyal RK, Babu CR, Majeed J, Dhobi M, Patel BM, Sharma K, Tonk R, <strong>Thakur AK </strong>. Herbal Based Treatments for COVID-19. CV Network (Official bulletin of the International Academy of Cardiovascular Sciences). 2020;19(2):15-16.  </li>
          <li>	Sharma P, Goyal RK, <strong>Thakur AK </strong>. Emergence of Bevacizumab as Biosimilar with Ophthalmologic Prospects for Patients and Pharma Industry. Ophthalmology and Ophthalmic Surgery. 2020;3(1):122.1-10.  </li>
          <li>	<strong>Thakur AK </strong>, Tyagi S, Shekhar N. Comorbid Brain Disorders Associated with Diabetes: Therapeutic Potentials of Prebiotics, Probiotics and Herbal Drugs. Translational Medicine Communications. 2019;4:12. doi:10.1186/s41231-019-0043-6.  </li>
          <li>	<strong>Thakur AK </strong>, Tariq U. Therapeutic Potential and Recent Development of Psychobiotics for the Management of Brain Disorders. Trends in Applied Sciences Research, 2019;14(2):70-79. doi: 10.3923/tasr.2019.70.79.  </li>
          <li>	Goyal RK, <strong>Thakur AK </strong>. Deadly Drug Combinations. Double Helical: A Complete Health Journal, 2019;5(1):41-44.  </li>
          <li>	Goyal RK, <strong>Thakur AK </strong>. A Status Report on Autonomic Nervous System: the Indian Research Scenario during the Last Five Years. Proceedings of the Indian National Science Academy, 2018;84(1):3-14. doi: 10.16943/ptinsa/2018/49305.  </li>
          <li>	Kumar Y, Goyal RK, <strong>Thakur AK </strong>. Pharmacotherapeutics of miglitol: an α-glucosidase inhibitor. Journal of Analytical & Pharmaceutical Research 2018;7(6):617‒619.  </li>
          <li>	<strong>Thakur AK </strong>, Kamboj P, Goswami K, Ahuja, K. Pathophysiology and Management of Alzheimer’s Disease: an overview. Journal of Analytical & Pharmaceutical Research 2018;7(2):226‒235.  </li>
          <li>	<strong>Thakur AK </strong>, Raj P. Pharmacological Perspective of Glycyrrhiza glabra Linn.: a Mini-Review. Journal of Analytical & Pharmaceutical Research 2017;5(5):00156. doi: 10.15406/japlr.2017.05.00156.  </li>
          <li>	Kumar V, <strong>Thakur AK </strong>, Verma S, Yadav V, Chatterjee SS. Potential of some traditionally used edible plants for prevention and cure of diabesity-associated comorbidities. TANG (Humanitas Medicine), 2015;5(2):e8.1-22. doi: 10.5667/tang.2014.0026.   </li>
          <li>	<strong>Thakur AK </strong>, Shakya A, Husain GM, Emerald M, Kumar V. Gut-Microbiota and Mental Health: Current and Future Perspectives. Journal of Pharmacology & Clinical Toxicology, 2014;2(1):1016.1-1016.15.  </li>
          <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Andrographolides and traditionally used Andrographis paniculata as potential adaptogens: Implications for therapeutic innovation. TANG (Humanitas Medicine), 2014;4(3):e15.1-14. doi: 10.5667/tang.2014.0002.  </li>
          <li>	Tiwari N, <strong>Thakur AK </strong>, Kumar V, Dey A, Kumar V. Therapeutic Targets for Diabetes Mellitus: An Update. Clinical Pharmacology and Biopharmaceutics, 2014;3:117.1-10. doi: 10.4172/2167-065X.1000117. </li>
          <li>	Kumar V, <strong>Thakur AK </strong>, Chatterjee SS. Perspective of Andrographis paniculata in neurological disorders. Clinical Pharmacology and Biopharmaceutics, 2014;S2:1-4. doi: 10.4172/2167-065X.S2-005.15.  </li>
          <li>	Kumari A, <strong>Thakur AK </strong>, Kumar V. Rational of Animals Use in Drug Discovery. 2013; PharmaTutor-Art-1814. (URL: http://www.pharmatutor.org/articles/rational-of-animals-use-in-drug-discovery).  </li>
          <li>	Kumar V, <strong>Thakur AK </strong>, Barothia ND, Chatterjee SS. Therapeutic potentials of Brassica juncea: an overview. TANG (Humanitas Medicine), 2011;1:e2.1-17. doi: 10.5667/tang.2011.0005. </li>

          </ol>

          <h2>
            <b>Poster Presentations</b>
            </h2>
            <ol>


            <li>	Shekhar N, <strong>Thakur AK </strong>. Role of Capric acid; a medium chain fatty acid in neurological diseases. DPSCON 2023, 1st March 2023, DPSRU, New Delhi, India.  </li>
            <li>	Tyagi S, <strong>Thakur AK </strong>. Role of Capsaicin in the management and treatment of neurodegenerative disease. DPSCON 2023, 1st March 2023, DPSRU, New Delhi, India. </li>
            <li>	Sablania H, Sinha S, Goyal RK, <strong>Thakur AK </strong>. Beneficial role of Nyctanthes arbor-tristis extract in CCI-induced sciatic nerve pain: A preclinical study at 13th IASTAM Oration & Awards Function-2023 & International Conclave, DPSRU, January 21-23, 2023, New Delhi, India. </li>
            <li>	Bhardwaj S, Mannan I, <strong>Thakur AK </strong>, Goyal RK. Anxiolytic activity of Acalypha indica Linn. Extract in Nicotine-Induced Rats: A preclinical study at 13th IASTAM Oration & Awards Function-2023 & International Conclave, DPSRU, January 21-23, New Delhi, India. </li>
            <li>	Deepshikha, Kumawat R, <strong>Thakur AK </strong>. Beneficial effect of L- Carveol in chronic restraint stress rat. A preclinical study at 13th IASTAM Oration & Awards function -2023 & International conclave, DPSRU, January 21-23, New Delhi, India. </li>
            <li>	Yadav N, Shekhar N, Tyagi S, Rani S, <strong>Thakur AK </strong>. Comparative Study of Low-Dose Antibiotics in MPTP-Induced Parkinson’s Disease: Pre-clinical Study” during XL Annual Meeting of Indian Academy of Neurosciences (IAN) organized by the Department of Biomedical Engineering, North-Eastern Hill University, Shillong, India. December 08-10, 2022. </li>
            <li>	Shekhar N, <strong>Thakur AK </strong>. Acute oral toxicity study of Capric acid by OECD Guideline 423 in rodents. IASTAM Conclave 2022, 29-30 July 2022, Jamia Hamdard, New Delhi, India.</li>
            <li>	Tyagi S, <strong>Thakur AK </strong>. Role of Capsaicin in MPTP-induced Mice. at the 11th International Conference on Central Nervous System-2022 held at DUBAI, UAE on 27th April 2022.  </li>
            <li>	Tariq U, Sarkar S, <strong>Thakur AK </strong>, Banerjee SK. Decreased Expression of SCN5A Gene Involved in Remodeling of Cardiomyoblast: Focusing on Energy Metabolism. “Dr. SC Tyagi Award” International Conference of Cardiovascular Sciences – 2020, 2020 Feb 21-23; Delhi Pharmaceutical Sciences and Research University, New Delhi, India. </li>
            <li>	Shekhar N, Tyagi S, Yadav N, <strong>Thakur AK </strong>. Potential role of gut metabolite Trimethylamine N-Oxide as a biomarker in predicting neurological and cardiovascular diseases. International Conference of Cardiovascular Sciences – 2020, 2020 Feb 21-23; Delhi Pharmaceutical Sciences and Research University, New Delhi, India.  </li>
            <li>	Shekhar N, <strong>Thakur AK </strong>, Siju EN. Evaluation of nephroprotective activity of leaves extract of Rotula aquatica Lour. 7th International Congress of Society for Ethnopharmacology, 2020 Feb 15-17; Jamia Hamdard, New Delhi, India. </li>
            <li>Tyagi S, Shekhar N, Yadav N, <strong>Thakur AK </strong>, Jaggi AS. Investigation of the role of acute and repeated stress on remote ischemic preconditioning and adenosine preconditioning induced cardio protection in rats. International Conference of Cardiovascular Sciences – 2020, 2020 Feb 21-23; Delhi Pharmaceutical Sciences and Research University, New Delhi, India.   </li>
            <li>Kharb D, Roshan K, <strong>Thakur AK </strong>. Nanoparticle-Based Medicines: A Review of FDA-Approved Materials and Clinical Trials. 6th World Congress on Nanomedical Sciences (ISNSCON-2019), New Delhi. January 07 – 10, 2019.  </li>
            <li>	Goswami K, Ahuja K, Kamboj P, <strong>Thakur AK </strong>. Herbal and Non-Pharmacological Therapies of Alzheimer’s Disease: An Overview. One Day Conference on Recent Advances in Pharmaceutical Sciences and Health. SPER, Jamia Hamdard, New Delhi. February 26, 2018. </li>
            <li>Kamboj P, Raj P, <strong>Thakur AK </strong>. Beneficial Effects of Glycyrrhiza glabra Linn. Extracts in Chronic Stressed-Triggered Depression in Mice. International Conference, February 02 to 04, 2018, DPSRU, New Delhi. </li>
            <li><strong>Thakur AK </strong>. Prospective of Therapeutic and Pharmacological Aspects in Controlled Drug Delivery Systems (Abstract No. ABS-1033). National Seminar on “Advances in Technology, Regulatory and Clinical Aspects in Controlled Drug Delivery Systems” organized by Delhi Pharmaceutical Sciences and Research University & Controlled Release Society - Indian Chapter at Delhi Pharmaceutical Sciences and Research University. New Delhi, India. July 15, 2016. </li>
            <li>	Kumar V, <strong>Thakur AK </strong>. Role of Andrographolide in Analgesic and Anti-inflammatory activity of Andrographis paniculata: an experimental study in diabetic rodents. 17th World Congress of Basic & Clinical Pharmacology, Organized by IUPHAR at Cape Town, South Africa, July 13-18, 2014. </li>
            <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Antidiabetic activity of standardized extract of Andrographis paniculata in rodents. 2nd International Pharma-Nutrition Conference, organized by Elsevier, MAX Atria, Singapore. April 15-17, 2013. </li>
            <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Metabolic effects of an Andrographis paniculata extract in rat obesity models. Indo-US Symposium Industry-Academia Interaction in Diabetes & Cardiovascular Drug Discovery, Organized by IMS, Banaras Hindu University, Varanasi, India & Indo-US Science & Technology Forum & S-LEARN. December 4-5, 2012. </li>
            <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. General neuropharmacological screening of standardized extract of Andrographis paniculata in rodents. XXX Annual Conference of Indian Academy of Neurosciences (IAN) & International Symposium on Translational Neuroscience, Amritsar, India. October 27-30, 2012. </li>
            <li>	<strong>Thakur AK </strong>, Chatterjee SS, Kumar V. Potential of Andrographis paniculata in brain disorders. International Conference- “Recent Advances in Pharmaceutical Science: Transferring Knowledge from Education to Practice”. PharmanextTM in association with Kathmandu University, Pokhara University, Tribhuvan University and Purbanchal University, Nepal. February 25-26, 2012. </li>
            <li>	<strong>Thakur AK </strong>, Kumar V. Anti-amnesic activity of Brassica juncea in diabetic rats. 12th International Congress of Ethnopharmacology- “Traditional Medicines and Globalization –The Future of Ancient Systems of Medicine”. Jadavpur University, Kolkata, India, February 17-19, 2012. </li>
            <li>	<strong>Thakur AK </strong>, Kumar V. Beneficial effect of Brassica juncea in co-morbid depression associated with alloxan induced diabetes in rodents. International conference on Recent Advances in Pharmaceutical Sciences. Department of Pharmaceutics, Institute of Technology, Banaras Hindu University, Varanasi, India. December 22-23, 2010. </li>
            <li>	<strong>Thakur AK </strong>, Kumar V. Beneficial effect of Brassica juncea in co-morbid anxiety associated with alloxan induced diabetes in rats. 43rd Annual Conference of Indian Pharmacological Society & International Conference on Pharmacology and Translational Research, National Institute of Nutrition, Hyderabad, India. December 13-16, 2010. </li>
            <li>	Husain GM, <strong>Thakur AK </strong>, Singh PN, Kumar V. Effect of Picrorhiza kurroa & Quassia amara on high fat diet induced obesity in rats. International Conference Update Ayurveda: “Bridging therapeutic gap” Parel, Mumbai, India. November 25-27, 2010. </li>
            <li>Husain GM, <strong>Thakur AK </strong>, Singh PN, Kumar V. Lack of antidepressant and anxiolytic activity of Picrorhiza kurroa and Quassia amara in streptozotocin induced diabetic rats. International Symposium on Brain Aging and Dementia: Basic and Transitional Aspect. Department of Zoology, Banaras Hindu University, Varanasi, India. November 29 - 30, 2010. </li>




            </ol>
        
        <h2><b>Oral Presentations </b></h2>

        <ol>

        <li>	<strong>Thakur AK </strong>, Harshita. Beneficial Effect of Metformin in Affective Disorders Associated with Diabetic-Obese Mice. 53rd Annual Conference of the Indian Pharmacological Society IPSCON 2023", SRMIST, Chennai, India. December 14-16, 2023. </li>
        <li>	<strong>Thakur AK </strong>, Kumar A. Potential Role of Metformin in Depression and Cognitive Dysfunctions Associated with Diabetes: An In-silico Study. 52nd IPSCON 2023, JSS College of Pharmacy, Mysuru, JSSAHER, Karnataka, India. February 23-25, 2023. </li>
        <li>	<strong>Thakur AK </strong>. Potential of Glycyrrhizin-rich Glycyrrhiza glabra Linn. Extract in Affective Disorders Associated with Chronic-Stress” during 25th APTICON-2022 organized by JSS College of Pharmacy, Mysuru, India. September 02-04, 2022. </li>
        <li>	<strong>Thakur AK </strong>, Kumar V. Neurotransmitters Modulating Effect of Andrographis paniculata Extract and Isolated Pure Andrographolide in Diabetic Rodents. Research Article for Oral Presentation at 50th Annual Inaugural Golden Jubilee Conference of Indian Pharmacological Society, Patna, India. February 14, 2017. (Awarded: G. Achari Prize). </li>
        <li><strong>Thakur AK </strong>, Rai G, Chatterjee SS, Kumar V. Ameliorative Effects of Andrographis paniculata Extract and Andrographolide on Memory Impairments in Streptozotocin-induced Diabetic Rats. 47th Annual Conference of the Indian Pharmacological Society “IPSCON- Guwahati 2014", Organized by GMCH and NIPER at GMCH Conventional Centre, Guwahati, December 28-30, 2014. </li>
        <li>	<strong>Thakur AK </strong>. Retrograde Amnesias. “BRAIN AWARENESS WEEK” sponsored by National Academy of Sciences, India (Varanasi Branch), Department of Zoology, Banaras Hindu University, Varanasi, March 17-18, 2010. </li>

        </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Conference/ Seminar Speaker/Invited Lecture
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
          
         
         <ol>
         <li>	Title: Anti-Neuropathic Efficacy of Homeopathic Dilutions of Toxicodendron pubescens: A Preclinical Study, during East Zone World Homoeopathy Day, Oct 05th & 06th, 2023 - Biswa Bangla Convention Center, Kolkata, India </li>
         <li>	Title: Potential Role of Low-Dose Antibiotics in MPTP-Induced Parkinson’s Disease, during One day National Symposium on “Challenges and opportunities in the management of Neurological disorder” at NIPER Guwahati, India. December 07, 2022. </li>
         <li>	Title: Beneficial effects of Glycyrrhizin-rich Glycyrrhiza glabra Linn. Extract on Learning and Memory in Stress-Triggered Rats, during International E-Conference collaboratively organized by School of Pharmacy, ARKA JAIN University and PHARMACY DEPARTMENT, SAN PEDRO COLLEGE, PHILIPPINES. November 19, 2021. </li>
         <li>	Title: Career in Pharmaceutical Sciences: A Pharmacologist’s Perspective, during Induction and Orientation program "AARAMBH 2021", ARKA JAIN University, 15th-19th September 2021. </li>

         </ol>
        
       
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <b>AWARDS AND RECOGNITION</b>
          </p>
          <ol>
            <li>
              <strong>Event Co-ordinator</strong> for the Poster/Photo/Art
              Competitions (G-20 Event) held at DPSRU, New Delhi. April 27,
              2023.
            </li>
            <li>
              <strong>Organising Committee Member and Chairperson</strong> for 2
              <sup>nd</sup> Annual Conference DPSCON 2023 held at DPSRU, New
              Delhi. March 01, 2023.
            </li>
            <li>
              Certificate of Appreciation for{" "}
              <strong>
                “Outstanding Performance for University Administration and
                Showcasing”
              </strong>{" "}
              during the year 2022 during 2<sup>nd</sup> Research Promotion
              Award Ceremony held at DPSRU, New Delhi. February 28, 2023.
            </li>
            <li>
              <strong>Notable Research Award </strong>for two Publications in
              the Journal of<strong> “Neurochemical Research” </strong>during 2
              <sup>nd</sup> Research Promotion Award Ceremony held at DPSRU, New
              Delhi. February 28, 2023.
            </li>
            <li>
              <strong>Organising Committee Member </strong>of 2<sup>nd</sup>{" "}
              Research Promotion Award Ceremony held at DPSRU, New Delhi.
              February 28, 2023.
            </li>
            <li>
              <strong>In-charge of Organising Committee </strong>for 13
              <sup>th</sup> IASTAM Oration and Award Function-2023 and
              International Conclave organised at DPSRU New Delhi. January
              21-22, 2023.
            </li>
            <li>
              <strong>Joint-Organising Secretary </strong>for National
              Conference on
              <strong>
                {" "}
                Opportunities for MSME’s from Drug Discovery to Formulation
                Development{" "}
              </strong>
              organised by DPSRU, New Delhi. January 11 to 14, 2023.
            </li>
            <li>
              <strong>Organising Member</strong> for One Day Workshop on
              Comparing, Contrasting and Advancing Ayurvedic and Unani Concepts
              with Contemporary Genomic Biology, organised at DPSRU, New Delhi.
              December 15, 2022.
            </li>
            <li>
              <strong>Organising Member</strong> for EXPERT TALK in
              collaboration with Merck on “Advance Immunoassay Solutions”
              organised at DPSRU New Delhi. November 02, 2022.
            </li>
            <li>
              <strong>Organising Member</strong> for EXPERT TALK on “Machine
              intelligence-driven framework for optimized hit selection in
              virtual screening” organised at DPSRU New Delhi. October 11, 2022.
            </li>
            <li>
              <strong>Organising Committee Member </strong>for “Har Din Har Ghar
              Ayurveda” programme organised by DPSRU, New Delhi. October 03 to
              08, 2022.
            </li>
            <li>
              <strong>Co-ordinator </strong>for Workshop on Preparation and
              Management of Standard Operating Procedures organised by DPSRU,
              New Delhi. July 14 to 25, 2022.
            </li>
            <li>
              <strong>Speaker </strong>for International E-Conference
              collaboratively organized by School of Pharmacy, ARKA JAIN
              University and PHARMACY DEPARTMENT, SAN PEDRO COLLEGE,
              PHILIPPINES. November 19, 2021.
            </li>
            <li>
              <strong>Moderator</strong> for the Q&amp;A Session, Webinar
              Series-I, DPSRU- Centre for Precision Medicine and Pharmacy.
              August 13, 2021.
            </li>
            <li>
              <strong>Speaker and Resource Person</strong> in Webinar
              Series-2021, ARKA JAIN University, Tatanagar (Jamshedpur), August
              01, 2021
            </li>
            <li>
              Biography selected for
              <strong>
                {" "}
                Marquis Who’s Who in the World<sup>®</sup>
              </strong>{" "}
              <strong>2020</strong>, comprises top 3% of the professionals in
              the world. January 16, 2021.
            </li>
            <li>
              <strong>Moderator</strong> for the session “Current Trends in
              Pharmaceutical Sciences: with Special Focus on Clinical Research”
              during XXXIII QIP-2020, September 22-29, 2020 at DIPSAR, New Delhi
            </li>
            <li>
              <strong>Co-coordinator</strong>, 2<sup>nd</sup> FIDP, August
              21-27, 2020 DPSRU, New Delhi
            </li>
            <li>
              <strong>Joint Organising Secretary </strong>and{" "}
              <strong>Chairperson (Registration Committee)</strong>,
              International Conference of Cardiovascular Sciences – 2020, 2020
              Feb 21-23; Delhi Pharmaceutical Sciences and Research University,
              New Delhi, India.
            </li>
            <li>
              Program Operating Officer of NAMS, India funded{" "}
              <strong>CME Programme</strong> held on 22 Feb 2020 at DPSRU, New
              Delhi.
            </li>
            <li>
              Organising Member,{" "}
              <strong>International Heart Symposium-2019</strong> held at DPSRU,
              New Delhi, 28<sup>th</sup> November, 2019
            </li>
            <li>
              Organising Member (Registration Committee) and Chairperson (Poster
              Session) of <strong>IASTAM International Conference</strong>,
              February 02 to 04, 2018, DPSRU, New Delhi.
            </li>
            <li>
              Awarded with prestigious <strong> Achari Prize</strong> during 50
              <sup>th</sup> Annual Inaugural Golden Jubilee Conference of Indian
              Pharmacological Society, 2017, Patna, Bihar.
            </li>
            <li>
              Organising Committee Member &amp; Chairperson in Poster Session, 5
              <sup>th</sup> National Conference of Pharmacoeconomics &amp;
              Outcomes Research. March 3<sup>rd</sup> &amp; 4<sup>th</sup>,
              2017, DPSRU, New Delhi.
            </li>
            <li>
              Organising Committee Member: workshop on Innovations and
              Entrepreneurship, 27<sup>th</sup> April 2017.
            </li>
            <li>
              Certificate of Reviewing Award: September 2016 from{" "}
              <em>Pharmacology Biochemistry and Behavior</em>, Elsevier.
            </li>
            <li>
              <strong>Financial Assistantship </strong>from{" "}
              <strong>DPSRU, New Delhi</strong> for Oral presentation at AIGJC.
            </li>
            <li>
              Organising Committee Member, DPSRU-CRS, one-day National Seminar,
              DPSRU, New Delhi.
            </li>
            <li>
              Biography selected for
              <strong>
                {" "}
                Marquis Who’s Who in the World<sup>®</sup>
              </strong>{" "}
              <strong>2014 </strong>(32<sup>nd</sup> Edition) and{" "}
              <strong>
                Marquis Who’s Who in the World<sup>®</sup>
              </strong>{" "}
              <strong>2016 </strong>(33<sup>rd</sup> Edition).
            </li>
            <li>
              <strong>Student Travel Grant Support (STGS)</strong> from IIT
              (BHU), Varanasi to attend IPSCON’14 held at Guwahati, Assam in
              December, 2014.
            </li>
            <li>
              <strong>International Travel Grant </strong>from
              <strong> DBT</strong> of India and{" "}
              <strong>Foreign Travel Grant </strong>from <strong>CSIR,</strong>{" "}
              Govt. of India for Poster presentation at Singapore in 2013.
            </li>
            <li>
              <strong>Financial Assistantship </strong>from{" "}
              <strong>DST-PURSE</strong>, IIT (BHU), Varanasi for Poster
              presentation at Singapore in 2013
            </li>
            <li>
              <strong>Financial Assistantship </strong>for{" "}
              <strong>Poster presentation</strong> from{" "}
              <strong>IIT (BHU)</strong> in 2012 (Nepal Conference) and in 2013
              (Singapore Conference).
            </li>
            <li>
              <strong>DST/INSPIRE Fellowship/2011</strong> for Ph.D. course as
              JRF/SRF-P grant (2011-2016).
            </li>
            <li>
              Fellowship from<strong> MHRD/UGC New Delhi </strong>during M.
              Pharm (2009-2011).
            </li>
            <li>
              <strong>University Topper in B. Pharm </strong>(80.19 % in
              2005-2009 Batch), M.I.T. (B.R.A. Bihar University), Muzaffarpur,
              Bihar.
            </li>
          </ol>
          <h2>
            <strong>Professional Membership</strong>
          </h2>
          <ol>
            <li>
              Life Membership of Delhi Pharmacological Society (DPS: LM-056)
            </li>
            <li>
              Life Membership of the Indian Academy of Neuroscience (IAN: LT-40)
            </li>
            <li>
              Life Membership of the Indian Pharmacological Society (IPS:
              LT-150)
            </li>
            <li>
              Life Membership of the Association of Pharmaceutical Teachers of
              India (APTI: ND/LM-110).
            </li>
            <li>
              Life Membership of the Indian Pharmacy Graduates’ Association
              (IPGA: LM-5550).
            </li>
          </ol>
        </div>
      ) : null}
    </div>
  );
};

export default DrAjitKumarThakur;
