import React from "react";
import cmys from "./cmys-01.png";
import "./CenterforMeditationandYogaScience.css";
import admissionLink from './Admission-form-for-Meditation-yoga-science.pdf'
import syllabus from './Syllabus.pdf'

import addmission_msg01 from './entry-msg01.jpg'
import addmission_msg02 from './entry-msg02.jpg'

import gal1 from './img1.jpg'
import gal2 from './img2.jpg'
import gal3 from './img3.jpg'
import gal4 from './img4.jpg'
import gal5 from './img5.jpg'
import gal6 from './img6.jpg'

const CenterforMeditationandYogaScience = () => {
  return (
    <div className="dpsru-every-component-css">
      <div className="logo-cmys-container">
        <img src={cmys} alt="logo" />
      </div>

      <div className="cmys-logo-paragrauph">
        “Spending Time in Meditation and Yoga to <br />
        Decrease Medication and Increase the Mindfulness”
      </div>
      <h2 className="dpsru-primary-heading-for-every-page">
        Center For Meditation and Yoga Sciences
      </h2>

      <div className="cmys-paragauph-first">
        Delhi Pharmaceutical Sciences and Research University has always
        promoted certain outreach programs for fitness and health. In 2016, the
        Yoga and Fitness Centre was started to provide 3 credit course for Yoga
        for students of Pharmacy and Physiotherapy in the University. Further, a
        Certificate Course was started for the general public.
        <br />
        <br />
        With special support from the Govt. of NCT of Delhi the University has
        started “Centre for Meditation and Yoga Sciences”, with a one year (3
        trimester) course “Diploma in Meditation and Yoga Sciences” from April
        2021. While DPSRU, being the nodal center, and has 15-20 centres across
        NCT of Delhi. The list of Centres is appended. There will be tentatively
        300 Seats for admission in second batch of one year Diploma Programme.
      </div>

      <div className="cmys-mission-vision-container">
        <div className="cmys-mission-vision-each-box">
          <h2 className="dpsru-secondary-heading-for-every-page"> VISION</h2>
          “To see every citizen healthy through Spritual and Scientific healthy
          practices”
        </div>

        <div className="cmys-mission-vision-each-box">
          <h2 className="dpsru-secondary-heading-for-every-page"> MISSION</h2>
          “To encourage people practicing Meditation and Yoga Sciences and there
          by spreading Harmony, Happiness, Health and Mindfulness”.
        </div>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page"> OBTECTIVES</h2>
      <ol className="cmys-obtectives-ol">
        <li>
          To design, develop and impart various courses for Yoga, Meditation,
          and nutrition to promote healthy lifestyle in the society .
        </li>
        <li>To promote the concept of Meditation and Yoga as complementary</li>
        <li>
          To establish satellite centers for spreading the knowledge and
          awareness for such
        </li>
      </ol>

      <ul className="cmys-obtectives-ol">
        <li>
          Admission form{" "}
          <a href={admissionLink}>
            <strong>[Link]</strong>
          </a>
        </li>
        <li>
          Syllabus{" "}
          <a href={syllabus}>
            <strong>[Link]</strong>
          </a>
        </li>
      </ul>

      <div className="cmyp-entry-msg-image-container">
        <img src={addmission_msg01} alt="msg" />
        <img src={addmission_msg02} alt="msg" />
      </div>

      <div className="inner-container cmyp-important-instructions-container">
        <p> <h2>Important instructions for Admission to Diploma in Meditation and Yoga Sciences.</h2></p>

        <ol><li >Interested candidates must bring their original documents for verification along with 1 set of self-attested copies.</li><li >Candidates are required to bring 2 latest passport size photographs.</li><li >The admitted Candidates, after verification of documents will deposit the requisite fee of Rs. 1000 within 3 days through online mode. (Bank details will be provided by the centre to eligible candidates).</li><li >The reporting time for candidates shall be between 10:00 AM to 2:00 PM.</li><li >The preference will be given to the candidates belonging to the marginal society.</li><li >The candidates will be admitted on the first come first serve basis.</li></ol>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">
      Gallery
      </h2>

      <div className="cmyp-gallery-container">
        <img src={gal1} alt="gallary" />
        <img src={gal2} alt="gallary" />
        <img src={gal3} alt="gallary" />
        <img src={gal4} alt="gallary" />
        <img src={gal5} alt="gallary" />
        <img src={gal6} alt="gallary" />
      </div>
    </div>
  );
};

export default CenterforMeditationandYogaScience;
