import React from "react";

const AnnualReportOfRti = () => {
  return (
    <div className="dpsru-every-component-css">
      {/* <h2 className="dpsru-primary-heading-for-every-page">
        Yearly Record of Total RTI Applications w.e.f. 01.04.2021 to 31.03.2022
      </h2>

      <div className="information-as-be-prescribed-table-container">
        <table width="716">
          <tbody>
            
            <tr>
              <td width="47">
                <p>
                  <strong>S.No.</strong>
                </p>
              </td>
              <td width="187">
                <p>
                  <strong>RTI ID No.</strong>
                </p>
              </td>
              <td width="170">
                <p>
                  <strong>Received Date</strong>
                </p>
              </td>
              <td width="312">
                <p>
                  <strong>Disposal&nbsp; Date</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>1</p>
              </td>
              <td width="187">
                <p>2808</p>
              </td>
              <td width="170">
                <p>30.03.2021</p>
              </td>
              <td width="312">
                <p>09.06.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>2</p>
              </td>
              <td width="187">
                <p>3175</p>
              </td>
              <td width="170">
                <p>09.04.2021</p>
              </td>
              <td width="312">
                <p>24.06.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>3</p>
              </td>
              <td width="187">
                <p>75</p>
              </td>
              <td width="170">
                <p>30.06.2021</p>
              </td>
              <td width="312">
                <p>20.07.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>4</p>
              </td>
              <td width="187">
                <p>2298</p>
              </td>
              <td width="170">
                <p>11.03.2021</p>
              </td>
              <td width="312">
                <p>08.04.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>5</p>
              </td>
              <td width="187">
                <p>117</p>
              </td>
              <td width="170">
                <p>31.08.2021</p>
              </td>
              <td width="312">
                <p>28.09.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>6</p>
              </td>
              <td width="187">
                <p>106</p>
              </td>
              <td width="170">
                <p>13.08.2021</p>
              </td>
              <td width="312">
                <p>28.09.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>7</p>
              </td>
              <td width="187">
                <p>4936</p>
              </td>
              <td width="170">
                <p>30.08.2021</p>
              </td>
              <td width="312">
                <p>22.09.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>8</p>
              </td>
              <td width="187">
                <p>4937</p>
              </td>
              <td width="170">
                <p>31.08.2021</p>
              </td>
              <td width="312">
                <p>22.09.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>9</p>
              </td>
              <td width="187">
                <p>5589 &amp; 5590</p>
              </td>
              <td width="170">
                <p>27.09.2021</p>
              </td>
              <td width="312">
                <p>21.10.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>10</p>
              </td>
              <td width="187">
                <p>6874</p>
              </td>
              <td width="170">
                <p>18.10.2021</p>
              </td>
              <td width="312">
                <p>16.11.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>11</p>
              </td>
              <td width="187">
                <p>6938</p>
              </td>
              <td width="170">
                <p>21.10.2021</p>
              </td>
              <td width="312">
                <p>22.11.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>12</p>
              </td>
              <td width="187">
                <p>RTI App. No. DIPSAR/R/2021/60017</p>
              </td>
              <td width="170">
                <p>4.12.2021</p>
              </td>
              <td width="312">
                <p>21.12.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>13</p>
              </td>
              <td width="187">
                <p>180</p>
              </td>
              <td width="170">
                <p>16.12.2021</p>
              </td>
              <td width="312">
                <p>28.12.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>14</p>
              </td>
              <td width="187">
                <p>166</p>
              </td>
              <td width="170">
                <p>02.12.2021</p>
              </td>
              <td width="312">
                <p>16.12.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>15</p>
              </td>
              <td width="187">
                <p>8878</p>
              </td>
              <td width="170">
                <p>22.12.2021</p>
              </td>
              <td width="312">
                <p>28.12.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>16</p>
              </td>
              <td width="187">
                <p>7531</p>
              </td>
              <td width="170">
                <p>12.11.2021</p>
              </td>
              <td width="312">
                <p>01.12.2021</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>17</p>
              </td>
              <td width="187">
                <p>RTI App. No. DIPSR/R/2021/60018</p>
              </td>
              <td width="170">
                <p>06.12.2021</p>
              </td>
              <td width="312">
                <p>05.01.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>18</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSAR/R/2021/60020</p>
              </td>
              <td width="170">
                <p>31.12.2021</p>
              </td>
              <td width="312">
                <p>24.01.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>19</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60002</p>
              </td>
              <td width="170">
                <p>21.01.2022</p>
              </td>
              <td width="312">
                <p>14.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>20</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60001</p>
              </td>
              <td width="170">
                <p>14.01.2022</p>
              </td>
              <td width="312">
                <p>13.01.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>21</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60004</p>
              </td>
              <td width="170">
                <p>03.01.2022</p>
              </td>
              <td width="312">
                <p>11.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>22</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60006</p>
              </td>
              <td width="170">
                <p>31.01.2022</p>
              </td>
              <td width="312">
                <p>23.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>23</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60005</p>
              </td>
              <td width="170">
                <p>29.01.2022</p>
              </td>
              <td width="312">
                <p>23.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>24</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60007</p>
              </td>
              <td width="170">
                <p>11.02.2022</p>
              </td>
              <td width="312">
                <p>23.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>25</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60010</p>
              </td>
              <td width="170">
                <p>28.02.2022</p>
              </td>
              <td width="312">
                <p>09.03.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>26</p>
              </td>
              <td width="187">
                <p>RTI App No. DIPSR/R/2022/60009</p>
              </td>
              <td width="170">
                <p>21.02.2022</p>
              </td>
              <td width="312">
                <p>21.03.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>27</p>
              </td>
              <td width="187">
                <p>189</p>
              </td>
              <td width="170">
                <p>31.12.2021</p>
              </td>
              <td width="312">
                <p>23.02.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>28</p>
              </td>
              <td width="187">
                <p>565</p>
              </td>
              <td width="170">
                <p>21.01.2022</p>
              </td>
              <td width="312">
                <p>Valid IPO was not submitted by the Mr. D.K. Garg</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>29</p>
              </td>
              <td width="187">
                <p>DIPSR/R/2022/60011</p>
              </td>
              <td width="170">
                <p>21.03.2022</p>
              </td>
              <td width="312">
                <p>06.04.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>30</p>
              </td>
              <td width="187">
                <p>App No. DIPSR/R/2022/60012</p>
              </td>
              <td width="170">
                <p>21.03.2022</p>
              </td>
              <td width="312">
                <p>06.04.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>31</p>
              </td>
              <td width="187">
                <p>App No. DIPSR/R/2022/60013</p>
              </td>
              <td width="170">
                <p>23.03.20222</p>
              </td>
              <td width="312">
                <p>07.04.2022</p>
              </td>
            </tr>
            <tr>
              <td width="47">
                <p>32</p>
              </td>
              <td width="187">
                <p>16</p>
              </td>
              <td width="170">
                <p>22.03.2022</p>
              </td>
              <td width="312">
                <p>27.04.2022</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

<h1 className="dpsru-primary-heading-for-every-page">Yearly Record of Total RTI Application w.e.f. 01.04.2023 to 31.03.2024</h1>
    <table border="1">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>RTI ID No.</th>
                <th>Received Date</th>
                <th>Disposal Date</th>
            </tr>
        </thead>
        <tbody>
            <tr><td>1</td><td>RTI App No. DIPSR/R/2023/60019</td><td>13.03.2023</td><td>03.04.2023</td></tr>
            <tr><td>2</td><td>Diary No. - 11848</td><td>15.03.2023</td><td>11.05.2023</td></tr>
            <tr><td>3</td><td>RTI App No. DIPSR/R/2023/60132</td><td>21.03.2023</td><td>09.05.2023</td></tr>
            <tr><td>4</td><td>RTI App No. DIPSR/R/2023/60023</td><td>24.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>5</td><td>RTI App No. DIPSR/R/2023/60024</td><td>25.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>6</td><td>RTI App No. DIPSR/R/2023/60026</td><td>28.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>7</td><td>RTI App No. DIPSR/R/2023/60025</td><td>28.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>8</td><td>RTI App No. DIPSR/R/2023/60027</td><td>29.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>9</td><td>RTI App No. DIPSR/R/2023/60028</td><td>30.03.2023</td><td>17.04.2023</td></tr>
            <tr><td>10</td><td>RTI App No. DIPSR/R/2023/60029</td><td>01.04.2023</td><td>17.04.2023</td></tr>
            <tr><td>11</td><td>RTI App No. DIPSR/R/2023/60032</td><td>12.04.2023</td><td>11.05.2023</td></tr>
            <tr><td>12</td><td>RTI App No. DIPSR/R/2023/60033</td><td>13.04.2023</td><td>02.05.2023</td></tr>
            <tr><td>13</td><td>RTI App No. DIPSR/R/2023/60034</td><td>22.04.2023</td><td>08.05.2023</td></tr>
            <tr><td>14</td><td>RTI App No. DIPSR/R/2023/60035</td><td>28.04.2023</td><td>08.05.2023</td></tr>
            <tr><td>15</td><td>RTI App No. DIPSR/R/2023/60036</td><td>03.05.2023</td><td>12.05.2023</td></tr>
            <tr><td>16</td><td>Diary No. - 371</td><td>13.04.2023</td><td>22.05.2023</td></tr>
            <tr><td>17</td><td>RTI App No. DIPSR/R/2023/60037</td><td>05.05.2023</td><td>24.05.2023</td></tr>
            <tr><td>18</td><td>Diary No. - 962</td><td>17.04.2023</td><td>24.05.2023</td></tr>
            <tr><td>19</td><td>RTI App No. DIPSR/R/2023/60038</td><td>15.05.2023</td><td>22.05.2023</td></tr>
            <tr><td>20</td><td>RTI App No. DIPSR/R/2023/60031</td><td>08.04.2023</td><td>19.05.2023</td></tr>
            <tr><td>21</td><td>RTI App No. DIPSR/R/2023/60040</td><td>21.05.2023</td><td>01.06.2023</td></tr>
            <tr><td>22</td><td>RTI App No. DIPSR/R/2023/60039</td><td>18.05.2023</td><td>01.06.2023</td></tr>
            <tr><td>23</td><td>RTI App No. DIPSR/R/2023/60002</td><td>23.05.2023</td><td>12.06.2023</td></tr>
            <tr><td>24</td><td>Diary No. - 85</td><td>03.04.2023</td><td>06.04.2023</td></tr>
            <tr><td>25</td><td>RTI App No. DIPSR/R/2023/90001</td><td>18.05.2023</td><td>12.06.2023</td></tr>
            <tr><td>26</td><td>RTI App No. DIPSR/R/2023/60041</td><td>06.06.2023</td><td>22.06.2023</td></tr>
            <tr><td>27</td><td>RTI App No. DIPSR/R/2023/60042</td><td>28.06.2023</td><td>17.07.2023</td></tr>
            <tr><td>28</td><td>Diary No. - 61</td><td>23.06.2023</td><td>18.07.2023</td></tr>
            <tr><td>29</td><td>RTI App No. DIPSR/R/2023/90005</td><td>26.06.2023</td><td>18.07.2023</td></tr>
            <tr><td>30</td><td>RTI App No. DIPSR/R/2023/90004</td><td>21.06.2023</td><td>18.07.2023</td></tr>
            <tr><td>31</td><td>Diary No. - 2674</td><td>06.07.2023</td><td>06.07.2023</td></tr>
            <tr><td>32</td><td>RTI App No. DIPSR/R/2023/60043</td><td>09.07.2023</td><td>11.07.2023</td></tr>
            <tr><td>33</td><td>RTI App No. DIPSR/R/2023/80049</td><td>21.08.2023</td><td>30.10.2023</td></tr>
            <tr><td>34</td><td>RTI App No. DIPSR/R/2023/60044</td><td>14.08.2023</td><td>28.08.2023</td></tr>
            <tr><td>35</td><td>RTI App No. DIPSR/R/2023/60046</td><td>20.08.2023</td><td>05.09.2023</td></tr>
            <tr><td>36</td><td>RTI App No. DIPSR/R/2023/60045</td><td>17.08.2023</td><td>05.09.2023</td></tr>
            <tr><td>37</td><td>RTI App No. DIPSR/R/2023/60048</td><td>07.09.2023</td><td>12.09.2023</td></tr>
            <tr><td>38</td><td>RTI App No. DIPSR/R/2023/60047</td><td>31.08.2023</td><td>14.09.2023</td></tr>
            <tr><td>39</td><td>RTI App No. DIPSR/R/2023/60050</td><td>15.09.2023</td><td>27.09.2023</td></tr>
            <tr><td>40</td><td>RTI App No. DIPSR/R/2023/60049</td><td>15.09.2023</td><td>27.09.2023</td></tr>
            <tr><td>41</td><td>RTI App No. DIPSR/R/2023/60052</td><td>29.09.2023</td><td>10.10.2023</td></tr>
            <tr><td>42</td><td>RTI App No. DIPSR/R/2023/60059</td><td>29.09.2023</td><td>16.10.2023</td></tr>
            <tr><td>43</td><td>RTI App No. DIPSR/R/2023/60051</td><td>27.09.2023</td><td>30.10.2023</td></tr>
            <tr><td>44</td><td>RTI App No. DIPSR/R/2023/60053</td><td>02.10.2023</td><td>09.10.2023</td></tr>
            <tr><td>45</td><td>RTI App No. DIPSR/R/2023/60054</td><td>12.10.2023</td><td>31.10.2023</td></tr>
            <tr><td>46</td><td>Diary No. - 168</td><td>10.10.2023</td><td>23.10.2023</td></tr>
            <tr><td>47</td><td>Diary No. - 173</td><td>10.10.2023</td><td>23.10.2023</td></tr>
            <tr><td>48</td><td>RTI App No. DIPSR/R/2023/90006</td><td>12.10.2023</td><td>19.10.2023</td></tr>
            <tr><td>49</td><td>Diary No. - 166</td><td>10.10.2023</td><td>23.10.2023</td></tr>
            <tr><td>50</td><td>RTI App No. DIPSR/R/2023/60055</td><td>24.10.2023</td><td>31.10.2023</td></tr>
            <tr><td>51</td><td>RTI App No. DIPSR/R/2023/60057</td><td>04.11.2023</td><td>20.11.2023</td></tr>
            <tr><td>52</td><td>RTI App No. DIPSR/R/2023/60058</td><td>09.11.2023</td><td>21.11.2023</td></tr>
            <tr><td>53</td><td>RTI App No. DIPSR/R/2023/60056</td><td>30.10.2023</td><td>23.11.2023</td></tr>
            <tr><td>54</td><td>Diary No. - 7503</td><td>10.11.2023</td><td>24.11.2023</td></tr>
            <tr><td>55</td><td>Diary No. - 7749</td><td>20.11.2023</td><td>28.11.2023</td></tr>
            <tr><td>56</td><td>Diary No. - 7690</td><td>17.11.2023</td><td>28.11.2023</td></tr>
            <tr><td>57</td><td>Diary No. - 8883</td><td>19.12.2023</td><td></td></tr>
            <tr><td>58</td><td>Diary No. - 201</td><td>08.11.2023</td><td>15.12.2023</td></tr>
            <tr><td>59</td><td>RTI App No. DIPSR/R/2023/60059</td><td>11.11.2023</td><td>01.12.2023</td></tr>
            <tr><td>60</td><td>Diary No. - 9013</td><td>22.12.2023</td><td>01.01.2024</td></tr>
            <tr><td>61</td><td>Diary No. - 230</td><td>15.12.2023</td><td>08.01.2024</td></tr>
            <tr><td>62</td><td>RTI App No. DIPSR/R/2023/60060</td><td>20.12.2023</td><td>12.01.2024</td></tr>
            <tr><td>63</td><td>RTI App No. DIPSR/R/2024/60001</td><td>02.01.2024</td><td>11.01.2024</td></tr>
            <tr><td>64</td><td>Diary No. - 9233</td><td>03.01.2024</td><td>16.01.2024</td></tr>
            <tr><td>65</td><td>RTI App No. DIPSR/R/2024/60002</td><td>09.01.2024</td><td>16.01.2024</td></tr>
            <tr><td>66</td><td>DOPOT/R/2024/60001</td><td>04.01.2024</td><td>17.01.2024</td></tr>
            <tr><td>67</td><td>Diary No. - 9226</td><td>03.01.2024</td><td>30.01.2024</td></tr>
            <tr><td>68</td><td>Diary No. - 9498</td><td>11.01.2024</td><td>23.01.2024</td></tr>
            <tr><td>69</td><td>Diary No. - 10357</td><td>08.02.2024</td><td>22.02.2024</td></tr>
            <tr><td>70</td><td>DOPOT/R/2024/60019</td><td>11.02.2024</td><td>29.02.2024</td></tr>
            <tr><td>71</td><td>Diary No. - 309</td><td>22.02.2024</td><td>11.03.2024</td></tr>
            <tr><td>72</td><td>RTI App No. DIPSR/R/2024/60005</td><td>23.02.2024</td><td>11.03.2024</td></tr>
            <tr><td>73</td><td>RTI App No. DIPSR/R/2024/60004</td><td>20.02.2024</td><td>11.03.2024</td></tr>
            <tr><td>74</td><td>RTI App No. DIPSR/R/2024/60003</td><td>16.02.2024</td><td>11.03.2024</td></tr>
            <tr><td>75</td><td>RTI App No. DIPSR/R/2024/80001</td><td>21.02.2024</td><td>26.02.2024</td></tr>
            <tr><td>76</td><td>Diary No. - 9610</td><td>15.01.2024</td><td>07.02.2024</td></tr>
            <tr><td>77</td><td>RTI App No. DIPSR/R/2024/60007</td><td>05.03.2024</td><td>14.03.2024</td></tr>
            <tr><td>78</td><td>RTI App No. DIPSR/R/2024/60010</td><td>13.03.2024</td><td>26.03.2024</td></tr>
            <tr><td>79</td><td>RTI App No. DIPSR/R/2024/60009</td><td>12.03.2024</td><td>26.03.2024</td></tr>
            <tr><td>80</td><td>RTI App No. DIPSR/R/2024/60006</td><td>27.02.2024</td><td>11.03.2024</td></tr>
            <tr><td>81</td><td>Diary No. - 10929</td><td>27.02.2024</td><td>26.03.2024</td></tr>
            <tr><td>82</td><td>RTI App No. DIPSR/R/2024/60011</td><td>14.03.2024</td><td>09.04.2024</td></tr>
            <tr><td>83</td><td>RTI App No. DIPSR/R/2024/60008</td><td>07.03.2024</td><td>05.04.2024</td></tr>
        </tbody>
    </table>


    </div>
  );
};

export default AnnualReportOfRti;
