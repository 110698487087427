import React from "react";
import ugtt01 from "./Dipsar time table files/time-table-of-B-pharm-5-and-7-semester-.pdf";
import ugtt02 from "./Dipsar time table files/time-table-of-B-pharm-1-semester-1.pdf";
import ugtt03 from "./Dipsar time table files/time-table-of-B-pharm-1-semester-1.pdf";
import ugtt04 from "./Dipsar time table files/B.-Pharma-Time-table-session-2022-23.pdf";

import pgtt01 from "./Dipsar time table files/time-table-session-2022-2023-M-pharm-sem.-1.pdf";
import pgtt03 from "./Dipsar time table files/time-table-session-2022-2023-M-pharm-sem-1.pdf";
import pgtt04 from "./Dipsar time table files/time-table-session-2022-2023-M-pharm-sem-2.pdf";

import dtt01 from "./Dipsar time table files/time-table-of-D-pharm-1st-semester-.pdf";
import dtt02 from "./Dipsar time table files/time-table-of-D-pharm-2-year1.pdf";
import dtt03 from "./Dipsar time table files/Time-table-of-D.-Pharma-I-year.pdf";
import dtt04 from "./Dipsar time table files/Time-table-of-D.-Pharma-II-year.pdf";

const DipsarTimeTable = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Time Table (DIPSAR)
      </h2>

      <div className="results-data-link-container">
        <h2 className="dpsru-secondary-heading-for-every-page">UG Program</h2>

        <ul>
          <li>
            <a href={ugtt01}>B. Pharm 5 7th semester 2 Nov 2022</a>
          </li>
          <li>
            <a href={ugtt02}>B. Pharm 1st semester 17 Oct 2022 </a>
          </li>
          <li>
            <a href={ugtt03}>B. Pharm 1st semester 30 Sep 2022 </a>
          </li>
          <li>
            <a href={ugtt04}>B. Pharm 1st semester 10 Sep 2022-2023 </a>
          </li>
        </ul>

        <h2 className="dpsru-secondary-heading-for-every-page">PG Program</h2>

        <ul>
          <li>
            <a href={pgtt01}>M. Pharm 1st semester 20 Dec 2022-23</a>
          </li>
          <li>
            <a href={pgtt01}>M. Pharm 1st semester 8 Feb 2022-23</a>
          </li>
          <li>
            <a href={pgtt03}>M. Pharm 1st semester 8 Feb 2022-23</a>
          </li>
          <li>
            <a href={pgtt04}>M. Pharm 1st semester 4 May 2022-23</a>
          </li>
        </ul>
        <h2 className="dpsru-secondary-heading-for-every-page">Diploma</h2>

        <ul>
          <li>
            <a href={dtt01}>
              D. Pharm 1st Year 2 Nov 2022{" "}
            </a>
          </li>
          <li>
            <a href={dtt02}>
              D. Pharm 2nd Year 2 Nov 2022{" "}
            </a>
          </li>
          <li>
            <a href={dtt03}>
              D. Pharm 1st Year 10Aug 2023{" "}
            </a>
          </li>
          <li>
            <a href={dtt04}>
              D. Pharm 2nd Year 10Aug 2023{" "}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DipsarTimeTable;
