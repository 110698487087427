import React, { useState } from "react";

import profimg from "./IMG_0028-scaled-e1686638404122.jpg";

const DrAtulJain = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Atul Jain</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmaceutics
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            atuljain [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?hl=en&user=cei1sVoAAAAJ">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.scopus.com/authid/detail.uri?authorId=57189266492">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0003-2511-3856">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        M. Pharm, Ph.D(Pharmaceutics)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Quality by design (Qbd)-driven development of nanostructured drug
        delivery systems, Lipomer, Conjugation, Receptor mediated drug delivery
        systems, Bioavailability enhancement of poorly
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Name of the Student</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Name of the Guide</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Title of the project</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Rajat </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Dr Meenakshi K Chauhan, </strong>
                </p>
                <p>
                  <strong>Dr Gaurav K Jain and </strong>
                </p>
                <p>
                  <strong>Dr Atul Jain </strong>
                </p>
              </td>
              <td>
                <p>
                  Formulation and evaluation of herbal cigarettes: Heathier
                  alternative for conventional cigarettes
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Kanika </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Dr Gaurav K Jain and Dr Atul Jain </strong>
                </p>
              </td>
              <td>
                <p>
                  Development, Characterization and Evaluation of Polyherbal
                  Mixture for its Galactagogue Activity
                </p>
              </td>
            </tr>
          </tbody>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              <p>
                Chand P, Kumar H, Jain R, Jain A, Jain V. Flavonoids: As
                omnipotent candidates for cancer management. South African
                Journal of Botany. 2023; 158:334-46.
              </p>
            </li>
            <li>
              <p>
                Pant A, Sharma G, Saini S, Jain A, Barnwal RP, Singh G, et al.
                Quality by Design (QbD)‐Steered Development and Validation of
                Analytical and Bioanalytical Methods for Raloxifene: Application
                of Monte Carlo Simulations and Variance Inflation Factor.
                Biomedical Chromatography. 2023: e5641.
              </p>
            </li>
            <li>
              <p>
                Kumari A, Raina N, Wahi A, Goh KW, Sharma P, Nagpal R, et al.
                Wound-Healing Effects of Curcumin and Its Nanoformulations: A
                Comprehensive Review. Pharmaceutics. 2022;14(11):2288.
              </p>
            </li>
            <li>
              <p>
                Kumar D, Singh H, Jain A, Sharma V, Bhardwaj N, Puri S, et al.
                Response surface methodology for removal of copper (II) ions
                from aqueous solutions by DMSA@ SiO2@ Fe3O4 nanocomposite.
                Chemical Papers. 2023;77(4):1907-20.
              </p>
            </li>
            <li>
              <p>
                D. Kumar, H. Singh, A. Jain, V. Sharma, N. Bharadwaj, S. Puri,
                M. Khatri. Response surface methodology for removal of copper
                ions from aueous solution by DMSA@SiO2@Fe3O4 nanocomposite.
                Chemical Papers, 2022 IF 2.2
              </p>
            </li>
            <li>
              A. Kumari, N. Raina, A. Wahi, K. W. Goh, P. Sharma , R.
              Nagpal, A. Jain, L. C. Ming, M. Gupta.Wound-Healing Effects of
              Curcumin and Its Nanoformulations: A Comprehensive Review.
              Pharmaceutics, 2022, IF 6. 29
            </li>
            <li>
              S. Saini, T. Sharma, A. Jain, H. Kaur, O. P. Katare, B.
              Singh. Systematically designed chitosan-coated solid lipid
              nanoparticles of ferulic acid for effective management of
              Alzheimer’s Disease: A Preclinical Evidence. Colloids Surf B
              Biointerfaces. 2021, IF 5.268 , ISSN No 09277765, DOI:
              10.1016/j.colsurfb.2021.111838
            </li>
            <li>
              A. Jain, T. Sharma, R. Kumar, O.P Katare, B. Singh.
              Raloxifene-loaded SLNs with enhanced biopharmaceutical potential:
              QbD steered development, in vitro evaluation, in vivo
              pharmacokinetics and IVIVC. Drug Deliv Trans Res 2021. IF 4.6 ,
              ISSN No 21903948, DOI: 10.1007/s13346-021-00990-x
            </li>
            <li>
              H. Singh, A. Jain, J. Kaur, S. K. Arya, M. Khatri.
              Adsorptive removal of oil from water using SPIONs–chitosan
              nanocomposite: Kinetics and process optimization. Appl. Nanosci.
              2020,10:1281-1295. IF 3.674, ISSN 2190-5517, DOI:
              10.1007/s13204-019-01195-y
            </li>
            <li>
              M. Kaurav, R. Kumar,A. Jain,R. S. Pandey. Novel biomimetic
              reconstituted built-in adjuvanted Hepatitis B vaccine for
              transcutaneous immunization. 2019, J. Pharma Sci, 108, ,
              3550-3559. IF 3.43, ISSN 1520-6017, DOI:
              10.1016/j.xphs.2019.07.007
            </li>
            <li>
              T. Sharma, O. P. Katare, A. Jain, S. Jain, D. Chaudhari, B.
              Borges, B. Bhoop. QbD-steered development of biotin-conjugated
              nanostructured lipid carriers for oral delivery of chrysin: role
              of surface modification in improving biopharmaceutical
              performance. Colloids Surf B Biointerfaces. 2021; 197:111429. IF
              5.268 , ISSN No 09277765, DOI: 10.1016/j.colsurfb.2020.111429
            </li>
            <li>
              T. Sharma, A. Jain, S. Saini, O.P. Katare and B. Singh.
              Implementation of analytical quality‐by‐design and green
              analytical chemistry approaches for the development of robust and
              ecofriendly UHPLC analytical method for quantification of chrysin.
              Sep. Sci. Plus, 2020,3:384-398 IF- NA, ISSN No 2573-1815, DOI:
              10.1002/sscp.202000028
            </li>
            <li>
              T Sharma, S Saini, A Jain, R Kaur B Singh. Developing Lipidic
              Nanocarriers using formulation by design (FbD), Spinco Biotech
              Cutting Edge, February 2020 IF-NA, ISSN-NA
            </li>
            <li>
              B. Singh, T Sharma, R. Kaur, S. Saini, A. Jain, S. Beg, K. Raza
              and B. Singh Systematic development of drug nanocargos using
              formulation by design (FbD): An updated overview. Crit Rev Ther
              Drug Carrier Syst.2020, 37(3):229–269. IF 4.74 , ISSN 07434863,
              DOI: 10.1615/CritRevTherDrugCarrierSyst.2020032040
            </li>
            <li>
              A. Jain, T. Sharma, S. Saini, O. P. Katare, V. Soni and B. Singh
              Nanocargoes: A burgeoning quest in cancer management. Curr.
              Nanomed. 2020,10 (2):149- 163. IF- NA, ISSN 2468-1881, DOI:
              10.2174/2468187309666190823160241
            </li>
            <li>
              T. Sharma, A. Jain, S. Saini, O.P. Katare, B. Singh.
              Supersaturated LFCS type III self-emulsifying delivery systems of
              sorafenib tosylate with improved biopharmaceutical performance:
              QbD-enabled development and evaluation. Drug Deliv.Transl. Res.
              2020,10:839–861. IF 4.6 , ISSN 21903948, DOI:
              10.1007/s13346-020-00772-x
            </li>
            <li>
              S. Beg, S. Dhiman, T Sharma, A. Jain, R. K. Sharma, A. Jain and B.
              Singh. Stimuli responsive in situ gelling systems loaded with PLGA
              nanoparticles of moxifloxacin hydrochloride for effective
              treatment of periodontitis. AAPS PharmSciTech. 2020,21(3):76. IF
              3.246, ISSN No-1530-9932, DOI 10.1208/s12249-019-1613-7
            </li>
            <li>
              S. Saini, T. Sharma, R. Kaur, A. Jain and B. Singh. Nanocountered
              lipid-based drug delivery strategies for crossing blood brain
              barrier in neurodegenerartion disorder. The Pharma Review.
              2019,27-32. IF-NA, ISSN No – 0973-399X
            </li>
            <li>
              A. Jain, S. Saini, R Kumar, T. Sharma, R. Swami, O. P. Katare and
              B. Singh. Phospholipid-based complex of raloxifene with enhanced
              biopharmaceutical potential: Synthesis, characterization and
              preclinical assessment. Int J Pharm. 2019, 571, 118698. IF 5.875
              ISSN No-0378-5173
            </li>
            <li>
              A. Jain, T. Sharma, G Sharma, R. K. Khurana, O. P. Katare and B.
              Singh. QbD-driven analytical method development and validation for
              raloxifene hydrochloride in pure drug and solid oral dosage form.
              2019, Anal Chem. Lett. 2019, 9, 463-477. IF , ISSN No-2230-7532
            </li>
            <li>
              A. Jain, S. Beg, S. Saini, T. Sharma, O.P. Katare and B. Singh.
              Application of chemometric approach for QbD-Enabled development
              and validation of an RP-HPLC method for estimation of
              methotrexate. J Liq Chromatogr Relat Technol, 2019,1-11. IF 1.312
              , ISSN No – 1520572X
            </li>
            <li>
              Parmar, A. Jain, S. Uppal, S.K. Mehta, K. Kaur, B. Singh, R.
              Sandhir and S. Sharma. Anti-proliferate and apoptosis triggering
              potential of methotrexate-transferrin conjugate encapsulated PLGA
              nanoparticles with enhanced cellular uptake by high affinity
              folate receptors. Artif Cells Nanomed Biotechnol.2018, 46:704-719.
              IF 5.678 , ISSN No – 2169-141X
            </li>
            <li>
              A. Jain, R. Kaur, S. Beg, V. Kushwah, S. Jain and B. Singh. Novel
              cationic supersaturable nanomicellar systems of raloxifene
              hydrochloride with enhanced biopharmaceutical attributes. Drug
              Deliv Trans Res.2018, 8: 670-692. IF 4.6 , ISSN No – 21903948
            </li>
            <li>
              A. Jain, G. Sharma, V. Kushwah, G. Ghoshal, A. Jain, B. Singh, US
              Shivhare, S Jain, O. P. Katare. Beta carotene-loaded zein
              nanoparticles to improve the biopharmaceutical attributes and to
              abolish the toxicity of methotrexate: a preclinical study for
              breast cancer. Artif Cells Nanomed Biotechnol. 2018, 46:402-412.
              IF 5.678 , ISSN No – 2169-141X
            </li>
            <li>
              R. K. Khurana, A. Jain, A. Jain, T Sharma, B. Singh and P.
              Kesharwani. Administration of antioxidants in cancer: Debate of
              the decade. Drug Discov Today. 2018, 23:763-770. IF 7.851, ISSN No
              – 18785832
            </li>
            <li>
              T. Sharma, R.K. Khurana, A. Jain, O. P. Katare and B. Singh.
              Development of a validated liquid chromatographic method for
              quantification of sorafenib tosylate in the presence of
              stress-induced degradation products and in biological matrix
              employing analytical quality by design (AqbD) approach. Biomed
              Chromatogr. 2018, 32:e4169. IF 1.902 , ISSN No – 10990801
            </li>
            <li>
              P. Kesharwani, A. Jain, A. Jain, A.K. Jain, N.K. Garg, R.K.
              Tekade, R.R.S. Thakur and A. K. Iyer. Cationic bovine serum
              albumin (CBA) conjugated PLGA nanoparticles for extended delivery
              of methotrexate into brain tumor. RSC Adv. 2016, 6, 89040-89050.
              IF 3.361 , ISSN No – 2046-2069
            </li>
            <li>
              A. Jain, A. Jain, P. Parajuli, V. Mishra, G. Ghoshal, B. Singh, U.
              S. Shivhare, O. P. Katare and P. Kesharwani. Recent advances in
              galactose-engineered nanocarriers for the site-specific delivery
              of siRNA and anticancer drugs. Drug Discov. Today.
              2018,23:960-973. IF 7.851, ISSN No – 18785832
            </li>
            <li>
              A. K, Jain, A. Jain, N.K. Garg, A. Agarwal, A. Jain, S.A. Jain,
              R.K. Tyagi, R.K. Jain, H. Agrawal and G.P. Agrawal. Adapalene
              loaded solid lipid nanoparticles gel: An effective approach for
              acne treatment. Colloids Surf B Biointerfaces. 2014,121:222-229.
              IF 5.268 , ISSN No 09277765
            </li>
            <li>
              A. Jain, N.K. Garg, A. Jain, P. Kesharwani, A.K. Jain, P.
              Nirbhavane and R.K. Tyagi. A synergistic approach of
              adapalene-loaded nanostructured lipid carriers, and vitamin C co-
              administration for treating acne. Drug Dev Ind Pharm.
              2016,42(6):897-905. IF 2.295, ISSN No – 03639045
            </li>
            <li>
              A. Jain, P. Kesharwani, N.K. Garg, A. Jain, S.A Jain, A. K, Jain,
              P. Nirbhavane, R. Ghanghoria, R. K. Tyagi and O.P. Katare.
              Galactose engineered solid lipid nanoparticles for targeted
              delivery of doxorubicin. Colloids Surf B Biointerfaces.
              2015,134:47-58. IF 5.268 , ISSN No 09277765
            </li>
            <li>
              S. Beg, A. Jain, R. Kaur, S. S. Panda, O. P. Katare, B. Singh.
              QbD-driven development and validation of an efficient
              bioanalytical method for estimation of olmesartan medoxomil. J
              Chromatogr Sci Rel Tech. 2015,53:1048-1059. IF 1.312 , ISSN-
              1520572X
            </li>
            <li>
              A. Jain, P. Kesharwani, N. K.Garg, A. Jain, P. Nirbhavane, N.
              Dwivedi, S. Banerjee, A.K. Iyer and Iqbal Mohd Amin M. C.
              Nano-constructed carriers loaded with antioxidant: Boon for
              cardiovascular system. Curr Pharm Des. 2015,21(30):4456-64. IF
              3.116 , ISSN 1381-6128
            </li>
            <li>
              A. Jain, A. Jain, N. K. Garg, R. K. Tyagi, B. Singh, O. P. Katare,
              T. J. Webstere and V. Soni. Surface engineered polymeric
              nanocarriers mediate the delivery of transferrin–methotrexate
              conjugates for an improved understanding of brain cancer. Acta
              Biomater. 2015, 24: 140-151. IF 8.947 , ISSN 17427061
            </li>
            <li>
              S. Beg, M. Rahman, A. Jain, S. Saini, P. Midoux, C. Pichon, F.
              Jalees Ahmad and S. Akhter. Nanoporous metal organic frameworks as
              hybrid polymer-metal composites for drug delivery and biomedical
              applications. Drug Discov. Today. 2017,22:625-637. IF 7.851, ISSN
              18785832
            </li>
            <li>
              A. Jain, A. Jain, N. K. Garg, R. K. Tyagi, B. Singh, O. P. Katare,
              T. J. Webstere and V. Soni. Nanoscale drug carriers bypass the
              blood-brain barrier. Mater. Today, 2015. IF 26.42, ISSN 13697021
            </li>
            <li>
              B. Singh, A. Jain and S. Beg. Lipid-based nanostructured drug
              delivery systems for oral intake. Chronicle PharmaBiz-Special
              Issue: Nanomedicine. 2014, 14(6), 34-39. IF- NA, ISSN No – NA
            </li>
            <li>
              B. Singh, S. Beg, G. Sharma, A. Jain and P. Negi. Holistic
              application of QbD for pharma product development excellence and
              regulatory compliance, Nirma Univ J Pharm Sci. 2014, 1(1), 19-35.
              IF , ISSN 2348 – 4012
            </li>
            <li>
              S. Neekhra, S. Jain, S.A. Jain, N.K. Garg, A. Jain, V. Jain, P.
              Jain, and A. Jain. Anti- nociceptive activity of spirulina
              platensis in mice. Int J Pharmacogn. 2014, 1, 8, 507-510. IF NA,
              ISSN No – 2348-3962
            </li>
            <li>
              S. Neekhra, V. Jain, P. Jain, S. Jain, S. A. Jain, A. Jain, and A.
              Jain. Assessment of anxiolytic potential of spirulina platensis.
              Int J Pharm Sci Res. 2015,70, 4061-4065. IF NA, ISSN No –
              2394-1502.
            </li>
            <li>
              R. Kaur, T. Sharma, R. Kaur, A. Jain and B. Singh. Delivering
              antimicrobial drugs to lungs through inhalation: An overview. Nov
              2019 IF NA , ISSN
            </li>
            <li>
              T. Sharma, R. Kaur, S. Saini, A. Jain and B. Singh. Strategic
              lipid-based delivery intervention for enhancing oral
              bioavailability of BCS II/IV drugs. Pharma Times, Sept 2019,51 IF
              NA , ISSN No 316849
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <p><strong>Book chapters </strong></p><ul><li>Jain, J. Kaur, K. Shah, N. S Chauhan, M. A. Babu, <strong>T. Sharma</strong>, Impact of Nanotechnology on Herbal Bioactives: An Overview, In Nanophytomedicine: Nanotechnology for Herbal Product Development and Value Addition, Elsevier, 2023, (In Press).</li><li>Sharma, J. Kaur, B. Prajapati, and <strong>A Jain,</strong> Lipid-Based Excipients: The Holy Grail in Pharmaceutical Product Development, In Lipid-Based Drug Delivery Systems: Principles and Applications, Jenny Stanford Publishing Pte Ltd, 2023 (In Press).</li><li>Ahmad, R. Swami, T. Sharma and <strong>A. Jain</strong>, Nanocarriers in the Management of Harmonal Cancer, In: Hormone Related Cancer Mechanistic and Nanomedicines. Springer 2023, 13-48.</li></ul>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p><b>SCHOLASTIC DISTINCTIONS OF ACADEMIC MERIT</b></p><ul><li  >Qualified GATE 2008 (AIR 199)</li><li  >Qualified GATE 2007 (AIR 985)</li><li  >Recipient of Junior Research Fellowship, UGC for pursuing M Pharm</li><li  >Received Jaswant Singh Gill Fellowship, UIPS for financial support during PhD</li> <b/></ul><p><b>AWARDS</b></p><ul><li  >Sipra Innovation Travel Award, M/s Sipra Labs, Hyderabad, July 2016</li><li  >HariRam Arora Award, Panjab University, Chandigarh, for best scientific Publication, February 2018</li><li  >Best Poster Presentation Award, IMTECH, Chandigarh, February 2018</li><li  >Dr P. D. Sethi Award, for Best Paper Publication in Pharma Analysis, 2018</li><li  >Best Oral Poster Presentation Award, IGNTU, September 2021</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrAtulJain;
