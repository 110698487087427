import React from 'react'
import './WorldHeartDay.css'

import gal1 from './whd images/image01.jpg'
import gal2 from './whd images/image02.jpg'
import gal3 from './whd images/image03.jpg'
import gal4 from './whd images/image04.jpg'

import gal5 from './whd images/image05.jpg'
import gal6 from './whd images/image06.jpg'
import gal7 from './whd images/image07.jpg'

import gal8 from './whd images/image08.jpg'
import gal9 from './whd images/image09.jpg'
import gal10 from './whd images/image10.jpg'


import gal11 from './whd images/image11.jpg'
import gal12 from './whd images/image12.jpg'


const WorldHeartDay = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">IACS India Section and DPSRU Celebrated World Heart Day</h2>
      <h2 className="world-heart-day-second-heading-head-name">Deepika Pandey, Mukesh Nandave, Ramesh K. Goyal</h2>

      <div className="whd-first-text-container">
      
				A Healthy Heart Campaign was organized at Delhi Pharmaceutical Sciences and Research University (DPSRU), New Delhi, to mark <strong>World Heart Day (29<sup>th</sup> September) </strong>in collaboration with International Academy of Cardiovascular Sciences (IACS)-India Section and the Rotary Club of Delhi Central. <br /> <br />Aligning with the aim of World Heart Day to spread awareness of heart disease, educate the community, and unite people for this noble cause, Prof. Ramesh K. Goyal, Vice Chancellor, DPSRU, inaugurated the event in the presence of all the faculty, staff, research scholars, students, and other participants, releasing balloons followed by a healthy Heart Walk, and Heart Health Talk delivered by the cardiologist.				
      </div>

      <div className="worldHeartDay-first-image-container ">
        <img src={gal1} alt="gallary" />
        <img src={gal2} alt="gallary" />
        <img src={gal3} alt="gallary" />
        <img src={gal4} alt="gallary" />
        </div>


      <div className="whd-first-text-container">Cardiologist talk of the revered speaker, Chairman, Cardiology, PSRI Heart Institute, New Delhi, Padma Bhusan Prof. (Dr.) K. K. Talwar was delivered over innovation, experience, and outstanding outcomes in heart failure therapy over a period of time<b>. </b>The session was chaired by Cardiologist Dr. (Prof.) Raja Babu Panwar, Former Vice-Chancellor, Rajasthan University of Health Sciences, Jaipur (Rajasthan), India. Prof. S.K. Maulik, Pharmacology Deptt. AIIMS, New Delhi also graced the occasion. Prof. Ramesh K Goyal, Vice Chancellor and Registrar, DPSRU, New Delhi, extended the welcome to the chairperson, chief guest and the speaker.</div>


      <div className="worldHeartDay-second-image-container">
        <div className="image-box-whd-second">
        <img src={gal5} alt="gallary" />
        <p>
        Talk by Padma Bhusan <br />
Dr. K.K. Talwar

        </p>
        </div>

        <div className="image-box-whd-second">
        <img src={gal6} alt="gallary" />
        <p>
        Felicitation of the speaker by Prof. Goyal & Prof. Panwar
        </p>
        </div>

        <div className="image-box-whd-second">
        <img src={gal7} alt="gallary" />
        <p>
        Felicitation of the Prof. Panwar
        </p>
        </div>
        </div>


      <br /> <br />
      <div className="whd-first-text-container">The event was officially introduced by Dr. Mukesh Nandave, Secretary General, IACS-India Section and Associate Dean (Research & Consultancy), DPSRU. Spreading awareness on heart health, he addressed the benefits of light and a healthy diet to maintain good heart health. Prof. SK Maulik introduced the audience on life sketch of
 <br /> <br />
As it is rightly said, “the best way to keep your heart in a healthy state is by eating right, sleeping right”. More than 200 delegates showed their adherence to the Heart Walk campaign led by Prof. Goyal and Prof. Panwar and other dignitaries. <br /><br />

Through his talk, Dr. Talwar delivered awareness about the prevalence of heart failure (HF), an alarming issue worldwide, and its rising burden. He narrated about how our daily lifestyle affects our heart health, and how creating an action plan including a healthy diet, regular exercise, and practicing spirituality could be an effective approach in the prevention of heart diseases. Talking about advancements in Cardiovascular Research, Prof. Talwar addressed the widely adopted methods to evaluate HF and successful treatments with established cases.</div>  


<div className="cmyp-gallery-container">
        <img src={gal8} alt="gallary" />
        <img src={gal9} alt="gallary" />
        <img src={gal10} alt="gallary" />
        </div>

<div className="whd-first-text-container">
Talking about advancements in cardiovascular research, Prof. Goyal addressed the research prospects developed and going on in cardiology sciences at DPSRU. He also put forth the idea of excelling in the direction of more development. The speaker was conferred with Prof. S.K. Gupta Oration in the field of Clinical Research.
Along with successfully marking the</div> 

<div className='whd-single-image-container'>
<img src={gal11} alt="" />
</div>

<div className="whd-first-text-container">
World Heart event, a new beginning in academic programs and research collaborations was formalized with MoU between the DPSRU and PSRI Hospitals, New Delhi. The MoU was signed in the presence of the Vice-Chancellor, the Registrar, and the Dr. K. K. Talwar, Chairman, Cardiology, PSRI Hospital. Simultaneously, posters were displayed reflecting the advancements in cardiovascular research and various approaches to healthy diet and lifestyle to maintain good heart health. The event came to an end, paying tribute to the Nation by singing the National Anthem.
</div>
<div className='whd-single-image-container'>
<img src={gal12} alt="" />
</div>
</div>
  )
}

export default WorldHeartDay