import React from "react";
import "./G20MahaFoodFestival.css";

import dpsruLogo from "./Maha Food Festival G20 Data/dpsru-logo.png";
import amritMahotsav from "./Maha Food Festival G20 Data/75-azadi-ka-amrit-mahotsav.png";
import nssLogo from "./Maha Food Festival G20 Data/national-service-scheme.png";

import mff1 from './Maha Food Festival G20 Data/mahaFoodFestivalPic1.jpeg'
import mff2 from './Maha Food Festival G20 Data/MahaFoodFestivalPic2.jpeg'
import mff3 from './Maha Food Festival G20 Data/MahaFoodFestivalPic3.jpeg'
import mff4 from './Maha Food Festival G20 Data/MahaFoodFestivalPic4.jpeg'
import mff5 from './Maha Food Festival G20 Data/MahaFoodFestivalPic5.png'
import mff6 from './Maha Food Festival G20 Data/MahaFoodFestivalPic6.jpeg'
import mff7 from './Maha Food Festival G20 Data/MahaFoodFestivalPic7.jpeg'

const G20MahaFoodFestival = () => {
  return (
    <div className="G20MahaFoodFestival-component">
      <div className="upper-logo-container-of-g20-maha-food-festival">
        <img src={dpsruLogo} alt="Dpsru Logo" />
        <img src={amritMahotsav} alt="amrit Mahotsav" />
        <img src={nssLogo} alt="Nss" />
      </div>

      <h2 className="g20-maha-food-festival-heading">
        G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU
      </h2>

      <p className="g20-maha-food-festival-paragrauph">
        Delhi Pharmaceutical Sciences and Research University organized G20-
        MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY”on 28th March 2023 as part
        of G20 activities celebrating India’s G20 Presidency. Event was started
        with lamp lightning ceremony and Saraswati Pooja. During this event a
        food fest of three countries of G20 was organized in which more than 25
        teams of students participated and presented a variety of cuisines of
        these member countries. A poster presentation competition was also
        organized on the theme “Plants as Food and Medicine from G20 Countries”
        and more than 35 posters have been prepared and presented by students.
        In addition, more than 10 teams from different departments have
        participated in Tug of war organized during this event. Students and
        participants also enjoyed many soulful cultural performances. The
        purpose of the event was to create awareness among the students about
        various international cuisines and provide knowledge about nutritional
        and food varieties. <br />
        <br />
        These activities will contribute to the theme of G20 this year “Vasudev
        Kutumbakam”- One Earth · One Family · One Future. During the event
        Honorable Vice Chancellor Prof R.K. Goyal, Registrar Dr. O.P. Shukla,
        Nodal Officer Dr. Saurabh Dahiya, Deans, Directors and co-ordinators of
        the events Dr. Mahaveer Dhobi, Dr. Amrita Mishra, Dr. Arya Lakshmi, Dr.
        Saveena, Dr. Gagandeep and Mr. Baljinder Singh, were present. Prof R.K.
        Goyal appreciated the students for their participation and congratulated
        the nodal officer and coordinators for successful organization of the
        event. Dr. Dahiya Nodal Officer, made the students aware about the
        importance of digitalization of cultural memories and the related
        outcomes of the Culture Working Group meeting held at Khajuraho. He
        stressed that creating a repository of cultural practices and preserving
        indigenous languages, foods, arts as tools of living heritage is the
        need of the hour.
      </p>

      <div className="galery-conteiner-of-g20-maha-food-festival">
        <h3 className="galery-heading-of-g-20-maha-food-f">
        Gallery
        </h3>

        <div className="image-container-of-g-20-gallery">
          <img src={mff1} alt="galery" />
          <img src={mff2} alt="galery" />
          <img src={mff3} alt="galery" />
          <img src={mff4} alt="galery" />
          <img src={mff5} alt="galery" />
          <img src={mff6} alt="galery" />
          <img src={mff7} alt="galery" />
        </div>
      </div>
    </div>
  );
};
export default G20MahaFoodFestival;
