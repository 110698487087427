import React from "react";
import stImgDpsru from '../NaacFiles/621.jpeg'
import './StrategicPlanOfDpsru.css'

function StrategicPlanOfDpsru() {
  return (
    <div className="strategic-plan-of-dpsru-component">
      <h2 className="dpsru-primary-heading-for-every-page">Strategic Plan of DPSRU</h2>

      <div className="straqtegic-plan-dpsru-image-container">
        <img src={stImgDpsru} alt="Strategic Plan of DPSRU Impage" />
      </div>
    </div>
  );
}

export default StrategicPlanOfDpsru;
