import React, { useState } from "react";

import profimg from "./IMG_0627-scaled-e1688470177320.jpg";

const DrGunjanSharma = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Dr. Gunjan Sharma</h2>
      <h3 className="facult-designation-for-each-container">
        Assistant Professor | Department of Pharmacology
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "} 
              gunjansharma [at] dpsru [dot] edu [dot] in
         
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              {" "}
              <a href="https://scholar.google.com/citations?hl=en&user=0-qakw8AAAAJ">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.scopus.com/authid/detail.uri?authorId=57653136600">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0001-6145-3175">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        PhD in Pharmaceutical Sciences
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Proteomic studies of Diabetes, Immunological expression alteration in
        metabolic disorders
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              To explore the possible role of identified protein-based
              biomarkers for Type 2 Diabetes Mellitus in gut microbiota.
            </li>
            <li>Study the cross linking of dysbiosis and hyperglycemia.</li>
            <li>Molecular Interplay of Gut Microbes in Metabolic Disorders.</li>
            <li>
              Understanding Pathogenetic association between gut microbiota and
              Diabetes.
            </li>
            <li>
              Probable role of T cell immunological expression alterations for
              therapeutic potential of Diabetes.
            </li>
          </ul>
        </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <ul>
            <li>
              Kaushik K. N., Chakraborthy G.S., Sharma G., 2008, “Sesamum
              indicum: A Review”, Journal of Herbal Medicine and Toxicology,
              2008, Vol. 2 (2) Page no. 16 – 19. ISSN: 0973-4643 Impact factor –
              1.3.
            </li>
            <li>
              Name of the book: Practice of Hospital, Clinical and Community
              Pharmacy
            </li>
          </ul>
          <ul>
            <li>Name of the Writer: Ms. Gunjan Sharma  Dr. Mohd. Aqil</li>
            <li>Title of Chapter: Drug Information Services</li>
            <li>Publisher: Elsevier Publications</li>
            <li>Date: October 2012</li>
            <li>Place: Delhi</li>
            <li>ISBN: 8131228312</li>
          </ul>
          <ul>
            <li>
              Archana Sharma, Gunjan Sharma Locked Nucleic Acid – A Cancer
              Therapeutic Agent”, International Journal of Advanced Research,
              2014, Vol 2, Issue 4, Page No.136 – 148, ISSN: 2320 – 5407, IF:1.6
            </li>
            <li>
              Neetu Singh, Sumeet Gullaiya, Gunjan Sharma, Shikha Singh,
              Sarvodaya Bajpai, Shyam Sundar Agrawal, A Systemic Review on CNS
              Effect of Electromagnetic Radiation 1800/900Mhz, 2014,
              International Journal of Pharmaceutical Sciences Review and
              Research, Volume 27, Issue 1, ISSN: 0976-044X, IF: 2.191
            </li>
            <li>
              Vedanshi Khajuria, Archana Sharma, Gunjan Sharma, Dapagliflozin– a
              SGLT2 inhibitor: A new road to Antidiabetic Treatment. 2014,
              International Journal of Advanced Research, ISSN: 2320 – 5407,
              IF:1.6
            </li>
            <li>
              Anshika Gupta, Gunjan Sharma, Shruti Pandey, Brajesh verma,
              Varshala Agrawal, Prosopis cineraria  its various therapeutic
              effects with special reference to Diabetes: A Novel Approach, Aug
              2014, International Journal of Pharmaceutical Sciences Review and
              Research, Volume 27, Issue 2, Page no. 328 – 333, ISSN: 0976-044X,
              IF: 2.191
            </li>
            <li>
              Gunjan Sharma, Divya Sadhna, Kailash Nath Kaushik, Haemovigilance:
              A system to improve safety in blood transfusion process, May 2014,
              World Journal of Pharmacy  Pharmaceutical Sciences, Volume 3,
              Issue 6, Page no. 1889 – 1898. ISSN: 2278-4357, IF: 2.786.
            </li>
            <li>
              Ilma kauser, Syed Zaid Naqvi, Rahul Mathur, Nidha Amir, Gunjan
              Sharma, Sumeet Gullaiya, Shyam Sundar Agarwal , Effects of
              Camellia sinensis and Garcinia cambogia on obesity  its
              comorbidities – safer alternative than synthetic drugs. Aug 2014,
              International Journal of Pharmaceutical Sciences Review and
              Research, Volume 27, Issue 2, Page no. 319 – 327, ISSN: 0976-044X,
              IF: 2.191
            </li>
            <li>
              Harsharan Pal Singh, Ishpreet Kaur, Gunjan Sharma, Sodium Glucose
              Co-Transporter-2 (SGLT2) Inhibitors as a New Class of
              Anti-diabetic Drugs: Pharmacokinetics, Efficacy and Clinical
              significance, July – Aug 201,5 International Journal of
              Pharmaceutical Sciences Review and Research, Volume 33, Issue 1,
              Page no. 40 – 47, ISSN: 0976-044X IF: 2.191
            </li>
            <li>
              Anshika Gupta, Gunjan Sharma, Md. Umama Ashhar, Kanika Sharma,
              S.S. Agrawal, Evaluation of Antidiabetic Activity of Prosopis
              cineraria Bark Extract on Albino Wistar Rats, International
              Journal of Pharmaceutical Sciences Review and Research, Volume 41,
              Issue 1, Page no. 145 – 149, ISSN: 0976-044X, IF: 2.191
            </li>
            <li>
             Hana, Kanika Sharma, Md. Umama Ashhar, Gunjan Sharma, Prem
              Kapur, Manju Sharma, Epidemiological studies for oral
              contraceptive pills prescription pattern, its efficacy and side
              effects in a South Delhi teaching hospital, International Journal
              of Research in Pharmaceutical Sciences, Vol 7, Issue 4, 2016, Page
              No. 1 – 7, ISSN:0975-
            </li>
            <li>
              Gunjan Sharma, Umama Ashhar Md, Kanika Sharma, Eyad Mokayad, Prem
              Kapur, Manju Sharma, Type 2 Diabetes Mellitus – Linked with X
              chromosome? An epidemiological study, International Journal of
              Research in Pharmaceutical Sciences, Vol 9, Issue 2, 2016, Page
              No. 1 – 7, ISSN:0975- 7538 DOI:
              https://doi.org/10.26452/ijrps.v9i1.1217
            </li>
            <li>
              Gunjan Sharma, Sandhya Hora, Vidhu Aeri, Deepshikha Pande Katare,
              Glucagon Like Peptide (Glp – 1) Receptor: A Promising Therapeutic
              Target for Screening of Herbal Antidiabetic Compounds, Asian
              Journal of Pharmaceutics, Vol 12, Issue 2, Apr-Jun 2018 (Supple),
              Page no. S512 – S518.
            </li>
            <li>
              Gunjan Sharma, Md. Umama Ashhar, Vidhu Aeri, Deepshikha Pande
              Katare, Effect of ethanolic extract of Euphorbia hirta on chronic
              diabetes mellitus and associated cardiorenal damage in rats,
              International Journal of Green Pharmacy, Vol 12, Issue 3, Jul-Sep
              2018.
            </li>
            <li>
              Arun K. Sharma, Ashish Kumar, Megha Sahu, Gunjan Sharma, Ashok K.
              Datusalia , Satyendra K. Rajput, Exercise preconditioning and low
              dose copper nanoparticles exhibits cardioprotection through
              targeting GSK-3β phosphorylation in ischemia/reperfusion induced
              myocardial infarction, 2018; 120: 59-66. IF: 2.8.
            </li>
            <li>
              Gunjan Sharma, Md. Umama Ashhar, Vidhu Aeri, Deepshikha Pande
              Katare, Development and characterization of late-stage diabetes
              mellitus and -associated vascular complications, Life Sci. 2019
              Jan 1;216:295-304. doi: 10.1016/j.lfs.2018.11.005. Epub 2018 Nov
              6. IF 3.2
            </li>
            <li>
              Gunjan Sharma, Megha Sahu, Ashish Kumar, Arun K Sharma, Vidhu
              Aeri, Deepshikha Pande Katare, Temporal Dynamics of Pre and Post
              Myocardial Infarcted Tissue with Concomitant Preconditioning of
              Aerobic Exercise in Chronic Diabetic Rats, Life Sciences,
              doi.org/10.1016/j.lfs.2019.03.077. Impact Factor: 3.2.
            </li>
          </ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container">
      Chairperson at ACMR 2023 helding at PGIMER, Chandigarh from 16 to 18 Feb 2023
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul>
         <li><b>Patents – 10</b></li></ul><ol><li >A method of preparing phytosomal nanoemulsion for the treatment of Type 2 Diabetes Mellitus (2017) – Under granting process</li><li >A process to develop Type 2 Diabetes Mellitus in albino wistar rat mimicked to human pathology (2018) – Published</li><li >Animal Ventilator (2019)</li><li >Anti-pollution Eye Makeup (2019)</li><li >Anti-hair growth nanocrystal formulation (2019)</li><li >A breast-feeding device to help with hypogalactia (2020)</li><li >Autonomic novel nano-formulation for long haul disinfection (2020)</li><li >A multilayer liner with enhanced capacity to absorb cervix discharge (2020)&nbsp;</li><li >Male external urinary catheter (2021)</li><li >Water Purification Add-on Device (2022)</li></ol><ul><li><b>Books – 2</b></li></ul><ol><li >Manju Sharma, Gunjan Sharma, Kanika Sharma, Laboratory Manual of Experimental Pharmacology for B. Pharm IV semester, Avni Publications, 2018, ISBN:978-93-82968-84-9</li><li >Manju Sharma, Gunjan Sharma, Kanika Sharma, Laboratory Manual of Experimental Pharmacology for B. Pharm VI semester, Avni Publications, 2018, ISBN:978-93-82968-85-6</li></ol><ul><li><strong>Professional Membership</strong></li></ul><p>IPS(Indian Pharmacological Society) ISPOR (International Society for Pharmacoeconomics and Outcomes Research) &amp; IACS (International Academy of Cardiovascular Sciences), IGPA (Indian Pharmacy Graduates Association)</p>
        </div>
      ) : null}
    </div>
  );
};

export default DrGunjanSharma;
