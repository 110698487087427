import React from "react";

const LibraryStatistics = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Library Statistics
      </h2>

      <table style={{margin:'2% auto'}}>
        <tbody>
          <tr>
            <td>
              <p >
                <span >
                  <b>S. No.</b>
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  <b>Library Resources/Collection&nbsp;</b>
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  <b>Till Date</b>
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >1</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Book&nbsp;
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  <b>22,092</b>
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="2">
              <p >
                <span >2</span>
              </p>
            </td>
            <td rowspan="2">
              <p >
                <span >
                  Journals&nbsp;&nbsp;
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Electronic Journals&nbsp; 64 (List Attached)
                </span>
              </p>
              <p >
                <span >
                  Bentham Science Life Science Package – 64
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >
                  Print&nbsp; Journals (National Journals/Magazine): 3&nbsp;
                </span>
              </p>
              <p >
                <span >
                  Pharma Times
                </span>
              </p>
              <p >
                <span >
                  Current Science
                </span>
              </p>
              <p >
                <span >
                  Chronicle Pharmabiz
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >3</span>
              </p>
            </td>
            <td>
              <p >
                <span >Database</span>
              </p>
            </td>
            <td>
              <p >
                <span >ProQuest</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >4</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Internet-based plagiarism detection service
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Turnitin&nbsp;
                </span>
              </p>
              <p >
                <span >
                  Ouriginal (Urkund) by INFLIBNET
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >5</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Magazines&nbsp;
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  10 (7 English &amp; 4 Hindi)&nbsp;
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >6</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Newspapers&nbsp;
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  8 (5 English &amp; 3 Hindi)
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >7</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Bound Volumes&nbsp;
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  500+&nbsp;
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >8</span>
              </p>
            </td>
            <td>
              <p >
                <span >CD/DVD</span>
              </p>
            </td>
            <td>
              <p >
                <span >272</span>
              </p>
              <p >
                <span >
                  M. Pharm Dissertations: 265
                </span>
              </p>
              <p >
                <span >
                  Ph.D. Thesis uploaded on Shodhganga as on September 2022: 07
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >9</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Complementary CD/DVD
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >249</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p >
                <span >10</span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  Membership
                </span>
              </p>
            </td>
            <td>
              <p >
                <span >
                  National Digital Library of India (NDLI)
                </span>
              </p>
              <p >
                <span >
                  Shodhganga
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LibraryStatistics;
