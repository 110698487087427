import React, { useState } from "react";

import profimg from "./IMG_6480-scaled-e1685347118414.jpg";

const DrShilpaJain = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =useState(false);

  const [isAwardsandRecOpen, setIsAwardsandRecOpen] =useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
      Dr. Shilpa Jain</h2>
      <h3 className="facult-designation-for-each-container">
      Associate Professor| School of Physiotherapy 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
          
            shilpaheadsop [at] dpsru [dot] edu [dot] in
           
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=NQvsRDsAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a  >
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0002-5807-9917">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      MPT (Sports), MBA, PhD
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Academic Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      12 Years
      </p>
      <h2 className="academic-qualification-heading-for-every-page">
      Industry Experience
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      
      </p>

     

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Research Area
      </button>

      {isResearchOpen ? (
        <div className="inner-container">
            <ul><li> Biomechanical Assessment</li><li> Pain</li><li> Myofascial Pain Syndrome</li><li>Trigger Points</li><li>Spinal Disorders</li><li>Sports Health</li></ul>
               </div>
      ) : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Paper Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
                </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
     Invited Lecture
      </button>

      {isConferencesOpen ? (
        <div className="inner-container">
   <p><strong>Conferences</strong></p><ul><li>Presented Paper in Physio Bharat 2022, National Conference on 19th June, 2022, on the title “Efficacy of Novel and Nano Formulation of Succinylcholine in Dissolving Myofascial Trigger Points”</li><li>Presented Paper in the 3rd National Conference on Sports and Advanced Physiotherapy on 14th Feb’ 2021 on the title: “Current Trends in MFPS”</li><li>Presented Paper in Virtual International Conference on Recent Advances and Challenges in Sports Rehabilitation from 5th to 7th June’ 2021, on the title: “Efficacy of Transdermal Drug Delivery of Suucinylcholine on Myofascial Pain Syndrome: Pilot Study”</li><li>Presented Poster on “Role of Pyroptosis on Cardiac Muscle Cell Damage: A review”, in International Conference of Cardiovascular Sciences-2020, (21-23 Feb’2020)</li><li>Presented Paper on “The Effect of Anodal Transcranial Direct Current Stimulation on Reaction Time” in World Physiotherapy Congress; 3rd International Conference 2019 (17th-22nd October’ 2019)</li><li>Presented oral paper in Sports Connect -2, 2019 in Professional Category on effect of microcurrent in reducing fine wrinkles and improving firmness of face</li><li>Presented Paper on the topic “ Effect of Forearm Guard Induced Pressure On Grip Strength, Vibration Perception Threshold and Two Point Discrimination On Female Collegiate Between The Age Group of 19-25 Years” in UPCON 2019</li><li>Presented, Effect of Fast Beat music, slow music and no music on Aerobic performance of young adults, Souvenir, PHYSIO HARCON-2nd International Conference-2018, Page NO. 22-23.</li><li>Presented paper on the topic “Transcranial Direct Current Stimulation: A review”, 1st National Sports Physiotherapy Conference, 2018</li><li>Presented Poster on the topic “ I Love My Sport But My Body Does Not: Why Very Less Medals In Olympics” in UPCON 2019</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
  Paper Presentations
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
       
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
            setIsAwardsandRecOpen(!isAwardsandRecOpen)
        }
      >
     Awards and Recognition
      </button>

      {isAwardsandRecOpen ? (
        <div className="inner-container">
     <ul><li>Shilpa Jain 2018 Significant Conribution Award National Sports Physiotherapy Conference</li><li>Shilpa Jain 2018 Swabhiman Samman Award Physio Harcon</li><li>Shilpa Jain 2019 Academic Excellence Award Neuraxis</li><li>Shilpa Jain 2021 Empowered Woman Award- Shakti of Physios Sports Connect 3</li><li>Shilpa Jain 2022 Professional Contribution Award Physio Bharat</li><li>Shilpa Jain 2019Youth Icon Virat Award Sports Connect 2</li><li>Shilpa Jain 2022 Chairperson Physio Bharat</li><li>Shilpa Jain 2018 Chairperson National Sports Physiotherapy Conference</li><li>Shilpa Jain 2018 Chairperson Physio Harcon</li><li>Shilpa Jain 2021 Key note Speaker Gaur Brahman Ayurvedic College and Hospital, Brahmanwas, Rohtak</li><li>Shilpa Jain 2021 Key note Speaker Sardar Bhagwan Singh University, Dehradun</li><li>Shilpa Jain 2019 Guest Speaker Physio Vibes</li><li>Shilpa Jain 2019 Resource Person CAEHS</li><li>Shilpa Jain 2019 Panelist Sports Connect 2</li><li>Shilpa Jain 2023 Reviewer 7th Annual Conference- Society of Indian Physiotherapists</li><li>Shilpa Jain 2022 Reviewer 6th Annual Conference- Society of Indian Physiotherapists</li><li>Shilpa Jain 2020 Reviewer 5th Annual Conference- Society of Indian Physiotherapists</li><li>Shilpa Jain 2021 Key note Speaker BMU Physiofest- 2021</li><li>Shilpa Jain 2018 Significant Conribution Award National Sports Physiotherapy Conference</li><li>Shilpa Jain 2018 Swabhiman Samman Award Physio Harcon</li><li>Shilpa Jain 2019 Academic Excellence Award Neuraxis</li><li>Shilpa Jain 2021 Empowered Woman Award- Shakti of Physios</li><li>Shilpa Jain 2022 Professional Contribution Award Physio Bharat</li><li>Shilpa Jain 2019 Youth Icon Virat Award Sports Connect 2</li><li>Shilpa Jain 2022 Chairperson Physio Bharat</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default DrShilpaJain;
