import React from 'react'
import './PreviousQuestionPapers.css'

import prePDH from './PQPData/Pre-PHD.pdf'

import bph1st from './PQPData/bph-1st-year.pdf'
import bph2nd from './PQPData/bph-2nd-Year.pdf'
import bph3rd from './PQPData/bph-3rd-year.pdf'
import bph4th from './PQPData/bph-4th-year.pdf'

import bpt1st from './PQPData/BPT-1st-year.pdf'
import bpt2nd from './PQPData/bpt-2nd-year.pdf'
import bpt3rd from './PQPData/bpt-3rd-year.pdf'
import bpt4th from './PQPData/bpt-4th-year.pdf'

import bsc1st from './PQPData/bsc-ss-1st-year.pdf'
import bsc2nd from './PQPData/bsc-ss-2nd-year.pdf'

import pharma1st from './PQPData/pharmacology-first-year.pdf'
import clini1st from './PQPData/clinical-research-1st-year.pdf'
import indus1st from './PQPData/industrial-pharmacy-1st-year.pdf'
import hospital1st from './PQPData/hospital-pharmacy-1st-year.pdf'
import pa1st from './PQPData/pharmaceutical-analysis-first-year.pdf'
import pharmaceutics from './PQPData/pharmaceutics.pdf'
import phac1st from './PQPData/pharmaceutical-chemistry.pdf'
import qa1st from './PQPData/quality-assurance.pdf'
import ra1st from './PQPData/regulatory-affairs.pdf'
import pb1st from './PQPData/pharmaceutical-biotechnology.pdf'
import mph1st from './PQPData/masters-in-public-health.pdf'
import mhm1st from './PQPData/mhm-first-year.pdf'
import pm1st from './PQPData/pharmaceutical-management.pdf'
import dp1st from './PQPData/diploma-in-pharmacy.pdf'
import dp2nd from './PQPData/dph-second-year.pdf'



function PreviousQuestionPapers() {
    return (
        <div className='dpsru-every-component-css'>

            <h3 className="dpsru-primary-heading-for-every-page">Previous Question Papers</h3>
            <h4 className="dpsru-secondary-heading-for-every-page">Course-wise Question Papers of Previous Years</h4>


            {/* div */}
            <div className='previousQuestionPapers-link-box-container'>
                <p>
                    <a href={prePDH} className='pre-phd-pre-que-first-a' style={{color:"black",fontWeight:'bold',padding:'2px 30px'}}>Pre -PHD</a>
                </p>
                <p className='previousQuestionPapers-link-box-container-heading'>B. Pharm</p>
                <ul>
                    <li>
                        <a href={bph1st}>First Year</a>
                    </li>
                    <li>
                        <a href={bph2nd}>Second Year</a>
                    </li>
                    <li>
                        <a href={bph3rd}>Third Year</a>
                    </li>
                    <li>
                        <a href={bph4th}>Fourth Year</a>
                    </li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>BPT</p>
                <ul>
                    <li>
                        <a href={bpt1st}>First Year</a>
                    </li>
                    <li><
                        a href={bpt2nd}>Second Year</a>
                    </li>
                    <li>
                        <a href={bpt3rd}>Third Year</a>
                    </li>
                    <li>
                        <a href={bpt4th}>Fourth Year</a>
                    </li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>B. Sc. Sports Science</p>
                <ul>
                    <li>
                    <a href={bsc1st}>First Year</a>
                    </li>
                    <li>
                        <a href={bsc2nd}>Second Year</a>
                    </li>
                    <li>Third Year</li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>M. Pharm</p>
                <ul>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Pharmacology
                        <ul>
                            <li>
                                <a href={pharma1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Clinical Research
                        <ul>
                            <li>
                                <a href={clini1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Industrial Pharmacy
                        <ul>
                            <li>
                                <a href={indus1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Hospital Pharmacy
                        <ul>
                            <li>
                                <a href={hospital1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Pharmaceutical Analysis
                        <ul>
                            <li>
                                <a href={pa1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Pharmaceutics
                        <ul>
                            <li>
                                <a href={pharmaceutics}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Pharmaceutical Chemistry
                        <ul>
                            <li>
                                <a href={phac1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Quality Assurance<ul>
                        <li>
                            <a href={qa1st}>First Year</a>
                        </li>
                        <li>Second Year</li>
                    </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Regulatory Affairs<br />
                        <ul>
                            <li>
                                <a href={ra1st}>First Year</a>
                            </li>
                            <li>Second Year</li>
                        </ul>
                    </li>
                    <li className='previousQuestionPapers-link-box-container-sub-heading'>Pharmaceutical Biotechnology<ul>
                        <li><a href={pb1st}>First Year</a>
                        </li>
                        <li>Second Year</li>
                    </ul>
                    </li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>Masters in Public Health</p>
                <ul>
                    <li>
                        <a href={mph1st}>First Year</a>
                    </li>
                    <li>Second Year</li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>Masters in Hospital Management</p>
                <ul>
                    <li>
                        <a href={mhm1st}>First Year</a>
                    </li>
                    <li>Second Year</li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading' >MBA in Pharmaceutical Management</p>
                <ul>
                    <li>
                        <a href={pm1st}>First Year</a>
                    </li>
                    <li>Second Year</li>
                </ul>
                <p className='previousQuestionPapers-link-box-container-heading'>Diploma in Pharmacy</p>
                <ul>
                    <li>
                        <a href={dp1st}>First Year</a>
                    </li>
                    <li>
                        <a href={dp2nd}>Second Year</a>
                    </li>
                </ul>

            </div>
            {/* div */}

        </div>
    )
}

export default PreviousQuestionPapers