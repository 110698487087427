import React from "react";
import "./WhatsNew.css";
import { Link } from "react-router-dom";
import newgif from "../../../NEW/new.gif";

// data
import dkyn02 from "./WhatsNew Data/dkyn02.pdf";
import dkyn01 from "./WhatsNew Data/dkyn01.pdf";
import r5thannualc from "./WhatsNew Data/r5thannualc.pdf";
import dpsruAnnualCon from "./WhatsNew Data/Convocation-Announcement-2-1.pdf";
import vigilanceAwarenessWeekImage from "./WhatsNew Data/vigilanceAwarenessWeekImage.jpeg";
import NoticeForStudentFacultyAndStaff from "./WhatsNew Data/NoticeForStudentFacultyAndStaff.pdf";
import ApplicationsforadmissionsofPGprogramsextendedto29thAugust2022 from "./WhatsNew Data/Applications-for-admissions-of-PG-programs-extended-to-29th-August-2022.jpg";
import scanSRF from "./WhatsNew Data/scan-SRF.pdf";
import DPSRUNIBJointCertificateProgramonQualityAssuranceinBiopharmaceuticals from "./WhatsNew Data/DPSRU-NIB-Joint-Certificate-Program-on-Quality-Assurance-in-Biopharmaceuticals.jpg";
import ListoFStudentSeclectedForPrePhD from "./WhatsNew Data/ListoFStudentSeclectedForPrePhD.pdf";
import PrePhdNoticeAnnexure2022 from "./WhatsNew Data/PrePhdNoticeAnnexure2022.pdf";
import Listofcandidatesofphdentranceexam from "./WhatsNew Data/List-of-candidates-of-phd-entrance-exam.pdf";
import NoticeForPrePhDEntreanceTest from "./WhatsNew Data/NoticeForPrePhDEntreanceTest.jpeg";
import PhDAdmissionForm from "./WhatsNew Data/Ph.D-Admission-Form.pdf";

import walkinInterview3010 from "../../../Notifications/Notification Files/Advertisement.pdf";
import walkinInterviewOn17Nov from "../../../Notifications/Notification Files/Interview on 17.11.23.pdf";
import walkinInterviewResult from "../../../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf";
import walkininterviewresulton17 from "../../../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 17-11-2023.pdf";
import recrutmentofjrffellow from '../../../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF).pdf'

// new
import applicationinvitedforphdadd from './WhatsNew Data/Ph.D.-advertisement-details-for-website-1.pdf'
import extensionfstatefundedscholorshipscheameforminorities from './WhatsNew Data/CamScanner-03-23-2022-15.14.16.pdf'
import maritcummeanslinkedfinancialAssistancescheme from './WhatsNew Data/CamScanner-03-23-2022-15.10.58.pdf'
import careeradvascheme from './WhatsNew Data/cas.pdf'
import noticeregardinextensionlastdateforscholorship from './WhatsNew Data/WhatsApp-Image-2022-02-24-at-10.32.25-AM.jpg'
import workshoponevolvingmarketdynamicinhii from './WhatsNew Data/Workshop-Report.pdf'
import noriceregardingverificationofpsit from './WhatsNew Data/notice-stock-items.pdf'
import dsirDpsrucrtdhcaftTeamgujrat from './WhatsNew Data/DSIR-DPSRU-Gujarat-visit-1.pdf'
import dsirDpsrucrtdhconsidaycele from './WhatsNew Data/CAFT-Constitution-day-celebrations.pdf'
import nssRegistrationdriveforDPSRUstudens from './WhatsNew Data/WhatsApp-Image-2022-02-04-at-9.46.45-PM.jpg'

// roshank

function WhatsNew() {
  return (
    <div className="whatsnew-component ">
      <h1 className="whatsnew-heading inner-container-heading-of-tab-button">
        What's New
      </h1>

      <div
        className="whatsnew-data-container inner-container"
        style={{ background: "none" }}
      >
        <ul>
          <li className="second-li-of-tab-container content-li-of-tabs-home">
            <a
              href={recrutmentofjrffellow}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
            Advertisement for the Post of Junior Research Fellow (JRF)
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li className="second-li-of-tab-container content-li-of-tabs-home">
            <a
              href={walkininterviewresulton17}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
             Result of Walk-in-Interview for Guest Faculties held on 17/11/2023
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li className="second-li-of-tab-container content-li-of-tabs-home">
            <a
              href={walkinInterviewResult}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Result of Walk-in-Interview for Guest Faculties held on 03/11/2023
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li className="second-li-of-tab-container content-li-of-tabs-home">
            <a
              href={walkinInterviewOn17Nov}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Walk-in Interview for Guest Faculties of Pharmacy and
              Physiotherapy on 17/11/2023 (Reporting Time strictly from 10:00 AM
              to 10:30 AM)
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li className="second-li-of-tab-container content-li-of-tabs-home">
            <a
              href={walkinInterview3010}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Walk-in- Interview for Guest Faculties on 30/10/2023
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a
              href={dkyn02}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice for Ph.D Admission
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a
              href={dkyn02}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              G20 Quiz Competition at DPSRU
            </a>
          </li>
          <li>
            <Link to="/G20MahaFoodFestival" className="whats-new-data">
              G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU
            </Link>
          </li>
          <li>
            <Link to="/pushpPradarshnam" className="whats-new-data">
              PUSHP PRADARSHNAM 2023
            </Link>
          </li>
          <li>
            <Link to="/indoBrazilFusionFest" className="whats-new-data">
              G20 Indo Brazil Fusion Fest
            </Link>
          </li>

          <li>
            <Link to="/g20PanelDiscussionReport" className="whats-new-data">
              Panel Discussion @DPSRU on “TO INVEST IN PEOPLE, PRIORITIZE
              EDUCATION”
            </Link>
          </li>

          <li>
            <a
              href={dkyn02}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL
              CONCLAVE
            </a>
          </li>
          <li>
            <p className="whats-new-data">
              Rehearsal Timings For Convocation 2022.
            </p>
          </li>

          <li>
            <a
              href={dkyn02}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Dilli Ki Yogshala Notice 2
            </a>
          </li>

          <li>
            <a
              href={dkyn01}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Dilli ki Yogshala Notice 1
            </a>
          </li>

          <li>
            <a
              href={r5thannualc}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Registration Form for the 5th Annual Convocation
            </a>
          </li>

          <li>
            <a
              href={dpsruAnnualCon}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              DPSRU 5th Annual Convocation Announcement
            </a>
          </li>

          <li>
            <a
              href="http://pledge.cvc.nic.in"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              All the students, teaching &amp; Non teaching Staff are requested
              to take an integrity pledge on this link mentioned
            </a>
          </li>

          <li>
            <a
              href={vigilanceAwarenessWeekImage}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Vigilance Awareness Week Workshop Notice
            </a>
          </li>

          <li>
            <a
              href="https://forms.gle/j3sKgfBsgpnjvNbBA"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Link to Fill the Sports Survey Form by Faculty, Staff and Students
            </a>
          </li>

          <li>
            <a
              href={NoticeForStudentFacultyAndStaff}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice for Students, Faculty and Staff
            </a>
          </li>

          <li>
            <a
              href={
                ApplicationsforadmissionsofPGprogramsextendedto29thAugust2022
              }
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Last Date of Applications for the admission of Executive MPharm
              (DRA), MSc AI in Medicine, PGDCR and DPSRU NIB Joint Certificate
              Course in Quality Assurance of Biopharmaceuticals extended to
              Monday 29th August 2022
            </a>
          </li>

          <li>
            <a
              href={scanSRF}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Applications Invited: Position of Senior Research Fellow in a SERB
              Funded Project
            </a>
          </li>

          <li>
            <a
              href={
                DPSRUNIBJointCertificateProgramonQualityAssuranceinBiopharmaceuticals
              }
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Applications Invited: Certificate Course in Quality Assurance of
              Bio-Pharmaceuticals || Last Date : Today (August 22, 2022)
            </a>
          </li>

          <li>
            <a
              href={ListoFStudentSeclectedForPrePhD}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              List of Students Selected for Pre-Ph.D. 2022
            </a>
          </li>

          <li>
            <a
              href={PrePhdNoticeAnnexure2022}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Pre-Ph.D. 2022 Notice and Annexures
            </a>
          </li>

          <li>
            <a
              href={Listofcandidatesofphdentranceexam}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              List of Candidates Eligible for pre-PhD entrance test - 2022
            </a>
          </li>

          <li>
            <a
              href={NoticeForPrePhDEntreanceTest}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice for pre-PhD entrance test
            </a>
          </li>

          <li>
            <a
              href={PhDAdmissionForm}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Ph.D. Admission Form
            </a>
          </li>

          <li>
            <a
              href={applicationinvitedforphdadd}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Applications Invited for Ph.D. Admissions
            </a>
          </li>

          <li>
            <a
              href={extensionfstatefundedscholorshipscheameforminorities}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Extension of State Funded Scholarship Schemes for Minorities
            </a>
          </li>

          <li>
            <a
              href={maritcummeanslinkedfinancialAssistancescheme}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Merit-cum-means linked Financial Assistance Scheme
            </a>
          </li>

          <li>
            <a
              href={careeradvascheme}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Career Advancement Scheme: Notification and Guidelines
            </a>
          </li>

          <li>
            <a
              href={noticeregardinextensionlastdateforscholorship}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Extension of Last Date for Scholarship Schemes on
              edistrict portal
            </a>
          </li>

          <li>
            <a
              href={workshoponevolvingmarketdynamicinhii}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Workshop on Evolving Market Dynamics in Healthcare Industry in
              India
            </a>
          </li>

          <li>
            <a
              href={noriceregardingverificationofpsit}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Verification of Purchased/Stock items in Store
              Department
            </a>
          </li>

          <li>
            <a
              href={dsirDpsrucrtdhcaftTeamgujrat}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              DSIR-DPSRU-CRTDH CAFT Team Gujarat Visit
            </a>
          </li>

          <li>
            <a
              href={dsirDpsrucrtdhconsidaycele}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              DSIR-DPSRU-CRTDH Constitution Day Celebration
            </a>
          </li>

          <li>
            <a
              href={nssRegistrationdriveforDPSRUstudens}
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              NSS Registration Drive for DPSRU Students
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2022/02/pdf_20220131_135811_0000.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Invitation to Foundation Day Alumni Meet
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2022/01/MLT-Orientation-Programme.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              MLT Orientation Program Schedule to be held on Monday - 10.01.2022
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2022/01/Pragati_Saksham_Swanath.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Pragati Saksham Swanath Scholarship
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2022/01/WhatsApp-Image-2022-01-03-at-5.32.16-PM-1.jpeg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice: Regarding Details of Teaching &amp; Non-Teaching Staff
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/12/Application-Form-Documents-Details-for-WCSC-Course.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              &nbsp;Application Form &amp; Required Documents for WCSC SPOT
              ADMISSION
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/12/NOTICE-FOR-THE-GOLD-MEDALIST-STUDENTS.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice for the Gold Medalist Students - 4th Annual Convocation
              Ceremony
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/12/convocation-notice.jpeg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Convocation: Rehearsal timings and convocation
              ceremony instructions
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/10/Adobe-Scan-29-Oct-2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Vigilance Awareness Week || Workshop on November 1, 2021 for
              Teaching &amp; Non-Teaching Staff
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/10/circular-of-vigilance-awareness-week.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Observance of Vigilance Awareness Week 2021 (from 26th October
              2021 to 01st November 2021)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/10/Notice-deposition-of-annual-semester-fees.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice: Regarding Deposition of Semester/Annual Fees
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/10/srf.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Advertisement for the Post of Senior Research Fellow (SRF) in a
              SERB Funded Project
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/10/B-Pharm-Odd-semester-Time-Table-21-22-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              B. Pharm Odd Semester Time Table 2021-22
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/09/vaccination-notice.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Vaccination Drive for Faculty/Staff and Family Members of DPSRU
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/09/YOGA-RESULT.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Result of Diploma in Meditation &amp; Yoga Sciences - First
              Trimester (September 2021)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/08/Certificate-Courses-WCSC-Job-Prospects-and-Other-Details.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Certificate Courses (WCSC) - Job Prospects and Other Details
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/08/Adobe-Scan-Aug-13-2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Quotations Invited for Patent Attorney
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/08/on-spot-admissions-03-08-2021-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Spot Admissions for course "Diploma in Meditation and Yoga
              Science" on the vacant seats on August 5, 2021
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/Prospectus-2021-22_Web.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Prospectus 2021-22
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/WhatsApp-Image-2020-10-23-at-8.32.16-AM.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              AICTE Pragati Scholarship Scheme for Girl Students
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/CREATION-OF-DUST-PROOF-COMPUTER-LAB-IN-ROOM-NO.-312-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender/ Bid document: Creation of Dust-Proof Computer Lab
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/Multicolour-Fluorescence-Imaging-System.pdf&nbsp;"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender/Bid document: Supply of Multi-Colour Fluorescence Imaging
              System
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/Document-199.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              RESULT: Candidates recommended for the post of Yoga Instructor
              (part-time) on the basis of interview held on 10th and 11th of
              July 2021
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/Yoga_Adfor_Website.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Walk-in Interview for Yoga Instructors
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/07/DSWO_Circular_4_AICTE-ucberkeley-program-July-2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              AICTE - UC Berkeley Programme for Students
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/Time-Table-w.e.f.-24.05.2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              B. Pharm (Even Semester) Time Table w.e.f. 24.05.2021
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/Mini-spray-Tender.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender document: Mini Spray Dryer for DPSRU Innovation and
              Incubation Foundation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/Rheometer-Tender.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender document: Rheometer for DPSRU Innovation and Incubation
              Foundation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/Lypholizer-Tender.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender document for Lyophilizer for DPSRU Innovation and
              Incubation Foundation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/NEXT-GENERATION-DNA-SEQUENCING-SYSTEM-TENDER-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Tender Document for Supply of Next Generation DNA Sequencing
              System
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/05/Adobe-Scan-13-May-2021-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Constitution of DPSRU Covid19 Task Group
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/04/PRE-PHD-TIME-TABLE-FINAL.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Pre-PhD. Course Work Time Table - wef. 23/03/2021
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/04/Time-Table-w.e.f.-13.04.2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              B. Pharm 8th Semester Time Table: w.e.f. 13.04.21
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/04/scholarship-advt.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              SC/ST/OBC Scholarship Scheme: Last Dt Extended
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/Notebloc_92FDDFDA-B386-41E3-9E0B-E3B6B4E95134-1145-0000008D2B903291.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Admission extended upto 3rd Apr 21 Med. &amp; Yoga Sc
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/TapScanner-03-26-2021-17.13.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice: Admissions in Meditation &amp; Yoga Sciences
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/25032021_DelhiGazette.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Delhi Gazette Extraordinary No. 249 SG-DL-E-26112020-223300
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/Result_of_Yoga_Instructor__Part_Time__Interview_held_on_23.03.2021.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Interview results for the post of Yoga-Instructor held on 23rd
              March 2021
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/4190-4195_220321-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice of Even Semester fees of DPSRU/DIPSAR
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/ilovepdf_merged.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Advertisement for the Post of Yoga Instructor
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/Recruitment-for-DPSRU-Centre-for-Precision-Medicine-Pharmacy-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Applications Invited for the various posts in project
              "Establishment of Centre of Precision Medicine and Pharmacy"
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/Advt-ICMR-SRF.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Advertisement for the Post of Senior Research Fellow (SRF)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/03/20210302_110341.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Scholarship Scheme as "Merit-Cum-means linked
              Financial Assistance Scheme"
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/02/pamphlet1_page-0001-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              School of Allied Health Sciences celebrated National Girl Child
              Day
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/Notebloc_103762D6-D366-41D9-B313-986B7AFD624D-438-0000001B5F4F4A56.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Hostel
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/PhD_Entrance_Exam.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Entrance Test of Ph.D-2020
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/SAHS-DPSRU-Student-Orientation-Program.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Student Orientation Programme (School of Allied
              Health Sciences-2020)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/SAHS-DPSRU-Student-Induction-Program.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Student Induction Programme (School of Allied
              Health Sciences-2020)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/Notice.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Orientation Program of M.Pharm.
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/orientation-day-program.jpg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Orientation Day Program for BPT
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2021/01/Academic-Calendar.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Academic Calendar-2020-21
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Recruitment-to-the-Post-of-Section-Officer-SO.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Recruitment to the Post of Section Officer (SO)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Notice-Regarding-Distribution-of-Degree.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Distribution of Degree
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Rehearsal-Timing-for-Convocation.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Rehearsal Timing for Convocation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Notice-For-the-Gold-Medalist-Students.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice For the Gold Medalist Students in 3rd Convocation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Convocation-Registration-Notice.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding 3rd Convocation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/Steps-for-pay-the-fess-of-online-Ph.D.-admission-on-DPSRU.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Steps for pay the fess of online Ph.D. admission on DPSRU
            </a>
          </li>

          <li>
            <a
              href="https://meet.google.com/gjp-ijjo-jyn"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Link for Online Orientation Programme
              (https://meet.google.com/gjp-ijjo-jyn)&nbsp;
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/12/IMG-20201204-WA0006-converted.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding commencement of B.Pharm. Classes
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/11/Notice-for-Convocation.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Convocation
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/11/ilovepdf_merged-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding Ph.D Admissions
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/11/Data.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Announcement and Registration for 3rd Annual Convocation for
              D.Pharm., B.Pharm., M.Pharm., MPH , MHM, Pharm. MBA, BPT and B.Sc
              Sprts Sciences
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/10/Qutation.jpeg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for Quotation for Weight Drop Brain Injury Appratus
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/Brochure-34-QIP.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              AICTE sponsored XXXIV-QIP: 30 Sept to 8th Oct
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/Date-Sheet-of-BPT-8th-Semester-BPT-4th-Year-Annual-Theory-Examination-September-2020-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Date Sheet of BPT 8th Semester &amp; BPT 4th Year Annual (Theory)
              Examination September 2020.pdf
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/D.-Pharm.-2nd-Year-B.-Pharm.-8th-Sem.-University-final-Exam-Datesheet-2020..pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              D.-Pharm.-2nd-Year-B.-Pharm.-8th-Sem.-University-final-Exam-Datesheet-2020
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/CamScanner-09-07-2020-15.59.25.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              DPSRU merit cum means linked financial assistance for pursuing
              higher education student's list-(2019-2020)
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/XXXII-QIP-Registration-Form-14-9-20-to-21-9-2020.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              QIP XXXII Registration form
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/XXXII-QIP_STP-Brochure-14-to-21-Sept.-2020.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              QIP XXXII Brochure
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/semester-fees.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              SEMESTER FEES FOR DPSRU AND DIPSAR
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/09/CamScanner-09-03-2020-11.14.29.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Cam Scanner 09-03-2020
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/08/CamScanner-08-25-2020-15.32.03.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for quotation for Repair work of the furniture
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/08/CamScanner-08-20-2020-13.05.03.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for the quotation of hiring for patent agent for PCT
              patent filling
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/08/Application-Form.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Full Time/Temporary Faculty - Application Form
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/08/Extension-of-Last-Date-for-submission-of-Applications.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Full Time/Temporary Faculty - Extension of Last Date for
              submission of Applications
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/08/New-Doc-2020-08-14-12.38.18_1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Employment Notice Regarding the application invited for Full
              Time/Temporary Faculty in DPSRU
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/07/Prospectus-2020_Final.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Prospectus-2020
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/07/Standard-operating-Procedure-DPSRU-16.07.2020.docx"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Standard Operating Procedures during COVID - 19
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/07/CamScanner-07-13-2020-11.19.24.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice reg. Library Fine Fee
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/06/CamScanner-06-08-2020-15.59.53.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              NIT For Pre and post Examination Work - Confidential
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/05/IMG_20200428_144551.jpg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              GENERAL NOTICE on RTIs during COVID-19 LOCKDOWN
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/03/Schedule-Assistant-Professor-pharmachemistry-pharmaceuticsphysiotherapy-1.docx"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Schedule - Asst. Professor in Pharmachemistry,Pharmaceutics and
              physiotherapy
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/03/NOTICE-Assistant-Professorpharmachemistypharmaceuticsphysiotherapy-1.docx"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              NOTICE - Asst. Professor in Pharmachemistry,Pharmaceutics and
              physiotherapy
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/02/Table-3A.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Short-listing of Candidates for Interview for the Post of
              Assistant Professors in Universities
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/02/NOTICE-for-the-post-of-Asst.-Professor-in-pharmacology-and-pharmacognosy.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              NOTICE for the post of Asst. Professor in Pharmacology and
              Pharmacognosy
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/02/Schedule-for-the-post-of-Asst.-Professor-in-Pharmacology-and-Pharmacognosy.docx"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Schedule for the post of Asst. Professor in Pharmacology and
              Pharmacognosy
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/02/TendorsDPSRU.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for quotation for procurement of Furniture
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/02/DPSRU_website-upload.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for quotation for procurement of various lab items
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/02/non-teaching.jpg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Schedule and syllabus for Non-Teaching Contractual Posts in DPSRU
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2020/01/New-Doc-2020-01-17-13.43.24.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Quotations are invited for PNG Pipeline work. Last date:31.01.2020
            </a>
          </li>

          <li>
            <a
              href="https://cdn3.tcsion.com/EForms/configuredHtml/2659/64417/login.html"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Download Office Assistant Admit Card/Hall Ticket
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/notice-for-mock-test.docx"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice for Mock Test of Office Assistant
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/New-Doc-2020-01-16-12.25.23.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for quotation for Repair of Lyophilizer Instrument. Last
              date:24.01.2020
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/Admission-Form-18-th-dec.-2019.doc"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Application form for admission to next semester
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/Reschedule-interview-dates-for-Pre-Ph.D-1.-in-DPSRU_1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Call for interview for Pre-Ph.D admission
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/New-Doc-2020-01-06-12.08.45-2.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Charges for examination Late fee &amp; various certificates/
              Documents
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2020/01/NonTeachingPositions.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice of Written Test /Interview of Non-Teaching Contractual
              Positions in DPSRU
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/whatsnews/Office Assistant Schedule.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Examination of Office Assistant post
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2019/12/New-File-of-Assistant-ProfessorPhysiotherapy.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Interview date of Assistant Professor (Physiotherapy)
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2019/12/NewData.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Status of Assistant Professor (Physiotherapy)
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/wp-content/uploads/2019/12/New-Doc-2019-12-24-15.00.05.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding the result of Teaching and Non-teaching Posts
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2019/08/today_2019-12-05 11.00.01.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Empanelment/Registration of Vendors for Supply of Books to DPSRU
              Library
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2019/08/SecondsCoun-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice regarding Second Counselling admission schedule for
              B.Pharm. ,D.Pharm. and B.P.T. in DIPSAR/DPSRU
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/notice-recruitment-date-extend.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice reg. extention of date w.r.t teaching posts recruitment
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/GRC_8.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              "Grievance Redressal Committee" for Students Welfare
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/Sifdc.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Quotations are invited for Supply and Installation of Franz
              Diffusion Cell at DPSRU
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/RFP-Hostel-Mess.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Request for proposal (RFP) for Operating DPSRU Hostel Mess
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/Employment_Notice_TPO.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Employment Notice regarding Training Placement Officer in DPSRU
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/MBAMpharm.jpg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Addendum to 1st Counselling Schedule for M.Pharm./MBA Courses (Non
              GPAT Candidates)
            </a>
          </li>

          <li>
            <p className="whats-new-data">
              Notice regarding the advertisement(26th July 2019) of Regular
              Teaching Posts in DPSRU
            </p>
          </li>

          <li>
            <p className="whats-new-data">
              Admission Schedule for Lateral Entry(B.Pharm. 2nd Year) First
              Counselling-2019
            </p>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/AdmssionScheduleMpharm.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Admission Schedule for M.Pharm./MBA Courses
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/DPSRU_Guest_Advertisement.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Employment Notice Regarding the application invited for Part
              Time/Full Time/Temporary Faculty in DPSRU
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/UGorientation-Program-Notice-2019.jpg"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice reg. UG Orientation Program 2019
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/OO-NCL-DPSRU-19-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice Regarding the posts of Nodal Officer, Lab Technician and
              MTS for BIRAC project in DIIF
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/OO-NCL-DPSRU-19-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Result for the posts of Computer Programmer, Junior Stenographer
              and Nurse
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/academic-calender-2019.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Academic Calender - 2019-2020
            </a>
          </li>

          <li>
            <a
              href="https://dpsru.edu.in/wp-content/uploads/2019/08/Revised_First_Counselling_Schedule_for_UG_courses-1.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Admission schedule for D.Pharm, B.Pharm &amp; BPT courses - First
              Counselling - 2019
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/OO-NCL-DPSRU-19.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Office Order reg. verification of document, Non-Creamy Layer
            </a>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/yoga-admission-19.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Admission for the Certificate Course in Yoga. Last Date -
              25.06.2019
            </a>
          </li>

          <li>
            <p className="whats-new-data">
              Request for the quotation for printing and designing of Pharmanual
              2018-19. Last Date - 27.05.2019
            </p>
          </li>

          <li>
            <a
              href="http://dpsru.edu.in/~roshank/dpsru/wp-content/uploads/2019/08/SecondsCoun.pdf"
              target="_blank"
              rel="noreferrer"
              className="whats-new-data"
            >
              Notice inviting Quotation for Library Books
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WhatsNew;
