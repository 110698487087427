import React from "react";

const LibraryRulesAndRegulations = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        Library Rules and Regulations
      </h2>

      <ul className="subscribed-resources-ul">
      
          <li>
            
              Only DIPSAR/DPSRU Students, Researchers, Faculty and Staff as
              registered members are allowed to use the Library.
            
          </li>
          <li>
            
              University Identity Card/Library membership card is compulsory for
              getting access to the library.
            
          </li>
          <li>
            
              Take special care to maintain the library borrower cards.Library
              Membership Cards are Non-Transferable and members shall be
              responsible for the loss and misuse of Library Card.
            
          </li>
          <li>
            
              A member who loses a Library Identity Card shall make a written
              report to the Registrar/ Library Incharge. He/she shall be
              charged&nbsp;<strong>Rs.100/-&nbsp;</strong>for the
              <strong>&nbsp;</strong>per library card to recover the loss and
              shall be issued only one duplicate library card after two weeks
              from the date of report for the academic year.&nbsp;&nbsp;
            
          </li>
          <li>
            
              Library borrower cards are valid for the entire duration of the
              semester to access library facilities at the end of the semester
              borrower cards shall be returned to the library.
            
          </li>
          <li>
            
              Readers are responsible for books issued against their names.
            
          </li>
          <li>
            
              Silence to be maintained and no group discussion permitted inside
              the library premises Books in demand may not be renewed.
            
          </li>
          <li>
            
              Materials borrowed should be returned on or before the due date
              stamped, if returned late overdue fine of&nbsp;
              <strong>Rs. 5 per day&nbsp;</strong>will be charged for the
              delayed period.
            
          </li>
          <li>
            
              Uses of Mobile phones, earphones are not permitted inside the
              Library premises.
            
          </li>
          <li>
            
              Readers are not allowed to bring their personal books or any
              printed material (spiral/bound book/photocopied material) inside
              the Library.Personal belongings like bags (laptop, shoulder, and
              sling), umbrellas, etc. should be kept at the Property Counter.
              These are not allowed inside the Library.
            
          </li>
          <li>
            
              Readers are advised not to leave their valuable items like money,
              passport, credit card etc. at the Property Counter.The library
              shall not be responsible for any loss or damage of the personal
              belongings of the users.
            
          </li>
          <li>
            
              No monetary compensation can be claimed from the library in case
              of any damage to the personal belongings of member inside the
              library.
            
          </li>
          <li>
            
              The seats of reading section are to be used/occupied on the first
              come first served basis.
            
          </li>
          <li>
            
              Readers should not scribble on table tops or damage them.
            
          </li>
          <li>
            
              Users should not rest or keep their feet on tables, chairs,
              shelves, etc. Sitting on table is strictly prohibited.
            
          </li>
          <li>
            
              Using Cell phone or any other noising instruments are prohibited.
            
          </li>
          <li>
            
              Users are not allowed to take cuttings from newspapers. Required
              information may be photocopied.
            
          </li>
          <li>
            
              The arrangement of chairs in the Reading Rooms should not be
              disturbed.
            
          </li>
          <li>
            
              Keep clean the Library.Users are strictly not allowed to carry
              eatables/drinks inside the Library premises.
            
          </li>
          <li>
            
              The Library documents are very costly. They are for the benefit
              for both the present and the future users.&nbsp;
              <strong>
                <em>
                  Writing, marking and tracing in the documents is misconduct
                  for the user and dealt with accordingly.
                </em>
              </strong>
              &nbsp;If a document is mutilated or damaged when it was
              inpossession of the member, library has every right to demand
              for&nbsp;<strong>replacement or compensation</strong>&nbsp;for
              damages.
            
          </li>
          <li>
            
              The library follows an open access system. Books or other reading
              materials taken from the stacks should not be re-shelved by the
              readers but should be left on the reading tables. Please remember
              that a book misplaced is a book lost.
            
          </li>
          <li>
            
              Pasting of the poster at the entrance, glass doors or inside the
              library premises not allowed.
            
          </li>
          <li>
            
              A person must behave with decency and decorum. Any irregularities
              may kindly be brought the notice of the Librarian / Library
              Committee / Dean (Academics) / Director for necessary action.
            
          </li>
          <li>
            
              Member is requested to follow a decent dress code inside the
              library. Wearing Bermuda, shots, half paints and knickers not
              allowed inside the library. The Librarian may prescribe that
              certain areas of the Library are to be used for specified purposes
              only, or by certain persons or groups only. A person must not use
              those areas in contravention of that prescription.
            
          </li> <br />
          <li>
            
             <b>Entitlement to Borrow Library Books :</b>The registered member shall be permitted to borrow book(s) from the library by producing a library membership card at circulation desk. The entitlement of loan is given below: <br /> <br />

             <table><tbody><tr><td><strong>Member Category&nbsp;</strong></td><td><strong>Entitlement</strong></td><td><strong>Period of Loan</strong></td></tr><tr><td><strong>Faculty/Staff</strong></td><td>5 Books</td><td>90 Days</td></tr><tr><td><strong>Full time contractual&nbsp; faculty</strong></td><td>2 Books</td><td>30 Days</td></tr><tr><td><strong>For Ph.D. Scholars</strong></td><td>2 Books</td><td>30 Days</td></tr><tr><td><strong>For Students</strong></td><td>2 Books</td><td>Days</td></tr></tbody></table>
            
          </li>

          <li>
            <b>
            Borrowing Period for other Publications for faculty member only :
            </b> <br /><br />
            <table><tbody><tr><td><strong>Publications</strong></td><td><strong>Members</strong></td><td><strong>Period of Loan</strong></td></tr><tr><td><strong>Reference books, Educational Video CDs, Bound Volumes, Annual Reports.</strong></td><td>Faculty</td><td>On demand (overnight)</td></tr><tr><td colspan="3"><strong>Training Manuals, Journals, Magazines, and Newspapers are non-borrowable. Users may use these reading materials within the library</strong></td></tr></tbody></table>
          </li>

          <li>
            <b>Overdue and Fines :</b> Fine should be imposing on all members of DPSRU/DIPSAR Library members (teaching and non-teaching staff, full time contractual faculty, scholars, and students etc.) for late return of library books i.e. Rs.5 per day for each book.
          </li>

          <li>
            <b>Re-Issue and Recall of Books :</b>
            <ul><li>Book(s) shall be re-issued to the same member at once only. But, if there is a demand for the book, the request for renewal may be turned down by the library.</li><li>During the examinations, books and publications issued for the due period will not be renewed even if there is no demand for the said books.</li><li>The librarian can recall books and publications at any time if the need arises.</li></ul>
          </li>
      
      <li>
        <b>Reservation of Books :</b>A book on the loan can be reserved for a member on his/her formal request. Intimation will be put up at notice board to the member who had requested for reservation immediately after the return of the book. The requester shall collect the book within one day from the date of issue of notice. Failing which same shall go back in normal circulation and the reservation of book will be canceled.
      </li>
      </ul>
    </div>
  );
};

export default LibraryRulesAndRegulations;
