import React from 'react'
import chancellorImg from './chancellorDpsru.jpg'
import chancellorSign from './chancellorSign.jpg'
import chancellorMsg from './chancellorMsg.jpg'
import '../ViceChancellor.css'

function ChancellorDpsru() {
    return (
        <div className='vice-chancellor-full-component'>
            <h2 className="vc-comp-heading">
                From the Chancellor's Desk
            </h2>

            <div className="chancellor-and-vice-chancellor-image-container">
                <img src={chancellorImg} alt="VICE CHANCELLOR" className="vc-img" />
            </div>
            <div className="vc-intro">
                <h3 className="vc-intro-detailes-text">
                Sh. Vinai Kumar Saxena <br />
                    Hon’ble Lt. Governor, Delhi

                    <br />
                    Chancellor, DPSRU <br />
                </h3>
                <h3 className="vc-intro-contact">
                    Ph. (Off.): +91-11-23960809<br />
                    Ph. (Off.): +91-11-23975022<br />
                    Fax	:+91-11-23937099 <br />
                </h3>
            </div>

            <div className="vc-message-full">
                Pharmaceutical Science is a dynamic and rapidly changing field that combines a board range of scientific disciplines critical to the discovery and development of new drugs and therapies. The pharmaceutical sector has been a key asset in the international pursuit of health for humankind. A qualification in this discipline can lead to a variety of job roles, Which often offer good professional progression for research and management in allied health sciences.
                <br /> <br />
                In last 6 Years the University has been working hard to attain high standards of academic excellence, particuarly in the field of research, innovation and incubation. The Univesity's contribution in providing skilled and specialized human resouce for the healthcare system is widely recognized.

                <br /><br />
                The University has a student strength of around 2600, with 6 certificate, 3 diploma, 7 undergraduate, 24 postgraduate and 8 doctoral programs in the field of not only pharmacy but also public health, hospital management, laboratory technology, artificial intelligence, yoga, meditation and Ayurveda etc.

                It is indeed appreciable that the University is committed to meeting the objectives of the New Education Policy 2020.


            </div> 

            <div className="sign-container chancellor-sign-container-img">
                <img src={chancellorSign} alt="vc-signature" className="vc-sign" />
                {/* <p className="vc-sign-text" style={{ fontFamily: 'cursive' }}>Sh. Vinai Kumar Saxena</p> */}

            </div>

            <div className="original-copy-of-vc-and-chancellor-msg">
                <a href={chancellorMsg}>
                    {/* <img src={chancellorMsg} alt="Chancellor Message" /> */}
                    See the original Copy of Chancellor Message
                </a>
            </div>
            <br /><br />

        </div>



    )
}

export default ChancellorDpsru