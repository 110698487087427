import React from "react";
import askPdf from './Final-PDF-Recommendation-Form.pdf'

const AskALibrarian = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Ask a Librarian</h2>

      <h2 className="need-help-question-heading">Need help using the DPSRU Library?</h2>

      <ul className="subscribed-resources-ul">
        <li>
          <b> In-Person </b> : Visit the Library Reference/Circulation Desk.
        </li>
        <li>
          <b>E-Mail </b> : library@dpsru.edu.in
        </li>


        <li>
          <b>Recommendation for Purchase</b>: For Recommend/Suggest a book, Journal, or any other relevant resource for purchase by the DPSRU Library.  Please used the Hard copies of <a href={askPdf}>recommendation form</a> and submit the same at the Reference/Circulation Desk.
        </li>

        <li>
          <b>Feedback and Suggestion: </b>DPSRU Library welcomes and encourages every user to let us know how to improve your experience in the Library. Please send us your Feedback and Suggestion.  Hard copies of suggestion forms can be submitted at the Reference/Circulation Desk.
        </li>
      </ul>

      <h2 className="need-help-question-heading">Follow us on Social Media: </h2>
      
      <ul className="subscribed-resources-ul">
        <li><b>Facebook Page Name :</b>DPSRU Library</li>
        <li><b>Facebook PageURL :</b><a href=" https://www.facebook.com/assistant2018librarian" style={{color:'blue'}} target="_blank"> Click Here..</a></li>
        </ul>
    </div>
  );
};

export default AskALibrarian;
