import React from "react";
import "./Institutions.css";
import { NavLink } from "react-router-dom";

function Institutions() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Institutions </h2>
      <h6 className="dpsru-secondary-heading-for-every-page">
        List of Institutions
      </h6>

      <div className="institutions-table-conntainer">
        <table>
          
          <thead style={{color:'white', background:'gray'}}>
            <tr>
              <td>S No.</td>
              <td>
                <h1>Institution’s Name</h1>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>
                <NavLink className="navlink-of-instutions-data" to='/dipsar'>
                  Delhi Institute of Pharmaceutical Sciences and
                  Research(DIPSAR)
                </NavLink>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                
                  <NavLink className="navlink-of-instutions-data" to="/soPharmaceticalSciences">
                    School of Pharmaceutical Sciences (SPS)
                  </NavLink>
                
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>
                
              <NavLink className="navlink-of-instutions-data" to="/sahsm">
                    School of Allied Health Sciences and Management (SAHSM)
                    </NavLink>
                
              </td>
            </tr>
            <tr>
              <td>4.</td>
              <td>
                
              <NavLink className="navlink-of-instutions-data" to="/soPhy">
                    School of Physiotherapy (SOP)
                    </NavLink>
                
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>
                
              <NavLink className="navlink-of-instutions-data" to="/assrm">
                    Academy of Sports Sciences Research and Management (ASSRM)
                    </NavLink>
                
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>
                
              <NavLink className="navlink-of-instutions-data" to="/wcsc">
                    World Class Skill Development Centre (WCSC)
                    </NavLink>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Institutions;
