import React, { useState } from "react";
import "./QuestionPapers.css";

import mp1 from "./QuestionPaper Files/mp1.pdf";
import mp2 from "./QuestionPaper Files/mp2.pdf";
import mp3 from "./QuestionPaper Files/mp3.pdf";
import mp4 from "./QuestionPaper Files/mp4.pdf";
import mp5 from "./QuestionPaper Files/mp5.pdf";
import mp6 from "./QuestionPaper Files/mp6.pdf";
import mp7 from "./QuestionPaper Files/mp7.pdf";
import mp8 from "./QuestionPaper Files/mp8.pdf";
import mp9 from "./QuestionPaper Files/mp9.pdf";
import mp10 from "./QuestionPaper Files/mp10.pdf";
import mp11 from "./QuestionPaper Files/mp11.pdf";
import mp12 from "./QuestionPaper Files/mp12.pdf";
import mp13 from "./QuestionPaper Files/mp13.pdf";
import mp14 from "./QuestionPaper Files/mp14.pdf";
import mp15 from "./QuestionPaper Files/mp15.pdf";

import MHA from "./QuestionPaper Files/MHA.pdf";
import MPH from "./QuestionPaper Files/MPH.pdf";
import MBAPM from "./QuestionPaper Files/MBAPM.pdf";
import MBAPET from "./QuestionPaper Files/MBAPET.pdf";
import MSCAI from "./QuestionPaper Files/M.-Pharm-Executive-MRA-1st-Semester-May-2022.pdf";
import MPTS from "./QuestionPaper Files/MPTS-1st-Semester-May-2022.pdf";

import BPharm from "./QuestionPaper Files/BPharm.pdf";
import BPharm2 from "./QuestionPaper Files/BPharm2.pdf";
import BPharm3 from "./QuestionPaper Files/BPharm3.pdf";
import BPharm4 from "./QuestionPaper Files/BPharm4.pdf";
import BPharm5 from "./QuestionPaper Files/BPharm5.pdf";
import BPharm6 from "./QuestionPaper Files/BPharm6.pdf";
import BPharm7 from "./QuestionPaper Files/BPharm7.pdf";
import BPharm8 from "./QuestionPaper Files/BPharm8.pdf";

import BPT1 from "./QuestionPaper Files/BPT1.pdf";
import BPT3 from "./QuestionPaper Files/BPT3.pdf";
import BPT5 from "./QuestionPaper Files/BPT5.pdf";
import BPT6 from "./QuestionPaper Files/BPT6.pdf";
import BPT7 from "./QuestionPaper Files/BPT7.pdf";
import BPT8 from "./QuestionPaper Files/BPT8.pdf";

import BSS1 from "./QuestionPaper Files/BSS1.pdf";
import BSS3 from "./QuestionPaper Files/BSS3.pdf";
import BSS4 from "./QuestionPaper Files/BSS4.pdf";
import BSS5 from "./QuestionPaper Files/BSS5.pdf";
import BSS6 from "./QuestionPaper Files/BSS6.pdf";

import BPAYU from "./QuestionPaper Files/BPAYU.pdf";

import BMLT from "./QuestionPaper Files/BMLT.pdf";

import dp221 from "./QuestionPaper Files/dp221.pdf";
import dp222 from "./QuestionPaper Files/dp222.pdf";
import dp223 from "./QuestionPaper Files/dp223.pdf";
import dp224 from "./QuestionPaper Files/dp224.pdf";
import dp225 from "./QuestionPaper Files/dp225.pdf";

import dp211 from "./QuestionPaper Files/dp211.pdf";
import dp212 from "./QuestionPaper Files/dp212.pdf";

import dp201 from "./QuestionPaper Files/dp201.pdf";
import dp202 from "./QuestionPaper Files/dp202.pdf";

import dp191 from "./QuestionPaper Files/dp191.pdf";
import dp192 from "./QuestionPaper Files/dp192.pdf";
import dp193 from "./QuestionPaper Files/dp193.pdf";
import dp194 from "./QuestionPaper Files/dp194.pdf";

import dp181 from "./QuestionPaper Files/dp181.pdf";
import dp182 from "./QuestionPaper Files/dp182.pdf";
import dp183 from "./QuestionPaper Files/dp183.pdf";
import dp184 from "./QuestionPaper Files/dp184.pdf";

import dp171 from "./QuestionPaper Files/dp171.pdf";
import dp172 from "./QuestionPaper Files/dp172.pdf";

import dmlt from "./QuestionPaper Files/dmlt.pdf";
import msc2022aipm from "./QuestionPaper Files/M.Sc_.-AI-PM-1st-Semester-May-2022.pdf";

// 2023
import bp101t from "./QuestionPaper Files/2023/BP 101T Q.P.pdf";
import bp102t from "./QuestionPaper Files/2023/BP 102T Q.P.pdf";
import bp103t from "./QuestionPaper Files/2023/BP 103T Q.P.pdf";
import bp104t from "./QuestionPaper Files/2023/BP 104T Q.P.pdf";

import bp201t from "./QuestionPaper Files/2023/BP 201T Q.P.pdf";
import bp202t from "./QuestionPaper Files/2023/BP 202T Q.P.pdf";
import bp203t from "./QuestionPaper Files/2023/BP 203T Q.P.pdf";
import bp204t from "./QuestionPaper Files/2023/BP 204T Q.P.pdf";

import bp301t from "./QuestionPaper Files/2023/BP 301T Q.P.pdf";
import bp302t from "./QuestionPaper Files/2023/BP 302T Q.P.pdf";
import bp303t from "./QuestionPaper Files/2023/BP 302T Q.P.pdf";
import bp304t from "./QuestionPaper Files/2023/BO 304T Q.P.pdf";
import bp401t from "./QuestionPaper Files/2023/BP 401T Q.P.pdf";
import bp402t from "./QuestionPaper Files/2023/BP 402T Q.P.pdf";
import bp403t from "./QuestionPaper Files/2023/BP 403T Q.P.pdf";
import bp404t from "./QuestionPaper Files/2023/BP 404T Q.P.pdf";
import bp405t from "./QuestionPaper Files/2023/BP 405T Q.P.pdf";
import bp501t from "./QuestionPaper Files/2023/BP 501T Q.P.pdf";
import bp502t from "./QuestionPaper Files/2023/BP 502T Q.P.pdf";
import bp503t from "./QuestionPaper Files/2023/BP 503T Q.P.pdf";
import bp504t from "./QuestionPaper Files/2023/BP 504T Q.P.pdf";
import bp505t from "./QuestionPaper Files/2023/BP 505T Q.P.pdf";

import bp601t from "./QuestionPaper Files/2023/BP 601T Q.P.pdf";
import bp602t from "./QuestionPaper Files/2023/BP 602T Q.P.pdf";
import bp603t from "./QuestionPaper Files/2023/BP 603T Q.P.pdf";
import bp604t from "./QuestionPaper Files/2023/BP 604T Q.P.pdf";
import bp605t from "./QuestionPaper Files/2023/BP 605T Q.P.pdf";
import bp606t from "./QuestionPaper Files/2023/BP 606T Q.P.pdf";
import bp701tQPOLdBatch from "./QuestionPaper Files/2023/BP 701T Q.P Old Batch.pdf";
import bp701t from "./QuestionPaper Files/2023/BP 701T Q.P.pdf";
import bp702t from "./QuestionPaper Files/2023/BP 702T Q.P.pdf";
import bp702tqpOldbatch from "./QuestionPaper Files/2023/BP 702TQ.P Old Batch.pdf";
import bp703tqpOldbatch from "./QuestionPaper Files/2023/BP 703T Q P Old Batch.pdf";
import bp703t from "./QuestionPaper Files/2023/BP 703T Q.P.pdf";
import bp704opOldBatch from "./QuestionPaper Files/2023/BP 704T Q.P Old Batch .pdf";
import bp705opOldBatch from "./QuestionPaper Files/2023/BP 705T Old Batch.pdf";
import bp706opOldBatch from "./QuestionPaper Files/2023/BP 705T Old Batch.pdf";

import bp801tQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp802tQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp803etQP from "./QuestionPaper Files/2023/BP 801T Q.P.pdf";
import bp804etQP from "./QuestionPaper Files/2023/BP 804ET Q.P.pdf";
import bp805etQP from "./QuestionPaper Files/2023/BP 805ET Q.P.pdf";
import bp808etQP from "./QuestionPaper Files/2023/BP 808ET Q.P.pdf";
import bp809etQP from "./QuestionPaper Files/2023/BP 809ET Q.P.pdf";
import bp811etQP from "./QuestionPaper Files/2023/BP 811ET Q.P.pdf";
import bp812etQP from "./QuestionPaper Files/2023/BP 812ET Q.P.pdf";


import exeDRS101qp from './QuestionPaper Files/2023/Exe. DRS 101T Q.P.pdf'
import exeDRS102qp from './QuestionPaper Files/2023/Exe. DRS 102 Q.P.pdf'
import exeDRS103qp from './QuestionPaper Files/2023/Exe. DRS 103T  Q.P.pdf'
import exeDRS104qp from './QuestionPaper Files/2023/Exe. DRS 104T Q.P.pdf'
import matCommonPaper from './QuestionPaper Files/2023/MAT Common Paper.pdf'
import Mph102tQP from './QuestionPaper Files/2023/MHP 102T Q.P.pdf'
import Mph103tQP from './QuestionPaper Files/2023/MHP 103T Q.P.pdf'
import Mph104tQP from './QuestionPaper Files/2023/MHP 104T Q.P.pdf'
import MCC102tqp from './QuestionPaper Files/2023/MCC 102T Q.P.pdf'
import MCC103tqp from './QuestionPaper Files/2023/MCC 103T Q.P.pdf'
import MCC104tqp from './QuestionPaper Files/2023/MCC 104T Q.P.pdf'
import mip104tqp from './QuestionPaper Files/2023/MIP 104T Q.P.pdf'
import mip102tqp from './QuestionPaper Files/2023/MIP 102T Q.P.pdf'
import mip103tqp from './QuestionPaper Files/2023/MIP 103T Q.P.pdf'
import mpa102tqp from './QuestionPaper Files/2023/MPA 102T Q.P.pdf'
import mpa103tqp from './QuestionPaper Files/2023/MPA 103T Q.P.pdf'
import mpa104tqp from './QuestionPaper Files/2023/MPA 104TQ.P.pdf'
import mpb102tqp from './QuestionPaper Files/2023/MPB 102T Q.P.pdf'
import mpb103tqp from './QuestionPaper Files/2023/MPB 103T Q.P.pdf'
import mpb104tqp from './QuestionPaper Files/2023/MPB 104T Q P.pdf'
import mpc103tqp from './QuestionPaper Files/2023/MPC 103T Q.P.pdf'
import mpc102tqp from './QuestionPaper Files/2023/MPC 102T Q.P.pdf'
import mpc104tqp from './QuestionPaper Files/2023/MPC 104T Q.P.pdf'
import mpcr102tqp from './QuestionPaper Files/2023/MPCR 102T Q.P.pdf'
import mpcr103tqp from './QuestionPaper Files/2023/MPCR 103T Q.P.pdf'
import mpcr104tqp from './QuestionPaper Files/2023/MPCR 104T Q.P.pdf'
import mpg102tqp from './QuestionPaper Files/2023/MPG 102T Q.P.pdf'
import mpg103tqp from './QuestionPaper Files/2023/MPG 103T Q.P.pdf'
import mpg104tqp from './QuestionPaper Files/2023/MPG 104T Q.P.pdf'
import mph102tqp from './QuestionPaper Files/2023/MPH 102T Q.P.pdf'
import mph103tqp from './QuestionPaper Files/2023/MPH 103T Q.P.pdf'
import mph104tqp from './QuestionPaper Files/2023/MPH 104T Q.P.pdf'
import mpl102tqp from './QuestionPaper Files/2023/MPL 102T Q.P.pdf'
import mpl103tqp from './QuestionPaper Files/2023/MPL 103T Q.P.pdf'
import mpl104tqp from './QuestionPaper Files/2023/MPL 104T Q.P.pdf'
import mqa102tqp from './QuestionPaper Files/2023/MQA 102T Q.P.pdf'
import mqa103tqp from './QuestionPaper Files/2023/MQA 103T Q.P.pdf'
import mqa104tqp from './QuestionPaper Files/2023/MQA 104T Q.P.pdf'
import mra101tqp from './QuestionPaper Files/2023/MRA 101T Q.P.pdf'
import mra102tqp from './QuestionPaper Files/2023/MRA 102T Q.P.pdf'
import mra103tqp from './QuestionPaper Files/2023/MRA 103T Q.P.pdf'
import mra104tqp from './QuestionPaper Files/2023/MRA 104T Q.P.pdf'
import ygs104tqp from './QuestionPaper Files/2023/YGS 104T Q.P.pdf'


import exemra201tqp from './QuestionPaper Files/2023/Exe- MRA 201T Q.P.pdf'
import exemra202tqp from './QuestionPaper Files/2023/Exe-MRA 202T Q.P.pdf'
import exemra203tqp from './QuestionPaper Files/2023/Exe-MRA 203T Q.P.pdf'
import exemra204tqp from './QuestionPaper Files/2023/EX MRA 204T Q.P.pdf'
import mcc201tQP from './QuestionPaper Files/2023/MCC 201T Q.P.pdf'
import mcc202tQP from './QuestionPaper Files/2023/MCC 202T Q.P.pdf'
import mcc203tQP from './QuestionPaper Files/2023/MCC 203T Q.P.pdf'
import mcc204tQP from './QuestionPaper Files/2023/MCC 204T Q.P.pdf'
import mhp201tqp from './QuestionPaper Files/2023/MHP 201T Q.P.pdf'
import mhp202tqp from './QuestionPaper Files/2023/MHP 202T Q.P.pdf'
import mhp203tqp from './QuestionPaper Files/2023/MHP 203T Q.P.pdf'
import mhp204tqp from './QuestionPaper Files/2023/MHP 204T Q,P.pdf'
import mip202tqp from './QuestionPaper Files/2023/MIP 202T Q.P.pdf'
import mip203tqp from './QuestionPaper Files/2023/MIP 203T Q.P.pdf'
import mip201tqp from './QuestionPaper Files/2023/MIP 201T Q.P.pdf'
import mip204tqp from './QuestionPaper Files/2023/MIP 204T Q,P.pdf'
import mpa201tqp from './QuestionPaper Files/2023/MPA 201T Q.P.pdf'
import mpa202tqp from './QuestionPaper Files/2023/MPA 202T Q.P.pdf'
import mpa203tqp from './QuestionPaper Files/2023/MPA 203T Q.P.pdf'
import mpa204tqp from './QuestionPaper Files/2023/MPA 204T Q,P.pdf'
import mpb201tqp from './QuestionPaper Files/2023/MPB 201T Q.P.pdf'
import mpb202tqp from './QuestionPaper Files/2023/MPB 202T Q.P.pdf'
import mpb203tqp from './QuestionPaper Files/2023/MPB 203T Q.P.pdf'
import mpb204tqp from './QuestionPaper Files/2023/MPB 204T Q,P.pdf'
import mpc201tqp from './QuestionPaper Files/2023/MPC 201T Q.P.pdf'
import mpc202tqp from './QuestionPaper Files/2023/MPC 202T Q.P.pdf'
import mpc203tqp from './QuestionPaper Files/2023/MPC 203T Q.P.pdf'
import mpc204tqp from './QuestionPaper Files/2023/MPC 204T Q,P.pdf'
import mpcr201tqp from './QuestionPaper Files/2023/MPCR 201T Q.P.pdf'
import mpcr202tqp from './QuestionPaper Files/2023/MPCR 202T Q.P.pdf'
import mpcr203tqp from './QuestionPaper Files/2023/MPCR 203T Q.P.pdf'
import mpcr204tqp from './QuestionPaper Files/2023/MPCR 204T Q,P.pdf'
import mpg201tqp from './QuestionPaper Files/2023/MPG 201T Q.P.pdf'
import mpg202tqp from './QuestionPaper Files/2023/MPG 202T Q.P.pdf'
import mpg203tqp from './QuestionPaper Files/2023/MPG 203T Q.P.pdf'
import mpg204tqp from './QuestionPaper Files/2023/MPG 204T Q,P.pdf'
import mph202tqp from './QuestionPaper Files/2023/MPH 202T Q.P.pdf'
import mph201tqp from './QuestionPaper Files/2023/MPH 201T Q.P.pdf'
import mph203tqp from './QuestionPaper Files/2023/MPH 203T Q.P.pdf'
import mph204tqp from './QuestionPaper Files/2023/MPH 204T Q,P.pdf'
import mpl201tqp from './QuestionPaper Files/2023/MPL 201T Q.P.pdf'
import mpl203tqp from './QuestionPaper Files/2023/MPL 203T Q.P.pdf'
import mqa201tqp from './QuestionPaper Files/2023/MQA 201T Q.P.pdf'
import mlp202tqp from './QuestionPaper Files/2023/MPL 202T Q.P.pdf'
import mpl204tql from './QuestionPaper Files/2023/MPL 204T Q,P.pdf'
import mqa202tqp from './QuestionPaper Files/2023/MQA 202T Q.P.pdf'
import mqa203tqp from './QuestionPaper Files/2023/MQA 203T Q.P.pdf'
import mqa204tqp from './QuestionPaper Files/2023/MQA 204T Q,P.pdf'
import mra201tqp from './QuestionPaper Files/2023/MRA 201T Q.P.pdf'
import mra202tqp from './QuestionPaper Files/2023/MRA 202T Q,P.pdf'
import mra203tqp from './QuestionPaper Files/2023/MRA 203T Q.P.pdf'
import mra204tqp from './QuestionPaper Files/2023/MRA 204T Q,P.pdf'




function QuestionPaper() {
  const [mp2022, setMp2022] = useState(false);
  const [mha2022, setMha2022] = useState(false);
  const [mph2022, setMph2022] = useState(false);
  const [mbaPm2022, setMbaPm2022] = useState(false);
  const [mbaPet2022, setMbaPet2022] = useState(false);
  const [mscAipm2022, setMscAipm2022] = useState(false);
  const [mpsm2022, setMpsm2022] = useState(false);
  const [bp2022, setBp2022] = useState(false);
  const [bphy2022, setBphy2022] = useState(false);
  const [bscss2022, setBscss2022] = useState(false);
  const [bpa2022, setBpa2022] = useState(false);
  const [bmlt2022, setBmlt2022] = useState(false);

  // diploma
  const [dp2022, setDp2022] = useState(false);
  const [dp2021, setDp2021] = useState(false);
  const [dp2020, setDp2020] = useState(false);
  const [dp2019, setDp2019] = useState(false);
  const [dp2018, setDp2018] = useState(false);
  const [dp2017, setDp2017] = useState(false);

  const [dmlt2022, setDmlt2022] = useState(false);

  // 2023
  const [bPharm2023, setBpharm2023] = useState(false);
  const [mPharm1stSem2023, setMpharm1stsem2023] = useState(false);
  const [mPharm2ndSem2023, setMpharm2ndsem2023] = useState(false);

  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">QUESTION PAPERS</h2>

      <h3 className="dpsru-secondary-heading-for-every-page">2023</h3>

      {/* box */}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">M.Pharm 1st semester</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMpharm1stsem2023(!mPharm1stSem2023)}
        >
          2023
        </button>

        {mPharm1stSem2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
            <li>
                <a href={matCommonPaper} target="_blank" rel="noreferrer">
                MAT Common Paper
                </a>
              </li>
            <li>
                <a href={exeDRS101qp} target="_blank" rel="noreferrer">
                Exe. DRS 101T Q.P.
                </a>
              </li>

            <li>
                <a href={exeDRS102qp} target="_blank" rel="noreferrer">
                Exe. DRS 102 Q.P.
                </a>
              </li>
            <li>
                <a href={exeDRS103qp} target="_blank" rel="noreferrer">
                Exe. DRS 103T  Q.P.
                </a>
              </li>
            <li>
                <a href={exeDRS104qp} target="_blank" rel="noreferrer">
                Exe. DRS 104T Q.P.
                </a>
              </li>
            <li>
                <a href={Mph102tQP} target="_blank" rel="noreferrer">
                MHP 102T Q.P.
                </a>
              </li>
            <li>
                <a href={Mph103tQP} target="_blank" rel="noreferrer">
                MHP 103T Q.P.
                </a>
              </li>
            <li>
                <a href={Mph104tQP} target="_blank" rel="noreferrer">
                MHP 104T Q.P.
                </a>
              </li>
            <li>
                <a href={MCC102tqp} target="_blank" rel="noreferrer">
                MCC 102T Q.P.
                </a>
              </li>
            <li>
                <a href={MCC103tqp} target="_blank" rel="noreferrer">
                MCC 103T Q.P.
                </a>
              </li>
            <li>
                <a href={MCC104tqp} target="_blank" rel="noreferrer">
                MCC 104T Q.P.
                </a>
              </li>
            <li>
                <a href={mip102tqp} target="_blank" rel="noreferrer">
                MIP 102T Q.P.
                </a>
              </li>
            <li>
                <a href={mip103tqp} target="_blank" rel="noreferrer">
                MIP 103T Q.P.
                </a>
              </li>
            <li>
                <a href={mip104tqp} target="_blank" rel="noreferrer">
                MIP 104T Q.P.
                </a>
              </li>

            <li>
                <a href={mpa102tqp} target="_blank" rel="noreferrer">
                MPA 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mpa103tqp} target="_blank" rel="noreferrer">
                MPA 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mpa104tqp} target="_blank" rel="noreferrer">
                MPA 104T Q.P.
                </a>
             </li>

            <li>
                <a href={mpb102tqp} target="_blank" rel="noreferrer">
                MPB 102T Q.P.
                </a>
             </li>

            <li>
                <a href={mpb103tqp} target="_blank" rel="noreferrer">
                MPB 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mpb104tqp} target="_blank" rel="noreferrer">
                MPB 104T Q P.
                </a>
             </li>
            <li>
                <a href={mpc102tqp} target="_blank" rel="noreferrer">
                MPC 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mpc103tqp} target="_blank" rel="noreferrer">
                MPC 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mpc104tqp} target="_blank" rel="noreferrer">
                MPC 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mpcr102tqp} target="_blank" rel="noreferrer">
                MPCR 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mpcr103tqp} target="_blank" rel="noreferrer">
                MPCR 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mpcr104tqp} target="_blank" rel="noreferrer">
                MPCR 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mpg102tqp} target="_blank" rel="noreferrer">
                MPG 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mpg103tqp} target="_blank" rel="noreferrer">
                MPG 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mpg104tqp} target="_blank" rel="noreferrer">
                MPG 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mph102tqp} target="_blank" rel="noreferrer">
                MPH 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mph103tqp} target="_blank" rel="noreferrer">
                MPH 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mph104tqp} target="_blank" rel="noreferrer">
                MPH 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mpl102tqp} target="_blank" rel="noreferrer">
                MPL 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mpl103tqp} target="_blank" rel="noreferrer">
                MPL 103T Q.P.
                </a>
             </li>

            <li>
                <a href={mpl104tqp} target="_blank" rel="noreferrer">
                MPL 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mqa102tqp} target="_blank" rel="noreferrer">
                MQA 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mqa103tqp} target="_blank" rel="noreferrer">
                MQA 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mqa104tqp} target="_blank" rel="noreferrer">
                MQA 104T Q.P.
                </a>
             </li>
            <li>
                <a href={mra101tqp} target="_blank" rel="noreferrer">
                MRA 101T Q.P.
                </a>
             </li>
            <li>
                <a href={mra102tqp} target="_blank" rel="noreferrer">
                MRA 102T Q.P.
                </a>
             </li>
            <li>
                <a href={mra103tqp} target="_blank" rel="noreferrer">
                MRA 103T Q.P.
                </a>
             </li>
            <li>
                <a href={mra104tqp} target="_blank" rel="noreferrer">
                MRA 104T Q.P.
                </a>
             </li>
            <li>
                <a href={ygs104tqp} target="_blank" rel="noreferrer">
                YGS 104T Q.P.
                </a>
             </li>


            </ul>
          </div>
        ) : null}
      </div>


      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">M.Pharm 2nd Semester</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMpharm2ndsem2023(!mPharm2ndSem2023)}
        >
          2023
        </button>

        {mPharm2ndSem2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
            <li>
                <a href={exemra201tqp} target="_blank" rel="noreferrer">
                Exe- MRA 201T Q.P.
                </a>
              </li>
            <li>
                <a href={exemra202tqp} target="_blank" rel="noreferrer">
                Exe-MRA 202T Q.P.
                </a>
              </li>
            <li>
                <a href={exemra203tqp} target="_blank" rel="noreferrer">
                Exe-MRA 203T Q.P.
                </a>
              </li>
            <li>
                <a href={exemra204tqp} target="_blank" rel="noreferrer">
                Exe-MRA 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mcc201tQP} target="_blank" rel="noreferrer">
                MCC 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mcc202tQP} target="_blank" rel="noreferrer">
                MCC 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mcc203tQP} target="_blank" rel="noreferrer">
                MCC 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mcc204tQP} target="_blank" rel="noreferrer">
                MCC 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mhp201tqp} target="_blank" rel="noreferrer">
                MHP 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mhp202tqp} target="_blank" rel="noreferrer">
                MHP 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mhp203tqp} target="_blank" rel="noreferrer">
                MHP 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mhp204tqp} target="_blank" rel="noreferrer">
                MHP 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mip201tqp} target="_blank" rel="noreferrer">
                MIP 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mip202tqp} target="_blank" rel="noreferrer">
                MIP 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mip203tqp} target="_blank" rel="noreferrer">
                MIP 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mip204tqp} target="_blank" rel="noreferrer">
                MIP 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpa201tqp} target="_blank" rel="noreferrer">
                MPA 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpa202tqp} target="_blank" rel="noreferrer">
                MPA 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mpa203tqp} target="_blank" rel="noreferrer">
                MPA 203T Q.P.
                </a>
              </li>

            <li>
                <a href={mpa204tqp} target="_blank" rel="noreferrer">
                MPA 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpb201tqp} target="_blank" rel="noreferrer">
                MPB 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpb202tqp} target="_blank" rel="noreferrer">
                MPB 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mpb203tqp} target="_blank" rel="noreferrer">
                MPB 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mpb204tqp} target="_blank" rel="noreferrer">
                MPB 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpc201tqp} target="_blank" rel="noreferrer">
                MPC 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpc202tqp} target="_blank" rel="noreferrer">
                MPC 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mpc203tqp} target="_blank" rel="noreferrer">
                MPC 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mpc204tqp} target="_blank" rel="noreferrer">
                MPC 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpcr201tqp} target="_blank" rel="noreferrer">
                MPCR 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpcr202tqp} target="_blank" rel="noreferrer">
                MPCR 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mpcr203tqp} target="_blank" rel="noreferrer">
                MPCR 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mpcr204tqp} target="_blank" rel="noreferrer">
                MPCR 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpg201tqp} target="_blank" rel="noreferrer">
                MPG 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpg202tqp} target="_blank" rel="noreferrer">
                MPG 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mpg203tqp} target="_blank" rel="noreferrer">
                MPG 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mpg204tqp} target="_blank" rel="noreferrer">
                MPG 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mph201tqp} target="_blank" rel="noreferrer">
                MPH 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mph202tqp} target="_blank" rel="noreferrer">
                MPH 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mph203tqp} target="_blank" rel="noreferrer">
                MPH 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mph204tqp} target="_blank" rel="noreferrer">
                MPH 204T Q.P.
                </a>
              </li>
            <li>
                <a href={mpl201tqp} target="_blank" rel="noreferrer">
                MPL 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mpl203tqp} target="_blank" rel="noreferrer">
                MPL 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mpl204tql} target="_blank" rel="noreferrer">
                MPL 204T Q,P.
                </a>
              </li>
            <li>
                <a href={mqa201tqp} target="_blank" rel="noreferrer">
                MQA 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mqa202tqp} target="_blank" rel="noreferrer">
                MQA 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mqa203tqp} target="_blank" rel="noreferrer">
                MQA 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mqa204tqp} target="_blank" rel="noreferrer">
                MQA 204T Q,P.
                </a>
              </li>
            <li>
                <a href={mlp202tqp} target="_blank" rel="noreferrer">
                MPL 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mra201tqp} target="_blank" rel="noreferrer">
                MRA 201T Q.P.
                </a>
              </li>
            <li>
                <a href={mra202tqp} target="_blank" rel="noreferrer">
                MRA 202T Q.P.
                </a>
              </li>
            <li>
                <a href={mra203tqp} target="_blank" rel="noreferrer">
                MRA 203T Q.P.
                </a>
              </li>
            <li>
                <a href={mra204tqp} target="_blank" rel="noreferrer">
                MRA 204T Q.P.
                </a>
              </li>

              </ul>
              </div>)
              :null
            }
              </div>

      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Bachelor of Pharmacy
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBpharm2023(!bPharm2023)}
        >
          2023
        </button>
        {bPharm2023 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={bp101t} target="_blank" rel="noreferrer">
                  BP 101T Q.P.
                </a>
              </li>
              <li>
                <a href={bp102t} target="_blank" rel="noreferrer">
                  BP 102T Q.P.
                </a>
              </li>
              <li>
                <a href={bp103t} target="_blank" rel="noreferrer">
                  BP 103T Q.P.
                </a>
              </li>
              <li>
                <a href={bp104t} target="_blank" rel="noreferrer">
                  BP 104T Q.P.
                </a>
              </li>{" "}
              <br />
              <li>
                <a href={bp201t} target="_blank" rel="noreferrer">
                  BP 201T Q.P.
                </a>
              </li>
              <li>
                <a href={bp202t} target="_blank" rel="noreferrer">
                  BP 202T Q.P.
                </a>
              </li>
              <li>
                <a href={bp203t} target="_blank" rel="noreferrer">
                  BP 203T Q.P.
                </a>
              </li>
              <li>
                <a href={bp204t} target="_blank" rel="noreferrer">
                  BP 204T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp301t} target="_blank" rel="noreferrer">
                  BP 301T Q.P.
                </a>
              </li>
              <li>
                <a href={bp302t} target="_blank" rel="noreferrer">
                  BP 302T Q.P.
                </a>
              </li>
              <li>
                <a href={bp303t} target="_blank" rel="noreferrer">
                  BP 303T Q.P.
                </a>
              </li>
              <li>
                <a href={bp304t} target="_blank" rel="noreferrer">
                  BO 304T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp401t} target="_blank" rel="noreferrer">
                  BP 401T Q.P.
                </a>
              </li>
              <li>
                <a href={bp402t} target="_blank" rel="noreferrer">
                  BP 402T Q.P.
                </a>
              </li>
              <li>
                <a href={bp403t} target="_blank" rel="noreferrer">
                  BP 403T Q.P.
                </a>
              </li>
              <li>
                <a href={bp404t} target="_blank" rel="noreferrer">
                  BP 404T Q.P.
                </a>
              </li>
              <li>
                <a href={bp405t} target="_blank" rel="noreferrer">
                  BP 405T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp501t} target="_blank" rel="noreferrer">
                  BP 501T Q.P.
                </a>
              </li>
              <li>
                <a href={bp502t} target="_blank" rel="noreferrer">
                  BP 502T Q.P.
                </a>
              </li>
              <li>
                <a href={bp503t} target="_blank" rel="noreferrer">
                  BP 503T Q.P.
                </a>
              </li>
              <li>
                <a href={bp504t} target="_blank" rel="noreferrer">
                  BP 504T Q.P.
                </a>
              </li>
              <li>
                <a href={bp505t} target="_blank" rel="noreferrer">
                  BP 505T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp601t} target="_blank" rel="noreferrer">
                  BP 601T Q.P.
                </a>
              </li>
              <li>
                <a href={bp602t} target="_blank" rel="noreferrer">
                  BP 602T Q.P.
                </a>
              </li>
              <li>
                <a href={bp603t} target="_blank" rel="noreferrer">
                  BP 603T Q.P.
                </a>
              </li>
              <li>
                <a href={bp604t} target="_blank" rel="noreferrer">
                  BP 604T Q.P.
                </a>
              </li>
              <li>
                <a href={bp605t} target="_blank" rel="noreferrer">
                  BP 605T Q.P.
                </a>
              </li>
              <li>
                <a href={bp606t} target="_blank" rel="noreferrer">
                  BP 606T Q.P.
                </a>
              </li>
              <br />
              <li>
                <a href={bp701tQPOLdBatch} target="_blank" rel="noreferrer">
                  BP 701T Q.P Old Batch
                </a>
              </li>
              <li>
                <a href={bp701t} target="_blank" rel="noreferrer">
                  BP 701T Q.P.
                </a>
              </li>
              <li>
                <a href={bp702tqpOldbatch} target="_blank" rel="noreferrer">
                  BP 702TQ.P Old Batch
                </a>
              </li>
              <li>
                <a href={bp702t} target="_blank" rel="noreferrer">
                  BP 702T Q.P.
                </a>
              </li>
              <li>
                <a href={bp703tqpOldbatch} target="_blank" rel="noreferrer">
                  BP 703T Q P Old Batch
                </a>
              </li>
              <li>
                <a href={bp703t} target="_blank" rel="noreferrer">
                  BP 703T Q.P.
                </a>
              </li>
              <li>
                <a href={bp704opOldBatch} target="_blank" rel="noreferrer">
                  BP 704T Q.P Old Batch{" "}
                </a>
              </li>
              <li>
                <a href={bp705opOldBatch} target="_blank" rel="noreferrer">
                  BP 705T Old Batch
                </a>
              </li>
              <li>
                <a href={bp706opOldBatch} target="_blank" rel="noreferrer">
                  BP 706T Q.P Old Batch
                </a>
              </li>{" "}
              <br />
              <li>
                <a href={bp801tQP} target="_blank" rel="noreferrer">
                  BP 801T Q.P.
                </a>
              </li>
              <li>
                <a href={bp802tQP} target="_blank" rel="noreferrer">
                  BP 802T Q.P.
                </a>
              </li>
              <li>
                <a href={bp803etQP} target="_blank" rel="noreferrer">
                  BP 803ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp804etQP} target="_blank" rel="noreferrer">
                  BP 804ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp805etQP} target="_blank" rel="noreferrer">
                  BP 805ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp808etQP} target="_blank" rel="noreferrer">
                  BP 808ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp809etQP} target="_blank" rel="noreferrer">
                  BP 809ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp811etQP} target="_blank" rel="noreferrer">
                  BP 811ET Q.P.
                </a>
              </li>
              <li>
                <a href={bp812etQP} target="_blank" rel="noreferrer">
                  BP 812ET Q.P.
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <h3 className="dpsru-secondary-heading-for-every-page">PG Program</h3>

      {/* box */}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">Master in Pharmacy</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMp2022(!mp2022)}
        >
          2022
        </button>
        {mp2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={mp1} target="_blank" rel="noreferrer">
                  M. Pharm Executive MRA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp2} target="_blank" rel="noreferrer">
                  M. Pharm MAT Common Paper 1st semester May 2022
                </a>
              </li>
              <li>
                <a href={mp3} target="_blank" rel="noreferrer">
                  M. Pharm MCC 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp4} target="_blank" rel="noreferrer">
                  M. Pharm MHP 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp5} target="_blank" rel="noreferrer">
                  M. Pharm MIP 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp6} target="_blank" rel="noreferrer">
                  M. Pharm MPA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp7} target="_blank" rel="noreferrer">
                  M. Pharm MPB 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp8} target="_blank" rel="noreferrer">
                  M. Pharm MPC 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp9} target="_blank" rel="noreferrer">
                  M. Pharm MPCR 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp10} target="_blank" rel="noreferrer">
                  M. Pharm MPG 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp11} target="_blank" rel="noreferrer">
                  M. Pharm MPH 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp12} target="_blank" rel="noreferrer">
                  M. Pharm MPL 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp13} target="_blank" rel="noreferrer">
                  M. Pharm MQA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp14} target="_blank" rel="noreferrer">
                  M. Pharm MRA 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={mp15} target="_blank" rel="noreferrer">
                  M. Pharm YGS (Yoga Science) 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Master of Hospital Administration
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMha2022(!mha2022)}
        >
          2022
        </button>
        {mha2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MHA} target="_blank" rel="noreferrer">
                  MHA 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  3*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Master of Public Health
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMph2022(!mph2022)}
        >
          2022
        </button>
        {mph2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MPH} target="_blank" rel="noreferrer">
                  M. PH. First Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  4*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          MBA ( Pharmaceutical Management)
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMbaPm2022(!mbaPm2022)}
        >
          2022
        </button>
        {mbaPm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MBAPM} target="_blank" rel="noreferrer">
                  MBA PM 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  5*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          MBA (Pharma Economics and Trade)
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMbaPet2022(!mbaPet2022)}
        >
          2022
        </button>
        {mbaPet2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MBAPET} target="_blank" rel="noreferrer">
                  MBA PET 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  6*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          M.Sc. (Artificial Intelligence and Precision Medicine)
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMscAipm2022(!mscAipm2022)}
        >
          2022
        </button>
        {mscAipm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={msc2022aipm} target="_blank" rel="noreferrer">
                  M.Sc. AI & PM 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  6*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Master of Physiotherapy in Sports Medicine
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setMpsm2022(!mpsm2022)}
        >
          2022
        </button>
        {mpsm2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={MPTS} target="_blank" rel="noreferrer">
                  MPTS 1st Semester May 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  7*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Bachelor of Pharmacy
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBp2022(!bp2022)}
        >
          2022
        </button>
        {bp2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPharm} target="_blank" rel="noreferrer">
                  B Pharm 1st Semester April 2022
                </a>
              </li>
              <li>
                <a href={BPharm2} target="_blank" rel="noreferrer">
                  B Pharm 3rd Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm3} target="_blank" rel="noreferrer">
                  B Pharm 3rd Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm4} target="_blank" rel="noreferrer">
                  B Pharm 5th Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm5} target="_blank" rel="noreferrer">
                  B Pharm 5th Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm6} target="_blank" rel="noreferrer">
                  B Pharm 7th Semester March 2022 (New Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm7} target="_blank" rel="noreferrer">
                  B Pharm 7th Semester March 2022 (Old Scheme)
                </a>
              </li>
              <li>
                <a href={BPharm8} target="_blank" rel="noreferrer">
                  B Pharm 8th Semester July 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  8*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Bachelor of Physiotherapy
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBphy2022(!bphy2022)}
        >
          2022
        </button>
        {bphy2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPT1} target="_blank" rel="noopener">
                  BPT 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={BPT3} target="_blank" rel="noopener">
                  BPT 3rd&nbsp; Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT5} target="_blank" rel="noopener">
                  BPT 5th Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT6} target="_blank" rel="noopener">
                  BPT 6th Semester August&nbsp; 2022
                </a>
              </li>
              <li>
                <a href={BPT7} target="_blank" rel="noopener">
                  BPT 7th Semester March 2022
                </a>
              </li>
              <li>
                <a href={BPT8} target="_blank" rel="noopener">
                  BPT 8th Semester August 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  9*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          B.Sc. (Hons.) Sports Science
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBscss2022(!bscss2022)}
        >
          2022
        </button>
        {bscss2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BSS1} target="_blank" rel="noopener">
                  BSS 1st Semester May 2022
                </a>
              </li>
              <li>
                <a href={BSS3} target="_blank" rel="noopener">
                  BSS 3rd Semester (New Scheme), March 2022
                </a>
              </li>
              <li>
                <a href={BSS4} target="_blank" rel="noopener">
                  BSS 4th Semester, August&nbsp; 2022
                </a>
              </li>
              <li>
                <a href={BSS5} target="_blank" rel="noopener">
                  BSS 5th Semester (Old Scheme), March 2022
                </a>
              </li>
              <li>
                <a href={BSS6} target="_blank" rel="noopener">
                  BSS 6th Semester (Old Scheme), July&nbsp; 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  10*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">
          Bachelor of Pharmacy (Ayurveda)
        </h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBpa2022(!bpa2022)}
        >
          2022
        </button>
        {bpa2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BPAYU} target="_blank" rel="noopener">
                  B.Pharm (Ayur) 1st Semester June 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* box  10*/}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">BMLT</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setBmlt2022(!bmlt2022)}
        >
          2022
        </button>
        {bmlt2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={BMLT} target="_blank" rel="noopener">
                  BMLT 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {/* Diploma programmes */}
      <h3 className="dpsru-secondary-heading-for-every-page">
        Diploma programmes
      </h3>

      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">Diploma in Pharmacy</h4>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2022(!dp2022)}
          >
            2022
          </button>
          {dp2022 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp221} target="_blank" rel="noreferrer">
                    1st Year Annual Examination August 2022
                  </a>
                </li>
                <li>
                  <a href={dp222} target="_blank" rel="noreferrer">
                    2nd Year Annual Examination May, 2022
                  </a>
                </li>
                <li>
                  <a href={dp223} target="_blank" rel="noreferrer">
                    D. Pharm 2nd Year Supplementary , August 2022
                  </a>
                </li>
                <li>
                  <a href={dp224} target="_blank" rel="noreferrer">
                    D. Pharm 2nd Year Supplementary Examination , April 2022
                  </a>
                </li>
                <li>
                  <a href={dp225} target="_blank" rel="noreferrer">
                    D. Pharm 1st Year Supplementary Examination April, 2022
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2021(!dp2021)}
          >
            2021
          </button>
          {dp2021 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp211}>
                    D. Pharm 1st Year Annual Examination. September, 2021
                  </a>
                </li>
                <li>
                  <a href={dp212}>
                    D. Pharm 2nd Year Annual Examination, May 2021
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2020(!dp2020)}
          >
            2020
          </button>
          {dp2020 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp201}>
                    D. Pharm 1st Year Supplementary Examination, October 2020
                  </a>
                </li>
                <li>
                  <a href={dp202}>
                    D. Pharm 2nd Year Annual Examination, September 2020
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2019(!dp2019)}
          >
            2019
          </button>
          {dp2019 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp191}>
                    D. Pharm 2nd Year Supplementary Examination, September, 2019
                  </a>
                </li>
                <li>
                  <a href={dp192}>
                    D. Pharm 1st Year Supplementary Examination, September, 2019
                  </a>
                </li>
                <li>
                  <a href={dp193}>
                    D. Pharm 1st Year Annual Examination, April 2019
                  </a>
                </li>
                <li>
                  <a href={dp194}>
                    D. Pharm 2nd Year Annual Examination, April 2019
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2018(!dp2018)}
          >
            2018
          </button>
          {dp2018 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp181}>
                    D. Pharm 2nd Year Supplementary Examination, September, 2018
                  </a>
                </li>
                <li>
                  <a href={dp182}>
                    D. Pharm 1st Year Supplementary Examination, September, 2018
                  </a>
                </li>
                <li>
                  <a href={dp183}>
                    D. Pharm 2nd Year Annual Examination, May 2018
                  </a>
                </li>
                <li>
                  <a href={dp184}>
                    D. Pharm 1st Year Annual Examination, May 2018
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>

        {/* btn box */}
        <div className="questionPaper-btn-box">
          <button
            className="questionPaper-btn"
            onClick={() => setDp2017(!dp2017)}
          >
            2017
          </button>
          {dp2017 ? (
            <div className="questionPaper-onClick-container questionPaper-onClick-container-2">
              <ul>
                <li>
                  <a href={dp171}>
                    D. Pharm 1st Year Supplementary Examination, September, 2017
                  </a>
                </li>
                <li>
                  <a href={dp172}>
                    D. Pharm 1st Year Annual Examination, May 2017
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>

      {/* box */}
      <div className="questionPaper-content-container">
        <h4 className="questionPaper-Container-heading">DMLT</h4>
        <button
          className="questionPaper-btn"
          onClick={() => setDmlt2022(!dmlt2022)}
        >
          2022
        </button>
        {dmlt2022 ? (
          <div className="questionPaper-onClick-container">
            <ul>
              <li>
                <a href={dmlt} target="_blank" rel="noreferrer">
                  DMLT 1st Semester April 2022
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <h3 className="dpsru-secondary-heading-for-every-page">
        Certificate Programmes (WCSC)
      </h3>
    </div>
  );
}

export default QuestionPaper;
