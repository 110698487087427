import React, { useState } from "react";

import profimg from "./IMG_0062-scaled-e1686561287881-150x150.jpg";

const MrBaljinderSingh = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">
Mr. Baljinder Singh
</h2>
      <h3 className="facult-designation-for-each-container">
      Assistant Professor | Department of Sports Science 
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email :{" "}
            <a href="mailto:">
              {" "}
             
            </a>
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>  <a >Google Scholar</a></li>
            <li>  <a>Scopus</a></li>
            <li>
              <a>ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
      <ul><li>Master of Physical Education (M.P.Ed), from Sri Guru Granth Sahib World University, Fatehgarh Sahib in 2021</li><li>Bachelor of Physical Education (B.P.Ed), from Sri Guru Granth Sahib World University, Fatehgarh Sahib in 2019</li><li>Bachelor of Arts from Sri Guru Gobind Singh College 26 Chandigarh, Punjab University-2017</li></ul>
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Academic Area:</b> Foundation of Sports Science, Sports Training, Test, Measurement and Evaluation, Sports of Choice: Athletics, Environmental Studies, Sports Journalism & Mass Media
      </p>
      <p className="academic-qualification-for-all-container-paragrauph">
     <b> Sports Interest:</b> Field Hockey, Track and Field Events, Yoga Practices, Gym Management, Gymnastics, Swimming
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
       Publications
      </button>

      {isResearchOpen ? <div className="inner-container">
      <ul><li>Presented a research paper on “Kinematic Variables and Its Relationship with Performance of Hang Style In Long Jump, 2023</li><li>Published a paper on “Analysis of the current status of physical education in schools of Patiala.</li><li>Published a paper on “An analysis of routine dietary intake and eating habits of college students” in an “International Journal of Sports Science and Nutrition 2022”. ISSN:2707-7012.</li></ul>
      </div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
       Conferences
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
        <ul><li>AHF (Asian Hockey Federation) Umpiring Workshops. (2021)</li><li>Participation in “International conference of psychology in contemporary sports, health, and fitness perspective 2019”</li><li>AHF (Asian Hockey Federation) Umpiring Workshops. (2021)</li><li>Hockey India Umpiring Workshop.2021</li><li>Hockey India Basic Coaching Workshop.2021</li><li>Attended 2 Days workshop on SPSS conducted by the Academy of Sports Sciences Research and Management (DPSRU), on 29<sup>th</sup> – 30<sup>th</sup> September 2021.</li><li>Participation in Sports India Conference 2022 PEFI “India-The Emerging Sporting Superpower” on 4<sup>th</sup> August 2022.</li><li>Attended seminar on “Awareness of Flag Code of India” on 16 august 2022.</li><li>Participation in “One Week International Faculty Development Program (FDP)” on 22<sup>nd</sup> – 27<sup>th</sup> August 2022.</li><li>Attended an International Conference Physical Education &amp; Exercise Sciences&nbsp;dated 22nd to 24th&nbsp; March, 2023 held at Punjabi University, Patiala.</li></ul>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
       Contributions to Society
      </button>

      {isConferencesOpen ? <div className="inner-container">
      <ul><li>Volunteered in Special Olympic Bharat Workshop of Special Need Child, in 2018 Trainer for KVS Train the trainer Program 2019-2020</li><li>Facility Volunteer in the COVID awareness program (social service program ), 2021.</li></ul>
      </div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <ul><li>Key speaker in intergrading approach to Sports and Physical Activities (2022)</li><li>Officiating in Inter-University in Patiala in the year 2019.</li><li>Officiating in North-Zone, Inter-College, School Level, District, State, All India Open Tournaments,</li><li>Officiating in “Senior Men’s State Championship” Hockey Tournament.</li><li>Technical Official in “All India Inter Academy Hockey Tournament”.</li><li>&nbsp;Member of Hockey Punjab Unit Federation since 2019.</li><li>&nbsp;National Umpire of Hockey India Federation since 2019.</li><li>Participated in All-India Inter-University Hockey Championship.</li><li>Bronze in Senior Men National Ball Hockey Championship.</li><li>Silver Medal in Senior State Men’s Hockey.</li><li>Bronze in Inter-Collegiate Competition.</li><li>Participated in Nehru Gold Cup Hockey Tournament.</li><li>Gold Medal in District School Tournament.</li><li>Bronze Medal in CBSE North Zone Hockey Tournament.</li><li>Participated in All-India Gurmeet Memorial Hockey Tournament.</li><li>Participated in SN Vohra Hockey Championship.</li></ul>
        </div>
      ) : null}
    </div>
  );
};

export default MrBaljinderSingh;
