import React, { useState } from "react";

import profimg from "./download-46.png";

const ProfRameshKGoyal = () => {
  const [isResearchOpen, setIsResearchOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);
  const [isConferencesOpen, setIsConferencesOpen] = useState(false);
  const [isAwardsandRecognitionsOpen, setIsAwardsandRecognitionsOpen] =
    useState(false);
  return (
    <div className="each-faculty-component-page">
      <div className="faculty-image-container-for-each-page">
        <img src={profimg} alt="Prof-pk-sahoo" />
      </div>
      <h2 className="faculty-name-for-each-container">Prof. Ramesh K. Goyal</h2>
      <h3 className="facult-designation-for-each-container">
        Honourable Vice- Chancellor & Professor
      </h3>
      <div className="faculty-contact-and-research-impact-details-each-container">
        <div className="faculty-contact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">Contact</h3>
          <h4 className="faculty-contact-details-email-for-each-page">
            Email : vc [at] dpsru [dot] edu [dot] in
          </h4>
        </div>
        <div className="faculty-research-impact-details-each-container">
          <h3 className="contact-heading-of-faculty-details">
            Research Impact
          </h3>
          <ul
            className="faculty-contact-details-email-for-each-page"
            style={{ paddingLeft: "12%" }}
          >
            <li>
              <a href="https://scholar.google.com/citations?user=3sdz8yEAAAAJ&hl=en">
                Google Scholar{" "}
              </a>
            </li>
            <li>
              <a href="https://www.scopus.com/authid/detail.uri?authorId=34569960300">
                Scopus
              </a>
            </li>
            <li>
              <a href="https://orcid.org/0000-0001-8179-6423">ORCID URL</a>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="academic-qualification-heading-for-every-page">
        Academic Qualifications
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        B.Sc. (Hon.), M.Sc. (Medical), Ph.D. (Pharmacy)
      </p>

      <h2 className="academic-qualification-heading-for-every-page">
        Areas of Interest
      </h2>
      <p className="academic-qualification-for-all-container-paragrauph">
        Cardiovascular Pharmacology and Diabetes
      </p>

      {/* toggle button */}
      <button
        className="naac-button"
        onClick={() => setIsResearchOpen(!isResearchOpen)}
      >
        Research
      </button>

      {isResearchOpen ? <div className="inner-container"></div> : null}

      {/* Publications */}

      <button
        className="naac-button"
        onClick={() => setIsPublicationsOpen(!isPublicationsOpen)}
      >
        Publications
      </button>

      {isPublicationsOpen ? (
        <div className="inner-container">
          <p>
            <b>LIST OF PUBLICATIONS IN REFEREED JOURNALS</b>{" "}
            <b>No. Co-authors, Title, Journal, Volume, Year</b>
          </p>
          <ol>
            <li>
              Patel SR, Goyal, RK Shah, DS. Studies on the pharmacological
              effects of <i>Tinospora cordifolia</i>. Jour. Res Ind Med Yoga
              Homoeo 13: 46-57; 1978
            </li>
            <li>
              Verma, SC Goyal RK. Evidence for the indirect effect of histamine
              in the estrogen primed rat uterus. Ind J Pharmacol 11: 201-208,
              1979
            </li>
            <li>
              Verma, SC, Goyal, RK. and McNeill, J.H. Adrenergic mechanisms for
              histamine H2 – receptor effects on rat myometrium. Ind J Physiol
              Pharmacol24(1) : 1-71, 1980
            </li>
            <li>
              Goyal, R.K. and Verma S.C Adrenergic mechanisms in myometrium :
              Their relation with adenylate cyclase cyclic AMP. Ind J Pharmacol
              12(3) : 141-147, 1980
            </li>
            <li>
              Goyal, R.K. and Verma, S.C Direct and excitatory histamine
              receptors in guinea-pig uterus. and taenia coli. Ind J Pharmacol
              12 : 165-171, (1980)
            </li>
            <li>
              Patel, N.M., Goyal, R.K. and Verma, S.C. Histaminergic H1 and H2
              receptors in guinea pig uterus and taenia coli. Can. J. Physiol.
              Pharmac. 58, 1500-1503, 1980
            </li>
            <li>
              Goyal, R.K. and Verma, S.C. Pharmacological investigations into
              the effects of histamine and histamine analogues in guinea pig and
              rat uterus. Agents and Actions 11(4) : 312-317, 1981
            </li>
            <li>
              Goyal, R.K., Patel N.M., Verma, S.C. Mechanism of action of
              5-hydroxytryptamine on the rat anococcygeus muscle Ind J Pharmacol
              13 : 233-245, 1981
            </li>
            <li>
              Goyal, R.K. and Patel, N.M. and Verma, S.C. Comparative studies on
              the effects of 5-hydroxytryptamine and noradrenaline on the rat
              anococcygeus muscle. Agents and Actions 11 : 667-672 1981
            </li>
            <li>
              Goyal, R.K., Verma S.C Studies on the effects of adrenaline
              noradrenaline and phenylephrine on the estrogen primed rat
              myometrium. Ind. J. Pharmac, 13 : 261- 270, 1982
            </li>
            <li>
              Goyal, R.K. and Verma, S.C. Mechanism of action of histamine in
              estrogen primed rat uterus. Eur. J. Pharmac. 77 : 237-242, 1982
            </li>
            <li>
              Patel, N.M., Goyal, R.K. and Verma, S.C. Effect of uptake blockers
              on 5- hydroxytryptamine and noradrenaline induced responses on rat
              anococcygeus muscle. Ind J Exp Biol 20 : 160-162, 1982
            </li>
            <li>
              Ahuja, G.B., Goyal, R.K. and Verma, S.C. Guinea-pig cardiac and
              ileal histamine receptors during hyperthyroid state. Ind J Exp
              Biol 20(6) : 479-480, 1982
            </li>
            <li>
              Patel, U.P., Goyal R.K. and Verma, S.C. Effects of MK-212 on
              isolated guinea pig heart. Ind J Pharmacol 14 : 319-232, 1982
            </li>
            <li>
              Goyal, R.K., Patel, N.M. and Verma, S.C. Evidence for the indirect
              effect of 5-hydro xytryptamine on the rat anococcygeus muscle.
              Life Sci. Adv. 2(1) : 21-26, 1982
            </li>
            <li>
              Goyal, R.K., Dave, K.C. and Verma, S.C. Investigations into the
              inhibitory effects of clonidine on the estrogen primed rat uterus.
              J. Aut. Pharmac. 3(3) : 213-217, 1983
            </li>
            <li>
              Ahuja, G.B., Goyal, R.K. and Verma, S.C. Hyperthyroid induced
              changes in the adrenergic receptor status in the guinea pig vas
              deferens. Ind J Exp Biol 21 : 272- 274, 1983
            </li>

            <li>
              Burande, M.D., Goyal, R.K. and Verma, S.C. Studies on the
              mechanism of cardiotonic effects of sodium fluoroacetate and
              dobutamine. Ind J Exp Biol 21 : 150-152, 1983
            </li>
            <li>
              Burande, M.D. , Goyal, R.K. and Verma, S.C. Studies on the
              influence of extra cellular ions on the positive inotropic effects
              of sodium fluoroacetate and dobutamine. Ind J Pharmacol 15(2) :
              111-118. 1983
            </li>
            <li>
              Patel, V.G. and Goyal, R.K. Influence of extracellular ions on the
              cardiotonic effects of BDPU (1-3(1-6,7-dimethoxyquinazoline 4 –
              yl) piperidine-4-yl urea). Ind J Exp Biol 21 : 448-450, 1983
            </li>
            <li>
              Goyal, R.K. The anococcygeus muscle, A promising smooth muscle
              preparation for the autonomic research. Ind J Pharmacol 16 :
              68-79, 1984
            </li>
            <li>
              Jain, A.N. and Goyal, R.K. Studies on the indirect sympathomimetic
              effects of nitroxazepine on the rat anococcygeus muscle. Ind J
              Pharmacol 16 : 221-224, 1984.
            </li>
            <li>
              Bhansali, B.B. and Goyal, R.K. Studies on the mechanism of
              positive inotropic action of WIN 35020 (3(dimethylamino),
              2,3,4,9-tetra hydro H.Carbazol, 7(HCI). Ind J Exp Biol 44 :
              484-486, 1984
            </li>
            <li>
              Goyal, R.K. and McNeill, J.H. Effects of chronic streptozotocin
              induced diabetes on the cardiac responses to milrinone. Can. J.
              Physiol. Pharmac. 63 : 1620-1623, 1985
            </li>
            <li>
              Goyal, R.K. and McNeill, J.H. Effects of [Na+] and [Ca2+] ions the
              responses to milrinone in rat cardiac preparations. Eur. J.
              Pharmac. 120 : 267-274, 1986
            </li>
            <li>
              Goyal, R.K. and Verma, S.C. Studies on the effects of histamine,
              impromidine, dimaprit and 4-methyl histamine (the H2 agonists) the
              estrogen primed a rat uterus. Ind J Pharmacol 18 : 116-116, 1986
            </li>
            <li>
              Rodrigues, B, Goyal, R.K. and McNeill , J.H. Effects of
              hydralazine on streptozotocin induced diabetic rat I. prevention
              of hyperlipidemia and improvement in cardiac function. J. Pharmac.
              Expt. Ther. 237 : 292-299, 1986
            </li>

            <p>
              28.. Srinivas, G.R. , Goyal, R.K., Vyas, S and Dewan A. Effects of
              some basicamide compounds. Part -I. In electric and aconitine
              arrhythmia models. Ind J Exp Biol 24 : 533-536, 1986
            </p>

            <li>
              Srinivas, G.R., Goyal, R.K., Vyas, S and Dewan, A Antiarrhythmic
              actions of some basicamide compounds. Part-II. In mouse chloroform
              and ischemic arrhythmic models. Ind J Exp Biol 24 : 537-540, 1986
            </li>
            <li>
              Mehta, S.M., Bhalara, D.D. and Goyal, R.K. Effect of ranitidine on
              the enzyme cholinesterase and the rat anococcygeus muscle. Agents
              and Actions 21 : 38-40, 1986.
            </li>
            <li>
              Desai, D.D., Jain, A.N. and Goyal, R.K. Effects of chronic
              treatments of estrogen and progesterone on histamine receptor
              sensitivity in guinea pig uterus. Ind J Pharmacol 19 : 19-25, 1987
            </li>
            <li>
              Patel, D.M. and Goyal, R.K. Effects of MK-212 (6-chloro-2-1-
              piperazinyl) pyrazine) and 5- hydroxytryptamine on various smooth
              muscle preparations. Ind J Pharmacol 19 : 19-25, 1987
            </li>
            <li>
              Bhansali, B.N., Vyas, S and Goyal., R.K. Cardiac effects of
              quercetin on the isolated rabbit and frog heart preparations Ind J
              Pharmacol19(2) : 100-107, 1987
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 28</i>
            </p>

            <li>
              Goyal, R.K., Rodrigues, B. and McNeill, J.H. Effects of T3 on the
              cardiac responses to adrenergic agonists in STZ induced diabetic
              rats. Gen. Pharmac. 18(4) : 357-362, 1987
            </li>
            <li>
              Shah, R.S., Jain., A.N., Dewan , A and Goyal, R.K. Effects of
              Chronic lead toxicity on the sensitivity of anococcygeus muscle of
              rat. Ind J Pharmacol 19(2) : 108-116, 1987
            </li>
            <li>
              Goyal, R.K., Jain, S.M, Sheth, J.J., Dave, K.C, Mehta , H.C.
              Altered digoxin levels in diabetic and patients with congestive
              cardiac failure. Ind J Pharmacol 19(2) : 117- 123, 1987
            </li>
            <li>
              Jain, A.N. and Goyal, R.K. Autonomic effects of nitroxazepine on
              rat anococcygeus muscle. Indian Drugs25(3) : 87-90, 1987
            </li>
            <li>
              Patel, V.K., Bhatt, R.V. and Goyal, R.K. Effect of metoclopramide
              and the status of dopamine receptors in rat anococcygeus muscle.
              Ind J Pharmacol 20(3) : 129-134, 1987
            </li>
            <li>
              Bhutiani, P.K., Shah, R.J. and Goyal, R.K. Influence of
              temperature on the status of histamine receptors in the isolated
              guinea pig ileum. Ind J Pharmacol 20(3) : 122- 128, 1988
            </li>
            <li>
              Goyal, R.K. and Dave, K.C Mechanism of noradrenaline release by
              histamine in the estrogen primed rat uterus. Ind J Pharmacol 20(2)
              : 135-142, 1988
            </li>
            <li>
              Gohil, S. K., Patel, V.K., Bhatt, R. V. and Goyal, R.K.
              Cholinergic effects of clonidine on the rat anococcygeus muscle
              preparation. Ind J Pharmacol 20 : 159-166, 1988
            </li>
            <li>
              Chinmayee, N., Mehta, A.A., Joshi, D.P. and Goyal, R.K. Auto
              inhibition and desensitization of serotonergic responses in smooth
              muscle preparations. Ind J Physiol Pharmacol32(3) : 215-220, 1988
            </li>
            <li>
              Shukla, V.M., Bhatt, R.V. and Goyal, R.K. Influence of
              hyperthyroidism on the cholinergic receptors. Ind J Pharmacol 20 :
              188-191, 1988
            </li>
            <li>
              Vasavada, B.H. Santani, D.D. and Goyal, R.K. Studies on the
              mechanism of positive inotropic action of Crataegus. Ind J Nat
              Prod 5 : 11-14, 1989
            </li>
            <li>
              Mehta, A.A., Santani., D.D. and Goyal, R.K. Potassium-channel
              openers : A novel class of drugs with therapeutic potentials.
              Indian Drugs26(9) : 457-459,1989.
            </li>
            <li>
              Rajdan, R., Mehta A.A. and Goyal, R.K. Differential effects of
              calcium and sodium on the mechanical responses to various agonists
              in rat vas-deferens and anococcygeus muscle. Ind J Pharmacol 21 :
              81-89, 1989
            </li>
            <li>
              Subramaniam, A. Bhatt, R.V, Santani, D.D. and Goyal, R.K.
              Pre-synaptic dopamine receptors in rat anococcygeus muscle. Ind J
              Pharmacol 21 : 120-128, 1989
            </li>
            <li>
              Vasavada, B.H, Mehta, A.A., Santani, D.D. and Goyal, R.K.
              Mechanism of action of various inotropic agents : A search for
              digitalis substitute. Ind J Pharmacol 22(3) : 119 127, 1990.
            </li>
            <li>
              Jain, S.M., Bhatt, R.V., Goyal , R.K. Santani, D.D. and Parmar,
              N.S. Effect of verapamil on gastric ulcers in rat. Ind J Pharmacol
              22 : 253-256, 1990
            </li>
            <li>
              Goyal, R.K., Kumar, R, and McNeill, J.H. Pharmacological evidence
              for the association of hypothyroidism with diabetes-mellitus. Ind
              J Pharmacol 22 : 71-77, 1990
            </li>
            <li>
              Shishoo, C.J., Dewani, M.B., Bhadti, V.S., Jain, K.S., Rathod,
              I.S., Goyal, R.K., Naik, S.R., Gandhi, T.P., and Patel, R.D.
              Synthesis and Pharmacological study of antihyperlipaemic activity
              of 2 substituted thieno (2,3-d) pyrimidin-4(3H)-ones. Arz Forsch
              40 : 566-572,1990
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 29</i>
            </p>

            <li>
              Goyal, R.K. Effects of carnitine treatment on cardiac
              phosphorylase and lipids in streptozotocin-induced diabetic rats.
              Ind J Pharmacol 23 : 197-201, 1991
            </li>
            <li>
              Bhatt, P.A., Goyal, R.K., and Santani, D.D. Alteration in
              responsiveness of various smooth muscle preparations to serotonin
              after chronic hyperthyroidism. Indian Drugs29 : 20-24, 1991
            </li>
            <li>
              Patel, N. J., Santani, D.D, and Goyal, R.K. Investigations into
              the possible receptor sensitivity of astemizole. Ind J Pharmacol
              24, : 129-130, 1992
            </li>
            <li>
              Kalara, N.A., Bhatt, R.V., Trivedi, U., Trivedi,J.J. and Goyal,
              R.K. Local anaethetic activity of some basicamide compounds. Ind J
              Pharmacol 25 : 20-24, 1993
            </li>
            <li>
              Goyal, R.K. Effects of hydralazine on cardiac responsiveness to
              adrenergic agonists in streptozotocin -induced diabetic rats. Ind
              J Physio Pharmacol 37 : 54-59, 1993
            </li>
            <li>
              Satia, M.C., and Goyal, R.K. Evidence for alpha-adrenoceptor
              action of hydralazine. Ind J Pharmacol 25 : 121-125, 1993
            </li>
            <li>
              Mehta, A.A., Dave, K.C. and Goyal R.K. Studies on post-receptor
              mechanisms in smooth muscle preparations. Ind J Pharmacol 25(4)
              :126-133, 1993
            </li>
            <li>
              Goyal, R.K., Gandhi, T.P., Satia, M.C. Role of hypertension
              control in diabetes mellitus and the agents of choice. Ind J
              Pharmacol 25(4) : 181-187, 1993
            </li>
            <li>
              Shah, G.B. and Goyal, R.K. Effect of yohimbine in congestive
              cardiac failure. Ind J Pharmacol 26(1) : 41-43. 1994
            </li>
            <li>
              Patil, M.R., Satia, M.C., Mehta, A.A. and Goyal, R.K. Evidence for
              the catecholamine depleting action of fluoxetine. Ind J Physiol
              Pharmaco l38(3) : 169-173,1994.
            </li>
            <li>
              Goyal, R.K, Desai, J.S., Shah, N.S. “Effect of Morphine addiction
              on In: Recent advances dopamine receptors” in the study of
              neurotransmitters receptoreds. B.N.Dhawan, Srimal R.C, Raghuvir R
              Rapaka: R.S. In C.D.R.I. Research Perspectives vol.17 pp
              48-57.C.D.R.I Lucknow, India,(1994).
            </li>
            <li>
              Mehta, A.A., Dave, K.C. and Goyal, R.K. Evidence for the
              involvement of calmodulin and K+ channels in contractile responses
              to various agonists in smooth muscle preparations. Res. Comm.
              5(1): 267-276, 1995
            </li>
            <li>
              Mehta, A.A., Dave, K.C. and Goyal, R.K. Involvement of K+ channels
              on the relaxant responses to various agonists in estrogen primed
              rat uterus. Ind J Physiol Pharmacol39(2) : 140-144, 1995
            </li>
            <li>
              Shah, T.S. Singh, N., Goyal, R.K., Dev, A., Chhabaria, M.T. and
              Shishoo, C.J. Pharmacological evaluation of LM-2616: A
              beta1-adrenoceptor antagonist with beta2-adrenoceptor agonistic
              activity. Res. Comm. 5(1): 253-265, 1995
            </li>
            <li>
              Satia, M.C., Shukla, M.L. and Goyal, R.K. Comparative effects of
              atenolol versus nifedipine on serum lipids and other biochemical
              parameters in diabetic and non diabetic hypertensive subjects. Ind
              J Physiol Pharmacol39(3): 237-241, 1995
            </li>
            <li>
              Satia, M.C., Gandhi, T.P. and Goyal, R.K. Losartan-an angiotensin
              receptor antagonist : A novel class of antihypertensive agents.
              Ind. J. Pharmac 27 : 142-151, 1995
            </li>
            <li>
              Hakim, Z.S., Satia, M.C. and Goyal, R.K. Angiotensin receptor
              antagonists : A new class of antihypertensive drugs. Ind. J.
              Pharm. Sci. 57(3):91-99, 1995
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 30</i>
            </p>

            <li>
              Shah, T.S., Satia, M.C., Gandhi, T.P., Bangaru, R.A. and Goyal,
              R.K. Effects of chronic nifedipine treatment on
              streptozotocin-induced diabetic rats. J Cardiovasc Pharmac 26 :
              6-12, 1995
            </li>
            <li>
              Desai, J.K., Goyal R.K. and Parmar, N.S. Gastric and duodenal
              anti-ulcers activity of SKF 38393, a dopamine D1-receptor agonist
              in rats. J Pharm Pharmacol 47(9) : 734- 738, 1995.
            </li>

            <p>
              71.. Hakim, Z.S., Bangaru, R.A., Santani, D.D. and Goyal, R.K.
              Potential anti-diabetic agents from plant sources. Ind J Nat Prod
              11 : 3-10, 1996
            </p>

            <li>
              Joshi, S.D,. Santani, D.D., Sheth J.J., Mehta, H.C. Dave, K.C.
              &amp; Goyal, R.K. Investigation into the possible mechanism
              involved in altered digoxin levels in diabetic patients. Ind J
              Physiol Pharmacol40 : 65-69, 1996
            </li>
            <li>
              Satia, M.C., Shukla, M.L., and Goyal, R.K. Effects of enalapril on
              lipid profile in diabetic and non-diabetic essential hypertensive
              patients. Ind J Physiol Pharmacol40 : 83-86, 1996
            </li>
            <li>
              Satia, M.C., Gandhi, T.P. and Goyal, R.K. Effects of chronic
              treatment with hydralazine on various in vitro preparations of
              rat. Ind J Exp Biol 34 : 205-207, 1996
            </li>
            <li>
              Shishoo, C.J. Jain K.S. Rathod, I.S. Thakkar, B. J., Brahmbhatt,
              S.B. Gandhi, T.P. Bangaru, R.A. and Goyal, R.K. Synthesis and
              quantitative structural activity relationship of antihyperlipaemic
              2-substituted theno [3,2-d]pyrimidin-4(3H) ones. Arz Forsch 46(I)
              : 273-276, 1996.
            </li>
            <li>
              Joshi, S.S., Shah, T.S. and Goyal, R.K. Effects of chronic
              treatment with nitrendipine on streptozotocin-induced diabetic
              rats. Ind. J. Pharm. Sci. 58 : 100-105 1996
            </li>
            <li>
              Desai, J.K., Goyal, R.K. and Parmar, N.S. Dopamine receptor
              subtypes in gastric and duodenal ulcers. Ind J Pharmacol 28 :
              129-142, 1996
            </li>
            <li>
              Goyal, R.K., Bangaru, R.A., Lakkad, L.B., Rao, M.V. Effects of
              chronic treatment with an atenolol and prazosin on
              streptozotocin-induced diabetic rats. Ind J Physiol Pharmacol40 :
              220-224, 1996
            </li>
            <li>
              Sevak, A.R. and Goyal, R.K. Effects of chronic treatment with
              lisinopril on cardiovascular complications in streptozotocin-
              induced diabetic and DOCA hypertensive rats. Pharmac Res Comm34 :
              201-209, 1996
            </li>
            <li>
              Parulekar, A.A. Srinivasan, Hakim, Z.S., Santani, D.D. and Goyal,
              R. K. Spirapril attenuates hyperinsulinaemia and hypertension in
              spontaneously hypertensive rats. Ind J Hyperten 1 : 7-10, 1996
            </li>
            <li>
              Karki R.G. and Goyal R.K. Pharmacoeconomics: A New Concept in
              Pharmacy Eastern Pharmacist 34: 43-45, 1996
            </li>
            <li>
              Mehta A.A and Goyal R.K. Hundred years of hypertension Pharma Plus
              2: 13- 17;1996
            </li>
            <li>
              Mallya, S.R.D. Bhansali, P. Mehta, H.C., Sheth J.J., Dave,K. C.,
              Santani, D.D. and Goyal, R.K. Effect of nifedipine, captopril and
              enalapril on serum digoxin levels in hypertensive patients with
              congestive cardiac failure. Ind J Hyperten 1(2) : 3-8, 1996
            </li>
            <li>
              Indian Consensus Group Indian Consensus for the prevention for the
              prevention of hypertension and coronary Heart Failure. J. Nut
              Environ. Med. 6 : 309-318, 1996
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 31</i>
            </p>

            <li>
              Desai, J.K., Goyal, R.K. and Parmar, N.S. Pathogenesis of peptic
              ulcer diseases and current trends in therapy. Ind J Physiol
              Pharmac, 41 : 3-15, 1997
            </li>
            <li>
              Satia, M. C., Shukla, M. L. Gandhi, T.P. and Goyal, R. K.
              Prevalence of hypertension and comparative evaluation of four
              antihypertensives monotherapy in Indian patients with NIDDM
              hypertensive patients. Ind J Hyperten 2 : 17-27. 1997
            </li>
            <li>
              Hakim, Z.S., Patel, B.K. and Goyal, R.K. Effects of chronic
              treatment with ramipril on streptozotocin -induced diabetic rats.
              Ind J Physiol Pharmacol41 : 353-360, 1997
            </li>
            <li>
              Sharma, N., Mehta, A. A., Santani, D .D. and Goyal ,R. K Evidence
              for alpha2– adrenoceptor agonistic action of minoxidil. J Pharm
              Pharmacol 49 : 935-937, 1997
            </li>
            <li>
              Shishoo, C.J., Gandhi, T.P. Gandhi, T.R., Rathod I.S. and Goyal,
              R. K. Mechanism of action of antihyperlipaemic activity and
              pharmacokinetics of 2-chloromethyl-5,6,7,8,- tetrahydrobenzo-
              (b)thieno[2,3-d]pyrimidine-4(3H)-one Arz Forsch 47(II): 1125-
              29,1997
            </li>
            <li>
              Rodrigues, B. Cam, M.C., Kong, J. Goyal, R.K. and McNeill, J. H.
              Strain differences in susceptibility to streptozotocin – induced
              diabetes : Effects on hypertriglyceridaemia and cardiomyopathy. J
              Cardiovasc Res 34 : 119-205, 1997
            </li>
            <li>
              Karki, R.G, Mehta A.A. and Goyal R.K. Gene therapy in hypertension
              Ind J Pharm Sci 59: 215-219; 1997
            </li>
            <li>
              Parulekar, A., Hakim, Z.S., Santani, D.D. and Goyal, R.K. Effect
              of chronic treatment with spirapril on biochemical parameters in
              streptozotocin diabetic and spontaneously hypertensive rats. Ind J
              Exp Biol 35 : 423-428, 1997
            </li>
            <li>
              Srinivasan, P.S., Hakim, Z.S., Santani, D.D. and Goyal, R.K.
              Effect of chronic treatment with amlodipine in streptozotocin
              diabetic and spontaneously hypertensive rats. Pharmac Res Comm 35
              : 423-428. 1997
            </li>
            <li>
              Damani, R.C., Satia, M.C. and Goyal, R.K. Beneficial effects of
              clonidine in streptozotocin- induced diabetes and DOCA
              hypertensive rats. J Pharm Pharmacol 49 : 1030-1035. 1997
            </li>
            <li>
              Goyal, R.K., Lakkad, N.B., and Rao, M.V. Effects of chronic
              treatment with enalapril on streptozotocin- induced diabetic rats.
              Pharmac Res Comm. 9 : 133-139, 1997
            </li>
            <li>
              Bhatt, S. R. Burande, M.D., Shah, S. M., Mehta, A. A. and Goyal,
              R.K. Effect of chronic treatment with oestrogen and progesterone
              on histamine levels and histamine H1 and H2 receptors in rats and
              guinea pigs. Pharmac Res Comm 9: 113- 122, 1997
            </li>
            <li>
              Mehta A.A and Goyal R.K. Pharmacy in Public Health care Pharma
              Plus 3: 7-10; 1997
            </li>
            <li>
              Mehta A.A. and Goyal R. K. Treatment of Hypertension in twenty
              first century Eastern Pharmacist 40: 51-54; 1997
            </li>
            <li>
              Goyal, R.K., Satia, M.C. Bangaru, R.A. and Gandhi, T.P. Effects of
              long term treatment with enalapril in streptozotocin-induced
              diabetic and DOCA hypertensive rats. J Cardiovasc Pharmac 32(2) :
              317-322, 1998
            </li>
            <li>
              Gokhale, M. S., Shah, D.H., Hakim, Z.S., Santani, D. D. and Goyal,
              R.K. Effects of chronic treatment with amlodipine on insulin
              sensitivity in NIDDM-diabetic rats. Pharmac Res Comm 37 : 455-459,
              1998.
            </li>
            <li>
              Goyal R.K. Molecular biology of insulin resistance and diabetic
              nephropathy. Ind J Hyperten 3 : 27-30, 1998
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 32</i>
            </p>

            <li>
              Goyal, R.K. Vascular endothelium in diabetes mellitus and
              hypertension Ind J Hyperten 3 : 17-19, 1998.
            </li>
            <li>
              Nair R R, Goyal R K Role of Pharmacologist in Indian
              Pharmaceutical Industry Ind J Phar Educ, 32 (3), 1998
            </li>
            <li>
              Desai, J.K., Goyal, R.K. and Parmar, N.S. Characterization of
              dopamine receptor subtypes in experimentally – induced gastric and
              duodenal ulcers J Pharm Pharmacol 51 : 187-192, 1999
            </li>
            <li>
              Mehta, A.A., Patel, S., Santani, D.D. and Goyal, R.K. Effects of
              nifedipine and enalapril on insulin-induced glucose disposal in
              spontaneously hypertensive and diabetic rats. Clin Expt Hyperten
              21 : 51-60, 1999
            </li>
            <li>
              Singh, N.K., and Goyal, R.K. New classes of antihypertensive drugs
              : Therapeutic Potentials Clin Expt Hyperten 21 : 137-144, 1999
            </li>
            <li>
              Goyal, R.K. Hyperinsulinaemia and Insulin Resistance in
              hypertension : Differential effects of antihypertensive agents.
              Clin Expt Hyperten 21 : 167-179, 1999
            </li>
            <li>
              Shah, G.B., Sharma, S, Mehta, A.A. and Goyal, R.K. Ocular
              hypotensive effect of ramiprilat in chronic and acute models of
              glaucoma in rabbits. Ind J Pharmacol 31(2): 110-115, 1999
            </li>
            <li>
              Umrani, D.N. and Goyal, R.K. Pharmacology of Sildenafil Citrate
              Ind J Physiol Pharmacol43 : 160-164; 1999
            </li>
            <li>
              Raghuprasad, M, Pathak U.S. Goyal R.K. Emerging trends in
              antiviral therapy Indian Drugs 36 : 545-555, 1999
            </li>
            <li>
              Goyal R.K. Nair S.S and Dave Changing trends in the
              recommendations for the bioassay of drugs in different
              pharmacopoeia Ind , Pharm Edu 33 : 215-116 1999
            </li>
            <li>
              Madia D K, Yeole P G, Goyal R K New technique for recording
              responses of isolated tissue preparation Ind , Pharm Edu 33(2),
              1999
            </li>
            <li>
              Hakim Z.S. and Goyal R.K. Comparative evaluation of different rat
              models with coexisting diabetes mellitus and hypertension Ind J
              Physiol Pharmacol44 (2) ; 125-135, 2000
            </li>
            <li>
              Shinde U.A Mehta, A.A and Goyal R. K. Nitric oxide : A molecule of
              the millennium Ind J Expt Biol 38 : 201-210, 2000
            </li>
            <li>
              Algarsamy V. Pathak U.S. Goyal R.K. Synthesis and evaluation of
              some novel 2- mercapto-3-(substituted-methylamino)
              quinazolin-4(3H)ones as analgesic, antiinflammatory and
              antibacterial agents. Ind J Pharm Sci 63- 66, 2000
            </li>
            <li>
              Patel A. V. Santani D.D. and Goyal R. K. Antiulcer activity and
              the mechanism of magaldrate in gastric ulceration models of rats.
              Ind J Physiol Pharmacol 44 :350-354, 2000
            </li>
            <li>
              Shah, G.B., Sharma, S, Mehta, A.A. and Goyal, R.K. Ocular
              hypotensive effect of angiotensive-converting enzyme inhibitors in
              acute and chronic models of glaucoma in rabbits. J. Cardiovasc.
              Pharmacol. 36: 169-175, 2000
            </li>
            <li>
              Goyal R. K. and Umrani D.N. Autonomic Pharmacology` Ind J
              Pharmacol 32: S15- S24, 2000
            </li>
            <li>
              Umrani D.N. and Goyal R.K. An overview of pathophysiology and
              treatment of insulin resistance Ind. J. Physiol. Pharmacol. 45 :
              22-36, 2001
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 33</i>
            </p>

            <li>
              Patel B and Goyal R.K Patient opting for self medication have
              greater faith in pharmacist: A pharmacoepidemiological study in
              North Gujarat Ind. J. Hosp Pharm.; 38: 95-97, 2001
            </li>
            <li>
              Shinde U.A. Mehta, A.A. and Goyal R.K. Effects of chronic
              treatment with bis(maltolato)oxovanadium (IV) in rat model of
              non-insulin dependent diabetes Ind J Exp Biol 39: 864-870; 2001
            </li>
            <li>
              Murali B. and Goyal R.K. Effects of chronic treatment with
              losartan on streptozotocin induced diabetic nephropathy Clin.
              Expt. Hyperten 23: 513-520, 2001
            </li>
            <li>
              Murali B. and Goyal R.K. Improvement in insulin sensitivity by
              losartan in non insulin dependent Diabetic rats Pharm. Res 44:
              385-389, 2001
            </li>
            <li>
              Gandhi, P.S. Shinde, U.A. Santani, D.D., Goyal R. K. and Mehta
              A.A. Effect of potassium channel modulators on glucocorticoid
              levels in rats. Adv Pharmacol Toxicol 2; 25-27, 2001
            </li>
            <li>
              Viswakarma S.L. and Goyal R.K. Role of apoptosis I cardiovascular
              disease and diabetes mellitus Ind. J. Pharm Edu 35: 114-118; 2001
            </li>
            <li>
              Murali B. and Goyal R.K. Effects of chronic treatment with
              losartan on insulin on streptozotocin- induced diabetic rats Ind J
              Exp Biol 40: 31-34; 2002
            </li>
            <li>
              Upadhyay U.M. and Goyal R.K. Characteristics of Type 2 diabetic
              patients of Western India Asian J. Diabetol. 4: 41-50; 2002
            </li>
            <li>
              Umrani D.N. and Goyal R.K. Beneficial effects of fenoldopam
              treatment on renal function in streptozotocin-induced diabetic
              rats Clin. Expt.Hyperten. 24: 207-219; 2002
            </li>
            <li>
              Gandhi PS, Patel MP, Shnide UA, Goyal RK and Mehta AA Involvement
              of potassium channels in the release of corticosteroids
              Ind.J.Pharm Sci, 2002; 64: 121-123
            </li>
            <li>
              Shenoy, A.G., and Goyal, R. K. Effects of chronic treatment with
              perindopril on insulin sensitivity and cardiovascular parameters
              in spontaneously hypertensive and streptozotocin-diabetic rats.
              Ind. J. Pharmac 34: 156-164; 2002
            </li>
            <li>
              Murali ,B, Upadhyaya, U. M and Goyal R.K. Effects of chronic
              treatment with Enicostemma littrole in streptozotocin-induced Type
              II diabetes mellitus in rats J. Ethnopharm. 81: 199-204; 2002
            </li>
            <li>
              Shah HD and Goyal RK Novel antiobesity drugs Ind. J. Pharmacol.
              34:372-37, 2002
            </li>
            <li>
              Kulkarni, J.S. Mehta, A.A., Santani, D.D. and Goyal, R.K Effect of
              chronic treatment with cromakalim and glibenclamide in
              alloxan-induced diabetic rats Pharmac Res Comm46: 101-105; 2002
            </li>
            <li>
              Goyal R.K. 5-HT2A receptors:A potential target for the treatment
              of diabetes mellitus and associated cardiovascular complications
              Global Outstanding Review, 4 ;25-27; 2002 (Japanese)
            </li>
            <li>
              Umrani DN and Goyal RK Fenoldopam treatment improves peripheral
              insulin sensitivity and Renal function in STZ-induced Type 2
              diabetic rats Clin. Exp. Hyperten. 25: 221-233; 2003
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 34</i>
            </p>

            <li>
              Goyal RK, Umrani DN Bodiwala DN and Dhalla NS Usefulness of 5HT2A
              receptors agonists for treatment of Cardiovascular complications
              In diabetes In.Hypertension Diabetes and Atherosclerosis Eds. G.N.
              Pierce, M.Nagano, P.&amp; NS Dhalla. Zahradka, Kluwar Academic
              Publishers, Boston, 2003; 317-326
            </li>
            <li>
              Umrani DN, Bodiwala DN and Goyal RK Effect of Sarpogelate on
              altered STZ diabetes induced Cardio-vascular responses to 5HT Mol.
              Cell. Biochem. 249; 53-57, 2003
            </li>
            <li>
              Murali B, Umrani DN and Goyal RK Effect of chronic treatment with
              losartan on strepzotocin-induced renal dysfunction Mol.Cell.
              Biochem. 249; 85-90, 2003:
            </li>
            <li>
              Natticardan T, Rastogi S, Chohan PK, Goyal RK, Dhalla NS
              Regulation of cardiac function in diabetes In: Atherosclerosis
              Hypertension and Diabetes Kluwer Academic Publishers, Boston;
              353-371, 2003
            </li>
            <li>
              Goyal RK and Dhalla NS Pharmacotherapeutics of diabetic
              cardiomyopathy
            </li>
            <li>
              In: Frontiers in Cardiovascular Sciences, Ed. A Chocklingam, Hi
              Berkovitz, &amp; P. K. Singal Kluwer Academic Publishers, Boston;
              307-321; 2003
            </li>
            <li>
              Goyal RK and Patel NJ Pharmacovigilance for Respiratory Disorders
              in North Gujarat
            </li>
            <li>
              Proceedings of the Annual Conference of the Society of
              Pharma-covigilance (India) March 2003
            </li>
            <li>
              Shinde U.A. and Goyal R.K. Effect of chromium picolanate on
              histopathological alterations in STZ and neonatal STZ diabetic
              rats J. Cell. Mol. Med. 7(3): 322-9, 2003
            </li>
            <li>
              Vishwakarma SL, Rajani M, Goyal RK Comparative anti diabetic
              activity of different fractions of Enicostema littorale Blume in
              Streptozotocin induced NIDDM rats Orient.Pharm.Exp.Med.3(4):
              196-204, 2003
            </li>
            <li>
              Shah KJ, Parikh KH, Chag MC, Shah UG, Baxi HA, Chandarana AH, Naik
              AM, Goyal RK. Prevalence of hyperhomocystenemia in western India
              population with or without coronary artery disease Ind. J.
              Cardiol. 6: 54-6, 2003
            </li>
            <li>
              Upadyay UM, Ravishankar MN, Padh H, Goyal RK HPTLC fingerprint
              profile of an aqueous extract of Enicostemma littorale J. Med.
              Arom. Plant Sci. 25: 675-81, 2003
            </li>
            <li>
              Patel NJ, Patel JL, Patel DK, Patel JH Epidemiological Studies of
              Diabetes in the North Gujarat Adv. Bio. Sci. 2:36-38;2003
            </li>
            <li>
              Akhani SP, Vishwakarma S, Goyal RK Antidiabetic activity of
              Zingiber officinalei in streptozotocin induced type I diabetic
              rats J. Pharm. Pharmacol. 56(1): 101-5, 2004
            </li>
            <li>
              Saini HK, Takeda N, Goyal RK, Kumamoto H, Arneja AS, Dhalla NS
              Therapeutic potentials of sarpogrelate in cardiovascular disease
              Cardiovasc. Drug Rev. 22(1):27- 54, 2004
            </li>
            <li>
              Shah HD, Goyal RK Glycoprotein IIb/IIIa receptor and its
              inhibition: A platelet directed therapeutic strategy Ind. J. P
              harmac. 36 (3): 133-139: 2004
            </li>
            <li>
              Shinde UA, Sharma G, Xu YJ, Dhalla NS, Goyal RK Antidiabetic
              activity and mechanism of action of chromium chloride Expt. Clin.
              Endo. Dia. 112: 1-6; 2004
            </li>
            <li>
              Upadhyaya U.M., and Goyal RK Efficacy of Enicostemma littorale in
              type II diabetic patients Phytother. Res 18: 233-235; 2004
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 35</i>
            </p>

            <li>
              Shinde UA, Sharma G, Goyal RK In vitro insulin mimicking action of
              Bis (maltolato) oxovanadium (IV) Ind J Pharma Sci 66(4); 392-395;
              2004
            </li>
            <li>
              Shah HD, Goyal RK Angiogenesis: A new approach to future therapy
              Ind.J.Pharm.Edu. 38(2) ;98-107: 2004
            </li>
            <li>
              Parmar S. and Goyal R.K. Antiulcer activity of levodopa J. Sci.
              Pharm. 5(2) 75-78; 2004
            </li>
            <li>
              Vishwakarma S.L. and Goyal R.K. A sensitive HPTLC method for
              estimation of swertiamarin in Enicostemma littorale Blume Swertia
              chirata (Wall) Clarke, and in formulations containing E.
              littorale. J. Planar Chromat. 17: 128-132; 2004
            </li>
            <li>
              Shinde UA, Sharma G, Xu Y J, Dhalla N S, Goyal R K Insulin
              sensitizing action of chromium picolinate in various experimental
              models of diabetes mellitus J. Trace Ele. Med. Biol. 18 (1):
              23-32; 2004
            </li>
            <li>
              Patel PM, Patel N.J. and Goyal RK Pharmacoepidemiology of
              polyherbal antidiabetics in Gujarat region: Itsa implications on
              the disease Anarta 10: 239-247; 2004
            </li>
            <li>
              Patel NJ, Patel JL, and Goyal RK Inhaled Insulin: A New Hope for
              Diabetic Patients Pharma Times. 36: 9-12; 2004
            </li>
            <li>
              Vishwakarma SL, and Goyal RK Hepatoprotective activity of
              Enicostemma littorale in CCl4 – induced liver damage J. Nat. Rem.
              4/2: 120-126; 2004
            </li>
            <li>
              Shah KJ, Chag MC, Parikh KH and Goyal RK Homocysteine and Coronary
              Artery Disease Ind J Pharma Sci 66(5): 595-601; 2004
            </li>
            <li>
              Paranjape AN, Santani DD, Goyal RK and Mehta AA Involvement of
              Potassium Channels in the Release of Various Hormones
              Ind.J.Pharma.Sci. (5): 691-693; 2004
            </li>
            <li>
              Patel NJ and Goyal RK Community based Survey on Awareness for
              Respiratory Disorders in North Gujarat Ind. J. Hos. Pharma.
              152-155; 2004
            </li>
            <li>
              Patel NJ and Goyal RK Pharmacovigilance for Respiratory Disorders
              in North Gujarat In: Pharmacovigilance: An Update. A Ray and K
              Gulati V P Chest Institute Delhi pp. 190-202; 2004
            </li>
            <li>
              Vishwakarma SL, Rajani M, Bagul MS and Goyal RK A Method for the
              Isolation of Swertiamarin from Enicostemma littorale Pharma. Bio.
              42(6): 400-403; 2004 167. Shah KJ, Parikh KH, Goyal RK Bosentan in
              primary pulmonary hypertension Ind. J. Pharmacol 36(2): 108; 2004
            </li>
            <li>
              Patel N.J., Goyal R.K. and Patel J.L. Investigation on the
              Prescription Trends for Respiratory Disorders and the Rational for
              the use of Anti-Microbials in North Gujarat] Bio. Sci. 3: 59-61;
              2004
            </li>
            <li>
              Goyal R.K. Techniques in Molecular Pharmacology In New
              perspectives in Drug Development. Eds. A. Ray, V. P. Chest
              Institute Delhi 6-10: 2004
            </li>
            <li>
              Mehta KN, Parikh KH, Chang MC, Shah UG, Baxi HA, Chandarana AH,
              Naik AN, Goyal RK Effect of folate treatment on homocystenemia in
              cardiac patients : A prospective study Ind.J.Pharmacol.37(1):
              37:13-17; 2005
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 36</i>
            </p>

            <li>
              Baheti JR, Kumar V, Shah GB and Goyal RK Free radical Scavenging
              activity of aqueous extract of Rhus succedanea galls J. Nat. Rem
              5(1): 15-18; 2005
            </li>
            <li>
              Shah HD, Parikh K and Goyal RK Recent Trends in Antiplatelet
              Therapy for Acute Coronary Syndromes: Glycoprotein IIb/ IIIa
              receptor antagonists In
            </li>

            <p>
              Pharmacotherapeutics of Heart Failure Gupta S.K., Singal P.K.
              Agrawal S.S. Eds. Anamaya Publishers NewDelhi pp 102-122; 2005
            </p>

            <li>
              Shah H. D. and Goyal R. K. Nesiritide: A recombinant human BNP as
              a therapy for decompensated heart failure Ind. J. Pharm. 37(3):
              196-197; 2005
            </li>
            <li>
              Goyal R. K. Investigation of Cellular and Molecular Mechanisms for
              Anti-diabetic Drugs with Special Reference to Unani and Ayurvedic
              Herbal Medicines In Traditional System of Medicine Eds: M.Z.Abdin
              and Y.P. Abrol Narosa Publishing House, New Delhi 279-292; 2005
            </li>
            <li>
              Goyal R.K. Design of Practical in Pharmacology in the light of
              CPCSEA and New Drug Development In Current Concepts in Drug Design
              and Formulation Development, Eds: D. K. Dhake Dept of
              Pharmaceutical Sci. Guru Jambheshwar University Hisar, 2005
            </li>
            <li>
              Guo Xiaobing, Saini H.K., Wang J. Gupta S.K. Goyal R.K. and Dhalla
              N.S. Prevention of remodeling in congestive heart failure due to
              myocardial infarction by blockade of the rennin-angiotensin system
              Expert Rev. Cardiovasc..Ther. 3(4): 717- 732; 2005
            </li>
            <li>
              Kadnur SK and Goyal RK Beneficial effects of Zingiber officinale
              Roscoe on fructose induced hyperlipidemia and hyperinsulinemia in
              rats Ind. J. Exp. Biol. 43:1161-1164, 2005
            </li>
            <li>
              Kadnur SK and Goyal RK Comparative Antidiabetic Activity of
              Methanol and Ethyl Acetate Extracts of Zingiber officinale Roscoe
              Ind. J. Pharm. Sci. 67(4):453-457, 2005
            </li>
            <li>
              Kadnur SK and Goyal RK Comparative antidiabetic activity of
              different fractions of methanolic extract of Zingiber officinale
              Roscoe in streptozotocin induced NIDDM rats. Oriental Pharm.
              Exp.Med. 5(3):1-8; 2005
            </li>
            <li>
              Jani AJ, Goyal RK and Mehta AA A new insight into the use of
              antihypertensive in glaucoma Orient.Pharm.Exp.Med. 5(3): 178-187;
              2005
            </li>
            <li>
              Patel PM, Patel NM and Goyal RK Quality control of Herbal and
              Herbo-Mineral Products: An Emerging Trend Pharma Times 37(11):
              71-79; 2005
            </li>
            <li>
              Patel PM, Patel NM and Goyal RK Development of Quality control
              methods for polyherbal Antidiabetic formulations Ind Pharmacist
              41(4):93-97;2005
            </li>
            <li>
              Vimal Kumar, Shah Tejal, Parmar NS, Shah GB and Goyal RK
              Antimicrobial activity of peganum Harmala Ind J Nat Prod
              21(2):24-26;2005
            </li>
            <li>
              Patel PM, Patel NM, Goyal RK Quality Control Methods for
              Polyherbal Antidiabetic Formulations Adv. Bio, Sci. 4:39-42; 2005
            </li>
            <li>
              Akhani, S.P., Vishwakarma, S.L. Goyal, R.K. Antidiabetic activity
              of Zingiber officinale roscoe in streptozotocin-induced
              non-insulin dependent diabetic rats In. J. of Pharmaceutical
              Sciences, 67(5)
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 37</i>
            </p>

            <li>
              Jani AJ, Goyal RK and Mehta AA Effect of calcium channel blockers
              on intraocular pressure in rabbits Ira. J. Pharmacol. Therap. 4:
              95-99; 2005
            </li>
            <li>
              Modi K, Santani DD, Goyal RK, Bhatt PA Effect of coenzyme Q10 on
              catalase activity and other antioxidant parameters in
              streptozotocin-induced diabetic rats. Biol. Trace. Elem. Res.
              109(1): 25-34;2006
            </li>
            <li>
              Dhalla, N.S., Dent, M.R., Tappia, P.S., Sethi, R., Barta, J.,
              Goyal, R.K. Subcellular remodeling as a viable target for the
              treatment of congestive heart failure Cardio. Pharma. Ther.
              11(1):31-45;2006
            </li>
            <li>
              Goyal RK, Kadnur SV Beneficial effects of Zingiber officinale on
              goldthioglucose induced obesity Fitoterapia 77(3):160-163, 2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. Development of HPTLC method
              for estimation of charantin in herbal formulations Pharmacog. Mag.
              2(8):224-226;2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. Estimation of Swertiamarin
              in marketed polyherbal antidiabetic formulations Planta Indica
              2(1):18-20;2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. Holistic classification of
              herbal Antidiabetics: A review Pharma Times 38(5):19-25;2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. Evaluation of marketed
              polyherbal antidiabetic formulation using Biomarker Charantin
              Pharma. Rev. 4(22): 113-115;2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. Preliminary phytochemical
              studies of some indigenous antidiabetic plants and its marketed
              formulation Anartha 11:131-144;2006
            </li>
            <li>
              Patel, P.M., Patel, N.M., Goyal, R.K. A high performance thin
              layer chromatography determination of Swertiamarin in polyherbal
              formulations J Pharma. Res. 5(4):122- 124; 2006
            </li>
            <li>
              Jani A. J, Goyal R.K, Mehta A.A .A clinical study on correlation
              between systemic blood pressure and Glaucoma Adv. Pharmacol.
              Toxicol. 7 (1): 75-81;2006
            </li>
            <li>
              Modi K, Viswakarma S, Goyal RK, Bhatt PA Beneficial effects of
              coenzyme Q10 in streptozotocin-induced diabetic rats.
              Ira.J.Pharmacol.Therap. 5: 61-65; 2006
            </li>
            <li>
              Patel JL, GoyalRK, Bhatt PA Effect of L-carnitine on lipid
              metabolism and cardiac performance in experimental diabetes Pharma
              Buzz 25-29; 2006
            </li>
            <li>
              Sharma S, Pathak DP, Goyal RK Spectrophotometric deetermination of
              captopril Antiseptic 103: 649-650; 2006
            </li>
            <li>
              Patel JL, Goyal RK Diabetes mellitus: Current status and common
              risk factors Trendz 4(4):11-17; 2006
            </li>
            <li>
              Patel JL, Patel LD, Goyal RK Epidemiology and current status of
              major cardiovascular diseases Trendz 4(5):11-17; 2006
            </li>
            <li>
              ShahK, Parikh K, Chag M, ShahU, Baxi H, Chandarana A, Naik A,
              Goyal RK A vigilance study of distal protection device used during
              percutaneous coronary interventions Intl. J Risk Safety Med. 18:
              205-212; 2006
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 38</i>
            </p>

            <li>
              Gandhi P.S., Goyal, R.K. Gender differences in prognosis of
              Systolic Heart failure in Patients undergoing bypass surgery Asian
              J. Clin Cardio 9(2):6-13; 2006
            </li>
            <li>
              Sharma S., Pathak D. P., Goyal R. K. Studies on Corneal Permeation
              and Oculo Hypotensive effect of Captopril in Glaucoma Current
              Pharma Res. J. 1(3);43-49:2006
            </li>
            <li>
              Patel J.L., Patel L.D., Goyal R.K. Artificial neural network: A
              novel tool for new drug discovery Pharma Times 38(8):15-20; 2006
            </li>
            <li>
              Jain A. R. Mallaya BS, Gupta V.M.Shah D.S., Trivedi B.R., Shastri
              N.A., Mehta C.B., Jain K.A., Bhavsar N.S., Naik A. M., Shah U.J.
              Gandhi P.S., Goyal, R.K. Beneficial effects of Endoventricular
              Circular patch plasty in Patients with left ventricular Systolic
              Dysfunction and left ventricular Dyskinetic apical Segment. Ind.
              J. Thoracic and Cardio Surgery 23:16-24; 2006
            </li>
            <li>
              Patel P.M. Patel N. M. and Goyal R.K. Quality control of Herbal
              Drugs Ind. Pharmacist. 26-30; 2006
            </li>
            <li>
              205. Baheti, J.R. Goyal R.K. Shah, G B Hepatoprotective active of{" "}
              <i>Hemidesmus indicus </i>R. Br in Rats Ind. J. Expt.Biol. 44(5);
              399-402:2006
            </li>
            <li>
              Sharma S., Pathak D. P., Goyal R. K. Studies on Corneal Permeation
              and Oculo Hypotensive effect of Benazepril in Chronic and Acute
              Models of Glaucoma Irn J.Pharm. Thera. 5(2);1-5:2006
            </li>
            <li>
              Goyal B.R, Agrawal B.B, Mehta A.A., Goyal R.K, Pharmacological
              classification of herbal anti-asthmatics Orient.Pharm.Exp.Med.
              7(1);11-25: 2007
            </li>
            <li>
              Goyal, R.K., Mehta K., Gandhi, P.S., Chag M.C., Parikh, K.H., Jain
              A. Drug Usage during Percutanceous Coronary Interventions in
              patients with Acute Coronary Syndrome and ventricular dysfunction
              In: Current Trends in Pharmacology In: Publication House Ray, A.
              Gulati, K. International Publication Home, Delhi 255- 283A, 2007
            </li>
            <li>
              Patel JL, Goyal RK. Application of Artificial Neural Networks in
              Medicine. Cur. Clin. Pharmac. 2:217-226; 2007
            </li>
            <li>
              Gandhi P.S., Goyal, R.K., Jain A.R., Mallaya B.S., Gupta V.M.,
              Shah D.S., Trivedi B.R., Shastri N.A., Mehta C.B.m Jain K.A.,
              Bhavsar N.S., Shah U. J., Beneficial effects of Carvedilol, as a
              Concomitant Therapy to Angiotensin converting Enzyme inhibitor in
              Patients with Ischemic left ventricular Systolic Dysfunction Can.
              J. Physiol. Pharmacol. 85(2):193-199; 2007
            </li>
            <li>
              Shah HD, Parikh KH, Chag MC, ShahUG, Baxi HA, Chandarana AH, Naik
              AM, Shah JN, Shah KJ, Iyer, S. and Goyal RK Beneficial Effects of
              Addition of Fenofibrate to Statin Therapy in Patients with Acute
              Coronary Syndrome after Percutaneous Coronary Interventions Exp.
              Clin. Cardiol., 12: 91-6: 2007
            </li>
            <li>
              Parikh K.H, Chag M, ShahK, ShahU, Baxi H, Chandarana A, Naik A,
              Shah HD, Goyal RK Intracoronary boluses of adenosine and sodium
              nitroprusside combination reverses slow/no-reflow during
              angioplasty: A clinical scenario of ischemia preconditioning Can.
              J. Physiol. Pharmacol. 85(4<i>); 476-82:</i>2007
            </li>
            <li>
              Bhatt P., Makwana D. Santani D., Goyal R.K Comparative
              effectiveness of carvedilol and propranolol on glycemic control
              and insulin resistance associated with L. thyroxin-induced
              hyperthyroidism-an experimental study Can. J. Physiol. Pharmacol.
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 39</i>
            </p>
            <p>85(5):514-20:2007</p>

            <li>
              Mehta A., Shah, U, Parikh K, Chag, M, Baxi, H, Chandarana, A,
              Naik, A, Naik A, Mehta K,, Goyal RK Effect of Pioglitazone and its
              combination with Statins in Coronary Artery Disease patients with
              hyperinsulinemia. Can. J Physiol. Pharmacol. 85(6):628-33:2007
            </li>
            <li>
              Bhadada SV, Goyal RK Comparative evaluation of atenolol and
              metoprolol on cardiovascular complications associated with
              streptozotocin-induced diabetic rats Can. J Physiol. Pharmacol. 2;
              85(8): 831-6: 2007
            </li>
            <li>
              Modi, K.P., Vishwakarma, S.L., Goyal, R.K., Bhatt, P.A.
              Hypolipidemic and antioxidant activity of Coenzyme Q10 in high
              cholesterol fed diet rats Current Pharma. Res. J. 1: 32-36; 2007
            </li>
            <li>
              Patel, P., Patel K., Patel N., Goyal R. A HPTLC method for
              quantitative estimation of swetiamarin in marketed polyherbal
              antidiabetic formulations Ind.J of Pharmaceutical Sciences, 69 (3)
              446-482; 2007
            </li>
            <li>
              Modi, K.P., Vishwakarma, S.L., Goyal, R.K., Bhatt, P.A. Effects of
              Coenzyme Q10 on lipid levels and antioxidant defenses in rats with
              fructose induced hyperlipidemia and hyperinsulinaemia Internet J.
              Pharmacol 3; 1-7; 2007
            </li>
            <li>
              Goyal, B R, Agrawal, B B., Goyal, R K and Mehta, A A.
              Phyto-pharmacology of Moringa oleifera Lam. – An overview Nat.
              Prod. Rad. 6(4):347-353;2007
            </li>
            <li>
              Goyal R K, Anita A. Mehta , Shailaja Mahajan Classification of
              Herbal Antidiabetics Based on Mechanism of Action and Chemical
              Constituents In: Recent progress in Medicinal plants 20: 3,
              65-110; 2007
            </li>
            <li>
              Desai T.R., Goyal R.K., and Solanki J.K. Phytochemical study of
              Wheatgrass – A comparative evaluation with respect to variation in
              fertilizers used Adv. Bio. Sci. 6:19- 23;2007
            </li>
            <li>
              Patel P.M., Patel K.N., Patel M.N., and Goyal R.K. Development and
              validation of HPTLC method for estimation of charantin in herbal
              formulations Indian Drugs44(8):636-39; 2007
            </li>
            <li>
              Goyal B. R, Agrawal B.B, Goyal R.K, Mehta A.A. Phyto-pharmacology
              of <i>Achyranthes aspera </i>: A Review. Pharmacog. Rev. 2007;
              1(1): 153-160.
            </li>
            <li>
              Sharma S., Goyal, R. K., Pathak, D. P Corneal permeation and
              ocular hypotensive effect of lisinopril chronic and acute models
              of glaucoma Agric. Biol. Res 24(2):102- 110;2008
            </li>
            <li>
              Goyal B. R., Goyal RK., Mehta A. A. Beneficial role of{" "}
              <i>Achyranthes aspera Linn. </i>in Toluene-Di-Isocyanate induced
              occupational asthma in rats. Open Nat. Products J. 1:44-49{" "}
              <i>;</i>2008
            </li>
            <li>
              Goyal, R K. Parikh H. Shah, H A. An overview on the Management of
              Chronic refractory Angina Pectoris In: Recent Advances in
              Cardiovascular Sciences ed. Agrawal S. S. DIPSAR, Delhi, 135-53,
              2008
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 40</i>
            </p>

            <li>
              Modi K.P., Patel N.M. Goyal, R. K. Estimation of L-Dopa from
              Mucuna pruriens Linn and Formulations Containing M. pruriens by
              HPTLC method Chem. Pharm. Bull. 56(3): 357-59; 2008
            </li>
            <li>
              Parikh, K H, Shah HD, Chag M C, Shah U G, Baxi H A, Chandarana A
              H, Naik A M, Goyal R K Efficacy of PercuSurge in acute myocardial
              infarction patients undergoing revascularization with a special
              emphasis on procedural time Indian Heart J.,60 101- 109; 2008
            </li>
            <li>
              Goyal, B.R., Mesariya P., Goyal R.K., Mehta A.A. Effect of
              telmisartan on cardiovascular complications associated with
              streptozottocin diabetic rats Mole. Cell. Biochem. 314 : 124-131;
              2008
            </li>
            <li>
              Patel J.L., Goyal RK, Bhatt P.A. Beneficial effects of
              levo-carnitine on lipid metabolism and cardiac function in
              neonatal streptozotocin rat model of diabetes Intern J. Diab.
              Met., 16: 29-34; 2008
            </li>
            <li>
              Modi, K.P., Patel, N.M., Goyal R.K. Protective effects of aqueous
              extract of Mucuna pruriens Linn. (DC) seed against
              gentamicin-induced nephrotoxicity and oxidative stress in rats
              Iran J. Pharmac. Ther., 7: 131-135-; 2008
            </li>
            <li>
              Yan-Jun Xu, Tappia PS, Goyal RK, Dhalla NS Mechanisms of the
              Iysophos-phatidic acid-induced increase in [Ca2+]i in skeletal
              muscle cells J. Cell. Mol. Med. 12,: 942- 954; 2008
            </li>
            <li>
              Goyal, B.R., Goyal, R. K. Mehta, A.A. Investigation into the
              mechanism of anti asthmatic action of <i>Lepidium sativum </i>
              Orient.Pharm.Exp.Med. 8(3) 286-294, 2008
            </li>
            <li>
              Desai, TR, Solanki JK, Goyal RK Pharmacognostic Characterization
              of <i>Triticum Aestivum </i>(Wheat Species) Adv. Bio. Sci.
              7:13-16;: 2008
            </li>
            <li>
              Desai, TR, Solanki JK, Goyal RK Pharmacognostic Characterization
              of <i>Triticum Aestivum </i>(Wheat Species) Planta Indica 4(3),
              2008
            </li>
            <li>
              Desai, TR, Solanki JK, Buch P, and Goyal RK{" "}
              <i>Triticum Aestivum </i>(Wheatgrass) formulation: An alternate
              treatment for the patients with Thalassemia
            </li>

            <p>Orient.Pharm.Exp.Med. 7: 466-476, 2008</p>

            <li>
              Datusalia AK, Kalra P, Narasimhan B, Sharma S and Goyal RK
              Anxiolytic and Antiseizure Effects of <i>Sida tiagii </i>Bhandri
              J. Health Sci, 54: 544-550; 2008
            </li>
            <li>
              Shah AR and Goyal RK Current Status of the Regulation for Medical
              Devices Ind. J. Pharm. Sci. 70:695-700; 2008
            </li>
            <li>
              Patel SK, Goyal RK, Anand IS, Shah JS, Patel HU, Patel CN Glucagon
              like peptide 1: A new therapeutic target for diabetes mellitus Ind
              J Pharmacol 38: 23104; 2006
            </li>
            <li>
              Parikh, K H, Shah HD, Chag M C, Shah U G, Baxi H A, Chandarana A
              H, Naik A M, Goyal R K Revascularization in acute myocardial
              infarction using Percusurge in distal protection Acroym:RAPID
              World Heart J. 1:35-47; 2008
            </li>
            <li>
              Shah HD, Parikh KH, Chag MC, ShahUG, Baxi HA, Chandarana AH, Naik
              AM, Shah JN, Shah KJ, Iyer, S. and Goyal RK Beneficial Effects of
              Addition of Fenofibrate to Statin Therapy in Patients with Acute
              Coronary Syndrome after Percutaneous Coronary Interventions World
              Heart J. 1:69-77; 2008
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 41</i>
            </p>

            <li>
              Vaidya HB, Goyal RK Glucagon Like Peptides-1 Modulators as Newer
              Target for Diabetes Current Drug Targets 9, 911-920; 2008
            </li>
            <li>
              Chotai NP, Patel BG, Sarvaiya NP, Megha RV, Goyal RK and Lakhani
              JD Estimation and Comparative Study of Insulin Levels in Human
              Subjects Ind. Pharmacist. 8, 79, 2009
            </li>
            <li>
              Patel SS, Shah RS &amp; Goyal RK Antihyperglycemic,
              antihyperlipidemic and antioxidant effects of Dihar, a polyherbal
              ayurvedic formulation in streptozotocin induced diabetic rats Ind.
              J. Exper. Biol. 47, 2009
            </li>
            <li>
              Mansuri MM, Goyal BR, Upadhyay UM, Sheth J and Goyal RK Effect of
              long term treatment of aqueous extract of{" "}
              <i>Enicostemma littorale </i>in Type 2 diabetic patients
              Orient.Pharm.Exp.Med. 9(1): 39-48, 2009
            </li>
            <li>
              Vaidya HB, Mandapati R, Vasudevan S, Padh H, Goyal RK
              Swertiamarin: A lead from <i>Enicostemma littorale </i>Blume for
              anti-hyperlipidaemic effect Eur. J. Pharmacol. 617, 108-112, 2009
            </li>
            <li>
              Goyal RK, Vaidya HB Exploring Role of 5-Hydroxytryptamine in
              Diabetes Mellitus and Cardiovascular Complications Current R &amp;
              D Highlights Jan-Mar 2009
            </li>
            <li>
              Vaidya HB, Mandapati R, Vasudevan S, Padh H, Goyal RK
              Antihyperlipidaemic activity of swertiamarin, a secoiridoid
              glycoside in poloxamer-407 induced hyperlipidaemic rats J. Nat.
              Med. 63: 437-442, 2009
            </li>
            <li>
              Goyal B.R., Solanki N. Goyal,R.K.. Mehta A.A Investigation Into
              the Cardiac Effects of Spironolactone in the Experimental Model of
              Type 1 Diabetes J. Cardiovasc. Pharmacol. 54: 502-509, 2009
            </li>
            <li>
              Mehta A. A., Mahajan S. G., Goyal B. R., Goyal R. K.
              Classification of herbal anti asthmatics based on mechanism of
              action and chemical constituents. Phytopharmacology and
              Therapeutic values. In: Recent Progress in Medicinal Plants eds.
              Singh V. K., Govil J. N. 2009: 281-314
            </li>
            <li>
              Goyal B. R., Goyal R. K., Mehta A. A. Investigation into the
              mechanism of anti asthmatic action of{" "}
              <i>Moringa oleifera J. Dietary Suppl. </i>6: 313-327; 2009
            </li>
            <li>
              Mehta T.Y., Prajapati L, Mittal B. Joshi C G, Sheth J J, Patel D
              B, Dave D M, Goyal R K Association of HLA-B* 1502 allele and
              Carbamazepine induced Stevens-Johnson Syndrome among Indians
              Indian J Dermatol Venerol Leprol 75(6) 579-582, 2009
            </li>
            <li>
              Shah J., Goyal RK Pharmaco-epidemiological study of learning and
              memory enhancing medicines: a comparison between herbal and
              allopathic formulations Res. J. Pharm. Thera. 3(1): 433-439, 2010
            </li>
            <li>
              Vaidya HB., Goyal R K Exploring newer target sodium glucose
              transporter 2 for the treatment of diabetes mellitus Mini Reviews
              in Medicinal Chemistry 10 (10) pp 905- 913 2010
            </li>
            <li>
              Vishwakarma SL, Sonawane RD, Rajani M, Goyal RK Evaluation of
              effect of aqueous extract of <i>Enicostemma littorale </i>Blume in
              streptozotocin induced type 1diabetic rats Ind. J. Exper. Biol.
              48: 26-30, 2010
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 42</i>
            </p>

            <li>
              Goyal RK, Shah VN, Saboo BD, Pathak SR, Shah NN,Gohel MC,Raval PB,
              Patel SSP Prevalence overweight and obeisity in Indian aldolescent
              school going children : Its relationship with socioeconomic status
              and associated lifestyle factors JAPI, 58:151-58, 2010
            </li>
            <li>
              Sonawane RD, Vishwakarma SL, Lakshmi S.,Rajani M, Padh H. Goyal RK
              Amelioration of STZ- induced type 1 diabetic nephropathy by
              aqueous extract of <i>Enicostemma littorale </i>Blume and
              swertiamarin in rats Mol Cell Biochem 340:1-6, 2010
            </li>
            <li>
              Saini-Chohan, H K Goyal, R K Goyal, Dhalla N S Involvement of
              sarcoplasmic reticulum in changing Intracellular calcium due to
              NA+/K+-ATPase inhibition in cardiomyocytes Can. J. Physiol.
              Pharmac., 88(7), 702-715; 2010
            </li>
            <li>
              Mehta A, Iyer L., Parmar S., Shah G, Goyal R K Oculohypotensive
              effect of perindopril in acute and chronic models of glaucoma in
              rabbits Can. J. Physiol. Pharmac, 88(5), 595-600, 2010
            </li>
            <li>
              Shah JS, Goyal RK Usagae trends for memory and vitality-enhancing
              medicines: A pharmacoepideiological study involving pharmacists of
              the Gujarat region Int. J Ayur Res 1:138-42; 2010
            </li>
            <li>
              Shah JS, Goyal RK Comparative cliniucal evaluation of herbal
              formulation with multivitamin formulation for learning and memory
              enhancement Asia J Pharm Clin Res 3: 69-75; 2010
            </li>
            <li>
              Tirgar PR, Shah KV, Patel VP, Desai TR, Gotyal RK Investigation
              into mechanism of action of anti-diabetic activity of{" "}
              <i>Emblica officinalis </i>on streptozotocin induced type 1
              diabetic rats Res J Pharm Biol Chem Sci 1: 672-82; 2010
            </li>
            <li>
              Goyal RK, Elimban V, Xu YJ, Kumamoto, H, Takeda, N, Dhalla NS.
              Mechanism of Sarpogrelate Action in Improving Cardiac Function in
              Diabetes J.Cardiovasc.Pharmc. Ther. 16,380-387; 2011
            </li>
            <li>
              Goyal B. R., Parmar K, Goyal R. K., Mehta A. A. Beneficial role of
              telmisartan on cardiovascular complications associated with
              STZ-induced type-2 diabetic rats. <i>Pharmaco. Reports </i>63(4);
              2011
            </li>
            <li>
              Bhadada S.V., Patel B.M., Mehta A.A., Goyal R.K. β3 Receptors:
              Role in cardiometabolic disorders.{" "}
              <i>Ther Adv Endocrin. Metabol,</i>. 2(2): 65- ;2011
            </li>
            <li>
              Baheti JR, Goyal RK Evaluation of hepatoprotective effect of{" "}
              <i>Ficus Bengalis </i>Int. J Res Pharm Sci.2:522-525; 2011
            </li>
            <li>
              Patel SS, Goyal RK, Cardioprotective of gallic acid in diabetes
              induced myocardial dysfunction in rats. Pharmacog Res. 3(4): 239;
              2011
            </li>
            <li>
              Shah JS, Goyal RK Investigation of neuropsychological effects of a
              polyherbal formulation on the learning and memory process in rats
              J Young Pharmacists 3: 119- 124: 2011
            </li>
            <li>
              Patel SS, Goyal RK, Prevention of diabetes-induced myocardial
              dysfunction in rats using the juice of the{" "}
              <i>Emblica officinale </i>fruits.Expt.Clin Cardiol 16(3): 87;
              2011.
            </li>
            <li>
              Rastogi SS, GoyalRK, Yeolekar ME etal Prevention of cardiovascular
              disease and diabetes mellitus in low and middle income countries
              Open Nutraceutical J 4:97-106; 2011
            </li>

            <p>
              Prof. Ramesh Goyal | Biodata <i>Page 43</i>
            </p>

            <li>
              Vaidya H, Prajapati A, Rajni M,Padh H, Goyal RK Beneficial effects
              of swertiamarin on dyslipidaemia in streptozotocin induced type 2
              diabetic rats Phytother. Res. 26(8): 1259-61 ;2012
            </li>
            <li>
              Vaidya HB, Giri S, Jain M, Goyal RK. Decrease in serum matrix
              mettaloproteinase-9 and matrix mettaloproteinase-3 levels in
              Zucker fa/fa obese rats after treatment with swertiamarin Expt
              Clin Cardiol 17: 2012
            </li>
            <li>
              Brahmkshatriya PP, Mehta AA, Saboo BD, Goyal RK Characteristics
              and prevalence of Latent autoimmune diabetes in adults (LADA).
              International Scholarly Research Network (ISRN) Pharmacology Doi.
              No. 10.5402, USA Feb. 2012
            </li>
            <li>
              Vaidya HB, Goyal RK, Cheema SK Antidiabetic activity of
              swertiamarin is due to an active metabolite, gentianine, that
              upregulates PPAR-γ gene expression in 3T3-L1 cells Phytother.
              Res., 27 (4): 624-7; 2013
            </li>

            <li>
              Patel K, Patel T Mehta, Gandhi TR, Goyal RK. Effect of fresh juice
              of <i>Brassica oleacea </i>Var. Capitataon isolated precontracted
              rat uterine horns. Hygeia Journal of Drugs Medicines 4:21-26 2012.
            </li>
            <li>
              Solanki RP, Goyal RK. Anticancer activity of acetone extract of{" "}
              <i>quercus infectoria </i>olivier fagaceae in 1,2
              dimethylhydrazine induced colon cancer. Int J Cancer Res; 2 1-7;
              2013
            </li>

            <li>
              Chawla P, Kulkarni K, Goyal R, Pamnani C, Nandave M. Comorbid
              conditions warrants antibiotic dosage adjustment in renal failure.
              Ind J Hosp Pharm 49: 3-6; 2013
            </li>

            <li>
              Patel SS, Goyal RK, Shah RS, Trigar PR, Jadav PD, Experimental
              study on effect of hydroalcoholoic extract of{" "}
              <i>Emblica officinaleI </i>fruits on glucose homeostasis and
              metabolic parameters. Ayu :34 440-444; 2013
            </li>
            <li>
              Nandave M. Goyal RK. Development and Adaptation in Diabetic
              Cardiomyopathy. In: Adaptive Biology and Medicine, New
              Developments Eds: L. M. Popescu, A. R. Hargens, P. K. Singal.
              Narosa Publishing House, NewDelhi Vol 7, 73-88; 2013
            </li>
            <li>
              Patel SS, Goyal RK Biotransformation of gallotonins from fresh
              fruit juice of <i>Emblica officinalis </i>in <i>in-vitro </i>
              system Res. J Phytochem 7(1): 18-23; 2013
            </li>
            <li>
              Vaidya HB, Ahmed A, Goyal RK,, Cheema SK Glycogenphosphorylase-a
              is a common target for antidiabetic effect of iridoid and
              sercoiridoid glucosides J Pharm Pharm Sci 16(4): 530-540; 2013
            </li>
            <li>
              Patel BM, Kakadia J, Goyal RK, Mehta AA Effect of spironolactone
              on cardiovascular complications associated with type 2 diabetes in
              rats Exp Clin Endocrinol Diabetes 121:441-447; 2013
            </li>
            <li>
              Shah PI, Goyal RK, Gosai M, Tripathi CB Protective actions of
              wheatgrass capsules in patients with thalassemia major Pharma
              Science Monitor 4(4): 296-302; 2013
            </li>
            <li>
              Shah PI, Goyal RK, Tripathi CB, Gosai M Evidence for the presence
              of iron chelator constituent in the wheatgrass for the treatment
              of patientswith thlassemia major J. Pharm Res. 5(7); 3543-3547;
              2013
            </li>

            <li>
              Geete SS, Kaur G, Goyal RK, Pamnani C Emergence of resistance to
              fluoroquinolones utilized as second line of treatment for
              tuberculosis Ind J Hos Pharm 50: 122-125; 2013
            </li>
            <li>
              Modi K, Patel B, Patel D, Chavda J, Goyal R Protective effects of
              aqueous extracts of <i>M. prurience </i>Linn (DC) seeds against
              cisplatin induced oxidative stress and nephrotoxicity in rats Afr.
              J Pharm Pharmacol 7 (28): 1994-9; 2013
            </li>
            <li>
              Solanki R, Goyal RK Preliminary phytochemical screening of{" "}
              <i>Quercus infectoria </i>Oliever, Fagaceae Int J Adv. Pharm Biol
              Chem 2(2): 346-30; 2013
            </li>
            <li>
              Nandave M., Ramdhave A, Goyal RK. Liver and Fat in Type 2
              Diabetes: New Insights and Clinical Relevance. In: Mechanisms of
              Diabetic cardiomyopathy : Biochemical and Molecular Mechnisms,
              Eds: B.Turan N. S. Dhalla, Springer, NewYork, USA. 249-268, 2014
            </li>
            <li>
              Rana DG, Upadhyaya UM, Goyal RK. Preferential effects of
              simvastatin on dopaminergic agents induced behavioral effect in
              experimental models of depression. Int. J Pharmaceut Res 6: 55-60;
              2014
            </li>
            <li>
              Vaidya HB, Goyal RK, Cheema SK. Acetylated and propionated
              derivatives of swertiamarin have anti-adipogenic effects. J
              Pharmacol Pharmacother 5: 232-8; 2014
            </li>
            <li>
              Rana DG, Patel A, Joshi C, Jhala M, Goyal RK. Alteration in the
              expression of Exon IIC transcripts of brain derived neurotropic
              factor gene by dopamine and simvastatin inchronic mild stress in
              mice. Can J Physiol Pharmacol 92: 985-92; 2014.
            </li>
            <li>
              Bhatt JA, Goyal RK. Significance of pharmaceutical factors on
              selection of biobatch. Int J Pharmaceut Sci Res 5 (11):1009-09;
              2014
            </li>
            <li>
              Bhatt J, Vaidya H, Khanna, V, Goyal RK. In silico docking studies
              for designing potent anti-diabetic derivatives of swertiamarin
              with enzyme HMG Co-A reductase. Molecular Cytogen 2014: 7: 97
            </li>
            <li>
              Sonawane RD, Deore VB, Patil SD, R. Patil CR, Surana SJ, Goyal RK
              Role of 5-HT2 receptors in diabetes`: Swertiamarin seco-iridoid
              glycoside might be a possible 5-HT2 receptor modulator. Physiology
              &amp; Behavior 144 : 66–72; 2015.
            </li>
            <li>
              Bhadada SV, Goyal, RK. Effect of Aqueous Extract of Tephrosia
              purpurea on Cardiovascular Complications and Cataract Associated
              with Streptozotocin-induced Diabetes in Rats. Indian J Pharm Sci
              2015;77(5):522-529
            </li>
            <li>
              Fedacko J, Hristova K, Elkilany G, Sharma JP, Cornelissen G, Singh
              RB, Saboo B, Goyal RK. Can chronotherapy enhance efficacyand
              diminish adverse effects of pcsk9 inhibitors in the management of
              acute coronary syndromes? W. Heart J. 2015: 7; 97- 102.
            </li>
            <li>
              Parikh H, Tripathi CB, Shah P, Ghori V, Goyal RK. Investigation
              into cardioprotective effects of <i>Crataegus oxycantha </i>and
              its molecular mechanism. Curr Clin Cardiol 2:161-7; 2015
            </li>
            <li>
              Bhadada SV, Bhadada VJ, Goyal, RK. Preventive Effect of Tephrosia
              purpurea on Selenite-Induced Experimental Cataract. Current Eye
              Research, 2016 41 (2) 222- 231. DOI: 10.3109/02713683.2015.1011281
            </li>

            <li>
              Bhatt JA, Rathod R, Jairaj V, Goyal RK. Development and validation
              of analytical method for estimation of swertiamarin in marketed
              preparation containing <i>Enicostemma littorale. </i>World J
              Pharmacy Pharm Sci 5: 1467-1478; 2016
            </li>
            <li>
              Bhadada SV, Goyal RK. Effect of flavonoid rich fraction of{" "}
              <i>Tephrosia purpurea </i>(Linn.) Pers. On complications
              associated with streptozotocin-induced type I diabetes mellitus.
              Indian J Expt. Biol 54:, 457-66; 2016
            </li>
            <li>
              Bhadada SV, Vyas VK, Goyal RK. Protective effect of Tephrosia
              purpurea in diabetic cataract through aldose reductase inhibitory
              activity. Biomedicine &amp; Pharmacotherapy 83: 221–228; 2016
            </li>
            <li>
              Ramesh A, Subbu A, Rajamani K., Goyal RK. Clinician’s perspective
              for personalized medicine and genetic testing as the future of
              cancer diagnosis. Stan Med J 3:119-26, 2016
            </li>
            <li>
              Desai K, Goyal RK. Formulation development and evaluation of
              herbal lozenges for the treatment of recurrent aphthous stomatitis
              Int J Res Pharmacol Pharmacother 5(4):318-25; 2016.
            </li>
            <li>
              Pichu S, Rengarajan S, Krishnan CN, Appasundaram, S, Goyal RK.
              miRNA related to hypoxia as a diagnostic biomarker of diabetic
              foot ulcer. Diabetes 2016; 65 A429
            </li>
            <li>
              Patel SS, Shah RS, Goyal RK. Antihyperglycemic Effects of
              Formulation of Spray Dried Fruit Juice of{" "}
              <i>Emblica officinalis </i>in Streptozotocin Induced Diabetic
              Rats. <i>Current Nutrition &amp; Food Science, </i>2017,{" "}
              <i>13, </i>1-6 1
            </li>
            <li>
              Desai K, Kataria M, Talavia A. , Dr. Ramesh K. GoyalFormulation
              development and evaluation of herbal lozenges for the treatment of
              recurrent aphthous stomatitis. Int. J. of Res. in Pharmacology
              &amp; Pharmacotherapeutics Vol-5(4) 2016; 318-325.
            </li>
            <li>
              Fedacko, J., Hristova, K., Elkilany, G., Sharma, J.P.,
              Cornelissen, G., Singh, R.B., Saboo, B., Goyal, R.K.Study protocol
              for a new study: “Can chronotherapy enhance efficacy and diminish
              adverse effects of PCSK9 inhibitors in the management of acute
              coronary syndromes? 2016: Current Trends in Cardiovascular
              Research 207- 212
            </li>
            <li>
              Sharma M, Kartha Mukhopadhyay B, Goyal RK, Gupta SK, Ganguly NK,
              Dhalla NS. India’s March to Halt the Emerging Cardiovascular
              Epidemic. Circ Res 2017;121(8):913-916.
            </li>
            <li>
              Pichu S, Patel BM, Appasundaram S, Goyal RK<b>. </b>Role of
              biomarkers in redicting diabetes complications with special
              reference to diabetic foot ulcers. Biomarker med.
              2017;11(4):377-388.
            </li>
            <li>
              Raghunathan S, Goyal RK<b>, </b>Patel BM. Selective inhibition of
              HDAC2 by magnesium valproate attenuates cardiac hypertrophy. Can J
              Physiol Pharmacol 2017 Mar;95(3):260-267.
            </li>
            <li>
              HG Vyas, RK Goyal, GM Vyas, BA Vyas, SA Shah. An observational
              study to determine the prevalence and risk factors associated with
              pregnancy induced hypertension in semi-urban town of India. Indian
              Practitioner 70 (12), 16-20
            </li>
            <li>
              Sachaniya J, Savaliya R, Goyal RK, Singh S. Liposomal Formulation
              of Vitamin A for the potential treatment of osteoporosis. Int J
              Nanomed, 2018;13:51-53.
            </li>

            <li>
              Goyal RK, Thakur AK, A Status Report on Autonomic Nervous System:
              The Indian Research Scenario During the Last Five Years.
              Proceedings of the Indian National Science Academy, 2018; 84(1):
              1-12.
            </li>
            <li>
              Singh RB, Mishra S, Kumar S, Tiwari AM, De F, Goyal RK, Kartikey
              K, Singh M. Micronutrient formulations for prevention of
              complications of pregnancy. Front Biosci 2018: 10:175-184.
            </li>
            <li>
              Kumar Y, Goyal RK, Thakur AK. Pharmacotherapeutics of miglitol: an
              α-glucosidase inhibitor. J Anal Pharm Res. 2018;7(6):617-619.
            </li>
            <li>
              Goyal RK, Thakur AK. Deadly drug combinations. Double Helical.
              2018;5(1):46-50..
            </li>
            <li>
              Goyal RK, Challenges and solutions for establishment of world
              class educational institutions. Uni News 2019: 57(06): 32-42
            </li>
            <li>
              Goyal RK. Towards a healthier heart. Double Helical.
              2019;5(4):36-41. 322 Goyal RK. Demystifying diabetes. Double
              Helical. 2018; 4(9):26-29.
            </li>
            <li>
              Goyal RK. India’s march to halt the emerging cardiovascular
              epidemic. Double Helical. 2018; 4(8):18-23.
            </li>
            <li>
              Goyal RK, Thakur AK. Deadly drug combinations. Double Helical.
              2018;5(1):46-50. institutions. Uni News. 2019;57(06): 32-42
            </li>
            <li>
              Kumar Y, Thakur AK, GoyalRK. Evaluation of alpha glucosidase
              inhibitors (miglitol) for its efficacy in constipation associated
              with diabetes. Ind J Pharm Edu Res 2019:53(3); S389-S91.
            </li>
            <li>
              Pannu A, Goyal RK, Nandave M. Therapeutic potential of
              thymoquinone in treatment of rheumatoid arthritisand related
              autoimmune disorders.In: Bioactive Food as Dietary Interventions
              for Arthritis and Related Inflammatory Diseases (Second Edition)
              Eds: Ross R, Victor W, Preedy R., Academic Press, 2019; pp
              343-354.
            </li>
            <li>
              Pannu A, Goyal RK, Ojha S, Nandave M. Narigenin: A promising
              flavonoid for herbal treatment of rheumoid arthritis and
              associated inflammatory disoe=rders. In: Bioactive Food as Dietary
              Interventions for Arthritis and Related Inflammatory Diseases
              (Second Edition). Eds: Ross R, Victor W, Preedy R., Academic
              Press, 2019; 575-58.
            </li>
            <li>
              Patel BM, Rawal H, Goyal RK. Single Herb to Single Phytochemical
              Based Therapy for Diabetes Mellitus. In: Natural Medicines:
              Clinical Efficacy, Safety and Quality. Eds. P.K. Mukherjee and D.
              Ghosh, CRC Press, Taylor and Francis Group, USA, 2019.
            </li>
            <li>
              Gupta M, Goyal RK and Sharma V. Angiogenesis treatment with CD13
              targeting nanomedicines. In: Nanomedicines and Disease Treatment:
              Concept to Applications. Eds. SarwarBeg, Mahfoozur Rahman, Md.
              Abul Barkat, Farhan Jalees Ahmad, Apple Academic Press, USA, 2019
              pp 271-304
            </li>
            <li>
              Dhamoon RK, GoyalRK, Popli H, Gupta M. Luliconazole-Loaded
              Thermosensitive Hydrogel as Aqueous based Nail Lacquer for the
              Treatment of Onychomycosis. Drug Del. Letters 2019:9; 321-29
            </li>

            <li>
              Patel BM and Goyal RK. Liver and insulin resistance: New wine in
              old bottle. Eur J Pharmac 2019; 862: 172657
            </li>
            <li>
              Gupta M, Sharma V, Chauhan DN, Chauhan NS, Shah K, Goyal RK.
              Nanotechnological-Based Drug Delivery System for Magical Molecule
              Curcumin: Delivery, Possibilities and Challenges. In; Eds.
              SarwarBeg, Mahfoozur Rahman, Md. Abul Barkat, Farhan Jalees Ahmad,
              Apple Academic Press, USA, 2019 pp 313-334
            </li>
            <li>
              Gupta M, Sharma V, Chauhan DN, Chauhan NS, Shah K, Goyal RK.
              Nanotechnological-Based Drug Delivery System for Magical Molecule
              Curcumin: Delivery, Possibilities and Challenges. In; Eds.
              SarwarBeg, Mahfoozur Rahman, Md. Abul Barkat, Farhan Jalees Ahmad,
              Apple Academic Press, USA, 2019 pp 1-3
            </li>
            <li>
              Sharma V, Gupta M, Chauhan DN, Goyal RK, Shah K. Novel drug
              delivery systems for phytoconstituents: Current and Future
              prospects In; Eds. Gupta M, Chauhan DN, Sharma V, Chauhan NS. CRS
              Press USA, 2019 pp 1-10
            </li>
            <li>
              Sharma P, Goyal RK, Nandave M. A Review: Prevention, treatment and
              management of tuberculosis through combinationalapproaches of
              different Indian systems of medicine. © Georg Thieme Verlag KG
            </li>
            <li>
              Singh RB, Torshin VI, Chibisov S, Goyal RK, Watanabe S, Nakagami
              H, Mogi M, Nayak BN. Can protective factors inculcate molecular
              adaptations of cardiomyocyte in the prevention of chronic heart
              failure? World Heart J 2019:11;149-57
            </li>
            <li>
              SM Mumtaz, RK Goyal, S Dwivedi. Adverse cardiovascular effects of
              betel nut. MGM Journal of Medical Sciences 2019:6 (4), 1712019
            </li>
            <li>
              Patel BM, Dave SH, <b>Goyal RK</b>. Modified Denouement in
              Bariatric Surgery Due to Genetic Polymorphism. In: Tappia PS et
              al, (Eds.) Pathophysiology of Obesity Induced Health
              Complications. Advances in Biochemistry in Health and disease
              2020; Vol 19; Springer Nature Switzerland, AG. pp 271-283
            </li>
            <li>
              Majeed J, Ajmera P and <b>Goyal RK. </b>Delineating clinical
              characteristics and comorbidities among 206 COVID-19 deceased
              patients in India: The emerging significance of renin-angiotensin
              system derangement. Diabetes Research and Clinical Practice. 2020;
              167: 108349
            </li>
            <li>
              <b>Goyal RK</b>, Majeed J, Tonk R, Dhobhi M, Patel BM, Sharma K
              and Appasundarm S. Current targets and drug candidates for
              prevention and treatment of of SARS-CoV-2 (COVID-19) infection.
              Rev. Cardiovas Med 2020: 21(3); 365-84.
            </li>
            <li>
              <b>Goyal RK, </b>Majeed J and Ajmera P. Decoding the Wrath of
              Virus. Double Helical. 2020; 6(4) 33-37.
            </li>
            <li>
              Pannu A, <b>Goyal RK</b>, SL Neha, R Gupta. A survey on popularity
              and general awareness regarding allopathy and Ayurvedic system of
              medicine among rural and urban areas. Intern J Rec Adv Multidis.
              Topics 2020: 1(1), 7-12.
            </li>
            <li>
              Ajmera, P., Majeed, J., Goyal, R.K., Yadav, S., Mukhopadhyay,D.
              Overcoming the Pandemic: Analysing the Ongoing Challenges in the
              Prevention of COVID 19 in India. J Health Manage 2020: 22(4);
              630–652.
            </li>

            <li>
              Sharma P, Thakur AK and <b>Goyal RK. </b>Emergence of Bevacizumab
              as biosimilar with ophthalmologic prospects for patients and
              pharma industry. Ophthalmology and Ophthalmic Surgery.2020;
              3(1):122
            </li>
            <li>
              <b>Goyal RK</b>, Babu CR, Majeed J, Dhobhi M, Patel BM, Sharma K,
              Tonk R, Thakur AK and Appasundarm S. Herbal based treatments for
              Covid 19. 2020. CV Network. 19(2):15-16
            </li>

            <li>
              Singh, R.B., Fedacko, J., Goyal, R.K., Rai, R.H., Nandave, M.,
              Tonk, R.K., , Chibisov, S.Pathophysiology and significance of
              troponin T in heart failure, with reference to behavioral risk
              factors. World Heart Journal, 2020: 12(1); 15-21
            </li>

            <li>
              Masi T, Goyal RK, Patel BM. Role of liver X receptor in
              cardiovascular diseases. In: Tappia PS et al, (Eds.) Biochemistry
              of Cardiovascular Dysfunction in Obesity, Advances in Biochemistry
              in Health and disease 2020; Vol 20; Springer Nature Switzerland,
              AG. 77-96
            </li>

            <li>
              Sharma P, Thakur AK and <b>Goyal RK. </b>Emergence of Bevacizumab
              as biosimilar with ophthalmologic prospects for patients and
              pharma industry. Ophthalmology and Ophthalmic Surgery.2020;
              3(1):122
            </li>
            <li>
              Patel BM, Sharma S, Nair N, Majeed J, <b>Goyal RK </b>and Dhobhi
              M. Therapeutic opportunities of edible anti-viral plants for
              Covid-19. Mol. Cell Biol. 2020: 476(6); 2345-64.
            </li>
            <li>
              Goswami S, Goyal RK, Mumtaz SM, Tonk RK, Gupta R, Pottoo FH. Role
              of forkhead transcription factors in the O class (FoxO) in
              development and progression of Alzheimers’s disease CNS Neurol
              Disord Drug Targets 2020: Sept 30,
              doi;10.2174/1871531966620100105553.
            </li>
            <li>
              Aijaz U and <b>Goyal RK</b>. Knowledge attitude and factors
              responsible for Vitamin and Calcium deficiency among youth. Int.
              J. Trend Sci. Res. Rev. 2020: 4(5)
            </li>
            <li>
              Dhiman S, Sahu PK, Reed WR, Ganesh GS, <b>Goyal RK </b>and Jain S.
              Impact of COVID-19 Outbreak on Mental Health and Perceived Strain
              among Caregivers Tending Children with Special Needs. Elsevier
              Editorial System (TM) for Research in Developmental Disabilities.
              2020: 107; 103790.
            </li>
            <li>
              Singh, A.,Kalaivani, M.Srivastava, S.,Goyal, R.K.,Gupta, S.K.
              Postmarketing Safety of Biosimilars: Current Status, Challenges,
              and Opportunities in the Spontaneous Reporting System. Ther.
              Innov. Regul. Sci. 2020: 54(3), 667-68
            </li>
            <li>
              <b>Goyal RK </b>and Aggarwal G. Biomarker based drug discovery
              with Translational Approach. In: Contemporary issues in Biomedical
              Science: Futuristic Thought. 2021 (In Press)
            </li>
            <li>
              SS Patel, RS Shah, RK Goyal. Standardization and Phytochemical
              Evaluation of Spray-dried Powder of Fruit Juice of Emblica
              officinalis Gaertn. Pharm Meth 2020: 11 (1)
            </li>
            <li>
              Gupta M, Sharma V, Chauhan DN, Chauhan NS, Shah NS, Goyal RK.
              Amphiphilic block copolymer: a smart option for bioactives
              delivery. In: Advances and Avenues in the Development of Novel
              Carriers for Bioactives and Biological agents. Eds Singh MR, Singh
              D, Khanna JM, Chauhan NS. Elsevier Incl Academic Press, USA, 2020:
              pp 451-479.
            </li>

            <li>
              Mumtaz SM, Gupta M and <b>Goyal RK</b>. Placental therapy as
              panacea: An insight to its therapeutic potential. Current
              Traditional Medicine. 2020: 22(4) 418-31.
            </li>
            <li>
              Pal, S., Prasad, R.S., Prasad, S.K., Goyal, R.K., Dhobi, M. An
              updated overview of ethnomedicinal uses, phytochemical and various
              pharmacological evaluations on the plant capparis decidua
              (Forssk.) edgew. Curr Trad Med: 2021, 7(2), 189– 202
            </li>
            <li>
              Vashi R, Patel BM, Goyal RK. Keeping abreast about ashwagandha in
              breast cancer. J. Ethnopharmacol 2021: 269; 113759.
            </li>
            <li>
              Pannu, A., Sharma, P.C., Thakur, V.K., Goyal, R.K. Emerging role
              of flavonoids as the treatment of depression. Biomolecules, 2021,
              11(12), 1825.
            </li>
            <li>
              Patel, B., Sharma, S., Nair, N., Goyal, R.K., Dhobi, M.
              Therapeutic opportunities of edible antiviral plants for COVID-19.
              Mol Cell Biol: 2021, 476(6), pp. 2345–2364
            </li>
            <li>
              Yadav V, Reang J, Sharma V, Majeed J, Swaminathan J,{" "}
              <b>Goyal RK </b>and Tonk RK. Repositioning of drugs an emerging
              strategy for urgent intervention of NcoV-19 infection.
              Coronavirus. 2021: 2; 1-15
            </li>
            <li>
              Musyumi P, Aggarwal G, Nagpal M and <b>Goyal RK</b>. A Case Study:
              Analysis of Patents of Coronaviruses and COVID-19 for Technology
              Assessment and Future Research. Curr Pharm Des. 2021: 27;434-50.
            </li>
            <li>
              Goyal RK, Apparsundaram. Managing Covid as virus induced
              cardiovascular punmonary diseasewith herbal medicine. Cardiology
              Today: 2021: 35(2); 43-46.
            </li>
            <li>
              Mumtaz SM, Bhardwaj G, Goswami S, Tonk RK, Goyal RK, Pottoo FH.
              Management of Glioblastoma Multiforme by Phytochemicals:
              Applications of Nanoparticle Based Targeted Drug Delivery System.
              Curr Drug Targets. 2021 22(4); 418-431 doi: 10.2174/
              1389450121666200727115454.PMID: 32718288
            </li>
            <li>
              Goyal RK, Subbu, A. Dhobi M. Patel BM. Herbal formulations for the
              treatment of COVID 19. In: Sobti RC, Dhalla, NS, Watanabe, M Eds.
              Deleating health and health systems: Mechanistic insights into
              COVID 19 complications Springer Singapore 2021: pp 437-447.
            </li>
            <li>
              Aggarwal G, Kaithawas, G, Singh M. and Goyal RK. Organ-on-a- chip:
              novel in-vitro model for drug discovery In: Sobti RC, Dhalla, NS,
              Watanabe, M Eds. Deleating health and health systems: Mechanistic
              insights into COVID 19 complications.Springer Nature Singapore
              2021: Academic Press
            </li>
            <li>
              Sharma, P.C., Sharma, D., Sharma, A.,Bhardwaj, J.K., Goyal, R.K.
              Recent advances in microbial toxin-related strategies to combat
              cancer. Seminar Cancer Biol: 2021 Academic Press
            </li>
            <li>
              Shrivastava, T.P., Goswami, S., Gupta, R., Goyal, R.K. Mobile App
              Interventions to Improve Medication Adherence Among Type 2
              Diabetes Mellitus Patients: A Systematic Review of Clinical
              Trials. J Diab Sci Tech: 2021: 19322968211060060
            </li>

            <li>
              Pannu A, Goyal RK.Pharmacognostical Characterization of Whole
              ScutellariaBarbata herb for Quality Control Assessment. Innov
              Internat J Med Pharm Sci: 2021: 6 (4)
            </li>
            <li>
              Jain S, Goyal RK, Ajmera P, Aggarwal G, Dhiman S, Recent Advances
              in Diagnosis and Management of Myofascial Pain Syndrome: A
              Narrative Review. J Clin Res: 2021: 15 (10)
            </li>
            <li>
              Dhiman S, Goyal RK, Ajmera P. Standardization of child sensory
              profile–2 care giver questionnaire in children with spastic
              cerebral palsy.Ind J Health Sci and Care 2021; 8 (spl), 33-33
            </li>
            <li>
              Bora V, Patel D, Johar K, Goyal RK, Patel BM.Systemic study of
              selected histone deacetylase inhibitors in cardiac complications
              associated with cancer cachexia Can J Physio Phar 2021: 99, 1-12
            </li>
            <li>
              Sahu, S., Patil, C.R., Kumar, S., Apparsundaram, S., Goyal,
              R.K..Role of ACE2-Ang (1–7)-Mas axis in post-COVID-19
              complications and its dietary modulation. Mol Cell Biol: 2022,
              477(1), pp. 225–240
            </li>
            <li>
              Musyuni, P., Nagpal, M., Singh, M., Goyal, R.K., Aggarwal, G.
              Nanotechnology Enabled Solutions to Combat Covid-19: Prevention,
              Treatment, and Diagnosis. Curr Pharm Biotech: 2022, 23(1), pp.
              98–111
            </li>
            <li>
              Rai RH, Goyal RK, Singh RB, Handjiev S, Singh J, Darlenska TH.
              ilVitamins and minerals as nutraceuticals in cardiovascular
              diseases and other chronic diseases. In: Functional Foods and
              Nutraceuticals in Metabolic and Non-Communicable Diseases, Eds
              Academic Press: 2022, pp 651-670
            </li>
            <li>
              Musyumi P, Nagpal M, <b>Goyal RK </b>and Aggarwal G.
              Nanotechnology enabled solutions to combat COVID-19: Prevention,
              Treatment, and Diagnosis. Current Pharmaceutical Biotechnology.
              2022 (Under review)
            </li>
            <li>
              Majeed J, Chauhan C and <b>Goyal RK</b>. Tourism, A Causative
              Factor responsible for rampant increase of corona cases in the
              World. Journal of Travel Research. 2022 (Under review)
            </li>
            <li>
              <b>Goyal RK</b>. Assessment of cross-country variations of
              Covid-19 in terms of case fatality rates and usage of
              complementary and alternative medicine. J Compl. Medi. 2022 (Under
              review)
            </li>
            <li>
              Khalid N, Gupta S, Majeed J and <b>Goyal RK. </b>Necessity for
              public awareness about rotavirus vaccine to prevent infantile
              mortality due to diarrhoea. Int J. Pharm. Sci. Nanotech. 2022
              (Under review)
            </li>
            <li>
              Majeed J, Chauhan C and <b>Goyal RK. </b>Fight against COVID-19:
              Comparative Analysis of USA and India. Indo Global Journal of
              Pharmaceutical Sciences (IGJPS). 2022 (Under review) Manuscript
              Number: IGJPS-2020-MSA-0752.
            </li>
            <li>
              Aggarwal G, and Goyal RK<b>. </b>Biomarker based Drug Discovery
              with Reverse Translational Approach. In: Sobti RC, Dhalla, NS,
              Watanabe, M Eds. Deleating health and health systems: Mechanistic
              insights into COVID 19 complications.Springer 2022: Nature
              Singapore.
            </li>
          </ol>
        </div>
      ) : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() => setIsConferencesOpen(!isConferencesOpen)}
      >
        Conferences
      </button>

      {isConferencesOpen ? <div className="inner-container"></div> : null}

      {/* toggle button Conferences */}
      <button
        className="naac-button"
        onClick={() =>
          setIsAwardsandRecognitionsOpen(!isAwardsandRecognitionsOpen)
        }
      >
        Awards and Recognitions
      </button>

      {isAwardsandRecognitionsOpen ? (
        <div className="inner-container">
          <p>
            <b>AWARDS, HONOURS AND FELLOWSHIPS (CURRENT)&nbsp;</b>
          </p>
          <p>
            <b>(Total 78 Awards </b>
            <b>excluding</b>
            <b> those to student presentations)&nbsp;</b>
          </p>
          <p>
            <b>Fellowships</b>
            <b>:&nbsp;</b>
          </p>
          <p>
            <b>1987: Fellow </b>
            <span>of Institution of Chemists (India) (</span>
            <b>FIC)&nbsp;</b>
          </p>
          <p>
            <b>1999: Fellow </b>
            <span>of Indian Pharmacological Society </span>
            <b>(FIPS)&nbsp;</b>
          </p>
          <p>
            <b>1999: Fellow </b>
            <span>of International College of Nutrition </span>
            <b>(FICN)&nbsp;</b>
          </p>
          <p>
            <b>2000: Fellow </b>
            <span>of National Academy of Medical Sciences, India </span>
            <b>(FAMS)&nbsp;</b>
          </p>
          <p>
            <b>2002: Fellow </b>
            <span>of </span>
            <b>
              International Academy of Cardiovascular Sciences, (FIACS)&nbsp;
            </b>
          </p>
          <p>
            <b>2006: Fellow </b>
            <span>of National Academy of Sciences (Allahabad), (</span>
            <b>FANSc</b>
            <span>).&nbsp;</span>
          </p>
          <p>
            <b>2006: Fellow, </b>
            <span>Indian Society of Hypertension </span>
            <b>(FISH)&nbsp;</b>
          </p>
          <p>
            <b>2010: Fellow </b>
            <span>Specialist of Clinical Hypertension </span>
            <b>(FSCH)&nbsp;</b>
          </p>
          <p>
            <b>2017: Fellow </b>
            <span>
              of Diabetes India, The Research Trust of Diabetes, India{" "}
            </span>
            <b>(FDI)&nbsp;</b>
          </p>
          <p>
            <b>2018: Fellow </b>
            <span>
              of Indian Society of Vetenerian Pharmacology and Toxicology{" "}
            </span>
            <b>(FISVPT) </b>
            <b>International:&nbsp;</b>
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <span>1.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2000&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Medal of Merit</b>
                    <span>, XVII World Congress of </span>
                    <b>
                      <i>
                        International Society of Heart Research, Winnipeg,
                        Canada
                      </i>
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>2.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2005&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Distinguished Service Award </b>
                    <span>
                      in Cardiovascular Sciences, Medicine and Surgery by{" "}
                    </span>
                    <b>
                      <i>
                        International Academy of Cardiovascular Sciences,
                        Winnipeg, Canada
                      </i>
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>3.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2007&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Life time Achievement Award in </b>
                    <span>
                      Cardiovascular Sciences, Medicine and Surgery&nbsp; by{" "}
                    </span>
                    <b>
                      <i>
                        International Academy of Cardiovascular Sciences,
                        Winnipeg, Canada
                      </i>
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>4.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2014&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Life time Achievement Award by Indo-Global Foundation,
                      Inc., USA
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>5.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2015&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Dr. Franz Haldberg Medal from The International College of
                      Cardiology &amp; Society of Chronobiology
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>6.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2018&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Diplom of the Honorary Profesor, </b>
                    <span>Stavropol Medical State University, </span>
                    <b>Russia </b>
                    <span>(Second Foreighner in 80 Years)</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>7.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2020&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Distinguished Leadership Award in </b>
                    <span>
                      Cardiovascular Sciences, Medicine and Surgery&nbsp; by{" "}
                    </span>
                    <b>
                      <i>
                        International Academy of Cardiovascular Sciences,
                        Winnipeg, Canada
                      </i>
                    </b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <ol>
            <li>
              <b></b> <b>Distinguished Awards:</b>
            </li>
          </ol>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <span>1.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1984</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>B.C. Heart Foundation Research Fellowship </b>
                    <span>
                      at the University of British&nbsp; Columbia, Vancouver,
                      Canada.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>2.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1990</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Dr.S.C. Verma Memorial Award </b>
                    <span>
                      for the best research paper presented at&nbsp; All Gujarat
                      Annual Meeting of Indian Pharmacological Society
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>3.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1993</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>R. K. Desai Award </b>
                    <span>
                      for the best paper presented in the Annual
                      Conference&nbsp; of the Gujarat Association of Physicians
                      held at Surat.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>4.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1996</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Dr. O. D. Gulati Oration </b>
                    <span>given at Vadodara Branch of Indian&nbsp;</span>
                  </p>
                  <p>
                    <span>&nbsp;Pharmacological Society</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>5.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1998</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Prof. R. Wegmann Oration </b>
                    <span>of Indian Society.of Hypertension</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>6.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>1999</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Award of Millennium </b>
                    <span>
                      for outstanding contribution cardiovascular&nbsp; Sciences
                      by International College of Nutrition
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>7.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2002</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>B. Uvnas Prize </b>
                    <span>
                      for the best Research paper published in the field
                      of&nbsp; Biogenic amines and autacoids given by Indian
                      Pharmacological Society
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>8.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2004&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Pharmacy Teacher of the Year </b>
                    <span>
                      award by Association of Indian Pharmaceutical Teachers of
                      India, at Vishakhapatanam
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>9.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2004&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Agra Ratna </b>
                    <span>
                      by the All Gujarat grawal Conference for the outstanding
                      contributions in Education (
                    </span>
                    <b>
                      <i>Award conferred by the Governor of Gujarat</i>
                    </b>
                    <span>)</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>10.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2005&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Prof. S. C. Lahiri Oration Award by the Indian
                      Pharmacological Society, Chennai
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>11.&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2006&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      P.D. Sethi Award for the best paper in Pharmaceutical
                      Analysis
                    </b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <span>12&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2007&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Dr. (Mrs) Manjushree Pal Memorial </span>
                    <b>Award for the best Pharmaceutical Scientist by APTI</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>13&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2011&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Dr. Mrunalini Devi Oration, , The M S University of Baroda
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>14&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2011&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Dr. H.R. Derasari Oration, Ahmedabad</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>15&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2014&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Prof. G.P. Shrivastav Memorial Lecture, APTI, Pune</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>16&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2016&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Dr Ansari Sabir Oration. 16</b>
                    <b>th </b>
                    <b>ISVPT, Navsari,</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>17&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2017&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Prof. K. N. Udupa Award by IASTAM, Varanasi</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>18&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2017&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>8</b>
                    <b>th </b>
                    <b>
                      Dr M. rajani Oration Shri B. V. Patel PERD Center,
                      Ahmedabad
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>19&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2017&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Shikshak Shri Award, Rashtriya Samta Swatantrata Manch,
                      Indo-Nepal
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>20&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2017&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Life time Achievment Award, Ludhiana</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>21&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2018&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Prof. Manjit Singh Oration, ISHR, India, Chandigarh</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>22&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2018&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Life Time Achievement Award,</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>23&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2018&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      John Autian Oration, Society of Pharmacovigilance (India)
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>24&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2018&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Prof. M. L. Shroff Oration, APTI, Jaipur</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>25&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2019&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Pharma Ratan Award, NewDelhi</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>26&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2020&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Lifetime Achievement Award in Engineering, Science and
                      Medicine by VDGOOG Professional Association, Chennai
                    </b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>27&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2020&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>APP Dynamic Professional Award</b>
                    <b> Award</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>28&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2022&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>
                      Sardar Jashwant Singh Rail Memorial Lecturership Award,
                      with Rs 25,000/- cash{" "}
                    </b>
                    <b>&nbsp;</b>
                    <b>Guru Nanak Dev University, Amritsar&nbsp;</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>29&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>2022&nbsp;</span>
                  </p>
                </td>
                <td>
                  <p>
                    <b>Top&nbsp;</b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <ol>
            <li>
              <b> Awards for Published Papers by the Professional Societies</b>
              <b></b>
            </li>
            <li>
              <b> P. P. Suryakumari Prize of Indian Pharmacological Society </b>
              <span>
                for the best Research paper published in the field of Diabetes
                mellitus&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <b>
              Received for 5 different research papers in 1994, 1996, 1997,
              1998, and 2003&nbsp;
            </b>
          </p>
          <ol start="2">
            <li>
              <b> N. N. Dutta Prize/Jaipur Prize </b>
              <span>
                for the best research paper published in the Indian Journal of
                Pharmacology{" "}
              </span>
              <b>(The prize given by Indian Pharmacological Society)</b>
              <span>.&nbsp;</span>
            </li>
          </ol>
          <p>
            <b>
              Received for 4 different research papers in 1981, 1988, 2000, and
              2003&nbsp;
            </b>
          </p>
          <ol start="3">
            <li>
              <b>
                {" "}
                HariOm Ashram Prerit Bhaikaka InterUniversity Smarak Trust Prize{" "}
              </b>
              <span>
                for the best papers published by Pharmacy Teacher from the
                Gujarat State Universities&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <b>
              Received for 8 different research papers in 1987, 1989, 1990,
              1995, 1997, 1998 1999, and 2002&nbsp;
            </b>
          </p>
          <ol start="4">
            <li>
              <b>
                {" "}
                Prof. V. Santaram Prize for the best Research paper published
                Indian Journal of Hypertension. (the Prize given by the Indian
                Society of Hypertension (in 1997)&nbsp;
              </b>
            </li>
            <li>
              <b> Indian Pharmacological Society Ahmedabad Branch Prize </b>
              <span>
                for the best Research paper published by any pharmacologist of
                Gujarat&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <b>
              Received for 6 different research papers in 1987, 1989, 1992,
              1997, 1999, 2002&nbsp;
            </b>
          </p>
          <ol start="6">
            <li>
              <b>
                {" "}
                Indian Association of Dermatologists, Venereologists and
                Leprologists&nbsp;
              </b>
            </li>
          </ol>
          <p>
            <b>
              Dr. L. Bishnupriya Debi Award for the Best research Paper in
              Indian Journal of Dermatology and Venereology&amp; Leprology
              2010&nbsp;
            </b>
          </p>
          <p>
            <b>
              <i>
                Besides above mentioned Awards various students of the nominee
                got the paper presentations Prizes
              </i>
            </b>{" "}
            <b>
              <i>
                (with the nominee as the co-author) in various conferences of
                the Professional Academic Societies like:
              </i>
            </b>{" "}
          </p>
          <ol >
            <li>
            <span> Indian Pharmacological Society&nbsp;</span>

            </li>
            <li>
              <span> Indian Society of Hypertension&nbsp;</span>
            </li>
            <li>
              <span>
                {" "}
                International Society of Heart Research (Indian Section)&nbsp;
              </span>
            </li>
            <li>
              <span> Gujarat Council on Science and technology</span>
            </li>
            <li><span> Indian Pharmaceutical Congress Association</span></li>
          </ol>
          <p>&nbsp;</p>
        </div>
      ) : null}
    </div>
  );
};

export default ProfRameshKGoyal;
